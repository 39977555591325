import React, { useMemo } from 'react';
import { useTranslate } from 'react-translate';
import { useDispatch, useSelector } from 'react-redux';

import SelectOnTab from '@components/SelectOnTab/SelectOnTab';

import { SET_FEED_ORDER_BY, SET_FEED_PRESET } from '@redux/feed/types';
import { getFiltersOptions } from './consts';
import { useCheckCapabilities } from '../hooks';
import * as S from './styles';

export default function FeedSelect() {
  const t = useTranslate('Feed');
  const dispatch = useDispatch();
  const { feedType, feedOrderBy, preset } = useSelector((state) => state.feedReducer);

  const isDisabled = useMemo(() => {
    const notVisibleWhen = ['POST', 'INSTAGRAM_STORIES'];
    return notVisibleWhen.includes(feedType);
  }, [feedType]);

  const { hasFeedAwards } = useCheckCapabilities();
  const filterOptions = getFiltersOptions({ hasFeedAwards });

  const sortMetrics = (value) => {
    const [orderedBy, preset] = value.split('/');

    dispatch({
      type: SET_FEED_ORDER_BY.SUCCESS,
      feedOrderBy: orderedBy,
    });

    dispatch({
      type: SET_FEED_PRESET.SUCCESS,
      preset,
    });
  };

  if (isDisabled) return null;
  return (
    <SelectOnTab
      iconSelect={['far', 'sort-amount-up']}
      onChange={(e) => sortMetrics(e.target.value)}
      disabled={isDisabled}
      selectValue={`${feedOrderBy}/${preset}`}
    >
      {filterOptions.map((value, index) => (
        <S.OptionStyled key={index} value={`${value.role}/${value.preset}`}>
          {t(value.label)}
        </S.OptionStyled>
      ))}
    </SelectOnTab>
  );
}
