import styled, { css } from 'styled-components';

const SetBackgroundAndColor = (
  theme,
  outline,
  borderColor,
  borderHoverColor,
  backgroundColor,
  backgroundHoverColor,
  fontColor,
  fontHoverColor,
  underlineOnHover
) => {
  if (outline) {
    return css`
      border: 1px solid ${theme.colors[borderColor || backgroundColor]};
      background-color: ${theme.colors.none};
      color: ${theme.colors[fontColor || backgroundColor]};
      &:hover {
        &:not(:disabled) {
          border: 1px solid ${theme.colors[borderHoverColor || backgroundColor]};
          background-color: ${theme.colors[backgroundHoverColor || backgroundColor]};
          color: ${theme.colors[fontHoverColor || 'white']};
        }
      }
    `;
  }
  fontColor = (fontColor && fontColor) || (backgroundColor === 'white' && 'blueGrey2') || 'white';

  return css`
    background-color: ${theme.colors[backgroundColor]};
    color: ${theme.colors[fontColor]};
    border: 1px solid ${theme.colors[borderColor || 'none']};
    &:hover {
      &:not(:disabled) {
        ${backgroundHoverColor
          ? `background-color: ${theme.colors[backgroundHoverColor]}`
          : backgroundColor.indexOf('blue') > -1
          ? `background-color: ${theme.colors.blue3}`
          : 'opacity: 0.89;'}
        ${underlineOnHover ? 'text-decoration: underline;' : ''}
              border: 1px solid ${theme.colors[borderHoverColor || 'none']};
        ${fontHoverColor ? `color: ${theme.colors[fontHoverColor]};` : ``}
      }
    }

    &:active {
      &:not(:disabled) {
        ${backgroundHoverColor
          ? `background-color: ${LightenDarkenColor(theme.colors[backgroundHoverColor], -40)}`
          : backgroundColor.indexOf('blue') > -1
          ? `background-color: ${theme.colors.blue1}`
          : `background-color: ${
              backgroundColor && LightenDarkenColor(theme.colors[backgroundColor], -40)
            }`}
      }
    }
  `;
};

const LightenDarkenColor = (col, amt) => {
  let usePound = false;

  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }

  let num = parseInt(col, 16);
  let r = (num >> 16) + amt;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

const SetSizeButton = (size, theme) => {
  switch (size) {
    case 'px40':
      return `
                  padding: ${theme.spacing.px12} ${theme.spacing.px40};
              `;
    case 'px30':
      return `
              padding: ${theme.spacing.px12} ${theme.spacing.px30};
          `;
    case 'px20':
      return `
                  padding: ${theme.spacing.px12} ${theme.spacing.px20};
              `;
    case 'px16':
      return `
              padding: ${theme.spacing.px12} ${theme.spacing.px16};
                          `;
    case 'px15':
      return `
                  padding: ${theme.spacing.px12} ${theme.spacing.px15};
                                      `;
    case 'px10':
      return `
                  padding: ${theme.spacing.px10} ${theme.spacing.px20};
              `;
    case 'px5':
      return `
              padding: ${theme.spacing.px5} ${theme.spacing.px10};
          `;
    default:
      return ``;
  }
};

export const ButtonStyle = styled('button')`
  ${({
    theme,
    block,
    rounded,
    size,
    fontSize,
    outline,
    backgroundColor,
    borderColor,
    borderHoverColor,
    backgroundHoverColor,
    fontColor,
    fontHoverColor,
    underlineOnHover,
  }) => css`
    text-align: center;
    outline: none;
    border: none;
    line-height: 1;
    transition: all 0.3s;
    margin-right: ${theme.spacing.px15};
    white-space: nowrap;
    width: ${block ? theme.spacing.full : 'initial'};
    border-radius: ${theme.rounded[rounded]};
    font-size: ${theme.fontSize[fontSize]};
    ${SetBackgroundAndColor(
      theme,
      outline,
      borderColor,
      borderHoverColor,
      backgroundColor,
      backgroundHoverColor,
      fontColor,
      fontHoverColor,
      underlineOnHover
    )};
    ${SetSizeButton(size, theme)};
    position: relative;
    top: 0;
    transition: all 0.3s;
    cursor: pointer;
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    &:active {
      top: 0;
      box-shadow: 0 0 0 ${theme.colors.active[backgroundColor]};
    }
    &:last-child {
      margin-right: ${theme.spacing.none};
    }
    & > span {
      // display: flex;
      justify-content: space-between;
      align-items: center;
    }

    svg {
      margin: 0 ${theme.spacing.px10} 0 0;
    }
  `}
`;
