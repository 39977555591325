// Types
import {
  GET_FOLDERS,
  GET_CAMPAIGN_FOLDERS,
  GET_GDTV_FOLDERS,
  SET_CURRENT_CREATIVE,
  SET_CURRENT_GDTV_CREATIVE,
  SET_CURRENT_CAMPAIGN_CREATIVE,
  GET_FOLDER,
  POST_FOLDER,
  POST_MEDIA,
  SET_CREATIVE_INFO,
  DOWNLOAD_MEDIA,
  DOWNLOAD_GDTV_MEDIA,
  DOWNLOAD_CAMPAIGN_MEDIA,
  SHARE_TEMPLATE,
  GET_GDTV_FOLDER,
  GET_CAMPAIGN_FOLDER,
  EDIT_GDTV_FOLDER,
  EDIT_CAMPAIGN_FOLDER,
  SET_GDTV_TYPE,
  GET_POSTS,
  GET_ADS,
  GET_CAMPAIGN_VIEWS,
  GET_GDTV_VIEWS,
  PUT_CAMPAIGN_STATUS,
  POST_ADVERTS_STATUS,
  POST_CAMPAIGN_CHANGE_STATUS,
} from './types';

const INITIAL_STATE = {
  listQtdUsers: {
    data: {
      data: [],
    },
    isLoading: false,
    error: '',
  },
  medias: {
    data: {
      data: [],
      paging: '',
    },
    isLoading: false,
    error: '',
  },
  mediaItem: {
    data: {
      data: [],
      paging: '',
    },
    isLoading: false,
    error: false,
  },
  campaignMedias: {
    data: {
      data: [],
      paging: '',
    },
    isLoading: false,
    error: '',
  },
  gdtvMedias: {
    data: {
      data: [],
      paging: '',
    },
    isLoading: false,
    error: '',
  },
  gdtvItem: {
    data: {
      data: [],
      paging: '',
    },
    isLoading: false,
    error: false,
  },
  campaignItem: {
    data: {
      data: [],
      paging: '',
    },
    isLoading: false,
    error: false,
  },
  downloadMediaLink: '',
  downloadGdtvMediaLink: '',
  newFolderId: 0,
  currentFolder: 0,
  currentGdtvFolder: 0,
  currentCampaignFolder: 0,
  newMediaUploaded: {},
  gdtvType: 'all',
  posts: {
    data: {
      data: [],
    },
    isLoading: false,
    error: '',
  },
  ads: {
    data: {
      data: [],
    },
    isLoading: false,
    error: '',
  },
  campaignViews: {
    data: {
      data: [],
    },
    isLoading: false,
    error: '',
  },
  gdtvViews: {
    data: {
      data: [],
    },
    isLoading: false,
    error: '',
  },
};

const mediasReducer = (state = INITIAL_STATE, action) => {
  let newMediaItem = state.mediaItem.data;
  let newGdtvItem = state.gdtvItem.data;
  let newCampaignItem = state.campaignItem.data;

  switch (action.type) {
    case GET_FOLDERS.REQUEST:
      return {
        ...state,
        medias: {
          ...state.medias,
          isLoading: true,
        },
      };
    case GET_FOLDERS.SUCCESS:
      return {
        ...state,
        medias: {
          ...state.medias,
          data: {
            data: state.medias.data.data.concat(action.data.data || []),
            paging: action.data.paging,
          },
          isLoading: false,
        },
      };

    case GET_FOLDERS.FAILURE:
      return {
        ...state,
        medias: {
          error: action.data,
          data: [],
          isLoading: false,
        },
      };

    case GET_FOLDERS.RESET:
      return {
        ...state,
        medias: { ...INITIAL_STATE.medias },
      };

    case GET_FOLDER.REQUEST:
      return {
        ...state,
        mediaItem: {
          ...state.mediaItem,
          isLoading: true,
        },
      };

    case GET_FOLDER.SUCCESS:
      return {
        ...state,
        mediaItem: {
          ...state.mediaItem,
          data: {
            ...action.data,
            data: state.mediaItem.data.data.concat(action.medias.data),
            paging: action.medias.paging,
          },
          isLoading: false,
        },
      };

    case GET_GDTV_FOLDER.SUCCESS:
      return {
        ...state,
        gdtvItem: {
          ...state.gdtvItem,
          data: {
            ...action.data,
            data: state.gdtvItem.data.data.concat(action.gdtvMedias),
            paging: action.gdtvMedias.paging,
          },
          isLoading: false,
        },
      };

    case GET_CAMPAIGN_FOLDER.SUCCESS:
      return {
        ...state,
        campaignItem: {
          ...state.campaignItem,
          data: {
            ...action.data,
            data: state.campaignItem.data.data.concat(action.campaignMedias),
            paging: action.campaignMedias.paging,
          },
          isLoading: false,
        },
      };

    case GET_CAMPAIGN_FOLDER.RESET:
      return {
        ...state,
        campaignItem: { ...INITIAL_STATE.campaignItem },
      };

    case GET_FOLDER.FAILURE:
      return {
        ...state,
        mediaItem: {
          error: action.data,
          data: {},
          isLoading: false,
        },
      };

    case GET_FOLDER.RESET:
      return {
        ...state,
        mediaItem: { ...INITIAL_STATE.mediaItem },
      };

    case SET_CURRENT_CREATIVE.REQUEST:
      return {
        ...state,
        currentFolder: action.id,
      };

    case SET_CURRENT_GDTV_CREATIVE.REQUEST:
      return {
        ...state,
        currentGdtvFolder: action.id,
      };

    case SET_CURRENT_CAMPAIGN_CREATIVE.REQUEST:
      return {
        ...state,
        currentCampaignFolder: action.id,
      };

    case SET_CURRENT_CAMPAIGN_CREATIVE.RESET:
      return {
        ...state,
        currentCampaignFolder: INITIAL_STATE.currentCampaignFolder,
      };

    case POST_FOLDER.REQUEST:
      return {
        ...state,
        newFolderId: null,
      };

    case POST_FOLDER.SUCCESS:
      return {
        ...state,
        newFolderId: action.id,
      };

    case SET_CREATIVE_INFO.SUCCESS:
      newMediaItem = {
        ...newMediaItem,
        name: action.data.name,
        description: action.data.description,
      };
      return {
        ...state,
        mediaItem: {
          ...state.mediaItem,
          data: { ...newMediaItem },
        },
      };

    case EDIT_GDTV_FOLDER.SUCCESS:
      newGdtvItem = {
        ...newGdtvItem,
        name: action.data.name,
        description: action.data.description,
      };
      return {
        ...state,
        gdtvItem: {
          ...state.gdtvItem,
          data: { ...newGdtvItem },
        },
      };

    case EDIT_CAMPAIGN_FOLDER.SUCCESS:
      newCampaignItem = {
        ...newCampaignItem,
        name: action.data.name,
        description: action.data.description,
      };
      return {
        ...state,
        campaignItem: {
          ...state.campaignItem,
          data: { ...newCampaignItem },
        },
      };

    case POST_MEDIA.REQUEST:
      return {
        ...state,
      };

    case POST_MEDIA.SUCCESS:
      return {
        ...state,
        newMediaUploaded: {
          id: action.id,
          uniqueCode: action.uniqueCode,
        },
      };

    case DOWNLOAD_MEDIA.REQUEST:
      return {
        ...state,
      };

    case DOWNLOAD_MEDIA.SUCCESS:
      return {
        ...state,
        downloadMediaLink: action.link,
      };

    case DOWNLOAD_GDTV_MEDIA.REQUEST:
      return {
        ...state,
      };

    case DOWNLOAD_GDTV_MEDIA.SUCCESS:
      return {
        ...state,
        downloadGdtvMediaLink: action.link,
      };

    case DOWNLOAD_CAMPAIGN_MEDIA.REQUEST:
      return {
        ...state,
      };

    case DOWNLOAD_CAMPAIGN_MEDIA.SUCCESS:
      return {
        ...state,
        downloadCampaignMediaLink: action.link,
      };

    case SHARE_TEMPLATE.REQUEST:
      return {
        ...state,
      };
    case GET_GDTV_FOLDER.REQUEST:
      return {
        ...state,
        gdtvItem: {
          ...state.gdtvItem,
          isLoading: true,
        },
      };

    case GET_GDTV_FOLDER.FAILURE:
      return {
        ...state,
        gdtvItem: {
          error: action.data,
          data: {},
          isLoading: false,
        },
      };

    case GET_GDTV_FOLDER.RESET:
      return {
        ...state,
        gdtvItem: { ...INITIAL_STATE.gdtvItem },
      };

    case GET_GDTV_FOLDERS.REQUEST:
      return {
        ...state,
        gdtvMedias: {
          ...state.gdtvMedias,
          isLoading: true,
        },
      };

    case GET_GDTV_FOLDERS.SUCCESS:
      return {
        ...state,
        gdtvMedias: {
          ...state.gdtvMedias,
          data: {
            data: state.gdtvMedias.data.data.concat(action.data.data || []),
            paging: action.data.paging,
          },
          isLoading: false,
        },
      };

    case GET_GDTV_FOLDERS.FAILURE:
      return {
        ...state,
        gdtvMedias: {
          error: action.data,
          data: [],
          isLoading: false,
        },
      };

    case GET_GDTV_FOLDERS.RESET:
      return {
        ...state,
        gdtvMedias: { ...INITIAL_STATE.gdtvMedias },
      };

    case GET_CAMPAIGN_FOLDERS.REQUEST:
      return {
        ...state,
        campaignMedias: {
          ...state.campaignMedias,
          isLoading: true,
        },
      };

    case GET_CAMPAIGN_FOLDERS.SUCCESS:
      return {
        ...state,
        campaignMedias: {
          ...state.campaignMedias,
          data: {
            data: state.campaignMedias.data.data.concat(action.data.data || []),
            paging: action.data.paging,
          },
          isLoading: false,
        },
      };

    case GET_CAMPAIGN_FOLDERS.FAILURE:
      return {
        ...state,
        campaignMedias: {
          error: action.data,
          data: [],
          isLoading: false,
        },
      };

    case GET_CAMPAIGN_FOLDERS.RESET:
      return {
        ...state,
        campaignMedias: { ...INITIAL_STATE.campaignMedias },
      };

    case SET_GDTV_TYPE.SUCCESS:
      return {
        ...state,
        gdtvType: action.gdtvType,
      };
    case GET_POSTS.REQUEST:
      return {
        ...state,
        posts: {
          ...state.posts,
          isLoading: true,
        },
      };
    case GET_POSTS.SUCCESS:
      return {
        ...state,
        posts: {
          ...state.posts,
          data: {
            data: state.posts.data.data.concat(action.data.data || []),
          },
          isLoading: false,
        },
      };

    case GET_POSTS.FAILURE:
      return {
        ...state,
        posts: {
          error: action.data,
          data: [],
          isLoading: false,
        },
      };

    case GET_POSTS.RESET:
      return {
        ...state,
        posts: { ...INITIAL_STATE.posts },
      };
    case GET_ADS.REQUEST:
      return {
        ...state,
        ads: {
          ...state.ads,
          isLoading: true,
        },
      };
    case GET_ADS.SUCCESS:
      return {
        ...state,
        ads: {
          ...state.ads,
          data: {
            data: state.ads.data.data.concat(action.data.data || []),
          },
          isLoading: false,
        },
      };

    case GET_ADS.FAILURE:
      return {
        ...state,
        ads: {
          error: action.data,
          data: [],
          isLoading: false,
        },
      };

    case GET_ADS.RESET:
      return {
        ...state,
        ads: { ...INITIAL_STATE.ads },
      };

    case GET_CAMPAIGN_VIEWS.REQUEST:
      return {
        ...state,
        campaignViews: {
          ...state.campaignViews,
          isLoading: true,
        },
      };

    case GET_CAMPAIGN_VIEWS.SUCCESS:
      return {
        ...state,
        campaignViews: {
          ...state.campaignViews,
          data: {
            data: state.campaignViews.data.data.concat(action.data.data || []),
          },
          isLoading: false,
        },
      };

    case GET_CAMPAIGN_VIEWS.FAILURE:
      return {
        ...state,
        campaignViews: {
          error: action.data,
          data: [],
          isLoading: false,
        },
      };

    case GET_CAMPAIGN_VIEWS.RESET:
      return {
        ...state,
        campaignViews: { ...INITIAL_STATE.campaignViews },
      };

    case GET_GDTV_VIEWS.REQUEST:
      return {
        ...state,
        gdtvViews: {
          ...state.gdtvViews,
          isLoading: true,
        },
      };

    case GET_GDTV_VIEWS.SUCCESS:
      return {
        ...state,
        gdtvViews: {
          ...state.gdtvViews,
          data: {
            data: state.gdtvViews.data.data.concat(action.data.data || []),
          },
          isLoading: false,
        },
      };

    case GET_GDTV_VIEWS.FAILURE:
      return {
        ...state,
        gdtvViews: {
          error: action.data,
          data: [],
          isLoading: false,
        },
      };

    case GET_GDTV_VIEWS.RESET:
      return {
        ...state,
        gdtvViews: { ...INITIAL_STATE.gdtvViews },
      };

    case PUT_CAMPAIGN_STATUS.REQUEST:
      return {
        ...state,
      };

    case PUT_CAMPAIGN_STATUS.SUCCESS:
      return {
        ...state,
      };

    case PUT_CAMPAIGN_STATUS.FAILURE:
      return {
        ...state,
      };

    case POST_ADVERTS_STATUS.REQUEST:
      return {
        ...state,
      };

    case POST_ADVERTS_STATUS.SUCCESS:
      return {
        ...state,
      };

    case POST_ADVERTS_STATUS.FAILURE:
      return {
        ...state,
      };

    case POST_CAMPAIGN_CHANGE_STATUS.REQUEST:
      return {
        ...state,
      };

    case POST_CAMPAIGN_CHANGE_STATUS.SUCCESS:
      return {
        ...state,
      };

    case POST_CAMPAIGN_CHANGE_STATUS.FAILURE:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default mediasReducer;
