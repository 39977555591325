const MenuProfileMobileEN = {
  MenuProfileMobile: {
    PROFILE_SETTINGS: 'My profile settings',
    LOGOUT: 'Logout',
    USER_CONFIG: 'Users configs',
    SEARCH: 'Search',
  },
};

const MenuProfileMobilePT = {
  MenuProfileMobile: {
    PROFILE_SETTINGS: 'Minhas configurações de perfil',
    LOGOUT: 'Desconectar',
    USER_CONFIG: 'Configurações de usuários',
    SEARCH: 'Pesquisar',
  },
};

const MenuProfileMobileES = {
  MenuProfileMobile: {
    PROFILE_SETTINGS: 'Configuración de mi perfil',
    LOGOUT: 'Desconectar',
    USER_CONFIG: 'Ajustes de usuario',
    SEARCH: 'Buscar',
  },
};
export { MenuProfileMobilePT, MenuProfileMobileEN, MenuProfileMobileES };
