import axios from 'axios';
import { auth_server_url } from '@utils/oAuth';

const instanceUsers = axios.create({});

instanceUsers.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => Promise.reject(error)
);

export const getTokenApi = (params) =>
  instanceUsers.post(`${auth_server_url}/connect/token`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

export const getTenantsApi = () =>
  instanceUsers.get('https://api.gerentesdigitais.com/business/1.3/bornlogicauth/businesses', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.authservertoken,
    },
  });

export const authenticateApi = (tenant) =>
  instanceUsers.post(
    'https://api.gerentesdigitais.com/business/1.3/bornlogicauth/authenticate',
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.authservertoken,
        'X-Company': tenant,
      },
    }
  );

export const getSubDomainID = async (subDomain) => {
  const response = await instanceUsers.get(
    `https://api.gerentesdigitais.com/business/1.2/settings/public?business_sub_domain=${subDomain}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.authservertoken,
      },
    }
  );
  return response;
};
