import styled from 'styled-components';

export const Container = styled.section`
  padding: 71px 32px 33px;
  border-top: solid 1px #d9d9d9;

  p {
    color: #000000;
  }

  p:first-child {
    margin-bottom: 10px;
  }
`;
