import {
  MarkedActionsFooterEN,
  MarkedActionsFooterPT,
  MarkedActionsFooterES,
} from '../Components/MarkedActionFooter/translate';
import {
  MarkedGroupsEN,
  MarkedGroupsPT,
  MarkedGroupsES,
} from '../Components/MarkedGroups/translate';
import {
  HeaderMarkedGroupsEN,
  HeaderMarkedGroupsPT,
  HeaderMarkedGroupsES,
} from '../Components/HeaderMarkedGroups/translate';
import {
  MarkedActionsModalEN,
  MarkedActionsModalPT,
  MarkedActionsModalES,
} from '../Components/MarkedActionsModal/translate';
import { FeedCardEN, FeedCardPT, FeedCardES } from '../Components/FeedCard/translate';
import { FeedChannelsEN, FeedChannelsPT, FeedChannelsES } from '../FeedChannels/translate';
import {
  EditModalEN,
  EditModalES,
  EditModalPT,
} from 'components/MediaCards/MediaCardChunks/Type1/Footer/EditModal/translate';

const FeedEN = {
  Feed: {
    SPENT: 'Spent',
    INVESTMENT: 'Investment',
    SEE: 'See',
    ADS: 'Ads',
    POSTS: 'Posts',
    INSTAGRAM_STORIES: 'Instagram stories',
    EMPTY_CONTENT_TITLE: 'Feed: ',
    EMPTY_CONTENT_MESSAGE: 'There is no feed.',
    MARK: 'Mark',
    UNMARK: 'Unmark',
    MARCATION: 'Mark',
    CLICKS: 'Clicks',
    LINK_CLICKS: 'Link Clicks',
    IMPRESSIONS: 'Impressions',
    ENGAGEMENT: 'Engagement',
    INVOLVMENT: 'Involvment',
    REACH: 'Reach',
    NAME: 'Name',
    ACTIONS: 'Actions',
    SEE_ON_FACEBOOK: 'See on facebook',
    BUDGET: 'Budget',
    LIFETIME: 'Lifetime',
    DAILY: 'Daily',
    PERIOD: 'Period',
    STATUS: 'Status',
    CONVERSIONS_PURCHASE: 'Conversions',
    UNAVAILABLE: 'Unavailable',

    // Select
    RECENT: 'Most recent',
    SHOWING: 'Your Feed is displaying:',
    SORT: 'Sorted by:',
    RELEVANCE: 'Relevance',
    AWARD_VIDEO_VIEW_MONTH: 'Video view award this month',
    AWARD_VIDEO_VIEW_LAST_MONT: 'Video view award last month',
    AWARD_POST_ENGAGEMENT_MONTH: 'Engagement award this month',
    AWARD_POST_ENGAGEMENT_LAST_MONT: 'Engagement award last month',
    AWARD_VIDEO_VIEW_YEAR: 'Video view award this year',
    AWARD_POST_ENGAGEMENT_YEAR: 'Engagement award this year',
    paused: 'paused',
    active: 'active',
    pending_review: 'pending',
    scheduled: 'pending',
    finished: 'finished',
    rejected: 'error',
    deleted: 'inactive',
    unknown: 'unknown',
    POST_DATE: 'Post',
    active_tooltip: 'Your ad is currently active and being delivered.',
    rejected_tooltip: "Your ad isn't active because doesn't follow Facebook guidelines.",
    pending_review_tooltip: 'Your ad is being analysed or is scheduled to start another day.',
    scheduled_tooltip: 'Your ad is being analysed or is scheduled to start another day.',
    finished_tooltip: 'Your ad is finished, see their results now.',
    deleted_tooltip: 'Your ad is deactivated.',
    paused_tooltip: 'Your ad is paused.',
    unknown_tooltip: 'Unknow status.',
    SEE_MORE: ' See more.',
    SEE_INSTAGRAM: 'See on Instagram',
    SEE_PREVIEW: 'See preview',
    VIEW_FACEBOOK: 'View on Facebook',
    EDIT: 'Edit',
    AUDIENCE: 'Audicence',
    ROAS: 'ROAS',
    revenue: 'Revenue',
    ...MarkedActionsFooterEN,
    ...MarkedGroupsEN,
    ...HeaderMarkedGroupsEN,
    ...MarkedActionsModalEN,
    ...FeedCardEN,
    ...FeedChannelsEN,
    ...EditModalEN,
  },
};

const FeedPT = {
  Feed: {
    SPENT: 'Investidos',
    INVESTMENT: 'Investimento',
    SEE: 'Ver',
    ADS: 'Anúncios',
    POSTS: 'Postagens',
    INSTAGRAM_STORIES: 'Instagram stories',
    EMPTY_CONTENT_TITLE: 'Feed: ',
    EMPTY_CONTENT_MESSAGE: 'Não há nenhuma postagem.',
    MARK: 'Marcar',
    UNMARK: 'Desmarcar',
    MARCATION: 'Marcar',
    CLICKS: 'Cliques',
    LINK_CLICK: 'Cliques no Link',
    IMPRESSIONS: 'Impressões',
    ENGAGEMENT: 'Engajamento',
    INVOLVMENT: 'Envolvimento',
    REACH: 'Alcance',
    NAME: 'Nome',
    ACTIONS: 'Ações',
    SEE_ON_FACEBOOK: 'Ver no facebook',
    BUDGET: 'Orçamento',
    LIFETIME: 'Vitalício',
    DAILY: 'Diário',
    PERIOD: 'Período',
    STATUS: 'Status',
    CONVERSIONS_PURCHASE: 'Conversões',
    UNAVAILABLE: 'Não disponível',

    // Select
    RECENT: 'Mais recentes',
    SHOWING: 'Seu Feed está mostrando:',
    SORT: 'Ordenado por:',
    RELEVANCE: 'Relevância',
    AWARD_VIDEO_VIEW_MONTH: 'Premiação por visualização mês corrente',
    AWARD_VIDEO_VIEW_LAST_MONT: 'Premiação por visualizacao último mês',
    AWARD_POST_ENGAGEMENT_MONTH: 'Premiação por por engajamento mês corrente',
    AWARD_POST_ENGAGEMENT_LAST_MONT: 'Premiacao por engajamento último mês',
    AWARD_VIDEO_VIEW_YEAR: 'Premiação por visualização ano corrente',
    AWARD_POST_ENGAGEMENT_YEAR: 'Premiação por por engajamento ano corrente',
    paused: 'pausado',
    active: 'ativo',
    pending_review: 'pendente',
    scheduled: 'pendente',
    finished: 'finalizado',
    rejected: 'erro',
    deleted: 'inativo',
    unknown: 'desconhecido',
    POST_DATE: 'Postagem',
    active_tooltip: 'Seu anúncio está ativo e sendo veiculado.',
    rejected_tooltip:
      'Seu anúncio não está sendo veiculado porque não segue as diretrizes do Facebook.',
    pending_review_tooltip: 'O anúncio está em análise ou programado para veicular em outra data.',
    scheduled_tooltip: 'O anúncio está em análise ou programado para veicular em outra data.',
    finished_tooltip: 'Seu anúncio foi finalizado, veja os resultados.',
    deleted_tooltip: 'Seu anúncio está inativo.',
    paused_tooltip: 'Seu anúncio está pausado.',
    unknown_tooltip: 'Status desconhecido.',
    SEE_MORE: ' Saiba mais',
    SEE_INSTAGRAM: 'Ver no Instagram',
    SEE_PREVIEW: 'Ver Prévia',
    VIEW_FACEBOOK: 'Ver no Facebook',
    EDIT: 'Editar',
    AUDIENCE: 'Público',
    ROAS: 'ROAS',
    revenue: 'Receita',
    ...MarkedActionsFooterPT,
    ...MarkedGroupsPT,
    ...HeaderMarkedGroupsPT,
    ...MarkedActionsModalPT,
    ...FeedCardPT,
    ...FeedChannelsPT,
    ...EditModalPT,
  },
};

const FeedES = {
  Feed: {
    SPENT: 'Participadas',
    INVESTMENT: 'Inversión',
    SEE: 'Para ver',
    ADS: 'Anuncios',
    POSTS: 'Publicaciones',
    INSTAGRAM_STORIES: 'Historias de Instagram',
    EMPTY_CONTENT_TITLE: 'Feed: ',
    EMPTY_CONTENT_MESSAGE: 'No hay publicación.',
    MARK: 'Marcar',
    UNMARK: 'Marcar',
    MARCATION: 'Marcar',
    CLICKS: 'Clics',
    LINK_CLICK: 'Clics en enlaces',
    IMPRESSIONS: 'Impresiones',
    ENGAGEMENT: 'Compromiso',
    INVOLVMENT: 'Envolvimiento',
    REACH: 'Alcanzar',
    NAME: 'Nombre',
    ACTIONS: 'Comportamiento',
    SEE_ON_FACEBOOK: 'Ver en facebook',
    BUDGET: 'Presupuesto',
    LIFETIME: 'Vida útil',
    DAILY: 'Diario',
    PERIOD: 'Periodo',
    STATUS: 'Estado',
    CONVERSIONS_PURCHASE: 'Conversiones',
    UNAVAILABLE: 'Indisponible',

    // Select
    RECENT: 'Más reciente',
    SHOWING: 'Tu feed se muestra:',
    SORT: 'Ordenado por:',
    RELEVANCE: 'Relevancia',
    AWARD_VIDEO_VIEW_MONTH: 'Premio a la vista del mes actual',
    AWARD_VIDEO_VIEW_LAST_MONT: 'Premios por ver el mes pasado',
    AWARD_POST_ENGAGEMENT_MONTH: 'Recompensa por compromiso del mes actual',
    AWARD_POST_ENGAGEMENT_LAST_MONT: 'Premio al compromiso del mes pasado',
    AWARD_VIDEO_VIEW_YEAR: 'Premio a la visualización del año en curso',
    AWARD_POST_ENGAGEMENT_YEAR: 'Premio al compromiso del año en curso',
    paused: 'pausado',
    active: 'activo',
    pending_review: 'pendiente',
    scheduled: 'pendiente',
    finished: 'terminado',
    rejected: 'error',
    deleted: 'inactivo',
    unknown: 'desconocido',
    POST_DATE: 'Correo',
    active_tooltip: 'Su anuncio está activo y se está publicando.',
    rejected_tooltip: 'Su anuncio no se está publicando porque no sigue las pautas de Facebook.',
    pending_review_tooltip:
      'El anuncio está en revisión o programado para publicarse en otra fecha.',
    scheduled_tooltip: 'El anuncio está en revisión o programado para publicarse en otra fecha.',
    finished_tooltip: 'Su anuncio ha sido finalizado, vea los resultados.',
    deleted_tooltip: 'Tu anuncio está inactivo.',
    paused_tooltip: 'Su anuncio está en pausa.',
    unknown_tooltip: 'Estado desconocido.',
    SEE_MORE: ' Sepa mas',
    SEE_INSTAGRAM: 'Ver en Instagram',
    SEE_PREVIEW: 'Ver vista previa',
    VIEW_FACEBOOK: 'Ver en Facebook',
    EDIT: 'Editar',
    AUDIENCE: 'Público',
    ROAS: 'ROAS',
    revenue: 'Ingresos',
    ...MarkedActionsFooterES,
    ...MarkedGroupsES,
    ...HeaderMarkedGroupsES,
    ...MarkedActionsModalES,
    ...FeedCardES,
    ...FeedChannelsES,
    ...EditModalES,
  },
};

export { FeedPT, FeedEN, FeedES };
