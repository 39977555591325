import React, { useMemo } from 'react';
import { useTranslate } from 'react-translate';
import SelectOnTab from '@components/SelectOnTab/SelectOnTab';
import * as S from './styles';

import { useUrlHandler } from '../hooks';

import { useCapability } from '@utils/hooks/Capability';
import { verifyReachDate } from '@utils/helpers';

export default function AccountsSelect({}) {
  const t = useTranslate('Ranking');

  const { HAS_POST_INVOLVEMENT: hasPostInvolvement, HAS_CONVERSION_FLOW: hasConversionFlow } =
    useCapability(['POST_INVOLVEMENT', 'CONVERSION_FLOW']);

  const allowedMetrics = useMemo(() => {
    let metrics = ['SPEND', 'LINK_CLICK', 'IMPRESSIONS', 'POST_ENGAGEMENT'];
    if (hasPostInvolvement) metrics.push['POST_INVOLVEMENT_RATE'];
    if (hasConversionFlow) metrics.push('OFFSITE_CONVERSION.FB_PIXEL_PURCHASE');
    if (verifyReachDate()) metrics.push('REACH');
    return metrics;
  }, [hasConversionFlow, hasPostInvolvement]);

  const { value: selectedMetric, handleChangeUrlValue: handleSelectMetric } =
    useUrlHandler('sort_by');

  return (
    <SelectOnTab
      iconSelect={['fal', 'filter']}
      onChange={(e) => handleSelectMetric(e.target.value)}
      selectValue={selectedMetric}
    >
      {allowedMetrics.map((value) => (
        <S.OptionStyled key={value} value={value}>
          {t(value)}
        </S.OptionStyled>
      ))}
    </SelectOnTab>
  );
}
