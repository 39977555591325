import images from '../../images';
import type { Scope } from './types';

export const ERROR_TYPES = {
  server: {
    title: 'ERRO_LOADING_TITLE',
    description: 'ERRO_LOADING_DESCRIPTION',
    image: images.erroCarregamento,
    imageAlt: 'servers overloading',
  },
  front404: {
    title: 'ERRO_404_TITLE',
    description: 'ERRO_404_DESCRIPTION',
    image: images.erro404,
    imageAlt: 'a confused robot',
  },
  front400: {
    title: 'ERROR_400_TITLE',
    description: 'ERROR_400_DESCRIPTION',
    image: images.erro404,
    imageAlt: 'a confused robot',
  },
};

export const BUTTONS_LIST: Scope[] = [
  'global',
  'listAll',
  'ranking',
  'audience',
  'budget',
  'media',
  'ci',
];
