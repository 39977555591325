const HeaderEN = {
  Header: {
    SEARCH: 'Search',
    HELP: 'Help',
  },
};

const HeaderPT = {
  Header: {
    SEARCH: 'Procurar',
    HELP: 'Ajuda',
  },
};

const HeaderES = {
  Header: {
    SEARCH: 'Buscar',
    HELP: 'Ayuda',
  },
};

export { HeaderPT, HeaderEN, HeaderES };
