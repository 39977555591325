import * as Yup from 'yup';

export async function yupValidate({ schema, data, context = {} }) {
  const options = {
    strict: false,
    abortEarly: false,
    stripUnknown: false,
    recursive: true,
    context: context,
  };

  try {
    const isValid = await schema.validate(data, options);
    return { isValid: true, data: isValid };
  } catch (err) {
    return { isValid: false, data: err };
  }
}
