import React from 'react';
import { useTranslate } from 'react-translate';

import SelectOnTab from '@components/SelectOnTab/SelectOnTab';
import * as S from './styles';

import { useUrlHandler } from '../hooks';

const AMOUNT_OPTIONS = [5, 10, 15, 20, 30, 40, 50];

export default function SelectPoolSize() {
  const t = useTranslate('Ranking');

  const { value: selectedSize, handleChangeUrlValue: handleChangeSize } = useUrlHandler('amount');

  return (
    <SelectOnTab
      iconSelect={['fal', 'filter']}
      onChange={(e) => handleChangeSize(e.target.value)}
      selectValue={selectedSize}
      mr20
    >
      {AMOUNT_OPTIONS.map((value) => (
        <S.OptionStyled key={value} value={value}>
          {value} {t('BEST')}
        </S.OptionStyled>
      ))}
    </SelectOnTab>
  );
}
