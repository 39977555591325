import { ApiTenant } from '@utils/api';

const ReportMetricsService = {
  postReportMetric(data) {
    return ApiTenant.post('/settings/1.0/keyvalue', data);
  },

  deleteMetric(key_value) {
    return ApiTenant.delete(`/settings/1.0/keyvalue/${key_value}`);
  },
};

export default ReportMetricsService;
