const ContactUsEN = {
  ContactUs: {
    TOAST_SUCCESS: 'We recived your message successfully. We appreciate your contact!',
    TOAST_FAILURE: 'Ops! Something wrong happened with your request.',
    MAIN_TITLE: 'GD Support and Help Center',
    TALK_TO_US: 'Talk to us!',
    P_PART1: "Didn't find your question in the",
    FAQ: 'Questions and answers',
    P_PART2: 'Contact us providing maximum information possible.',
    SUBJECT: 'Subject',
    PLACEHOLDER_SUBJECT: 'Tell us how can we help you...',
    DESCRIPTION: 'Description',
    PLACEHOLDER_DESCRIPTION: 'Explain your problem or doubt with more detail...',
    SEND: 'Send request',
    REQUIRED_FIELD: 'Required field!',
  },
};
const ContactUsPT = {
  ContactUs: {
    TOAST_SUCCESS: 'Recebemos sua mensagem com sucesso. Agradecemos seu contato!',
    TOAST_FAILURE: 'Ops! Houve algum erro ao tentar realizar sua solicitação',
    MAIN_TITLE: 'Suporte GD e Central de ajuda',
    TALK_TO_US: 'Fale com a gente!',
    P_PART1: 'Não encontrou sua dúvida na seção de',
    FAQ: 'Perguntas e Respostas',
    P_PART2: 'Entre em contato fornecendo o máximo de informações possíveis.',
    SUBJECT: 'Assunto',
    PLACEHOLDER_SUBJECT: 'Diz aí como podemos te ajudar...',
    DESCRIPTION: 'Descrição',
    PLACEHOLDER_DESCRIPTION: 'Explique com detalhes a sua dúvida ou problema...',
    SEND: 'Enviar solicitação',
    REQUIRED_FIELD: 'Campo necessário!',
  },
};
const ContactUsES = {
  ContactUs: {
    TOAST_SUCCESS: 'Hemos recibido con éxito su mensaje. ¡Agradecemos su contacto! ',
    TOAST_FAILURE: '¡UPS! Se ha producido un error al intentar realizar su solicitud.',
    MAIN_TITLE: 'Centro de ayuda y soporte de GD',
    TALK_TO_US: '¡Háblanos!',
    P_PART1: 'No encontre su pregunta en la seccion',
    FAQ: 'Preguntas y respuestas',
    P_PART2: 'Póngase en contacto con la mayor cantidad de información posible.',
    SUBJECT: 'Tema',
    PLACEHOLDER_SUBJECT: 'Dime cómo podemos ayudarte...',
    DESCRIPTION: 'Descripción',
    PLACEHOLDER_DESCRIPTION: 'Por favor explique en detalle su pregunta o problema...',
    SEND: 'Enviar petición',
    REQUIRED_FIELD: '¡Campo requerido!',
  },
};

export { ContactUsEN, ContactUsPT, ContactUsES };
