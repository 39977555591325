/* Modules */
import { combineReducers } from 'redux';

/* Reducers */
import formReducer from './form/reducer';
import authenticationReducer from './auth/reducer';
import translatorReducer from './translates/reducer';
import loadingReducer from './loading/reducer';
import genericReducer from './generic/reducer';
import businessReducer from './business/reducer';
import actionsReducer from './actions/reducer';
import toastReducer from './toast/reducer';
import mediasReducer from './medias/reducer';
import reportReducer from './report/reducer';
import budgetReducer from './budget/reducer';
import easyAccessReducer from './easyaccess/reducer';
import feedReducer from './feed/reducer';
import videoViewReducer from './videoView/reducer';
import usersReducer from './users/reducer';
import surveyReducer from './survey/reducer';
import settingsReducer from './settings/reducer';
import campaignsReducer from './campaigns/reducer';

// all the reducers are in one place
const rootReducers = combineReducers({
  actionsReducer,
  authenticationReducer,
  budgetReducer,
  businessReducer,
  mediasReducer,
  feedReducer,
  formReducer,
  easyAccessReducer,
  genericReducer,
  loadingReducer,
  toastReducer,
  translatorReducer,
  reportReducer,
  videoViewReducer,
  usersReducer,
  surveyReducer,
  settingsReducer,
  campaignsReducer,
});

export default rootReducers;
