import dayjs from 'dayjs';
import BFFApi from '../utils/bffApi';

const PlannerService = {
  getEventById(id) {
    return BFFApi.get(`/api/v1/calendar/events/${id}`);
  },

  putEventById(event) {
    const { id } = event;
    delete event.id;
    const start = dayjs(event.start.toDate()).format('YYYY-MM-DDTHH:mm');
    const end = dayjs(event.end.toDate().toString()).format('YYYY-MM-DDTHH:mm');

    const formattedEvent = {
      event_type_id: event.calendarId,
      user_id: event.userId,
      title: event.title,
      description: event.description,
      start_date_time: start,
      end_date_time: end,
    };
    return BFFApi.put(`/api/v1/calendar/events/${id}`, formattedEvent);
  },

  async putEventMergeObjectiveById(event, objectiveParam) {
    const { id } = event;
    const eventResponse = await BFFApi.put(`/api/v1/calendar/events/${id}`, event);

    if (!eventResponse.data.is_success) {
      return null;
    }
    if (objectiveParam == null) {
      const objective = await BFFApi.get(`/api/v1/calendar/events/${id}/objectives/getbyeventid`);
      if (objective.data.is_success && objective.data?.payload?.id) {
        BFFApi.delete(`/api/v1/calendar/events/${objective.data?.payload?.id}/objectives`);
      }
      return [eventResponse.data, null];
    }

    let objectiveResponse = null;
    const objective = await BFFApi.get(`/api/v1/calendar/events/${id}/objectives/getbyeventid`);

    if (objective.data.is_success && objective.data?.payload?.id) {
      objectiveResponse = await BFFApi.put(
        `/api/v1/calendar/events/${objective.data?.payload?.id}/objectives`,
        {
          ...objectiveParam,
          event_id: id,
        }
      );
    } else {
      objectiveResponse = await BFFApi.post(`/api/v1/calendar/events/objectives`, {
        ...objectiveParam,
        event_id: id,
      });
    }

    return [eventResponse, objectiveResponse];
  },

  async deleteEventById(id) {
    const deleted = await BFFApi.delete(`/api/v1/calendar/events/${id}`);

    if (deleted.data.is_success) {
      const objective = await BFFApi.get(`/api/v1/calendar/events/${id}/objectives/getbyeventid`);
      if (objective.data.is_success && objective.data?.payload?.id) {
        BFFApi.delete(`/api/v1/calendar/events/${objective.data?.payload?.id}/objectives`);
      }
    }

    return deleted;
  },

  getAllEvents(queryParameters) {
    const { since, until } = queryParameters;
    const periodQuery = `datepreset.since=${since}&datepreset.until=${until}`;
    return BFFApi.get(`/api/v1/calendar/events?${periodQuery}&limit=100`);
  },

  async postEventMergeObjective(event, objective) {
    const eventResponse = await BFFApi.post(`/api/v1/calendar/events`, event);

    if (!eventResponse.data.is_success) {
      return null;
    }
    if (objective == null) {
      return [eventResponse.data, null];
    }

    const objectiveResponse = await BFFApi.post(`/api/v1/calendar/events/objectives`, {
      ...objective,
      event_id: eventResponse.data.payload.id,
    });

    if (!objectiveResponse.data.is_success) {
      await BFFApi.delete(`/api/v1/calendar/events/${eventResponse.data.payload.id}`);
      return null;
    }

    return [eventResponse.data, objectiveResponse.data];
  },

  getAllEventsTypes() {
    return BFFApi.get('/api/v1/calendar/events/types');
  },

  getEventTypeById(id) {
    return BFFApi.get(`/api/v1/calendar/events/${id}/types`);
  },

  putEventTypeById(data) {
    const { id } = data;
    delete data.id;
    return BFFApi.put(`/api/v1/calendar/events/${id}/types`, data);
  },

  deleteEventTypeById(data) {
    const { id } = data;
    return BFFApi.delete(`/api/v1/calendar/events/${id}/types`);
  },

  postEventType(data) {
    return BFFApi.post(`/api/v1/calendar/events/types`, data);
  },

  getObjectiveByEventId(id) {
    return BFFApi.get(`/api/v1/calendar/events/${id}/objectives/getbyeventid`);
  },

  deleteObjectiveByEventId(id) {
    return BFFApi.delete(`/api/v1/calendar/events/${id}/objectives`);
  },

  putEventObjectiveById(data) {
    const { id } = data;
    delete data.id;
    return BFFApi.put(`/api/v1/calendar/events/${id}/objectives`, data);
  },

  postEventObjective(data) {
    return BFFApi.post(`/api/v1/calendar/events/objectives`, data);
  },

  getEventObjectiveById(id) {
    return BFFApi.get(`/api/v1/calendar/events/${id}/objectives/getbyeventid`);
  },

  postChatbot(data) {
    return BFFApi.post(`/api/v1/chatbot`, data);
  },

  deleteChatbotById(id){
    return BFFApi.delete(`/api/v1/chatbot/${id}`);
  }

};

export default PlannerService;
