import styled, { css } from 'styled-components';
import CurrencyInput from 'react-currency-input';
import { ButtonWithMixPanel } from 'components/MixPanel';

export const Wrapper = styled.div`
  display: flex;
  height: auto;

  ${({ theme, hasError }) => css`
    min-height: ${theme.spacing.px100};

    .react-datepicker-wrapper {
      width: 100px;

      & > div {
        & > input {
          width: ${theme.spacing.px90};
          height: ${theme.spacing.px35};
          border: none;
          background: ${theme.colors.blueGrey6} 0% 0% no-repeat padding-box;
          border-radius: ${theme.rounded.px5};
          color: ${hasError ? theme.colors.errorRed : theme.colors.blue2};
          font-size: ${theme.fontSize.px14};
          text-align: center;
          cursor: pointer;
        }
        *:focus {
          outline: none;
        }

        & > input:disabled {
          cursor: not-allowed;
        }
      }
    }
  `}
`;

export const Period = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50%;

  ${({ theme }) => css`
    border-right: 0.5px solid ${theme.colors.blueGrey5};
  `}
`;

export const PeriodText = styled.div`
  top: 0;
  position: absolute;

  ${({ theme }) => css`
    color: 0.5px solid ${theme.colors.blueGrey2};
    font-size: ${theme.fontSize.px14};
  `}
`;

export const Budget = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  position: relative;
`;

export const CustomInput = styled(CurrencyInput)`
  display: block;
  outline: none;
  text-align: center;
  border: none;

  ${({ theme }) => css`
    border-radius: ${theme.rounded.px5};
    background: ${theme.colors.blueGrey6} 0% 0% no-repeat padding-box;
    height: ${theme.spacing.px35};
    width: ${theme.spacing.full};
    font-size: ${theme.fontSize.px14};
    color: ${theme.colors.successfulGreen2};
  `}
`;

export const LinkWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.px8};
  `}
`;

export const LinkLabel = styled.div`
  ${({ theme }) => css`
    color: 0.5px solid ${theme.colors.blueGrey2};
    font-size: ${theme.fontSize.px14};
    margin-bottom: ${theme.spacing.px8};
  `}
`;

export const OptionalLabel = styled.div`
  text-align: right;

  ${({ theme }) => css`
    color: 0.5px solid ${theme.colors.blueGrey2};
    font-size: ${theme.fontSize.px12};
    margin-top: ${theme.spacing.px8};
  `}
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 1;
  opacity: 0.75;
  left: 0;
  top: 0;

  ${({ theme }) => css`
    height: ${theme.spacing.full};
    width: ${theme.spacing.full};
    background: ${theme.colors.white};
  `}
`;

export const Title = styled.div`
  text-align: left;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.px10};
    font-size: ${theme.fontSize.px16};
    color: ${theme.colors.red7};
    font-weight: ${theme.fontWeight.bold};
  `}
`;

export const Subtitle = styled.div`
  text-align: left;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.px40};
    font-size: ${theme.fontSize.px12};
    color: ${theme.colors.blueGrey7};
  `}
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: end;
  position: absolute;
  bottom: 0;
  right: 0px;
  left: 0px;

  ${({ theme }) => css`
    border-top: 0.5px solid ${theme.colors.blueGrey5};
    height: ${theme.spacing.px90};
  `}
`;

export const SaveApproveButton = styled(ButtonWithMixPanel)`
  button {
    width: 136px;
    text-align: center;
    cursor: pointer;

    ${({ theme }) => css`
      color: ${theme.colors.white};
      background-color: ${theme.colors.blueSecurity};
      border-radius: ${theme.rounded.px10};
      font-size: ${theme.fontSize.px14};
      height: ${theme.spacing.px40};
      margin-top: ${theme.spacing.px25};
      margin-bottom: ${theme.spacing.px25};
      margin-right: ${theme.spacing.px32};
    `}
  }
`;

export const CancelButton = styled.div`
  width: 136px;
  text-align: center;
  cursor: pointer;

  ${({ theme }) => css`
    color: ${theme.colors.blueGrey3};
    font-size: ${theme.fontSize.px14};
    height: ${theme.spacing.px40};
    line-height: ${theme.spacing.px40};
    margin-top: ${theme.spacing.px25};
    margin-bottom: ${theme.spacing.px25};
  `}
`;

export const SwitchWrapper = styled.div`
  position: absolute;

  ${({ theme }) => css`
    right: ${theme.spacing.px32};
    top: ${theme.spacing.px35};
  `}
`;

export const SwitchText = styled.div`
  position: absolute;

  ${({ theme }) => css`
    right: ${theme.spacing.px35};
    width: ${theme.spacing.px70};
    font-size: ${theme.fontSize.px10};
    color: ${theme.colors.blueSecurity};
  `}
`;

export const DateText = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.blueGrey4};
    font-size: ${theme.fontSize.px11};
    margin-right: ${theme.spacing.px6};
    margin-left: ${theme.spacing.px10};
  `}
`;
