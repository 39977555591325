/* Modules */
import { call, put, takeEvery } from 'redux-saga/effects';
import { map } from 'lodash';

import { FeedService, SchemaService } from '@services';

// Utils
import { validateCapability, decodeToken } from '@utils/helpers';

/* Types */
import { SHOW_TOAST } from '@redux/toast/types';
import {
  FETCH_FEED,
  FETCH_FEED_PREVIEWS,
  UPDATE_AD,
  FETCH_FEED_STORIES,
  FETCH_INSTAGRAM_POSTS,
} from './types';

function* getFeed(action) {
  const token = window.localStorage.getItem('business-token');
  const businessTokenData = decodeToken(token);

  let urlType;
  let orderType = '';

  if (!action.preset) {
    action.preset = 'this_month';
  }

  if (action.feedtype === 'AD') {
    urlType = 'ads';

    if (!action.preset.includes('date_preset') && !action.preset.includes('since')) {
      action.preset = `date_preset=${action.preset}`;
    }
    if (action.orderBy === 'relevance') {
      orderType = '/relevance/v1?';
    } else if (action.orderBy === 'video_view' || action.orderBy === 'post_engagement') {
      orderType = `/relevance/v2?algorithm_metric=${action.orderBy}&${action.preset}`;
    } else {
      orderType = `?${action.preset}`;
    }
  } else {
    urlType = 'fanpageposts?';
  }

  const list = yield call(
    FeedService.getFeeds,
    action.code,
    urlType,
    orderType,
    action.next,
    action.limit
  );
  let reach = {
    data: [],
  };

  if (list.status === 200 && list.data && list.data.data && list.data.data.length) {
    const newData = [];
    const ids = map(list.data.data, 'id').map((item) => `"${item}"`);
    const result =
      action.feedtype === 'AD'
        ? yield call(
            FeedService.getAds,
            '1.1',
            action.orderBy !== 'relevance' ? action.preset : null,
            ids,
            'currency,spend,impressions,clicks,post_engagement,video_view,link_click,offsite_conversion.fb_pixel_purchase'
          )
        : yield call(
            FeedService.getPosts,
            '1.1',
            ids,
            'currency,spend,impressions,clicks,post_engagement,video_view,offsite_conversion.fb_pixel_purchase'
          );

    newData.push(...list.data.data);

    result.data.forEach((item) => {
      if (newData.find((x) => x.id === item.source_id)) {
        newData.find((x) => x.id === item.source_id).insights = item;
      }
    });

    if (result.status === 200 && validateCapability(businessTokenData, 'REACH')) {
      // Reach rules
      const obj = { ads: [] };
      const customIds = map(list.data.data, 'id');
      customIds.forEach((item) => obj.ads.push({ id: item }));
      reach = yield call(FeedService.getAdReach, obj);

      if (reach.status === 200) {
        reach.data.data.forEach((itemReach) => {
          const sameIdData = newData.find((x) => x.id === itemReach.source_id);
          if (sameIdData) {
            newData.find((x) => x.id === itemReach.source_id).reach = itemReach.reach
              ? itemReach.reach
              : '0';
          } else if (itemReach.reach === '0') {
            newData.find((x) => x.id !== itemReach.source_id).reach = '0';
          }
        });
        yield put({ type: FETCH_FEED.SUCCESS, data: newData, paging: list.data.paging || {} });
      } else {
        yield put({ type: FETCH_FEED.SUCCESS, data: newData, paging: list.data.paging || {} });
      }
    } else {
      yield put({ type: FETCH_FEED.SUCCESS, data: newData, paging: list.data.paging || {} });
    }
  } else {
    yield put({ type: FETCH_FEED.FAILURE });
  }
}

function* getFeedPreviews(action) {
  const response = yield call(
    FeedService.getItemPreviews,
    action.version,
    action.id,
    action.fields,
    action.feedType,
    action.assetIds
  );

  if (response.status === 200) {
    yield put({ type: FETCH_FEED_PREVIEWS.SUCCESS, previews: response.data.data });
  } else if (response.status === 400) {
    yield put({ type: FETCH_FEED_PREVIEWS.FAILURE, previews: response.data.data });
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message:
        'A prévia de seu conteúdo não pôde ser gerada, verifique se a página tem conta vinculada ou já permitiu o acesso.',
      messageType: 'error',
    });
  } else {
    yield put({ type: FETCH_FEED_PREVIEWS.FAILURE, previews: response.data.data });
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: 'A prévia de seu conteúdo não pôde ser gerada.',
      messageType: 'error',
    });
  }
}

function* getFeedStories(action) {
  const response = yield call(FeedService.getInstagramStories, action.data);
  if (response.status === 200) {
    yield put({
      type: FETCH_FEED.SUCCESS,
      data: response.data.data || [],
      paging: { next: response.data.cursor } || {},
    });
  }
}

function* getInstagramPosts(action) {
  const response = yield call(FeedService.getInstagramPosts, action.data);
  if (response.status === 200) {
    yield put({
      type: FETCH_FEED.SUCCESS,
      data: response.data.data || [],
      paging: { next: response.data.cursor } || {},
    });
  }
}

function* updateAd(action) {
  try {
    const response = yield call(
      SchemaService.updateAd,
      action.version,
      action.code,
      action.adId,
      action.body
    );
    if (response.status === 200) {
      let error = false;
      if (response.data.adset_result && response.data.adset_result.error_code !== undefined) {
        yield put({
          type: SHOW_TOAST.SUCCESS,
          message: response.data.adset_result.user_error_message,
          messageType: 'error',
        });

        error = true;
      }

      if (response.data.campaign_result && response.data.campaign_result.error_code !== undefined) {
        yield put({
          type: SHOW_TOAST.SUCCESS,
          message: response.data.campaign_result.user_error_message,
          messageType: 'error',
        });

        error = true;
      }

      if (response.data.ad_result && response.data.ad_result.error_code !== undefined) {
        yield put({
          type: SHOW_TOAST.SUCCESS,
          message: response.data.ad_result.user_error_message,
          messageType: 'error',
        });

        error = true;
      }

      if (!error) {
        yield put({
          type: SHOW_TOAST.SUCCESS,
          message: 'O anúncio foi editado corretamente.',
          messageType: 'success',
        });
      }

      yield put({ type: UPDATE_AD.SUCCESS, data: response.data });

      // yield put({ type: UPDATE_AD.SUCCESS, data: response.data });
      // yield put({ type: SHOW_TOAST.SUCCESS, message: 'us guri eh brabo pai', messageType: 'success' });
    } else {
      yield put({
        type: SHOW_TOAST.SUCCESS,
        message: response.data.error,
        messageType: 'error',
      });
      yield put({ type: UPDATE_AD.FAILURE, data: response.data });
    }
  } catch (e) {
    yield put({ type: UPDATE_AD.FAILURE, data: e.response });
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
  }
}

export const feedSaga = [
  takeEvery(FETCH_FEED.REQUEST, getFeed),
  takeEvery(FETCH_FEED_PREVIEWS.REQUEST, getFeedPreviews),
  takeEvery(FETCH_FEED_STORIES.REQUEST, getFeedStories),
  takeEvery(FETCH_INSTAGRAM_POSTS.REQUEST, getInstagramPosts),
  takeEvery(UPDATE_AD.REQUEST, updateAd),
];
