import React, { useState } from 'react';

import { useLocation, useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslate } from 'react-translate';

import { useCapability } from 'utils/hooks/Capability';

import * as S from './styles';
import { useValidatePermission } from './hooks';
import { handleRoutes } from './utils';
import type { MainMenuReducers, Router } from './types';

function MainMenu() {
  const [labelActionsMenu, setLabelActionsMenu] = useState('Medias');

  const { HAS_GDTV_MANAGE: hasGdtvManage, HAS_MEDIA_APPROVAL: hasMediaApproval } = useCapability([
    'GDTV_MANAGE',
    'MEDIA_APPROVAL',
  ]);

  const scopes = useValidatePermission({
    labelActionsMenu,
    setLabelActionsMenu,
  });

  const t = useTranslate('MainMenu');
  const match = useRouteMatch<Router>();
  const { pathname, search } = useLocation();

  const authenticationReducer = useSelector(
    (state: MainMenuReducers) => state.authenticationReducer
  );

  function renderOptions() {
    if (!scopes) {
      return null;
    }

    return scopes.map((scope) => (
      <S.MainMenuItem
        key={scope}
        currentTitle={scope}
        active={pathname}
        isBornlogic={authenticationReducer.subDomain?.sub_domain === 'bornlogic'}
      >
        <S.MainMenuLink
          to={{
            pathname: handleRoutes({
              hasGDTVManageCapability: hasGdtvManage,
              scope,
              code: match.params.code || '',
              hasMediaApprovalCapability: hasMediaApproval,
              labelActionsMenu,
            }),
            search,
          }}
        >
          <h6>{scope === 'Medias' ? t('Medias') : t(scope)}</h6>
        </S.MainMenuLink>
      </S.MainMenuItem>
    ));
  }

  return (
    <S.MainMenuWrapper>
      <S.MainMenuList>{renderOptions()}</S.MainMenuList>
    </S.MainMenuWrapper>
  );
}

export default MainMenu;
