/* Create action types */
import createActionTypes from '../createActionTypes';

export const FETCH_FEED = createActionTypes('FETCH_FEED');
export const FETCH_FEED_PREVIEWS = createActionTypes('FETCH_FEED_PREVIEWS');
export const SET_FEED_TYPE = createActionTypes('SET_FEED_TYPE');
export const SET_FEED_ORDER_BY = createActionTypes('SET_FEED_ORDER_BY');
export const SET_FEED_PRESET = createActionTypes('SET_FEED_PRESET');
export const FETCH_FEED_STORIES = createActionTypes('FETCH_FEED_STORIES');
export const FETCH_INSTAGRAM_POSTS = createActionTypes('FETCH_INSTAGRAM_POSTS');
export const UPDATE_AD = createActionTypes('UPDATE_AD');
export const FILTER_CHANNEL = createActionTypes('FILTER_CHANNEL');
