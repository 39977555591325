import parse from 'html-react-parser';
import numeral from 'numeral';
import * as jwtDecode from 'jwt-decode';
import { cnpj } from 'cpf-cnpj-validator';
import { COMMA_CHAR, HASHTAG_CHAR, SPACE_CHAR, UNDERLINE_CHAR } from '@utils/constants';
import { isString } from 'lodash';
import dayjs from 'dayjs';
import { store } from '../providers';
import { CompanyService } from '../services';
import { americanasTenants, viaVarejoTenants } from 'utils/constants';

export const MASK_TYPE = {
  CURRENCY: 'CURRENCY',
  PERCENT: 'PERCENT',
  ONE_DIGIT_PERCENT: 'ONE_DIGIT_PERCENT',
  ABSOLUTE: 'ABSOLUTE',
  POINT_ABSOLUTE: 'POINT_ABSOLUTE',
  TWO_DIGIT_PERCENT: 'TWO_DIGIT_PERCENT',
};

const maskValue = (item, type) => {
  item = item == undefined || isNaN(item) ? 0 : item;

  if (type) {
    switch (type.toUpperCase()) {
      case MASK_TYPE.CURRENCY:
        return item.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

      case MASK_TYPE.PERCENT:
        return item.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 });

      case MASK_TYPE.ABSOLUTE:
        return numeral(item).format('0,0');

      case MASK_TYPE.POINT_ABSOLUTE:
        return numeral(item).format('0.00');

      case MASK_TYPE.ONE_DIGIT_PERCENT:
        return `${numeral(item).format('0,0')}%`;

      case MASK_TYPE.TWO_DIGIT_PERCENT:
        return `${numeral(item).format('0.00')}%`;

      default:
        return item;
    }
  }
  return item;
};

const createDataForTableMetrics = (value, originalValue, noTitle = false) => {
  return {
    value,
    originalValue,
    columnTitleColor: 'purple',
    noTitle,
    noDivider: true,
    noSort: false,
  };
};
const formatCurrencyLanguageAndDots = (value) => {
  let newValue = value.split('R$').join('').split(',').join('').split('.').join('').trim();
  newValue = `${newValue.substring(0, newValue.length - 2)}.${newValue.substring(
    newValue.length - 2,
    newValue.length
  )}`; // Adiciona somente o ponto dos centavos

  return +newValue;
};

const getTimezone = () => {
  const date = new Date();
  const timezone = (date.getTimezoneOffset() / 60) * -1;
  return timezone;
};

const verifyReachDate = () => {
  const {
    authenticationReducer: { businessTokenData },
    genericReducer: { period },
  } = store.getState();

  const start = dayjs(period.start).startOf('month').$d;
  const end = dayjs(period.end).endOf('month').$d;

  const startMonth = dayjs(start).format('YYYY-MM-DD');
  const endMonth = dayjs(end).format('YYYY-MM-DD');
  const today = dayjs().endOf('day').format('YYYY-MM-DD');

  let date = 0;

  if (validateCapability(businessTokenData, 'REACH')) {
    if (period.start === startMonth && (period.end === today || period.end === endMonth)) {
      date = dayjs(start).format('YYYY-MM');
    }
    if (period.datePreset === 'LAST_7D' || period.datePreset === 'THIS_YEAR') {
      date = period.datePreset;
    }
  }
  return date;
};

const validateUserInfoCapability = (businessTokenData) =>
  // Verifica se o usuário possui a capability USER_INFO
  validateCapability(businessTokenData, 'USER_INFO');

const validateCapability = (businessTokenData, capability) => {
  // Bypass temporário da dashoard
  if (capability === 'DASHBOARD') return true;
  if (!hasCapabilities(businessTokenData)) {
    return false;
  }

  if ('bgd_capabilities' in businessTokenData) {
    return businessTokenData.bgd_capabilities.find((item) => item.name === capability);
  }
  if ('bgd_capabilities_v2' in businessTokenData) {
    return capability in businessTokenData.bgd_capabilities_v2;
  }

  return false;
};

const hasCapabilities = (businessTokenData) =>
  businessTokenData &&
  ('bgd_capabilities' in businessTokenData || 'bgd_capabilities_v2' in businessTokenData);

const handleValueTooBig = (val) => {
  // Transforma 15654 em 15.6k, 15000 em 15k, 15123456 em 15.1M, 15000000 em 15M
  let newVal = 0;

  if (val) {
    newVal = val.toString();
    if (newVal > 1000 && newVal < 1000000) {
      newVal = mountNewNumber(newVal, 2, 'k');
    } else if (newVal >= 1000000) {
      newVal = mountNewNumber(newVal, 5, 'M');
    }
  }
  return newVal;
};

const handleSizeTooBig = (val) => {
  // Transforma 15654 em 15.6k, 15000 em 15k, 15123456 em 15.1M, 15000000 em 15M
  let newVal = 0;
  if (val) {
    newVal = val.toString();

    if (newVal < 1048576) {
      newVal = mountNewNumber(newVal, 2, 'KB');
    } else if (newVal >= 1048576 && newVal < 1073741824) {
      newVal = mountNewNumber(newVal, 5, 'MB');
    } else if (newVal >= 1073741824) {
      newVal = mountNewNumber(newVal, 5, 'GB');
    }
  }
  return newVal;
};

const mountNewNumber = (val, numbersToRemove, endLetter) => {
  val = val.substring(0, val.length - numbersToRemove);
  if (val.substring(val.length - 1) === '0') {
    val = val.substring(0, val.length - 1) + endLetter;
  } else {
    val = `${val.substring(0, val.length - 1)}.${val.substring(val.length - 1)}${endLetter}`;
  }
  return val;
};

const LightenDarkenColor = (col, amt) => {
  let usePound = false;

  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);

  let r = (num >> 16) + amt;

  if (r > 255) {
    r = 255;
  } else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) {
    b = 255;
  } else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;

  if (g > 255) {
    g = 255;
  } else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

const setNavigator = () => {
  let isFirefox;
  let isSafari;
  let isIE;
  let isEdge;
  let isChrome = false;

  isFirefox = typeof InstallTrigger !== 'undefined';
  isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === '[object SafariRemoteNotification]';
    })(!window.safari);
  isIE = /* @cc_on!@ */ false || !!document.documentMode;
  isEdge = !isIE && !!window.StyleMedia;
  isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

  if (isFirefox === true) return 'Firefox';
  if (isSafari === true) return 'Safari';
  if (isIE === true) return 'explorer';
  if (isEdge === true) return 'edge';
  if (isChrome === true) return 'chrome';
  return undefined;
};

const decodeToken = (token) => {
  let decoded = jwtDecode(token);

  if (decoded && decoded.bgd_capabilities) {
    decoded = {
      ...decoded,
      bgd_capabilities: JSON.parse(decoded.bgd_capabilities),
    };
  } else if (decoded && decoded.bgd_capabilities_v2) {
    const obj = decoded.bgd_capabilities_v2.split(' ').reduce((acc, cur) => {
      const temp = cur.split(':');
      acc[temp[0]] = temp[1];
      return acc;
    }, {});

    decoded = {
      ...decoded,
      bgd_capabilities_v2: obj,
    };
  }
  return decoded;
};

const groupColors = [
  '#765a50',
  '#b51e42',
  '#b926be',
  '#561ea5',
  '#2ebdf2',
  '#00967e',
  '#efa53a',
  '#f36a2d',
  '#587583',
  '#010101',
];
const permitedVideos =
  '.3g2, .3gp, .3gpp, .asf, .avi, .dat, .divx, .dv, .f4v, .flv, .gif, .m2ts, .m4v, .mkv, .mod, .mov, .mp4, .mpe, .mpeg, .mpeg4, .mpg, .mts, .nsv, .ogm, .ogv, .qt, .tod, .ts, .vob, .wmv';
const permitedImages =
  ' .bmp, .dib, .gif, .heic, .heif, .iff, .jfif, .jp2, .jpe, .jpeg, .jpg, .png, .psd, .tif, .tiff, .wbmp, .webp, .xbm';

export {
  maskValue,
  createDataForTableMetrics,
  formatCurrencyLanguageAndDots,
  getTimezone,
  verifyReachDate,
  validateUserInfoCapability,
  handleValueTooBig,
  setNavigator,
  groupColors,
  permitedVideos,
  permitedImages,
  handleSizeTooBig,
  LightenDarkenColor,
  validateCapability,
  hasCapabilities,
  decodeToken,
};

export const GDScoreArray = ['bornlogic'];

export const MediaLibraryArray = ['bornlogic'];

const offlineInitiateCheckoutArrayStatic = ['viavarejo'];

export function isValidCNPJ(data) {
  return cnpj.isValid(data);
}

export function formatCNPJ(data) {
  return cnpj.format(data);
}

export const offlineInitiateCheckoutArray = () => offlineInitiateCheckoutArrayStatic;

export const renderHTML = (html) => parse(html);

export const newTradeCI = ['americanas', 'lasa', 'bornlogic'];

export function getHiperlink({
  subDomain = '',
  communicationMean = '',
  id = '',
  isTradeFeed = '',
  postUrl = '',
}) {
  if (isTradeFeed) {
    return postUrl;
  }

  return `https://${subDomain}.gerentesdigitais.com/${communicationMean}?id=${id}`;
}

export const showStringByCharPattern = (text, characterPattern = COMMA_CHAR) => {
  if (!text) {
    return '';
  }

  const stringPattern = {
    [HASHTAG_CHAR]: text.split(HASHTAG_CHAR).reduce((accumulator, value, idx) => {
      const text = removeExtraSpaces(value);
      const hashtag = `${HASHTAG_CHAR}${text.replace(/\s/g, UNDERLINE_CHAR)}`;
      return !idx ? text : `${getStringPattern(accumulator)}${hashtag}`;
    }, ''),
    [COMMA_CHAR]: text.split(COMMA_CHAR).reduce((accumulator, value, idx) => {
      const text = removeExtraSpaces(value);
      return !idx ? text : `${getStringPattern(accumulator)}${text}`;
    }, ''),
  };

  return stringPattern[characterPattern];
};

const removeExtraSpaces = (text) => {
  if (!text) {
    return '';
  }
  return text.replace(/\s+/g, SPACE_CHAR).trim();
};

const getStringPattern = (text) => {
  if (!text) {
    return '';
  }
  return `${text}${COMMA_CHAR}${SPACE_CHAR}`;
};

export const formatCurrencyToBRL = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export const viewWindowModes = [
  {
    value: '7d_click,7d_view',
    label: 'A opção 7 dias clique 7 dias view não existe mais. Por favor atualize.',
  },
  { value: '7d_click,1d_view', label: '7 dias clique e 1 dia visualização' },
  { value: '1d_click,1d_view', label: '1 dia clique e 1 dia visualização' },
  { value: '1d_click', label: '1 dia clique' },
  { value: '7d_click', label: '7 dia clique' },
];

export const getAttributionWindows = async (type = 'url') => {
  const companyDetails = await CompanyService.getCompany();
  if (companyDetails?.data?.is_success) {
    const viewWindow = companyDetails?.data?.payload?.view_window?.split(',') || '';
    if (type === 'url') {
      if (viewWindow?.length === 1) {
        return `&attribution_windows=['${viewWindow[0]}']`;
      } else if (viewWindow?.length === 2) {
        return `&attribution_windows=['${viewWindow[0]}','${viewWindow[1]}']`;
      }
      return '';
    }

    return viewWindow;
  }
  return '';
};

export const companyMainMetrics = [
  'Reconhecimento',
  'Engajamento',
  'Tráfego',
  'Cadastro',
  'Conversas iniciadas',
  'Conversões',
  'Cliques no link',
];

export const checkIfIsTradeView = () => {
  if (process.env.NODE_ENV === 'development') {
    return (
      process.env.REACT_APP_TENANT.includes('trade') ||
      process.env.REACT_APP_TENANT.includes('marketplace-trade')
    );
  }
  return (
    window.location.href.includes('trade') || window.location.href.includes('marketplace-trade')
  );
};

export const isMarketplaceTrade = () => {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_TENANT.includes('marketplace-trade');
  }
  return window.location.href.includes('marketplace-trade');
};

export const checkIfIsTradeExperiment = () => {
  const tenantsFromExperiment = [
    'cea',
    'polishop',
    'lojascolombo',
    'mobly',
    'imperatriz',
    'savegnago',
    'csdsupermercados',
    'havan',
    'fastshop',
    'renner',
    'extra',
    'paodeacucar',
    'magazineluiza',
    'comprebem',
  ];

  if (process.env.NODE_ENV === 'development') {
    const actualTenantIsFromExperiment = tenantsFromExperiment.some((tenant) =>
      process.env.REACT_APP_TENANT.includes(tenant)
    );
    return actualTenantIsFromExperiment;
  }

  const actualTenantIsFromExperiment = tenantsFromExperiment.some((tenant) =>
    window.location.href.includes(tenant)
  );
  return actualTenantIsFromExperiment;
};

export const checkIfIsCITradeExperiment = () => {
  const tenantsFromCIExperiment = [
    'cea',
    'polishop',
    'lojascolombo',
    'mobly',
    'imperatriz',
    'savegnago',
    'csdsupermercados',
  ];

  if (process.env.NODE_ENV === 'development') {
    const actualTenantIsFromCIExperiment = tenantsFromCIExperiment.some((tenant) =>
      process.env.REACT_APP_TENANT.includes(tenant)
    );
    return actualTenantIsFromCIExperiment;
  }

  const actualTenantIsFromCIExperiment = tenantsFromCIExperiment.some((tenant) =>
    window.location.href.includes(tenant)
  );
  return actualTenantIsFromCIExperiment;
};

export const turnToLowercase = (text) => {
  if (isString(text)) {
    return text.toLowerCase();
  }
  return null;
};

export function haveIntersectionBetweenTwoArrays(arrayA, arrayB) {
  return arrayA.some((arrayAChunk) => arrayB.includes(arrayAChunk));
}

export const getLocationKey = (location) =>
  turnToLowercase(location) === 'region' ? 'state' : 'city';

export const getCategoryKey = (category) =>
  turnToLowercase(category) === 'categories' ? 'category' : 'word';

export const shouldRenderRoasTrade = () => {
  const tenantsToShow = [
    'trade-magalu',
    'trade-viavarejo',
    'trade-polishop',
    'trade-fastshop',
    'trade-havan',
    'trade-centauro',
    'trade-cea',
    'trade-zema',
  ];

  if (process.env.NODE_ENV === 'development') {
    const actualTenantIncludeRoas = tenantsToShow.some((tenant) =>
      process.env.REACT_APP_TENANT.includes(tenant)
    );
    return actualTenantIncludeRoas;
  }

  const actualTenantIncludeRoas = tenantsToShow.some((tenant) =>
    window.location.href.includes(tenant)
  );
  return actualTenantIncludeRoas;
};

export const shouldRenderRoasFeed = () => {
  const tenantsToShow = ['soma'];

  if (process.env.NODE_ENV === 'development') {
    const actualTenantIncludeRoas = tenantsToShow.some((tenant) =>
      process.env.REACT_APP_TENANT.includes(tenant)
    );
    return actualTenantIncludeRoas;
  }

  const actualTenantIncludeRoas = tenantsToShow.some((tenant) =>
    window.location.href.includes(tenant)
  );
  return actualTenantIncludeRoas;
};

export const isTenantBornlogic = () => {
  return (
    window.location.href.includes('trade-bornlogic') ||
    (process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_TENANT.includes('trade-bornlogic'))
  );
};

export const shouldRenderZendeskMessenger = (pathname) => {
  const notRenderIn = ['/Planner', '/Trade/Dashboard'];
  return !notRenderIn.some((item) => pathname.includes(item));
};

export const getFormattedPeriod = (period) => {
  const numberOfDays = dayjs(period.end).diff(period.start, 'days');
  const previousEnd = dayjs(period.start).subtract(1, 'days').format('YYYY-MM-DD');
  const previousStart = dayjs(previousEnd).subtract(numberOfDays, 'days').format('YYYY-MM-DD');
  const dmPreviousEnd = dayjs(previousEnd).format('DD/MM');
  const dmPreviousStart = dayjs(previousStart).format('DD/MM');
  const dmEnd = dayjs(period.end).format('DD/MM');
  const dmStart = dayjs(period.start).format('DD/MM');

  return {
    previousEnd,
    previousStart,
    dmPreviousEnd,
    dmPreviousStart,
    dmEnd,
    dmStart,
  };
};

export const convertToCSV = (arr) => {
  const array = [Object.keys(arr[0])].concat(arr);

  return array
    .map((it) => {
      return Object.values(it).toString();
    })
    .join('\n');
};

export const downloadFile = ({ data, fileName, fileType }) => {
  const blob = new Blob([data], { type: fileType });

  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  window.URL.revokeObjectURL(blob);
};

export const setAttributionWindows = (domain) => {
  if (
    [
      'viavarejo',
      'magazineluiza',
      'lojaslebes',
      'consorcio-magalu',
      'disantinni',
      'zema',
      'operadoraoi',
      'oi',
      'mueblesjamar',
      'youcom',
      'inbrands',
      'rihappy',
      'aliansce',
      'uzashoes',
      'ashua',
    ].includes(domain)
  ) {
    return "&attribution_windows=['7d_click','7d_view']";
  }
};

export const getTenant = () => {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_TENANT;
  }
  return window.location.host.split('.')[0];
};

export function unileverPOC(searchValue = '', byEmail = false) {
  const tenants = ['soltaofreio'];
  const emails = [''];

  if (byEmail) {
    return emails.includes(searchValue);
  }

  if (process.env.NODE_ENV === 'development') {
    return tenants.some((tenant) => process.env.REACT_APP_TENANT.includes(tenant));
  }

  return tenants.some((tenant) => window.location.href.includes(tenant));
}

export function isAbleToForceBudget(tenant = '', userMail = '') {
  if (!tenant || !userMail) return false;

  const allowedTenants = [
    ...americanasTenants,
    ...viaVarejoTenants,
    'havan',
    'vemconveniencia',
    'panvel',
    'paodeacucar',
    'extra',
    'carrefour',
  ];

  const isBornlogicUser = userMail.includes('@bornlogic');
  const isAllowedTenant = allowedTenants.includes(tenant);

  return isBornlogicUser || isAllowedTenant;
}

export const isKeyValueTrue = (keyValue = '') => {
  return keyValue?.toLowerCase() === 'true';
};

export const isKroton = (tenant = '') => {
  const krotonTenants = ['kroton', 'cogna'];
  return krotonTenants.includes(tenant);
};
