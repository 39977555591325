import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  ${({ theme }) => css`
    position: absolute;
    width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.spacing.px12} ${theme.spacing.px16};
    box-sizing: border-box;
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-size: ${theme.fontSize.px12};
    margin: 0 ${theme.spacing.px15} 0 0;
    position: relative;
    max-height: 60px;
    overflow: hidden;
    z-index: 1;
  `}
`;
