import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from 'react-translate';
import Sidebar from '@containers/Sidebar';
import Header from '@containers/Header';
import ErrorPage from 'containers/ErrorPage';
import Offline from '@components/Offline';
import Tabs from '../Tabs';
import * as S from './styles';
import { SurveyProvider } from '@utils/contexts';

const Dashboard = (props) => {
  const {
    children,
    noSidebar,
    noTabs,
    hideBreadcrumb,
    showButtonCalendar,
    shouldNotAddMargin = false,
  } = props;

  const t = useTranslate('DashboardOff');
  const { breadCrumb } = useSelector((state) => state.genericReducer);

  const [isOnline, setisOnline] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => setisOnline(navigator.onLine), 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <S.DashboardWrapper>
      <S.RouterWrapper>
        <SurveyProvider>
          <Header />

          {isOnline ? (
            <>
              {!breadCrumb.status || breadCrumb.status === 200 ? (
                <>
                  {!noTabs && (
                    <Tabs hideBreadcrumb={hideBreadcrumb} showButtonCalendar={showButtonCalendar} />
                  )}
                  <S.ContentWrapper>
                    {!noSidebar && <Sidebar />}
                    <S.MainWrapper shouldNotAddMargin={shouldNotAddMargin}>
                      {children}
                    </S.MainWrapper>
                  </S.ContentWrapper>
                </>
              ) : (
                <S.MainWrapper shouldNotAddMargin={shouldNotAddMargin}>
                  <ErrorPage {...props} />
                </S.MainWrapper>
              )}
            </>
          ) : (
            <Offline description={t('DESCRIPTION_TEXT')} linkText={t('LINK_TEXT')} />
          )}
        </SurveyProvider>
      </S.RouterWrapper>
    </S.DashboardWrapper>
  );
};

export default Dashboard;
