import React, { useState, useMemo, useCallback } from 'react';
import { useTranslate } from 'react-translate';
import { useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { Scrollbars } from 'react-custom-scrollbars';
import { Formik, Field } from 'formik';
import InputDefault from '../InputDefault/InputDefault';
import Tooltip from '../Tooltip/Tooltip';
import GroupItem from './GroupItem';
import Active from './Active';
import ClickOutHandler from 'react-clickout-handler';

import * as S from './style';
import { callGtag, getChildGroups, getParents } from './utils';
import LoadingOld from 'components/LoadingOld/LoadingOld';

function BreadCrumb({ redirectUrl }) {
  const authenticationReducer = useSelector((state) => state.authenticationReducer);
  const { businessId, dataUser } = authenticationReducer;

  const {
    breadCrumb: { isLoading: isLoadingCode, data },
  } = useSelector((state) => state.genericReducer);

  const t = useTranslate('BreadCrumb');
  const history = useHistory();
  const { path } = useRouteMatch();
  const { code, module, group } = useParams();

  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const [actualGroup, setActualGroup] = useState({ source_id: code });
  const [parent, setParent] = useState(null);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const isAdmin = useMemo(() => dataUser.role === 'admin', [dataUser.role]);

  const changeCodeUrl = useCallback(
    (code) => {
      let newUrl = path;
      if (redirectUrl) {
        newUrl = `/:code/${redirectUrl}`;
      }

      newUrl = newUrl.replace(':code', code);
      newUrl = newUrl.replace(':module', module);
      newUrl = newUrl.replace(':group', group);
      history.push(newUrl);
    },
    [group, history, module, path, redirectUrl]
  );

  const openGroup = useCallback(
    async (id, name) => {
      setParent(actualGroup);
      setIsLoading(true);
      const childGroups = await getChildGroups(id);
      setActualGroup({ name: name, source_id: id, children: childGroups });
      setIsLoading(false);
      callGtag('Abrir Grupo', dataUser.user_id, businessId, id);
      setSearch('');
    },
    [actualGroup, businessId, dataUser.user_id]
  );

  const closeGroup = useCallback(
    async (parent) => {
      setSearch('');

      setIsLoading(true);
      const parents = await getParents(parent.source_id);
      if (parents.length === 0) {
        if (isAdmin) {
          setParent({
            source_id: 'root',
          });
        } else {
          setParent(null);
        }
      } else {
        setParent(parents[0]);
      }

      const childGroups = await getChildGroups(parent.source_id);
      setActualGroup({ name: parent.name, source_id: parent.source_id, children: childGroups });
      setIsLoading(false);
    },
    [isAdmin]
  );

  const selectGroup = useCallback(
    (ID) => {
      setShowDropdownMenu(!showDropdownMenu);
      changeCodeUrl(ID);

      callGtag('Selecionar Grupo', dataUser.user_id, businessId, ID);
    },
    [businessId, changeCodeUrl, dataUser.user_id, showDropdownMenu]
  );

  const handleCloseBreadCrumb = useCallback(async () => {
    callGtag('Fechar Breadcrumb', dataUser.user_id, businessId);
    setShowDropdownMenu(false);
  }, [dataUser.user_id, businessId]);

  const handleShowBreadCrumb = useCallback(async () => {
    if (showDropdownMenu || isLoadingCode) return;

    callGtag('Abrir Breadcrumb', dataUser.user_id, businessId);

    setShowDropdownMenu(true);

    setIsLoading(true);
    const parents = await getParents(code);
    if (parents.length === 0) {
      if (isAdmin) {
        setParent({
          source_id: 'root',
        });
      } else {
        setParent(null);
      }
    } else {
      setParent(parents[0]);
    }

    const childGroups = await getChildGroups(code);
    setActualGroup({ source_id: code, children: childGroups });
    setIsLoading(false);
  }, [showDropdownMenu, isLoadingCode, dataUser.user_id, businessId, code, isAdmin]);

  const activeLabel = useMemo(() => {
    if (code === 'root') return <Active name={t('ALL_GROUPS')} />;

    return !isLoadingCode && data?.currentCode ? (
      <Active name={data.currentCode.name} />
    ) : (
      <Active name="...." />
    );
  }, [data?.currentCode, isLoadingCode, t, code]);

  function DropdownMenuContent() {
    if (isLoading || isLoadingCode) return <LoadingOld isLoading={true} />;

    return (
      <>
        <S.Title>{t('SELECT_WHICH_ONE')}</S.Title>
        <S.SubTitle>{t('YOU_CAN_CHOOSE_ONE_GROUP')}</S.SubTitle>
        {code !== actualGroup?.source_id && (
          <S.SelectButton onClick={() => selectGroup(actualGroup?.source_id)}>
            {t('CHOOSE_THIS_LEVEL')}
          </S.SelectButton>
        )}
        {actualGroup && (
          <>
            <GroupItem
              key={actualGroup.source_id}
              source_id={actualGroup.source_id}
              name={
                actualGroup.source_id === 'root'
                  ? t('ALL_GROUPS')
                  : actualGroup.name || data?.currentCode?.name
              }
              isOpen={true}
              hasParent={parent?.source_id && actualGroup.source_id !== 'root'}
              color={actualGroup.color}
              openGroup={() =>
                openGroup(
                  actualGroup.source_id,
                  actualGroup.source_id === 'root' ? 'root' : actualGroup.name
                )
              }
              closeGroup={() => closeGroup(parent)}
            />
            {!!actualGroup?.children?.length && (
              <S.InputWrapper>
                <Formik>
                  <Field
                    component={InputDefault}
                    type="text"
                    id="search"
                    name="search"
                    placeholder={t('SEARCH')}
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    fontSize="px14"
                    icon={['fal', 'search']}
                    block
                  />
                </Formik>
              </S.InputWrapper>
            )}
            <Scrollbars autoHeight>
              {actualGroup.children?.map(
                (group) =>
                  group?.name?.toUpperCase().includes(search.toUpperCase()) && (
                    <GroupItem
                      key={group.source_id}
                      source_id={group.source_id}
                      name={group.name}
                      hasChild={group.child_groups_count}
                      color={group.color}
                      openGroup={() => openGroup(group.source_id, group.name)}
                      onSelect={() => selectGroup(group.source_id)}
                      selected={code === group.source_id}
                    />
                  )
              )}
            </Scrollbars>
          </>
        )}
      </>
    );
  }

  return (
    <ClickOutHandler
      events={['mousedown', 'touchstart', 'keydown']}
      onClickOut={(event) => {
        if (!isLoadingCode) {
          const isKey = event instanceof KeyboardEvent;
          const isEsc = event?.key === 'Escape';

          if (isEsc || !isKey) handleCloseBreadCrumb();
        }
      }}
    >
      <S.BreadCrumbItens onClick={handleShowBreadCrumb}>{activeLabel}</S.BreadCrumbItens>
      {showDropdownMenu && (
        <S.TooltipWrapper>
          <Tooltip orientation="top" align="left" padding="32px;" width="540px" border>
            <S.Wrapper>
              <DropdownMenuContent />
            </S.Wrapper>
          </Tooltip>
        </S.TooltipWrapper>
      )}
    </ClickOutHandler>
  );
}

export default BreadCrumb;
