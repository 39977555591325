import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { primaryTheme as theme } from '@styles/themes';

export const CustomLink = styled(Link)`
  display: flex;
  align-items: center;
  margin: ${theme.spacing.px15} ${theme.spacing.none};
  padding: ${theme.spacing.px8} ${theme.spacing.px16} ${theme.spacing.px8} ${theme.spacing.px25};
  cursor: pointer;
  text-decoration: none;
  h6,
  svg {
    color: ${theme.colors.blueGrey2};
    font-weight: ${theme.fontWeight.light};
  }
  &:hover {
    h6,
    svg {
      color: ${theme.colors.blue2};
    }
  }
  ${({ active }) =>
    active &&
    css`
      h6,
      svg {
        color: ${theme.colors.blue2};
        font-weight: ${theme.fontWeight.normal};
      }
      border-right: 3px solid #2328eb;
      background: #d3d4eb;
    `};
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `};
  i {
    margin-right: ${theme.spacing.px16};
  }
`;

export const CustomIcon = styled.span`
  margin-right: 10px;
  ${({ facebook }) =>
    facebook &&
    css`
      font-size: ${theme.fontSize.px20};
    `}
`;

export const SubMenuContainer = styled.section`
  display: flex;
  align-items: center;
  margin: ${theme.spacing.px15} ${theme.spacing.none};
  padding: ${theme.spacing.px8} ${theme.spacing.px16} ${theme.spacing.px8} ${theme.spacing.px25};
  cursor: pointer;
  text-decoration: none;
  user-select: none;

  h6 {
    color: ${theme.colors.blueGrey2};
    font-weight: ${theme.fontWeight.light};
  }

  &:hover {
    h6,
    svg {
      color: ${theme.colors.blue2} !important;
    }
  }
`;

export const SubMenuIconDiv = styled.div`
  margin-left: ${theme.spacing.px25};
`;

export const SubMenusContainer = styled.section`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;
