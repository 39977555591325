const DropDownSelectPT = {
  DropDownSelect: {
    undefinedTreatment: 'Selecione para incluir',
  },
};

const DropDownSelectEN = {
  DropDownSelect: {
    undefinedTreatment: 'Select to include',
  },
};

const DropDownSelectES = {
  DropDownSelect: {
    undefinedTreatment: 'Seleccionar para incluir',
  },
};

export { DropDownSelectPT, DropDownSelectEN, DropDownSelectES };
