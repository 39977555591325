import SchemaService from 'services/schema';

const { gtag } = window;

export const callGtag = (action, user_id, business_id, group_id) => {
  gtag('event', 'click', {
    event_category: 'breadcrumb',
    event_label: action,
    user_id,
    business_id,
    group_id: group_id ?? undefined,
  });
};

export async function getParents(id) {
  try {
    const parents = await SchemaService.getParents(id);
    return parents.data;
  } catch (e) {
    return [];
  }
}

export async function getChildGroups(id) {
  try {
    const childGroups = await SchemaService.getChildGroups(id);
    return childGroups.data;
  } catch (e) {
    return [];
  }
}
