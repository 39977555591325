import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslate } from 'react-translate';

import Container from 'components/Container';
import Title from 'components/Title';
import * as S from 'containers/ErrorPage/styles';

import { ERROR_TYPES, BUTTONS_LIST } from './consts';

import type { ErrorPageProps, Scope } from './types';

function ErrorPage({ isFrontError, resetError }: ErrorPageProps) {
  const t = useTranslate('ErrorPage');
  const { search, pathname } = useLocation();

  function handleRoutes(scope: Scope) {
    const code = pathname.split('/')[1];

    const redirectDict: Record<Scope, string> = {
      global: `/${code}/Report/Overview/Global`,
      listAll: `/${code}/Report/Listing/Groups`,
      budget: `/${code}/Budget/Listing/Groups`,
      media: `/${code}/Medias/Approvals`,
      audience: `/${code}/Report/Audience/Age&Gender`,
      ranking: `/${code}/Report/Ranking/Accounts?sort_by=SPEND`,
      ci: `/${code}/CI`,
    };

    return redirectDict[scope];
  }

  const errorType = useMemo(() => {
    const urlParams = new URLSearchParams(search);
    const serverError = urlParams.get('serverError');

    if (serverError === 'true') {
      return ERROR_TYPES['server'];
    } else if (isFrontError) {
      return ERROR_TYPES['front400'];
    } else {
      return ERROR_TYPES['front404'];
    }
  }, [isFrontError, search]);

  return (
    <S.Wrapper>
      <Container>
        <S.Content>
          <S.ImageWrapper>
            <S.Image src={errorType.image} alt={errorType.imageAlt} />
          </S.ImageWrapper>
          <S.ContentInfo>
            <Title tag="h1" fontWeight="bold" marginBottom="px10">
              {t(errorType.title)}
            </Title>
            <Title tag="h5" fontWeight="normal" marginBottom="px30">
              {t(errorType.description)}
            </Title>
            <S.Body>
              <p>{t('CHOOSE_PAGE')}</p>
              {BUTTONS_LIST.map((item) => (
                <S.Button
                  key={item}
                  data-testid={`button_${item}`}
                  to={handleRoutes(item)}
                  onClick={resetError}
                  replace
                >
                  {t(item.toUpperCase())}
                </S.Button>
              ))}
            </S.Body>
          </S.ContentInfo>
        </S.Content>
      </Container>
    </S.Wrapper>
  );
}

export default ErrorPage;
