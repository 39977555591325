const ReleaseNotesHistoryEN = {
  ReleaseNotesHistory: {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    10: 'October',
    11: 'November',
    12: 'December',
    BANNER_TITLE: 'Release notes',
    BANNER_TEXT:
      "If you wanna know what's new on GD - and what's changed too - you're in the right place!",
    LAST_UPDATES: 'Last updates',
    ALL_UPDATES: 'See all updates',
    NO_CONTENT_TITLE: 'Oops... Nothing to see here.',
    NO_CONTENT_TEXT1: "Here's the place where you can see the GD news!",
    NO_CONTENT_TEXT2:
      "It seems that there's no news to report, but stay tunned for the next updates!",
  },
};

const ReleaseNotesHistoryPT = {
  ReleaseNotesHistory: {
    '01': 'Janeiro',
    '02': 'Fevereiro',
    '03': 'Março',
    '04': 'Abril',
    '05': 'Maio',
    '06': 'Junho',
    '07': 'Julho',
    '08': 'Agosto',
    '09': 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro',
    BANNER_TITLE: 'Novidades no produto',
    BANNER_TEXT:
      'Se você tem curiosidade sobre que tem de novo no gerentesdigitais — e o que mudou — você chegou no lugar certo!',
    LAST_UPDATES: 'Últimas atualizações',
    ALL_UPDATES: 'Ver todas novidades',
    NO_CONTENT_TITLE: 'Ops... Nada por aqui.',
    NO_CONTENT_TEXT1: 'Aqui ficam as novidades do GD!',
    NO_CONTENT_TEXT2:
      'Parece que no momento não há novidades pra exibir, mas fique ligado nas próximas atualizações!',
  },
};

const ReleaseNotesHistoryES = {
  ReleaseNotesHistory: {
    '01': 'Enero',
    '02': 'Febrero',
    '03': 'Marzo',
    '04': 'Abril',
    '05': 'Mayo',
    '06': 'Junio',
    '08': 'Agosto',
    '07': 'Julio',
    '09': 'Septiembre',
    10: 'Octubre',
    11: 'Noviembre',
    12: 'Diciembre',
    BANNER_TITLE: 'Noticias de producto',
    BANNER_TEXT:
      'Si quieres saber qué hay de nuevo en GD, y qué ha cambiado también, ¡estás en el lugar correcto!',
    LAST_UPDATES: 'Últimas actualizaciones',
    ALL_UPDATES: 'Ver todas las noticias',
    NO_CONTENT_TITLE: 'Ups... No hay nada que ver aquí.',
    NO_CONTENT_TEXT1: '¡Aquí está el lugar donde puedes ver las noticias de GD!',
    NO_CONTENT_TEXT2:
      'Parece que no hay noticias que informar, ¡pero estad atentos a las próximas actualizaciones!',
  },
};

export { ReleaseNotesHistoryEN, ReleaseNotesHistoryES, ReleaseNotesHistoryPT };
