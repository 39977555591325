import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function sizeInput(theme, size, fieldValidation) {
  switch (size) {
    case 'px20':
      return `
        ${
          fieldValidation
            ? `padding: ${theme.spacing.px20} ${theme.spacing.px60} ${theme.spacing.px20} ${theme.spacing.px20};`
            : `padding: ${theme.spacing.px20} ${theme.spacing.px20};`
        }
      `;

    case 'px15':
      return `
        padding: ${theme.spacing.px10} ${theme.spacing.px15};
      `;

    default:
      return `
        padding: ${theme.spacing.px10} ${theme.spacing.px15};
      `;
  }
}

export const InputDefaultWrapper = styled('div')`
  ${({ theme, sizeWidth }) => css`
    position: relative;
    background: ${theme.colors.white};
    border-radius: ${theme.rounded.px10};
    ${!!sizeWidth ? `width: ${sizeWidth};` : ''}
  `}
`;

export const InputDefaultContent = styled('div')`
  ${({ theme, searchAbsolute, mobile }) => css`
    ${searchAbsolute
      ? `
            background: ${theme.colors.white};
            position: absolute;
            right: 0;
            top: -19px;
            border-radius: ${theme.rounded.px10};
            `
      : ``}
    ${mobile
      ? `
                max-width: ${theme.spacing.full};
                background: transparent;
                margin-top: ${theme.spacing.px60};
                width: ${theme.spacing.full};
                ${
                  searchAbsolute
                    ? `
                        position: static;
                    `
                    : ``
                }
            `
      : ''}
  `}
`;

export const InputDefaultStyled = styled('textarea')`
  ${({
    theme,
    icon,
    autoResize,
    withAutoComplete,
    optionsAutoComplete,
    block,
    size,
    fontSize,
    fieldValidation,
    bgColor,
    typed,
    withoutBorder,
    isModal,
  }) => css`
    font-size: ${theme.fontSize[fontSize]};

    font-weight: ${theme.fontWeight.bold};
    color: ${theme.colors.blueGrey3};
    background: none;
    outline: none;
    box-sizing: border-box;
    transition: width 0.5s;
    position: relative;
    resize: none;
    line-height: 1.5;
    min-height: 120px;
    border: 1px solid ${theme.colors.grey2};
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    &:disabled {
      background: ${theme.colors.blueGrey6};
      color: ${theme.colors.blueGrey3};
      cursor: not-allowed;
    }
    ${withoutBorder
      ? `
        border: none
        `
      : ``}
    ${!!bgColor ? `background-color: ${theme.colors[bgColor]}; border: none; color: black;` : ''}
    ${withAutoComplete && optionsAutoComplete && typed && typed.length
      ? `
        border-radius: ${theme.rounded.px10};
        &:active,
        &:focus,
        &:focus-within {
            border-radius: ${theme.rounded.px10} ${theme.rounded.px10} ${theme.rounded.none} ${theme.rounded.none};
        }
        `
      : `
        border-radius: ${theme.rounded.px10};
        `}
    ${icon
      ? `padding: ${theme.spacing.px10} ${theme.spacing.px15} ${theme.spacing.px10} ${theme.spacing.px40};`
      : sizeInput(theme, size, fieldValidation)}
    ${autoResize
      ? `
        width: 210px;
        &:active,
        &:focus,
        &:focus-within {
            width: 400px;
            max-width: ${theme.spacing.full};
        }
        `
      : `
        width: ${theme.spacing.full};
        max-width: ${theme.spacing.full};
        `}
    ${block
      ? `
        width: ${theme.spacing.full};
        max-width: ${theme.spacing.full};
        `
      : ``}
    ${isModal
      ? `
        padding: ${theme.spacing.px20} ${theme.spacing.px40};
        `
      : ``}
  `}
`;

export const CustomIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.px14};
    color: ${theme.colors.blueGrey3};
    position: absolute;
    left: ${theme.spacing.px15};
    top: 13px;
  `}
`;

export const CustomValidation = styled('span')`
  ${({ theme }) => css`
    position: absolute;
    right: ${theme.spacing.px20};
    top: ${theme.spacing.px20};
  `}
`;

export const CustomValidationIcon = styled(FontAwesomeIcon)`
  ${({ theme, color }) => css`
    display: block;
    font-size: ${theme.fontSize.px25};
    color: ${theme.colors[color]};
  `}
`;

export const MessageValidation = styled('div')`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.px12};
    color: ${theme.colors.red3};
    margin-top: ${theme.spacing.px3};
  `}
`;
