export const CloneCapabilitiesModalEN = {
  CloneCapabilitiesModal: {
    SAVE: 'Save',
    CANCEL: 'Cancel',
    MODAL_TITLE: 'Replicate permissions from another user to user {{username}}.',
    MODAL_TITLE_PLURAL: 'Replicate permissions from another user to {howManyUsers} users.',
    SELECT_USER_CAPABILITY_TO_REPLICATE_LABEL:
      'From which user do you want to replicate permissions from?',
    SELECT_USER_CAPABILITY_TO_REPLICATE_PLACEHOLDER: 'Search by username...',
    SELECT_USER_CAPABILITY_TO_REPLICATE_TOOLTIP:
      "This user's permissions will be replicated to the users previously selected in the table.",
    EMPTY_INPUT_MESSAGE: 'Search by username.',
    EMPTY_OPTIONS_MESSAGE: 'No users found with that name.',
  },
};

export const CloneCapabilitiesModalES = {
  CloneCapabilitiesModal: {
    SAVE: 'Gravar',
    CANCEL: 'Cancelar',
    MODAL_TITLE: 'Replicar permisos de otro usuario al usuario {{username}}.',
    MODAL_TITLE_PLURAL: 'Replicar permisos de otro usuario a {{howManyUsers}} usuarios.',
    SELECT_USER_CAPABILITY_TO_REPLICATE_LABEL: '¿De qué usuario desea replicar los permisos?',
    SELECT_USER_CAPABILITY_TO_REPLICATE_PLACEHOLDER: 'Buscar por nombre de usuario...',
    SELECT_USER_CAPABILITY_TO_REPLICATE_TOOLTIP:
      'Los permisos de este usuario se replicarán a los usuarios previamente seleccionados en la tabla.',
    EMPTY_INPUT_MESSAGE: 'Buscar por nombre de usuario.',
    EMPTY_OPTIONS_MESSAGE: 'No se encontraron usuarios con este nome.',
  },
};

export const CloneCapabilitiesModalPT = {
  CloneCapabilitiesModal: {
    SAVE: 'Salvar',
    CANCEL: 'Cancelar',
    MODAL_TITLE: 'Replicar permissões de outro usuário para usuário {{username}}.',
    MODAL_TITLE_PLURAL: 'Replicar permissões de outro usuário para {{howManyUsers}} usuários.',
    SELECT_USER_CAPABILITY_TO_REPLICATE_LABEL: 'De que usuário deseja replicar permissões?',
    SELECT_USER_CAPABILITY_TO_REPLICATE_PLACEHOLDER: 'Pesquise pelo nome do usuário...',
    SELECT_USER_CAPABILITY_TO_REPLICATE_TOOLTIP:
      'As permissões deste usuário serão replicadas para os usuários previamente selecionados na tabela.',
    EMPTY_INPUT_MESSAGE: 'Pesquise pelo nome do usuário.',
    EMPTY_OPTIONS_MESSAGE: 'Nenhum usuário encontrado com esse nome.',
  },
};
