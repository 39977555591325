import { ApiTenant } from '@utils/api';

class EventsService {
  static async createPublicMixPanelEvent(data) {
    try {
      const res = await ApiTenant.post('/1.0/EventTracking/Public', data);
      return res;
    } catch (err) {}
  }

  static async createMixPanelEventWithoutTenant(data) {
    try {
      const res = await ApiTenant.post('/1.0/EventTracking/User', data);
      return res;
    } catch (err) {}
  }

  static async createMixPanelEventWithTenant({ unityId, data }) {
    try {
      const res = await ApiTenant.post(`/1.0/${unityId}/EventTracking/User`, data);
      return res;
    } catch (err) {}
  }
}

export default EventsService;
