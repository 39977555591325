const ButtonCalendarEN = {
  ButtonCalendar: {
    'TITLE-DATE-RANGE': 'Select period',
    APPLY: 'Apply',
    TODAY: 'Today',
    YESTERDAY: 'Yesterday',
    LAST_7D: 'Last seven days',
    THIS_MONTH: 'This Month',
    LAST_MONTH: 'Last Month',
    THIS_YEAR: 'This Year',
    'BUTTON-TITLE': 'Filter per period',
    CUSTOM_PERIOD: 'Custom period',
  },
};

const ButtonCalendarPT = {
  ButtonCalendar: {
    'TITLE-DATE-RANGE': 'Selecione o período desejado',
    APPLY: 'Aplicar',
    TODAY: 'Hoje',
    YESTERDAY: 'Ontem',
    LAST_7D: 'Últimos sete dias ',
    LAST_MONTH: 'Mês passado',
    THIS_MONTH: 'Este mês',
    THIS_YEAR: 'Este ano',
    'BUTTON-TITLE': 'Filtre por período',
    CUSTOM_PERIOD: 'Período customizado',
  },
};

const ButtonCalendarES = {
  ButtonCalendar: {
    'TITLE-DATE-RANGE': 'Seleccione el período deseado',
    APPLY: 'Aplicar',
    TODAY: 'Hoy',
    YESTERDAY: 'Ayer',
    LAST_7D: 'Últimos siete días ',
    LAST_MONTH: 'Mes pasado',
    THIS_MONTH: 'Este mes',
    THIS_YEAR: 'Este año',
    'BUTTON-TITLE': 'Filtrar por período',
    CUSTOM_PERIOD: 'Período personalizado',
  },
};

export { ButtonCalendarPT, ButtonCalendarEN, ButtonCalendarES };
