import axios from 'axios';

const BASE_URL = 'https://api-homolog.gerentesdigitais.com/youtube/v1';

const instancePublic = axios.create({});

instancePublic.interceptors.request.use(
  (config) => {
    config.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    config.headers.post['Access-Control-Allow-Origin'] = '';

    return config;
  },
  (error) => Promise.reject(error)
);

export const postToGetSasUrl = async (token) => {
  const body = {
    access_token: token,
  };

  const response = await instancePublic.post(`${BASE_URL}/token`, body);
  return response.data;
};

export const postVideo = async (body, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return await instancePublic.post(`${BASE_URL}/video`, body, { headers });
};
