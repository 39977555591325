const DEFAULT_FILTER_OPTIONS = [
  {
    role: 'created_time',
    preset: 'lifetime',
    label: 'RECENT',
  },
  {
    role: 'relevance',
    preset: 'lifetime',
    label: 'RELEVANCE',
  },
];

const FEED_AWARDS_FILTER_OPTIONS = [
  {
    role: 'video_view',
    preset: 'this_month',
    label: 'AWARD_VIDEO_VIEW_MONTH',
  },
  {
    role: 'post_engagement',
    preset: 'this_month',
    label: 'AWARD_POST_ENGAGEMENT_MONTH',
  },
  {
    role: 'video_view',
    preset: 'last_month',
    label: 'AWARD_VIDEO_VIEW_LAST_MONT',
  },
  {
    role: 'post_engagement',
    preset: 'last_month',
    label: 'AWARD_POST_ENGAGEMENT_LAST_MONT',
  },
  {
    role: 'video_view',
    preset: 'this_year',
    label: 'AWARD_VIDEO_VIEW_YEAR',
  },
  {
    role: 'post_engagement',
    preset: 'this_year',
    label: 'AWARD_POST_ENGAGEMENT_YEAR',
  },
];

export const getFiltersOptions = ({ hasFeedAwards }) => {
  if (hasFeedAwards) return [...DEFAULT_FILTER_OPTIONS, ...FEED_AWARDS_FILTER_OPTIONS];
  return DEFAULT_FILTER_OPTIONS;
};
