import { useCallback, useState } from 'react';
import { SettingsService } from '@services';

export default function useUnitsSummary(code) {
  const [activesNumber, setActivesNumber] = useState(0);
  const [runningNumber, setRuninngNumber] = useState(0);
  const [errorNumber, setErrorNumber] = useState(0);
  const [totalUnits, setTotalUnits] = useState(0);
  const [pendingUnits, setPendingUnits] = useState(0);
  const [inactiveUnits, setInactiveUnits] = useState(0);
  const [loading, setLoading] = useState(false);

  const loadTotalUnits = useCallback(async () => {
    setLoading(true);

    setActivesNumber(0);
    setErrorNumber(0);
    setRuninngNumber(0);
    setTotalUnits(0);
    setPendingUnits(0);
    setInactiveUnits(0);

    const unitsSummaryResponse = await SettingsService.getUnitsSummary(code);

    if (unitsSummaryResponse.status < 300) {
      setActivesNumber(
        unitsSummaryResponse.data?.active - unitsSummaryResponse.data?.gd_inactive_units
      );
      setErrorNumber(unitsSummaryResponse.data?.error);
      setRuninngNumber(unitsSummaryResponse.data?.running);
      setTotalUnits(unitsSummaryResponse.data?.total);
      setPendingUnits(unitsSummaryResponse.data?.pending);
      setInactiveUnits(unitsSummaryResponse.data?.gd_inactive_units);
    }

    setLoading(false);
  }, [code]);

  return {
    activesNumber,
    runningNumber,
    errorNumber,
    totalUnits,
    loading,
    loadTotalUnits,
    inactiveUnits,
    pendingUnits,
  };
}
