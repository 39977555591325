const GDScorePT = {
  GDScore: {
    TITLE: 'Ações Pontuáveis',
    EMPTY_TITLE: 'Crie a primeira Ação que gera pontos!',
    SUB_TITLE:
      'Para pontuar em GD Score, as unidades precisam concluir ações pontuáveis. Cadastre uma ação, os pontos ganhos e a validade deles, para incentivar os usuários a usar o app!',
    CREATE_ACTION_BUTTON_LABEL: 'Criar nova ação pontuável',
  },
  ActionCard: {
    FROM: 'De',
    TO: 'até',
    DUE_TO: 'Válido por:',
    ACTIVE_ACTION: 'Ação ativa',
    PAUSED_ACTION: 'Ação pausada',
    POINTS: 'Pontos',
    DAYS: 'dias',
    RECURRENT_SCORE: 'Pontuação Recorrente',
    UNIQUE_SCORE: 'Pontuação Única',
    EDIT_LABEL: 'Editar Ação Pontuável',
    PAUSE_ACTION: 'Pausar Ação Pontuável',
    ACTIVATE_ACTION: 'Ativar Ação Pontuável',
    CREDIT: 'Crédito',
    DEBIT: 'Débito',
    EVENT: 'Evento:',
    KEY: 'Chave:',
  },
  GDScoreForm: {
    GO_BACK: 'Voltar',
    CANCEL: 'Cancelar',
    SEE_REVIEW: 'Ver resumo',
    TITLE_ERROR: 'Título da ação é necessário',
    KEY_ERROR: 'Chave da ação necessária',
    POINTS_ERROR: 'Número de pontos é necessário',
    NUMBER_TYPE_ERROR: 'Insira apenas números',
    VALIDITY_DAYS_ERROR: 'Validade dos pontos é necessária',
    IS_REPEATABLE_ERROR: 'Escolha um tipo de pontuação',
    EVENT_TYPE_ERROR: 'Tipo de evento é necessário',
    UNIQUE: 'Único',
    RECURRENT: 'Recorrente',
    ACTIVE: 'Ativa',
    PAUSED: 'Pausada',
    CREDIT: 'Crédito',
    DEBIT: 'Débito',

    ACTION_TITLE: 'Título da Ação',
    POINTS_LABEL: 'Pontos a ganhar',
    POINTS_DUE_DATE: 'Validade dos pontos',
    RECURRENCE: 'Recorrência',
    EVENT_TYPE_OPTIONS: 'Tipo de Evento',
    ACTION_STATUS: 'Status da Ação',
    RECURRENT_SCORE: 'Pontuação Recorrente',
    UNIQUE_SCORE: 'Pontuação Única',
    ACTION_ID: 'ID da Ação',
    ID_TOOLTIP_LABEL: 'A ID deve ser cadastrada por desenvolvedores como gatilho da ação',
    DESCRIPTION: 'Descrição da ação',
    UNINFORMED: 'Não informado',
    SEND_ACTION: 'Enviar ação',
    SUCCESSFUL_MODAL_TITLE: 'Sua ação foi cadastrada!',
    SUCCESSFUL_EDIT_MODAL_TITLE: 'Sua ação foi atualizada!',
    SUCCESSFUL_MODAL_SUB_TITLE:
      'Para que as unidades ganhem pontos ao realizar essa ação, o time de desenvolvedores precisa cadastrar a Chave como gatilho da ação.',
    SELECT: 'Selecione',
    TITLE_PLACEHOLDER: 'Digite o nome (como as unidades vão ver)',
    POINTS_PLACEHOLDER: 'Ex: 30 pontos',
    POINTS_DUE_DATE_PLACEHOLDER: 'Ex: 120 dias',
    DESCRIPTION_PLACEHOLDER:
      'Descreva os detalhes da Ação Pontuável e suas regras. O que nós, da Bornlogic, precisamos saber sobre essa Ação Pontuável?',
    ACTION_KEY: 'Chave da Ação',
    KEY_PLACEHOLDER: 'Ex: CREATE_ADD',
    KEY_TOOLTIP_LABEL: 'Essa Chave deve ser cadastrada por desenvolvedores como gatilho da ação',
  },
};

const GDScoreEN = {
  GDScore: {
    TITLE: 'Scoring Actions',
    EMPTY_TITLE: 'Create the first Action that generates points!',
    SUB_TITLE:
      'To score in GD Score, units must complete scoring actions. Register an action, the points earned and their validity, to encourage users to use the app!',
    CREATE_ACTION_BUTTON_LABEL: 'Create new scoring action',
  },
  ActionCard: {
    FROM: 'From',
    TO: 'to',
    DUE_TO: 'Valid by:',
    ACTIVE_ACTION: 'Active action',
    PAUSED_ACTION: 'Paused action',
    POINTS: 'Points',
    DAYS: 'days',
    RECURRENT_SCORE: 'Recurring Score',
    UNIQUE_SCORE: 'Unique Score',
    EDIT_LABEL: 'Edit Scoring Action',
    PAUSE_ACTION: 'Pause Scoring Action',
    ACTIVATE_ACTION: 'Activate Scoring Action',
    CREDIT: 'Credit',
    DEBIT: 'Debit',
    EVENT: 'Event:',
    KEY: 'Key:',
  },
  GDScoreForm: {
    GO_BACK: 'Go Back',
    CANCEL: 'Cancel',
    SEE_REVIEW: 'See summary',
    TITLE_ERROR: 'Action title is required',
    KEY_ERROR: 'Action key is required',
    POINTS_ERROR: 'Number of points is required',
    NUMBER_TYPE_ERROR: 'Enter numbers only',
    VALIDITY_DAYS_ERROR: 'Points validity is required',
    IS_REPEATABLE_ERROR: 'Choose a score type',
    EVENT_TYPE_ERROR: 'Event type is required',
    UNIQUE: 'Unique',
    RECURRENT: 'Recurrent',
    ACTIVE: 'Active',
    PAUSED: 'Paused',
    CREDIT: 'Credit',
    DEBIT: 'Debit',

    ACTION_TITLE: 'Action Title',
    POINTS_LABEL: 'Points to earn',
    POINTS_DUE_DATE: 'Points validity',
    RECURRENCE: 'Recurrence',
    EVENT_TYPE_OPTIONS: 'Event Type',
    ACTION_STATUS: 'Action Status',
    RECURRENT_SCORE: 'Recurring Score',
    UNIQUE_SCORE: 'Unique Score',
    ACTION_ID: 'Action ID',
    ID_TOOLTIP_LABEL: 'The ID must be registered by developers as the action trigger',
    DESCRIPTION: 'Action Description',
    UNINFORMED: 'Uninformed',
    SEND_ACTION: 'Send action',
    SUCCESSFUL_MODAL_TITLE: 'Your action has been registered!',
    SUCCESSFUL_EDIT_MODAL_TITLE: 'Your action has been updated!',
    SUCCESSFUL_MODAL_SUB_TITLE:
      'In order for the units to earn points when performing this action, the development team needs to register the Key as the action trigger.',
    SELECT: 'Select',
    TITLE_PLACEHOLDER: 'Enter the name (as the units will see)',
    POINTS_PLACEHOLDER: 'I.e: 30 points',
    POINTS_DUE_DATE_PLACEHOLDER: 'I.e: 120 days',
    DESCRIPTION_PLACEHOLDER:
      'Describe the details of the Scoring Action and its rules. What do we at Bornlogic need to know about this Scoring Action?',
    ACTION_KEY: 'Action Key',
    KEY_PLACEHOLDER: 'I.e: CREATE_ADD',
    KEY_TOOLTIP_LABEL: 'This Key must be registered by developers as an action trigger',
  },
};

const GDScoreES = {
  GDScore: {
    TITLE: 'Acciones de Puntuación',
    EMPTY_TITLE: '¡Crea la primera Acción que genere puntos!',
    SUB_TITLE:
      'Para puntuar en GD Score, las unidades deben completar acciones de puntuación. ¡Registre una acción, los puntos ganados y su validez, para animar a los usuarios a usar la aplicación!',
    CREATE_ACTION_BUTTON_LABEL: 'Crear nueva acción de puntuación',
  },
  ActionCard: {
    FROM: 'De',
    TO: 'hasta',
    DUE_TO: 'Válido por:',
    ACTIVE_ACTION: 'Acción activa',
    PAUSED_ACTION: 'Acción en pausa',
    POINTS: 'Puntos',
    DAYS: 'días',
    RECURRENT_SCORE: 'Puntuación recurrente',
    UNIQUE_SCORE: 'Puntuación única',
    EDIT_LABEL: 'Editar Acción de Puntuación',
    PAUSE_ACTION: 'Pausar acción de puntuación',
    ACTIVATE_ACTION: 'Activar acción de puntuación',
    CREDIT: 'Crédito',
    DEBIT: 'Débito',
    EVENT: 'Evento:',
    KEY: 'Tecla:',
  },
  GDScoreForm: {
    GO_BACK: 'Regreso',
    CANCEL: 'Cancelar',
    SEE_REVIEW: 'Ver resumen',
    TITLE_ERROR: 'El título de la acción es obligatorio',
    KEY_ERROR: 'Se requiere la clave de acción',
    POINTS_ERROR: 'Se requiere el número de puntos',
    NUMBER_TYPE_ERROR: 'Ingrese solo números',
    VALIDITY_DAYS_ERROR: 'Se requiere validez de puntos',
    IS_REPEATABLE_ERROR: 'Elige un tipo de puntuación',
    EVENT_TYPE_ERROR: 'Tipo de evento obligatorio',
    UNIQUE: 'Único',
    RECURRENT: 'Recurrente',
    ACTIVE: 'Activo',
    PAUSED: 'Pausada',
    CREDIT: 'Crédito',
    DEBIT: 'Débito',

    ACTION_TITLE: 'Título de la acción',
    POINTS_LABEL: 'Puntos para ganar',
    POINTS_DUE_DATE: 'Validez de los puntos',
    RECURRENCE: 'Reaparición',
    EVENT_TYPE_OPTIONS: 'Tipo de evento',
    ACTION_STATUS: 'Estado de la acción',
    RECURRENT_SCORE: 'Puntuación recurrente',
    UNIQUE_SCORE: 'Puntuación única',
    ACTION_ID: 'ID de Acción',
    ID_TOOLTIP_LABEL:
      'El ID debe ser registrado por los desarrolladores como desencadenante de la acción.',
    DESCRIPTION: 'Descripción de la acción',
    UNINFORMED: 'No informado',
    SEND_ACTION: 'Enviar acción',
    SUCCESSFUL_MODAL_TITLE: '¡Tu acción ha sido registrada!',
    SUCCESSFUL_EDIT_MODAL_TITLE: '¡Tu acción ha sido actualizada!',
    SUCCESSFUL_MODAL_SUB_TITLE:
      'Para que las unidades ganen puntos al realizar esta acción, el equipo de desarrollo debe registrar la Tecla como desencadenante de la acción.',
    SELECT: 'Seleccione',
    TITLE_PLACEHOLDER: 'Ingrese el nombre (como lo verán las unidades)',
    POINTS_PLACEHOLDER: 'Ex: 30 puntos',
    POINTS_DUE_DATE_PLACEHOLDER: 'Ex: 120 días',
    DESCRIPTION_PLACEHOLDER:
      'Describa los detalles de la Acción de Puntuación y sus reglas. ¿Qué necesitamos saber en Bornlogic sobre esta Scoring Action?',
    ACTION_KEY: 'Tecla de acción',
    KEY_PLACEHOLDER: 'Ex: CREATE_ADD',
    KEY_TOOLTIP_LABEL:
      'Esta clave debe ser registrada por los desarrolladores como un disparador de acción',
  },
};

export { GDScoreEN, GDScorePT, GDScoreES };
