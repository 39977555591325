import React, { useState, useEffect } from 'react';
import { useGetPendingSurveys } from '../../../utils/hooks/Survey';
import SurveyModal from '@bornlogic/survey-client';
import SurveyService from '../../../services/survey';

const SurveyModalWrapper = ({ trigger }) => {
  if (!trigger) {
    return null;
  }

  const pendingSurveys = useGetPendingSurveys();
  const [showModal, setShowModal] = useState(true);
  const [surveyAnswer, setSurveyAnswer] = useState({});

  useEffect(() => {
    if (
      Object.values(surveyAnswer).length !== 0 &&
      !(pendingSurveys.isLoading || pendingSurveys.isError)
    ) {
      SurveyService.submitBatch('2.0', surveys[0].id, surveyAnswer);
    }
  }, [surveyAnswer]);

  if (pendingSurveys.isLoading || pendingSurveys.isError) {
    return null;
  }

  const surveys = pendingSurveys.surveys.filter((survey) =>
    trigger === 'manual'
      ? survey.trigger.type.toLowerCase() === trigger
      : survey.trigger.event_name.toLowerCase() === trigger
  );

  let questions;

  if (surveys.length > 0) {
    questions = surveys[0].questions.map((question) => {
      if (question.title.toUpperCase().includes('WHATSAPP')) {
        return {
          id: question.id,
          title: question.title,
          description: 'phone',
          type: question.text,
          required: question.required,
          options_type: question.options_type,
        };
      } else {
        return question;
      }
    });
  }

  return (
    <>
      {surveys.length > 0 && (
        <SurveyModal
          showModal={showModal}
          setShowModal={setShowModal}
          setSurveyAnswer={setSurveyAnswer}
          title={surveys[0].name}
          contactConfirmation={surveys[0].contact_confirmation}
          questions={questions}
        />
      )}
    </>
  );
};

export default SurveyModalWrapper;
