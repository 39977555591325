import React, { useCallback, useState } from 'react';
import * as S from './styles';
import new_logo_bornlogic from '@images/new_logo_bornlogic.png';
import { getOptions, createFolder } from './services';
import Select from 'react-select';
import FilesUpload from './upload/FilesUpload';
import { useShowToast } from '../../utils/hooks/Common';

const UploadMN = () => {
  const [authId, setAuthId] = useState('');
  const [businessOptions, setBusinessOptions] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState({});
  const [disabled, setDisabled] = useState({ first: false });
  const [folderId, setFolderId] = useState('');
  const [loadings, setLoadings] = useState({ business: false, folder: false });
  const showToast = useShowToast();

  const getOptionsForId = async () => {
    setLoadings({ ...loadings, business: true });
    try {
      const response = await getOptions(authId);
      if (response?.data?.length) {
        const options = [];
        response.data.map((option) => {
          if (option.type === 'DEFAULT_USER' || option.type === 'ADMINISTRATOR') {
            options.push({
              memberId: option.id,
              value: option.id,
              label: option?.businesses?.data[0]?.name,
              type: option.type,
              name: option?.businesses?.data[0]?.name,
              businessId: option?.businesses?.data[0]?.id,
            });
          }
        });
        setBusinessOptions(options);
        setLoadings({ ...loadings, business: false });
      }
    } catch (e) {
      showToast('Erro ao buscar business, verifique sua credencial!', 'error');
      setLoadings({ ...loadings, business: false });
    }
  };

  const handleSelect = (option) => {
    setSelectedBusiness(option);
  };

  const createUserFolder = async () => {
    setLoadings({ ...loadings, folder: true });
    try {
      const response = await createFolder(selectedBusiness, authId);
      setFolderId(response.data.id);
      setLoadings({ ...loadings, folder: false });
    } catch (e) {
      showToast('Erro ao criar pasta, tente novamente!', 'error');
      setDisabled({ first: false });
      setSelectedBusiness({});
      setLoadings({ ...loadings, folder: false });
    }
    setDisabled({ first: true });
  };

  const removeBusiness = useCallback(() => {
    setSelectedBusiness({});
    setFolderId('');
    setDisabled({ first: false });
  }, []);

  const UploadFile = () => {
    const userConfigs = { token: authId, folderId };

    return <FilesUpload userConfigs={userConfigs} removeBusiness={removeBusiness} />;
  };

  return (
    <S.Container>
      <S.LogoContainer>
        <img src={new_logo_bornlogic} alt="logo bornlogic" />
      </S.LogoContainer>
      <S.Content>
        <S.FirstStep>
          <div>
            <S.BusinessContainer>
              <p>
                Acesse{' '}
                <a
                  href="https://mandrake.gerentesdigitais.com/#/homolog"
                  target="_blank"
                  rel="noreferrer"
                >
                  aqui
                </a>{' '}
                para resgatar sua credencial.
              </p>
              <textarea
                value={authId}
                disabled={disabled.first}
                placeholder="Insira sua credencial aqui"
                onChange={(e) => setAuthId(e.target.value)}
              />
              <button disabled={disabled.first || !authId} onClick={getOptionsForId}>
                Buscar business
                {loadings.business && <i className="fad fa-spinner fa-spin" />}
              </button>

              {!!businessOptions.length && (
                <>
                  <Select
                    classNamePrefix="formikSelect"
                    className={'overrider-border'}
                    id={1}
                    value={selectedBusiness?.name}
                    onChange={handleSelect}
                    options={businessOptions}
                    placeholder={selectedBusiness?.name || 'Selecione'}
                    isSearchable={true}
                    isDisabled={disabled.first}
                  />
                  <button
                    onClick={createUserFolder}
                    disabled={!selectedBusiness?.name || disabled.first}
                  >
                    Criar pasta e avançar
                    {loadings.folder && <i className="fad fa-spinner fa-spin" />}
                  </button>
                </>
              )}
            </S.BusinessContainer>
          </div>
        </S.FirstStep>
        <UploadFile />
      </S.Content>
    </S.Container>
  );
};

export default UploadMN;
