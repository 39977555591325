const CommentsEN = {
  Comments: {
    total_of_comments: 'Total comments received and made in the period.',
    average_per_unit: 'Total comments divided by the number of pages.',
    average_by_content: 'Total comments divided by all content produced.',
    BEST: 'best',
    LOWEST: 'lowest',
    POSITION: 'Position',
    VALUE: 'Value',
    RATE: 'Rate',
    RANKING_EVOLUTION: 'Ranking evolution',
    DAYS: 'days',
    DAY: 'day',
    HOURS: 'hours',
    REPLY: 'Replies',
    NO_REPLY: 'No replies',
    TOTAL_OF_COMMENTS: 'Comments total',
    AVERAGE_BY_CONTENT: 'Average by content',
    TITLE: 'Comments',
    SUBTITLE: "Find out how your units' comments are responding in this report",
    TITLE_RANK: 'Ranking',
    TITLE_TOOLTIP: 'Comments tooltip',
    OF_THE_TOTAL: 'of the total',
    AVERAGE_RESPONSE_TIME: 'Average response time',
    OF: 'of',
    RESPONSE_RATE: 'Response rate',
    TOTAL_COMMENTS: 'All comments',
    AVERAGE_PER_UNIT: 'Average per unit',
    TOTAL_PAGE_COMMENTS: 'Made by unit',
    COMPARED_TO: 'Compared to the',
    FILTER: 'Filter',
    AND: 'when the value was',
    PERIOD: 'Period',
    EMPTY_DESCRIPTION: 'There is no data.',
    RANK_TITLE: 'Ranking per unit',
    SEE_PREVIEW: 'See',
    POST: 'Posts',
    NAME: 'Name',
    DESCRIPTION: 'Description',
    TO: 'to',
    METRICS: 'Metrics',
    RESPONSE_RATES_RANK: 'response rates',
  },
};

const CommentsPT = {
  Comments: {
    total_of_comments: 'Total de comentários recebidos e feitos no período.',
    average_per_unit: 'Total de comentários dividido pelo número de páginas.',
    average_by_content: 'Total de comentários dividido por todos os conteúdos produzidos.',
    RESPONSE_RATES_RANK: 'taxas de resposta',
    BEST: 'melhores',
    LOWEST: 'menores',
    TOTAL_OF_COMMENTS: 'Total de comentários',
    REPLY: 'Respondido',
    NO_REPLY: 'Não respondidos',
    POSITION: 'Posição',
    VALUE: 'Valor',
    RATE: 'Taxa',
    RANKING_EVOLUTION: 'Evolução no ranking',
    DAYS: 'dias',
    DAY: 'dia',
    TOTAL_PAGE_COMMENTS: 'Feitos pela unidade',
    AVERAGE_BY_CONTENT: 'Média por conteúdo',
    HOURS: 'horas',
    TITLE: 'Comentários',
    SUBTITLE: 'Saiba como estão as respostas de comentários de suas unidades neste relatório',
    AVERAGE_PER_UNIT: 'Média por unidade',
    TITLE_RANK: 'Ranking',
    AVERAGE_RESPONSE_TIME: 'Taxa média de resposta',
    TITLE_TOOLTIP: 'Comentários tooltip',
    TOTAL_COMMENTS: 'Todos comentários',
    OF_THE_TOTAL: 'do total',
    OF: 'de',
    RESPONSE_RATE: 'Taxa de resposta',
    TOTAL_COMMENTS: 'Todos comentários',
    RESPONSE_RATE: 'Taxa de resposta',
    TOTAL_PAGE_COMMENTS: 'Feitos pela unidade',
    COMPARED_TO: 'Comparado ao periodo de',
    FILTER: 'Filtro',
    AND: 'quando o valor foi de',
    FOOTER_TITLE: 'Mais informações da produção de vídeos',
    PERIOD: 'Período',
    INVESTMENT: 'Investimento',
    EMPTY_DESCRIPTION: 'Não há dados para serem exibidos.',
    RANK_TITLE: 'Ranking das unidades',
    SEE_PREVIEW: 'Ver',
    POST: 'Postagens',
    NAME: 'Nome',
    DESCRIPTION: 'Descrição',
    TO: 'a',
    METRICS: 'Métricas',
    one_hundred: '100%',
  },
};

const CommentsES = {
  Comments: {
    total_of_comments: 'Total de comentarios recibidos y realizados en el período.',
    average_per_unit: 'Total de comentarios dividido por el número de páginas.',
    average_by_content: 'Total de comentarios dividido por todo el contenido producido.',
    RESPONSE_RATES_RANK: 'tasas de respuesta',
    BEST: 'mejores',
    LOWEST: 'menores',
    TOTAL_OF_COMMENTS: 'Comentarios totales',
    REPLY: 'Contestado',
    NO_REPLY: 'Sin respuesta',
    POSITION: 'Posición',
    VALUE: 'Valor',
    RATE: 'Calificar',
    RANKING_EVOLUTION: 'Evolución del ranking:',
    DAYS: 'días',
    DAY: 'día',
    TOTAL_PAGE_COMMENTS: 'Hecho por la unidad',
    AVERAGE_BY_CONTENT: 'Promedio por contenido',
    HOURS: 'horas',
    TITLE: 'Comentarios',
    SUBTITLE: 'Sepa cómo son las respuestas a los comentarios de sus unidades en este informe',
    AVERAGE_PER_UNIT: 'Promedio por unidad',
    TITLE_RANK: 'Ranking',
    AVERAGE_RESPONSE_TIME: 'Índice promedio de respuesta',
    TITLE_TOOLTIP: 'Comentarios tooltip',
    TOTAL_COMMENTS: 'Todos los comentarios',
    OF_THE_TOTAL: 'del total',
    OF: 'en',
    RESPONSE_RATE: 'Índice de respuesta:',
    COMPARED_TO: 'Comparado con el período de',
    FILTER: 'Filtro',
    AND: 'cuando el valor era',
    FOOTER_TITLE: 'Más informaciones sobre producción de vídeos',
    PERIOD: 'Periodo',
    INVESTMENT: 'Inversión',
    EMPTY_DESCRIPTION: 'No hay datos para mostrar.',
    RANK_TITLE: 'Ranking de las unidades',
    SEE_PREVIEW: 'Ver',
    POST: 'Publicaciones',
    NAME: 'Nombre',
    DESCRIPTION: 'Descripción',
    TO: 'a',
    METRICS: 'Indicadores',
    one_hundred: '100%',
  },
};

export { CommentsEN, CommentsPT, CommentsES };
