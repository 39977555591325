import useSWR from 'swr';
import { fetcher } from '@utils/api';

export const useFacebookCities = ({ ids, search = '', limit }) => {
  const urlSearchParams = new URLSearchParams();

  let shouldFetch = false;

  if (ids?.length > 0) {
    shouldFetch = true;

    urlSearchParams.append('ids', ids.join(','));
    urlSearchParams.append('country_code', 'BR');
  } else if (search) {
    shouldFetch = true;

    urlSearchParams.append('q', search);
    urlSearchParams.append('country_code', 'BR');

    if (limit) {
      urlSearchParams.append('limit', limit);
    }
  }

  const url = `/facebook/1.0/cities?${urlSearchParams}`;

  return useSWR(shouldFetch ? url : null, fetcher, {
    revalidateOnFocus: false,
  });
};
