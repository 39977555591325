import styled from 'styled-components';

export const GroupActive = styled.div`
  max-width: 220px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #4c5564;
  font-size: 14px;
  white-space: nowrap;
`;
