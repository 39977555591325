const CustomerSatisfactionEN = {
  CutomerSatisfaction: {
    YES: 'Yes',
    NO: 'No',
    CSAT_QUESION: 'This information was useful to you?',
    CSAT_THANKS: 'Thank you for the answer!',
  },
};

const CustomerSatisfactionPT = {
  CutomerSatisfaction: {
    YES: 'Sim',
    NO: 'Não',
    CSAT_QUESION: 'Essa informação foi útil para você?',
    CSAT_THANKS: 'Obrigado pela sua resposta!',
  },
};

const CustomerSatisfactionES = {
  CutomerSatisfaction: {
    YES: 'Sí',
    NO: 'No',
    CSAT_QUESION: '¿Fue útil esta información?',
    CSAT_THANKS: '¡Gracias por su respuesta!',
  },
};

export { CustomerSatisfactionEN, CustomerSatisfactionPT, CustomerSatisfactionES };
