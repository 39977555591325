import styled from 'styled-components';

export const Container = styled('div')`
  background: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  gap: 30px;
  .image_container {
    margin: 0 auto;
    border: 1px dashed #ccc;
  }
  .image_container-content {
    gap: 30px;
    label {
      margin-bottom: 0;
    }
  }
`;

export const LoggedContainer = styled('div')`
  border: 1px solid #ccc;
  width: 80%;
  margin: 0 auto;
  max-width: 1600px;
  padding: 30px;

  display: flex;
  align-items: center;
  flex-wrap: wrap;

  div.header {
    width: 20%;
    height: 80%;
    max-width: 200px;
    max-height: 250px;
    padding: 16px;
    display: flex;
    gap: 8px;
    flex-direction: column;
    align-items: center;
    background: #f7f7f8;
    border-radius: 8px;
    border: 1px solid darkgrey;
    img {
      border-radius: 50%;
      max-width: 80px;
    }
    h1 {
      font-size: 18px;
    }
  }
  span {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    button {
      background: #cccccc;
      border-radius: 8px;
      border: none;
      padding: 5px 15px;
      box-sizing: border-box;
      text-transform: uppercase;
      cursor: pointer;
      width: 80px;

      :hover {
        font-weight: bold;
      }
      :last-child {
        font-size: 12px;
        width: 100%;
      }
    }
  }
  div:nth-child(2) {
    margin: 0 auto;
    flex-grow: 1;
  }
`;

export const URLContainer = styled('div')`
  div.url {
    margin-top: 10px;
  }
`;

export const LogoContainer = styled('span')``;
