// Api
import { ApiTenant } from '@utils/api';

class SurveyService {
  static getPending(version, userid) {
    return ApiTenant.get(`/survey/${version}/pending/${userid}?origin=WEB`);
  }

  static submitSurvey(version, data) {
    return ApiTenant.post(`/survey/${version}/submit`, data);
  }

  static submitBatch(version, surveyId, data) {
    return ApiTenant.post(`/surveys/${version}/answer/${surveyId}/batch`, data);
  }
}

export default SurveyService;
