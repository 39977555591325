import { useEffect, useState } from 'react';
import { CompanyService } from '@services';

export default function useTenant(code) {
  const [tenant, setTenant] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadTotalUnits = async () => {
      setLoading(true);

      const response = await CompanyService.getCompany();

      if (response?.data?.is_success) {
        setTenant(response.data.payload);
      }

      setLoading(false);
    };

    loadTotalUnits();
  }, [code]);

  const refetch = async () => {
    const response = await CompanyService.getCompany();

    if (response.data.is_success) {
      setTenant(response.data.payload);
    }
  };

  return {
    tenant,
    loading,
    refetch,
  };
}
