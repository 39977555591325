// Text Avatar conditionals
const cpa = (spend, purchase) => {
  const cpa = spend / purchase;

  if (purchase === 0) {
    return 0;
  }

  return cpa;
};

const vr = (videoViews, impressions) => (videoViews / (impressions || 1)) * 100;

const cpm = (spend, impressions) => (spend / (impressions || 1)) * 1000;

const cpc = (spend, clicks) => spend / (clicks || 1);

const cplc = (spend, linkClick) => spend / (linkClick || 1);

const ctr = (click, impressions) => ((click * 100)/ (impressions || 1));

const cpe = (spend, postEngagement) => spend / (postEngagement || 1);

const cpi = (spend, involvement) => spend / (involvement || 1);

const ir = (involvement, impressions) => (involvement / (impressions || 1)) * 100;

const er = (postEngagement, impressions) => (postEngagement / (impressions || 1)) * 100;

const cpv = (spend, videoView) => (videoView !== 0 ? spend / videoView : 0);

const cpr = (spend, reach) => spend / (reach || 1);

const cpmr = (spend, reach) => (spend / (reach || 1)) * 1000;

const rer = (reach, postEngagement) => (reach !== 0 ? (postEngagement / reach) * 100 : 0);

export {
  cpa, vr, cpm, cpc, ctr, cpe, er, cpv, cpr, cpmr, cpi, ir, rer, cplc,
};
