import React, { useState } from 'react';
import { useTranslate } from 'react-translate';
import { useParams } from 'react-router-dom';
import FilterStatus from './FilterStatus';
import FilterRole from './FilterRole';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FETCH_LIST_USERS } from '@redux/business/types';
import { SET_FILTERS } from '@redux/users/types';
import * as S from './styles';

function UsersFilters() {
  const t = useTranslate('User');
  const { code } = useParams();

  const [statusFilter, setStatusFilter] = useState('all');
  const [roleFilter, setRoleFilter] = useState('all');
  const [queryFilter, setQueryFilter] = useState('');
  const dispatch = useDispatch();

  const searching = debounce(({ query, role, status }) => {
    let tempQuery = query || queryFilter;
    const tempRole = role || roleFilter;
    const tempStatus = status || statusFilter;
    if (query === '') {
      tempQuery = query;
    }

    dispatch({
      type: FETCH_LIST_USERS.RESET,
    });

    setQueryFilter(tempQuery);

    dispatch({
      type: SET_FILTERS.SUCCESS,
      filters: { query: tempQuery, status: tempStatus, role: tempRole },
    });

    if (tempQuery?.length > 0) {
      if (tempRole === 'all' && tempStatus === 'all') {
        dispatch({
          type: FETCH_LIST_USERS.REQUEST,
          version: '1.2',
          search: `fields=user_id,current_step,role,status,language,user_name,user_last_name,avatar_url,email,permitted_groups,capabilities,facebook_specs&limit=20&root_permitted_group=${code}&query=${tempQuery}`,
        });
      } else if (tempStatus === 'all') {
        dispatch({
          type: FETCH_LIST_USERS.REQUEST,
          version: '1.2',
          search: `fields=user_id,current_step,role,status,language,user_name,user_last_name,avatar_url,email,permitted_groups,capabilities,facebook_specs&limit=20&root_permitted_group=${code}&query=${tempQuery}&role=${tempRole}`,
        });
      } else if (tempRole === 'all') {
        dispatch({
          type: FETCH_LIST_USERS.REQUEST,
          version: '1.2',
          search: `fields=user_id,current_step,role,status,language,user_name,user_last_name,avatar_url,email,permitted_groups,capabilities,facebook_specs&limit=20&root_permitted_group=${code}&query=${tempQuery}&status=${tempStatus}`,
        });
      } else {
        dispatch({
          type: FETCH_LIST_USERS.REQUEST,
          version: '1.2',
          search: `fields=user_id,current_step,role,status,language,user_name,user_last_name,avatar_url,email,permitted_groups,capabilities,facebook_specs&limit=20&root_permitted_group=${code}&query=${tempQuery}&status=${tempStatus}&role=${tempRole}`,
        });
      }
    } else {
      if (tempRole !== 'all') {
        filterRole(roleFilter);
      } else if (statusFilter !== 'all') {
        filterStatus(statusFilter);
      } else {
        dispatch({
          type: FETCH_LIST_USERS.REQUEST,
          version: '1.2',
          search: `fields=user_id,current_step,role,status,language,user_name,user_last_name,avatar_url,email,permitted_groups,capabilities,facebook_specs&limit=20&root_permitted_group=${code}`,
        });
      }
    }
  }, 500);

  function filterStatus(value) {
    dispatch({
      type: FETCH_LIST_USERS.RESET,
    });

    setStatusFilter(value);

    dispatch({
      type: SET_FILTERS.SUCCESS,
      filters: { query: queryFilter, status: value, role: roleFilter },
    });

    if (queryFilter !== '') {
      searching({ status: value });
    } else {
      if (value !== 'all') {
        if (roleFilter !== 'all') {
          dispatch({
            type: FETCH_LIST_USERS.REQUEST,
            version: '1.2',
            search: `fields=user_id,current_step,role,status,language,user_name,user_last_name,avatar_url,email,permitted_groups,capabilities,facebook_specs&limit=20&root_permitted_group=${code}&status=${value}&role=${roleFilter}`,
          });
        } else {
          dispatch({
            type: FETCH_LIST_USERS.REQUEST,
            version: '1.2',
            search: `fields=user_id,current_step,role,status,language,user_name,user_last_name,avatar_url,email,permitted_groups,capabilities,facebook_specs&limit=20&root_permitted_group=${code}&status=${value}`,
          });
        }
      } else {
        if (roleFilter !== 'all') {
          dispatch({
            type: FETCH_LIST_USERS.REQUEST,
            version: '1.2',
            search: `fields=user_id,current_step,role,status,language,user_name,user_last_name,avatar_url,email,permitted_groups,capabilities,facebook_specs&limit=20&root_permitted_group=${code}&role=${roleFilter}`,
          });
        } else {
          dispatch({
            type: FETCH_LIST_USERS.REQUEST,
            version: '1.2',
            search: `fields=user_id,current_step,role,status,language,user_name,user_last_name,avatar_url,email,permitted_groups,capabilities,facebook_specs&limit=20&root_permitted_group=${code}`,
          });
        }
      }
    }
  }

  function filterRole(value) {
    dispatch({
      type: FETCH_LIST_USERS.RESET,
    });

    setRoleFilter(value);

    dispatch({
      type: SET_FILTERS.SUCCESS,
      filters: { query: queryFilter, status: statusFilter, role: value },
    });

    if (queryFilter !== '') {
      searching({ role: value });
    } else {
      if (value !== 'all') {
        if (statusFilter !== 'all') {
          dispatch({
            type: FETCH_LIST_USERS.REQUEST,
            version: '1.2',
            search: `fields=user_id,current_step,role,status,language,user_name,user_last_name,avatar_url,email,permitted_groups,capabilities,facebook_specs&limit=20&root_permitted_group=${code}&role=${value}&status=${statusFilter}`,
          });
        } else {
          dispatch({
            type: FETCH_LIST_USERS.REQUEST,
            version: '1.2',
            search: `fields=user_id,current_step,role,status,language,user_name,user_last_name,avatar_url,email,permitted_groups,capabilities,facebook_specs&limit=20&root_permitted_group=${code}&role=${value}`,
          });
        }
      } else {
        if (statusFilter !== 'all') {
          dispatch({
            type: FETCH_LIST_USERS.REQUEST,
            version: '1.2',
            search: `fields=user_id,current_step,role,status,language,user_name,user_last_name,avatar_url,email,permitted_groups,capabilities,facebook_specs&limit=20&root_permitted_group=${code}&status=${statusFilter}`,
          });
        } else {
          dispatch({
            type: FETCH_LIST_USERS.REQUEST,
            version: '1.2',
            search: `fields=user_id,current_step,role,status,language,user_name,user_last_name,avatar_url,email,permitted_groups,capabilities,facebook_specs&limit=20&root_permitted_group=${code}`,
          });
        }
      }
    }
  }

  return (
    <>
      <FontAwesomeIcon
        icon={['fal', 'search']}
        style={{ fontSize: '14px', color: '#577584', marginRight: '4px' }}
      />
      <S.FilterInput
        onChange={(e) => searching({ query: e.target.value })}
        placeholder={t('USERS_FILTERS_INPUT_PLACEHOLDER')}
      />
      <FilterStatus onChange={filterStatus} status={statusFilter} />
      <S.Spacing />
      <FilterRole onChange={filterRole} />
    </>
  );
}

export default UsersFilters;
