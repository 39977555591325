const OverallEN = {
  Overall: {
    BUDGET_GOAL: 'Change period goal',
    BUDGET_ALLOCATED: 'See history',
    BUDGET_NOT_ALLOCATED: 'Change budget in large scale',
    GOAL_PERIOD: 'Goal period',
    ALLOCATED: 'Allocated',
    NOT_ALLOCATED: 'Not allocated',
    ACCS_AND_GROUPS: 'Accounts and groups',
    SPENT: 'Spent',
    BUDGET: 'Budget',
    ACCOUNT: 'Account',
    TOTAL: 'Total',
    SPEND: 'Spend',
    REMANING: 'Remaning budget',
    CHANGE_BUDGET_MODAL_TITLE: 'Mass Budget',
    CHANGE_BUDGET_MODAL_DESCRIPTION: 'Add budget to all divisional stores',
    CHANGE_BUDGET_MODAL_PLACEHOLDER: 'Value to add',
    CHANGE_GOAL_MODAL_TITLE: 'Edit period goal',
    CHANGE_GOAL_MODAL_PLACEHOLDER: 'New period goal',
    SUCCESS_MESSAGE_CHANGE_BUDGET: 'Budget changed successfully!',
    ERROR_MESSAGE_NOT_ENOUGH_BUDGET: "You don't have enough budget, please send another value.",
    BATCH_BUDGET_COOLDOWN_PERIOD:
      'Error when changing the monthly goal, it is still on hold, try again in ',
    ERROR_MESSAGE_WAIT:
      'A mass distribution is already in progress. Wait for it to finish before starting a new one.',
    ERROR_MESSAGE_INVALID: 'Bulk distribution is only supported in Groups.',
    ERROR_MESSAGE_CHANGE_BUDGET: 'Error on changing budget, try again later!',
    SUCCESS_MESSAGE_EDIT_GOAL:
      'Period goal was changed successfully, please wait a moment for the values to be updated!',
    ERROR_MESSAGE_EDIT_GOAL: 'Error on change monthly goal, try again later!',
    BUTTON_CONFIRM: 'Confirm',
    OPEN: 'Open',

    IMPRESSIONS: 'Impressions',
    VIDEO_VIEWS: 'Video Views',
    ENGAGEMENT: 'Engagement',
    RATE_ENGAGEMENT: 'Engagement Rate',
    PAGES: 'Pages',
    STRATEGIES: 'Strategies',
    ACCOUNTS: 'Accounts',
    GROUPS: 'Groups',
    DEPOSIT: 'Deposit',
    REMOVE: 'Remove',
    TITLE_DEPOSIT: 'Add budget',
    TITLE_REMOVE: 'Remove budget',
    SUBTITLE_DEPOSIT: 'Increase the budget amount',
    SUBTITLE_REMOVE: 'Decreate the budget amount',
    MAXIMUM: 'Maximum',
    NEW_BUDGET: 'New budget',
    BUTTON_CANCEL: 'Cancel',
    SUCCESS_DEPOSIT: 'Deposit was successful',
    ERROR_DEPOSIT: 'Deposit failed',
    SUCCESS_WITHDRAW: 'Withdrawal was successful',
    ERROR_WITHDRAW: 'Withdrawal failed',
    INSUFFICIENT_BUDGET: 'Not enough budget',
    TYPE_HERE: 'Type the value here',
    DISABLED_AD_ACCOUNT: 'Disabled ad account',

    INTELLIGENT_DISTRIBUTION_SUCCESS: 'Smart budget allocation applied succesfully.',
    INTELLIGENT_DISTRIBUTION_ERROR:
      'An error occurred while trying to apply smart budget allocation.',
    INTELLIGENT_AMOUT_DISTRIBUTION_ACTIVATED: 'Smart budget allocation activated.',
    INTELLIGENT_AMOUT_DISTRIBUTION_DISABLE: 'Smart budget allocation deactivated.',
    INTELLIGENT_AMOUT_DISTRIBUTION_DESCRIPTION: 'Smart budget allocation system.',
    KNOW_MORE: 'Know more',
    INTELLIGENT_DISTRIBUTION_OPEN_MODAL:
      'Now you can achieve better results by automating the allocation of budget between stores.',
    INTELLIGENT_DISTRIBUTION_OPEN_MODAL_1:
      'The system identifies units with low consumption and automatically distributes part of the budget to stores with a sustainable consumption pattern.',
    INTELLIGENT_DISTRIBUTION_INFORMATION_MODAL:
      'Smart redistribution has specific rules to reward stores that have a healthy consumption pattern within their budget and minimize the impacts of stores with low consumption.',
    INTELLIGENT_DISTRIBUTION_INFORMATION_MODAL_1:
      'The redistribution takes place on the 15th of the month, following the rule:',
    INTELLIGENT_DISTRIBUTION_INFORMATION_MODAL_2: 'Consumption below 40%:',
    INTELLIGENT_DISTRIBUTION_INFORMATION_MODAL_3:
      'stores in this range will have part of their budget reallocated to stores with healthy consumption in the budget.',
    INTELLIGENT_DISTRIBUTION_INFORMATION_MODAL_4: 'Consumption between 40% and 65%:',
    INTELLIGENT_DISTRIBUTION_INFORMATION_MODAL_5:
      'stores in this range will receive the redistribution of the budget equally considering the maximum limit of 100% of their original budget.',
    INTELLIGENT_DISTRIBUTION_INFORMATION_MODAL_6: 'Consumption bigger than 65%:',
    INTELLIGENT_DISTRIBUTION_INFORMATION_MODAL_7: 'keep the budget unchanged.',
    INTELLIGENT_DISTRIBUTION_INFORMATION_MODAL_8:
      'This functionality can be activated or deactivated at any time and, if activated, the redistribution will only take place on the 15th of the month.',

    CANCEL_BUTTON: 'Cancel',
    ACTIVE: 'Activate',
    INTELIGENT_DISTRIBUTION: 'Smart allocation!',
    CONFIRM_LABEL: 'Got it!',
    FORCE_MESSAGE: 'Force even if there is no budget in the upper groups.',
    RESET: 'Reset',
    TITLE_RESET: 'Reset budget',
    RESET_MESSAGE_ONE:
      'The unit will lose all remaining budget, and ads will no longer be sponsored from this point on.',
    RESET_MESSAGE_TWO: 'Click "confirm" if you are sure this is what you want.',
    SUCCESS_RESET: 'Success when resetting budget.',
    ERROR_RESET: 'Error resetting. Try again later!',
  },
};

const OverallPT = {
  Overall: {
    BUDGET_GOAL: 'Alterar meta do período',
    BUDGET_ALLOCATED: 'Ver histórico',
    BUDGET_NOT_ALLOCATED: 'Alterar orçamento em massa',
    GOAL_PERIOD: 'Meta do período',
    ALLOCATED: 'Verba alocada',
    NOT_ALLOCATED: 'Verba não alocada',
    ACCS_AND_GROUPS: 'Grupos e contas',
    SPENT: 'Gastos',
    BUDGET: 'Verba',
    ACCOUNT: 'Conta',
    TOTAL: 'Total',
    SPEND: 'Gasto',
    REMANING: 'Verba restante',
    CHANGE_BUDGET_MODAL_TITLE: 'Orçamento em massa',
    CHANGE_BUDGET_MODAL_DESCRIPTION: 'Adicionar orçamento em todas as lojas do grupo',
    CHANGE_BUDGET_MODAL_PLACEHOLDER: 'Valor a adicionar',
    CHANGE_GOAL_MODAL_TITLE: 'Editar meta do período',
    CHANGE_GOAL_MODAL_PLACEHOLDER: 'Nova meta do período',
    SUCCESS_MESSAGE_CHANGE_BUDGET: 'Orçamento alterado com sucesso!',
    BATCH_BUDGET_COOLDOWN_PERIOD:
      'Erro ao alterar a meta mensal, a mesma ainda encontra-se em espera, tente novamente em ',
    ERROR_MESSAGE_NOT_ENOUGH_BUDGET:
      'Você não tem orçamento suficiente, por favor envie outro valor.',
    ERROR_MESSAGE_WAIT:
      'Uma distribuição em massa já está em andamento. Aguarde a finalização para iniciar uma nova.',
    ERROR_MESSAGE_INVALID: 'Distribuição em massa só é suportada em grupos',
    ERROR_MESSAGE_CHANGE_BUDGET: 'Erro ao alterar orçamento, tente novamente mais tarde!',
    SUCCESS_MESSAGE_EDIT_GOAL:
      'Meta do período foi alterada com sucesso, aguarde alguns instantes para que os valores sejam atualizados!',
    ERROR_MESSAGE_EDIT_GOAL: 'Erro ao alterar a meta mensal, tente novamente mais tarde!',
    BUTTON_CONFIRM: 'Confirmar',
    OPEN: 'Abrir',

    IMPRESSIONS: 'Impressões',
    VIDEO_VIEWS: 'Visualizações',
    ENGAGEMENT: 'Engajamentos',
    RATE_ENGAGEMENT: 'Taxa de engajamento',
    PAGES: 'Páginas',
    STRATEGIES: 'Estratégias',
    ACCOUNTS: 'Contas',
    GROUPS: 'Grupos',
    DEPOSIT: 'Depositar',
    REMOVE: 'Remover',
    TITLE_DEPOSIT: 'Depositar verba',
    TITLE_REMOVE: 'Retirar verba',
    SUBTITLE_DEPOSIT: 'Aumente o valor do orçamento',
    SUBTITLE_REMOVE: 'Diminua o valor do orçamento',
    MAXIMUM: 'Máximo',
    NEW_BUDGET: 'Novo orçamento',
    BUTTON_CANCEL: 'Cancelar',
    SUCCESS_DEPOSIT: 'Sucesso ao realizar o depósito!',
    ERROR_DEPOSIT: 'Falha ao realizar o depósito, tente novamente mais tarde!',
    SUCCESS_WITHDRAW: 'Sucesso ao remover o orçamento!',
    ERROR_WITHDRAW: 'Falha ao remover o orçamento, tente novamente mais tarde!',
    INSUFFICIENT_BUDGET: 'Saldo insuficiente.',
    TYPE_HERE: 'Digite o valor aqui',
    DISABLED_AD_ACCOUNT: 'Conta de anúncios desativada',

    INTELLIGENT_DISTRIBUTION_SUCCESS: 'Redistribuição inteligente de verba aplicada com sucesso.',
    INTELLIGENT_DISTRIBUTION_ERROR:
      'Ocorreu um erro ao tentar aplicar a redistribuição inteligente de verba.',
    INTELLIGENT_AMOUT_DISTRIBUTION_ACTIVATED: 'Redistribuição de verba inteligente ativada.',
    INTELLIGENT_AMOUT_DISTRIBUTION_DISABLE: 'Redistribuição de verba inteligente desativada.',
    INTELLIGENT_AMOUT_DISTRIBUTION_DESCRIPTION: 'Sistema de redistribuição de verba inteligente.',
    KNOW_MORE: 'Saiba mais',
    INTELLIGENT_DISTRIBUTION_OPEN_MODAL:
      'Agora você pode alcançar melhores resultados automatizando a redistribuição de verba entre as lojas.',
    INTELLIGENT_DISTRIBUTION_OPEN_MODAL_1:
      'O sistema identifica unidades com baixo consumo e redistribui parte do orçamento de forma automática para lojas com padrão de consumo sustentável.',
    INTELLIGENT_DISTRIBUTION_INFORMATION_MODAL:
      'A redistribuição inteligente possui regras específicas para premiar lojas que possuem um padrão de consumo saudável do seu orçamento e minimizar os impactos de lojas com baixo consumo.',
    INTELLIGENT_DISTRIBUTION_INFORMATION_MODAL_1:
      'A redistribuição ocorre no 15º dia do mês, seguindo a regra:',
    INTELLIGENT_DISTRIBUTION_INFORMATION_MODAL_2: 'Consumo abaixo de 40%:',
    INTELLIGENT_DISTRIBUTION_INFORMATION_MODAL_3:
      'as lojas desta faixa terão parte da sua verba remanejada para lojas com consumo saudável do orçamento.',
    INTELLIGENT_DISTRIBUTION_INFORMATION_MODAL_4: 'Consumo entre 40% e 65%:',
    INTELLIGENT_DISTRIBUTION_INFORMATION_MODAL_5:
      'as lojas desta faixa receberão a redistribuição da verba igualmente considerando o limite máximo de 100% do seu orçamento original.',
    INTELLIGENT_DISTRIBUTION_INFORMATION_MODAL_6: 'Consumo maior que 65%:',
    INTELLIGENT_DISTRIBUTION_INFORMATION_MODAL_7: 'mantém o orçamento sem alterações.',
    INTELLIGENT_DISTRIBUTION_INFORMATION_MODAL_8:
      'Essa funcionalidade pode ser ativada ou desativada a qualquer momento e, caso esteja ativada, a redistribuição só irá ocorrer no próximo dia 15 do mês.',

    CANCEL_BUTTON: 'Cancelar',
    ACTIVE: 'Ativar',
    INTELIGENT_DISTRIBUTION: 'Redistribuição inteligente!',
    CONFIRM_LABEL: 'Entendi',
    FORCE_MESSAGE: 'Forçar mesmo que não tenha verba nos grupos superiores.',
    RESET: 'Resetar',
    TITLE_RESET: 'Reset de verba',
    RESET_MESSAGE_ONE:
      'A unidade perderá todo o orçamento restante e os anúncios não poderão mais ser patrocinados a partir deste momento.',
    RESET_MESSAGE_TWO: 'Clique em "confirmar" caso tenha certeza que é isso que você quer.',
    SUCCESS_RESET: 'Sucesso ao resetar orçamento.',
    ERROR_RESET: 'Erro ao resetar. Tente novamente mais tarde!',
  },
};

const OverallES = {
  Overall: {
    BUDGET_GOAL: 'Cambiar el objetivo del período',
    BUDGET_ALLOCATED: 'Ver histórico',
    BUDGET_NOT_ALLOCATED: 'Cambiar el presupuesto en masa',
    GOAL_PERIOD: 'Objetivo del período',
    ALLOCATED: 'Importe repartido',
    NOT_ALLOCATED: 'Importe sin asignación',
    ACCS_AND_GROUPS: 'Grupos y cuentas',
    SPENT: 'Gastado',
    BUDGET: 'Importe',
    ACCOUNT: 'Conta',
    TOTAL: 'Total',
    SPEND: 'Gastado',
    REMANING: 'Importes ahorrados',
    CHANGE_BUDGET_MODAL_TITLE: 'Presupuesto masivo',
    CHANGE_BUDGET_MODAL_DESCRIPTION: 'Agregar presupuesto a todas las tiendas del grupo',
    CHANGE_BUDGET_MODAL_PLACEHOLDER: 'Valor para agregar',
    CHANGE_GOAL_MODAL_TITLE: 'Editar objetivo del período',
    CHANGE_GOAL_MODAL_PLACEHOLDER: 'Objetivo de período nuevo',
    SUCCESS_MESSAGE_CHANGE_BUDGET: '¡El presupuesto se modificó correctamente!',
    BATCH_BUDGET_COOLDOWN_PERIOD:
      'Error al cambiar el objetivo mensual, todavía está en espera, inténtelo de nuevo en',
    ERROR_MESSAGE_NOT_ENOUGH_BUDGET: 'No tienes presupuesto suficiente, envía otra cantidad',
    ERROR_MESSAGE_WAIT:
      'Ya está en marcha una distribución masiva. Espere a que se complete para comenzar uno nuevo. ',
    ERROR_MESSAGE_INVALID: 'La distribución masiva solo se admite en grupos',
    ERROR_MESSAGE_CHANGE_BUDGET: 'Error al cambiar el presupuesto. Vuelve a intentarlo más tarde',
    SUCCESS_MESSAGE_EDIT_GOAL:
      'El objetivo del período se modificó correctamente, espere unos momentos para que se actualicen los valores',
    ERROR_MESSAGE_EDIT_GOAL: 'Error al cambiar el objetivo mensual. Vuelve a intentarlo más tarde',
    BUTTON_CONFIRM: 'Confirmar',
    OPEN: 'Abrir',

    IMPRESSIONS: 'Impresiones',
    VIDEO_VIEWS: 'Vistas',
    ENGAGEMENT: 'Compromisos',
    RATE_ENGAGEMENT: 'Tasa de interacción',
    PAGES: 'Páginas',
    STRATEGIES: 'Estrategias',
    ACCOUNTS: 'Cuentas',
    GROUPS: 'Grupos',
    DEPOSIT: 'Depósito',
    REMOVE: 'Eliminar',
    TITLE_DEPOSIT: 'Depositar fondos',
    TITLE_REMOVE: 'Retirar fondos',
    SUBTITLE_DEPOSIT: 'Aumentar el importe del presupuesto',
    SUBTITLE_REMOVE: 'Disminuir el monto del presupuesto',
    MAXIMUM: 'Máximo',
    NEW_BUDGET: 'Nuevo presupuesto',
    BUTTON_CANCEL: 'Cancelar',
    SUCCESS_DEPOSIT: '¡Depósito exitoso!',
    ERROR_DEPOSIT: 'Error al depositar, ¡inténtalo de nuevo más tarde!',
    SUCCESS_WITHDRAW: '¡Se ha realizado correctamente la eliminación del presupuesto!',
    ERROR_WITHDRAW: 'No se pudo eliminar el presupuesto. Vuelve a intentarlo más tarde',
    INSUFFICIENT_BUDGET: 'Saldo insuficiente.',
    TYPE_HERE: 'Ingrese valor aquí',
    DISABLED_AD_ACCOUNT: 'Cuenta de anuncios inhabilitada',

    INTELLIGENT_DISTRIBUTION_SUCCESS:
      'Redistribución inteligente de fondos aplicada correctamente.',
    INTELLIGENT_DISTRIBUTION_ERROR:
      'Se produjo un error al intentar aplicar la redistribución inteligente.',
    INTELLIGENT_AMOUT_DISTRIBUTION_ACTIVATED: 'Redistribución de asignación inteligente activada',
    INTELLIGENT_AMOUT_DISTRIBUTION_DISABLE:
      'Redistribución inteligente de subvenciones inhabilitada',
    INTELLIGENT_AMOUT_DISTRIBUTION_DESCRIPTION:
      'Sistema inteligente de redistribución de subvenciones',
    KNOW_MORE: 'Más información',
    INTELLIGENT_DISTRIBUTION_OPEN_MODAL:
      'Ahora puedes lograr mejores resultados automatizando la redistribución de fondos entre tiendas.',
    INTELLIGENT_DISTRIBUTION_OPEN_MODAL_1:
      'El sistema identifica unidades con bajo consumo y automáticamente redistribuye parte del presupuesto a tiendas con patrón de consumo sustentable.',
    INTELLIGENT_DISTRIBUTION_INFORMATION_MODAL:
      'La redistribución inteligente tiene reglas específicas para recompensar a las tiendas que tienen un patrón de consumo saludable dentro de su presupuesto y minimizar el impacto de las tiendas con bajo consumo.',
    INTELLIGENT_DISTRIBUTION_INFORMATION_MODAL_1:
      'La redistribución se realiza el día 15 del mes, siguiendo la regla:',
    INTELLIGENT_DISTRIBUTION_INFORMATION_MODAL_2: 'Consumo por debajo del 40%:',
    INTELLIGENT_DISTRIBUTION_INFORMATION_MODAL_3:
      'las tiendas en este rango tendrán parte de su presupuesto reasignado a tiendas con un presupuesto de consumo saludable.',
    INTELLIGENT_DISTRIBUTION_INFORMATION_MODAL_4: 'Consumo entre 40% y 65%:',
    INTELLIGENT_DISTRIBUTION_INFORMATION_MODAL_5:
      'las tiendas en este rango recibirán la redistribución del presupuesto igualmente considerando el límite máximo del 100% de su presupuesto original.',
    INTELLIGENT_DISTRIBUTION_INFORMATION_MODAL_6: 'Consumo superior al 65%:',
    INTELLIGENT_DISTRIBUTION_INFORMATION_MODAL_7: 'mantener el presupuesto sin cambios.',
    INTELLIGENT_DISTRIBUTION_INFORMATION_MODAL_8:
      'Esta funcionalidad se puede activar o desactivar en cualquier momento y, si está activada, la redistribución solo se realizará el próximo día 15 del mes.',

    CANCEL_BUTTON: 'Cancelar',
    ACTIVE: 'Activar',
    INTELIGENT_DISTRIBUTION: '¡Redistribución inteligente!',
    CONFIRM_LABEL: 'Entiendo',
    FORCE_MESSAGE: 'Obligar aunque no haya presupuesto en los grupos superiores.',
    RESET: 'Reiniciar',
    RESET_MESSAGE_ONE:
      'La unidad perderá el presupuesto restante y los anuncios ya no serán patrocinados a partir de este momento.',
    RESET_MESSAGE_TWO: 'Haz clic en "confirmar" si estás seguro de que esto es lo que quieres',
    TITLE_RESET: 'Restablecer presupuesto',
    SUCCESS_RESET: 'Éxito al restablecer el presupuesto.',
    ERROR_RESET: 'Error al restablecer. Vuelve a intentarlo más tarde!',
  },
};

export { OverallEN, OverallPT, OverallES };
