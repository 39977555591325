import { SHOW_TOAST } from '@redux/toast/types';
import { useDispatch } from 'react-redux';

function useShowToast() {
  const dispatch = useDispatch();

  const showToast = (message, type) => {
    dispatch({
      type: SHOW_TOAST.SUCCESS,
      message,
      messageType: type,
    });
  };

  return showToast;
}

export default useShowToast;
