// Types
import { CHANGE_LANGUAGE } from './types';

const INITIAL_STATE = {
  language: '',
  changedByUser: false,
};

const translatorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE.SUCCESS:
      // Só irá alterar a linguagem do sistema se o usuário clicar para alterar ou carregará a linguagem default do usuário
      if (state.changedByUser && !action.changedByUser) {
        return state;
      }
      return {
        ...state,
        language: action.language,
        changedByUser: !!action.changedByUser,
      };

    default:
      return state;
  }
};

export default translatorReducer;
