import React, { useEffect, useRef, useState } from 'react';

import { useRouteMatch, useLocation, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'react-translate';

import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import Title from 'components/Title';
import Button from 'components/Button';
import CustomModal from 'components/CustomModal/CustomModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SET_DATE_RANGE } from '@redux/generic/types';
import * as S from './styles';
import { Typography, TYPOGRAPHY_VARIANTS } from '@bornlogic/reborn';
import DateRange from 'components/DateRange';
import { useWindowDimensions } from 'utils/hooks';

function getStoreValues(state) {
  return {
    translatorReducer: state.translatorReducer,
    genericReducer: state.genericReducer,
  };
}

function ButtonCalendar() {
  const dateRangeRef = useRef(null);
  const [opened, setOpened] = useState(false);
  const [dateMarked, setDateMarked] = useState(null);
  const [isPreset, setIsPreset] = useState(false);
  const [itemSelected, setItemSelected] = useState({ datePreset: '' });

  const t = useTranslate('ButtonCalendar');
  const dispatch = useDispatch();
  const {
    params: { code, module },
  } = useRouteMatch();
  const location = useLocation();
  const { search } = location;
  const history = useHistory();
  const {
    translatorReducer,
    genericReducer: { current, currentItem, period },
  } = useSelector(getStoreValues);
  const [ranges, setRanges] = useState([]);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (!opened && period.start) {
      setRanges([
        {
          startDate: dayjs(period.start).toDate(),
          endDate: dayjs(period.end).toDate(),
          key: 'selection',
        },
      ]);
    }
  }, [period, opened]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const currentData =
      code === 'root' ? !!current.data && current.data : !!currentItem.data && currentItem.data;
    const datePreset = params.get('date_preset');
    let since = params.get('since');
    let until = params.get('until');

    if (!until || !since) {
      since = currentData.start_date;
      until = currentData.end_date;
      if (!datePreset && module === 'Overview') {
        history.push('?custom_date=true');
      }
    }
    if (currentData) {
      if (datePreset) {
        setDateMarked(datePreset.toUpperCase());
        setIsPreset(true);

        dispatch({
          type: SET_DATE_RANGE.REQUEST,
          update: true,
          currentData,
          period: {
            datePreset: datePreset.toUpperCase(),
          },
        });
      } else if (until && since) {
        setDateMarked(`${since} ${until}`);
        setIsPreset(false);
        dispatch({
          type: SET_DATE_RANGE.REQUEST,
          update: true,
          currentData,
          period: {
            startDate: since,
            endDate: until,
          },
        });
      }
    }
  }, [
    current.data,
    search,
    current.isLoading,
    currentItem.isLoading,
    location.search,
    code,
    currentItem.data,
    module,
    history,
    dispatch,
  ]);

  useEffect(() => {
    if (translatorReducer.language === 'pt-BR') {
      dayjs.locale('pt');
    } else if (translatorReducer.language === 'en-US') {
      dayjs.locale('en');
    }
  }, [translatorReducer]);

  function saveDateRange(selectedSearch) {
    let searchToPush;

    if (selectedSearch.datePreset) {
      searchToPush = `date_preset=${selectedSearch.datePreset.toLowerCase()}`;
    } else {
      const since = dayjs(selectedSearch.start).format('YYYY-MM-DD');
      const until = dayjs(selectedSearch.end).format('YYYY-MM-DD');
      searchToPush = `since=${since}&until=${until}`;
      if (selectedSearch.isCustomOption === true) {
        searchToPush += '&custom_date=true';
      }
    }

    searchToPush = verifyOtherParams(searchToPush);

    if (!history.location.search.includes(searchToPush)) {
      history.push({
        search: searchToPush,
      });
    }

    setOpened(false);
  }

  function verifyOtherParams(searchToPush) {
    const urlParams = new URLSearchParams(location.search);

    for (const item of urlParams) {
      if (
        item[0] !== 'since' &&
        item[0] !== 'until' &&
        item[0] !== 'date_preset' &&
        item[0] !== 'custom_date'
      ) {
        searchToPush += `&${item[0]}=${item[1]}`;
      }
    }

    return searchToPush;
  }

  function handleSelect(select) {
    dateRangeRef.current.focus();
    setRanges([
      {
        key: 'selection',
        startDate: select.start,
        endDate: select.end,
      },
    ]);
    setItemSelected(select);
  }

  useEffect(() => {
    function checkKeyUp(e) {
      if (e.keyCode === 27) setOpened(false);
    }

    document.addEventListener('keyup', checkKeyUp);

    return () => {
      document.removeEventListener('keyup', checkKeyUp);
    };
  }, []);

  return (
    <>
      {dateMarked && (
        <S.ButtonStyled onClick={() => setOpened(true)}>
          <S.IconStyled>
            <FontAwesomeIcon icon={['fal', 'calendar-alt']} />
          </S.IconStyled>
          <Typography typography={TYPOGRAPHY_VARIANTS.paragraph2}>
            {isPreset
              ? t(dateMarked.toUpperCase())
              : `${dayjs(dateMarked.split(' ')[0]).format('ll')} -
                ${dayjs(dateMarked.split(' ')[1]).format('ll')}`}
          </Typography>
        </S.ButtonStyled>
      )}
      {opened && (
        <CustomModal
          visible={opened}
          effect="fadeInDown"
          width={width > 800 ? '70%' : '95%'}
          maxWidth="1000px"
          onClickAway={() => setOpened(false)}
        >
          <S.TitleStyle>
            <Title tag="h2" color="black1">
              {t('TITLE-DATE-RANGE')}
            </Title>
          </S.TitleStyle>
          <DateRange ranges={ranges} handleSelectButtonCalendar={handleSelect} />
          <S.ButtonApply>
            <Button
              innerRef={dateRangeRef}
              backgroundColor="blueSecurity"
              onClick={() => saveDateRange(itemSelected)}
            >
              {t('APPLY')}
            </Button>
          </S.ButtonApply>
        </CustomModal>
      )}
    </>
  );
}

export default ButtonCalendar;
