import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AuthenticatedComponents from './AuthenticatedComponents';
import { useUser } from 'utils/contexts';

import AuthLogin from 'containers/AuthLogin';
import PrivacyTerms from 'containers/PrivacyTerms/LoggedOut';
import GoogleLogin from 'containers/GoogleLogin';
import UploadMN from 'containers/UploadMN';

const MyRouter = () => {
  const { hasUser } = useUser();
  return (
    <Switch>
      <Route path="/Upload">
        <UploadMN />
      </Route>
      <Route path="/GoogleLogin">
        <GoogleLogin />
      </Route>
      <Route path="/AuthLogin">
        <AuthLogin />
      </Route>
      <Route path="/PrivacyTerms">
        <PrivacyTerms />
      </Route>
      {hasUser() ? <AuthenticatedComponents /> : <Redirect to="/AuthLogin" />}
    </Switch>
  );
};

export default MyRouter;
