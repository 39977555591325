import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

const EmptyContent = ({ title, message }) => (
  <S.NoContent>
    {title ? <S.StrongParagraph>{title}</S.StrongParagraph> : null}
    {message}
  </S.NoContent>
);

EmptyContent.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
};

EmptyContent.defaultProps = {
  title: '',
  message: '',
};

export default EmptyContent;
