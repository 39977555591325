import React from 'react';

import { useHistory, useRouteMatch } from 'react-router';
import Image from '@components/Image';

import InputDefault from '@components/InputDefault/InputDefault';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import images from '@images';
import * as S from './styles';

function AutoComplete({
  icon,
  options,
  onChange,
  typed,
  value,
  innerRef,
  data,
  focused,
  onKeyUp,
  onKeyDown,
  closeAutocomplete,
  ...rest
}) {
  const { params } = useRouteMatch();
  const history = useHistory();

  function renderItems(options) {
    const path = history.location.pathname.split('/')[2];

    return options.map((item, index) => {
      if (item.id) {
        let urlTo = `/${item.id}/${path}`;
        if (params.module) {
          urlTo += `/${params.module}`;
        }
        if (params.group) {
          urlTo += `/${params.group}`;
        }

        return (
          <li key={item.id}>
            <S.AutoCompleteLink to={urlTo} onClick={closeAutocomplete} tabIndex={index + 1}>
              {item.value}
            </S.AutoCompleteLink>
          </li>
        );
      }
      if (typed.length) {
        return <S.AutoCompleteSpan key={item.value}>{item.value}</S.AutoCompleteSpan>;
      }

      return null;
    });
  }

  return (
    <form autoComplete="off">
      <S.AutoCompleteWrapper>
        <S.AutoCompleteBody focused={focused}>
          {focused && (
            <>
              <Image src={images.logoGd} alt="Gerentes Digitais" logo />
              <InputDefault
                icon={icon}
                onChange={onChange}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
                value={value}
                innerRef={innerRef}
                typed={typed}
                withAutoComplete
                searchAbsolute
                data={data}
                withoutBorder
                tabIndex={1}
                {...rest}
              />
              <S.AutoCompleteContent focused={focused}>
                <S.AutoCompleteResult options={options} typed={typed} focused={focused}>
                  {renderItems(options, typed)}
                </S.AutoCompleteResult>
              </S.AutoCompleteContent>
              <S.ButtonClose focused={focused} onClick={closeAutocomplete} type="button">
                <FontAwesomeIcon icon={['fal', 'times']} />
              </S.ButtonClose>
            </>
          )}
        </S.AutoCompleteBody>
      </S.AutoCompleteWrapper>
    </form>
  );
}

export default AutoComplete;
