import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => css`
    margin-top: ${theme.spacing.px10};
    p,
    svg {
      color: ${theme.colors.blueGrey2};
      font-size: ${theme.fontSize.px14};
    }
  `}
`;

export const Info = styled.div`
  width: 75%;
  ${({ theme, full, idAd, isInstagram }) => css`
    background: ${theme.colors.white};
    border-radius: ${theme.rounded.px10};
    border: 1px solid ${theme.colors.grey8};
    padding: ${theme.spacing.px14} ${theme.spacing.px24};
    .first-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      ${isInstagram &&
      css`
        justify-content: flex-start;
        > div {
          margin: 0 ${theme.spacing.px10};
        }
      `}
      font-size: ${theme.fontSize.px12};
      color: ${theme.colors.blueGrey1};
      ${idAd &&
      css`
        margin-bottom: ${theme.spacing.px10};
      `}
      div:nth-last-child(1) {
        p {
          color: ${theme.colors.blue2};
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      div {
        display: flex;
        align-items: center;
      }
      svg {
        margin-right: ${theme.spacing.px10};
      }
    }
    .last-content {
      text-align: center;
    }
    ${full &&
    css`
      width: ${theme.spacing.full};
    `}
  `}
`;

export const AdDataWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${({ theme }) => css`
    font-size: ${theme.fontSize.px12};
    color: ${theme.colors.blueGrey2};
  `}
`;

export const SecondContentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;

  ${({ theme }) => css`
    font-size: ${theme.fontSize.px12};
    color: ${theme.colors.blueGrey1};
    margin-top: ${theme.spacing.px10};
    margin-bottom: ${theme.spacing.px10};
  `}
`;

export const FirstElement = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    width: ${theme.spacing.px100};
  `}
`;

export const SecondElement = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`;

export const ValueDonate = styled.div`
  width: fit-content;

  ${({ theme }) => css`
    padding-left: ${theme.spacing.px12};
  `}
`;

export const ValueLink = styled.div`
  width: fit-content;

  ${({ theme }) => css`
    padding-left: ${theme.spacing.px10};
  `}
`;
