const MenuProfileEN = {
  MenuProfile: {
    'PROFILE-SETTINGS': 'View my account',
    'EDIT-PROFILE-SETTINGS': 'Edit my account',
    LOGOUT: 'Logout',
    DEFAULTUSER: 'Employee',
    ADMIN: 'Admin',
    'USER-CONFIG': 'Users configs',
    SUPPORT: 'Support',
    CONFIG: 'Settings',
    GDSCORE: 'GD Score',
    PRIVACY: 'Privacy',
    SEARCH: 'Search',
    CLEAR: 'Clear',
    PROFILECOVER: 'Profile & Cover',
  },
};

const MenuProfilePT = {
  MenuProfile: {
    'PROFILE-SETTINGS': 'Visualizar minha conta',
    'EDIT-PROFILE-SETTINGS': 'Editar minha conta',
    LOGOUT: 'Sair',
    DEFAULTUSER: 'Colaborador',
    ADMIN: 'Administrador',
    'USER-CONFIG': 'Configurações de usuários',
    SUPPORT: 'Suporte',
    CONFIG: 'Configurações',
    GDSCORE: 'GD Score',
    PRIVACY: 'Privacidade',
    SEARCH: 'Buscar',
    CLEAR: 'Limpar',
    PROFILECOVER: 'Perfil e capa',
  },
};

const MenuProfileES = {
  MenuProfile: {
    'PROFILE-SETTINGS': 'Ver mi cuenta',
    'EDIT-PROFILE-SETTINGS': 'Editar mi cuenta',
    LOGOUT: 'Salir',
    DEFAULTUSER: 'Colaborador',
    ADMIN: 'Administrador',
    'USER-CONFIG': 'Ajustes de usuario',
    SUPPORT: 'Soporte',
    CONFIG: 'Configuración',
    GDSCORE: 'GD Score',
    PRIVACY: 'Privacidad',
    SEARCH: 'Búsqueda',
    CLEAR: 'Borrar',
    PROFILECOVER: 'Perfil y portada',
  },
};

export { MenuProfilePT, MenuProfileEN, MenuProfileES };
