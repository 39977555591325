const HelpMenuPT = {
  HelpMenu: {
    HELP_CENTER: 'Central de Ajuda',
    FAQ: 'Perguntas Frequentes',
    TALK_TO_US: 'Fale com a gente',
    RELEASE_NOTES: 'Novidades no produto',
  },
};

const HelpMenuEN = {
  HelpMenu: {
    HELP_CENTER: 'Help Center',
    FAQ: 'FAQ',
    TALK_TO_US: 'Talk to Us',
    RELEASE_NOTES: 'Release Notes',
  },
};

const HelpMenuES = {
  HelpMenu: {
    HELP_CENTER: 'Centro de Ayuda',
    FAQ: 'Preguntas frecuentes',
    TALK_TO_US: 'Habla con nosotros',
    RELEASE_NOTES: 'Noticias de producto',
  },
};

export { HelpMenuEN, HelpMenuPT, HelpMenuES };
