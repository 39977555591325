import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const TabsWrapper = styled('div')`
  ${({ theme: { spacing, colors } }) => css`
    position: relative;
    width: ${spacing.full};
    background: ${colors.white};
    border-top: 1px solid ${colors.grey2};
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
  `}
`;

export const TabsScope = styled.div`
  ${({ theme: { breakpoint } }) => css`
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    @media screen and (max-width: ${breakpoint.md}) {
      flex-wrap: wrap;
    }
  `}
`;

export const TabsStyled = styled.div`
  ${({ theme: { breakpoint, spacing } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(${spacing.full} - 200px);
    @media screen and (max-width: ${breakpoint.md}) {
      display: block;
      overflow: hidden;
      width: ${spacing.full};
    }
  `}
`;

export const TabsStyledUserTable = styled.div`
  ${({ theme: { breakpoint, spacing } }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: ${spacing.full};
    @media screen and (max-width: ${breakpoint.md}) {
      display: block;
      overflow: hidden;
      width: ${spacing.full};
    }
  `}
`;

export const TabsContent = styled.div`
  ${({ theme: { breakpoint, spacing } }) => css`
    display: flex;
    align-items: center;
    overflow: hidden;
    flex-shrink: 0;
    @media screen and (max-width: ${breakpoint.md}) {
      border: none;
      border-radius: 0;
      width: ${spacing.full};
    }

    @media screen and (max-width: 450px) {
      flex-direction: column;
      a {
        width: 40%;
      }
    }
  `}
`;

export const TabItem = styled(Link)`
  ${({ theme: { fontSize, colors, spacing, breakpoint } }) => css`
    font-size: ${fontSize.px12};
    color: ${colors.blueGrey2};
    padding: 16px ${spacing.px15};
    text-decoration: none;
    white-space: nowrap;
    border-bottom: 2px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    &::first-letter {
      text-transform: uppercase;
    }
    @media screen and (max-width: ${breakpoint.md}) {
      width: ${spacing.full};
      box-sizing: border-box;
      text-align: center;
      padding: ${spacing.px15} ${spacing.px15};
      &:last-child {
        border-bottom: none;
      }
    }
    p {
      margin: 0 3px 0 0;
      font-size: ${fontSize.px12};
    }
    :focus {
      color: #2328eb;
    }
    :hover {
      color: #2328eb;
    }
    ${({ actived, theme }) =>
      actived === 1 &&
      css`
        background: ${colors.white};
        color: ${theme.colors.blueSecurity};
        border-bottom: 2px solid ${theme.colors.blueSecurity};
      `}

    ${({ disabled }) =>
      disabled === true &&
      css`
        pointer-events: none;
        cursor: not-allowed;
      `}
  `}
`;

export const TabItemDisabled = styled.span`
  ${({ theme: { fontSize, colors, spacing, breakpoint } }) => css`
    font-size: ${fontSize.px12};
    color: ${colors.blueGrey3};
    padding: 16px ${spacing.px15};
    text-decoration: none;
    white-space: nowrap;
    cursor: not-allowed;
    color: ${colors.grey2};
    &::first-letter {
      text-transform: uppercase;
    }
    @media screen and (max-width: ${breakpoint.md}) {
      border-left: none;
      width: ${spacing.full};
      box-sizing: border-box;
      text-align: center;
      padding: ${spacing.px15} ${spacing.px15};
    }
  `}
`;

export const LeftContent = styled.div`
  ${({ theme: { spacing, breakpoint } }) => css`
    flex-basis: 250px;
    display: flex;
    align-items: stretch;
    position: relative;
    @media screen and (max-width: ${breakpoint.md}) {
      order: 1;
      flex-basis: ${spacing.full};
    }
  `}
`;

export const RightContent = styled.div`
  ${({ theme: { spacing, breakpoint } }) => css`
    flex-basis: 250px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media screen and (max-width: ${breakpoint.md}) {
      order: 2;
      flex-basis: ${spacing.full};
      justify-content: center;
    }
    @media screen and (max-width: 650px) {
      flex-direction: column;
      & > div,
      & > button {
        margin: ${spacing.none} ${spacing.none} ${spacing.px15};
      }
    }
  `}
`;

export const BackTrade = styled.div`
  color: #2328eb;
  line-height: 40px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
`;
