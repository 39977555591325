import React, { useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as S from './styles';
import { chartDetails } from './chartDetails';

const ProgressChart = (props) => {
  const { graphParameters } = props;

  const getGraphConfigs = useMemo(() => {
    const {
      value,
      size,
      fontSize,
      color,
      backgroundLineColor,
      backgroundColor,
      textMarginTop,
      outerLine,
      innerLine,
    } = graphParameters;

    if (!value) return {};

    const parameters = {
      size: size || 200,
      value: value || 0,
      fontSize: fontSize || '30px',
      color: color,
      backgroundLineColor: backgroundLineColor || '#E6E6E6',
      backgroundColor: backgroundColor || 'rgba(0,0,0,0)',
      textMarginTop: textMarginTop || 32,
      outerLine: outerLine || '115%',
      innerLine: innerLine || '100%',
    };

    const formatConfigs = chartDetails(parameters);

    return formatConfigs;
  }, [graphParameters]);

  return (
    <S.ChartContainer>
      {getGraphConfigs?.series && (
        <HighchartsReact highcharts={Highcharts} options={getGraphConfigs} />
      )}
    </S.ChartContainer>
  );
};

export default ProgressChart;
