// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import * as S from './styles';

// Components
import ReactModal from 'react-modal';
import Title from '@components/Title';
import Button from '@components/Button';
import ButtonClose from '@components/ButtonClose';

const ModalDefault = ({
  title,
  visible,
  iconClose,
  mobileFullScreen,
  closeButtonStyled,
  width,
  buttonConfirmDisabled,
  buttonConfirmColor,
  buttonCancelDisabled,
  onClickAway,
  cancelButton,
  confirmButton,
  cancelButtonText,
  confirmButtonText,
  noHeader,
  noFooter,
  children,
  buttonConfirmColorHoverColor,
}) => (
  <ReactModal
    ariaHideApp={false}
    isOpen={visible}
    onRequestClose={onClickAway}
    style={{
      content: {
        width: width,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '10px',
      },
    }}
  >
    <S.ModalWrapper>
      <S.ButtonClosedStyled onClick={onClickAway}>
        {closeButtonStyled || <ButtonClose iconClose={iconClose} />}
      </S.ButtonClosedStyled>
      {!noHeader ? (
        <S.ModalHeader>
          <Title tag="h4" fontWeight="bold" color="grey1" marginBottom="none">
            {title}
          </Title>
        </S.ModalHeader>
      ) : null}
      <S.ModalBody mobileFullScreen={mobileFullScreen}>{children}</S.ModalBody>
      {!noFooter ? (
        <S.ModalFooter>
          {!!cancelButton && (
            <Button
              onClick={cancelButton}
              underlineOnHover
              fontColor="blueGrey3"
              disabled={buttonCancelDisabled}
              backgroundColor="white"
            >
              {cancelButtonText}
            </Button>
          )}
          {!!confirmButton && (
            <Button
              onClick={confirmButton}
              disabled={buttonConfirmDisabled}
              backgroundColor={buttonConfirmColor || 'blue2'}
              backgroundHoverColor={buttonConfirmColorHoverColor || 'blue3'}
            >
              {confirmButtonText}
            </Button>
          )}
        </S.ModalFooter>
      ) : null}
      <S.ModalStyles noHeader={noHeader} noFooter={noFooter} mobileFullScreen={mobileFullScreen} />
    </S.ModalWrapper>
  </ReactModal>
);

ModalDefault.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClickAway: PropTypes.func,
  title: PropTypes.string,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  confirmButton: PropTypes.func,
  cancelButton: PropTypes.func,
  buttonConfirmColorHoverColor: PropTypes.string,
  noHeader: PropTypes.bool,
  noFooter: PropTypes.bool,
  mobileFullScreen: PropTypes.bool,
  buttonConfirmDisabled: PropTypes.bool,
  buttonConfirmColor: PropTypes.string,
  buttonCancelDisabled: PropTypes.bool,
  iconClose: PropTypes.array,
  closeButtonStyled: PropTypes.any,
};

ModalDefault.defaultProps = {
  visible: false,
  onClickAway: () => {},
  title: 'Modal Title',
  cancelButtonText: 'Cancel',
  confirmButtonText: 'Confirm',
  noHeader: false,
  noFooter: false,
};

export default ModalDefault;
