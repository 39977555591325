const ListAllEN = {
  ListAll: {
    TITLE: 'Results for the selected period',
    GROUPS: 'Groups',
    Groups: 'Groups',
    Accounts: 'Accounts',
    Pages: 'Pages',
    Strategies: 'Strategies',
    DOWNLOAD: 'Download',
    SUMMARY: 'Total',
    IMPRESSIONS: 'Impressions',
    IMPRESSIONS_PAGE: 'Organic Impressions',
    REACHES: 'Reach',
    VIDEO_VIEWS: 'Video Views',
    VIDEO_VIEWS_PAGE: 'Organic Views',
    ENGAGEMENT: 'Engagement',
    ENGAGEMENT_PAGE: 'Reactions',
    RATE_ENGAGEMENT: 'Engagement Rate',
    REVENUE: 'Revenue',
    SPEND: 'Spend',
    OPEN: 'Open',
    STRATEGY_CAMPAIGN_OBJECTIVE_APP_INSTALLS: 'App installs',
    STRATEGY_CAMPAIGN_OBJECTIVE_BRAND_AWARENESS: 'Brand awareness',
    STRATEGY_CAMPAIGN_OBJECTIVE_CONVERSIONS: 'Conversions',
    STRATEGY_CAMPAIGN_OBJECTIVE_EVENT_RESPONSES: 'Event responses',
    STRATEGY_CAMPAIGN_OBJECTIVE_LEAD_GENERATION: 'Lead generation',
    STRATEGY_CAMPAIGN_OBJECTIVE_LINK_CLICKS: 'Link clicks',
    STRATEGY_CAMPAIGN_OBJECTIVE_VIDEO_VIEWS: 'Video view',
    STRATEGY_CAMPAIGN_OBJECTIVE_REACH: 'Reach',
    STRATEGY_CAMPAIGN_OBJECTIVE_LOCAL_AWARENESS: 'Local awareness',
    STRATEGY_CAMPAIGN_OBJECTIVE_POST_ENGAGEMENT: 'Post engagement',
    STRATEGY_CAMPAIGN_OBJECTIVE_MESSAGES: 'Messages',
    STRATEGY_CAMPAIGN_OBJECTIVE_OFFER_CLAIMS: 'Offer claims',
    STRATEGY_CAMPAIGN_OBJECTIVE_PAGE_LIKES: 'Page likes',
    STRATEGY_CAMPAIGN_OBJECTIVE_PRODUCT_CATALOG_SALES: 'Product catalog sales',
    INVOLVEMENT: 'Reactions',
    RATE_INVOLVEMENT: 'Involvement Rate',
    PURCHASE: 'Online Purchase',
    LINK_CLICKS: 'Link Clicks',
    LINK_CLICKS_PAGE: 'Organic Link Clicks',
    CONVERSATION_STARTED: 'Conversations started',
    CONVERSATION_NEW: 'New conversations',
    OFFLINE_PURCHASE: 'Offline Purchase',
    OFFLINE_REVENUE: 'Offline Revenue',
    ROAS_GENERAL: 'general ROAS',
  },
};

const ListAllPT = {
  ListAll: {
    TITLE: 'Resultados obtidos no período selecionado',
    GROUPS: 'Grupos',
    Groups: 'Grupos',
    Accounts: 'Contas',
    Pages: 'Páginas',
    Strategies: 'Estratégias',
    DOWNLOAD: 'Download',
    SUMMARY: 'Total',
    IMPRESSIONS: 'Impressões',
    IMPRESSIONS_PAGE: 'Impressões Orgânicas',
    REACHES: 'Alcance',
    VIDEO_VIEWS: 'Visualizações',
    VIDEO_VIEWS_PAGE: 'Visualizações Orgânicas',
    ENGAGEMENT: 'Engajamentos',
    ENGAGEMENT_PAGE: 'Reações',
    RATE_ENGAGEMENT: 'Taxa de engajamento',
    REVENUE: 'Receita Online',
    SPEND: 'Investido',
    OPEN: 'Abrir',
    STRATEGY_CAMPAIGN_OBJECTIVE_APP_INSTALLS: 'Instalações de aplicativo',
    STRATEGY_CAMPAIGN_OBJECTIVE_BRAND_AWARENESS: 'Reconhecimento de marca',
    STRATEGY_CAMPAIGN_OBJECTIVE_CONVERSIONS: 'Conversões',
    STRATEGY_CAMPAIGN_OBJECTIVE_EVENT_RESPONSES: 'Participações no evento',
    STRATEGY_CAMPAIGN_OBJECTIVE_LEAD_GENERATION: 'Geração de cadastros',
    STRATEGY_CAMPAIGN_OBJECTIVE_LINK_CLICKS: 'Cliques para o site',
    STRATEGY_CAMPAIGN_OBJECTIVE_VIDEO_VIEWS: 'Visualização de vídeos',
    STRATEGY_CAMPAIGN_OBJECTIVE_REACH: 'Alcance',
    STRATEGY_CAMPAIGN_OBJECTIVE_LOCAL_AWARENESS: 'Reconhecimento de loja',
    STRATEGY_CAMPAIGN_OBJECTIVE_POST_ENGAGEMENT: 'Envolvimento com publicação',
    STRATEGY_CAMPAIGN_OBJECTIVE_MESSAGES: 'Mensagens',
    STRATEGY_CAMPAIGN_OBJECTIVE_OFFER_CLAIMS: 'Obtenções da oferta',
    STRATEGY_CAMPAIGN_OBJECTIVE_PAGE_LIKES: 'Curtidas na página',
    STRATEGY_CAMPAIGN_OBJECTIVE_PRODUCT_CATALOG_SALES: 'Vendas do catálogo',
    INVOLVEMENT: 'Envolvimento',
    RATE_INVOLVEMENT: 'Taxa de envolvimento',
    PURCHASE: 'Compras Online',
    LINK_CLICKS: 'Cliques no Link',
    LINK_CLICKS_PAGE: 'Cliques Orgânicos no Link',
    CONVERSATION_STARTED: 'Conversas Iniciadas',
    CONVERSATION_NEW: 'Novas Conversas',
    OFFLINE_PURCHASE: 'Compras Offline',
    OFFLINE_REVENUE: 'Receita Offline',
    ROAS_GENERAL: 'ROAS geral',
  },
};

const ListAllES = {
  ListAll: {
    TITLE: 'Resultados obtenidos en el período seleccionado',
    GROUPS: 'Grupos',
    Groups: 'Grupos',
    Accounts: 'Cuentas',
    Pages: 'Paginas',
    Strategies: 'Estrategias',
    DOWNLOAD: 'Download',
    SUMMARY: 'Total',
    IMPRESSIONS: 'Impresiones',
    IMPRESSIONS_PAGE: 'Impresiones orgánicas',
    REACHES: 'Alcance',
    VIDEO_VIEWS: 'Visualizaciones',
    VIDEO_VIEWS_PAGE: 'Visualizaciones orgánicas',
    ENGAGEMENT: 'Involucramientos',
    ENGAGEMENT_PAGE: 'Reacciones',
    RATE_ENGAGEMENT: 'Tasa de participación',
    REVENUE: 'Ingresos Online',
    SPEND: 'Invertido',
    OPEN: 'Abrir',
    STRATEGY_CAMPAIGN_OBJECTIVE_APP_INSTALLS: 'Instalación de la aplicación',
    STRATEGY_CAMPAIGN_OBJECTIVE_BRAND_AWARENESS: 'Reconocimiento de marca',
    STRATEGY_CAMPAIGN_OBJECTIVE_CONVERSIONS: 'Conversiones',
    STRATEGY_CAMPAIGN_OBJECTIVE_EVENT_RESPONSES: 'Participación en el evento',
    STRATEGY_CAMPAIGN_OBJECTIVE_LEAD_GENERATION: 'Generación de registros',
    STRATEGY_CAMPAIGN_OBJECTIVE_LINK_CLICKS: 'Clics al sitio web',
    STRATEGY_CAMPAIGN_OBJECTIVE_VIDEO_VIEWS: 'Visualización de vídeos',
    STRATEGY_CAMPAIGN_OBJECTIVE_REACH: 'Alcance',
    STRATEGY_CAMPAIGN_OBJECTIVE_LOCAL_AWARENESS: 'Reconocimiento de tienda',
    STRATEGY_CAMPAIGN_OBJECTIVE_POST_ENGAGEMENT: 'Participación con la publicación',
    STRATEGY_CAMPAIGN_OBJECTIVE_MESSAGES: 'Mensajes',
    STRATEGY_CAMPAIGN_OBJECTIVE_OFFER_CLAIMS: 'Adquisiciones de oferta',
    STRATEGY_CAMPAIGN_OBJECTIVE_PAGE_LIKES: 'Me gusta en la página',
    STRATEGY_CAMPAIGN_OBJECTIVE_PRODUCT_CATALOG_SALES: 'Ventas por catalogo',
    INVOLVEMENT: 'Envolvimiento',
    RATE_INVOLVEMENT: 'Tasa de envolvimiento',
    PURCHASE: 'Compras Online',
    LINK_CLICKS: 'Clics en enlaces',
    LINK_CLICKS_PAGE: 'Clics orgánicos en enlaces',
    CONVERSATION_STARTED: 'Conversaciones iniciadas',
    CONVERSATION_NEW: 'Nuevas conversaciones',
    OFFLINE_PURCHASE: 'Compras Offline',
    OFFLINE_REVENUE: 'Ingresos Offline',
    ROAS_GENERAL: 'general ROAS',
  },
};

export { ListAllPT, ListAllEN, ListAllES };
