// Api
import { ApiPublic, ApiUsers, ApiTenant } from '@utils/api';

class AuthenticationService {
  static checkSubDomain(version, subDomain) {
    return ApiPublic.get(`/business/${version}/settings/public?business_sub_domain=${subDomain}`);
  }

  static emailIsValid(version, email) {
    return ApiPublic.get(
      `/user/${version}/public?email=${email}&fields=avatar_url,user_name,user_last_name`
    );
  }

  static checkEmail(version, email) {
    return ApiUsers.get(`/user/${version}?email=${email}`);
  }

  static authenticate(version, data) {
    return ApiPublic.post(`/user/${version}/authenticate`, data);
  }

  static userMe(version) {
    return ApiUsers.get(`/user/${version}/me`);
  }

  static resetPassword(version, data) {
    return ApiUsers.post(`/user/${version}/password/reset`, data);
  }

  static fetchListCompanies(version) {
    return ApiUsers.get(`/business/${version}`);
  }

  static authenticateBusiness(version) {
    return ApiUsers.post(`/business/${version}/authenticate`);
  }

  static forgotPassword(version, data) {
    return ApiPublic.post(`/user/${version}/password/recover`, data);
  }

  static getUsers(version, id) {
    return ApiUsers.get(`/user/${version}/${id}`);
  }

  static getSessions(version) {
    return ApiUsers.get(`/user/${version}/sessions`);
  }

  static invalidateSession(version, data) {
    return ApiUsers.post(`/user/${version}/sessions/invalidate`, data);
  }

  static logout(version) {
    return ApiUsers.post(`/user/${version}/sessions/invalidate/current`);
  }

  static getCapabilityCategory(version) {
    return ApiTenant.get(`/settings/${version}/CapabilityCategory`);
  }
}

export default AuthenticationService;
