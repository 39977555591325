// Api
import { getTimezone } from '@utils/helpers';
import { ApiTenant } from '../utils/api';
import BFFApi from '../utils/bffApi';

// Timezone

class ReportService {
  static getCommentsSummary(data) {
    return ApiTenant.get(
      `/messages/1.0/comments/report/${data.group_id}/summary?since=${data.from}&until=${data.to}`
    );
  }

  static getCommentsGraph(data) {
    return ApiTenant.get(`/messages/1.0/comments/report/${data.group_id}/evolution?since=${data.from}&until=${data.to}&grouped=${data.type}
    `);
  }

  static getCommentsRank(data) {
    return ApiTenant.get(
      `/messages/1.0/comments/report/${data.group_id}/ranking?since=${data.from}&until=${data.to}&limit=50`
    );
  }

  static getCommentsRankEvolution(data) {
    return ApiTenant.get(
      `/messages/1.0/comments/report/${data.page_id}/ranking/evolution?since=${data.from}&until=${data.to}&page_id=${data.group_id}`
    );
  }

  static reportAd(data) {
    return ApiTenant.post('/engagement/report/groups/ad', data);
  }

  static reportPageFacebook(data) {
    return ApiTenant.post('/engagement/report/groups/page/facebook', data);
  }

  // static reportPageInstagram(data) {
  //     return ApiTenant.post(`/engagement/report/groups/page/instagram`, data);
  // }

  static reportAdEvolution(data) {
    return ApiTenant.post('/engagement/report/groups/ad/evolution', data);
  }

  static reportPageFacebookEvolution(data) {
    return ApiTenant.post('/engagement/report/groups/page/facebook/evolution', data);
  }

  // static reportPageInstagramEvolution(data) {
  //     return ApiTenant.post(`/engagement/report/groups/page/instagram/evolution`, data);
  // }

  static postBestPerforming(data) {
    return ApiTenant.post('/engagement/report/groups/ad/best-performing', data);
  }

  static postPurchasePerforming(data) {
    return ApiTenant.get(
      `/insights/1.1/ad?since=${data.from}&until=${
        data.to
      }&timezone_offset=${getTimezone()}&fields=${data.metric}&ad_ids=[${data.ids}]`
    );
  }

  static getInfoGroup(code, arrayIds, feedType = 'ads') {
    return ApiTenant.get(
      `/schema/1.1/${code}/facebook/${feedType}?fields=id,name,creative_type,image_urls,video_urls,link_url,created_time,fan_page,texts&asset_ids=[${arrayIds}]`
    );
  }

  static getContentImpressions(data) {
    return ApiTenant.post('/engagement/report/groups/facebook/content/impressions/median', data);
  }

  static postInsightsMetrics(action) {
    const {
      startDate,
      endDate,
      timezoneOffset,
      fields,
      periodType,
      datePreset,
      data,
      attributionWindows,
    } = action;

    const period = periodType ? `&period=${periodType}` : '';
    const datePresetParam = datePreset ? `&date_preset=${datePreset}` : '';

    return ApiTenant.post(
      `/insights/1.1?since=${startDate}&until=${endDate}&timezone_offset=${timezoneOffset}${period}${datePresetParam}&fields=${fields}${attributionWindows}`,
      data
    );
  }

  static getActivityMetrics(action) {
    const { startDate, endDate, timezoneOffset, dateInterval, datePreset, sourceId } = action;

    const period = dateInterval ? `&date_interval=${dateInterval}` : '';
    const datePresetParam = datePreset ? `&date_preset=${datePreset}` : '';

    return BFFApi.get(
      `/api/v1/activity/${sourceId}/summary?since=${startDate}&until=${endDate}&timezone_offset=${timezoneOffset}${period}${datePresetParam}`
    );
  }

  static getActivityGroupedMetrics(action, sourceId) {
    const { startDate, endDate, timezoneOffset, fields } = action;
    return BFFApi.get(
      `/api/v1/activity/${sourceId}/summary/grouped?since=${startDate}&until=${endDate}&timezone_offset=${timezoneOffset}&date_interval=month&without_date_grouping=true&fields=${fields}`
    );
  }

  static async getBudgetMetrics(action) {
    const { startDate, endDate, sourceId } = action;
    const response = await BFFApi.get(
      `/api/v1/budget/${sourceId}/summary?since=${startDate}&until=${endDate}`
    );
    if (response.data.is_success) {
      return {
        is_success: true,
        data: response.data.payload.map((item) => ({
          allocated: item.allocated || 0,
          spend: item.spend || 0,
          perSpend: item.per_spend || 0,
          fundingId: item.funding_id || 0,
          start: item.since || '',
          end: item.until || '',
        })),
      };
    }
    return response;
  }

  static getActivityGroupedMetricsByDay(action) {
    const { sinceCurrent, untilCurrent, sinceCompare, untilCompare, sourceId } = action;
    return BFFApi.get(
      `/api/v1/activity/${sourceId}/content-frequency?since_current=${sinceCurrent}&until_current=${untilCurrent}&since_compare=${sinceCompare}&until_compare=${untilCompare}`
    );
  }
  static getInsightsSpend(action) {
    const { since, until, sourceId } = action;
    return BFFApi.get(`/api/v1/insights/spend?source_id=${sourceId}&since=${since}&until=${until}`);
  }
}

export default ReportService;
