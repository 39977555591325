import styled, { css } from 'styled-components';

const getStylingProps = (styles) => css`
  ${styles.direction ? `flex-direction: ${styles.direction};` : ''};  

  ${styles.flex ? `flex: ${styles.flex};` : ''};  

  ${styles.gap ? `gap: ${styles.gap};` : ''};
  ${styles.wrap ? `flex-wrap: ${styles.wrap};` : ''};

  ${styles.bgColor ? `background-color: ${styles.bgColor};` : ''};

  ${styles.alignItems ? `align-items: ${styles.alignItems};` : ''};
  ${styles.alignContent ? `align-content: ${styles.alignContent};` : ''};

  ${styles.justifyContent ? `justify-content: ${styles.justifyContent};` : ''};
  ${styles.justifyItems ? `justify-items: ${styles.justifyItems};` : ''};

  ${styles.width ? `width: ${styles.width};` : ''};
  ${styles.height ? `height: ${styles.height};` : ''};

  ${styles.minWidth ? `min-width: ${styles.minWidth};` : ''};
  ${styles.maxWidth ? `max-width: ${styles.maxWidth};` : ''};

  ${styles.minHeight ? `min-height: ${styles.minHeight};` : ''};
  ${styles.maxHeight ? `max-height: ${styles.maxHeight};` : ''};

  ${styles.m ? `margin: ${styles.m};` : ''};

  ${styles.my ? `margin: ${styles.my} 0;` : ''};
  ${styles.mx ? `margin: 0 ${styles.mx};` : ''};

  ${styles.mt ? `margin-top: ${styles.mt};` : ''};
  ${styles.mr ? `margin-right: ${styles.mr};` : ''};
  ${styles.ml ? `margin-left: ${styles.ml};` : ''};
  ${styles.mb ? `margin-bottom: ${styles.mb};` : ''};

  ${styles.p ? `padding: ${styles.p};` : ''};

  ${styles.py ? `padding: ${styles.py} 0;` : ''};
  ${styles.px ? `padding: 0 ${styles.px};` : ''};

  ${styles.pt ? `padding-top: ${styles.pt};` : ''};
  ${styles.pr ? `padding-right: ${styles.pr};` : ''};
  ${styles.pl ? `padding-left: ${styles.pl};` : ''};
  ${styles.pb ? `padding-bottom: ${styles.pb};` : ''};

  ${styles.border ? `border: ${styles.border};` : ''};

  ${styles.overflowY ? `overflow-y: ${styles.overflowY};` : ''};
  ${styles.overflowX ? `overflow-x: ${styles.overflowX};` : ''};

  ${styles.fontSize ? `font-size: ${styles.fontSize};` : ''};
`;

const Box = styled.div`
  display: flex;
  box-sizing: border-box;

  ${(props) => getStylingProps(props)};

  ${(props) => (props.sm ? `
    @media (min-width: 640px) {
      ${getStylingProps(props.sm)};      
    }
  ` : ''
  )};

  ${(props) => (props.md ? `
    @media (min-width: 768px) {
      ${getStylingProps(props.md)}; 
    }
  ` : ''
  )};

  ${(props) => (props.lg ? `
    @media (min-width: 1024px) {
      ${getStylingProps(props.lg)};
    }
  ` : ''
  )};

  ${(props) => (props.xl ? `
    @media (min-width: 1280px) {
      ${getStylingProps(props.xl)};
    }
  ` : ''
  )};
`;

export default Box;
