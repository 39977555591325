import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { ReportMetricsService } from '@services';
import { useGetMetrics } from '@utils/hooks/ReportMetrics';

export const ReportMetricsContext = createContext();

export const ReportMetricsProvider = ({ children }) => {
  const reportResponse = useGetMetrics('tenant_custom_metrics');
  const [metrics, setMetrics] = useState(null);
  const prevResponse = useRef([]);

  useEffect(() => {
    if (reportResponse.getMetrics && !metrics) {
      setMetrics(JSON.parse(reportResponse.getMetrics.value));
      prevResponse.current = JSON.parse(reportResponse.getMetrics.value);
    }
    if (
      reportResponse.getMetrics &&
      prevResponse.current.length !== JSON.parse(reportResponse.getMetrics.value).length
    ) {
      setMetrics(JSON.parse(reportResponse.getMetrics.value));
      prevResponse.current = JSON.parse(reportResponse.getMetrics.value);
    }
    if (reportResponse.getMetrics === null && !reportResponse.isLoading && !metrics) {
      setMetrics([]);
      prevResponse.current = [];
    }
  }, [reportResponse]);

  const createMetrics = async (data) => {
    const body = { key: data.key, value: JSON.stringify(data.value) };
    try {
      const response = await ReportMetricsService.postReportMetric(body);
      if (response.status === 200) {
        setMetrics(data.value);
      }
      return response;
    } catch (error) {
      return error;
    }
  };

  return (
    <ReportMetricsContext.Provider value={{ createMetrics, metrics, setMetrics }}>
      {children}
    </ReportMetricsContext.Provider>
  );
};

export const useMetrics = () => {
  const context = useContext(ReportMetricsContext);

  if (!context) throw new Error('Expected to be wrapped in a Metrics');

  return context;
};
