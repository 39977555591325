/* Modules */
import { call, put, takeEvery } from 'redux-saga/effects';

import { SurveyService } from '@services';

/* Types */
import { SHOW_TOAST } from '@redux/toast/types';
import { FETCH_SURVEY, SUBMIT_SURVEY } from './types';

function* getSurvey(action) {
  const response = yield call(SurveyService.getPending, action.version, action.code);
  if (response && (response.status === 200 || response.status === 204)) {
    yield put({
      type: FETCH_SURVEY.SUCCESS,
      data: response.data || [],
    });
  }
}

function* submitSurvey(action) {
  try {
    const response = yield call(SurveyService.submitSurvey, action.version, action.body);
    if (response.status === 200) {
      const error = false;

      if (!error) {
        yield put({
          type: SHOW_TOAST.SUCCESS,
          message: 'Agradecemos o seu feedback!',
          messageType: 'success',
        });
      }

      yield put({ type: SUBMIT_SURVEY.SUCCESS, data: response.data });
    } else {
      yield put({ type: SUBMIT_SURVEY.FAILURE, data: response.data });
      yield put({
        type: SHOW_TOAST.SUCCESS,
        message: response.data.error,
        messageType: 'error',
      });
    }
  } catch (e) {
    yield put({ type: SUBMIT_SURVEY.FAILURE, data: e.response });
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
  }
}

export const surveySaga = [
  takeEvery(FETCH_SURVEY.REQUEST, getSurvey),
  takeEvery(SUBMIT_SURVEY.REQUEST, submitSurvey),
];
