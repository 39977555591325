const WillHaveMoneyEN = {
  APPROVE_THIS_AD: 'Approve this ad',
  APPROVED_TEXT_INFO: 'Choose whether this content will be organic or sponsored.',
  POST_WITHOUT_MONEY: 'Post without money',
  POST_WITH_MONEY: 'Post with money',
};

const WillHaveMoneyPT = {
  APPROVE_THIS_AD: 'Aprovar este anúncio',
  APPROVED_TEXT_INFO: 'Escolha se este conteúdo será orgânico ou patrocinado.',
  POST_WITHOUT_MONEY: 'Postar sem verba',
  POST_WITH_MONEY: 'Postar com verba',
};

const WillHaveMoneyES = {
  APPROVE_THIS_AD: 'Aprobar este anuncio',
  APPROVED_TEXT_INFO: 'Elige si este contenido será orgánico o patrocinado.',
  POST_WITHOUT_MONEY: 'Publicar sin dinero',
  POST_WITH_MONEY: 'Publicar con presupuesto',
};

export { WillHaveMoneyPT, WillHaveMoneyEN, WillHaveMoneyES };
