import React, { createContext, useContext, useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import {
  mountUrlToLogin,
  mountUrlToLogout,
  getSubDomain,
  hasBusinessTokenStorage,
  setBusinessTokenStorage,
  getBusinessTokenStorage,
  setTokens,
  getCallbackParams,
  isValidState,
  getParamsForToken,
  hasTokens,
  getTokens,
  validateObject,
  logoutOldUser,
  reLoginUser,
  fallbackRelogin,
  adjustSubDomain,
} from '@utils/oAuth';

import { getTokenApi, getTenantsApi, authenticateApi } from '@utils/oAuthApi';

import { AuthenticationService } from '@services';
import { getSubDomainID } from '../../oAuthApi';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [tenant, setTenant] = useState(null);
  const [invalidTenant, setInvalidTenant] = useState(false);
  const [tenantOptions, setTenantOptions] = useState([]);
  const [businessToken, setBusinessToken] = useState(() => {
    if (hasBusinessTokenStorage()) {
      return getBusinessTokenStorage();
    }
    return null;
  });
  const [token, setToken] = useState(() => {
    if (hasTokens()) {
      return getTokens();
    }
    return null;
  });
  const [decodedBusinessToken, setDecodedBusinessToken] = useState(null);
  const login = () => mountUrlToLogin();
  const logout = () => mountUrlToLogout();
  const hasUser = () => validateObject(businessToken);
  const hasTenant = () => validateObject(tenant);

  const getToken = async (params) => {
    try {
      const { data } = await getTokenApi(params);
      if (data) {
        setTokens(data);
        setToken(data);
        setInvalidTenant(false);
      }
    } catch (error) {
      setInvalidTenant(true);
    }
  };

  const getTenants = async () => {
    try {
      const { data } = await getTenantsApi();
      // if tenant is empty, redirect to another screen, else, set tenant options
      if (data.length !== 0) {
        setTenantOptions(data);
        return;
      }
      reLoginUser();
    } catch (error) {
      reLoginUser();
      if (error.response.status === 401) {
        return;
      }
    }
  };

  const authenticate = async () => {
    try {
      const { data } = await authenticateApi(tenant.id || tenant);
      const { data: loggedTenant } = await getSubDomainID(tenant.sub_domain);
      if (data && !hasBusinessTokenStorage()) {
        setBusinessTokenStorage(data.token);
        setBusinessToken(data.token);
      }
      if (tenant.sub_domain !== getSubDomain() && loggedTenant.id !== tenant.id) {
        window.location.href = adjustSubDomain(tenant.sub_domain);
      }
    } catch (error) {
      getTenants();
      if (error.response.status === 401 && hasBusinessTokenStorage()) {
        setInvalidTenant(true);
        logout();
      }
    }
  };

  const getTenantsFromSubDomain = async () => {
    try {
      const { data } = await AuthenticationService.checkSubDomain('1.2', getSubDomain());
      if (data) {
        setTenant(data);
      }
    } catch (error) {
      if (hasTokens()) {
        getTenants();
      }
      if (error.response.status === 404) {
        window.location.href = adjustSubDomain('bornlogic');
      }
    }
  };

  useEffect(() => {
    logoutOldUser();
    if (!hasTenant()) getTenantsFromSubDomain();
  }, []);

  useEffect(() => {
    const { code, state } = getCallbackParams();
    getTenantsFromSubDomain();
    if (isValidState(state)) {
      getToken(getParamsForToken(code));
    } else {
      fallbackRelogin();
    }
  }, []);

  useEffect(() => {
    if (invalidTenant && tenant?.sub_domain) {
      window.location.href = adjustSubDomain(tenant.sub_domain);
    }
  }, [invalidTenant, tenant?.sub_domain]);

  useEffect(() => {
    if (!hasTenant() && hasTokens()) getTenants();
  }, [token]);

  useEffect(() => {
    const shouldAuthenticate = hasTenant() && hasTokens();
    if (shouldAuthenticate) {
      authenticate();
    }
  }, [tenant, token]);

  useEffect(() => {
    if (businessToken && !decodedBusinessToken) {
      const decoded = jwt_decode(businessToken);
      setDecodedBusinessToken(decoded);
    }
  }, [businessToken]);

  return (
    <UserContext.Provider
      value={{
        login,
        logout,
        subDomain: tenant,
        hasUser,
        businessToken,
        invalidTenant,
        tenantOptions,
        setTenant,
        decodedBusinessToken,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export function useUser() {
  return useContext(UserContext);
}
