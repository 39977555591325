import styled from 'styled-components';

export const FlexContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;

  .clicked {
    height: 85vh;
    justify-content: space-between;
    width: 100%;
  }

  .noScript {
    width: 65%;
  }
`;

export const OuterContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 65%;
  align-self: center;

  .logged-out {
    width: 200px;
    margin: 80px 0 64px 0;
  }

  .align-right {
    align-self: flex-start;
    background-color: green;
  }

  .copyright {
    margin-bottom: 20px;
  }
`;

export const ClckedContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .flex-start {
    align-self: flex-start;
    margin-left: 17.35vw;
  }

  @media (max-width: 1280px) {
    .flex-start {
      margin-left: 17.5vw;
    }
  }
`;

export const BackButtonContainer = styled.div`
  margin: 30px 0 41px 0;
  color: #2328eb;
`;

export const BackButton = styled.button`
  color: #2328eb;
  border: none;
  background-color: #f8f8f8;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 72px;

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  font-size: 14px;
  line-height: 30.5px;

  p {
    margin-bottom: 14px;
  }
`;

export const Title = styled.span`
  font-size: 32px;
  margin-bottom: 40px;
  font-weight: bold;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
`;

export const InnerButtonsContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const StyledButton = styled.button`
  background-color: #2328eb;
  color: white;
  width: 388px;
  height: 56px;
  border-radius: 8px;
  border: none;
  margin: 16px;

  :hover {
    cursor: pointer;
  }
`;
