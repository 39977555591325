/* Create action types */
import createActionTypes from '../createActionTypes';

export const SEND_GUEST = createActionTypes('SEND_GUEST');
export const SAVE_PROFILE = createActionTypes('SAVE_PROFILE');
export const CHANGE_SEARCH_ACTIONS = createActionTypes('CHANGE_SEARCH_ACTIONS');
export const CHANGE_SOURCES_USERS_SHARE = createActionTypes('CHANGE_SOURCES_USERS_SHARE');
export const CHANGE_CHECKBOX_PROFILE_COVER = createActionTypes('CHANGE_CHECKBOX_PROFILE_COVER');
export const CHANGE_SEARCH_PROFILE_COVER = createActionTypes('CHANGE_SEARCH_PROFILE_COVER');
export const CHANGE_SEARCH_MEDIAS = createActionTypes('CHANGE_SEARCH_MEDIAS');
export const CHANGE_SOURCES_MEDIAS = createActionTypes('CHANGE_SOURCES_MEDIAS');
export const CHANGE_MARKED_REPLICATE = createActionTypes('CHANGE_MARKED_REPLICATE');
