import React from 'react';
import { ModalDefault, Text, Checkbox } from '@bornlogic/gd-design-system';
import { SelectGroup } from 'containers/Settings/UserTable/SelectGroup';
import { useFormik } from 'formik';
// @ts-expect-error
import { ActionsService } from '@services';
import * as S from './style';
import { useShowToast } from 'utils/hooks/Common';

type ChangeGroupModalProps = {
  isOpen: boolean;
  onClose: () => void;
  unitsIds: string[];
  onSuccess: () => void;
};

const ChangeGroupInBatchModal = ({
  isOpen,
  onClose,
  unitsIds,
  onSuccess,
}: ChangeGroupModalProps) => {
  const showToast = useShowToast();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      newGroup: '',
      ignoreAvailableBudget: false,
    },
    onSubmit: async (values) => {
      const newGroupSourceId = values.newGroup;
      const ignoreAvailableBudget = values.ignoreAvailableBudget;

      const response = await ActionsService.changeGroupInBatch('1.1', newGroupSourceId, {
        child_source_ids: unitsIds,
        ignore_available_budget: ignoreAvailableBudget,
        keep_current_relationships: false,
      });

      if (response.status === 200) {
        showToast('Mudança de grupos feita com sucesso.', 'success');
        onClose();
        onSuccess();
        return;
      }

      showToast(
        'Ocorreu um erro ao mudar o grupo das unidades. Tente novamente mais tarde.',
        'error'
      );
    },
  });

  return (
    <ModalDefault
      isOpen={isOpen}
      onClose={onClose}
      title="Mudar grupo de unidades em massa"
      confirmBtnText="Confirmar"
      onConfirmBtnClick={formik.handleSubmit}
      confirmBtnDisabled={formik.isSubmitting}
      optionBtnText="Cancelar"
      onOptionBtnClick={onClose}
    >
      <S.Container>
        <form onSubmit={formik.handleSubmit}>
          <label>
            <Text>Selecione o novo grupo</Text>

            <SelectGroup
              placeholder="Selecione o grupo"
              id="newGroup"
              name="newGroup"
              selectValue={formik.values['newGroup']}
              onChange={formik.handleChange('newGroup')}
            />
          </label>

          <S.CheckboxWrapper>
            <Checkbox
              id="ignoreAvailableBudget"
              name="ignoreAvailableBudget"
              checked={formik.values.ignoreAvailableBudget}
              onChange={formik.handleChange}
            />

            <label htmlFor="ignoreAvailableBudget">
              <Text>Ignorar orçamento disponível no novo grupo</Text>
            </label>
          </S.CheckboxWrapper>
        </form>
      </S.Container>
    </ModalDefault>
  );
};

export default ChangeGroupInBatchModal;
