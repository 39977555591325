import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate } from 'react-translate';
import { ModalDefault, Title, InputText, Text, ColorPicker } from '@bornlogic/gd-design-system';
import GroupsAndPageUniqueList from '../../../../components/GroupsAndPageUniqueList';
import SettingsService from '../../../../services/settings';
import { SHOW_TOAST } from '@redux/toast/types';
import * as S from './styles';

const AddGroup = ({ onClose, isOpen }) => {
  const t = useTranslate('Groups');
  const dispatch = useDispatch();
  const [groupName, setGroupName] = useState('');
  const [parentID, setParentID] = useState([]);
  const [selectedColor, setSelectedColor] = useState('');

  const _onConfirmClick = async () => {
    let sourceID = 'root';

    if (parentID.length > 0) {
      sourceID = parentID[0].source_id;
    }

    const body = {
      name: groupName,
      color: selectedColor,
    };

    const response = await SettingsService.createGroup(body, sourceID);

    if (response.status === 200) {
      dispatch({
        type: SHOW_TOAST.SUCCESS,
        message: 'Grupo criado com sucesso',
        messageType: 'success',
      });
      _cleanUpAndClose();
    } else {
      dispatch({
        type: SHOW_TOAST.SUCCESS,
        message: 'Ocorreu um erro ao tentar criar o grupo.',
        messageType: 'error',
      });
      _cleanUpAndClose();
    }
  };

  const _cleanUpAndClose = () => {
    setSelectedColor('');
    setGroupName('');
    setParentID('');
    onClose();
  };

  const _handleChangeGroupName = (e) => {
    setGroupName(e);
  };

  const _handleSelectColor = (color) => {
    setSelectedColor(color);
  };

  const isBtnDisabled = () => {
    if (groupName === '') return true;
    if (selectedColor === '') return true;

    return false;
  };

  return (
    <ModalDefault
      isOpen={isOpen}
      title="Novo grupo"
      confirmBtnText={t('CONFIRM')}
      onConfirmBtnClick={_onConfirmClick}
      confirmBtnDisabled={isBtnDisabled()}
      optionBtnText={t('CANCEL')}
      onOptionBtnClick={_cleanUpAndClose}
      thirdBtnDisabled
      onClose={() => onClose()}
      size="40%"
      maxHeight="90%"
    >
      <S.Container>
        <S.InputWrapper>
          <S.LabelSpace>
            <Title as="h6">Nome</Title>
          </S.LabelSpace>
          <InputText
            onChange={_handleChangeGroupName}
            placeHolder="Digite o nome do seu grupo"
            value={groupName}
          />
        </S.InputWrapper>
        <S.InputWrapper>
          <S.LabelSpace>
            <Title as="h6">Selecione um grupo pai</Title>
          </S.LabelSpace>
          <S.LabelSpace>
            <Text textColor="#8BA4B1">(não selecione para criar no grupo principal)</Text>
          </S.LabelSpace>
          <GroupsAndPageUniqueList onSelectLeafs={(data) => setParentID(data)} />
        </S.InputWrapper>
        <S.InputWrapper>
          <S.LabelSpace>
            <Title as="h6">Selecione uma cor para o grupo</Title>
          </S.LabelSpace>
          <ColorPicker
            colors={[
              '#765A50',
              '#FFFFFF',
              '#B51D42',
              '#B926BF',
              '#561EA5',
              '#2DBFF3',
              '#00967D',
              '#F1A539',
              '#F36A2D',
              '#577584',
              '#000000',
            ]}
            onColorSelect={_handleSelectColor}
          />
        </S.InputWrapper>
      </S.Container>
    </ModalDefault>
  );
};

export default AddGroup;
