// Budget Reducer
// Here must have all data referent to budget page

// Types
import {
  GET_BUDGET_GROUP,
  CHANGE_BUDGET,
  CHANGE_BUDGET_AVAILABLE,
  GET_BUDGET_ACCOUNT,
  POST_WITHDRAW,
  POST_DEPOSIT,
  SET_BUDGET_HISTORY,
  SET_BUDGET_ORDER, POST_RESET,
} from './types';

const INITIAL_STATE = {
  money: {
    data: {
      cards: {},
      list: [],
    },
    isLoading: false,
    status: null,
    message: null,
  },
  deposit: {
    data: '',
    isLoading: false,
    error: null,
  },
  withdraw: {
    data: '',
    isLoading: false,
    error: null,
  },
  budgetHistory: null,
  budgetOrder: null,
};

const budgetReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_BUDGET_HISTORY.SUCCESS:
      return {
        ...state,
        budgetHistory: action.budgetHistory,
      };
    case SET_BUDGET_ORDER.SUCCESS:
      return {
        ...state,
        budgetOrder: action.budgetOrder,
      };
    case GET_BUDGET_GROUP.REQUEST:
      return {
        ...state,
        money: {
          data: {
            cards: {},
            list: [],
          },
          isLoading: true,
          status: null,
          message: null,
        },
      };

    case GET_BUDGET_GROUP.SUCCESS:
      return {
        ...state,
        money: {
          data: {
            cards: action.cards,
            list: action.list,
          },
          isLoading: false,
          status: action.status,
          message: null,
        },
      };

    case GET_BUDGET_GROUP.FAILURE:
      return {
        ...state,
        money: {
          data: INITIAL_STATE.money.data,
          isLoading: false,
          status: action.status,
          message: action.message,
        },
      };

    case GET_BUDGET_GROUP.RESET:
      return {
        ...state,
        money: INITIAL_STATE.money,
      };

    case CHANGE_BUDGET_AVAILABLE.REQUEST:
      return {
        ...state,
        money: {
          ...state.money,
          isLoading: true,
        },
      };

    case CHANGE_BUDGET_AVAILABLE.SUCCESS:
      return {
        ...state,
        money: {
          ...state.money,
          data: state.money.data,
          isLoading: false,
        },
      };

    case CHANGE_BUDGET_AVAILABLE.FAILURE:
      return {
        ...state,
        money: {
          ...state.money,
          isLoading: false,
        },
      };

    case CHANGE_BUDGET.REQUEST:
      return {
        ...state,
        money: {
          ...state.money,
          isLoading: true,
        },
      };

    case CHANGE_BUDGET.SUCCESS:
      return {
        ...state,
        money: {
          ...state.money,
          data: {
            ...state.money.data,
            cards: {
              ...state.money.data.cards,
              total: action.newValue,
            },
          },
          isLoading: false,
        },
      };

    case CHANGE_BUDGET.FAILURE:
      return {
        ...state,
        money: {
          ...state.money,
          isLoading: false,
        },
      };

    case GET_BUDGET_ACCOUNT.REQUEST:
      return {
        ...state,
        money: {
          data: {
            cards: {},
            list: [],
          },
          isLoading: true,
          status: null,
          message: null,
        },
      };

    case GET_BUDGET_ACCOUNT.SUCCESS:
      return {
        ...state,
        money: {
          data: {
            cards: {},
            list: action.list,
          },
          isLoading: false,
          status: action.status,
          message: null,
        },
      };

    case GET_BUDGET_ACCOUNT.FAILURE:
      return {
        ...state,
        money: INITIAL_STATE.money,
      };

    case GET_BUDGET_ACCOUNT.RESET:
      return {
        ...state,
        money: INITIAL_STATE.money,
      };

    case POST_DEPOSIT.REQUEST:
      return {
        ...state,
        deposit: {
          data: [],
          isLoading: true,
          error: null,
        },
      };
    case POST_DEPOSIT.SUCCESS: {
      const newArrayDeposit = [];

      state.money.data.list.forEach((val) => {
        if (val.source_id !== action.data.source_id) {
          newArrayDeposit.push(val);
        } else {
          newArrayDeposit.push({
            ...val,
            budget: val.budget + Number(action.data.value),
            remaining: val.remaining + Number(action.data.value),
          });
        }
      });

      return {
        ...state,
        deposit: {
          data: action.deposit,
          isLoading: false,
          error: null,
        },
        money: {
          ...state.money,
          data: {
            ...state.money.data,
            list: newArrayDeposit,
            cards: {
              ...state.money.data.cards,
              allocated: state.money.data.cards.allocated + Number(action.data.value),
              remaining: state.money.data.cards.remaining - Number(action.data.value),
            },
          },
        },
      };
    }
    case POST_DEPOSIT.FAILURE:
      return {
        ...state,
        deposit: {
          data: [],
          isLoading: false,
          error: action.depositError,
        },
      };
    case POST_WITHDRAW.REQUEST:
      return {
        ...state,
        withdraw: {
          data: [],
          isLoading: true,
          error: null,
        },
      };
    case POST_WITHDRAW.SUCCESS: {
      const newArrayWithdraw = [];

      state.money.data.list.forEach((val) => {
        if (val.source_id !== action.data.source_id) {
          newArrayWithdraw.push(val);
        } else {
          newArrayWithdraw.push({
            ...val,
            budget: val.budget - Number(action.data.value),
            remaining: val.remaining - action.data.value,
          });
        }
      });

      return {
        ...state,
        withdraw: {
          data: action.withdraw,
          isLoading: false,
          error: null,
        },
        money: {
          ...state.money,
          data: {
            ...state.money.data,
            list: newArrayWithdraw,
            cards: {
              ...state.money.data.cards,
              allocated: state.money.data.cards.allocated - action.data.value,
              remaining: state.money.data.cards.remaining + action.data.value,
            },
          },
        },
      };
    }
    case POST_WITHDRAW.FAILURE:
      return {
        ...state,
        withdraw: {
          data: [],
          isLoading: false,
          error: action.withdrawError,
        },
      };
    case POST_RESET.REQUEST:
      return {
        ...state,
        withdraw: {
          data: [],
          isLoading: true,
          error: null,
        },
      };
    case POST_RESET.SUCCESS: {
      const newArrayReset = [];

      state.money.data.list.forEach((val) => {
        if (val.source_id !== action.data.source_id) {
          newArrayReset.push(val);
        } else {
          newArrayReset.push({
            ...val,
            budget: 0.01,
            remaining: 0.01,
          });
        }
      });

      return {
        ...state,
        withdraw: {
          data: action.withdraw,
          isLoading: false,
          error: null,
        },
        money: {
          ...state.money,
          data: {
            ...state.money.data,
            list: newArrayReset,
            cards: {
              ...state.money.data.cards,
              allocated: 0.01,
              remaining: 0.01,
            },
          },
        },
      };
    }


    default:
      return state;

  }

};

export default budgetReducer;
