const CheckCapabilitiesEN = {
  CheckCapabilities: {
    MANAGEMENT: 'Management',
    CREATION: 'Creation',
    APP: 'App GD',

    SELECT_ALL_CATEGORY: 'Select all capabilities from category "{{category}}"',

    REPORT: 'Reports',
    CONVERSION_FLOW: 'Conversion Flow',
    APPROVAL: 'Automatic approval',
    ENGAGEMENT: 'Engagement',
    VIDEO_VIEW: 'Video View',
    ACTIVITY: 'Activity',
    REPORT_RANKING: 'Ranking',
    REPORT_AUDIENCE: 'Audience',
    REPORT_COMMENTS: 'Comments',
    LEAD_ADS: 'Lead Ads',
    GENERAL_LISTING: 'General Listing',
    MANAGE_GROUPS: 'Manage Grupos',
    USER_MANAGE: 'Manage Users',
    USER_INVITE: 'User Invite',
    ADACCOUNT_BUDGET: 'Budget',
    BUDGET_SPEND_CAP: 'Manage group budget',
    BATCH_BUDGET_SPEND_CAP: 'Mass budget distribution',
    USER_INFO: 'Edit User Profile',
    TRENDS: 'Trends',
    MEDIA_SETS_UPLOAD: 'Upload Media Sets',
    UNIT_SCORE: 'Unit Score',
    USER_LISTING: 'User listing',
    USER_PROFILE: 'View User Profile',
    EDIT_AD: 'Ad Editing',
    FEED: 'Feed',
    CDA: 'Dynamic Creatives',
    FEED_AWARDS: 'Sort by awards in the Feed',
    MEDIA_SETS: 'Media Library',
    BATCH_PROMOTE: 'Promote',
    BATCH_REPLICATE: 'Replicate',
    BATCH_UPDATE_PROFILE_COVER: 'Change profile and cover in bulk',
    MEDIA_APPROVAL: 'Control of produced content',
    SUGGEST_APPROVAL: 'Post Type',
    SELLER_REACH: 'Reach',
    SELLER_BUDGET_SPEND_CAP: 'Budget',
    SELLER_MEDIA_SETS: 'Media Library',
    SELLER_INSTAGRAM: 'Instagram Ad',
    SELLER_TARGET_SELECTION: 'Target Selection',
    SELLER_PICTURE_CHANGE: 'Pictures Change',
    SELLER_WHATSAPP: 'Whatsapp Contact',
    SELLER_VIEW_COMMENTS: 'Comments',
    SELLER_RESPOND_TO_COMMENTS: 'Interactions',
    MEDIA_SET_MANAGE: 'Manage Media Library',
    GDTV: 'GDTV',
    GDTV_MANAGE: 'Manage GDTV',
    SELLER_CAROUSEL: 'Carousel',
    SELLER_MULTI_IMAGE: 'Multi image',
    VIEW_LEADS_REPORT: 'View leads report',
    DOWNLOAD_LEADS_LIST: 'Download leads list',
    SELLER_LEADS: 'Leads',
    SELLER_DOWNLOAD_LEADS: 'Download lead ads',
    SELLER_VIEW_LEADS_REPORT: 'View leads report',
    CREATE_INCENTIVE_CAMPAIGN_FIXED_BUDGET: 'Fixed budget incentive campaigns',
    REPORT_CUSTOM: 'Custom',
    CALENDAR: 'Calendar of Actions',
    CATEGORIZE_NEW_POST: 'Product categorization',

    CONVERSION_FLOW_DESCRIPTION: 'Report with conversion metrics',
    APPROVAL_DESCRIPTION: 'All content created via APP is automatically approved.',
    REPORT_DESCRIPTION:
      "Overall health of the unit, group or all groups, according to the user's access level, with budgets, impressions, etc.",
    ENGAGEMENT_DESCRIPTION: 'Report with engagement metrics.',
    VIDEO_VIEW_DESCRIPTION:
      'Report with performance metrics of video content production, video engagement, percentage of viewing, etc.',
    ACTIVITY_DESCRIPTION:
      'Activity of the stores in the project, such as the amount of content produced, monthly average, percentage of the format, active units and connected users.',
    REPORT_RANKING_DESCRIPTION:
      'Report to compare the performance, in different metrics, of the units.',
    REPORT_AUDIENCE_DESCRIPTION: 'Analysis of Age, Gender, Channels and Devices of the public.',
    REPORT_COMMENTS_DESCRIPTION:
      'Comments report on publications of the units., with time metrics and responses percentages.',
    LEAD_ADS_DESCRIPTION: 'Report of results of the ads with the objective of capturing Leads.',
    GENERAL_LISTING_DESCRIPTION:
      'Visualization of the groups in the form of a list, with the possibility of access to adapt the platform to the data of that group.',
    MANAGE_GROUPS_DESCRIPTION:
      'Allows you to move, delete, rename a group, manage accounts, and replace page IDs.',
    USER_MANAGE_DESCRIPTION: 'Allows you to edit user permissions.',
    USER_INVITE_DESCRIPTION: 'Gives the ability to add users and block active users.',
    ADACCOUNT_BUDGET_DESCRIPTION: 'View the budget limit of the ad accounts.',
    BUDGET_SPEND_CAP_DESCRIPTION: 'Allows you to deposit or remove funds from units.',
    BATCH_BUDGET_SPEND_CAP_DESCRIPTION:
      'Allows you to distribute the available funds to several units at once',
    USER_INFO_DESCRIPTION: 'Edit my user profile in GD.',
    TRENDS_DESCRIPTION: 'View trends',
    MEDIA_SETS_UPLOAD_DESCRIPTION: 'Upload Media Sets',
    UNIT_SCORE_DESCRIPTION: 'View unit score',
    USER_LISTING_DESCRIPTION: 'View all user accounts',
    USER_PROFILE_DESCRIPTION: 'View my user profile in GD.',
    EDIT_AD_DESCRIPTION:
      'Allows editing in: period of circulation, budget, campaing objective and pause ads.',
    FEED_DESCRIPTION: 'Access to the feed of posts and ads.',
    CDA_DESCRIPTION: 'Allows the distribution of customized media between units and groups.',
    FEED_AWARDS_DESCRIPTION: 'It makes it possible to sort the ads by award.',
    MEDIA_SETS_DESCRIPTION: 'Allows to receive media files in the library.',
    BATCH_PROMOTE_DESCRIPTION: 'Allows to mass promote posts or ads.',
    BATCH_REPLICATE_DESCRIPTION: 'Allows to mass replicate posts or ads.',
    BATCH_UPDATE_PROFILE_COVER_DESCRIPTION:
      'Allows you to change the profile photo and page cover by the platform.',
    MEDIA_APPROVAL_DESCRIPTION: 'Allows to approve or disapprove the media coming from the APP',
    AUTO_APPROVAL_DESCRIPTION: 'All content created via APP is automatically approved.',
    SUGGEST_APPROVAL_DESCRIPTION:
      'Allows the APP user to define whether the content will be paid or organic and configure it',
    SELLER_REACH_DESCRIPTION: 'Allows to visualize the Ads metrics on the feed page.',
    SELLER_BUDGET_SPEND_CAP_DESCRIPTION: 'Allows to see the budget for the ad account.',
    SELLER_MEDIA_SETS_DESCRIPTION: 'Allows to receive media files in the library.',
    SELLER_INSTAGRAM_DESCRIPTION: 'Allows to create ads on Instagram through the app.',
    SELLER_TARGET_SELECTION_DESCRIPTION:
      'Allows to select the target audience during the creation of each ad.',
    SELLER_PICTURE_CHANGE_DESCRIPTION:
      'Allows to change the facebook profile and page cover images.',
    SELLER_WHATSAPP_DESCRIPTION: 'Allows to change the Whatsapp number from a page.',
    SELLER_VIEW_COMMENTS_DESCRIPTION: 'Allows to read the comments on a Facebook page.',
    SELLER_RESPOND_TO_COMMENTS_DESCRIPTION:
      'Allows to interact with the comments on a Facebook page.',
    MEDIA_SET_MANAGE_DESCRIPTION:
      'Allows to create, delete and share medias between groups and unities.',
    GDTV_DESCRIPTION: 'Allows to access medias shared via GDTV.',
    GDTV_MANAGE_DESCRIPTION:
      'Allows to upload and delete medias shared via GDTV. All the produced contents will be accessible using the feed.',
    SELLER_CAROUSEL_DESCRIPTION:
      'Possibility of creating a single ad with up to ten images or videos, each media with its own title, description and link.',
    SELLER_MULTI_IMAGE_DESCRIPTION:
      'Allows you to make postings and announcements with more than one image or video.',
    VIEW_LEADS_REPORT_DESCRIPTION:
      'Report with results of the ads with the objective of generating leads, with metrics such as total leads and conversion leads.',
    DOWNLOAD_LEADS_LIST_DESCRIPTION:
      'Allows you to download the spreadsheet with the data of the leads from the lead campaign.',
    SELLER_LEADS_DESCRIPTION:
      'Allows you to create lead campaigns with the purpose of generating leads',
    SELLER_DOWNLOAD_LEADS_DESCRIPTION:
      'Allows you to download the spreadsheet with the data of the registered leads from the lead campaign in the app.',
    SELLER_VIEW_LEADS_REPORT_DESCRIPTION:
      'Report with results of the ads with the objective of generating leads, with metrics such as total leads and conversion leads.',
    CREATE_INCENTIVE_CAMPAIGN_FIXED_BUDGET_DESCRIPTION:
      'Allows the creation of incentive campaigns from a fixed budget.',
    REPORT_CUSTOM_DESCRIPTION: 'Custom Roport',
    CALENDAR_DESCRIPTION:
      'Allows you to create, change and remove events related to content, posts, education and goals.',
    CATEGORIZE_NEW_POST_DESCRITION:
      'Allows you to tag which category the advertised product falls into.',
    SELLER_INSTAGRAM_ORGANIC: 'Instagram Organic Post',
    SELLER_INSTAGRAM_ORGANIC_DESCRIPTION: 'Allows you to make organic posts on Instagram',
    SELLER_CAPTION_GENERATOR: 'Automated Caption Generation',
    SELLER_CAPTION_GENERATOR_DESCRIPTION: 'Allows for automated caption creation',
  },
};

const CheckCapabilitiesPT = {
  CheckCapabilities: {
    MANAGEMENT: 'Gerenciamento',
    CREATION: 'Criação',
    APP: 'Aplicativo GD',

    SELECT_ALL_CATEGORY: 'Selecione todas as permissões da categoria "{{category}}"',

    REPORT: 'Relatórios',
    APPROVAL: 'Aprovação automática',
    CONVERSION_FLOW: 'Fluxo de Conversão',
    ENGAGEMENT: 'Engajamento',
    VIDEO_VIEW: 'Video View',
    ACTIVITY: 'Atividade',
    REPORT_RANKING: 'Ranking',
    REPORT_AUDIENCE: 'Audiência',
    REPORT_CUSTOM: 'Personalizado',
    REPORT_COMMENTS: 'Comentários',
    REPORT_COMMENTS: 'Comentários',
    LEAD_ADS: 'Lead Ads',
    GENERAL_LISTING: 'Listagem Geral',
    MANAGE_GROUPS: 'Gerenciar Grupos',
    TRENDS: 'Trends',
    MEDIA_SETS_UPLOAD: 'Biblioteca de mídias',
    UNIT_SCORE: 'Score de unidade',
    USER_LISTING: 'Listagem de Usuários',
    USER_MANAGE: 'Gerenciar Usuários',
    USER_INVITE: 'Convidar usuário',
    ADACCOUNT_BUDGET: 'Orçamento',
    BUDGET_SPEND_CAP: 'Gerenciar orçamento dos grupos',
    BATCH_BUDGET_SPEND_CAP: 'Distribuir orçamento em massa',
    FEED: 'Feed',
    EDIT_AD: 'Edição de anúncios',
    FEED_AWARDS: 'Ordenar por premiações no Feed',
    CDA: 'Criativos Dinâmicos',
    BATCH_UPDATE_PROFILE_COVER: 'Mudar perfil e capa em massa',
    BATCH_PROMOTE: 'Promover',
    BATCH_REPLICATE: 'Replicar',
    USER_INFO: 'Edição de Perfil de Usuário',
    USER_PROFILE: 'Visualizar Perfil de Usuário',
    MEDIA_SETS: 'Biblioteca de Mídias',
    DEFAULT_POST: 'Postagem Default',
    MEDIA_APPROVAL: 'Controle de conteúdo produzido',
    SUGGEST_APPROVAL: 'Tipo de postagem',
    SELLER_REACH: 'Alcance',
    SELLER_BUDGET_SPEND_CAP: 'Saldo',
    SELLER_MEDIA_SETS: 'Biblioteca de mídias',
    SELLER_INSTAGRAM: 'Anúncio Instagram',
    SELLER_TARGET_SELECTION: 'Seleção de público',
    SELLER_PICTURE_CHANGE: 'Mudar foto de perfil e capa',
    SELLER_WHATSAPP: 'Contato Whatsapp',
    SELLER_VIEW_COMMENTS: 'Comentários',
    SELLER_RESPOND_TO_COMMENTS: 'Interação',
    MEDIA_SET_MANAGE: 'Envio e gerenciamento de Mídias',
    GDTV: 'Visualizar GDTV',
    GDTV_MANAGE: 'Envio de GDTV',
    SELLER_CAROUSEL: 'Carrossel',
    SELLER_MULTI_IMAGE: 'Multi imagem',
    VIEW_LEADS_REPORT: 'Visualizar Geração de cadastros',
    DOWNLOAD_LEADS_LIST: 'Download da lista de cadastros',
    SELLER_LEADS: 'Geração de Cadastros',
    SELLER_DOWNLOAD_LEADS: 'Download da lista de cadastros',
    SELLER_VIEW_LEADS_REPORT: 'Visualizar Geração de cadastros',
    CREATE_INCENTIVE_CAMPAIGN_FIXED_BUDGET: 'Campanhas de incentivo com orçamento fixo',
    CALENDAR: 'Calendário de Ações',
    CATEGORIZE_NEW_POST: 'Categorização de produto',

    APPROVAL_DESCRIPTION: 'Todo conteúdo criado via APP é automaticamente aprovado.',
    CONVERSION_FLOW_DESCRIPTION: 'Relatório com métricas de conversão',
    ENGAGEMENT_DESCRIPTION: 'Relatório com métricas de engajamento.',
    VIDEO_VIEW_DESCRIPTION:
      'Relatório com métricas de resultados da produção de conteúdos em vídeo, engajamento nos vídeos, porcentagem de visualização, entre outras.',
    ACTIVITY_DESCRIPTION:
      'Atividade das lojas no projeto, como a quantidade de conteúdos produzidos, média mensal, porcentual do formato, unidades ativas e usuários conectados.',
    REPORT_RANKING_DESCRIPTION:
      'Relatório para comparar o desempenho, em diferentes métricas, das unidades.',
    REPORT_COMMENTS_DESCRIPTION:
      'Relatório de comentários em publicações das unidades, com métricas de tempo e porcentagens de respostas.',
    REPORT_AUDIENCE_DESCRIPTION: 'Análise de Idade, Gênero, Canais e Dispositivos do público.',
    REPORT_COMMENTS_DESCRIPTION:
      'Relatório de comentários em publicações das unidades, com métricas de tempo e porcentagens de respostas.',
    LEAD_ADS_DESCRIPTION: 'Relatório de resultados dos anúncios com objetivo de captação de Lead.',
    GENERAL_LISTING_DESCRIPTION:
      'Visualização dos grupos em forma de listagem, com possibilidade de entrada para adaptação da plataforma para os dados daquele grupo.',
    MANAGE_GROUPS_DESCRIPTION:
      'Permite mover, excluir, renomear um grupo, gerenciar contas e substituir IDs das páginas.',
    TRENDS_DESCRIPTION: 'Visualizar trends',
    MEDIA_SETS_UPLOAD_DESCRIPTION: 'Enviar biblioteca de mídias',
    UNIT_SCORE_DESCRIPTION: 'Visualizar score de unidade',
    USER_LISTING_DESCRIPTION: 'Visualização de todas as contas de usuários.',
    USER_MANAGE_DESCRIPTION: 'Permite editar permissões de usuários.',
    USER_INVITE_DESCRIPTION: 'Dá a capacidade de adicionar usuários e bloquear usuários ativos.',
    ADACCOUNT_BUDGET_DESCRIPTION: 'Visualizar o limite de Orçamento das contas de anúncios.',
    BUDGET_SPEND_CAP_DESCRIPTION: 'Permite depositar ou remover verba das unidades.',
    BATCH_BUDGET_SPEND_CAP_DESCRIPTION:
      'Permite que possa distribuir a verba disponível para várias unidades ao mesmo tempo',
    FEED_DESCRIPTION: 'Acesso ao feed de postagens e anúncios.',
    EDIT_AD_DESCRIPTION:
      'Permite a edição em: período de veiculação, investimento, objetivo de campanha e pausar anúncios.',
    FEED_AWARDS_DESCRIPTION: 'Possibilita ordenar os anúncios por premiação.',
    CDA_DESCRIPTION: 'Permite a distribuição de mídias personalizadas entre as unidades e grupos.',
    BATCH_UPDATE_PROFILE_COVER_DESCRIPTION:
      'Permite alterar a foto de perfil e capa das páginas pela plataforma.',
    BATCH_PROMOTE_DESCRIPTION: 'Permite promover postagens ou anúncios em massa.',
    BATCH_REPLICATE_DESCRIPTION: 'Permite replicar postagens ou anúncios em massa.',
    USER_INFO_DESCRIPTION: 'Editar meu perfil de usuário no GD.',
    USER_PROFILE_DESCRIPTION: 'Visualizar meu perfil de usuário no GD.',
    MEDIA_SETS_DESCRIPTION: 'Permite receber arquivos de mídias na biblioteca.',
    DEFAULT_POST_DESCRIPTION: 'Marcar postagem default (interno)',
    MEDIA_APPROVAL_DESCRIPTION: 'Possibilita aprovar ou reprovar a mídia vinda do APP',
    AUTO_APPROVAL_DESCRIPTION: 'Todo conteúdo criado via APP é automaticamente aprovado.',
    SUGGEST_APPROVAL_DESCRIPTION:
      'Permite ao usuário do APP poder definir se o conteúdo será pago ou orgânico e configurá-lo',
    REPORT_DESCRIPTION:
      'Saúde geral da unidade, do grupo ou de todos os grupos, dependendo do nível de acesso do usuário, com orçamentos, impressões, entre outras.',
    SELLER_REACH_DESCRIPTION: 'Visualizar as métricas dos anúncios no feed.',
    SELLER_BUDGET_SPEND_CAP_DESCRIPTION: 'Visualizar o saldo da conta.',
    SELLER_MEDIA_SETS_DESCRIPTION: 'Permite receber arquivos de mídias na biblioteca.',
    SELLER_INSTAGRAM_DESCRIPTION: 'Permite fazer via APP anúncios para o Instagram.',
    SELLER_TARGET_SELECTION_DESCRIPTION: 'Selecionar público que será alcançado pelo anúncio.',
    SELLER_PICTURE_CHANGE_DESCRIPTION:
      'Permite alterar a foto de perfil e capa da página do Facebook.',
    SELLER_WHATSAPP_DESCRIPTION: 'Permite alterar o número de Whatsapp da página do Facebook.',
    SELLER_VIEW_COMMENTS_DESCRIPTION: 'Visualizar os comentários recebidos na página do Facebook.',
    SELLER_RESPOND_TO_COMMENTS_DESCRIPTION:
      'Permite interagir com os comentários recebidos na página do Facebook.',
    MEDIA_SET_MANAGE_DESCRIPTION:
      'Permite distribuir mídias entre as unidades e grupos; permite também excluir pastas e arquivos.',
    GDTV_DESCRIPTION: 'Permite visualizar os conteúdos enviados via GDTV.',
    GDTV_MANAGE_DESCRIPTION:
      'Permite a distribuição de conteúdos via GDTV. Os conteúdos distribuídos poderão ser vistos no feed do aplicativo pelas unidades.',
    SELLER_CAROUSEL_DESCRIPTION:
      'Possibilidade de criar um único anúncio com até dez imagens ou vídeos, cada mídia com seu próprio título, descrição e link.',
    SELLER_MULTI_IMAGE_DESCRIPTION:
      'Permite fazer postagens e anúncios com mais de uma imagem ou vídeo.',
    VIEW_LEADS_REPORT_DESCRIPTION:
      'Relatório com resultados dos anúncios com objetivo de geração de cadastros, com métricas de cadastros totais e conversão para cadastro.',
    DOWNLOAD_LEADS_LIST_DESCRIPTION:
      'Permite baixar a planilha com os dados dos usuários cadastrados a partir da campanha de geração de leads.',
    SELLER_LEADS_DESCRIPTION: 'Permite criar campanhas com objetivo de geração de cadastros.',
    SELLER_DOWNLOAD_LEADS_DESCRIPTION:
      'Permite baixar a planilha com os dados dos usuários cadastrados a partir da campanha de geração de leads no aplicativo.',
    SELLER_VIEW_LEADS_REPORT_DESCRIPTION:
      'Relatório com resultados dos anúncios com objetivo de geração de cadastros, com métricas de cadastros totais e conversão para cadastro.',
    CREATE_INCENTIVE_CAMPAIGN_FIXED_BUDGET_DESCRIPTION:
      'Permite a criação de campanhas de incentivo a partir de um orçamento fixo.',
    REPORT_CUSTOM_DESCRIPTION: 'Relatório Personalizado',
    CALENDAR_DESCRIPTION:
      'Permite criar, alterar e remover eventos relacionados a conteúdo, publicações, educação e metas.',
    CATEGORIZE_NEW_POST_DESCRIPTION:
      'Permite o tagueamento de qual categoria o produto anunciado se enquadra.',
    SELLER_INSTAGRAM_ORGANIC: 'Postagem Orgânica Instagram',
    SELLER_INSTAGRAM_ORGANIC_DESCRIPTION: 'Permite fazer postagens orgânicas no Instagram',
    SELLER_CAPTION_GENERATOR: 'Geração de Legendas Automatizadas',
    SELLER_CAPTION_GENERATOR_DESCRIPTION: 'Permite criar legendas de forma automatizada',
  },
};

const CheckCapabilitiesES = {
  CheckCapabilities: {
    MANAGEMENT: 'Gestión',
    CREATION: 'Creación',
    APP: 'Aplicación GD',

    SELECT_ALL_CATEGORY: 'Seleccione todos los permisos de la categoría "{{category}}"',

    REPORT: 'Informes',
    APPROVAL: 'Aprobación automática',
    CONVERSION_FLOW: 'Flujo de conversión',
    ENGAGEMENT: 'Compromiso',
    VIDEO_VIEW: 'Vista de video',
    ACTIVITY: 'Actividad',
    REPORT_RANKING: 'Clasificación',
    REPORT_AUDIENCE: 'Audiencia',
    REPORT_CUSTOM: 'Personalizado',
    REPORT_COMMENTS: 'Comentarios',
    REPORT_COMMENTS: 'Comentarios',
    LEAD_ADS: 'Lead Ads',
    GENERAL_LISTING: 'Listado general',
    MANAGE_GROUPS: 'Administrar grupos',
    TRENDS: 'Trends',
    MEDIA_SETS_UPLOAD: 'Biblioteca multimedia',
    UNIT_SCORE: 'Puntuación de usuario',
    USER_LISTING: 'Listado de usuarios',
    USER_MANAGE: 'Administrar usuarios',
    USER_INVITE: 'Invitar usuario',
    ADACCOUNT_BUDGET: 'Presupuesto',
    BUDGET_SPEND_CAP: 'Administrar el presupuesto de los grupos',
    BATCH_BUDGET_SPEND_CAP: 'Distribuir presupuesto masivo',
    FEED: 'Feed',
    EDIT_AD: 'Edición de anuncios',
    FEED_AWARDS: 'Ordenar por premios en Feed',
    CDA: 'Creatividades dinámicas',
    BATCH_UPDATE_PROFILE_COVER: 'Cambiar perfil y cobertura a granel',
    BATCH_PROMOTE: 'Promover',
    BATCH_REPLICATE: 'Reproducir',
    USER_INFO: 'Edición de perfil de usuario',
    USER_PROFILE: 'Ver perfil de usuario',
    MEDIA_SETS: 'Mediateca',
    DEFAULT_POST: 'Publicación predeterminada',
    MEDIA_APPROVAL: 'Control de contenido producido',
    SUGGEST_APPROVAL: 'Tipo de correo',
    SELLER_REACH: 'Alcance',
    SELLER_BUDGET_SPEND_CAP: 'Saldo',
    SELLER_MEDIA_SETS: 'Mediateca',
    SELLER_INSTAGRAM: 'Anuncio de Instagram',
    SELLER_TARGET_SELECTION: 'Selección de audiencia',
    SELLER_PICTURE_CHANGE: 'Cambiar foto de perfil y portada',
    SELLER_WHATSAPP: 'Contacto Whatsapp',
    SELLER_VIEW_COMMENTS: 'Comentarios',
    SELLER_RESPOND_TO_COMMENTS: 'Interacción',
    MEDIA_SET_MANAGE: 'Envío y gestión de medios',
    GDTV: 'Ver GDTV',
    GDTV_MANAGE: 'Envío de GDTV',
    SELLER_CAROUSEL: 'Carrusel',
    SELLER_MULTI_IMAGE: 'Multi imagen',
    VIEW_LEADS_REPORT: 'Ver generación de registro',
    DOWNLOAD_LEADS_LIST: 'Descarga la lista de entradas',
    SELLER_LEADS: 'Generación de registros',
    SELLER_DOWNLOAD_LEADS: 'Descarga la lista de entradas',
    SELLER_VIEW_LEADS_REPORT: 'Ver generación de registro',
    CREATE_INCENTIVE_CAMPAIGN_FIXED_BUDGET: 'Campañas de incentivos con presupuesto fijo',
    CALENDAR: 'Calendario de Acciones',
    CATEGORIZE_NEW_POST: 'Categorización de productos',

    APPROVAL_DESCRIPTION:
      'Todo el contenido creado a través de la aplicación se aprueba automáticamente.',
    CONVERSION_FLOW_DESCRIPTION: 'Informe con métricas de conversión',
    ENGAGEMENT_DESCRIPTION: 'Informe con métricas de participación.',
    VIDEO_VIEW_DESCRIPTION:
      'Informe con métricas de resultados de producción de contenido de video, participación de video, porcentaje de visualización, entre otros.',
    ACTIVITY_DESCRIPTION:
      'Almacene la actividad en el proyecto, como la cantidad de contenido producido, promedio mensual, porcentaje de formato, unidades activas y usuarios conectados.',
    REPORT_RANKING_DESCRIPTION:
      'Informe para comparar el rendimiento, en diferentes métricas, de las unidades.',
    REPORT_COMMENTS_DESCRIPTION:
      'Informe de comentarios sobre publicaciones de las unidades, con métricas de tiempo y porcentajes de respuesta.',
    REPORT_AUDIENCE_DESCRIPTION:
      'Análisis de edad, género, canales y dispositivos de la audiencia.',
    REPORT_COMMENTS_DESCRIPTION:
      'Informe de comentarios sobre publicaciones de las unidades, con métricas de tiempo y porcentajes de respuesta.',
    LEAD_ADS_DESCRIPTION: 'Informe de resultados de anuncios con el objetivo de captar un Lead.',
    GENERAL_LISTING_DESCRIPTION:
      'Visualización de grupos en forma de lista, con posibilidad de entrada para adaptar la plataforma a los datos de ese grupo.',
    MANAGE_GROUPS_DESCRIPTION:
      'Le permite mover, eliminar, cambiar el nombre de un grupo, administrar cuentas y reemplazar ID de página.',
    TRENDS_DESCRIPTION: 'Ver trends',
    MEDIA_SETS_UPLOAD_DESCRIPTION: 'Enviar biblioteca multimedia',
    UNIT_SCORE_DESCRIPTION: 'Ver puntuación de usuario',
    USER_LISTING_DESCRIPTION: 'Vista de todas las cuentas de usuario.',
    USER_MANAGE_DESCRIPTION: 'Le permite editar los permisos de usuario.',
    USER_INVITE_DESCRIPTION:
      'Ofrece la posibilidad de agregar usuarios y bloquear usuarios activos.',
    ADACCOUNT_BUDGET_DESCRIPTION: 'Ver el límite de presupuesto para cuentas publicitarias.',
    BUDGET_SPEND_CAP_DESCRIPTION: 'Le permite depositar o retirar fondos de las unidades.',
    BATCH_BUDGET_SPEND_CAP_DESCRIPTION:
      'Le permite distribuir el presupuesto disponible a varias unidades al mismo tiempo',
    FEED_DESCRIPTION: 'Acceso a las publicaciones y al feed de anuncios.',
    EDIT_AD_DESCRIPTION:
      'Permite editar en: período de ubicación, inversión, objetivo de campaña y pausa de anuncios.',
    FEED_AWARDS_DESCRIPTION: 'Le permite ordenar los anuncios por premio.',
    CDA_DESCRIPTION: 'Permite la distribución de medios personalizados entre unidades y grupos.',
    BATCH_UPDATE_PROFILE_COVER_DESCRIPTION:
      'Le permite cambiar la foto de perfil y la portada de la página por plataforma.',
    BATCH_PROMOTE_DESCRIPTION: 'Le permite promocionar publicaciones o anuncios de forma masiva.',
    BATCH_REPLICATE_DESCRIPTION: 'Le permite replicar publicaciones o anuncios de forma masiva.',
    USER_INFO_DESCRIPTION: 'Edite mi perfil de usuario en GD.',
    USER_PROFILE_DESCRIPTION: 'Ver mi perfil de usuario en GD.',
    MEDIA_SETS_DESCRIPTION: 'Le permite recibir archivos multimedia en la biblioteca.',
    DEFAULT_POST_DESCRIPTION: 'Marcar publicación predeterminada (interna)',
    MEDIA_APPROVAL_DESCRIPTION:
      'Le permite aprobar o desaprobar los medios provenientes de la aplicación',
    AUTO_APPROVAL_DESCRIPTION:
      'Todo el contenido creado a través de la aplicación se aprueba automáticamente.',
    SUGGEST_APPROVAL_DESCRIPTION:
      'Permite al usuario de la aplicación definir si el contenido será de pago u orgánico y configurarlo',
    REPORT_DESCRIPTION:
      'Salud general de la unidad, del grupo o de todos los grupos, según el nivel de acceso del usuario, con presupuestos, impresiones, entre otros.',
    SELLER_REACH_DESCRIPTION: 'Ver métricas de anuncios en el feed.',
    SELLER_BUDGET_SPEND_CAP_DESCRIPTION: 'Ver el saldo de su cuenta.',
    SELLER_MEDIA_SETS_DESCRIPTION: 'Le permite recibir archivos multimedia en la biblioteca.',
    SELLER_INSTAGRAM_DESCRIPTION:
      'Le permite hacer anuncios en Instagram a través de la aplicación.',
    SELLER_TARGET_SELECTION_DESCRIPTION: 'Seleccione la audiencia a la que llegará el anuncio.',
    SELLER_PICTURE_CHANGE_DESCRIPTION:
      'Le permite cambiar la foto de perfil y la portada de la página de Facebook.',
    SELLER_WHATSAPP_DESCRIPTION:
      'Le permite cambiar el número de Whatsapp de la página de Facebook.',
    SELLER_VIEW_COMMENTS_DESCRIPTION: 'Vea los comentarios recibidos en la página de Facebook.',
    SELLER_RESPOND_TO_COMMENTS_DESCRIPTION:
      'Le permite interactuar con los comentarios recibidos en la página de Facebook.',
    MEDIA_SET_MANAGE_DESCRIPTION:
      'Le permite distribuir medios entre unidades y grupos; también le permite eliminar carpetas y archivos.',
    GDTV_DESCRIPTION: 'Le permite ver los contenidos enviados a través de GDTV.',
    GDTV_MANAGE_DESCRIPTION:
      'Permite la distribución de contenido a través de GDTV. Los contenidos distribuidos se pueden ver en el feed de la aplicación por las unidades.',
    SELLER_CAROUSEL_DESCRIPTION:
      'Posibilidad de crear un solo anuncio con hasta diez imágenes o videos, cada medio con su propio título, descripción y enlace.',
    SELLER_MULTI_IMAGE_DESCRIPTION: 'Le permite publicar anuncios con más de una imagen o video.',
    VIEW_LEADS_REPORT_DESCRIPTION:
      'Informe con resultados publicitarios con el propósito de generar entradas, con métricas de entradas totales y conversión a matrícula.',
    DOWNLOAD_LEADS_LIST_DESCRIPTION:
      'Permite descargar la hoja de cálculo con los datos de los usuarios registrados de la campaña de generación de leads.',
    SELLER_LEADS_DESCRIPTION: 'Le permite crear campañas con el propósito de generar registros.',
    SELLER_DOWNLOAD_LEADS_DESCRIPTION:
      'Permite descargar la hoja de cálculo con los datos de los usuarios registrados de la campaña de generación de leads en la aplicación.',
    SELLER_VIEW_LEADS_REPORT_DESCRIPTION:
      'Informe con resultados publicitarios con el propósito de generar entradas, con métricas de entradas totales y conversión a matrícula.',
    CREATE_INCENTIVE_CAMPAIGN_FIXED_BUDGET_DESCRIPTION:
      'Permite la creación de campañas de incentivos a partir de un presupuesto fijo.',
    REPORT_CUSTOM_DESCRIPTION: 'Informe personalizado',
    CALENDAR_DESCRIPTION:
      'Le permite crear, cambiar y eliminar eventos relacionados con contenido, publicaciones, educación y objetivos.',
    CATEGORIZE_NEW_POST_DESCRIPTION:
      'Le permite etiquetar en qué categoría se encuentra el producto anunciado.',
    SELLER_INSTAGRAM_ORGANIC: 'Publicación Orgánica de Instagram',
    SELLER_INSTAGRAM_ORGANIC_DESCRIPTION: 'Le permite hacer publicaciones orgánicas en Instagram',
    SELLER_CAPTION_GENERATOR: 'Generador Automático de Leyendas',
    SELLER_CAPTION_GENERATOR_DESCRIPTION: 'Permite la creación automática de leyendas',
  },
};

export { CheckCapabilitiesEN, CheckCapabilitiesPT, CheckCapabilitiesES };
