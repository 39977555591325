import styled, { css } from 'styled-components';

interface DropZoneProps {
  over: boolean;
  width: number;
  height: number;
  image: string;
}

export const DropZone = styled.div<DropZoneProps>`
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${({ width, height, image, over, theme }) => css`
    width: ${width}px;
    height: ${height}px;
    background-image: url(${image || ''});
    color: ${theme.colors.grey9};

    ${over
      ? css`
          border: 2px dashed grey;
          background-color: ${theme.colors.grey2};
          color: ${theme.colors.grey11};
        `
      : css`
          border: 2px solid grey;
          background-color: ${theme.colors.grey4};
          color: ${theme.colors.grey9};
        `}
  `}
`;

export const ButtonsContainer = styled.div`
  display: flex;

  ${({ theme }) => css`
    gap: ${theme.spacing.px10};
    margin: ${theme.spacing.px10} 0;
  `}
`;

export const UploadAreaPlaceholder = styled.div`
  text-align: center;

  ${({ theme }) => css`
    font-size: ${theme.fontSize.px32};
  `}
`;

export const EmptyUploadArea = styled.div`
  pointer-events: none;
`;

export const Label = styled.label`
  cursor: pointer;

  ${({ theme }) => css`
    background-color: ${theme.colors.blueSecurity};
    color: ${theme.colors.white};
    border-radius: ${theme.rounded.px8};
    font-size: ${theme.fontSize.px14};
    padding: ${theme.spacing.px8};

    :hover {
      background-color: ${theme.colors.blueSecurity4};
    }
  `}
`;

export const DeleteButton = styled.button`
  border: none;
  cursor: pointer;

  ${({ theme }) => css`
    background-color: ${theme.colors.errorRed};
    color: ${theme.colors.white};
    border-radius: ${theme.rounded.px8};
    font-size: ${theme.fontSize.px14};
  `}
`;

export const FileUploadButton = styled.input`
  display: none;
`;

export const DropZoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
