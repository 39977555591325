const TradeTrackingEN = {
  TradeTracking: {
    TRACKING_TITLE: 'Ad Coverage Data',
    GENERAL_LABEL: 'General',
    MONTH_AVERAGE_GRAPH_TITLE: 'Monthly average ad coverage',
    MONTH_AVERAGE_GRAPH_YAXIS: 'Ad coverage ( % )',
    DATA_CAPACITY: 'Average data capacity of the last 6 months',
    AMOUNT_ADS_TRACKED: 'Amount of tracked ads',
    ADS_TRACKED: 'Tracked Ads',
    TRACKED_PERCENT: '% tracked',
  },
};

const TradeTrackingPT = {
  TradeTracking: {
    TRACKING_TITLE: 'Dados de Cobertura de Anúncios',
    GENERAL_LABEL: 'Geral',
    MONTH_AVERAGE_GRAPH_TITLE: 'Média mensal de cobertura de anúncios',
    MONTH_AVERAGE_GRAPH_YAXIS: 'Cobertura de anúncios ( % )',
    DATA_CAPACITY: 'Capacidade media de dados dos ultimos 6 meses',
    AMOUNT_ADS_TRACKED: 'Quantidade de anúncios trackeados',
    ADS_TRACKED: 'Anúncios trackeados',
    TRACKED_PERCENT: '% de trackeamento',
  },
};

const TradeTrackingES = {
  TradeTracking: {
    TRACKING_TITLE: 'Datos de Cobertura de Anuncios',
    GENERAL_LABEL: 'General',
    MONTH_AVERAGE_GRAPH_TITLE: 'Cobertura publicitaria promedio mensual',
    MONTH_AVERAGE_GRAPH_YAXIS: 'Cobertura publicitaria ( % )',
    DATA_CAPACITY: 'Capacidad media de datos de los últimos 6 meses',
    AMOUNT_ADS_TRACKED: 'Cantidad de anuncios rastreados',
    ADS_TRACKED: 'Anuncios rastreados',
    TRACKED_PERCENT: '% de rastreados',
  },
};

export { TradeTrackingEN, TradeTrackingPT, TradeTrackingES };
