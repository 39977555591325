import React, { useState } from 'react';
import Alert from '@components/Alert';
import { LoadingOld } from '@components/LoadingOld/LoadingOld';
import { SchemaService } from '@services';
import * as S from './style';

function Offline({ description, linkText }) {
  const [loading, setLoading] = useState(false);

  const checkIfConnected = async () => {
    try {
      setLoading(true);
      const response = await SchemaService.current('1.1');

      if (response.status === 200) {
        window.location.reload();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Alert>
      <S.CustomIcon icon={['far', 'wifi-slash']} />
      <S.OfflineDescription>{description}</S.OfflineDescription>
      <S.LinkReload onClick={() => checkIfConnected()}>{linkText}</S.LinkReload>
      {loading && (
        <S.LoadingWrapper>
          <LoadingOld isLoading centerIcon />
        </S.LoadingWrapper>
      )}
    </Alert>
  );
}

export default Offline;
