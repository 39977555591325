import styled, { css } from 'styled-components';

interface SelectWrapperProps {
  withoutBorder?: boolean;
}

export const SelectWrapper = styled.div<SelectWrapperProps>`
  ${({ theme, withoutBorder }) => css`
    border: none;
    display: inline-block;
    position: relative;
    overflow: hidden;
    min-width: 180px;
    border-bottom: 1px solid ${theme.colors.grey2};
    width: ${theme.spacing.full};

    ${withoutBorder && 'border: none;'}
  `}
`;

interface SelectStyledProps {
  disabled?: boolean;
  selectSize?: string;
}

export const SelectStyled = styled.select<SelectStyledProps>`
  background: none;
  outline: none;
  border: none;
  appearance: none;
  -webkit-appearance: none;
  position: relative;
  z-index: 1;
  height: auto;

  ${({ theme, disabled, selectSize }) => css`
    width: ${theme.spacing.full};

    font-size: ${theme.fontSize.px16};
    padding: 10px 40px 10px 12px;
    ${selectSize === 'px14' &&
    css`
      font-size: ${theme.fontSize.px14};
      padding: 5px 30px 5px 10px;
    `}
    + svg {
      width: ${theme.spacing.px25};
      height: ${theme.spacing.px25};
      right: ${theme.spacing.px10};
      top: ${theme.spacing.px5};
      fill: ${theme.colors.grey2};
      position: absolute;
      z-index: 0;
      ${selectSize === 'px14' &&
      css`
        width: ${theme.spacing.px15};
        height: ${theme.spacing.px15};
        top: ${theme.spacing.px5};
      `}
    }
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
  `}
`;
