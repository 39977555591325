import React from 'react';
import { useTranslate } from 'react-translate';
import CustomModal from 'components/CustomModal/CustomModal';
import { RootStateOrAny, useSelector } from 'react-redux';
import ImageDropZone from './ImageDropZone';
import * as S from './styles';
import { Image } from '../types';

interface ModalProps {
  isOpen: boolean;
  handleToggle: () => void;
  onDrop: (image: Image, flag?: boolean) => void;
  postNewImages: () => void;
}

export default function ModalChangeImages({
  isOpen,
  handleToggle,
  onDrop,
  postNewImages,
}: ModalProps) {
  const t = useTranslate('ProfileAndCover');
  const { isLoading } = useSelector((state: RootStateOrAny) => state.loadingReducer);

  return (
    <CustomModal visible={isOpen} onClickAway={handleToggle} width="90%">
      <S.CustomModalContent>
        <S.Croppers>
          <ImageDropZone
            width={180}
            height={180}
            imagePicked={(image: Image) => onDrop(image, true)}
          />
          <ImageDropZone width={820} height={360} imagePicked={(image: Image) => onDrop(image)} />
        </S.Croppers>

        <S.SaveButton
          onClick={postNewImages}
          eventName="click_save_modal_change_image"
          disabled={isLoading}
        >
          {isLoading ? '...' : t('CONFIRM')}
        </S.SaveButton>
      </S.CustomModalContent>
    </CustomModal>
  );
}
