const DashboardEN = {
  DashboardOff: {
    DESCRIPTION_TEXT: 'No internet connection',
    LINK_TEXT: 'Try again',
  },
};

const DashboardPT = {
  DashboardOff: {
    DESCRIPTION_TEXT: 'Sem internet',
    LINK_TEXT: 'Tentar novamente',
  },
};

const DashboardES = {
  DashboardOff: {
    DESCRIPTION_TEXT: 'Sin internet',
    LINK_TEXT: 'Inténtalo de nuevo',
  },
};
export { DashboardPT, DashboardEN, DashboardES };
