/* Create action types */
import createActionTypes from '../createActionTypes';

export const POST_COVER_PROFILE_IMAGE = createActionTypes('POST_COVER_PROFILE_IMAGE');
export const GET_ALL_ACCOUNTS = createActionTypes('GET_ALL_ACCOUNTS');
export const GET_ALL_FANPAGES = createActionTypes('GET_ALL_FANPAGES');
export const GET_RECEIVED = createActionTypes('GET_RECEIVED');
export const SEND_ANSWER = createActionTypes('SEND_ANSWER');
export const EDIT_DESCRIPTION_MEDIA = createActionTypes('EDIT_DESCRIPTION_MEDIA');
export const DISAPPROVE_MEDIA = createActionTypes('DISAPPROVE_MEDIA');
export const DISAPPROVE_MEDIA_MULT_IMG = createActionTypes('DISAPPROVE_MEDIA_MULT_IMG');
export const APPROVE_MEDIA = createActionTypes('APPROVE_MEDIA');
export const SHOW_MODAL_APPROVE_OR_DISAPPROVE = createActionTypes(
  'SHOW_MODAL_APPROVE_OR_DISAPPROVE'
);
export const APPROVE_MEDIA_MULT_IMG = createActionTypes('APPROVE_MEDIA_MULT_IMG');
export const UPDATE_MEDIA_MULT_IMG = createActionTypes('UPDATE_MEDIA_MULT_IMG');
