/* Modules */
import { call, put, takeEvery } from 'redux-saga/effects';
import { VideoViewService } from '@services';

/* Types */
import { HEADER_AND_FOOTER_INFO, CHART, AUDIENCE, TABLE } from './types';

function* headerAndFooterInfo(action) {
  const response = yield call(VideoViewService.headerAndFooterInfo, action.data);

  if (response.status === 200) {
    yield put({ type: HEADER_AND_FOOTER_INFO.SUCCESS, data: response.data });
  } else {
    yield put({ type: HEADER_AND_FOOTER_INFO.FAILURE, data: response.data.error });
  }
}
function* chart(action) {
  const response = yield call(VideoViewService.chart, action.data);

  if (response.status === 200) {
    yield put({ type: CHART.SUCCESS, data: response.data });
  } else {
    yield put({ type: CHART.FAILURE, data: response.data.error });
  }
}
function* audience(action) {
  const response = yield call(VideoViewService.audience, action.data);

  if (response.status === 200) {
    yield put({ type: AUDIENCE.SUCCESS, data: response.data });
  } else {
    yield put({ type: AUDIENCE.FAILURE, data: response.data.error });
  }
}
function* table(action) {
  const response = yield call(VideoViewService.table, action.data);

  if (response.status === 200) {
    yield put({ type: TABLE.SUCCESS, data: response.data });
  } else {
    yield put({ type: TABLE.FAILURE, data: response.data.error });
  }
}
export const videoViewSaga = [
  takeEvery(HEADER_AND_FOOTER_INFO.REQUEST, headerAndFooterInfo),
  takeEvery(CHART.REQUEST, chart),
  takeEvery(AUDIENCE.REQUEST, audience),
  takeEvery(TABLE.REQUEST, table),
];
