// Types
import { IS_LOADING } from './types';

const INITIAL_STATE = {
  isLoading: false,
};

const loadingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IS_LOADING.SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    default:
      return state;
  }
};

export default loadingReducer;
