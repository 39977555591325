const ErrorPageEN = {
  ErrorPage: {
    CHOOSE_PAGE: 'So how about moving on to one of the other pages?',
    GLOBAL: 'Global',
    LISTALL: 'List all',
    RANKING: 'Ranking',
    AUDIENCE: 'Audience',
    BUDGET: 'Budget',
    MEDIA: 'Media',
    ERROR_400_TITLE: 'Error 400',
    ERROR_400_DESCRIPTION:
      "Something has broken here... Our team has been notified and it's currently working on the solution",
    ERRO_404_TITLE: 'Error 404',
    ERRO_404_DESCRIPTION: "This page doesn't exist...",
    ERRO_LOADING_TITLE: 'We identified a server problem',
    ERRO_LOADING_DESCRIPTION: 'Our team is working on it and soon you can get back to that task.',
    CI: 'Incentive Campaigns',
  },
};

const ErrorPagePT = {
  ErrorPage: {
    CHOOSE_PAGE: 'Então que tal seguir para uma das outras páginas?',
    GLOBAL: 'Visão global',
    LISTALL: 'Listagem geral',
    RANKING: 'Ranking',
    AUDIENCE: 'Audiência',
    BUDGET: 'Orçamentos',
    MEDIA: 'Mídias',
    ERROR_400_TITLE: 'Erro 400',
    ERROR_400_DESCRIPTION:
      'Alguma coisa quebrou aqui... Nosso time foi notificado e está trabalhando nisso.',
    ERRO_404_TITLE: 'Erro 404',
    ERRO_404_DESCRIPTION: 'Esta página não existe...',
    ERRO_LOADING_TITLE: 'Identificamos um problema no servidor',
    ERRO_LOADING_DESCRIPTION:
      'Nosso time está trabalhando nisso e em breve você consegue voltar à essa tarefa.',
    CI: 'Campanhas de incentivos',
  },
};

const ErrorPageES = {
  ErrorPage: {
    CHOOSE_PAGE: 'Entonces, ¿qué tal si sigues una de las otras páginas?',
    GLOBAL: 'Visión global',
    LISTALL: 'Listado general',
    RANKING: 'Ranking',
    AUDIENCE: 'Audiencia',
    BUDGET: 'Presupuestos',
    MEDIA: 'Medios',
    ERROR_400_TITLE: 'Error 400',
    ERROR_400_DESCRIPTION:
      'Algo se rompió aquí... Nuestro equipo ha sido notificado y está trabajando en ello.',
    ERRO_404_TITLE: 'Error 404',
    ERRO_404_DESCRIPTION: 'Esta página no existe...',
    ERRO_LOADING_TITLE: 'Hemos detectado un problema en el servidor',
    ERRO_LOADING_DESCRIPTION:
      'Nuestro equipo está trabajando en ello y pronto podrás volver a esta tarea.',
    CI: 'Campañas de incentivos',
  },
};

export { ErrorPagePT, ErrorPageEN, ErrorPageES };
