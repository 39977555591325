import React from 'react';

import { useTranslate } from 'react-translate';
import * as S from './styles';

function GroupItem({
  isOpen = false,
  hasParent,
  closeGroup,
  color,
  name,
  hasChild,
  id,
  selected,
  onSelect,
  openGroup,
}) {
  const t = useTranslate('BreadCrumb');

  return (
    <S.Wrapper open={isOpen}>
      {isOpen && hasParent && <S.GoBackIcon onClick={closeGroup} icon={['fad', 'arrow-left']} />}
      <S.Color color={color} open={isOpen} />
      <S.Name open={isOpen}>
        {name}
        {hasChild && (
          <S.OpenChilds open={isOpen} onClick={() => openGroup(id)}>
            {t('OPEN')}
          </S.OpenChilds>
        )}
      </S.Name>
      <S.CheckWrapper open={isOpen}>
        <input type="radio" checked={selected} onChange={onSelect} />
      </S.CheckWrapper>
    </S.Wrapper>
  );
}

export default GroupItem;
