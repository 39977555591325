import styled, { css } from 'styled-components';

export const LinkDownload = styled('a')`
  ${({ theme }) => css`
    background: rgba(0, 0, 0, 0.85);
    border-radius: ${theme.rounded.full};
    width: ${theme.spacing.px25};
    height: ${theme.spacing.px25};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    transition: all 0.3s;
    svg {
      font-size: ${theme.fontSize.px16};
      color: ${theme.colors.white};
    }
  `}
`;
