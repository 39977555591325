/* Create action types */
import createActionTypes from '../createActionTypes';

export const GET_FOLDERS = createActionTypes('GET_FOLDERS');
export const GET_GDTV_FOLDERS = createActionTypes('GET_GDTV_FOLDERS');
export const GET_FOLDER = createActionTypes('GET_FOLDER');
export const POST_FOLDER = createActionTypes('POST_FOLDER');
export const POST_GDTV_FOLDER = createActionTypes('POST_GDTV_FOLDER');
export const POST_CAMPAIGN_FOLDER = createActionTypes('POST_CAMPAIGN_FOLDER');
export const POST_MEDIA = createActionTypes('POST_MEDIA');
export const DELETE_MEDIA = createActionTypes('DELETE_MEDIA');
export const SET_CURRENT_CREATIVE = createActionTypes('SET_CURRENT_CREATIVE');
export const SET_CURRENT_GDTV_CREATIVE = createActionTypes('SET_CURRENT_GDTV_CREATIVE');
export const SET_CURRENT_CAMPAIGN_CREATIVE = createActionTypes('SET_CURRENT_CAMPAIGN_CREATIVE');
export const SET_CREATIVE_INFO = createActionTypes('SET_CREATIVE_INFO');
export const REMOVE_CREATIVE = createActionTypes('REMOVE_CREATIVE');
export const REMOVE_GDTV_FOLDER = createActionTypes('REMOVE_GDTV_FOLDER');
export const DISABLE_GDTV_FOLDER = createActionTypes('DISABLE_GDTV_FOLDER');
export const ENABLE_GDTV_FOLDER = createActionTypes('ENABLE_GDTV_FOLDER');
export const REMOVE_CAMPAIGN_FOLDER = createActionTypes('REMOVE_CAMPAIGN_FOLDER');
export const DOWNLOAD_MEDIA = createActionTypes('DOWNLOAD_MEDIA');
export const DOWNLOAD_GDTV_MEDIA = createActionTypes('DOWNLOAD_GDTV_MEDIA');
export const DOWNLOAD_CAMPAIGN_MEDIA = createActionTypes('DOWNLOAD_CAMPAIGN_MEDIA');
export const GET_CHILD_GROUPS = createActionTypes('GET_CHILD_GROUPS');
export const SHARE_TEMPLATE = createActionTypes('SHARE_TEMPLATE');
export const GET_GDTV_FOLDER = createActionTypes('GET_GDTV_FOLDER');
export const GET_CAMPAIGN_FOLDER = createActionTypes('GET_CAMPAIGN_FOLDER');
export const EDIT_GDTV_FOLDER = createActionTypes('EDIT_GDTV_FOLDER');
export const EDIT_CAMPAIGN_FOLDER = createActionTypes('EDIT_CAMPAIGN_FOLDER');
export const SET_GDTV_TYPE = createActionTypes('SET_GDTV_TYPE');
export const GET_POSTS = createActionTypes('GET_POSTS');
export const GET_ADS = createActionTypes('GET_ADS');
export const GET_CAMPAIGN_FOLDERS = createActionTypes('GET_CAMPAIGN_FOLDERS');
export const GET_CAMPAIGN_VIEWS = createActionTypes('GET_CAMPAIGN_VIEWS');
export const GET_GDTV_VIEWS = createActionTypes('GET_GDTV_VIEWS');
export const PUT_CAMPAIGN_STATUS = createActionTypes('PUT_CAMPAIGN_STATUS');
export const POST_ADVERTS_STATUS = createActionTypes('POST_ADVERTS_STATUS');
export const POST_CAMPAIGN_CHANGE_STATUS = createActionTypes('POST_CAMPAIGN_CHANGE_STATUS');
