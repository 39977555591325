const GroupsEN = {
  Groups: {
    default_user: 'User',
    admin: 'Admin',
    invited: 'Invited',
    active: 'Active',
    blocked: 'Blocked',
    GROUP_OPTION: 'Groups',
    ADD_USER: 'Invite user',
    ADD: 'Invite',
    INVITE_USERS: 'Invite Users',
    CANCEL: 'Cancel',
    ADD_ONE_MORE: 'Invite more',
    FEEDBACK_ERROR: 'Error adding unit, try again shortly.',
    FEEDBACK_SUCCESS: 'Unit added! Follow the status updates.',
    GENERAL_CONFIG: 'General configuration',
    CREDIT_LINE: 'What is the credit line?',
    SHARED: 'Shared',
    PRIVATE: 'Private',
    BUDGET: 'What monthly budget will this unit have?',
    BUDGET_PLACEHOLDER: '$ 0,00',
    AD_RADIUS: 'What is the radius of the ads?',
    AD_RADIUS_PLACEHOLDER: '0 km',
    AUTO_APPROVE: 'Will this unit have automatic approval?',
    NO: 'No',
    YES: 'Yes',
    USER_EMAIL_LABEL: 'User email*',
    USER_EMAIL_PLACEHOLDER: 'Enter email to send invitation',
    USER_CONFIG: 'User settings',
    GROUP_NAME: 'Group name',
    GROUP_NAME_PLACEHOLDER: 'Enter unit name',
    FACEBOOK_ID: 'Facebook ID',
    FACEBOOK_ID_PLACEHOLDER: 'Enter Facebook page ID',
    ACCOUNT_ID_LABEL: 'Ad account ID',
    ACCOUNT_ID_PLACEHOLDER: 'Enter ad account ID',
    GROUP: 'Group',
    SELECT_GROUP: 'Select a group',
    PENDING: 'Pending',
    INVITED: 'Invited',
    ACTIVE: 'Active',
    BLOCKED: 'Blocked',
    CHANGE_IMAGE: 'Change images',
    REPLICATE_AUDIENCE: 'Replicate audience',
    INACTIVATE_UNIT: 'Inactivate unit',
    TRY_AGAIN: 'Try again',
    EDIT_AUDIENCE: 'Edit audience',
    INACTIVATE: 'Inactivate',
    CLEAR_FILTER: 'Clear selection',
    MISSING_IMAGE: 'Please insert one image!',
    AVAILABLE_AUDIENCE: 'Available audience',
    SELECT_AUDIENCE: 'Select audience',
    CHANGE_AUDIENCE_SUBTITLE: 'Choose what audience will be default to the ads of this unit.',
    CHANGE_TARGETING_FEEDBACK_ERROR:
      'Error trying to change the unit targeting,  try again shortly.',
    CHANGE_TARGETING_FEEDBACK_SUCCESS: 'Unit audience changed!',
    SINGLE_SELECTED_GROUP: 'selected group',
    SOME_SELECTED_GROUP: 'selected groups',
    CONFIRM: 'Confirm',
    DELETE: 'Delete',
    EDIT: 'Edit',
    CHANGE_COLOR_MESSAGE_SUCCESS: 'Name and color changed successfully.',
    CHANGE_COLOR_MESSAGE_ERROR: 'An error occurred while trying to change the group name or color.',
    MOVE_GROUP_MESSAGE_SUCCESS: 'Group moved successfully.',
    MOVE_GROUP_MESSAGE_ERROR: 'An error occurred while trying to move the group.',
    EDIT_GROUP_TITLE: 'Edit group',
    NEW_GROUP_NAME_PLACEHOLDER: 'Enter the group name',
    MOVE_GROUP_TITLE: 'Move group to:',
    IGNORE_BUDGET_WHEN_MOVE_GROUP_LABEL: 'Skip budget available in new group',
    SELECT_GROUP_COLOR_LABEL: 'Select a color for the group:',
    MOVE_GROUP_SUBTITLE: "(leave it blank if you don't want to move it.)",
    TOTALIZER_SUBTITLE: ' Results obtained in the selected period',
    MOVE: 'Move',
  },
};

const GroupsPT = {
  Groups: {
    default_user: 'Usuário',
    admin: 'Administrador',
    invited: 'Convidado',
    active: 'Ativo',
    blocked: 'Bloqueado',
    GROUP_OPTION: 'Grupos',
    ADD_USER: 'Convidar usuário',
    ADD: 'Convidar',
    CANCEL: 'Cancelar',
    ADD_ONE_MORE: 'Convidar mais',
    INVITE_USERS: 'Convidar Usuários',
    FEEDBACK_ERROR: 'Erro ao adicionar unidade, tente novamente em instantes.',
    FEEDBACK_SUCCESS: 'Unidade adicionada! Fique de olho nos status.',
    GENERAL_CONFIG: 'Configuração geral',
    CREDIT_LINE: 'Qual a linha de crédito?',
    SHARED: 'Compartilhada',
    PRIVATE: 'Privada',
    BUDGET: 'Qual orçamento mensal essa unidade terá?',
    BUDGET_PLACEHOLDER: 'R$ 0,00',
    AD_RADIUS: 'Qual raio de atuação dos anúncios?',
    AD_RADIUS_PLACEHOLDER: '0 km',
    AUTO_APPROVE: 'Essa unidade terá aprovação automática?',
    NO: 'Não',
    YES: 'Sim',
    USER_EMAIL_LABEL: 'Email do usuário*',
    USER_EMAIL_PLACEHOLDER: 'Digite o email para enviar o convite',
    USER_CONFIG: 'Configuração de usuário',
    GROUP_NAME: 'Nome do grupo',
    GROUP_NAME_PLACEHOLDER: 'Digite o nome da unidade',
    FACEBOOK_ID: 'Facebook ID',
    FACEBOOK_ID_PLACEHOLDER: 'Digite ID da página',
    ACCOUNT_ID_LABEL: 'Conta de anúncio ID',
    ACCOUNT_ID_PLACEHOLDER: 'Digite ID da conta',
    GROUP: 'Grupo',
    SELECT_GROUP: 'Selecione um grupo',
    PENDING: 'Pendente',
    INVITED: 'Convidados',
    ACTIVE: 'Ativo',
    BLOCKED: 'Bloqueados',
    CHANGE_IMAGE: 'Mudar imagens',
    REPLICATE_AUDIENCE: 'Replicar público',
    INACTIVATE_UNIT: 'Inativar unidade',
    TRY_AGAIN: 'Tentar novamente',
    EDIT_AUDIENCE: 'Alterar público',
    INACTIVATE: 'Inativar',
    CLEAR_FILTER: 'Limpar seleção',
    MISSING_IMAGE: 'Por favor insira uma imagem!',
    CHANGE: 'Alterar',
    AVAILABLE_AUDIENCE: 'Públicos disponíveis',
    SELECT_AUDIENCE: 'Selecione o público',
    CHANGE_AUDIENCE_SUBTITLE:
      'Escolha qual será o público automático dos anúncios nestas unidades.',
    CHANGE_TARGETING_FEEDBACK_ERROR:
      'Erro ao alterar público da unidade, tente novamente em instantes.',
    CHANGE_TARGETING_FEEDBACK_SUCCESS: 'Público da unidade alterado!',
    SINGLE_SELECTED_GROUP: 'grupo selecionado',
    SOME_SELECTED_GROUP: 'grupos selecionados',
    CONFIRM: 'Confirmar',
    DELETE: 'Excluir',
    EDIT: 'Editar',
    CHANGE_COLOR_MESSAGE_SUCCESS: 'Nome e cor alterada com sucesso.',
    CHANGE_COLOR_MESSAGE_ERROR: 'Ocorreu um erro ao tentar alterar o nome ou a cor do grupo.',
    MOVE_GROUP_MESSAGE_SUCCESS: 'Grupo movido com sucesso.',
    MOVE_GROUP_MESSAGE_ERROR: 'Ocorreu um erro ao tentar mover o grupo.',
    EDIT_GROUP_TITLE: 'Editar grupo',
    NEW_GROUP_NAME_PLACEHOLDER: 'Digite o nome do grupo',
    MOVE_GROUP_TITLE: 'Mover grupo para:',
    IGNORE_BUDGET_WHEN_MOVE_GROUP_LABEL: 'Ignorar orçamento disponível no novo grupo',
    SELECT_GROUP_COLOR_LABEL: 'Selecione uma cor para o grupo:',
    MOVE_GROUP_SUBTITLE: '(deixe em branco se não deseja muda-lo de lugar)',
    TOTALIZER_SUBTITLE: ' Resultados obtidos no período selecionado',
    MOVE: 'Mover',
  },
};

export { GroupsEN, GroupsPT };
