import React from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as S from './styles';

export const downloadFile = (donwloadUrl: string) => {
  axios({
    url: donwloadUrl,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const fileNameSplit = donwloadUrl.split('/');
    const fileName = fileNameSplit[fileNameSplit.length - 1];
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};

type DownloadButtonProps = {
  donwloadUrl: string;
};

const DownloadButton: React.FC<DownloadButtonProps> = ({ donwloadUrl }) => {
  return (
    <S.LinkDownload title="Download">
      <FontAwesomeIcon icon={['fal', 'download']} onClick={() => downloadFile(donwloadUrl)} />
    </S.LinkDownload>
  );
};

export default DownloadButton;
