import ButtonWithMixPanel from 'components/MixPanel/Reborn/Button';
import styled, { css } from 'styled-components';

export const CustomModalContent = styled('div')`
  ${({ theme }) => css`
    padding: ${theme.spacing.px60} 0 ${theme.spacing.px30};
  `}
`;

export const SaveButton = styled(ButtonWithMixPanel)`
  display: flex;
  justify-content: flex-end;
`;

export const Croppers = styled('div')`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    @media (max-width: ${theme.breakpoint.xl}) {
      overflow-x: scroll;
    }

    @media (max-width: ${theme.breakpoint.sm}) {
      display: block;

      & > div:nth-child(2) {
        & > div:nth-child(1) {
          /* Aqui precisa ser hardcoded a larg e alt pois o component só exibe a imagem nessas proporções. Precisa-se testar se a imagem sobe corretamente */
          width: 250px !important;
          height: 109px !important;
          margin: 10px 0;
        }
      }
    }
  `}
`;
