import { useEffect, useState, useCallback } from 'react';
import { CompanyService } from '@services';

const ORDENATION_TYPES = {
  DATETIME: 'dateTime',
  LAST_STATUS: 'lastStatus',
  NEW_STATUS: 'newStatus',
  USER_EMAIL: 'userEmail',
};

const orderDefault = {
  type: null,
  value: null,
};

export const TENANT_STATUS_CODES = {
  FREETRIAL: 'free_trial',
  POC: 'poc',
  FULL: 'full',
  INACTIVE: 'inactive',
};

const formatedStatus = {
  free_trial: 'Free trial - Cliente em teste',
  poc: 'POC - Cliente em teste pago',
  full: 'Full - Contrato completo',
  inactive: 'Churn - Cliente desativado',
};

const useCompanyHistory = (returnOnlyStatusUpdates = false) => {
  const [loading, setLoading] = useState(false);
  const [orderedHistory, setOrderedHistory] = useState(null);
  const [history, setHistory] = useState(null);
  const [order, setOrder] = useState(orderDefault);


  const checkOrdering = (orderType) => {
    if (order.type !== orderType) {
      setOrder({
        value: 'ASC',
        type: orderType,
      });
    } else if (order.type === orderType && order.value === 'ASC') {
      setOrder({
        ...order,
        value: 'DESC',
      });
    } else {
      setOrder({
        ...order,
        value: 'ASC',
      });
    }
  };

  const setInternalOrder = {
    dateTime() {
      checkOrdering(ORDENATION_TYPES.DATETIME);
    },
    newStatus() {
      checkOrdering(ORDENATION_TYPES.NEW_STATUS);
    },
    userEmail() {
      checkOrdering(ORDENATION_TYPES.USER_EMAIL);
    },
  };

  const verifyIsValidLog = (lastStatus, status) =>
    (returnOnlyStatusUpdates && lastStatus !== status) || !returnOnlyStatusUpdates;

  const normalizeData = (oldData) => {
    const newData = [];
    let lastStatus = '';

    oldData.forEach((item, index) => {
      if (index > 0) {
        lastStatus = formatedStatus[oldData[index - 1].status];
      }

      if (verifyIsValidLog(lastStatus, formatedStatus[item.status])) {
        newData.push({
          ...item,
          status: formatedStatus[item.status],
          last_status: lastStatus,
          date: new Date(item.date).toLocaleDateString(),
        });
      }
    });

    return newData;
  };

  const executeOrdering = useCallback(
    () => ({
      dateTime() {
        const oldHistory = [...history];

        if (order.value === 'ASC') {
          oldHistory.sort((h1, h2) => new Date(h1.date) - new Date(h2.date));
        } else {
          oldHistory.sort((h1, h2) => new Date(h2.date) - new Date(h1.date));
        }

        setOrderedHistory(oldHistory);
      },
      newStatus() {
        const oldHistory = [...history];

        if (order.value === 'ASC') {
          oldHistory.sort((h1, h2) => {
            if (h1.status.toLowerCase() < h2.status.toLowerCase()) return -1;
            if (h2.status.toLowerCase() < h1.status.toLowerCase()) return 1;

            return 0;
          });
        } else {
          oldHistory.sort((h1, h2) => {
            if (h1.status.toLowerCase() > h2.status.toLowerCase()) return -1;
            if (h2.status.toLowerCase() > h1.status.toLowerCase()) return 1;

            return 0;
          });
        }

        setOrderedHistory(oldHistory);
      },
      userEmail() {
        const oldHistory = [...history];

        if (order.value === 'ASC') {
          oldHistory.sort((h1, h2) => {
            if (h1.user_email.toLowerCase() < h2.user_email.toLowerCase()) return -1;
            if (h2.user_email.toLowerCase() < h1.user_email.toLowerCase()) return 1;

            return 0;
          });
        } else {
          oldHistory.sort((h1, h2) => {
            if (h1.user_email.toLowerCase() > h2.user_email.toLowerCase()) return -1;
            if (h2.user_email.toLowerCase() > h1.user_email.toLowerCase()) return 1;

            return 0;
          });
        }

        setOrderedHistory(oldHistory);
      },
    }),
    [order, history]
  );

  const orderBy = (column) => {
    setInternalOrder[column]();
  };

  const setInitialStateHistory = (data) => {
    setHistory(normalizeData(data));
    setOrderedHistory(normalizeData(data));
  };

  useEffect(() => {
    async function loadCompanyStatusHistory() {
      setLoading(true);

      const response = await CompanyService.getCompanyStatusHistory();

      const { is_success: isSuccess, payload } = response.data;

      if (isSuccess) {
        setInitialStateHistory(payload);
      }

      setLoading(false);
    }

    loadCompanyStatusHistory();
  }, []);

  useEffect(() => {
    if (order.type !== null) executeOrdering()[order.type]();
  }, [order, executeOrdering]);

  return {
    history: orderedHistory,
    orderBy,
    orderTypes: ORDENATION_TYPES,
    order,
    loading,
  };
};

export default useCompanyHistory;
