import UploadService from '../services/upload';

class fileUpload {
  constructor() {
    this.hostUrl = null;
    this.sasUrl = null;
    this.blockIdPrefix = 'block-';
    this.reader = null;
    this.numberOfBlocks = 1;
    this.currentFilePointer = 0;
    this.totalBytesRemaining = 0;
    this.blockIds = [];
    this.bytesUploaded = 0;
    this.maxBlockSizeBytes = 256;
    this.maxBlockSize = 256 * 1024;
    this.file = null;
    this.submitUri = null;
    this.fileUrl = null;
    this.percentComplete = 0;
    this.status = 'NOTPREPARED';
    this.afterPrepareUploadCallback = null;
    this.progressChangeCallback = null;
    this.commitUploadSuccessCallback = null;
    this.afterCommitUploadSuccessCallback = null;
    this.commitUploadErrorCallback = null;
    this.onSingleUploadComplete = null;
    this.biggerSizeFile = false;

    this.UploadImage = function (file, sasUrl, kickassId, biggerSizeFile) {
      if (biggerSizeFile) {
        this.biggerSizeFile = true;
      }

      this.kickassId = kickassId;
      this.blockIds = [];
      this.sasUrl = sasUrl;
      this.file = file;
      this.currentFilePointer = 0;
      this.totalBytesRemaining = 0;
      const hash = Date.now() + makeid() + file.name.substr(file.name.lastIndexOf('.'));
      this.submitUri = this.getSubmitUri(sasUrl, hash);
      this.fileUrl = this.getFileUrl(sasUrl, hash);
      this.setBlocksInfo();
      if (this.afterPrepareUploadCallback) this.afterPrepareUploadCallback();
      this.status = 'PREPARED';
      this.startUpload();
    };
    this.getSubmitUri = function (sasUrl, fileName) {
      const baseUrl = sasUrl;
      const indexOfQueryStart = baseUrl.indexOf('?');
      return `${baseUrl.substring(0, indexOfQueryStart)}/${fileName}${baseUrl.substring(
        indexOfQueryStart
      )}`;
    };
    this.getFileUrl = function (sasUrl, fileName) {
      const baseUrl = sasUrl;
      const indexOfQueryStart = baseUrl.indexOf('?');
      return `${baseUrl.substring(0, indexOfQueryStart)}/${fileName}`;
    };
    function makeid() {
      let text = '';
      const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (let i = 0; i < 5; i += 1) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      return text;
    }

    this.setBlocksInfo = function () {
      const fileSize = this.file.size;
      if (fileSize < this.maxBlockSize) {
        this.maxBlockSize = fileSize;
      }
      this.totalBytesRemaining = fileSize;
      if (fileSize % this.maxBlockSize === 0) {
        this.numberOfBlocks = fileSize / this.maxBlockSize;
      } else {
        this.numberOfBlocks = parseInt(fileSize / this.maxBlockSize, 10) + 1;
      }
    };
    this.resumeUpload = function () {
      this.status = 'UPLOADING';
      this.upload();
    };
    this.pauseUpload = function () {
      this.status = 'PAUSED';
    };
    this.cancelUpload = function () {
      this.status = 'CANCELLED';
    };
    this.startUpload = function () {
      this.bytesUploaded = 0;
      this.maxBlockSizeBytes = 256;
      this.maxBlockSize = this.biggerSizeFile ? 1024 * 1024 : 256 * 1024;
      this.status = 'UPLOADING';
      this.blockIds = [];
      const ref = this;
      this.reader = new FileReader();
      this.reader.onloadend = function (evt) {
        if (evt.target.readyState === FileReader.DONE) {
          ref.uploadBlock(evt.target.result);
        }
      };
      this.upload();
    };

    this.upload = function () {
      if (this.status === 'PAUSED' || this.status === 'CANCELLED') {
        return;
      }
      if (this.totalBytesRemaining > 0) {
        this.status = 'UPLOADING';
        this.readNextBlock();
      } else {
        this.status = 'COMMITING';
        this.commitBlockList();
      }
    };

    this.readNextBlock = function () {
      const fileContent = this.file.slice(
        this.currentFilePointer,
        this.currentFilePointer + this.maxBlockSize
      );
      const blockId = this.blockIdPrefix + this.pad(this.blockIds.length, 6);
      this.blockIds.push(btoa(blockId));
      this.reader.readAsArrayBuffer(fileContent);
      this.currentFilePointer += this.maxBlockSize;
      this.totalBytesRemaining -= this.maxBlockSize;
      if (this.totalBytesRemaining < this.maxBlockSize) {
        this.maxBlockSize = this.totalBytesRemaining;
      }
    };

    this.uploadBlock = (blockData) => {
      const uri = `${this.submitUri}&comp=block&blockid=${this.blockIds[this.blockIds.length - 1]}`;
      const requestData = new Uint8Array(blockData);
      const ref = this;

      UploadService.uploadImage(uri, requestData)
        .then(() => {
          ref.bytesUploaded += requestData.length;
          ref.percentComplete = Math.floor(
            (parseFloat(ref.bytesUploaded) / parseFloat(ref.file.size)) * 100
          );
          if (ref.progressChangeCallback) {
            ref.progressChangeCallback(ref.kickassId, ref.percentComplete);
          }
          ref.upload();
        })
        .catch(() => {
          ref.status = 'ERROR';
        });
    };

    this.commitBlockList = function () {
      const uri = `${this.submitUri}&comp=blocklist`;
      let requestBody = '<?xml version="1.0" encoding="utf-8"?><BlockList>';
      for (let i = 0; i < this.blockIds.length; i += 1) {
        requestBody += `<Latest>${this.blockIds[i]}</Latest>`;
      }
      requestBody += '</BlockList>';
      const ref = this;

      UploadService.uploadImage(uri, requestBody)
        .then((data) => {
          if (ref.commitUploadSuccessCallback) {
            ref.commitUploadSuccessCallback(ref.kickassId, data.request.responseURL.split('?')[0]);
          }
          ref.status = 'UPLOADED';
        })
        .catch((xhr, desc, newErr) => {
          if (ref.commitUploadErrorCallback) ref.commitUploadErrorCallback(desc, newErr);
          ref.status = 'ERROR';
          ref.err = newErr;
        });
    };

    this.pad = function (number, length) {
      let str = `${number}`;
      while (str.length < length) {
        str = `0${str}`;
      }
      return str;
    };
  }
}

export default fileUpload;
