// Module
import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Image from '@components/Image';

// Images
import images from '@images';

const ButtonProfileWrapper = styled('button')`
  ${({ theme, open }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    background-color: ${theme.colors.white};
    padding: ${theme.spacing.none};
    cursor: pointer;
    outline: none;
    position: relative;
    overflow: hidden;
    ${open ? 'z-index: 1002;' : ''}
  `}
`;

const UserProfile = styled('div')``;

const ImageWrapper = styled('figure')`
  ${({ theme, iconSize }) => `
        margin: ${theme.spacing.none};
        display: flex;
        justify-content: center;
        width: ${theme.spacing[iconSize]};
        height: ${theme.spacing[iconSize]};
    `}
`;

const FirstLetterUser = styled('div')`
  ${({ theme, iconSize }) => `
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${theme.spacing[iconSize]};
        height: ${theme.spacing[iconSize]};
        font-size: 26px;
        color: white;
        background-color: ${theme.colors.blue2};
        border-radius: ${theme.rounded.full};
    `}
`;

const ButtonProfile = ({ onClick, iconSize, isOpen, ref, userName, userLastName, avatarUrl }) => (
  <ButtonProfileWrapper onClick={onClick} ref={ref} open={isOpen}>
    <UserProfile>
      <ImageWrapper iconSize={iconSize}>
        {avatarUrl ? (
          <Image src={avatarUrl} alt={`${userName} ${userLastName}`} id="buttonProfile" />
        ) : (
          <FirstLetterUser iconSize={iconSize} id="buttonProfile">
            {userName ? userName.substring(0, 1).toUpperCase() : '?'}
          </FirstLetterUser>
        )}
      </ImageWrapper>
    </UserProfile>
  </ButtonProfileWrapper>
);

ButtonProfile.propTypes = {
  onClick: PropTypes.func,
  userName: PropTypes.string,
  userLastName: PropTypes.string,
  avatarUrl: PropTypes.string,
};

ButtonProfile.defaultProps = {
  onClick: () => {},
  userName: '',
  userLastName: '',
  avatarUrl: images.avatarDefault,
  ref: null,
};

export default ButtonProfile;
