import useSWR from 'swr';
import { bffFetcher, fetcher } from 'utils/api';
import { checkIfIsTradeView, isTenantBornlogic, checkIfIsTradeExperiment } from 'utils/helpers';

type PeriodType = {
  since?: string,
  until?: string
}

type DataType = {
  payload?: Array<any>,
  is_success?: boolean,
  value?: string
}

const handleResponse = (data:DataType | undefined, error: Error | undefined) => {
  return {
    data: data ? data : null,
    isLoading: !error && !data,
    isError: error ? error.message : error,
  };
};

function useGetLocations() {
  const { data, error } = useSWR<DataType, Error>(
    checkIfIsTradeView() && !isTenantBornlogic() ? '/api/v1/trends/location/' : null,
    bffFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  return handleResponse(data, error);
}

function useGetInvestmentsTips(period: PeriodType) {
  const { data, error } = useSWR<DataType, Error>(
    period?.since && (checkIfIsTradeView() || checkIfIsTradeExperiment()) && !isTenantBornlogic()
      ? `/api/v1/trends/insights/investments?since=${period.since}&until=${period.until}`
      : null,
    bffFetcher,
    { revalidateOnFocus: false }
  );

  return handleResponse(data, error);
}

function useGetInsights(period: PeriodType) {
  const { data, error } = useSWR<DataType, Error>(
    period.since && (checkIfIsTradeView() || checkIfIsTradeExperiment()) && !isTenantBornlogic()
      ? `/api/v1/trends/insights/Categories?since=${period.since}&until=${period.until}`
      : null,
    bffFetcher,
    { revalidateOnFocus: false }
  );

  return handleResponse(data, error);
}

function useGetInvestmentSummary(period: PeriodType) {
  const { data, error } = useSWR<DataType, Error>(
    period?.since && checkIfIsTradeView() && !isTenantBornlogic()
      ? `/api/v1/trends/insights/investments/summary/Location?since=${period.since}&until=${period.until}`
      : null,
    bffFetcher,
    { revalidateOnFocus: false }
  );

  return handleResponse(data, error);
}

function useGetCoverage(period: PeriodType) {
  const { data, error } = useSWR<DataType, Error>(
    period.since && checkIfIsTradeView() && !isTenantBornlogic()
      ? `/api/v1/trends/coverage?since=${period.since}&until=${period.until}`
      : null,
    bffFetcher,
    { revalidateOnFocus: false }
  );
  return handleResponse(data, error);
}

function useGetCoverageHistory(period: PeriodType) {
  const { data, error } = useSWR<DataType, Error>(
    period.since && checkIfIsTradeView() && isTenantBornlogic()
      ? `/api/v1/trends/coverage/history?since=${period.since}&until=${period.until}&force=true`
      : null,
    bffFetcher,
    { revalidateOnFocus: false }
  );
  return handleResponse(data, error);
}

function useGetTradeChampionsExp() {
  const { data, error, mutate } = useSWR<DataType, Error>(`/settings/1.0/keyvalue/trade_champions_exp`, fetcher, {
    revalidateOnFocus: false,
  });

  return {
    reviewData: data?.value ? JSON.parse(data.value) : null,
    isLoading: !error && data === undefined,
    isError: error ? error.message : error,
    reviewMutate: mutate,
  };
}

export {
  useGetLocations,
  useGetInvestmentsTips,
  useGetInsights,
  useGetInvestmentSummary,
  useGetCoverage,
  useGetCoverageHistory,
  useGetTradeChampionsExp,
};
