import React from 'react';
import PropTypes from 'prop-types';

import IconAnimated from '../IconAnimated/IconAnimated';
import * as S from './styles';

const TextareaDefault = ({
  autoResize,
  bgColor,
  block,
  data,
  disabled,
  errors,
  fieldLoading,
  fieldValidation,
  fontSize,
  icon,
  id,
  innerRef,
  mobile,
  name,
  onBlur,
  onChange,
  onFocus,
  onKeyUp,
  searchAbsolute,
  size,
  sizeWidth,
  type,
  typed,
  value,
  withAutoComplete,
  withoutBorder,
  isModal,
  maxLenght,
  ...rest
}) => {
  return (
    <S.InputDefaultWrapper sizeWidth={sizeWidth}>
      <S.InputDefaultContent searchAbsolute={searchAbsolute} mobile={mobile}>
        {!!icon?.length && <S.CustomIcon icon={icon} spin={icon[1] === 'circle-notch'} />}
        <S.InputDefaultStyled
          bgColor={bgColor}
          icon={icon && icon.length}
          autoResize={autoResize}
          withAutoComplete={withAutoComplete}
          type={type}
          id={id}
          maxLength={maxLenght}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          optionsAutoComplete={data}
          disabled={disabled}
          block={block}
          size={size}
          fontSize={fontSize}
          ref={innerRef}
          fieldValidation={fieldValidation}
          typed={typed}
          withoutBorder={withoutBorder}
          isModal={isModal}
          {...rest}
        />
        {!!fieldValidation &&
          ((!!errors && errors.length >= 1) || value.length === 0 ? (
            <S.CustomValidation color={fieldLoading ? 'grey2' : 'green3'}>
              <S.CustomValidationIcon color="red3" icon={['fal', 'times']} />
            </S.CustomValidation>
          ) : (
            <S.CustomValidation>
              {fieldLoading ? (
                <S.CustomValidationIcon color={'grey2'} icon={['fal', 'circle-notch']} spin />
              ) : (
                <IconAnimated />
              )}
            </S.CustomValidation>
          ))}
      </S.InputDefaultContent>
      <S.MessageValidation>{errors}</S.MessageValidation>
    </S.InputDefaultWrapper>
  );
};

TextareaDefault.propTypes = {
  icon: PropTypes.array,
  autoResize: PropTypes.bool,
  withAutoComplete: PropTypes.bool,
  type: PropTypes.string,
  id: PropTypes.node,
  name: PropTypes.string,
  value: PropTypes.string,
  searchAbsolute: PropTypes.bool,
  size: PropTypes.string,
  fontSize: PropTypes.string,
  mobile: PropTypes.bool,
  withoutBorder: PropTypes.bool,
  isModal: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyUp: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

TextareaDefault.defaultProps = {
  icon: [],
  autoResize: false,
  withAutoComplete: false,
  type: 'text',
  id: 'inputDefault',
  name: 'inputDefault',
  value: undefined,
  searchAbsolute: false,
  size: 'px15',
  fontSize: 'px14',
  mobile: false,
  withoutBorder: false,
  isModal: false,
  onBlur: () => {},
  onFocus: () => {},
  onKeyUp: () => {},
  disabled: false,
};

export default TextareaDefault;
