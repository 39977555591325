import React, { useState, createContext, useContext } from 'react';

const INITIAL_CONTEXT = {
  trigger: false,
  setTrigger: () => {},
};

export const SurveyContext = createContext(INITIAL_CONTEXT);

export const SurveyProvider = ({ children }) => {
  const [trigger, setTrigger] = useState('manual');

  return (
    <SurveyContext.Provider value={{ trigger, setTrigger }}>{children}</SurveyContext.Provider>
  );
};

export const useSurvey = () => {
  const context = useContext(SurveyContext);

  if (!context) throw new Error('Expected to be wrapped in a Survey');

  return context;
};
