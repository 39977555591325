import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import * as S from './styles';


const ButtonClose = ({
  onClick, showButton, styleguide, iconClose,
}) => (
  <S.ButtonStyle
    onClick={onClick}
    showButton={showButton}
    styleguide={styleguide}
    id="buttonClose"
  >
    <FontAwesomeIcon icon={iconClose || faTimes} data-testid="svg" />
  </S.ButtonStyle>
);

ButtonClose.propTypes = {
  onClick: PropTypes.func,
  showButton: PropTypes.bool,
  styleguide: PropTypes.bool,
  iconClose: PropTypes.array,
};

ButtonClose.defaultProps = {
  onClick: () => {},
  showButton: true,
  styleguide: false,
};

export default ButtonClose;
