import styled from 'styled-components';

export const Wrapper = styled('div')`
  display: flex;
  justify-content: center;
  width: 100px;
  height: 40px;
  align-self: center;
  margin-top: 50px;
  margin-bottom: 20px;
`;

export const CardLoginWrapper = styled('div')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${({ theme: { colors, spacing, breakpoint, rounded } }) => `
    background: ${colors.white};
    box-shadow: ${spacing.none} ${spacing.px10} ${spacing.px30} ${colors.grey2};
    border-radius: ${rounded.px10};
    margin: ${spacing.px30} ${spacing.none} ${spacing.px30};
    width: 500px;
    height: 350px;
    max-width: ${spacing.full};
    box-sizing: border-box;
    padding: ${spacing.px60};
    @media screen and (max-width: ${breakpoint.sm}) {
        padding: ${spacing.px60} ${spacing.px30};
    }
`}
`;

export const LoginWrapper = styled('div')`
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  ${({ theme }) => `
      margin-bottom: ${theme.spacing.xg};
  `}

  ${(props) =>
    props.showCreatePassword &&
    `
    @media (max-width: 768px) {
      & div > div {
        padding: 0;
      }
    }
  `}
`;

export const LoginWrapperCard = styled('div')`
  display: flex;
  justify-content: center;
`;

export const LoginWrapperLanguage = styled('figure')`
  margin: ${({ theme }) => theme.spacing.none} auto;
  max-width: 500px;
`;

export const ImageWrapper = styled('figure')`
  ${({ theme: { spacing } }) => `
    display: flex;
    justify-content: center;
    max-width: ${spacing.full};
`}
`;

export const LegalWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
`;

export const PrivacyButton = styled.button`
  display: flex;
  flex-flow: row nowrap;
  margin-top: 8px;
  border: none;
  background-color: #f8f8f8;
  color: #2328eb;
  font-weight: 600;

  :hover {
    cursor: pointer;
  }
`;
