import React, { useEffect, useMemo, useState } from 'react';
import { useTranslate } from 'react-translate';
import { useRouteMatch } from 'react-router';
import { default as ModalDefault } from '@components/Modal';
import TextareaDefault from '@components/TextareaDefault/TextareaDefault';
import CustomModal from '@components/CustomModal/CustomModal';
import { RENAME_GROUP } from '@redux/report/types';
import { GET_UNITS } from '@redux/settings/types';
import * as S from './styles';
import InstagramService from '@services/instagram';
import useShowToast from '@utils/hooks/Common/useShowToast';
import { useDispatch, useSelector } from 'react-redux';

function EditModalSettings(props) {
  const {
    name,
    source_id,
    isOpenChangeEditModal,
    toggleChangeEditModal,
    instagramAccounts,
    onRenameSuccess,
  } = props;

  const [newName, setNewName] = useState(name);
  const [renameSelected, setRenameSelected] = useState(true);
  const [changeIntegrationSelected, setChangeIntegrationSelected] = useState(false);
  const [loadingLink, setLoadingLink] = useState(false);
  const [optionsAccountInstagram, setOptionsAccountInstagram] = useState([]);
  const [valueOption, setValueOption] = useState([]);

  const showToast = useShowToast();

  const t = useTranslate('ManageGroups');

  const dispatch = useDispatch();
  const match = useRouteMatch();
  const groupCode = match.params.code || 'root';

  const authenticationReducer = useSelector((state) => state.authenticationReducer);
  const { dataUser } = authenticationReducer;

  useEffect(() => {
    InstagramService.getListAccountsIG().then((response) => {
      setOptionsAccountInstagram(response.data.data);
    });
  }, []);

  const accountOptions = useMemo(() => {
    const options = optionsAccountInstagram?.map((option) => {
      return { value: option.id, label: option.name };
    });

    return options;
  }, [optionsAccountInstagram]);

  const defaultValue = useMemo(() => {
    const value = instagramAccounts?.map((item) => {
      return {
        value: item?.instagram_id,
        label: item?.name,
      };
    });

    return value;
  }, [instagramAccounts]);

  const filterLinkInstagram = useMemo(() => {
    if (valueOption?.length && defaultValue?.length) {
      const value = valueOption.filter((account) => !defaultValue.includes(account));

      return value;
    }
    return valueOption;
  }, [valueOption, defaultValue]);

  const deleteAccount = (evt) => {
    if (evt.action === 'remove-value') {
      const value = defaultValue?.includes(evt.removedValue);

      if (value) {
        InstagramService.deleteAccountIG(evt.removedValue.value, source_id).then((response) => {
          if (response.status === 200) {
            showToast(t('UNLIKED_INTEGRATION'), 'success');
          } else if (response.data.error === 'MISSING_UNIT_ID') {
            showToast(t('MISSING_UNIT_ID'), 'error');
          } else if (response.data.error === 'MISSING_INSTAGRAM_ACCOUNT_ID') {
            showToast(t('MISSING_INSTAGRAM_ACCOUNT_ID'), 'error');
          } else if (response.data.error === 'FAILED_TO_FETCH_MARKET_NETWORK_CREDENTIALS') {
            showToast(t('FAILED_CREDENTIALS'), 'error');
          } else if (
            response.data.error === 'FAILED_TO_FETCH_MARKET_NETWORK_UNIT_ASSET_GROUP_MAP'
          ) {
            showToast(t('FAILED_UNIT_ASSET_GROUP_MAP'), 'error');
          } else if (response.data.error === 'MISSING_MARKET_NETWORK_UNIT_MAP') {
            showToast(t('MISSING_UNIT_MAP'), 'error');
          } else if (response.data.error === 'FAILED_TO_UNMAP_INSTAGRAM_BUSINESS_ACCOUNTS') {
            showToast(t('FAILED_TO_UNMAP'), 'error');
          }
        });
      }
    }
  };

  const postAccount = async () => {
    if (filterLinkInstagram) {
      await Promise.all(
        filterLinkInstagram.map((account) =>
          InstagramService.postAccountsIG(account.value, source_id).then((response) => {
            if (response.status === 200) {
              showToast(t('SUCCESS_INTEGRATION'), 'success');
            } else if (response.data.error === 'MISSING_UNIT_ID') {
              showToast(t('MISSING_UNIT_ID'), 'error');
            } else if (response.data.error === 'MISSING_INSTAGRAM_ACCOUNT_ID') {
              showToast(t('MISSING_INSTAGRAM_ACCOUNT_ID'), 'error');
            } else if (response.data.error === 'MISSING_BUSINESS_ID ') {
              showToast(t('MISSING_BUSINESS_ID'), 'error');
            } else if (response.data.error === 'FAILED_TO_FETCH_MARKET_NETWORK_CREDENTIALS') {
              showToast(t('FAILED_CREDENTIALS'), 'error');
            } else if (
              response.data.error === 'FAILED_TO_FETCH_MARKET_NETWORK_UNIT_ASSET_GROUP_MAP'
            ) {
              showToast(t('FAILED_UNIT_ASSET_GROUP_MAP'), 'error');
            } else if (response.data.error === 'FAILED_TO_FETCH_FACEBOOK_BUSINESS_USERS') {
              showToast(t('FAILED_TO_FETCH_FACEBOOK_BUSINESS_USERS'), 'error');
            } else if (response.data.error === 'MISSING_MARKET_NETWORK_UNIT_MAP') {
              showToast(t('MISSING_UNIT_MAP'), 'error');
            } else if (response.data.error === 'FAILED_TO_UNMAP_INSTAGRAM_BUSINESS_ACCOUNTS') {
              showToast(t('FAILED_TO_MAP'), 'error');
            } else if (
              response.data.error === 'FAILED_TO_MAP_INSTAGRAM_BUSINESS_ACCOUNTS_TO_USERS'
            ) {
              showToast(t('FAILED_TO_MAP_INSTAGRAM_BUSINESS_ACCOUNTS_TO_USERS'), 'error');
            } else if (response.data.error === 'INVALID_INSTAGRAM_BUSINESS_ACCOUNT'
            ) {
              showToast(t('INVALID_INSTAGRAM_BUSINESS_ACCOUNT'), 'error')
            }

            dispatch({
              type: GET_UNITS.RESET,
            });

            dispatch({
              type: GET_UNITS.REQUEST,
              version: '1.2',
              source_id: groupCode,
              page_hash: '',
            });

            setLoadingLink(false);
            toggleChangeEditModal();
          })
        )
      );
    }
  };

  const changeTab = () => {
    setRenameSelected(!renameSelected);
    setChangeIntegrationSelected(!changeIntegrationSelected);
  };

  const handleNewName = ({ target: { value } }) => {
    setNewName(value.substring(0, 1) === ' ' ? value.trim() : value);
  };

  const rename = () => {
    dispatch({
      type: RENAME_GROUP.REQUEST,
      version: '1.1',
      sourceId: source_id,
      body: { name: newName },
      onSuccess: onRenameSuccess,
      successMessage: t('RENAME_SUCCESS'),
      errorMessage: t('RENAME_ERROR'),
    });
  };

  const onConfirm = () => {
    if (changeIntegrationSelected) {
      postAccount();
      setLoadingLink(true);
    }

    if (renameSelected) {
      if (name !== newName) {
        rename();
      }
    }
  };

  const onKeyDown = ({ key }) => {
    if (key === 'Enter') {
      rename();
      edit();
    } else if (key === 'Escape') {
      toggleChangeEditModal();
    }
  };

  useEffect(() => {
    setNewName(name);
  }, [name]);

  return (
    <CustomModal visible={isOpenChangeEditModal}>
      <S.CustomModalContent>
        <ModalDefault
          onClickAway={toggleChangeEditModal}
          visible
          effect="fadeInDown"
          width={530}
          title={name && t('EDIT_UNIT', { name: name })}
          cancelButton={toggleChangeEditModal}
          cancelButtonText={t('CANCEL')}
          confirmButton={onConfirm}
          confirmButtonText={t('CONFIRM')}
          buttonConfirmDisabled={!newName}
          mobileFullScreen
          iconClose={['fal', 'times']}
        >
          <S.TabSelector>
            <S.TabItem selected={renameSelected} onClick={() => changeTab()}>
              {t('RENAME')}
            </S.TabItem>

            {dataUser.role === 'admin' && (
              <S.TabItem selected={changeIntegrationSelected} onClick={() => changeTab()}>
                {t('INTEGRATIONS')}
              </S.TabItem>
            )}
          </S.TabSelector>
          <S.SectionWrapper show={renameSelected}>
            <S.InputDescription>
              <TextareaDefault
                autoFocus
                placeholder={t('RENAME_MODAL_PLACEHOLDER')}
                onChange={handleNewName}
                onKeyDown={onKeyDown}
                fontSize="px14"
                value={newName}
                withoutBorder
                isModal
              />
            </S.InputDescription>
          </S.SectionWrapper>

          <S.SectionWrapper show={changeIntegrationSelected}>
            <S.InputDescription>
              <S.IntegrationWrapper>
                {loadingLink && filterLinkInstagram.length > 0 ? (
                  <S.Loading>{t('MAKING_CHANGES')}</S.Loading>
                ) : (
                  <>
                    <label>{t('PROFILE_NAME')} </label>
                    <S.CustomSelect
                      placeholder={t('SELECT')}
                      defaultValue={defaultValue}
                      isClearable={false}
                      options={accountOptions}
                      onChange={(value, evt) => {
                        setValueOption(value), deleteAccount(evt);
                      }}
                      maxMenuHeight={100}
                      components={{ IndicatorSeparator: () => null }}
                      isMulti
                    />
                  </>
                )}
              </S.IntegrationWrapper>
            </S.InputDescription>
          </S.SectionWrapper>
        </ModalDefault>
      </S.CustomModalContent>
    </CustomModal>
  );
}

export default EditModalSettings;
