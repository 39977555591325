import React from 'react';
import type { Ref } from 'react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import IconAnimated from '../IconAnimated/IconAnimated';
import * as S from './styles';
import type { IColor, IFontSize } from 'types/styled';

interface InputDefaultProps {
  autoResize?: boolean;
  fontSize?: keyof IFontSize;
  icon?: IconProp;
  id?: string;
  mobile?: boolean;
  name: string;
  searchAbsolute?: boolean;
  size?: string;
  type?: string;
  value?: string;
  withAutoComplete?: boolean;
  withoutBorder?: boolean;
  bgColor?: keyof IColor;
  block?: boolean;
  data?: string;
  disabled?: boolean;
  errors?: string;
  fieldLoading?: boolean;
  fieldValidation?: boolean;
  innerRef?: Ref<HTMLInputElement>;
  onBlur?: () => void;
  onChange?: () => void;
  onFocus?: () => void;
  onKeyUp?: () => void;
  sizeWidth?: string;
  typed?: string;
}

function InputDefault({
  autoResize = false,
  fontSize = 'px14',
  id = 'inputDefault',
  mobile = false,
  name = 'inputDefault',
  searchAbsolute = false,
  type = 'text',
  value = undefined,
  withAutoComplete = false,
  withoutBorder = false,
  disabled = false,
  size,
  icon,
  bgColor,
  block,
  data,
  errors,
  fieldLoading,
  fieldValidation,
  innerRef,
  onBlur,
  onChange,
  onFocus,
  onKeyUp,
  sizeWidth,
  typed,
  ...rest
}: InputDefaultProps) {
  return (
    <S.InputDefaultWrapper sizeWidth={sizeWidth}>
      <S.InputDefaultContent searchAbsolute={searchAbsolute} mobile={mobile}>
        {!!icon && (
          <S.CustomIcon icon={icon} spin={Array.isArray(icon) && icon[1] === 'circle-notch'} />
        )}
        <S.InputDefaultStyled
          bgColor={bgColor}
          icon={!!icon}
          autoResize={autoResize}
          withAutoComplete={withAutoComplete}
          type={type}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          optionsAutoComplete={data}
          disabled={disabled}
          block={block}
          inputSize={size}
          fontSize={fontSize}
          ref={innerRef}
          fieldValidation={fieldValidation}
          typed={typed}
          withoutBorder={withoutBorder}
          {...rest}
        />
        {fieldValidation ? (
          (!!errors && errors.length >= 1) || value?.length === 0 ? (
            <S.CustomValidation color={fieldLoading ? 'grey2' : 'green3'}>
              <S.CustomValidationIcon color="red3" icon={['fal', 'times']} />
            </S.CustomValidation>
          ) : (
            <S.CustomValidation>
              {fieldLoading ? (
                <S.CustomValidationIcon color="grey2" icon={['fal', 'circle-notch']} spin />
              ) : (
                <IconAnimated />
              )}
            </S.CustomValidation>
          )
        ) : null}
      </S.InputDefaultContent>
      {!!errors && <S.MessageValidation>{errors}</S.MessageValidation>}
    </S.InputDefaultWrapper>
  );
}

export default InputDefault;
