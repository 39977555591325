/* Modules */
import { call, put, takeEvery } from 'redux-saga/effects';
import { SchemaService } from 'services';
import dayjs from 'dayjs';

/* Types */
import {
  SET_DATE_RANGE,
  FETCH_AUTO_COMPLETE_GROUP,
  GET_CURRENT,
  GET_CURRENT_ITEM,
  GET_CODE_INFO,
  GET_CODE_INFORMATION,
  GET_GROUPS_PERMITTED_ACCESS,
  CHANGE_MONTLY_GOAL,
  LISTING_PAGE_COUNT,
} from './types';

import { SHOW_TOAST } from '../toast/types';

function* setDateRange(action) {
  const defineds = {
    startOfToday: dayjs().startOf('day').format('YYYY-MM-DD'),
    endOfToday: dayjs().endOf('day').format('YYYY-MM-DD'),
    startOfYesterday: dayjs().startOf('day').subtract(1, 'day').format('YYYY-MM-DD'),
    endOfYesterday: dayjs().endOf('day').subtract(1, 'day').format('YYYY-MM-DD'),
    sevenDaysAgo: dayjs().endOf('day').subtract(7, 'day').format('YYYY-MM-DD'),
    startOfMonth: dayjs().startOf('month').format('YYYY-MM-DD'),
    startOfYear: dayjs().startOf('year').format('YYYY-MM-DD'),
    startOfLastMonth: dayjs().startOf('month').subtract(1, 'month').format('YYYY-MM-DD'),
    endOfLastMonth: dayjs().endOf('month').subtract(1, 'month').format('YYYY-MM-DD'),
    startCustomPeriod: dayjs(action.currentData.start_date).format('YYYY-MM-DD'),
    endCustomPeriod: dayjs(action.currentData.end_date).format('YYYY-MM-DD'),
  };

  let period = { ...action.period };

  if (action.update) {
    let start;
    let end;
    let params;
    let dt = '';
    if (action.period.datePreset) {
      dt = action.period.datePreset.toUpperCase();
      params = `date_preset=${action.period.datePreset}`;

      if (dt === 'TODAY') {
        start = defineds.startOfToday;
        end = defineds.endOfToday;
      } else if (dt === 'YESTERDAY') {
        start = defineds.startOfYesterday;
        end = defineds.endOfYesterday;
      } else if (dt === 'LAST_7D') {
        start = defineds.sevenDaysAgo;
        end = defineds.endOfYesterday;
      } else if (dt === 'LAST_MONTH') {
        start = defineds.startOfLastMonth;
        end = defineds.endOfLastMonth;
      } else if (dt === 'THIS_MONTH') {
        start = defineds.startOfMonth;
        end = defineds.endOfToday;
      } else {
        start = defineds.startOfYear;
        end = defineds.endOfToday;
      }

      // Used only to call the Api right way.
    } else {
      start = action.period.startDate.split('T')[0];
      end = action.period.endDate.split('T')[0];
      params = `since=${start}&until=${end}`;

      if (
        action.period.startDate === defineds.startOfToday &&
        action.period.endDate === defineds.endOfToday
      ) {
        dt = 'TODAY';
      } else if (
        action.period.startDate === defineds.startOfYesterday &&
        action.period.endDate === defineds.endOfYesterday
      ) {
        dt = 'YESTERDAY';
      } else if (
        action.period.startDate === defineds.sevenDaysAgo &&
        action.period.endDate === defineds.endOfYesterday
      ) {
        dt = 'LAST_7D';
      } else if (
        action.period.startDate === defineds.startOfLastMonth &&
        action.period.endDate === defineds.endOfLastMonth
      ) {
        dt = 'LAST_MONTH';
      } else if (
        action.period.startDate === defineds.startOfMonth &&
        action.period.endDate === defineds.endOfToday
      ) {
        dt = 'THIS_MONTH';
      } else if (
        action.period.startDate === defineds.startOfYear &&
        action.period.endDate === defineds.endOfToday
      ) {
        dt = 'THIS_YEAR';
      } else if (
        action.period.startDate === defineds.startCustomPeriod &&
        action.period.endDate === defineds.endCustomPeriod
      ) {
        params = `since=${defineds.startCustomPeriod}&until=${defineds.endCustomPeriod}`;
      }
    }

    period = {
      datePreset: dt,
      start,
      end,
      params: params.toLowerCase(),
    };
  }

  yield put({ type: SET_DATE_RANGE.SUCCESS, preset: period });
}

function* fetchAutoCompleteGroup(action) {
  const response = yield call(
    SchemaService.autoCompleteGroups,
    action.version,
    action.typed,
    action.types
  );

  if (response.status === 200) {
    yield put({
      type: FETCH_AUTO_COMPLETE_GROUP.SUCCESS,
      autoCompleteGroup: response.data,
      typed: action.typed,
    });
  } else {
    yield put({
      type: FETCH_AUTO_COMPLETE_GROUP.FAILURE,
      autoCompleteGroupError: response.data.error,
    });
  }
}

function* getCurrent(action) {
  const response = yield call(SchemaService.current, action.version);

  if (response.status === 200) {
    yield put({ type: GET_CURRENT.SUCCESS, current: response.data, status: response.status });
  } else {
    yield put({
      type: GET_CURRENT.FAILURE,
      currentError: response.data.error,
      status: response.status,
    });
  }
}

function* getCurrentItem(action) {
  const response = yield call(SchemaService.current, action.version);

  if (response.status === 200) {
    yield put({
      type: GET_CURRENT_ITEM.SUCCESS,
      currentItem: response.data,
      status: response.status,
    });
  } else {
    yield put({
      type: GET_CURRENT_ITEM.FAILURE,
      currentItemError: response.data.error,
      status: response.status,
    });
  }
}

function* getCodeInfo(action) {
  const response = yield call(SchemaService.getCodeInfo, action.version, action.id, action.search);

  if (response.status === 200) {
    yield put({ type: GET_CODE_INFO.SUCCESS, code: response.data, status: response.status });
  } else {
    yield put({ type: GET_CODE_INFO.FAILURE, status: response.status });
  }
}

function* getCodeInformation(action) {
  const response = yield call(SchemaService.getCodeInformation, action.version, action.code);

  if (response?.status === 200) {
    yield put({
      type: GET_CODE_INFORMATION.SUCCESS,
      currentCode: response.data,
      status: response.status,
    });
  } else {
    yield put({ type: GET_CODE_INFORMATION.FAILURE, status: response.status });
  }
}

function* getGroupsPermittedAccess(action) {
  const response = yield call(
    SchemaService.getGroupsPermittedAccess,
    action.version,
    action.id,
    action.fields
  );

  if (response.status === 200) {
    yield put({ type: GET_GROUPS_PERMITTED_ACCESS.SUCCESS, groupsPermitted: response.data });
  } else {
    yield put({
      type: GET_GROUPS_PERMITTED_ACCESS.FAILURE,
      groupsPermittedError: response.data.error,
    });
  }
}

function* changeMontlyGoal(action) {
  const response = yield call(
    SchemaService.changeMontlyGoal,
    action.version,
    action.fundingId,
    action.data
  );

  if (response.status === 200) {
    yield put({
      type: CHANGE_MONTLY_GOAL.SUCCESS,
      status: response.status,
      newValue: action.data.value,
    });
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.successMessage, messageType: 'success' });
  } else {
    yield put({ type: CHANGE_MONTLY_GOAL.FAILURE, status: response.status });
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
  }
}

function* listingPageCount(action) {
  const response = yield call(
    SchemaService.getListingCount,
    action.version,
    action.tenantId,
    action.unit
  );

  if (response.status === 200) {
    yield put({ type: LISTING_PAGE_COUNT.SUCCESS, data: response.data });
  } else {
    yield put({ type: LISTING_PAGE_COUNT.FAILURE, data: response.status });
  }
}

export const genericSaga = [
  takeEvery(SET_DATE_RANGE.REQUEST, setDateRange),
  takeEvery(FETCH_AUTO_COMPLETE_GROUP.REQUEST, fetchAutoCompleteGroup),
  takeEvery(GET_CURRENT.REQUEST, getCurrent),
  takeEvery(GET_CURRENT_ITEM.REQUEST, getCurrentItem),
  takeEvery(GET_CODE_INFO.REQUEST, getCodeInfo),
  takeEvery(GET_CODE_INFORMATION.REQUEST, getCodeInformation),
  takeEvery(GET_GROUPS_PERMITTED_ACCESS.REQUEST, getGroupsPermittedAccess),
  takeEvery(CHANGE_MONTLY_GOAL.REQUEST, changeMontlyGoal),
  takeEvery(LISTING_PAGE_COUNT.REQUEST, listingPageCount),
];
