import React, { useState, useEffect } from 'react';

import styled from 'styled-components';

import { useParams } from 'react-router-dom';

import { MultiSelectCheckBox } from '@bornlogic/gd-design-system';

import SettingsService from '../../services/settings';

const MultiSelectContainer = styled.div`
  min-height: 300px;
`;

const GroupsPagesAccountsMultiSelectCheckbox = ({ onSelectLeafs, rootCode }) => {
  const { code = 'root' } = useParams();

  const initiatorCode = rootCode || code;

  const [leaf, setLeaf] = useState(initiatorCode);
  const [backLeaf, setBackLeaf] = useState(initiatorCode);
  const [groupsAndPages, setGroupsAndPages] = useState([]);
  const [selectedLeafs, setSelectedLeafs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const arrIds = [initiatorCode];

  const normalizeGroupsAndPages = (data) => {
    const normalizedData = [];

    data.forEach((item) => {
      normalizedData.push({
        source_id: item.source_id,
        child_count: item.child_groups_count,
        name: item.name,
        color: item.color,
        ad_account_ids: item.ad_account_ids,
      });
    });

    return normalizedData;
  };

  const _handleAddNewLeaf = (data) => {
    setSelectedLeafs(data);
  };

  useEffect(() => {
    if (leaf !== null) {
      const loadGroupsPageAdAccountBySourceID = async (sourceId) => {
        const response = await SettingsService.getGroupsPageAdAccountBySourceID(sourceId);
        if (response.status === 200) {
          setGroupsAndPages(normalizeGroupsAndPages(response.data));
        }
        setIsLoading(false);
      };

      loadGroupsPageAdAccountBySourceID(leaf);
    }
  }, [leaf]);

  useEffect(() => {
    if (selectedLeafs.length > 0) onSelectLeafs(selectedLeafs);
  }, [onSelectLeafs, selectedLeafs]);

  return (
    <MultiSelectContainer>
      {groupsAndPages && !isLoading && (
        <MultiSelectCheckBox
          mainNode={groupsAndPages}
          leaf={leaf}
          source_id="source_id"
          child_count="child_count"
          root={backLeaf}
          searchPlaceHolder="Buscar"
          maxHeight={300}
          minHeight={300}
          selectedLeafs={selectedLeafs}
          onSelectLeaf={_handleAddNewLeaf}
          fetchNextNode={({ source_id, isBackToParent }) => {
            setIsLoading(true);

            if (isBackToParent) {
              const lastBackLeaf = arrIds.pop();

              if (!lastBackLeaf) {
                setLeaf(initiatorCode);
                setBackLeaf(initiatorCode);
              } else {
                setLeaf(backLeaf);
                setBackLeaf(lastBackLeaf);
              }
            } else if (!isBackToParent) {
              if (backLeaf !== initiatorCode) {
                arrIds.push(backLeaf);
              }

              setBackLeaf(leaf);
              setLeaf(source_id);
            }

            return source_id;
          }}
        />
      )}
    </MultiSelectContainer>
  );
};

export default GroupsPagesAccountsMultiSelectCheckbox;
