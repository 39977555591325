import { color, spacing } from '@bornlogic/reborn';
import styled, { css } from 'styled-components';

export const TitleStyle = styled.div`
  ${({ theme: { spacing } }) => css`
    margin-top: ${spacing.px10};
    > div {
      margin-bottom: 20px !important;
    }
  `}
`;

export const ButtonApply = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const IconStyled = styled.div`
  color: ${color.neutralLow.v100.color};
  margin-right: ${spacing.nano};

  ${({ theme: { fontSize } }) => css`
    font-size: ${fontSize.px16};
  `}
`;

export const ButtonStyled = styled.button`
  border: none;
  border-bottom: 2px solid ${color.primary.v100.color};

  ${({ theme: { colors, spacing, fontSize, fontWeight } }) => css`
    display: flex;
    align-items: center;
    color: ${colors.blueGrey3};
    background-color: ${colors.white};
    text-align: center;
    outline: none;
    line-height: 1;
    transition: all 0.3s;
    margin-left: ${spacing.px24};
    font-size: ${fontSize.px14};

    font-weight: ${fontWeight.light};
    position: relative;
    top: 0;
    transition: all 0.2s;
    cursor: pointer;
    white-space: nowrap;
    height: 100%;
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    &:active {
      box-shadow: 0 0 0 ${colors.active.blue2};
    }
    & > span {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  `}
`;
