// Report Reducer
// Here must have all data referent to report page

// Modules
import dayjs from 'dayjs';

// Types
import {
  CHANGE_COMMENTS_METRIC_CHART,
  CHANGE_GROUP_COLOR,
  CHANGE_METRIC_CHART,
  CREATE_GROUP,
  DELETE_GROUP,
  FETCH_CAMPAIGN_HEALTH_INSTAGRAM,
  GET_ACTIVITIES_GROUPED_BY_DAY,
  GET_ACTIVITY_METRICS,
  GET_AUDIENCE_AGE_GENDER,
  GET_AUDIENCE_CHANNEL,
  GET_AUDIENCE_DEVICE,
  GET_BUDGET_METRICS,
  GET_CAMPAIGN_HEALTH,
  GET_CHART_RANKING,
  GET_COMMENTS_GRAPH,
  GET_COMMENTS_RANK,
  GET_COMMENTS_RANK_EVOLUTION,
  GET_COMMENTS_SUMMARY,
  GET_CONVERSION_FLOW,
  GET_GROUP_COLORS,
  GET_INFO_GROUPS,
  GET_LIST_ALL_ACCOUNTS,
  GET_LIST_ALL_GROUPS,
  GET_LIST_ALL_PAGES,
  GET_RANKING,
  GET_RANKING_ACCOUNTS,
  GET_RANKING_GROUPS,
  GET_RANKING_PAGES,
  GET_SUMMARY,
  POST_ENGAGEMENT_BEST,
  POST_INSIGHTS_METRICS,
  POST_PURCHASE_BEST,
  POST_REPORT_AD,
  POST_REPORT_AD_EVOLUTION,
  POST_REPORT_PAGE_EVOLUTION_FACEBOOK,
  POST_REPORT_PAGE_EVOLUTION_INSTAGRAM,
  POST_REPORT_PAGE_FACEBOOK,
  POST_REPORT_PAGE_INSTAGRAM,
  RENAME_GROUP,
  REPLACE_ACCOUNT,
  REPLACE_PAGE,
} from './types';

const INITIAL_STATE = {
  overview: {
    global: {
      campaignHealth: {
        data: {
          amountSpent: 0,
          budgetGoal: 0,
          remainingBudget: 0,
          dailyGoal: 0,
          percentageFromTotal: 0,
        },
        isLoading: true,
        message: null,
        status: null,
      },
      campaignHealthInstagram: {
        data: {},
        error: '',
        isLoading: true,
      },
      organicInsights: {
        data: {
          pageImpressions: 0,
          pageVideoViews: 0,
        },
      },
    },
    conversionFlow: {
      data: {
        purchase: 0,
        completeRegistration: 0,
        addToCart: 0,
        viewContent: 0,
        linkClick: 0,
      },
      isLoading: false,
      message: null,
      status: null,
    },
    cardDivided: {
      data: {
        linkClick: 0,
        spend: 0,
        purchase: 0,
        impressions: 0,
        videoViews: 0,
        clicks: 0,
        engagements: 0,
        organicImpressions: 0,
        organicVideoViews: 0,
        involvement: 0,
        onSiteConversionLeadsGrouped: 0,
        emotions: {
          like: 0,
          love: 0,
          wow: 0,
          haha: 0,
          sorry: 0,
          anger: 0,
          total: 0,
        },
      },
      isLoading: true,
      message: null,
      status: null,
    },
  },
  listAll: {
    data: {
      total: {},
      body: [],
    },
    groupColors: [],
    isLoading: false,
    message: null,
    status: null,
  },
  engagement: {
    data: {
      ad: {
        content: [],
        evolution: [],
      },
      facebook: {
        content: [],
        evolution: [],
      },
      instagram: {
        content: [],
        evolution: [],
      },
      top: [],
      topPurchase: [],
      topInfo: [],
      topLoading: false,
      topPurchaseLoading: false,
    },
    summary: {},
    isLoading: true,
    message: null,
    status: null,
  },
  ranking: {
    data: {
      best: [],
      worst: [],
      metric: null,
      size: 10,
    },
    charts: [],
    chart: {
      content: [],
      error: null,
      isLoading: false,
      idSelected: null,
    },
    groups: [],
    metrics: [],
    isLoading: false,
    message: null,
    status: null,
  },
  audience: {
    ageGender: {
      data: [],
      isLoading: false,
      message: null,
      status: null,
    },
    channel: {
      data: [],
      isLoading: false,
      message: null,
      status: null,
    },
    device: {
      data: [],
      isLoading: false,
      message: null,
      status: null,
    },
  },
  summary: {
    data: {},
    isLoading: true,
    error: null,
  },
  commentsSummary: {
    isLoading: false,
    data: {},
    error: null,
  },
  commentsGraph: {
    isLoading: false,
    data: {},
    error: null,
  },
  commentsRank: {
    isLoading: false,
    data: {},
    error: null,
  },
  commentsRankEvolution: {
    data: {
      metric: 'response_rate',
    },
    charts: [],
    chart: {
      content: [],
      error: null,
      isLoading: false,
      idSelected: null,
    },
    groups: [],
    metrics: [],
    isLoading: false,
    message: null,
    status: null,
  },
  custom: {
    data: [],
    isLoading: false,
    error: null,
  },
  customActivity: {
    data: [],
    isLoading: false,
    error: null,
  },
  customBudget: {
    data: [],
    isLoading: false,
    error: null,
  },
  activitiesGrouped: {
    data: [],
    isLoading: false,
    error: null,
  },
};

const reportReducer = (state = INITIAL_STATE, action) => {
  let listAllDataBody = [...state.listAll.data.body];
  const listAllGroupColors = [...state.listAll.groupColors];

  switch (action.type) {
    case GET_COMMENTS_SUMMARY.REQUEST:
      return {
        ...state,
        commentsSummary: {
          data: {},
          isLoading: true,
          error: null,
        },
      };

    case GET_COMMENTS_SUMMARY.SUCCESS:
      return {
        ...state,
        commentsSummary: {
          data: action.commentsSummary,
          isLoading: false,
          error: null,
        },
      };

    case GET_COMMENTS_SUMMARY.FAILURE:
      return {
        ...state,
        commentsSummary: {
          data: {},
          isLoading: false,
          error: action.commentsSummaryError,
        },
      };

    case GET_COMMENTS_GRAPH.REQUEST:
      return {
        ...state,
        commentsGraph: {
          data: {},
          isLoading: true,
          error: null,
        },
      };

    case GET_COMMENTS_GRAPH.SUCCESS:
      return {
        ...state,
        commentsGraph: {
          data: action.commentsGraph,
          isLoading: false,
          error: null,
        },
      };

    case GET_COMMENTS_GRAPH.FAILURE:
      return {
        ...state,
        commentsGraph: {
          data: {},
          isLoading: false,
          error: action.commentsGraphError,
        },
      };

    case GET_COMMENTS_RANK.REQUEST:
      return {
        ...state,
        commentsRank: {
          data: {},
          isLoading: true,
          error: null,
        },
      };

    case GET_COMMENTS_RANK.SUCCESS:
      return {
        ...state,
        commentsRank: {
          data: action.commentsRank,
          isLoading: false,
          error: null,
        },
      };

    case GET_COMMENTS_RANK.FAILURE:
      return {
        ...state,
        commentsRank: {
          data: {},
          isLoading: false,
          error: action.commentsRankError,
        },
      };

    case GET_COMMENTS_RANK_EVOLUTION.REQUEST:
      return {
        ...state,
        commentsRankEvolution: {
          ...state.commentsRankEvolution,
          chart: {
            content: [],
            error: '',
            isLoading: true,
            idSelected: null,
          },
        },
      };

    case GET_COMMENTS_RANK_EVOLUTION.SUCCESS:
      return {
        ...state,
        commentsRankEvolution: {
          ...state.commentsRankEvolution,
          charts: action.charts,
          metrics: action.metrics,
          chart: {
            content: action.chart,
            error: '',
            isLoading: false,
            idSelected: action.id,
          },
        },
      };

    case GET_COMMENTS_RANK_EVOLUTION.FAILURE:
      return {
        ...state,
        commentsRankEvolution: {
          ...state.commentsRankEvolution,
          chart: {
            content: [],
            error: action.chartError,
            isLoading: false,
            idSelected: null,
          },
        },
      };

    case GET_COMMENTS_RANK_EVOLUTION.RESET:
      return {
        ...state,
        commentsRankEvolution: {
          ...state.commentsRankEvolution,
          chart: {
            content: [],
            error: '',
            isLoading: false,
            idSelected: null,
          },
        },
      };

    case GET_CAMPAIGN_HEALTH.REQUEST:
      return {
        ...state,
        overview: {
          ...state.overview,
          global: {
            ...state.overview.global,
            campaignHealth: {
              data: INITIAL_STATE.overview.global.campaignHealth.data,
              isLoading: true,
            },
            organicInsights: {
              data: INITIAL_STATE.overview.global.organicInsights.data,
              isLoading: true,
            },
          },
          cardDivided: {
            data: INITIAL_STATE.overview.cardDivided.data,
            isLoading: true,
          },
        },
      };

    case GET_CAMPAIGN_HEALTH.SUCCESS:
      const root = action.code === 'root';
      const getData = action.data;
      const getCurrent = action.current;
      const getCurrentItem = action.currentItem;
      const getPeriod = action.period;

      const startPeriod = getPeriod.start;
      const endPeriod = getPeriod.end;
      const adInsights = root
        ? getData.ad_insights && getData.ad_insights[0]
        : getData.data && getData.data[0];
      const pageInsights = (root ? getData.page_insights : getData.data && getData.data[0]) || {};

      const startDate = dayjs(startPeriod);
      const endDate = dayjs(endPeriod);
      const difference = endDate.diff(startDate, 'day') + 1;

      const startCurrent = dayjs(dayjs(getCurrent.start_date).format('YYYY-MM-DD'));
      const endCurrent = dayjs(dayjs(getCurrent.end_date).format('YYYY-MM-DD'));
      const differenceCurrent = endCurrent.diff(startCurrent, 'day') + 1;

      const getTotal = root
        ? getCurrent.total
        : getCurrentItem.budget && getCurrentItem.budget.total;

      const totalCurrent = (getTotal / differenceCurrent) * difference;

      const remainingDays = endDate.diff(dayjs(), 'day') + 2;

      return {
        ...state,
        overview: {
          ...state.overview,
          global: {
            ...state.overview.global,
            campaignHealth: {
              data: {
                amountSpent: adInsights.spend || 0,
                budgetGoal: totalCurrent || 0,
                remainingBudget: totalCurrent - adInsights.spend || 0,
                dailyGoal: (totalCurrent - adInsights.spend) / remainingDays || 0,
                percentageFromTotal: (adInsights.spend / totalCurrent) * 100 || 0,
              },
              isLoading: false,
              message: null,
              status: action.status,
            },
            organicInsights: {
              data: {
                pageImpressions: pageInsights.page_impressions_organic || 0,
                pageVideoViews: pageInsights.page_video_views_organic || 0,
              },
              isLoading: false,
              message: null,
              status: action.status,
            },
          },
          cardDivided: {
            data: {
              cost_per_conversations_started:
                adInsights['cost_per_onsite_conversion.messaging_conversation_started_7d'] || 0,
              conversations_started:
                adInsights['onsite_conversion.messaging_conversation_started_7d'] || 0,
              leads: adInsights.lead || 0,
              onSiteConversionLeadsGrouped: adInsights['onsite_conversion.lead_grouped'] || 0,
              cost_per_lead: adInsights['cost_per_onsite_conversion.lead_grouped'] || 0,
              lead_rate: adInsights.lead_rate || 0,
              impressions: adInsights.impressions || 0,
              linkClick: adInsights.link_click || 0,
              videoViews: adInsights.video_view || 0,
              clicks: adInsights.clicks || 0,
              engagements: adInsights.post_engagement || 0,
              reach:
                (action.reach && action.reach.reduce((init, current) => init + current.reach, 0)) ||
                null,
              organicImpressions: pageInsights.page_impressions_organic || 0,
              organicVideoViews: pageInsights.page_video_views_organic || 0,
              involvement: pageInsights.page_post_involvement || 0,
              post_involvement: adInsights.post_involvement || 0,
              emotions: {
                like: pageInsights.page_actions_post_reactions_like_total || 0,
                love: pageInsights.page_actions_post_reactions_love_total || 0,
                wow: pageInsights.page_actions_post_reactions_wow_total || 0,
                haha: pageInsights.page_actions_post_reactions_haha_total || 0,
                sorry: pageInsights.page_actions_post_reactions_sorry_total || 0,
                anger: pageInsights.page_actions_post_reactions_anger_total || 0,
                total:
                  (pageInsights.page_actions_post_reactions_like_total || 0) +
                  (pageInsights.page_actions_post_reactions_love_total || 0) +
                  (pageInsights.page_actions_post_reactions_wow_total || 0) +
                  (pageInsights.page_actions_post_reactions_haha_total || 0) +
                  (pageInsights.page_actions_post_reactions_sorry_total || 0) +
                  (pageInsights.page_actions_post_reactions_anger_total || 0),
              },
              purchase:
                (adInsights['offsite_conversion.fb_pixel_purchase'] || 0) +
                (adInsights['offline_conversion.purchase'] || 0),
              conversion_rate: adInsights.link_click
                ? (((adInsights['offsite_conversion.fb_pixel_purchase'] || 0) +
                    (adInsights['offline_conversion.purchase'] || 0)) /
                    adInsights.link_click) *
                    100 || 0
                : 0,
              cost_per_acquisition:
                (adInsights['offsite_conversion.fb_pixel_purchase'] || 0) +
                  (adInsights['offline_conversion.purchase'] || 0) >
                0
                  ? (adInsights['spend'] || 0) /
                    ((adInsights['offsite_conversion.fb_pixel_purchase'] || 0) +
                      (adInsights['offline_conversion.purchase'] || 0))
                  : 0,
              onlineRevenue:
                adInsights['offsite_conversion.fb_pixel_purchase_conversion_value'] || 0,
              offlineRevenue: adInsights['offline_conversion.purchase_conversion_value'] || 0,
            },
            isLoading: false,
            message: null,
            status: action.status,
          },
        },
      };

    case GET_CAMPAIGN_HEALTH.FAILURE:
      return {
        ...state,
        overview: {
          ...state.overview,
          global: {
            ...state.overview.global,
            campaignHealth: {
              data: INITIAL_STATE.overview.global.campaignHealth.data,
              isLoading: false,
              message: 'An error occurred try to make a request.',
              status: action.status,
            },
          },
          cardDivided: {
            data: INITIAL_STATE.overview.cardDivided.data,
            isLoading: false,
            message: 'An error occurred try to make a request.',
            status: action.status,
          },
        },
      };

    case GET_CAMPAIGN_HEALTH.RESET:
      return {
        ...state,
        overview: INITIAL_STATE.overview,
      };

    case FETCH_CAMPAIGN_HEALTH_INSTAGRAM.REQUEST:
      return {
        ...state,
        overview: {
          ...state.overview,
          global: {
            ...state.overview.global,
            campaignHealthInstagram: {
              data: {},
              error: '',
              isLoading: true,
            },
          },
        },
      };

    case FETCH_CAMPAIGN_HEALTH_INSTAGRAM.SUCCESS:
      return {
        ...state,
        overview: {
          ...state.overview,
          global: {
            ...state.overview.global,
            campaignHealthInstagram: {
              data: action.campaignHealthInstagram,
              error: '',
              isLoading: false,
            },
          },
        },
      };

    case FETCH_CAMPAIGN_HEALTH_INSTAGRAM.FAILURE:
      return {
        ...state,
        overview: {
          ...state.overview,
          global: {
            ...state.overview.global,
            campaignHealthInstagram: {
              data: {},
              error: action.campaignHealthInstagram,
              isLoading: false,
            },
          },
        },
      };

    case GET_CONVERSION_FLOW.REQUEST:
      return {
        ...state,
        overview: {
          ...state.overview,
          conversionFlow: {
            data: INITIAL_STATE.overview.conversionFlow.data,
            isLoading: true,
          },
          cardDivided: {
            data: INITIAL_STATE.overview.cardDivided.data,
            isLoading: true,
          },
        },
      };

    case GET_CONVERSION_FLOW.SUCCESS:
      const rootConversion = action.code === 'root';
      const dataSummaries = action.summaries || {};
      const dataResponse = action.data;
      const pageInsightsConversion = rootConversion ? {} : dataResponse.page_insights;
      const adInsightsConversion =
        rootConversion && dataResponse.ad_insights && dataResponse.ad_insights[0]
          ? dataResponse.ad_insights[0]
          : {};

      return {
        ...state,
        overview: {
          ...state.overview,
          conversionFlow: {
            data: {
              purchase: !rootConversion
                ? dataSummaries['offsite_conversion.fb_pixel_purchase'] || 0
                : adInsightsConversion['offsite_conversion.fb_pixel_purchase'] || 0,
              completeRegistration: !rootConversion
                ? dataSummaries['offsite_conversion.fb_pixel_complete_registration'] || 0
                : adInsightsConversion['offsite_conversion.fb_pixel_complete_registration'] || 0,
              addToCart: !rootConversion
                ? dataSummaries['offsite_conversion.fb_pixel_add_to_cart'] || 0
                : adInsightsConversion['offsite_conversion.fb_pixel_add_to_cart'] || 0,
              viewContent: !rootConversion
                ? dataSummaries['offsite_conversion.fb_pixel_view_content'] || 0
                : adInsightsConversion['offsite_conversion.fb_pixel_view_content'] || 0,
              linkClick: !rootConversion
                ? dataSummaries.link_click || 0
                : adInsightsConversion.link_click || 0,
            },
            isLoading: false,
            message: null,
            status: action.status,
          },
          cardDivided: {
            data: {
              linkClick: !rootConversion
                ? dataSummaries.link_click || 0
                : adInsightsConversion.link_click || 0,
              spend: !rootConversion ? dataSummaries.spend || 0 : adInsightsConversion.spend || 0,
              purchase: !rootConversion
                ? dataSummaries['offsite_conversion.fb_pixel_purchase'] || 0
                : adInsightsConversion['offsite_conversion.fb_pixel_purchase'] || 0,
              impressions: !rootConversion
                ? dataSummaries.impressions || 0
                : adInsightsConversion.impressions || 0,
              clicks: !rootConversion
                ? dataSummaries.clicks || 0
                : adInsightsConversion.clicks || 0,
              engagements: !rootConversion
                ? dataSummaries.post_engagement || 0
                : adInsightsConversion.post_engagement || 0,
              organicImpressions: !rootConversion
                ? dataSummaries.page_impressions_organic || 0
                : pageInsightsConversion.page_impressions_organic || 0,
              organicVideoViews: !rootConversion
                ? dataSummaries.page_video_views_organic || 0
                : pageInsightsConversion.page_video_views_organic || 0,
              involvement: !rootConversion
                ? dataSummaries.post_involvement || 0
                : pageInsightsConversion.page_post_involvement || 0,
              cost_per_acquisition: !rootConversion
                ? dataSummaries['cost_per_offsite_conversion.fb_pixel_purchase'] || 0
                : adInsightsConversion['cost_per_offsite_conversion.fb_pixel_purchase'] || 0,
              conversion_rate: !rootConversion
                ? dataSummaries['offsite_conversion.fb_pixel_purchase_rate'] || 0
                : adInsightsConversion['offsite_conversion.fb_pixel_purchase_rate'] || 0,
              emotions: {
                like: !rootConversion
                  ? dataSummaries.page_actions_post_reactions_like_total || 0
                  : pageInsightsConversion.page_actions_post_reactions_like_total || 0,
                love: !rootConversion
                  ? dataSummaries.page_actions_post_reactions_love_total || 0
                  : pageInsightsConversion.page_actions_post_reactions_love_total || 0,
                wow: !rootConversion
                  ? dataSummaries.page_actions_post_reactions_wow_total || 0
                  : pageInsightsConversion.page_actions_post_reactions_wow_total || 0,
                haha: !rootConversion
                  ? dataSummaries.page_actions_post_reactions_haha_total || 0
                  : pageInsightsConversion.page_actions_post_reactions_haha_total || 0,
                sorry: !rootConversion
                  ? dataSummaries.page_actions_post_reactions_sorry_total || 0
                  : pageInsightsConversion.page_actions_post_reactions_sorry_total || 0,
                anger: !rootConversion
                  ? dataSummaries.page_actions_post_reactions_anger_total || 0
                  : pageInsightsConversion.page_actions_post_reactions_anger_total || 0,
                total:
                  (!rootConversion
                    ? dataSummaries.page_actions_post_reactions_like_total || 0
                    : pageInsightsConversion.page_actions_post_reactions_like_total || 0) +
                  (!rootConversion
                    ? dataSummaries.page_actions_post_reactions_love_total || 0
                    : pageInsightsConversion.page_actions_post_reactions_love_total || 0) +
                  (!rootConversion
                    ? dataSummaries.page_actions_post_reactions_wow_total || 0
                    : pageInsightsConversion.page_actions_post_reactions_wow_total || 0) +
                  (!rootConversion
                    ? dataSummaries.page_actions_post_reactions_haha_total || 0
                    : pageInsightsConversion.page_actions_post_reactions_haha_total || 0) +
                  (!rootConversion
                    ? dataSummaries.page_actions_post_reactions_sorry_total || 0
                    : pageInsightsConversion.page_actions_post_reactions_sorry_total || 0) +
                  (!rootConversion
                    ? dataSummaries.page_actions_post_reactions_anger_total || 0
                    : pageInsightsConversion.page_actions_post_reactions_anger_total || 0),
              },
            },
            isLoading: false,
            message: null,
            status: action.status,
          },
        },
      };

    case GET_CONVERSION_FLOW.FAILURE:
      return {
        ...state,
        overview: {
          ...state.overview,
          conversionFlow: {
            data: INITIAL_STATE.overview.conversionFlow.data,
            isLoading: false,
            message: 'An error occurred try to make a request.',
            status: action.status,
          },
          cardDivided: {
            data: INITIAL_STATE.overview.cardDivided.data,
            isLoading: false,
            message: 'An error occurred try to make a request.',
            status: action.status,
          },
        },
      };

    case GET_CONVERSION_FLOW.RESET:
      return {
        ...state,
        overview: INITIAL_STATE.overview,
      };

    case GET_LIST_ALL_GROUPS.REQUEST:
      return {
        ...state,
        listAll: {
          ...state.listAll,
          data: INITIAL_STATE.listAll.data,
          isLoading: true,
          message: null,
          status: null,
        },
      };

    case GET_LIST_ALL_GROUPS.SUCCESS:
      const dataGroup = action.data;
      const summariesGroup = action.summaries;
      const accountGroup = action.account;
      const reachGroup = action.reach;

      const mountedBody = [];

      if (accountGroup.length) {
        dataGroup.forEach((item) => {
          const bodyReachGroups =
            !!reachGroup.length &&
            reachGroup.filter((x) => x.source_id === item.source_id)[0].reach;

          let newReachGroups;

          if (bodyReachGroups === 0) {
            newReachGroups = 0;
          } else if (!bodyReachGroups && bodyReachGroups !== 0) {
            newReachGroups = null;
          } else {
            newReachGroups = bodyReachGroups;
          }

          mountedBody.push({
            name: accountGroup.find((x) => x.source_id === item.source_id).name || '',
            source_id: item.source_id,
            impressions: item.impressions || 0,
            videoViews: item.video_view || 0,
            engagement: item.post_engagement || 0,
            rate_engagement: item.impressions
              ? (item.post_engagement / item.impressions) * 100 || 0
              : 0,
            involvement: item.post_involvement || 0,
            rate_involvement: item.impressions
              ? (item.post_involvement / item.impressions) * 100 || 0
              : 0,
            spend: item.spend || 0,
            reach: newReachGroups,
            color:
              item.color || accountGroup.find((x) => x.source_id === item.source_id).color || '',
            type: 'group',
            purchase: item['offsite_conversion.fb_pixel_purchase'],
            conversion_rate: item.link_click
              ? (item['offsite_conversion.fb_pixel_purchase'] / item.link_click) * 100 || 0
              : 0,
            conversion_value: item['offsite_conversion.fb_pixel_purchase_conversion_value'],
            cost_per_acquisition: item['cost_per_offsite_conversion.fb_pixel_purchase'],
            link_clicks: item.link_click,
            cost_per_link_click: item.cost_per_link_click,
            rate_link_click: item.impressions ? (item.link_click / item.impressions) * 100 || 0 : 0,
            return_on_advertising_spend: item.spend
              ? item['offsite_conversion.fb_pixel_purchase_conversion_value'] / item.spend || 0
              : 0,
            return_on_investment: item.spend
              ? (item['offsite_conversion.fb_pixel_purchase_conversion_value'] - item.spend) /
                  item.spend || 0
              : 0,
            revenue: item['offsite_conversion.fb_pixel_purchase_conversion_value'] || 0,
            conversations_started: item['onsite_conversion.messaging_conversation_started_7d'] || 0,
            conversations_new: item['onsite_conversion.messaging_first_reply'] || 0,
            cost_per_conversations_started:
              item['cost_per_onsite_conversion.messaging_conversation_started_7d'] || 0,
            cost_per_conversations_new:
              item['cost_per_onsite_conversion.messaging_first_reply'] || 0,
            offline_purchase: item['offline_conversion.purchase'] || 0,
            cost_per_offline_purchase: item['cost_per_offline_conversion.purchase'] || 0,
            offline_initiate_checkout: item['offline_conversion.initiate_checkout'] || 0,
            cost_per_offline_initiate_checkout:
              item['cost_per_offline_conversion.initiate_checkout'] || 0,
            offline_initiate_checkout_conversion_value:
              item['offline_conversion.initiate_checkout_conversion_value'] || 0,
            offline_initiate_checkout_return_on_advertising_spend: item.spend
              ? item['offline_conversion.initiate_checkout_conversion_value'] / item.spend || 0
              : 0,
            offline_conversion_value: item['offline_conversion.purchase_conversion_value'] || 0,
            offline_return_on_advertising_spend: item.spend
              ? item['offline_conversion.purchase_conversion_value'] / item.spend || 0
              : 0,
            offline_revenue: item['offline_conversion.purchase_conversion_value'] || 0,
          });
        });
      }

      let totalReachGroups;
      if (mountedBody.every((x) => x.reach === null)) {
        totalReachGroups = null;
      } else {
        totalReachGroups =
          reachGroup && reachGroup.length && reachGroup.reduce((acc, cur) => acc + cur.reach, 0);
      }

      let newTotalReachGroups;
      if (totalReachGroups === 0) {
        newTotalReachGroups = 0;
      } else if (!totalReachGroups && totalReachGroups !== 0) {
        newTotalReachGroups = null;
      } else {
        newTotalReachGroups = totalReachGroups;
      }

      const mountedTotal = {
        name: 'Total',
        impressions: summariesGroup.impressions || 0,
        videoViews: summariesGroup.video_view || 0,
        engagement: summariesGroup.post_engagement || 0,
        rate_engagement: summariesGroup.impressions
          ? (summariesGroup.post_engagement / summariesGroup.impressions) * 100 || 0
          : 0,
        spend: summariesGroup.spend || 0,
        involvement: summariesGroup.post_involvement || 0,
        rate_involvement: summariesGroup.impressions
          ? (summariesGroup.post_involvement / summariesGroup.impressions) * 100 || 0
          : 0,
        reach: newTotalReachGroups,
        conversion_rate: summariesGroup.link_click
          ? (summariesGroup['offsite_conversion.fb_pixel_purchase'] / summariesGroup.link_click) *
              100 || 0
          : 0,
        cost_per_acquisition: summariesGroup['cost_per_offsite_conversion.fb_pixel_purchase'] || 0,
        purchase: summariesGroup['offsite_conversion.fb_pixel_purchase'] || 0,
        link_clicks: summariesGroup.link_click,
        cost_per_link_click: summariesGroup.cost_per_link_click,
        rate_link_click: summariesGroup.impressions
          ? (summariesGroup.link_click / summariesGroup.impressions) * 100 || 0
          : 0,
        return_on_advertising_spend: summariesGroup.spend
          ? summariesGroup['offsite_conversion.fb_pixel_purchase_conversion_value'] /
              summariesGroup.spend || 0
          : 0,
        return_on_investment: summariesGroup.spend
          ? (summariesGroup['offsite_conversion.fb_pixel_purchase_conversion_value'] -
              summariesGroup.spend) /
              summariesGroup.spend || 0
          : 0,
        revenue: summariesGroup['offsite_conversion.fb_pixel_purchase_conversion_value'] || 0,
        conversations_started:
          summariesGroup['onsite_conversion.messaging_conversation_started_7d'] || 0,
        conversations_new: summariesGroup['onsite_conversion.messaging_first_reply'] || 0,
        cost_per_conversations_started:
          summariesGroup['cost_per_onsite_conversion.messaging_conversation_started_7d'] || 0,
        cost_per_conversations_new:
          summariesGroup['cost_per_onsite_conversion.messaging_first_reply'] || 0,
        offline_purchase: summariesGroup['offline_conversion.purchase'] || 0,
        cost_per_offline_purchase: summariesGroup['cost_per_offline_conversion.purchase'] || 0,
        offline_initiate_checkout: summariesGroup['offline_conversion.initiate_checkout'] || 0,
        cost_per_offline_initiate_checkout:
          summariesGroup['cost_per_offline_conversion.initiate_checkout'] || 0,
        offline_initiate_checkout_conversion_value:
          summariesGroup['offline_conversion.initiate_checkout_conversion_value'] || 0,
        offline_initiate_checkout_return_on_advertising_spend: summariesGroup.spend
          ? summariesGroup['offline_conversion.initiate_checkout_conversion_value'] /
              summariesGroup.spend || 0
          : 0,
        offline_conversion_value:
          summariesGroup['offline_conversion.purchase_conversion_value'] || 0,
        offline_return_on_advertising_spend: summariesGroup.spend
          ? summariesGroup['offline_conversion.purchase_conversion_value'] / summariesGroup.spend ||
            0
          : 0,
      };

      return {
        ...state,
        listAll: {
          ...state.listAll,
          data: {
            total: mountedTotal,
            body: mountedBody,
          },
          isLoading: false,
          message: null,
          status: action.status,
        },
      };

    case GET_LIST_ALL_GROUPS.FAILURE:
      return {
        ...state,
        listAll: {
          ...state.listAll,
          data: INITIAL_STATE.listAll.data,
          isLoading: false,
          message: 'An error occurred try to make a request.',
          status: action.status,
        },
      };

    case GET_LIST_ALL_GROUPS.RESET:
      return {
        ...state,
        listAll: {
          ...state.listAll,
          data: INITIAL_STATE.listAll.data,
        },
      };

    case GET_LIST_ALL_ACCOUNTS.REQUEST:
      return {
        ...state,
        listAll: {
          ...state.listAll,
          data: INITIAL_STATE.listAll.data,
          isLoading: true,
          message: null,
          status: null,
        },
      };

    case GET_LIST_ALL_ACCOUNTS.SUCCESS:
      const dataAccounts = action.data;
      const summariesAccounts = action.summaries;
      const accountAccounts = action.account;
      const reachAccounts = action.reach;

      const mountedBodyAccounts = [];

      if (accountAccounts.length) {
        dataAccounts.forEach((item) => {
          const bodyReachAccounts =
            !!reachAccounts.length &&
            reachAccounts.find((x) => x.source_id === item.source_id).reach;
          let newReach;

          if (bodyReachAccounts === 0) {
            newReach = 0;
          } else if (!bodyReachAccounts && bodyReachAccounts !== 0) {
            newReach = null;
          } else {
            newReach = bodyReachAccounts;
          }

          mountedBodyAccounts.push({
            name: accountAccounts.find((x) => x.source_id === item.source_id).name || '',
            source_id: item.source_id,
            impressions: item.impressions || 0,
            videoViews: item.video_view || 0,
            engagement: item.post_engagement || 0,
            rate_engagement: item.impressions
              ? (item.post_engagement / item.impressions) * 100 || 0
              : 0,
            involvement: item.post_involvement || 0,
            rate_involvement: item.impressions
              ? (item.post_involvement / item.impressions) * 100 || 0
              : 0,
            spend: item.spend || 0,
            reach: newReach,
            color: item.color || '',
            type: 'account',
            purchase: item['offsite_conversion.fb_pixel_purchase'],
            conversion_rate: item.link_click
              ? (item['offsite_conversion.fb_pixel_purchase'] / item.link_click) * 100 || 0
              : 0,
            cost_per_acquisition: item['cost_per_offsite_conversion.fb_pixel_purchase'],
            link_clicks: item.link_click,
            cost_per_link_click: item.cost_per_link_click,
            rate_link_click: item.impressions ? (item.link_click / item.impressions) * 100 || 0 : 0,
            conversations_started: item['onsite_conversion.messaging_conversation_started_7d'] || 0,
            conversations_new: item['onsite_conversion.messaging_first_reply'] || 0,
            cost_per_conversations_started:
              item['cost_per_onsite_conversion.messaging_conversation_started_7d'] || 0,
            cost_per_conversations_new:
              item['cost_per_onsite_conversion.messaging_first_reply'] || 0,
            revenue: item['offsite_conversion.fb_pixel_purchase_conversion_value'] || 0,
            return_on_advertising_spend: item.spend
              ? item['offsite_conversion.fb_pixel_purchase_conversion_value'] / item.spend || 0
              : 0,
            offline_purchase: item['offline_conversion.purchase'] || 0,
            cost_per_offline_purchase: item['cost_per_offline_conversion.purchase'] || 0,
            offline_initiate_checkout: item['offline_conversion.initiate_checkout'] || 0,
            cost_per_offline_initiate_checkout:
              item['cost_per_offline_conversion.initiate_checkout'] || 0,
            offline_initiate_checkout_conversion_value:
              item['offline_conversion.initiate_checkout_conversion_value'] || 0,
            offline_initiate_checkout_return_on_advertising_spend: item.spend
              ? item['offline_conversion.initiate_checkout_conversion_value'] / item.spend || 0
              : 0,
            offline_conversion_value: item['offline_conversion.purchase_conversion_value'] || 0,
            offline_revenue: item['offline_conversion.purchase_conversion_value'] || 0,
            offline_return_on_advertising_spend: item.spend
              ? item['offline_conversion.purchase_conversion_value'] / item.spend || 0
              : 0,
          });
        });
      }

      let totalReachAccount;
      if (mountedBodyAccounts.every((x) => x.reach === null)) {
        totalReachAccount = null;
      } else {
        totalReachAccount =
          !!reachAccounts.length && reachAccounts.reduce((acc, cur) => acc + cur.reach, 0);
      }

      let newTotalReach;
      if (totalReachAccount === 0) {
        newTotalReach = 0;
      } else if (!totalReachAccount && totalReachAccount !== 0) {
        newTotalReach = null;
      } else {
        newTotalReach = totalReachAccount;
      }

      const mountedTotalAccount = {
        name: 'Total',
        impressions: summariesAccounts.impressions || 0,
        videoViews: summariesAccounts.video_view || 0,
        engagement: summariesAccounts.post_engagement || 0,
        rate_engagement: summariesAccounts.impressions
          ? (summariesAccounts.post_engagement / summariesAccounts.impressions) * 100 || 0
          : 0,
        spend: summariesAccounts.spend || 0,
        involvement: summariesAccounts.post_involvement || 0,
        rate_involvement: summariesAccounts.impressions
          ? (summariesAccounts.post_involvement / summariesAccounts.impressions) * 100 || 0
          : 0,
        reach: newTotalReach,
        purchase: summariesAccounts['offsite_conversion.fb_pixel_purchase'],
        conversion_rate: summariesAccounts.link_click
          ? (summariesAccounts['offsite_conversion.fb_pixel_purchase'] /
              summariesAccounts.link_click) *
              100 || 0
          : 0,
        cost_per_acquisition: summariesAccounts['cost_per_offsite_conversion.fb_pixel_purchase'],
        link_clicks: summariesAccounts.link_click,
        cost_per_link_click: summariesAccounts.cost_per_link_click,
        rate_link_click: summariesAccounts.impressions
          ? (summariesAccounts.link_click / summariesAccounts.impressions) * 100 || 0
          : 0,
        conversations_started:
          summariesAccounts['onsite_conversion.messaging_conversation_started_7d'] || 0,
        conversations_new: summariesAccounts['onsite_conversion.messaging_first_reply'] || 0,
        cost_per_conversations_started:
          summariesAccounts['cost_per_onsite_conversion.messaging_conversation_started_7d'] || 0,
        cost_per_conversations_new:
          summariesAccounts['cost_per_onsite_conversion.messaging_first_reply'] || 0,
        offline_purchase: summariesAccounts['offline_conversion.purchase'] || 0,
        cost_per_offline_purchase: summariesAccounts['cost_per_offline_conversion.purchase'] || 0,
        offline_initiate_checkout: summariesAccounts['offline_conversion.initiate_checkout'] || 0,
        cost_per_offline_initiate_checkout:
          summariesAccounts['cost_per_offline_conversion.initiate_checkout'] || 0,
        offline_initiate_checkout_conversion_value:
          summariesAccounts['offline_conversion.initiate_checkout_conversion_value'] || 0,
        offline_initiate_checkout_return_on_advertising_spend: summariesAccounts.spend
          ? summariesAccounts['offline_conversion.initiate_checkout_conversion_value'] /
              summariesAccounts.spend || 0
          : 0,
        revenue: summariesAccounts['offsite_conversion.fb_pixel_purchase_conversion_value'] || 0,
        return_on_advertising_spend: summariesAccounts.spend
          ? summariesAccounts['offsite_conversion.fb_pixel_purchase_conversion_value'] /
              summariesAccounts.spend || 0
          : 0,
        offline_conversion_value:
          summariesAccounts['offline_conversion.purchase_conversion_value'] || 0,

        offline_return_on_advertising_spend: summariesAccounts.spend
          ? summariesAccounts['offline_conversion.purchase_conversion_value'] /
              summariesAccounts.spend || 0
          : 0,
      };

      return {
        ...state,
        listAll: {
          ...state.listAll,
          data: {
            total: mountedTotalAccount,
            body: mountedBodyAccounts,
          },
          isLoading: false,
          message: null,
          status: action.status,
        },
      };

    case GET_LIST_ALL_ACCOUNTS.FAILURE:
      return {
        ...state,
        listAll: {
          ...state.listAll,
          data: INITIAL_STATE.listAll.data,
          isLoading: false,
          message: 'An error occurred try to make a request.',
          status: action.status,
        },
      };

    case GET_LIST_ALL_ACCOUNTS.RESET:
      return {
        ...state,
        listAll: {
          ...state.listAll,
          data: INITIAL_STATE.listAll.data,
        },
      };

    case GET_LIST_ALL_PAGES.REQUEST:
      return {
        ...state,
        listAll: {
          ...state.listAll,
          data: INITIAL_STATE.listAll.data,
          isLoading: true,
          message: null,
          status: null,
        },
      };

    case GET_LIST_ALL_PAGES.SUCCESS:
      const dataPages = action.data;
      const summariesPages = action.summaries;
      const accountPages = action.account;

      const mountedBodyPages = [];

      if (accountPages.length) {
        dataPages.forEach((item) => {
          const engagement =
            (item.page_actions_post_reactions_like_total || 0) +
            (item.page_actions_post_reactions_love_total || 0) +
            (item.page_actions_post_reactions_wow_total || 0) +
            (item.page_actions_post_reactions_haha_total || 0) +
            (item.page_actions_post_reactions_sorry_total || 0) +
            (item.page_actions_post_reactions_anger_total || 0);

          mountedBodyPages.push({
            name: accountPages.find((x) => x.source_id === item.source_id).name || '',
            source_id: item.source_id,
            impressions: item.page_impressions_organic || 0,
            videoViews: item.page_video_views_organic || 0,
            engagement: engagement || 0,
            rate_engagement: item.page_impressions_organic
              ? (engagement / item.page_impressions_organic) * 100 || 0
              : 0,
            reach: null,
            type: 'page',
          });
        });
      }

      const engagement =
        (summariesPages.page_actions_post_reactions_like_total || 0) +
        (summariesPages.page_actions_post_reactions_love_total || 0) +
        (summariesPages.page_actions_post_reactions_wow_total || 0) +
        (summariesPages.page_actions_post_reactions_haha_total || 0) +
        (summariesPages.page_actions_post_reactions_sorry_total || 0) +
        (summariesPages.page_actions_post_reactions_anger_total || 0);

      const mountedTotalPages = {
        name: 'Total',
        impressions: summariesPages.page_impressions_organic || 0,
        videoViews: summariesPages.page_video_views_organic || 0,
        engagement: engagement || 0,
        rate_engagement: summariesPages.page_impressions_organic
          ? (engagement / summariesPages.page_impressions_organic) * 100 || 0
          : 0,
        reach: null,
      };

      return {
        ...state,
        listAll: {
          ...state.listAll,
          data: {
            total: mountedTotalPages,
            body: mountedBodyPages,
          },
          isLoading: false,
          message: null,
          status: action.status,
        },
      };

    case GET_LIST_ALL_PAGES.FAILURE:
      return {
        ...state,
        listAll: {
          ...state.listAll,
          data: INITIAL_STATE.listAll.data,
          isLoading: false,
          message: 'An error occurred try to make a request.',
          status: action.status,
        },
      };

    case GET_LIST_ALL_PAGES.RESET:
      return {
        ...state,
        listAll: {
          ...state.listAll,
          data: INITIAL_STATE.listAll.data,
        },
      };

    case GET_RANKING_GROUPS.REQUEST:
      return {
        ...state,
        ranking: {
          ...state.ranking,
          data: INITIAL_STATE.ranking.data,
          isLoading: true,
          message: null,
          status: null,
        },
      };

    case GET_RANKING_GROUPS.SUCCESS:
      return {
        ...state,
        ranking: {
          data: {
            best: action.best || [],
            worst: action.worst || [],
            metric: action.metric || null,
          },
          isLoading: false,
          message: null,
          status: null,
        },
      };

    case GET_RANKING_GROUPS.FAILURE:
      return {
        ...state,
        ranking: {
          ...state.ranking,
          data: INITIAL_STATE.ranking.data,
          isLoading: false,
          message: 'An error occurred try to make a request.',
          status: action.status,
        },
      };

    case GET_RANKING_GROUPS.RESET:
      return {
        ...state,
        ranking: INITIAL_STATE.ranking,
      };

    case GET_RANKING_ACCOUNTS.REQUEST:
      return {
        ...state,
        ranking: {
          ...state.ranking,
          data: INITIAL_STATE.ranking.data,
          isLoading: true,
          message: null,
          status: null,
        },
      };

    case GET_RANKING_ACCOUNTS.SUCCESS:
      return {
        ...state,
        ranking: {
          data: {
            best: action.best || [],
            worst: action.worst || [],
            metric: action.metric || null,
          },
          isLoading: false,
          message: null,
          status: null,
        },
      };

    case GET_RANKING_ACCOUNTS.FAILURE:
      return {
        ...state,
        ranking: {
          ...state.ranking,
          data: INITIAL_STATE.ranking.data,
          isLoading: false,
          message: 'An error occurred try to make a request.',
          status: action.status,
        },
      };

    case GET_RANKING_ACCOUNTS.RESET:
      return {
        ...state,
        ranking: INITIAL_STATE.ranking,
      };

    case GET_RANKING_PAGES.REQUEST:
      return {
        ...state,
        ranking: {
          ...state.ranking,
          data: INITIAL_STATE.ranking.data,
          isLoading: true,
          message: null,
          status: null,
        },
      };

    case GET_RANKING_PAGES.SUCCESS:
      return {
        ...state,
        ranking: {
          data: {
            best: action.best || [],
            worst: action.worst || [],
            metric: action.metric || null,
          },
          isLoading: false,
          message: null,
          status: null,
        },
      };

    case GET_RANKING_PAGES.FAILURE:
      return {
        ...state,
        ranking: {
          ...state.ranking,
          data: INITIAL_STATE.ranking.data,
          isLoading: false,
          message: 'An error occurred try to make a request.',
          status: action.status,
        },
      };

    case GET_RANKING_PAGES.RESET:
      return {
        ...state,
        ranking: INITIAL_STATE.ranking,
      };

    case GET_RANKING.REQUEST:
      return {
        ...state,
        ranking: {
          ...state.ranking,
          data: {
            ...state.ranking.data,
            best: [],
            worst: [],
            metric: null,
          },
          isLoading: true,
          message: null,
          status: null,
        },
      };

    case GET_RANKING.SUCCESS:
      return {
        ...state,
        ranking: {
          ...state.ranking,
          data: {
            ...state.ranking.data,
            ...action.ranking,
            metric: action.metric,
          },
          groups: action.groups,
          isLoading: false,
          message: null,
          status: null,
        },
      };

    case GET_RANKING.FAILURE:
      return {
        ...state,
        ranking: {
          ...state.ranking,
          data: {
            best: [],
            worst: [],
            metric: 'spend',
            size: 10,
          },
          isLoading: false,
          message: null,
          status: null,
        },
      };

    case GET_RANKING.RESET:
      return {
        ...state,
        ranking: {
          ...state.ranking,
          data: {
            best: [],
            worst: [],
            metric: 'spend',
            size: 10,
          },
          isLoading: false,
          message: null,
          status: null,
        },
      };
    case CHANGE_METRIC_CHART.SUCCESS:
      return {
        ...state,
        ranking: {
          ...state.ranking,
          chart: {
            ...state.ranking.chart,
            content: action.chart,
          },
        },
      };

    case CHANGE_COMMENTS_METRIC_CHART.SUCCESS:
      return {
        ...state,
        commentsRankEvolution: {
          ...state.commentsRankEvolution,
          chart: {
            ...state.commentsRankEvolution.chart,
            content: action.chart,
          },
        },
      };

    case GET_AUDIENCE_AGE_GENDER.REQUEST:
      return {
        ...state,
        audience: {
          ...state.audience,
          ageGender: {
            data: INITIAL_STATE.audience.ageGender.data,
            isLoading: true,
            message: null,
            status: null,
          },
        },
      };

    case GET_AUDIENCE_AGE_GENDER.SUCCESS:
      const dataAction = action.data;

      return {
        ...state,
        audience: {
          ...state.audience,
          ageGender: {
            data: dataAction,
            isLoading: false,
            message: null,
            status: action.status,
          },
        },
      };

    case GET_AUDIENCE_AGE_GENDER.FAILURE:
      return {
        ...state,
        audience: {
          ...state.audience,
          ageGender: {
            data: INITIAL_STATE.audience.ageGender.data,
            isLoading: false,
            message: action.message,
            status: action.status,
          },
        },
      };

    case GET_AUDIENCE_AGE_GENDER.RESET:
      return {
        ...state,
        audience: {
          ...state.audience,
          ageGender: INITIAL_STATE.audience.ageGender,
        },
      };

    case GET_AUDIENCE_CHANNEL.REQUEST:
      return {
        ...state,
        audience: {
          ...state.audience,
          channel: {
            data: INITIAL_STATE.audience.channel.data,
            isLoading: true,
            message: null,
            status: null,
          },
        },
      };

    case GET_AUDIENCE_CHANNEL.SUCCESS:
      return {
        ...state,
        audience: {
          ...state.audience,
          channel: {
            data: action.data,
            isLoading: false,
            message: null,
            status: action.status,
          },
        },
      };

    case GET_AUDIENCE_CHANNEL.FAILURE:
      return {
        ...state,
        audience: {
          ...state.audience,
          channel: {
            data: INITIAL_STATE.audience.channel.data,
            isLoading: false,
            message: action.message,
            status: action.status,
          },
        },
      };

    case GET_AUDIENCE_CHANNEL.RESET:
      return {
        ...state,
        audience: {
          ...state.audience,
          channel: INITIAL_STATE.audience.channel,
        },
      };

    case GET_AUDIENCE_DEVICE.REQUEST:
      return {
        ...state,
        audience: {
          ...state.audience,
          device: {
            data: INITIAL_STATE.audience.device.data,
            isLoading: true,
            message: null,
            status: null,
          },
        },
      };

    case GET_AUDIENCE_DEVICE.SUCCESS:
      return {
        ...state,
        audience: {
          ...state.audience,
          device: {
            data: action.data,
            isLoading: false,
            message: null,
            status: action.status,
          },
        },
      };

    case GET_AUDIENCE_DEVICE.FAILURE:
      return {
        ...state,
        audience: {
          ...state.audience,
          device: {
            data: INITIAL_STATE.audience.device.data,
            isLoading: false,
            message: action.message,
            status: action.status,
          },
        },
      };

    case GET_AUDIENCE_DEVICE.RESET:
      return {
        ...state,
        audience: {
          ...state.audience,
          device: INITIAL_STATE.audience.device,
        },
      };

    case RENAME_GROUP.SUCCESS:
      const renamedGroup = listAllDataBody.find((item) => item.source_id === action.sourceId);
      if (renamedGroup) {
        renamedGroup.name = action.name;
      }
      return {
        ...state,
        listAll: {
          ...state.listAll,
          data: {
            ...state.listAll.data,
            body: listAllDataBody,
          },
        },
      };

    case REPLACE_ACCOUNT.SUCCESS:
      const replacedAccount = listAllDataBody.find((item) => item.source_id === action.oldId);
      if (replacedAccount) {
        replacedAccount.source_id = action.sourceId;
      }
      return {
        ...state,
        listAll: {
          ...state.listAll,
          data: {
            ...state.listAll.data,
            body: listAllDataBody,
          },
        },
      };

    case REPLACE_PAGE.SUCCESS:
      const replacedPage = listAllDataBody.find((item) => item.source_id === action.oldId);
      if (replacedPage) {
        replacedPage.source_id = action.sourceId;
      }

      return {
        ...state,
        listAll: {
          ...state.listAll,
          data: {
            ...state.listAll.data,
            body: listAllDataBody,
          },
        },
      };

    case DELETE_GROUP.SUCCESS:
      listAllDataBody = listAllDataBody.filter((item) => item.source_id !== action.sourceId);
      return {
        ...state,
        listAll: {
          ...state.listAll,
          data: {
            ...state.listAll.data,
            body: [...listAllDataBody],
          },
        },
      };

    case CHANGE_GROUP_COLOR.SUCCESS:
      const changedGroupColor = listAllGroupColors.find(
        (item) => item.sourceID === action.sourceId
      );

      if (changedGroupColor) {
        changedGroupColor.color = action.color;
      }

      return {
        ...state,
        listAll: {
          ...state.listAll,
          groupColors: [...listAllGroupColors],
        },
      };

    case CREATE_GROUP.SUCCESS:
      const newGroup = {
        name: action.data.name,
        source_id: action.source_id,
        impressions: 0,
        videoViews: 0,
        engagement: 0,
        rate_engagement: 0,
        involvement: 0,
        rate_involvement: 0,
        spend: 0,
        reach: null,
        color: action.data.color,
        type: 'group',
      };
      listAllDataBody.push(newGroup);
      if (action.currentCode === 'root') {
        const newColor = {
          sourceID: action.source_id,
          color: action.data.color,
        };
        listAllGroupColors.push(newColor);
      }
      return {
        ...state,
        listAll: {
          ...state.listAll,
          data: {
            ...state.listAll.data,
            body: listAllDataBody,
          },
          groupColors: [...listAllGroupColors],
        },
      };

    case GET_GROUP_COLORS.SUCCESS:
      return {
        ...state,
        listAll: {
          ...state.listAll,
          groupColors: action.colors,
        },
      };

    case GET_CHART_RANKING.REQUEST:
      return {
        ...state,
        ranking: {
          ...state.ranking,
          chart: {
            content: [],
            error: '',
            isLoading: true,
            idSelected: null,
          },
        },
      };

    case GET_CHART_RANKING.SUCCESS:
      return {
        ...state,
        ranking: {
          ...state.ranking,
          charts: action.charts,
          chart: {
            content: action.chart,
            error: '',
            isLoading: false,
            idSelected: action.id,
          },
        },
      };

    case GET_CHART_RANKING.FAILURE:
      return {
        ...state,
        ranking: {
          ...state.ranking,
          chart: {
            content: [],
            error: action.chartError,
            isLoading: false,
            idSelected: null,
          },
        },
      };

    case GET_CHART_RANKING.RESET:
      return {
        ...state,
        ranking: {
          ...state.ranking,
          chart: {
            content: [],
            error: '',
            isLoading: false,
            idSelected: null,
          },
        },
      };

    case POST_REPORT_AD.REQUEST:
      return {
        ...state,
        engagement: {
          ...state.engagement,
          data: {
            ...state.engagement.data,
            ad: {
              content: [],
              evolution: [],
            },
          },
          summary: {},
          isLoading: true,
          message: null,
          status: null,
        },
      };

    case POST_REPORT_AD.SUCCESS:
      return {
        ...state,
        engagement: {
          ...state.engagement,
          data: {
            ...state.engagement.data,
            ad: {
              ...state.engagement.data.ad,
              content: !!action.ad && !!action.ad[0] ? action.ad[0].periods : [],
            },
          },
          summary: action.summary,
          isLoading: false,
          message: null,
          status: action.status,
        },
      };

    case POST_REPORT_AD.FAILURE:
      return {
        ...state,
        engagement: {
          ...state.engagement,
          data: {
            ...state.engagement.data,
            ad: {
              content: [],
              evolution: [],
            },
          },
          summary: {},
          isLoading: false,
          message: null,
          status: action.status,
        },
      };

    case POST_REPORT_PAGE_FACEBOOK.REQUEST:
      return {
        ...state,
        engagement: {
          ...state.engagement,
          data: {
            ...state.engagement.data,
            facebook: {
              content: [],
              evolution: [],
            },
          },
          isLoading: true,
          message: null,
          status: null,
        },
      };

    case POST_REPORT_PAGE_FACEBOOK.SUCCESS:
      return {
        ...state,
        engagement: {
          ...state.engagement,
          data: {
            ...state.engagement.data,
            facebook: {
              ...state.engagement.data.facebook,
              content: action.page,
            },
          },
          isLoading: false,
          message: null,
          status: action.status,
        },
      };

    case POST_REPORT_PAGE_FACEBOOK.FAILURE:
      return {
        ...state,
        engagement: {
          ...state.engagement,
          data: {
            ...state.engagement.data,
            facebook: {
              content: [],
              evolution: [],
            },
          },
          isLoading: false,
          message: null,
          status: action.status,
        },
      };

    case POST_REPORT_PAGE_INSTAGRAM.REQUEST:
      return {
        ...state,
        engagement: {
          ...state.engagement,
          data: {
            ...state.engagement.data,
            instagram: {
              content: [],
              evolution: [],
            },
          },
          isLoading: true,
          message: null,
          status: null,
        },
      };

    case POST_REPORT_PAGE_INSTAGRAM.SUCCESS:
      return {
        ...state,
        engagement: {
          ...state.engagement,
          data: {
            ...state.engagement.data,
            instagram: {
              ...state.engagement.data.instagram,
              content: action.page,
            },
          },
          isLoading: false,
          message: null,
          status: action.status,
        },
      };

    case POST_REPORT_PAGE_INSTAGRAM.FAILURE:
      return {
        ...state,
        engagement: {
          ...state.engagement,
          data: {
            ...state.engagement.data,
            instagram: {
              content: [],
              evolution: [],
            },
          },
          isLoading: false,
          message: null,
          status: action.status,
        },
      };

    case POST_REPORT_AD_EVOLUTION.REQUEST:
      return {
        ...state,
        engagement: {
          ...state.engagement,
          data: {
            ...state.engagement.data,
            ad: {
              ...state.engagement.data.ad,
              evolution: [],
            },
          },
          isLoading: true,
          message: null,
          status: null,
        },
      };

    case POST_REPORT_AD_EVOLUTION.SUCCESS:
      return {
        ...state,
        engagement: {
          ...state.engagement,
          data: {
            ...state.engagement.data,
            ad: {
              ...state.engagement.data.ad,
              evolution: action.ad,
            },
          },
          isLoading: false,
          message: null,
          status: action.status,
        },
      };

    case POST_REPORT_AD_EVOLUTION.FAILURE:
      return {
        ...state,
        engagement: {
          ...state.engagement,
          data: {
            ...state.engagement.data,
            ad: {
              ...state.engagement.data.ad,
              evolution: [],
            },
          },
          isLoading: false,
          message: null,
          status: action.status,
        },
      };

    case POST_REPORT_PAGE_EVOLUTION_FACEBOOK.REQUEST:
      return {
        ...state,
        engagement: {
          ...state.engagement,
          data: {
            ...state.engagement.data,
            facebook: {
              ...state.engagement.data.facebook,
              evolution: [],
            },
          },
          isLoading: true,
          message: null,
          status: null,
        },
      };

    case POST_REPORT_PAGE_EVOLUTION_FACEBOOK.SUCCESS:
      return {
        ...state,
        engagement: {
          ...state.engagement,
          data: {
            ...state.engagement.data,
            facebook: {
              ...state.engagement.data.facebook,
              evolution: action.page,
            },
          },
          isLoading: false,
          message: null,
          status: action.status,
        },
      };

    case POST_REPORT_PAGE_EVOLUTION_FACEBOOK.FAILURE:
      return {
        ...state,
        engagement: {
          ...state.engagement,
          data: {
            ...state.engagement.data,
            facebook: {
              ...state.engagement.data.facebook,
              evolution: [],
            },
          },
          isLoading: false,
          message: null,
          status: action.status,
        },
      };

    case POST_REPORT_PAGE_EVOLUTION_INSTAGRAM.REQUEST:
      return {
        ...state,
        engagement: {
          ...state.engagement,
          data: {
            ...state.engagement.data,
            instagram: {
              ...state.engagement.data.instagram,
              evolution: [],
            },
          },
          isLoading: true,
          message: null,
          status: null,
        },
      };

    case POST_REPORT_PAGE_EVOLUTION_INSTAGRAM.SUCCESS:
      return {
        ...state,
        engagement: {
          ...state.engagement,
          data: {
            ...state.engagement.data,
            instagram: {
              ...state.engagement.data.instagram,
              evolution: action.page,
            },
          },
          isLoading: false,
          message: null,
          status: action.status,
        },
      };

    case POST_REPORT_PAGE_EVOLUTION_INSTAGRAM.FAILURE:
      return {
        ...state,
        engagement: {
          ...state.engagement,
          data: {
            ...state.engagement.data,
            instagram: {
              ...state.engagement.data.instagram,
              evolution: [],
            },
          },
          isLoading: false,
          message: null,
          status: action.status,
        },
      };

    case POST_ENGAGEMENT_BEST.REQUEST:
      return {
        ...state,
        engagement: {
          ...state.engagement,
          data: {
            ...state.engagement.data,
            top: [],
            topLoading: true,
          },
        },
      };

    case POST_ENGAGEMENT_BEST.SUCCESS:
      return {
        ...state,
        engagement: {
          ...state.engagement,
          data: {
            ...state.engagement.data,
            top: action.top,
            topLoading: false,
          },
          isLoading: false,
          message: null,
          status: null,
        },
      };

    case POST_ENGAGEMENT_BEST.FAILURE:
      return {
        ...state,
        engagement: {
          ...state.engagement,
          data: {
            ...state.engagement.data,
            top: [],
            topLoading: false,
          },
          isLoading: false,
          message: null,
          status: null,
        },
      };

    case POST_PURCHASE_BEST.REQUEST:
      return {
        ...state,
        engagement: {
          ...state.engagement,
          data: {
            ...state.engagement.data,
            topPurchase: [],
            topPurchaseLoading: true,
          },
        },
      };

    case POST_PURCHASE_BEST.SUCCESS:
      return {
        ...state,
        engagement: {
          ...state.engagement,
          data: {
            ...state.engagement.data,
            topPurchase: action.topPurchase,
            topPurchaseLoading: false,
          },
          isLoading: false,
          message: null,
          status: null,
        },
      };

    case POST_PURCHASE_BEST.FAILURE:
      return {
        ...state,
        engagement: {
          ...state.engagement,
          data: {
            ...state.engagement.data,
            topPurchase: [],
            topPurchaseLoading: false,
          },
          isLoading: false,
          message: null,
          status: null,
        },
      };

    case GET_INFO_GROUPS.REQUEST:
      return {
        ...state,
        engagement: {
          ...state.engagement,
          data: {
            ...state.engagement.data,
            topInfo: [],
            topLoading: true,
          },
        },
      };

    case GET_INFO_GROUPS.SUCCESS:
      return {
        ...state,
        engagement: {
          ...state.engagement,
          data: {
            ...state.engagement.data,
            topInfo: action.data,
            topLoading: false,
          },
        },
      };

    case GET_INFO_GROUPS.FAILURE:
      return {
        ...state,
        engagement: {
          ...state.engagement,
          data: {
            ...state.engagement.data,
            topInfo: [],
            topLoading: false,
          },
        },
      };
    case GET_SUMMARY.REQUEST:
      return {
        ...state,
        summary: {
          isLoading: true,
        },
      };

    case GET_SUMMARY.SUCCESS:
      return {
        ...state,
        summary: {
          data: action.summary,
          isLoading: false,
        },
      };

    case GET_SUMMARY.FAILURE:
      return {
        ...state,
        summary: {
          error: action.error,
          isLoading: false,
        },
      };

    case POST_INSIGHTS_METRICS.REQUEST:
      return {
        ...state,
        custom: {
          ...state.custom,
          data: [],
        },
      };

    case POST_INSIGHTS_METRICS.SUCCESS:
      const data = [];
      if (action.custom && action.custom.length > 0) {
        action.custom.forEach((item) => {
          data.push({
            source_id: item.source_id,
            spend: item.spend || 0,
            onsite_conversion_purchase_value: item.onsite_conversion_purchase_value || 0,
            offline_conversion_purchase_value: item.offline_conversion_purchase_value || 0,
            offsite_conversion_fb_pixel_initiate_checkout_value:
              item.offsite_conversion_fb_pixel_initiate_checkout_value || 0,
            offsite_conversion_fb_pixel_purchase_value:
              item.offsite_conversion_fb_pixel_purchase_value || 0,
            post_engagement_view: item.post_engagement_view || 0,
            onsite_conversion_add_to_cart: item.onsite_conversion_add_to_cart || 0,
            onsite_conversion_add_to_wishlist: item.onsite_conversion_add_to_wishlist || 0,
            onsite_conversion_lead_grouped: item.onsite_conversion_lead_grouped || 0,
            onsite_conversion_messaging_conversation_started_7d:
              item.onsite_conversion_messaging_conversation_started_7d || 0,
            onsite_conversion_purchase: item.onsite_conversion_purchase || 0,
            offline_conversion_lead: item.offline_conversion_lead || 0,
            offline_conversion_other: item.offline_conversion_other || 0,
            offline_conversion_purchase: item.offline_conversion_purchase || 0,
            offsite_conversion_fb_pixel_initiate_checkout:
              item.offsite_conversion_fb_pixel_initiate_checkout || 0,
            offsite_conversion_fb_pixel_lead: item.offsite_conversion_fb_pixel_lead || 0,
            offsite_conversion_fb_pixel_purchase: item.offsite_conversion_fb_pixel_purchase || 0,
            offline_conversion_add_to_cart: item.offline_conversion_add_to_cart || 0,
            impressions: item.impressions || 0,
            clicks: item.clicks || 0,
            inline_link_clicks: item.inline_link_clicks || 0,
            inline_post_engagement: item.inline_post_engagement || 0,
            offsite_conversion_fb_pixel_view_content:
              item.offsite_conversion_fb_pixel_view_content || 0,
            post_reaction: item.post_reaction || 0,
            photo_view: item.photo_view || 0,
            comment: item.comment || 0,
            post: item.post || 0,
            like: item.like || 0,
            link_click: item.link_click || 0,
            link_click_rate: item.link_click_rate || 0,
            post_engagement: item.post_engagement || 0,
            post_engagement_rate: item.post_engagement_rate || 0,
            offsite_conversion_fb_pixel_add_to_cart:
              item.offsite_conversion_fb_pixel_add_to_cart || 0,
            offsite_conversion_fb_pixel_initiated_checkout:
              item.offsite_conversion_fb_pixel_initiated_checkout || 0,
            video_view: item.video_view || 0,
            video_view_rate: item.video_view_rate || 0,
            currency: item.currency || 'BRL',
            start: item.start || '',
            end: item.end || '',
            ['offsite_conversion.fb_pixel_purchase']:
              item?.['offsite_conversion.fb_pixel_purchase'] ?? 0,
            ['offsite_conversion.fb_pixel_purchase_conversion_value']:
              item?.['offsite_conversion.fb_pixel_purchase_conversion_value'] ?? 0,
            ['offsite_conversion.fb_pixel_purchase_roas']:
              item?.['offsite_conversion.fb_pixel_purchase_roas'] ?? 0,
            ['offline_conversion.purchase']:
              item?.['offsite_conversion.fb_pixel_purchase_roas'] ?? 0,
            ['offline_conversion.purchase_conversion_value']:
              item?.['offline_conversion.purchase_conversion_value'] ?? 0,
            ['offline_conversion.purchase_roas']: item?.['offline_conversion.purchase_roas'] ?? 0,
          });
        });
      }
      return {
        ...state,
        custom: {
          ...state.custom,
          data,
          error: '',
          isLoading: false,
        },
      };

    case POST_INSIGHTS_METRICS.FAILURE:
      return {
        ...state,
        custom: {
          ...state.custom,
          data: [],
          error: action.error,
          isLoading: false,
        },
      };

    case POST_INSIGHTS_METRICS.RESET:
      return {
        ...state,
        custom: {
          ...state.custom,
          data: [],
          error: '',
          isLoading: false,
        },
      };

    case GET_ACTIVITY_METRICS.REQUEST:
      return {
        ...state,
        customActivity: {
          ...state.customActivity,
          data: [],
        },
      };

    case GET_ACTIVITY_METRICS.SUCCESS:
      const dataActivity = state.customActivity.data;
      if (action.customActivity && action.customActivity.length > 0) {
        action.customActivity.forEach((item) => {
          dataActivity.push({
            units_with_posts: item.units_with_posts || 0,
            units_without_posts: item.units_without_posts || 0,
            units_with_ads: item.units_with_ads || 0,
            units_without_ads: item.units_without_ads || 0,
            unit_ads_average: item.unit_ads_average || 0,
            unit_posts_average: item.unit_posts_average || 0,
            ad_count: item.ad_count || 0,
            post_count: item.post_count || 0,
            ad_percentage: item.ad_percentage || 0,
            post_percentage: item.post_percentage || 0,
            units_count: item.units_count || 0,
            units_with_posts_and_ads: item.units_with_posts_and_ads || 0,
            units_with_posts_or_ads: item.units_with_posts_or_ads || 0,
            currency: item.currency || 0,
            start: item.key || '',
            end: item.key || '',
          });
        });
      }
      return {
        ...state,
        customActivity: {
          ...state.customActivity,
          data: dataActivity,
          error: '',
          isLoading: false,
        },
      };

    case GET_ACTIVITY_METRICS.FAILURE:
      return {
        ...state,
        customActivity: {
          ...state.customActivity,
          data: [],
          error: action.error,
          isLoading: false,
        },
      };

    case GET_ACTIVITY_METRICS.RESET:
      return {
        ...state,
        customActivity: {
          ...state.customActivity,
          data: [],
          error: '',
          isLoading: false,
        },
      };

    case GET_BUDGET_METRICS.REQUEST:
      return {
        ...state,
        customBudget: {
          ...state.customBudget,
          data: [],
        },
      };

    case GET_BUDGET_METRICS.SUCCESS:
      const dataBudget = [];
      if (action.customBudget && action.customBudget.length > 0) {
        action.customBudget.forEach((item) => {
          dataBudget.push({
            allocated: item.allocated || 0,
            spend: item.spend || 0,
            perSpend: item.per_spend || 0,
            fundingId: item.funding_id || 0,
            start: item.since || '',
            end: item.until || '',
          });
        });
      }
      return {
        ...state,
        customBudget: {
          ...state.customBudget,
          data: dataBudget,
          error: '',
          isLoading: false,
        },
      };

    case GET_BUDGET_METRICS.FAILURE:
      return {
        ...state,
        customBudget: {
          ...state.customBudget,
          data: [],
          error: action.error,
          isLoading: false,
        },
      };

    case GET_BUDGET_METRICS.RESET:
      return {
        ...state,
        customBudget: {
          ...state.customBudget,
          data: [],
          error: '',
          isLoading: false,
        },
      };

    case GET_ACTIVITIES_GROUPED_BY_DAY.REQUEST:
      return {
        ...state,
        activitiesGrouped: {
          ...state.activitiesGrouped,
          data: [],
        },
      };

    case GET_ACTIVITIES_GROUPED_BY_DAY.SUCCESS:
      return {
        ...state,
        activitiesGrouped: {
          ...state.activitiesGrouped,
          data: action.activitiesGrouped,
          error: '',
          isLoading: false,
        },
      };

    case GET_ACTIVITIES_GROUPED_BY_DAY.FAILURE:
      return {
        ...state,
        activitiesGrouped: {
          ...state.activitiesGrouped,
          data: [],
          error: action.error,
          isLoading: false,
        },
      };

    case GET_ACTIVITIES_GROUPED_BY_DAY.RESET:
      return {
        ...state,
        activitiesGrouped: {
          ...state.activitiesGrouped,
          data: [],
          error: '',
          isLoading: false,
        },
      };

    default:
      return state;
  }
};

export default reportReducer;
