import React from 'react';
import { useRouteMatch, Redirect } from 'react-router-dom';
import pathToRegexp from 'path-to-regexp';

export default function RedirectWithParams({ to, push }) {
  const match = useRouteMatch();

  if (!match) {
    return null;
  }

  const pathTo = pathToRegexp.compile(to);
  return <Redirect to={pathTo(match.params)} push={push} />;
}
