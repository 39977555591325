import { TYPOGRAPHY_VARIANTS, Typography } from '@bornlogic/reborn';
import React from 'react';
import { LogModal, LogContainer, Divider } from './styles';
import { useTranslate } from 'react-translate';

export default function ErrorLogModal({ errorLog, onClose }) {
  const t = useTranslate('Units')
  const isOpen = !!errorLog?.logs?.length;

  if (!isOpen) return null

  return (
    <>
      <LogModal
        isOpen={isOpen}
        ariaHideApp={false}
        shouldCloseOnOverlayClick
        shouldFocusAfterRender
        onRequestClose={onClose}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            zIndex: 10000,
          }
        }}
      >
        <Typography typography={TYPOGRAPHY_VARIANTS.h5}>{t('ERROR_LOG_TITLE')}</Typography>
        <Typography typography={TYPOGRAPHY_VARIANTS.subtitle1}>{t('ERROR_LOG_DESCRIPTION')}</Typography>
        <LogContainer>
          {errorLog?.logs?.map(({ message, pageId }) => (
            <>
              <Typography typography={TYPOGRAPHY_VARIANTS.paragraph1}>{`${t('ERROR_LOG_PAGE_ID')} ${pageId}`}
              </Typography>
              <Typography typography={TYPOGRAPHY_VARIANTS.body3}>
                {t(message)}</Typography>
              <Divider />
            </>
          ))}
        </LogContainer>
      </LogModal >
    </>
  );
}
