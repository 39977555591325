import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate } from 'react-translate';
import { ModalDefault, Title, Select } from '@bornlogic/gd-design-system';
import { SHOW_TOAST } from '@redux/toast/types';
import SettingsService from '../../../../services/settings';
import GroupsPagesAccountsMultiSelectCheckbox from '../../../../components/GroupsPagesAccountsMultiSelectCheckbox';
import * as S from './styles';

const ReplicateLeads = ({ isOpen, onClose, page_id }) => {
  const [forms, setForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);
  const [selectedLeafs, setSelectedLeafs] = useState([]);

  const dispatch = useDispatch();
  const t = useTranslate('Units');

  const normalizeForms = (data) => {
    const result = [];

    data.forEach((item) => {
      result.push({
        title: item.name,
        value: item.id,
      });
    });

    return result;
  };

  const loadForms = async () => {
    const response = await SettingsService.getForms(page_id);

    if (response.data && response.data.length > 0) {
      setForms(normalizeForms(response.data));
    }
  };

  const _handleSelect = (value) => {
    setSelectedForm(value);
  };

  const cleanUp = () => {
    setForms([]);
    setSelectedForm(null);
  };

  const cleanUpAndClose = () => {
    cleanUp();
    onClose();
  };

  const _handleReplicateAudience = async () => {
    if (!selectedForm) {
      dispatch({
        type: SHOW_TOAST.SUCCESS,
        message: `${t('SELECT_FORM')}!`,
        messageType: 'error',
      });
      return;
    }

    if (!selectedLeafs || selectedLeafs.length === 0) {
      dispatch({
        type: SHOW_TOAST.SUCCESS,
        message: t('INVALID_GROUPS_SELECION'),
        messageType: 'error',
      });
      return;
    }

    const arrAdAccountIds = [];

    selectedLeafs.forEach((item) => {
      arrAdAccountIds.push(item.source_id);
    });

    const group_ids = [...arrAdAccountIds];
    const body = {
      source_page_id: page_id,
      source_form_id: selectedForm,
      group_ids,
    };

    const response = await SettingsService.replicateLeads(body);

    if (response.status === 200) {
      cleanUpAndClose();
      dispatch({
        type: SHOW_TOAST.SUCCESS,
        message: t('REPLICATE_LEADS_SUCCESS'),
        messageType: 'success',
      });
    }
  };

  useEffect(() => {
    if (page_id !== '') {
      loadForms();
    }
  }, [page_id]);

  useEffect(() => {
    if (page_id !== '') {
      loadForms();
    }
  }, [isOpen]);

  const totalAvailable = 0;

  return (
    <ModalDefault
      isOpen={isOpen}
      title={t('REPLICATE_LEADS')}
      confirmBtnText={t('CONFIRM')}
      onConfirmBtnClick={_handleReplicateAudience}
      confirmBtnDisabled={false}
      optionBtnText={t('CANCEL')}
      onOptionBtnClick={cleanUpAndClose}
      thirdBtnDisabled
      onClose={() => onClose()}
      size="40%"
      maxHeight="90%"
      subTitle={`${t('REPLICATE_LEADS_SUBTITLE1')} ${totalAvailable} ${t(
        'REPLICATE_LEADS_SUBTITLE2'
      )}`}
    >
      <S.Container>
        <S.InputWrapper>
          <S.LabelSpace>
            <Title as="h6">{t('FORM')}</Title>
          </S.LabelSpace>
          <Select
            items={forms}
            onChange={_handleSelect}
            value={selectedForm}
            placeholder={t('SELECT_FORM')}
          />
        </S.InputWrapper>
        <S.InputWrapper>
          <S.LabelSpace>
            <Title as="h6">{t('UNITS_OPTION')}</Title>
          </S.LabelSpace>
          <GroupsPagesAccountsMultiSelectCheckbox
            onSelectLeafs={(data) => setSelectedLeafs(data)}
          />
        </S.InputWrapper>
      </S.Container>
    </ModalDefault>
  );
};

export default ReplicateLeads;
