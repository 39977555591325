import styled from 'styled-components';
import { primaryTheme as theme } from '@styles/themes';

export const SidebarWrapper = styled.div`
  padding-top: ${theme.spacing.px15};
  @media screen and (max-width: ${theme.breakpoint.xl}) {
    display: ${({ showSidebar }) => (showSidebar ? 'block' : 'none')};
  }
`;

export const LinksWrapper = styled.div`
  min-width: 170px;
  position: relative;
`;
