import React, { useContext } from 'react';
import { useTranslate } from 'react-translate';

import { Text } from '@bornlogic/gd-design-system';
import AdvancedConfigsContext from '@utils/contexts/AdvancedConfig';

import * as S from './styles';
import SwitchWithLabel from './SwitchWithLabel';
import { GOALS_OPTIONS, CTAS_OPTIONS } from './consts';

const GoalsAndCTA = () => {
  const t = useTranslate('Units');

  const { goals, setGoals, ctas, setCtas, disabledCtas } = useContext(AdvancedConfigsContext);

  function changeCTA(key) {
    setCtas((state) => {
      if (state.includes(key)) {
        return state.filter((cta) => cta !== key);
      }

      return [...state, key];
    });
  }

  function changeGoals(key) {
    setGoals((state) => {
      if (state.includes(key)) {
        return state.filter((goal) => goal !== key);
      }

      return [...state, key];
    });
  }

  function renderSwitchList({ values, checkList, onChangeFunc }) {
    return values.map(({ value, label }) => {
      const isCtaDisabled = disabledCtas.includes(value)

      let tooltipLabel =  ''
      if (isCtaDisabled) {
        if (value === 'whatsapp_message') {
          tooltipLabel = 'DISABLED_WHATSAPP_CTA_WARNING_TEXT'
        } else {
          tooltipLabel = 'DISABLED_CTA_WARNING_TEXT'
        }
      }

      return (
        <SwitchWithLabel
          key={value}
          label={label}
          hasError={isCtaDisabled}
          tooltipLabel={tooltipLabel}
          toggleValue={checkList.includes(value)}
          onChange={() => onChangeFunc(value)}
        />
      );
    });
  }

  function renderLeftContainer() {
    return (
      <S.Column>
        <S.Title>{t('GOALS_TITLE')}</S.Title>
        {renderSwitchList({ values: GOALS_OPTIONS, checkList: goals, onChangeFunc: changeGoals })}
      </S.Column>
    );
  }

  function renderRightContainer() {
    return (
      <S.Column>
        <S.Title>{t('CTA_TITLE')}</S.Title>
        {renderSwitchList({ values: CTAS_OPTIONS, checkList: ctas, onChangeFunc: changeCTA })}
      </S.Column>
    );
  }

  return (
    <S.Container>
      <Text>{t('GOALS_AND_CTA_TITLE')}</Text>
      <S.Content>
        {renderLeftContainer()}
        {renderRightContainer()}
      </S.Content>
    </S.Container>
  );
};

export default GoalsAndCTA;
