const CardCampaignEN = {
  CardCampaign: {
    post_engagement: 'Objective: Engagement',
    messages: 'Objective: Messages',
    store_visits: 'Objective: Store visits',
    link_clicks: 'Objective: Link clicks',
    conversions: 'Objective: Conversions',
    video_views: 'Objective: Video views',
    lead_generation: 'Objective: Leads',
    from: 'From',
    to: 'to',
    engaged_units: 'engaged units',
    tag: 'Partner: ',
  },
  CampaignStatus: {
    active: 'Active campaign',
    excluded: 'Deleted campaign',
    paused: 'Paused campaign',
    scheduled: 'Scheduled campaign',
    finished: 'Finished campaign',
    title_filter: 'Status campaign',
    modal_title: 'Filters',
    modal_sub_title: 'Select the filters you want to apply',
    modal_confirm_button: 'Apply',
    modal_cancel_button: 'Clear filters',
  },
};

const CardCampaignPT = {
  CardCampaign: {
    post_engagement: 'Objetivo: Engajamento',
    messages: 'Objetivo: Mensagens',
    store_visits: 'Objetivo: Visitas ao estabelecimento',
    link_clicks: 'Objetivo: Cliques no link',
    conversions: 'Objetivo: Conversões',
    video_views: 'Objetivo: Visualizações de video',
    lead_generation: 'Objetivo: Geração de cadastros',
    from: 'De',
    to: 'até',
    engaged_units: 'unidades participantes',
    tag: 'Parceiro: ',
  },
  AdStatus: {
    active: 'Anúncio ativo',
    archived: 'Anúncio arquivado',
    paused: 'Anúncio pausado',
    scheduled: 'Anúncio agendado',
    finished: 'Anúncio finalizado',
  },
  CampaignStatus: {
    active: 'Campanha ativa',
    excluded: 'Campanha excluída',
    paused: 'Campanha pausada',
    scheduled: 'Campanha agendada',
    finished: 'Campanha finalizada',
    title_filter: 'Status da campanha',
    modal_title: 'Filtros',
    modal_sub_title: 'Selecione os filtros que deseja aplicar',
    modal_confirm_button: 'Aplicar',
    modal_cancel_button: 'Limpar filtros',
  },
};

const CardCampaignES = {
  CardCampaign: {
    post_engagement: 'Objetivo: Interacción',
    messages: 'Objetivo: Mensajes',
    store_visits: 'Objetivo: Tráfico en el negocio',
    link_clicks: 'Objetivo: Tráfico',
    conversions: 'Objetivo: Conversiones',
    video_views: 'Objetivo: Reproducciones de video',
    lead_generation: 'Objetivo: Generación de clientes potenciales',
    engaged_units: 'unidades implicadas',
    from: 'De',
    to: 'hasta',
    tag: 'Compañero: ',
  },
  CampaignStatus: {
    active: 'Campaña activa',
    excluded: 'Campaña eliminada',
    paused: 'Campaña en pausa',
    scheduled: 'Campaña programada',
    finished: 'Campaña finalizada',
    title_filter: 'Estado de la campaña',
    modal_title: 'Filtros',
    modal_sub_title: 'Seleccione los filtros que desea aplicar',
    modal_confirm_button: 'Aplicar',
    modal_cancel_button: 'Limpiar filtros',
  },
};
export { CardCampaignEN, CardCampaignPT, CardCampaignES };
