// Types
import { FETCH_SURVEY, SUBMIT_SURVEY } from './types';

const INITIAL_STATE = {
  data: [],
  isLoading: false,
  status: null,
  message: null,
};

const surveyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SURVEY.REQUEST:
      return {
        ...state,
        isLoading: true,
        status: null,
        message: null,
      };

    case FETCH_SURVEY.SUCCESS:
      return {
        ...state,
        data: [...state.data, ...action.data],
        isLoading: false,
        status: action.status,
        message: null,
        showSurveyModal: action.data && action.data.length,
      };

    case FETCH_SURVEY.FAILURE:
      return {
        ...state,
        data: [],
        isLoading: false,
        status: action.status,
        message: action.message,
      };

    case FETCH_SURVEY.RESET:
      return {
        ...state,
        data: INITIAL_STATE.data,
        paging: INITIAL_STATE.paging,
        previews: INITIAL_STATE.previews,
        isLoading: INITIAL_STATE.isLoading,
        status: INITIAL_STATE.status,
        message: INITIAL_STATE.message,
      };

    case SUBMIT_SURVEY.REQUEST:
      return {
        ...state,
        isLoading: true,
        showSurveyModal: true,
      };

    case SUBMIT_SURVEY.SUCCESS:
      return {
        ...state,
        isLoading: false,
        showSurveyModal: false,
      };

    case SUBMIT_SURVEY.FAILURE:
      return {
        ...state,
        isLoading: false,
        showSurveyModal: false,
      };

    default:
      return state;
  }
};

export default surveyReducer;
