import useSWR from 'swr';
import { fetcher } from '@utils/api';

function useMetrics(_filter = {}, datePreset) {
  const { since, sinceToCompare } = datePreset;

  const url = {
    since_current: since.startOfCurrentMonth,
    until_current: since.currentDayOfMonth,
    since_previous: sinceToCompare.startOfLastMonth,
    until_previous: sinceToCompare.currentDayOfLastMonth,
    limit: '12',
  };

  const { data, error, mutate } = useSWR(
    `/incentivecampaign/1.0/report/daterange/reportMultiplefilters?${new URLSearchParams(
      url
    ).toString()}`,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );

  return {
    metrics: data || null,
    isLoading: !error && !data,
    isError: error ? error.message : error,
    mutate,
  };
}

function useGetTradePartners() {
  const { data, error, mutate } = useSWR(`/settings/1.0/keyvalue/trade_partner`, fetcher, {
    revalidateOnFocus: false,
  });

  return {
    data: data ? JSON.parse(data.value) : null,
    isLoading: !error && data === undefined,
    isError: error ? error.message : error,
    mutate: mutate,
  };
}

function useGetPermittedUnits(code, fields, isTitokCampaing = false) {
  const fieldsWithTypes = `/schema/1.1/${code}/leaves?fields=${fields ? fields : ''}${
    isTitokCampaing && '&related_to_types=["tiktok_ad_account"]'
  }`;
  const { data, error } = useSWR(code ? fieldsWithTypes : null, fetcher, {
    revalidateOnFocus: false,
  });
  return {
    data: data ? data : null,
    isLoading: !error && data === undefined,
    isError: error ? error.message : error,
  };
}

export { useMetrics, useGetTradePartners, useGetPermittedUnits };

export * from './PerformanceMetrics';
export * from './FinancialMetrics';
