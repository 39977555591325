import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  text-align: center;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme: { spacing, breakpoint } }) => css`
    padding-top: ${spacing.px40};

    @media screen and (max-width: ${breakpoint.md}) {
      flex-direction: column;
    }
  `}
`;

export const ImageWrapper = styled.div`
  ${({ theme: { spacing, breakpoint } }) => css`
    margin-right: ${spacing.px40};

    @media screen and (max-width: ${breakpoint.md}) {
      margin: 0 0 ${spacing.px40};
    }
  `}
`;

export const Image = styled.img`
  width: 75%;

  ${({ theme: { breakpoint } }) => css`
    @media screen and (max-width: ${breakpoint.md}) {
      width: 90%;
    }
  `}
`;

export const ContentInfo = styled.div`
  text-align: left;
  width: 35%;

  ${({ theme: { breakpoint } }) => css`
    @media screen and (max-width: ${breakpoint.md}) {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;
    }
  `}
`;

export const Body = styled.div`
  ${({ theme: { colors, fontSize, spacing } }) => css`
    p {
      color: ${colors.seriousBlack};
      font-size: ${fontSize.px16};
      margin-bottom: ${spacing.px10};
    }
  `}
`;

export const Button = styled(Link)`
  float: left;
  text-decoration: none;
  transition: all 0.3s;

  ${({ theme: { colors, spacing } }) => css`
    border: 1px solid ${colors.blueGrey2};
    padding: ${spacing.px5} ${spacing.px10};
    color: ${colors.blueGrey2};
    border-radius: ${spacing.px10};
    margin: ${spacing.px5} ${spacing.px10} ${spacing.px5} 0;
    :hover {
      background: ${colors.blueSecurity};
      color: ${colors.white};
      border: 1px solid ${colors.blueSecurity};
    }
  `}
`;
