import React, { useState, useEffect } from 'react';
import { useTranslate } from 'react-translate';
import * as yup from 'yup';
import { Radio, InputText, Tab, Tabs, Select, Title, Checkbox } from '@bornlogic/gd-design-system';
import SettingsService from '../../../../../services/settings';
import * as S from './styles';

const AddUnitTabs = ({ creditLine, accountMethod, ...props }) => {
  const [creditLines, setCreditLines] = useState([]);
  const t = useTranslate('Units');

  const normalizeCreditLines = (data) => {
    const result = [];

    data.forEach((item) => {
      result.push({
        title: item.legal_entity_name,
        value: item.id,
      });
    });

    return result;
  };

  const loadCreditLines = async () => {
    const response = await SettingsService.getCreditLines();

    if (response && response.data && response.data.length > 0) {
      setCreditLines(normalizeCreditLines(response.data));
    }
  };

  const handleAutoApprove = ({ value }) => {
    props.setAutoApprove(value);
  };

  const validateIsNumber = (value) =>
    new Promise((resolve) => {
      const schema = yup.number();
      schema.isValid(value).then((valid) => {
        resolve(valid === true || value === '');
      });
    });

  const handleBudgetInput = (value) => {
    const toValidate = value.split('.');

    validateIsNumber(toValidate[0]).then((validation) => {
      if (validation) {
        props.setMonthlyBudget(value);
      }
    });
  };

  const handleAdRadiusInput = (value) => {
    validateIsNumber(value).then((validation) => {
      if (validation) {
        props.setActuationRadius(value);
      }
    });
  };

  const handleSelectGroup = (value) => {
    props.setSelectedGroup(value);
  };

  const handleCreditLine = (value) => {
    if (value.value === 1) {
      setCreditLines([]);
      props.setSelectedCreditLine(null);
      props.setMonthlyBudget('');
    }
    if (value.value === 3) {
      props.setAccountMethod(2);
    }
    props.setCreditLine(value.value);
  };

  const handleAccountConfig = (value) => {
    if (value.value === 1) {
      props.setAdAccountId('');
    }
    props.setAccountMethod(value.value);
  };

  const handleSelectCreditLine = (value) => {
    props.setSelectedCreditLine(value);
  };

  const handleInviteUsersToBM = (event) => {
    props.setInviteUsersToBM(event.target.checked);
  };

  const handlePageIdValidation = async () => {
    const response = await SettingsService.validatePageId(props.facebookId);

    if (response.data && response.data.error && response.data.error === 'INVALID_PAGE_ID') {
      props.setFacebookIdError(t('INVALID_PAGE_ID'));
    } else {
      props.setFacebookIdError('');
    }
  };

  const handleAccountIdValidation = async () => {
    const response = await SettingsService.validateAccountId(props.adAccountId);

    if (response.data && response.data.error && response.data.error === 'INVALID_AD_ACCOUNT_ID') {
      props.setAccountIdError(t('INVALID_AD_ACCOUNT_ID'));
    } else {
      props.setAccountIdError('');
    }
  };

  useEffect(() => {
    if (creditLine === 2) {
      loadCreditLines();
    } else {
      setCreditLines([]);
      props.setSelectedCreditLine(null);
    }
  }, [creditLine]);

  return (
    <Tabs initialTabId="general-config">
      <Tab id="general-config" title={t('GENERAL_CONFIG')}>
        <S.Container>
          <S.InputWrapper>
            <S.LabelSpace>
              <Title as="h6">{t('BUDGET')}</Title>
            </S.LabelSpace>
            <InputText
              onChange={(value) => handleBudgetInput(value)}
              placeHolder={t('BUDGET_PLACEHOLDER')}
              value={props.monthlyBudget}
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <S.LabelSpace>
              <Title as="h6">{t('AD_RADIUS')}</Title>
            </S.LabelSpace>
            <InputText
              onChange={(value) => handleAdRadiusInput(value)}
              value={props.actuationRadius}
              placeHolder={t('AD_RADIUS_PLACEHOLDER')}
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <S.LabelSpace>
              <Title as="h6">{t('AUTO_APPROVE')}</Title>
            </S.LabelSpace>
            <Radio
              id="auto-approve-yes"
              label={t('YES')}
              onClick={handleAutoApprove}
              value={2}
              checked={props.autoApprove === 2}
            />
            <Radio
              id="auto-approve-no"
              label={t('NO')}
              onClick={handleAutoApprove}
              value={1}
              checked={props.autoApprove === 1}
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <S.LabelSpace>
              <Title as="h6">{t('USER_EMAIL_LABEL')}</Title>
            </S.LabelSpace>
            <InputText
              onChange={(value) => props.setUserEmail(value)}
              value={props.userEmail}
              placeHolder={t('USER_EMAIL_PLACEHOLDER')}
            />
            <S.ObservationField>*opcional</S.ObservationField>
          </S.InputWrapper>
        </S.Container>
      </Tab>
      <Tab id="unit-config" title={t('UNIT_CONFIG')}>
        <S.Container>
          <S.InputWrapper>
            <S.LabelSpace>
              <Title as="h6">{t('UNIT_NAME')}</Title>
            </S.LabelSpace>
            <InputText
              onChange={(value) => props.setUnitName(value)}
              value={props.unitName}
              placeHolder={t('UNIT_NAME_PLACEHOLDER')}
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <S.LabelSpace>
              <Title as="h6">{t('FACEBOOK_ID')}</Title>
            </S.LabelSpace>
            <InputText
              onChange={(value) => props.setFacebookId(value)}
              value={props.facebookId}
              placeHolder={t('FACEBOOK_ID_PLACEHOLDER')}
              error={props.facebookIdError !== ''}
              errorMessage={props.facebookIdError}
              onBlur={handlePageIdValidation}
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <S.LabelSpace>
              <Title as="h6">{t('PAYMENT_METHOD')}</Title>
            </S.LabelSpace>
            <S.RadioContainer>
              <Radio
                id="choose_credit_line"
                label={t('CHOSE_CREDIT_LINE')}
                onClick={handleCreditLine}
                value={2}
                checked={creditLine === 2}
              />
              <Radio
                id="ticket"
                label={t('TICKET')}
                onClick={handleCreditLine}
                value={1}
                checked={creditLine === 1}
              />
              <Radio
                id="credit_card"
                label={t('CREDIT_CARD')}
                onClick={handleCreditLine}
                value={3}
                checked={creditLine === 3}
              />
            </S.RadioContainer>
          </S.InputWrapper>
          {creditLine === 2 && (
            <S.InputWrapper>
              <S.LabelSpace>
                <Title as="h6">{t('CREDIT_LINE')}</Title>
              </S.LabelSpace>
              <Select
                items={creditLines}
                onChange={handleSelectCreditLine}
                value={props.selectedCreditLine}
              />
            </S.InputWrapper>
          )}
          <S.InputWrapper>
            <S.LabelSpace>
              <Title as="h6">{t('MONETARY_CONFIG_ACCOUNT')}</Title>
            </S.LabelSpace>
            <S.RadioContainer>
              <Radio
                id="choose_account_config"
                label={t('EXISTING_ACCOUNT')}
                onClick={handleAccountConfig}
                value={2}
                checked={accountMethod === 2}
              />
              <Radio
                id="new_account"
                label={t('CREATE_ACCOUNT')}
                onClick={handleAccountConfig}
                value={1}
                checked={accountMethod === 1}
                disabled={creditLine === 3}
              />
            </S.RadioContainer>
          </S.InputWrapper>
          {accountMethod === 2 && (
            <S.InputWrapper>
              <S.LabelSpace>
                <Title as="h6">{t('ACCOUNT_ID_LABEL')}</Title>
              </S.LabelSpace>
              <InputText
                onChange={(value) => props.setAdAccountId(value)}
                value={props.adAccountId}
                placeHolder={t('ACCOUNT_ID_PLACEHOLDER')}
                error={props.accountIdError !== ''}
                errorMessage={props.accountIdError}
                onBlur={handleAccountIdValidation}
              />
            </S.InputWrapper>
          )}
          <S.InputWrapper>
            <S.LabelSpace>
              <Title as="h6">{t('GROUP')}</Title>
            </S.LabelSpace>
            <Select
              placeholder={t('SELECT_GROUP')}
              items={props.elegibleGroups}
              selectValue={props.selectedGroup || ''}
              onChange={handleSelectGroup}
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <S.CheckboxContainer>
              <Checkbox
                checked={props.inviteUsersToBM}
                onClick={handleInviteUsersToBM}
                onChange={() => {}}
              />
              <Title as="h6">{t('INVITE_USER')}</Title>
            </S.CheckboxContainer>
          </S.InputWrapper>
        </S.Container>
      </Tab>
    </Tabs>
  );
};

export default AddUnitTabs;
