import { BusinessToken, Capabilities } from 'model/auth';
import { CapabilitiesResponse, CapabilityObjectLabel } from './types';

const hasCapabilities = (token: BusinessToken) =>
  token && ('bgd_capabilities' in token || 'bgd_capabilities_v2' in token);

const buildLabelHasCapability = (capability: Capabilities): CapabilityObjectLabel =>
  `HAS_${capability}`;

const validateCapability = (token: BusinessToken, capability: string): boolean | any => {
  // Bypass temporário da dashoard
  if (capability === 'DASHBOARD') return true;

  if (!hasCapabilities(token)) {
    return false;
  }

  if (token.bgd_capabilities) {
    return token.bgd_capabilities.find((item: any) => item.name === capability);
  }

  if (token.bgd_capabilities_v2) {
    return capability in token.bgd_capabilities_v2;
  }

  return false;
};

export const buildCapabilitiesObject = (token: BusinessToken, capabilities: Capabilities[]) =>
  capabilities.reduce((acc: CapabilitiesResponse, capability: Capabilities) => {
    const booleanLabel = buildLabelHasCapability(capability);
    acc[booleanLabel] = validateCapability(token, capability);
    return acc;
  }, {});
