import { CommonText } from '@utils/constants';

const { en, pt, es } = CommonText;

const CampaignStepsEN = {
  CampaignSteps: {
    MEDIA_LIBRARY: en.MEDIA_LIBRARY,
    MEDIA_LIBRARY_PLACEHOLDER: 'Select the media folder',
    OPTIONAL: en.OPTIONAL,
    TOOLTIP_CONTENT:
      'You can attach a folder from Media Library to help the participation of this campaign.',
    MEDIA_FOLDER_LABEL: 'Media library folder',
    LOADING: en.LOADING,
    IMPORTED_GROUPS_MESSAGE:
      'We import the units pre-selected by you. To share with more units, just go back one step to edit Groups and Units.',
    IMPORTED_GROUPS_OK_MESSAGE: 'Ok!',
    BLOCKED_SHARE_BUDGET: 'The "Amount per unit" field cannot be zeroed to use this option',
    SHARED_BUDGET_INFO: 'When active, the amount defined in "Value per unit" will be allocated to all units allowed to participate in the campaign.'
  },
};

const CampaignStepsPT = {
  CampaignSteps: {
    MEDIA_LIBRARY: pt.MEDIA_LIBRARY,
    MEDIA_LIBRARY_PLACEHOLDER: 'Selecione a pasta de mídias',
    OPTIONAL: pt.OPTIONAL,
    TOOLTIP_CONTENT:
      'Você pode vincular uma pasta da Biblioteca de Mídias, para auxiliar na participação desta Campanha de Incentivo.',
    MEDIA_FOLDER_LABEL: 'Pasta da biblioteca de mídias',
    LOADING: pt.LOADING,
    IMPORTED_GROUPS_MESSAGE:
      'Importamos as unidades pré selecionadas por você. Para compartilhar com mais unidades, basta voltar uma etapa para editar Grupos e unidades.',
    IMPORTED_GROUPS_OK_MESSAGE: 'Entendi!',
    BLOCKED_SHARE_BUDGET: 'O campo "Valor por unidade" não pode ser zerado para usar esta opção',
    SHARED_BUDGET_INFO: 'Quando ativo, o valor definido em "Valor por unidade" será alocado para todas as unidades autorizadas a participar da campanha.'
  },
};

const CampaignStepsES = {
  CampaignSteps: {
    MEDIA_LIBRARY: es.MEDIA_LIBRARY,
    MEDIA_LIBRARY_PLACEHOLDER: 'Seleccione la carpeta multimedia',
    OPTIONAL: es.OPTIONAL,
    TOOLTIP_CONTENT:
      'Puede adjuntar una carpeta de la Mediateca para ayudar a la participación de esta campaña.',
    MEDIA_FOLDER_LABEL: 'Carpeta de la Mediateca',
    LOADING: es.LOADING,

    IMPORTED_GROUPS_MESSAGE:
      'Importamos las unidades preseleccionadas por ti. Para compartir con más unidades, simplemente retroceda un paso para editar Grupos y Unidades.',
    IMPORTED_GROUPS_OK_MESSAGE: 'Entendí!',
    BLOCKED_SHARE_BUDGET: 'El campo "Cantidad por unidad" no se puede poner a cero para usar esta opción',
    SHARED_BUDGET_INFO: 'Cuando esté activo, la cantidad definida en "Valor por unidad" se asignará a todas las unidades autorizadas a participar en la campaña.'
  },
};

export { CampaignStepsEN, CampaignStepsPT, CampaignStepsES };
