const SettingsEasyAccessEN = {
  SettingsEasyAccess: {
    NOT_STARTED: 'Not started',
    RUNNING: 'Running',
    ACTIVES: 'Actives',
    GENERATE_LINK: 'Generate link',
    OPEN: 'Open',
    GROUP: 'Group',
    STATUS: 'Status',
    EA: 'Easy Access',
    ADD: 'Add',
    CANCEL: 'Cancel',
    OCCUPATION: 'Occupation',
    MONETARY_CONFIG_ACCOUNT: 'How you want to link your ad accounts',
    CHOSE_CREDIT_LINE: 'Choose credit line',
    TICKET: 'Ticket',
    MONTHLY_BUDGET: 'Monthly budget',
    CURRENCY: '$ 0',
    AUTOMATIC_APPROVE: 'Will these units have automatic approval?',
    YES: 'Yes',
    NO: 'No',
    CREDIT_LINE: 'Credit line',
    AUTOMATIC_APPROVE_USER: 'Automatically approve new users on this easy access.',
    INVITE_USER: 'Invite users to the business manager.',
    COPY: 'Copy',
    CLOSE: 'Close',
    DEFAULT_CURRENCY: '$',
    APPROVE_USER: 'User approval',
    APPROVE_EA: 'Automatic content approval',
    COPY_LINK: 'Copy link',
    USER_GROUP: 'Group users',
    AVAILABLE_LINKS: 'Available links',
    PAYMENT_METHOD: 'What will be the payment method?',
    USERS: 'Users',
    LINKS: 'Links',
    NAME: 'Name',
    BUDGET: 'Budget',
  },
};

const SettingsEasyAccessPT = {
  SettingsEasyAccess: {
    NOT_STARTED: 'Não iniciado',
    RUNNING: 'Em andamento',
    ACTIVES: 'Ativos',
    GENERATE_LINK: 'Gerar link',
    OPEN: 'Abrir',
    GROUP: 'Grupo',
    ACTIVE: 'Ativo',
    STATUS: 'Status',
    EA: 'Easy Access',
    ADD: 'Adicionar',
    CANCEL: 'Cancelar',
    OCCUPATION: 'Função',
    MONETARY_CONFIG_ACCOUNT: 'Como deseja vincular as contas de anúncio?',
    CHOSE_CREDIT_LINE: 'Escolher linha de crédito',
    TICKET: 'Boleto',
    MONTHLY_BUDGET: 'Orçamento mensal',
    CURRENCY: 'R$ 0',
    AUTOMATIC_APPROVE_EA: 'Essas unidades terão aprovação automática?',
    YES: 'Sim',
    NO: 'Não',
    CREDIT_LINE: 'Linha de crédito',
    AUTOMATIC_APPROVE_USER: 'Automaticamente aprovar novos usuários nesse easy access.',
    INVITE_USER: 'Convidar usuários para o gerenciador de negócios.',
    INVITE_LINK_CONFIRMATION: 'O link de convite para o easy access foi gerado.',
    COPY: 'Copiar',
    CLOSE: 'Fechar',
    DEFAULT_CURRENCY: 'R$',
    APPROVE_USER: 'Aprovação de usuário',
    APPROVE_EA: 'Aprovação automática de conteúdo',
    COPY_LINK: 'Copiar link',
    USER_GROUP: 'Usuários do grupo',
    AVAILABLE_LINKS: 'Links disponíveis',
    PAYMENT_METHOD: 'Qual será a forma de pagamento?',
    USERS: 'Usuários',
    LINKS: 'Links',
    NAME: 'Nome',
    BUDGET: 'Orçamento',
  },
};

export { SettingsEasyAccessEN, SettingsEasyAccessPT };
