const LineChartsEN = {
  LineCharts: {
    TITLE: 'Custom',
    SUBTITLE: 'Create custom reports based on the metrics that best fit your business',
    SUBTITLE_TWO: 'metrics that best fit your business',
    SPEND: 'Invested value',
    IMPRESSIONS: 'Impressions',
    SPEND_IMPRESSIONS: 'CPM',
    LINK_CLICK: 'Link clicks',
    SPEND_LINK_CLICK: 'Cost per link clicks',
    LINK_CLICK_IMPRESSIONS: 'Link click through rate',
    VIDEO_VIEW_VIEW: 'Video viewing',
    SPEND_VIDEO_VIEW: 'Cost per video viewing',
    VIDEO_VIEW_VIEW_IMPRESSIONS: 'Video view rate',
    POST_ENGAGEMENT: 'Engagement',
    SPEND_POST_ENGAGEMENT: 'Cost per engagement',
    POST_ENGAGEMENT_IMPRESSIONS: 'Engagement rate',
    POST_REACTION: 'Reactions',
    SPEND_POST_REACTION: 'Cost per reactions',
    POST_REACTION_IMPRESSIONS: 'Reaction rate',
    PHOTO_VIEW: 'Photo views',
    SPEND_PHOTO_VIEW: 'Cost per photo views',
    PHOTO_VIEW_IMPRESSIONS: 'Photo view rate',
    COMMENT: 'Comment',
    SPEND_COMMENT: 'Cost per reviews',
    COMMENT_IMPRESSIONS: 'Feedback rate',
    POST: 'Shares',
    SPEND_POST: 'Cost per shares',
    POST_IMPRESSIONS: 'Sharing fee',
    LIKE: 'Likes on the page',
    SPEND_LIKE: 'Cost per likes on the page',
    LIKE_IMPRESSIONS: 'Rate of likes on the page',
    OFFLINE_CONVERSION_PURCHASE: 'Offline selling',
    SPEND_OFFLINE_CONVERSION_PURCHASE: 'Cost for offline sales',
    OFFLINE_CONVERSION_PURCHASE_IMPRESSIONS: 'Offline sales fee',
    OFFLINE_CONVERSION_PURCHASE_VALUE: 'Offline sales revenue',
    OFFLINE_CONVERSION_PURCHASE_VALUE_SPEND: 'ROAS offline sale',
    OFFSITE_CONVERSION_FB_PIXEL_PURCHASE: 'Online sale',
    SPEND_OFFSITE_CONVERSION_FB_PIXEL_PURCHASE: 'Cost per online sale',
    OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_IMPRESSIONS: 'Online sales fee',
    OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_VALUE: 'Online sales revenue',
    OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_VALUE_SPEND: 'ROAS online sale',
    OFFSITE_CONVERSION_FB_PIXEL_ADD_TO_CART: 'Add to cart (online)',
    SPEND_OFFSITE_CONVERSION_FB_PIXEL_ADD_TO_CART: 'Cost add to cart (online)',
    OFFSITE_CONVERSION_FB_PIXEL_ADD_TO_CART_IMPRESSIONS: 'Add to cart fee (online)',
    OFFSITE_CONVERSION_FB_PIXEL_VIEW_CONTENT: 'Content view (online)',
    SPEND_OFFSITE_CONVERSION_FB_PIXEL_VIEW_CONTENT: 'Content viewing cost (online)',
    OFFSITE_CONVERSION_FB_PIXEL_VIEW_CONTENT_IMPRESSIONS: 'Content viewing fee (online)',
    OFFLINE_CONVERSION_PURCHASE_CONVERSION_FB_PIXEL_PURCHASE: 'General sale',
    SPEND_OFFLINE_CONVERSION_PURCHASE_OFFSITE_FB_PIXEL_PURCHASE: 'General sale cost',
    OFFLINE_CONVERSION_PURCHASE_OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_IMPRESSIONS:
      'General sale rate',
    OFFLINE_CONVERSION_PURCHASE_VALUE_OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_VALUE:
      'General sales revenue',
    OFFLINE_CONVERSION_PURCHASE_VALUE_OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_VALUE_SPEND:
      'ROAS general sale',
    ONSITE_CONVERSION_LEAD_GROUPED: 'Records generated',
    SPEND_ONSITE_CONVERSION_LEAD_GROUPED: 'Cost for generated registrations',
    ONSITE_CONVERSION_LEAD_GROUPED_IMPRESSIONS: 'Registration fee generated',
    ONSITE_COVERSION_MESSAGING_CONVERSATION_STARTED_7D: 'Conversations started',
    SPEND_ONSITE_COVERSION_MESSAGING_CONVERSATION_STARTED_7D: 'Cost for initiated conversations',
    ONSITE_COVERSION_MESSAGING_CONVERSATION_STARTED_7D_IMPRESSIONS: 'Conversations initiated rate',
    METRICS_TEXT: 'Metrics',
    FILTER_CHART: 'Filter chart',
    FILTER_TABLE: 'Filter table',
    TITLE_REGIONAL_RESULTS: 'Results by regions',
    TITLE_UNITIES_RESULTS: 'Results by unities',
    OPEN: 'Open',
    INVESTMENT: 'Investment',
    CPM: 'CPM',
    ENGAGEMENT: 'Engagement',
    FILTER_TABLE_TEXT: 'Table filters',
    METRICS_SUB_TEXT_FILTER: 'Select the filters you want to apply',
    METRICS_SUB_TEXT_FILTER_ONE: 'Choose which group you want to view',
    AD_COUNT: 'Quantity of ads created',
    POST_COUNT: 'Quantity of posts created',
    CONTENT_COUNT: 'Total of content created',
    UNITS_COUNT: 'Total of units',
    UNITS_WITH_POSTS: 'Quantity of units that are posting',
    UNITS_WITH_ADS: 'Quantity of units that are making ads',
    UNITS_WITH_POSTS_AND_ADS: 'Quantity of units that are posting and making ads',
    UNITS_WITH_POSTS_OR_ADS: 'Quantity of units that are posting or making ads',
    AD_PERCENTAGE: 'Stores advertising fee',
    POST_PERCENTAGE: 'Stores posting fee',
    UNITS_ACTIVE_FEE: 'Active stores fee',
  },
};

const LineChartsPT = {
  LineCharts: {
    TITLE: 'Personalizado',
    SUBTITLE: 'Crie relatórios personalizados de acordo com as ',
    SUBTITLE_TWO: 'métricas que mais se adequam ao seu negócio',
    SPEND: 'Valor investido',
    IMPRESSIONS: 'Impressões',
    SPEND_IMPRESSIONS: 'CPM',
    LINK_CLICK: 'Cliques no link',
    SPEND_LINK_CLICK: 'Custo por cliques no link',
    LINK_CLICK_IMPRESSIONS: 'Taxa de cliques no link',
    VIDEO_VIEW_VIEW: 'Visualização de vídeo',
    SPEND_VIDEO_VIEW: 'Custo por visualização de vídeo',
    VIDEO_VIEW_VIEW_IMPRESSIONS: 'Taxa de visualização de vídeo',
    POST_ENGAGEMENT: 'Engajamento',
    SPEND_POST_ENGAGEMENT: 'Custo por engajamento',
    POST_ENGAGEMENT_IMPRESSIONS: 'Taxa de engajamento',
    POST_REACTION: 'Reações',
    SPEND_POST_REACTION: 'Custo por reações',
    POST_REACTION_IMPRESSIONS: 'Taxa de reações',
    PHOTO_VIEW: 'Visualizações de foto',
    SPEND_PHOTO_VIEW: 'Custo por visualizações de foto',
    PHOTO_VIEW_IMPRESSIONS: 'Taxa de visualizações de foto',
    COMMENT: 'Comentário',
    SPEND_COMMENT: 'Custo por comentários',
    COMMENT_IMPRESSIONS: 'Taxa de comentários',
    POST: 'Compartilhamentos',
    SPEND_POST: 'Custo por compartilhamentos',
    POST_IMPRESSIONS: 'Taxa de compartilhamentos',
    LIKE: 'Curtidas na página',
    SPEND_LIKE: 'Custo por curtidas na página',
    LIKE_IMPRESSIONS: 'Taxa de curtidas na página',
    OFFLINE_CONVERSION_PURCHASE: 'Venda offline',
    SPEND_OFFLINE_CONVERSION_PURCHASE: 'Custo por venda offline',
    OFFLINE_CONVERSION_PURCHASE_IMPRESSIONS: 'Taxa de venda offline',
    OFFLINE_CONVERSION_PURCHASE_VALUE: 'Receita venda offline',
    OFFLINE_CONVERSION_PURCHASE_VALUE_SPEND: 'ROAS venda offline',
    OFFSITE_CONVERSION_FB_PIXEL_PURCHASE: 'Venda online',
    SPEND_OFFSITE_CONVERSION_FB_PIXEL_PURCHASE: 'Custo por venda online',
    OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_IMPRESSIONS: 'Taxa de venda online',
    OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_VALUE: 'Receita venda online',
    OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_VALUE_SPEND: 'ROAS venda online',
    OFFSITE_CONVERSION_FB_PIXEL_ADD_TO_CART: 'Adicionar ao carrinho (online)',
    SPEND_OFFSITE_CONVERSION_FB_PIXEL_ADD_TO_CART: 'Custo adicionar ao carrinho (online)',
    OFFSITE_CONVERSION_FB_PIXEL_ADD_TO_CART_IMPRESSIONS: 'Taxa adicionar ao carrinho (online)',
    OFFSITE_CONVERSION_FB_PIXEL_VIEW_CONTENT: 'Visualização de conteúdo (online)',
    SPEND_OFFSITE_CONVERSION_FB_PIXEL_VIEW_CONTENT: 'Custo visualização de conteúdo (online)',
    OFFSITE_CONVERSION_FB_PIXEL_VIEW_CONTENT_IMPRESSIONS: 'Taxa visualização de conteúdo (online)',
    OFFLINE_CONVERSION_PURCHASE_CONVERSION_FB_PIXEL_PURCHASE: 'Venda geral',
    SPEND_OFFLINE_CONVERSION_PURCHASE_OFFSITE_FB_PIXEL_PURCHASE: 'Custo por venda geral',
    OFFLINE_CONVERSION_PURCHASE_OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_IMPRESSIONS:
      'Taxa de venda geral',
    OFFLINE_CONVERSION_PURCHASE_VALUE_OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_VALUE:
      'Receita venda geral',
    OFFLINE_CONVERSION_PURCHASE_VALUE_OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_VALUE_SPEND:
      'ROAS venda geral',
    ONSITE_CONVERSION_LEAD_GROUPED: 'Cadastros gerados',
    SPEND_ONSITE_CONVERSION_LEAD_GROUPED: 'Custo por cadastros gerados',
    ONSITE_CONVERSION_LEAD_GROUPED_IMPRESSIONS: 'Taxa de cadastros gerados',
    ONSITE_COVERSION_MESSAGING_CONVERSATION_STARTED_7D: 'Conversas iniciadas',
    SPEND_ONSITE_COVERSION_MESSAGING_CONVERSATION_STARTED_7D: 'Custo por conversas iniciadas',
    ONSITE_COVERSION_MESSAGING_CONVERSATION_STARTED_7D_IMPRESSIONS: 'Taxa de conversas iniciadas',
    OFFSITE_CONVERSION_FB_PIXEL_INITIATED_CHECKOUT: 'Checkout iniciado (online)',
    SPEND_OFFSITE_CONVERSION_FB_PIXEL_INITIATED_CHECKOUT: 'Custo por checkout iniciado (online)',
    OFFSITE_CONVERSION_FB_PIXEL_INITIATED_CHECKOUT_IMPRESSIONS:
      'Taxa de checkout iniciado (online)',
    METRICS_TEXT: 'Métricas',
    METRICS_SUB_TEXT: 'Selecione as métricas que deseja aplicar',
    FILTER_CHART: 'Filtrar gráfico',
    FILTER_TABLE: 'Filtrar tabela',
    TITLE_REGIONAL_RESULTS: 'Resultados por regional',
    TITLE_UNITIES_RESULTS: 'Resultados por unidades',
    OPEN: 'Abrir',
    INVESTMENT: 'Investimento',
    CPM: 'CPM',
    ENGAGEMENT: 'Engajamento',
    FILTER_TABLE_TEXT: 'Filtros da tabela',
    METRICS_SUB_TEXT_FILTER: 'Selecione os filtros que deseja aplicar',
    METRICS_SUB_TEXT_FILTER_ONE: 'Escolha qual grupo quer visualizar',
    AD_COUNT: 'Quantidade de anúncios criados',
    POST_COUNT: 'Quantidade de postagens criadas',
    CONTENT_COUNT: 'Total de conteúdos criados',
    UNITS_COUNT: 'Total de lojas',
    UNITS_WITH_POSTS: 'Quantidade de lojas postando',
    UNITS_WITH_ADS: 'Quantidade de lojas anunciando',
    UNITS_WITH_POSTS_AND_ADS: 'Quantidade de lojas postando e anunciando',
    UNITS_WITH_POSTS_OR_ADS: 'Quantidade de lojas postando ou anunciando',
    AD_PERCENTAGE: 'Taxa de lojas anunciando',
    POST_PERCENTAGE: 'Taxa de lojas postando',
    UNITS_ACTIVE_FEE: 'Taxa de lojas ativas',
  },
};

const LineChartsES = {
  LineCharts: {
    TITLE: 'Personalizado',
    SUBTITLE: 'Cree informes personalizados basados ',
    SUBTITLE_TWO: '​​en las métricas que mejor se adapten a su negocio',
    SPEND: 'Valor invertido',
    IMPRESSIONS: 'Impresiones',
    SPEND_IMPRESSIONS: 'CPM',
    LINK_CLICK: 'Clics en enlaces',
    SPEND_LINK_CLICK: 'Costo por clics en el enlace',
    LINK_CLICK_IMPRESSIONS: 'Tasa de clics en enlaces',
    VIDEO_VIEW_VIEW: 'Visualización de vídeos',
    SPEND_VIDEO_VIEW: 'Costo por visualización de video',
    VIDEO_VIEW_VIEW_IMPRESSIONS: 'Tasa de visualización de video',
    POST_ENGAGEMENT: 'Involucramiento',
    SPEND_POST_ENGAGEMENT: 'Costo por participación',
    POST_ENGAGEMENT_IMPRESSIONS: 'Tasa de participación',
    POST_REACTION: 'Reacciones',
    SPEND_POST_REACTION: 'Costo por reacciones',
    POST_REACTION_IMPRESSIONS: 'Tasa de reacciones',
    PHOTO_VIEW: 'Visualización de fotos',
    SPEND_PHOTO_VIEW: 'Costo por visualización de fotos',
    PHOTO_VIEW_IMPRESSIONS: 'Tasa de visualización de fotos',
    COMMENT: 'Comentario',
    SPEND_COMMENT: 'Costo por comentarios',
    COMMENT_IMPRESSIONS: 'Tasa de comentarios',
    POST: 'Compartidos',
    SPEND_POST: 'Costo por compartidos',
    POST_IMPRESSIONS: 'Tasa de compartidos',
    LIKE: 'Me gusta en la página',
    SPEND_LIKE: 'Costo por me gusta en la página',
    LIKE_IMPRESSIONS: 'Tasa de me gusta en la página',
    OFFLINE_CONVERSION_PURCHASE: 'Venta offline',
    SPEND_OFFLINE_CONVERSION_PURCHASE: 'Costo por venta offline',
    OFFLINE_CONVERSION_PURCHASE_IMPRESSIONS: 'Tasa de venta offline',
    OFFLINE_CONVERSION_PURCHASE_VALUE: 'Ingresos venta offline',
    OFFLINE_CONVERSION_PURCHASE_VALUE_SPEND: 'ROAS venta offline',
    OFFSITE_CONVERSION_FB_PIXEL_PURCHASE: 'Venta online',
    SPEND_OFFSITE_CONVERSION_FB_PIXEL_PURCHASE: 'Costo por venta online',
    OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_IMPRESSIONS: 'Tasa de venta online',
    OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_VALUE: 'Ingresos venta online',
    OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_VALUE_SPEND: 'ROAS venta online',
    OFFSITE_CONVERSION_FB_PIXEL_ADD_TO_CART: 'Agregar al carrito (online)',
    SPEND_OFFSITE_CONVERSION_FB_PIXEL_ADD_TO_CART: 'Costo agregar al carrito (online)',
    OFFSITE_CONVERSION_FB_PIXEL_ADD_TO_CART_IMPRESSIONS: 'Tasa agregar al carrito (online)',
    OFFSITE_CONVERSION_FB_PIXEL_VIEW_CONTENT: 'Visualización del contenido (online)',
    SPEND_OFFSITE_CONVERSION_FB_PIXEL_VIEW_CONTENT: 'Costo visualización del contenido (online)',
    OFFSITE_CONVERSION_FB_PIXEL_VIEW_CONTENT_IMPRESSIONS:
      'Tasa visualización del contenido (online)',
    OFFLINE_CONVERSION_PURCHASE_CONVERSION_FB_PIXEL_PURCHASE: 'Venta general',
    SPEND_OFFLINE_CONVERSION_PURCHASE_OFFSITE_FB_PIXEL_PURCHASE: 'Costo por venta general',
    OFFLINE_CONVERSION_PURCHASE_OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_IMPRESSIONS:
      'Tasa de venta general',
    OFFLINE_CONVERSION_PURCHASE_VALUE_OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_VALUE:
      'Ingresos venta general',
    OFFLINE_CONVERSION_PURCHASE_VALUE_OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_VALUE_SPEND:
      'ROAS venta general',
    ONSITE_CONVERSION_LEAD_GROUPED: 'Registros generados',
    SPEND_ONSITE_CONVERSION_LEAD_GROUPED: 'Costo por registros generados',
    ONSITE_CONVERSION_LEAD_GROUPED_IMPRESSIONS: 'Tasa de registros generados',
    ONSITE_COVERSION_MESSAGING_CONVERSATION_STARTED_7D: 'Conversaciones iniciadas',
    SPEND_ONSITE_COVERSION_MESSAGING_CONVERSATION_STARTED_7D: 'Costo por conversaciones iniciadas',
    ONSITE_COVERSION_MESSAGING_CONVERSATION_STARTED_7D_IMPRESSIONS:
      'Tasa de conversaciones iniciadas',
    OFFSITE_CONVERSION_FB_PIXEL_INITIATED_CHECKOUT: 'Checkout empezado (online)',
    SPEND_OFFSITE_CONVERSION_FB_PIXEL_INITIATED_CHECKOUT: 'Costo por checkout empezado (online)',
    OFFSITE_CONVERSION_FB_PIXEL_INITIATED_CHECKOUT_IMPRESSIONS:
      'Tasa de checkout empezado (online)',
    METRICS_TEXT: 'Indicadores',
    METRICS_SUB_TEXT: 'Seleccione los indicadores que desea aplicar',
    FILTER_CHART: 'Filtrar gráfico',
    FILTER_TABLE: 'Filtrar tabla',
    TITLE_REGIONAL_RESULTS: 'Resultados por región',
    TITLE_UNITIES_RESULTS: 'Resultados por unidades',
    OPEN: 'Abrir',
    INVESTMENT: 'Inversión',
    CPM: 'CPM',
    ENGAGEMENT: 'Involucramiento',
    FILTER_TABLE_TEXT: 'Filtros de tabla',
    METRICS_SUB_TEXT_FILTER: 'Seleccione los filtros que desea aplicar',
    METRICS_SUB_TEXT_FILTER_ONE: 'Elige qué grupo quieres ver',
    AD_COUNT: 'Número de anuncios creados',
    POST_COUNT: 'Número de publicaciones creadas',
    CONTENT_COUNT: 'Contenido total creado',
    UNITS_COUNT: 'Tiendas totales',
    UNITS_WITH_POSTS: 'Número de tiendas publicando',
    UNITS_WITH_ADS: 'Número de tiendas anunciando',
    UNITS_WITH_POSTS_AND_ADS: 'Número de tiendas publicando e anunciando',
    UNITS_WITH_POSTS_OR_ADS: 'Número de tiendas publicando ou anunciando',
    AD_PERCENTAGE: 'Tasa de tiendas anunciando',
    POST_PERCENTAGE: 'Tasa de tiendas publicando',
    UNITS_ACTIVE_FEE: 'Tasa de tiendas activas',
  },
};

export { LineChartsEN, LineChartsPT, LineChartsES };
