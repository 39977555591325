// Modules
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import PropTypes from 'prop-types';
import Container from 'components/Container';
import ButtonClose from 'components/ButtonClose';

// Components
import Modal from 'react-modal';

const ModalWrapper = styled('div')`
  display: block;
`;

const ModalStyles = createGlobalStyle`
    .ReactModal__Overlay--after-open {
        background: rgba(0, 0, 0, 0.5) !important;
        z-index: 2004;
    }
    .ReactModal__Content--after-open {
        max-height: 90vh;
        max-width: 90%;
        box-sizing: border-box;
        overflow: hidden !important;
    }
`;

const CustomModal = ({ visible, width, maxWidth = '', onClickAway, children }) => (
  <Modal
    data-testid="modal"
    ariaHideApp={false}
    isOpen={visible}
    onRequestClose={onClickAway}
    style={{
      content: {
        width,
        maxWidth,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '10px',
      },
    }}
  >
    <ModalWrapper>
      <ButtonClose onClick={onClickAway} iconClose={['fal', 'times']} />
      <Container>{children}</Container>
    </ModalWrapper>
    <ModalStyles />
  </Modal>
);

CustomModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClickAway: PropTypes.func,
};

CustomModal.defaultProps = {
  visible: false,
  onClickAway: () => {},
};

export default CustomModal;
