import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useGetTradePartners } from '@api/Campaign';
import { useLocalStorage } from '@utils/hooks/Common';
import { useTradeTenants } from '@utils/hooks/TradeAds';

import { useGetCampaignFolders } from '@api/Campaign/CampaignFolders';

const INITIAL_CONTEXT = {
  campaigns: {
    campaigns: [],
    fetchMoreData: () => {},
    hasNextPage: false,
    isLoading: false,
    lastCampaign: null,
  },
  filters: {
    incentive_campaign_status: '',
    incentive_campaign_name: '',
    appliedFilters: false,
  },
  isCreatingCampaign: false,
};

export const CampaignsContext = createContext(INITIAL_CONTEXT);

export const CampaignsProvider = ({ children }) => {
  const [filters, setFilters] = useLocalStorage(
    'filter_incentive_campaign',
    INITIAL_CONTEXT.filters
  );

  const [nameFilter, setNameFilter] = useState('');
  const tenantsData = useTradeTenants();

  const {
    campaigns,
    fetchMoreData,
    hasNextPage,
    isLoading,
    error,
    lastCampaign,
    mutate: mutateCampaigns,
  } = useGetCampaignFolders({
    ...filters,
    incentive_campaign_name: nameFilter,
  });

  const tradePartnersResponse = useGetTradePartners();

  const hasTradeAds = campaigns.filter(
    (media) => media.campaign_tags && media.campaign_tags.length > 0
  );
  const hasOnlyCampaignTradeAds = hasTradeAds && hasTradeAds.length === campaigns.length;

  const [isCreatingCampaign, setIsCreatingCampaign] = useLocalStorage('new_campaign');

  const tradePartners = useMemo(() => {
    const hasTradePartners = tradePartnersResponse?.data?.length;
    if (hasTradePartners !== tradePartners?.length && hasTradePartners) {
      return tradePartnersResponse.data.sort((a, b) => a.value.localeCompare(b.value));
    }
  }, [tradePartnersResponse]);

  const shouldResetFilters =
    (filters.appliedFilters || nameFilter) &&
    (!campaigns || campaigns.length === 0 || hasOnlyCampaignTradeAds) &&
    isLoading === undefined;

  const resetFilters = () => {
    setTimeout(() => {
      setNameFilter('');
      setFilters(INITIAL_CONTEXT.filters);
    }, 1000);
  };

  const disableFilters = () => setFilters({ ...filters, appliedFilters: false });

  const setNewFilters = useCallback(
    (params, appliedFilters = false) => {
      setFilters({ ...params, appliedFilters });
    },
    [setFilters]
  );

  const getTagPartner = useMemo(() => {
    const arrayTenants = tenantsData?.tenants?.map((tenant) => {
      return { value: tenant.exchanged_tags[0], label: tenant.target_tenant_name };
    });
    return arrayTenants;
  }, [tenantsData]);
  const providerStructure = {
    campaigns: {
      campaigns,
      fetchMoreData,
      hasNextPage,
      isLoading,
      error,
      lastCampaign,
      mutateCampaigns,
    },
  };

  const hasLastCampaign = !!lastCampaign?.id;
  const hasCampaigns = !!campaigns?.length;

  return (
    <CampaignsContext.Provider
      value={{
        ...providerStructure,
        hasLastCampaign,
        hasCampaigns,
        filters,
        setNewFilters,
        setNameFilter,
        nameFilter,
        disableFilters,
        resetFilters,
        shouldResetFilters,
        getTagPartner,
        isCreatingCampaign,
        setIsCreatingCampaign,
        tradePartnersResponse,
        tradePartners,
      }}
    >
      {children}
    </CampaignsContext.Provider>
  );
};

export const useCampaign = () => {
  const context = useContext(CampaignsContext);

  if (!context) throw new Error('Expected to be wrapped in a CampaignFormStep');

  return context;
};
