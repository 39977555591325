const TableEN = {
  Table: {
    DOWNLOAD: 'Download',
    SUMMARY: 'Total',
    IMPRESSIONS: 'Impressions',
    REACHES: 'Reach',
    VIDEO_VIEWS: 'Video Views',
    ENGAGEMENT: 'Engagement',
    RATE_ENGAGEMENT: 'Engagement Rate',
    SPEND: 'Spend',
    OPEN: 'Open',
    PAGES: 'Pages',
    STRATEGIES: 'Strategies',
    ACCOUNTS: 'Accounts',
    GROUPS: 'Groups',
    GROUP: 'Group',
    'NO-DATA': 'No data.',
    STRATEGY_CAMPAIGN_OBJECTIVE_APP_INSTALLS: 'App installs',
    STRATEGY_CAMPAIGN_OBJECTIVE_BRAND_AWARENESS: 'Brand awareness',
    STRATEGY_CAMPAIGN_OBJECTIVE_CONVERSIONS: 'Conversions',
    STRATEGY_CAMPAIGN_OBJECTIVE_EVENT_RESPONSES: 'Event responses',
    STRATEGY_CAMPAIGN_OBJECTIVE_LEAD_GENERATION: 'Lead generation',
    STRATEGY_CAMPAIGN_OBJECTIVE_LINK_CLICKS: 'Link clicks',
    STRATEGY_CAMPAIGN_OBJECTIVE_VIDEO_VIEWS: 'Video view',
    STRATEGY_CAMPAIGN_OBJECTIVE_REACH: 'Reach',
    STRATEGY_CAMPAIGN_OBJECTIVE_LOCAL_AWARENESS: 'Local awareness',
    STRATEGY_CAMPAIGN_OBJECTIVE_POST_ENGAGEMENT: 'Post engagement',
    STRATEGY_CAMPAIGN_OBJECTIVE_MESSAGES: 'Messages',
    STRATEGY_CAMPAIGN_OBJECTIVE_OFFER_CLAIMS: 'Offer claims',
    STRATEGY_CAMPAIGN_OBJECTIVE_PAGE_LIKES: 'Page likes',
    STRATEGY_CAMPAIGN_OBJECTIVE_PRODUCT_CATALOG_SALES: 'Product catalog sales',
    INVOLVEMENT: 'Involvement',
    RATE_INVOLVEMENT: 'Involvement Rate',
    LINK_CLICKS: 'Link Clicks',

    impressions_desc:
      "How many times an ad was shown on the feed to your public. If it appears in different moments to the same person, another impression is counted. Be aware: for videos an impression is counted even if the video doesn't start.",
    videoViews_desc: 'How many video views in ads, calculated only after 3 seconds.',
    engagement_desc:
      'Indicates the sum of reactions, comments, shares, views, link clicks, and offer claim.',
    involvement_desc:
      'Indicates the number of engagement (sum of reactions, comments, shares, views, link clicks, and offer claim), withdrawing the number of views.',
    link_clicks_desc: 'Indicates how many link clicks your ad had.',
    purchase_desc:
      "Indicates the number of conversions you had with your ad. It's tracked by Facebook's Pixel, installed on your website.",
    revenue_desc:
      "It indicates the value of your website purchases, tracked by your Facebook's Pixel.",
    ROAS_desc:
      "Return on advertising spend: indicates how successful your ads are. It's calculated by the conversion value divided by spent.",
    CPM_desc:
      "Cost per 1,000 impressions: calculates the price of 1,000 impressions in ads. It's calculated by the total amount spent, divided by the number of impressions, and multiplied by 1,000.",
    CPV_desc:
      "Cost per view: calculates the price of video views in ads. It's calculated by the total amount spent, divided by the number of views.",
    CPE_desc:
      "Cost per engagement: calculates the investment for each engagement. It's calculated by the total amount spent divided by the number of engagements.",
    ER_desc:
      "Engagement rate: calculates the percentage of people who engaged with your ads. It's calculated by the number of engagements, divided by impressions, and multiplied by 100.",
    CPC_desc:
      "Cost per link click: calculates the price of each link click your ad had. It's calculated by the total amount spent divided by the total of link clicks.",
    CTR_desc:
      "Click-through rate: calculates the percentage of people who saw your ad and clicked somewhere. It's calculated by the total of clicks, divided by impressions, and multiplied by 100.",
    CPA_desc:
      "Cost per acquisition: calculates how much you had to spend to generate a conversion. It's calculated by the total amount spent divided by acquisition (conversion).",
    CR_desc:
      "Conversion rate: calculates the percentage of people who converted (buy, lead...). It's calculated by the total of conversion divided by clicks and multiplied by 100.",
    impressions_desc_pages:
      "Indicates how many times your contents were shown on the feed to your public without an investment on the publication. If it appears in different moments to the same person, another impression is counted. Be aware: for videos an impression is counted even if the video doesn't start.",
    videoViews_desc_pages:
      'Indicates how many organic (without investment) video views, calculated only after 3 seconds.',
    involvement_desc_pages: 'It indicates only the sum of reactions.',
    engagement_desc_pages: 'It indicates only the sum of reactions.',
    link_clicks_desc_pages: 'Indicates how many link organic clicks your post had.',
    conversations_started_desc: 'Indicates how many conversations were initiated through your ads.',
    conversations_new_desc: 'Indicates how many new conversations were initiated through your ads.',
    CPCI_desc:
      'Cost per conversation started: calculates the amount invested to start a new conversation. Its formula is: invested amount divided by the number of conversations initiated',
    OROAS_desc:
      "Return on advertising spend: indicates how successful your ads are. It's calculated by the offline conversion value divided by spent.",
    offline_revenue_desc: 'It indicates the value of your offline purchases.',
    reach_desc:
      "It shows how many people the ad has reached in your audience's feed. If it appears at different times for the same person, only one reach is counted.",
    VR_desc:
      "View Rate: calculates the percentage of views related to the number of times an ad was shown. It's calculated by the total video views, divided by the total impressions, and multiplied by 100.",
    offline_conversion_initiate_checkout_desc: 'Shows the number of purchases via wpp',
    offline_initiate_checkout_conversion_value_desc: 'Indicates revenue from sales made by wpp',
    ROAS_GENERAL_desc: 'Overall ROAS is calculated by (online revenue + offline revenue)/(spend)',
  },
};

const TablePT = {
  Table: {
    DOWNLOAD: 'Download',
    SUMMARY: 'Total',
    IMPRESSIONS: 'Impressões',
    REACHES: 'Alcance',
    VIDEO_VIEWS: 'Visualizações',
    ENGAGEMENT: 'Engajamentos',
    RATE_ENGAGEMENT: 'Taxa de engajamento',
    SPEND: 'Investido',
    OPEN: 'Abrir',
    PAGES: 'Páginas',
    STRATEGIES: 'Estratégias',
    ACCOUNTS: 'Contas',
    GROUPS: 'Grupos',
    GROUP: 'Grupo',
    'NO-DATA': 'Não há dados.',
    STRATEGY_CAMPAIGN_OBJECTIVE_APP_INSTALLS: 'Instalações de aplicativo',
    STRATEGY_CAMPAIGN_OBJECTIVE_BRAND_AWARENESS: 'Reconhecimento de marca',
    STRATEGY_CAMPAIGN_OBJECTIVE_CONVERSIONS: 'Conversões',
    STRATEGY_CAMPAIGN_OBJECTIVE_EVENT_RESPONSES: 'Participações no evento',
    STRATEGY_CAMPAIGN_OBJECTIVE_LEAD_GENERATION: 'Geração de cadastros',
    STRATEGY_CAMPAIGN_OBJECTIVE_LINK_CLICKS: 'Cliques para o site',
    STRATEGY_CAMPAIGN_OBJECTIVE_VIDEO_VIEWS: 'Visualização de vídeos',
    STRATEGY_CAMPAIGN_OBJECTIVE_REACH: 'Alcance',
    STRATEGY_CAMPAIGN_OBJECTIVE_LOCAL_AWARENESS: 'Reconhecimento de loja',
    STRATEGY_CAMPAIGN_OBJECTIVE_POST_ENGAGEMENT: 'Envolvimento com publicação',
    STRATEGY_CAMPAIGN_OBJECTIVE_MESSAGES: 'Mensagens',
    STRATEGY_CAMPAIGN_OBJECTIVE_OFFER_CLAIMS: 'Obtenções da oferta',
    STRATEGY_CAMPAIGN_OBJECTIVE_PAGE_LIKES: 'Curtidas na página',
    STRATEGY_CAMPAIGN_OBJECTIVE_PRODUCT_CATALOG_SALES: 'Vendas do catálogo',
    INVOLVEMENT: 'Envolvimento',
    RATE_INVOLVEMENT: 'Taxa de envolvimento',
    LINK_CLICKS: 'Cliques no Link',

    impressions_desc:
      'Mostra a quantidade de vezes que o anúncio foi exibido no feed para o público. Caso apareça em diferentes momentos para a mesma pessoa, mais uma impressão é contada. Mas atenção: para vídeos a impressão é contada mesmo sem o vídeo ser iniciado.',

    videoViews_desc:
      'Mostra a quantidade de visualizações em anúncios de vídeo, a partir de 3 segundos.',
    engagement_desc:
      'Mostra a soma de reações, comentários e compartilhamentos, visualização de foto ou vídeo, clique em link ou pedido em ofertas.',
    involvement_desc:
      'Mostra o número total de engajamentos (soma de reações, comentários e compartilhamentos, visualização de foto, clique em link ou pedido em ofertas) menos o número de visualizações.',
    link_clicks_desc: 'Mostra a quantidade de cliques nos links dos anúncios.',
    purchase_desc:
      'Mostra o número de compras feitas no site que tenham sido finalizadas através de algum anúncio. É monitorado pelo Pixel do próprio Facebook instalado em seu site.',
    revenue_desc:
      'Indica a receita das vendas no site, obtido pelo monitoramento do Pixel do Facebook.',
    ROAS_desc:
      'Retorno sobre os gastos com publicidade: indica o sucesso de seus anúncios. É calculado pela receita online dividida pelo investimento.',
    CPM_desc:
      'Custo por mil: calcula o preço a cada mil impressões de anúncio. Sua fórmula é: quanto já investiu, dividido pelo número de impressões e multiplicado por mil.',
    CPV_desc:
      'Custo por visualização: calcula o preço de cada visualização de vídeo anunciado. Sua fórmula é: quanto já investiu, dividido pelo número de visualizações.',
    CPE_desc:
      'Custo por engajamento: calcula o valor investido para cada engajamento. Sua fórmula é: valor investido dividido por engajamento.',
    ER_desc:
      'Taxa de engajamento: calcula a porcentagem de pessoas que engajaram com seu conteúdo. Sua fórmula é: número de engajamentos dividido por impressões, vezes 100.',
    CPC_desc:
      'Custo por clique no link: calcula o preço de cada clique no link em anúncios. Sua fórmula é: valor investido dividido por quantidade de cliques no link.',
    CTR_desc:
      'Taxa de cliques: calcula a porcentagem de pessoas que viram o anúncio e clicaram em alguma parte. Sua fórmula é: número de cliques dividido por impressões vezes 100.',
    CPA_desc:
      'Custo por aquisição: calcula o valor investido para gerar uma compra em seu site. Sua fórmula é: valor investido dividido por aquisição (compra).',
    CR_desc:
      'Taxa de conversão: calcula a porcentagem de pessoas que realizaram a conversão proposta, seja compra, seja completar um formulário, entre outras. Sua fórmula é: número de conversões dividido por cliques vezes 100.',
    impressions_desc_pages:
      'Mostra a quantidade de vezes que os conteúdos foram exibidos no feed para o público, sem investimento na publicação. Caso o conteúdo apareça em diferentes momentos para a mesma pessoa, mais uma impressão é contada. Mas atenção: para vídeos a impressão é contada mesmo sem o vídeo ser iniciado.',
    videoViews_desc_pages:
      'Mostra a quantidade de visualizações orgânicas (sem verba investida) em vídeos, a partir de 3 segundos.',
    involvement_desc_pages: 'Indica apenas a soma de reações.',
    engagement_desc_pages: 'Indica apenas a soma de reações.',
    link_clicks_desc_pages: 'Mostra a quantidade de cliques orgânicos nos links dos posts.',
    conversations_started_desc: 'Mostra a quantidade de conversas iniciadas.',
    conversations_new_desc:
      'Indica quantas conversas novas foram iniciadas através de seus anúncios.',
    CPNC_desc:
      'Custo por nova conversa: calcula o valor investido para iniciar uma nova conversa. Sua fórmula é: valor investido dividido pela quantidade de novas conversas iniciadas',
    offline_purchase_desc:
      'Mostra o número de compras feitas offline que tenham sido finalizadas e reportadas após interação com algum anúncio.',
    CPCO_desc:
      'Custo por aquisição: calcula o valor investido para gerar uma compra offline. Sua fórmula é: valor investido dividido por compras offline.',
    CPCI_desc:
      'Custo por conversa iniciada: calcula o valor investido para iniciar uma conversa. Sua fórmula é: valor investido dividido pela quantidade de conversas iniciadas',
    OROAS_desc:
      'Retorno sobre os gastos com publicidade: indica o sucesso de seus anúncios. É calculado pela receita offline dividida pelo investimento.',
    offline_revenue_desc: 'Indica a receita das vendas feitas de forma offline.',
    reach_desc:
      'Mostra a quantidade de pessoas que o anúncio atingiu no feed do seu público. Caso apareça em diferentes momentos para a mesma pessoa, só um alcance é contado.',
    VR_desc:
      'Taxa de Visualização: calcula a porcentagem de visualizações em relação à quantidade de vezes que o anúncio foi exibido. Sua fórmula é: visualizações dividido pelas impressões vezes 100.',
    offline_conversion_initiate_checkout_desc: 'Mostra o número de compras via wpp',
    offline_initiate_checkout_conversion_value_desc: 'Indica a receita de vendas feitas pelo wpp',
    ROAS_GENERAL_desc:
      'O ROAS geral, é calculado por (receita online + receita offline)/(investido)',
  },
};

const TableES = {
  Table: {
    DOWNLOAD: 'Download',
    SUMMARY: 'Total',
    IMPRESSIONS: 'Impresiones',
    REACHES: 'Alcance',
    VIDEO_VIEWS: 'Visualizaciones',
    ENGAGEMENT: 'Involucramientos',
    RATE_ENGAGEMENT: 'Tasa de participación',
    SPEND: 'Invertido',
    OPEN: 'Abrir',
    PAGES: 'Páginas',
    STRATEGIES: 'Estratégias',
    ACCOUNTS: 'Cuentas',
    GROUPS: 'Grupos',
    GROUP: 'Grupo',
    'NO-DATA': 'Não há dados.',
    STRATEGY_CAMPAIGN_OBJECTIVE_APP_INSTALLS: 'Instalaciones de aplicaciones',
    STRATEGY_CAMPAIGN_OBJECTIVE_BRAND_AWARENESS: 'Reconocimiento de marca',
    STRATEGY_CAMPAIGN_OBJECTIVE_CONVERSIONS: 'Conversiones',
    STRATEGY_CAMPAIGN_OBJECTIVE_EVENT_RESPONSES: 'Participación en el evento',
    STRATEGY_CAMPAIGN_OBJECTIVE_LEAD_GENERATION: 'Generación de registros',
    STRATEGY_CAMPAIGN_OBJECTIVE_LINK_CLICKS: 'Clics al sitio web',
    STRATEGY_CAMPAIGN_OBJECTIVE_VIDEO_VIEWS: 'Visualización de vídeos',
    STRATEGY_CAMPAIGN_OBJECTIVE_REACH: 'Alcance',
    STRATEGY_CAMPAIGN_OBJECTIVE_LOCAL_AWARENESS: 'Reconocimiento de tienda',
    STRATEGY_CAMPAIGN_OBJECTIVE_POST_ENGAGEMENT: 'Envolvimento con la publicación',
    STRATEGY_CAMPAIGN_OBJECTIVE_MESSAGES: 'Mensajes',
    STRATEGY_CAMPAIGN_OBJECTIVE_OFFER_CLAIMS: 'Obtenções da oferta',
    STRATEGY_CAMPAIGN_OBJECTIVE_PAGE_LIKES: 'Gustos de lá página',
    STRATEGY_CAMPAIGN_OBJECTIVE_PRODUCT_CATALOG_SALES: 'Ventas por catalogo',
    INVOLVEMENT: 'Envolvimiento',
    RATE_INVOLVEMENT: 'Cuota de envolvimiento',
    LINK_CLICKS: 'Clics en enlaces',

    impressions_desc:
      'Muestra el número de veces que el anuncio se ha mostrado en el feed a la audiencia. Si aparece en diferentes momentos para la misma persona, se cuenta una impresión más. Pero cuidado: en el caso de los videos, la impresión se cuenta incluso sin que se haya iniciado el video.',

    videoViews_desc:
      'Muestra la cantidad de vistas en anuncios de video durante más de 3 segundos.',
    engagement_desc:
      'Muestra la suma de reacciones, comentarios y compartidos, vista de foto o video, clic en el enlace o un pedido en las ofertas.',
    involvement_desc:
      'Muestra el número total de interacciones (suma de reacciones, comentarios y compartidos, vista de fotos, clic en enlace o pedido en ofertas) menos el número de vistas.',
    link_clicks_desc: 'Muestra la cantidad de clics en enlaces de anuncios.',
    purchase_desc:
      'Muestra el número de compras realizadas en el sitio que se han completado a través de un anuncio. Es monitoreado por el Pixel de Facebook instalado en su sitio web.',
    revenue_desc:
      'Indica los ingresos por ventas en el sitio web, obtenidos al monitorear el Pixel de Facebook.',
    ROAS_desc:
      'Retorno de la inversión publicitaria: indica el éxito de sus anuncios. Se calcula dividiendo los ingresos online por la inversión.',
    CPM_desc:
      'Costo por mil: calcula el precio por mil impresiones de anuncios. Su fórmula es: cuánto has invertido ya, dividido por el número de impresiones y multiplicado por mil.',
    CPV_desc:
      'Costo por vista: calcula el precio de cada vista de video anunciada. Su fórmula es: cuánto ya ha invertido, dividido por el número de visitas.',
    CPE_desc:
      'Costo por participación: calcula la cantidad invertida para cada participación. Su fórmula es: monto invertido dividido por compromiso.',
    ER_desc:
      'Tasa de interacción: calcula el porcentaje de personas que han interactuado con su contenido. Su fórmula es: número de interacciones dividido por impresiones multiplicado por 100.',
    CPC_desc:
      'Costo por clic en el vínculo: calcula el precio de cada clic en el enlance en los anuncios. Su fórmula es: cantidad invertida dividida por la cantidad de clics en el enlace.',
    CTR_desc:
      'Tasa de clics: calcula el porcentaje de personas que vieron el anuncio e hicieron clic en algún lugar. Su fórmula es: número de clics dividido por impresiones multiplicado por 100.',
    CPA_desc:
      'Costo por adquisición: calcula el monto invertido para generar una compra en su sitio web. Su fórmula es: monto invertido dividido por adquisición (compra).',
    CR_desc:
      'Tasa de conversión: calcula el porcentaje de personas que realizaron la conversión propuesta, ya sea comprando o completando un formulario, entre otros. Su fórmula es: número de conversiones dividido por clics multiplicado por 100. ',
    impressions_desc_pages:
      'Muestra el número de veces que se mostró el contenido en el feed al público, sin inversión en publicación. Si el contenido aparece en diferentes momentos para la misma persona, se cuenta una impresión más. Pero cuidado: en el caso de los videos, la impresión se cuenta incluso sin que se haya iniciado el video.',
    videoViews_desc_pages:
      'Muestra la cantidad de vistas orgánicas (sin fondos invertidos) en videos, durante más de 3 segundos.',
    involvement_desc_pages: 'Indica solo la suma de reacciones.',
    engagement_desc_pages: 'Indica solo la suma de reacciones.',
    link_clicks_desc_pages:
      'Muestra la cantidad de clics orgánicos en los enlaces de las publicaciones.',
    conversations_started_desc: 'Muestra la cantidad de conversaciones iniciadas.',
    conversations_new_desc:
      'Indica cuántas conversaciones nuevas se iniciaron a través de sus anuncios.',
    CPNC_desc:
      'Costo por nueva conversación: calcula el monto invertido para iniciar una nueva conversación. Su fórmula es: monto invertido dividido por el número de nuevas conversaciones iniciadas',
    offline_purchase_desc:
      'Muestra la cantidad de compras realizadas offline que se completaron y se informaron después de interactuar con un anuncio.',
    CPCO_desc:
      'Costo por adquisición: calcula el monto invertido para generar una compra offline. Su fórmula es: monto invertido dividido por compras offline.',
    CPCI_desc:
      'Costo por conversación iniciada: calcula el monto invertido para iniciar una conversación. Su fórmula es: monto invertido dividido por el número de conversaciones iniciadas',
    OROAS_desc:
      'Retorno de la inversión publicitaria: indica el éxito de sus anuncios. Se calcula dividiendo los ingresos offline por la inversión.',
    offline_revenue_desc: 'Indica los ingresos de las ventas realizadas offline.',
    reach_desc:
      'Muestra a cuántas personas ha llegado el anuncio en el feed de su audiencia. Si aparece en diferentes momentos para la misma persona, solo se cuenta un alcance.',
    VR_desc:
      'Tasa de vistas: calcula el porcentaje de vistas en relación con la cantidad de veces que se mostró el anuncio. Su fórmula es: vistas divididas por impresiones multiplicadas por 100.',
    offline_conversion_initiate_checkout_desc: 'Muestra el número de compras por Whatsapp.',
    offline_initiate_checkout_conversion_value_desc:
      'Indica a receita das vendas realizadas pelo WhatsApp.',
    ROAS_GENERAL_desc:
      'El ROAS general se calcula mediante (ingresos en línea + ingresos fuera de línea)/(invertido)',
  },
};

export { TablePT, TableEN, TableES };
