// Executar a função this.UploadImage(file, sas_url, id)

import axios from 'axios';

const instance = axios.create({
  headers: { 'x-ms-blob-typer': 'BlockBlob', 'access-control-allow-origin': '*' },
});

delete instance.defaults.headers.common.Authorization;

class UploadService {
  static uploadImage(uri, data) {
    return instance.put(uri, data);
  }
}

export default UploadService;
