import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import numeral from 'numeral';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import Highcharts from 'highcharts';
import solidGauge from 'highcharts/modules/solid-gauge';
import piramid from 'highcharts/modules/funnel';
import highchartsMore from 'highcharts/highcharts-more.js';
import { ToastContainer, toast as showToast } from 'react-toastify';
import { animateScroll as scroll } from 'react-scroll';
import { checkIfIsTradeView as checkIfIsTradeViewHelper } from '@utils/helpers';
import Loading from '@components/Loading/Loading';
import { TranslatorProvider } from 'react-translate';
import { translationsPT, translationsEN, translationsES } from '@src/translate';
import { useUser } from '@utils/contexts';
import MyRouter from '@src/router/router';
import { useDetectAdBlock } from 'adblock-detect-react';
import { CHANGE_LANGUAGE } from '@redux/translates/types';
import {
  FETCH_DOMAIN,
  USER_DATA_DECODED,
  GET_CURRENT_USER,
  AUTHENTICATE_BUSINESS,
  BUSINESS_DATA_DECODED,
} from '@redux/auth/types';

import '@fortawesome/fontawesome-pro/css/all.css';
import 'react-toastify/dist/ReactToastify.css';
import {
  getSubDomain,
  setTrackableCockie,
  getTrackableCockie,
  setBusinessID,
  getBusinessID,
} from '@utils/auth';

import * as S from './styles';
import { Warning } from '@components/Warning';
import { GET_CODE_INFORMATION, GET_CURRENT, GET_CURRENT_ITEM } from 'redux/generic/types';

import Zendesk, { ZendeskAPI, setZendeskVisibility } from '../Zendesk/zendesk';

highchartsMore(Highcharts);
piramid(Highcharts);
solidGauge(Highcharts);

const { gtag } = window;

function App() {
  const { language } = useSelector((state) => state.translatorReducer);
  const { toast } = useSelector((state) => state.toastReducer);
  const { isLoading } = useSelector((state) => state.loadingReducer);
  const {
    dataUser,
    businessId,
    businessTokenData,
    businessTokenData: { permitted_group },
  } = useSelector((state) => state.authenticationReducer);

  const { logout, subDomain, decodedBusinessToken, businessToken, invalidTenant } = useUser();
  const isTradeView = useRef(false);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const adBlockDetected = useDetectAdBlock();

  const localBusinessId = getBusinessID();
  function getTranslation() {
    if (language === 'pt-BR') {
      return translationsPT;
    }
    if (['es-es', 'es-ES'].includes(language)) {
      return translationsES;
    }
    return translationsEN;
  }

  function scrollTop() {
    scroll.scrollToTop();
  }

  const getPathWithIndex = useCallback(
    (index) => {
      return history.location.pathname.split('/')[index];
    },
    [history]
  );

  const code = getPathWithIndex(1);

  function redirectTo(route) {
    history.push(route);
  }

  function checkIfHaveGroupPermission() {
    const isRoot = code === 'root';

    if (permitted_group && isRoot) {
      return redirectTo(
        `/${permitted_group}/${getPathWithIndex(2)}/
                ${getPathWithIndex(3)}
                /${getPathWithIndex(4)}`
      );
    }
  }

  function checkIfIsTradeView() {
    if (checkIfIsTradeViewHelper()) {
      isTradeView.current = true;
      return true;
    }

    isTradeView.current = false;
    return false;
  }

  // ERM-369
  // function redirectToGlobal(decoded) {
  //   let customDecoded = null;

  //   if (isTradeView.current) {
  //     history.push('/root/Trade/Dashboard');
  //   } else if (decoded.bgd_capabilities) {
  //     customDecoded = JSON.parse(decoded.bgd_capabilities);
  //     if (
  //       decoded.permitted_group &&
  //       customDecoded.some((item) => item.name === 'APPROVAL') &&
  //       customDecoded.find((item) => item.name === 'APPROVAL').is_main_capability
  //     ) {
  //       redirectTo(`/${decoded.permitted_group}/Actions/Approvals/New`);
  //     } else if (
  //       !decoded.permitted_group &&
  //       customDecoded.some((item) => item.name === 'APPROVAL') &&
  //       customDecoded.find((item) => item.name === 'APPROVAL').is_main_capability
  //     ) {
  //       redirectTo('/root/Actions/Approvals/New');
  //     } else if (decoded.permitted_group) {
  //       redirectTo(`/${decoded.permitted_group}/Dashboard`);
  //     } else {
  //       redirectTo('/root/Dashboard');
  //     }
  //   } else if (decoded.bgd_capabilities_v2) {
  //     if (decoded.bgd_capabilities_v2.includes('TRADE_ADS_INDUSTRY')) {
  //       redirectTo('/root/TradeAds');
  //     } else if (decoded.permitted_group) {
  //       redirectTo(`/${decoded.permitted_group}/Dashboard`);
  //     } else {
  //       redirectTo('/root/Dashboard');
  //     }
  //   }
  // }

  function decodeBusinessToken() {
    let decoded = decodedBusinessToken;

    if (decoded && decoded.bgd_capabilities) {
      decoded = {
        ...decoded,
        bgd_capabilities: JSON.parse(decoded.bgd_capabilities),
      };
    } else if (decoded && decoded.bgd_capabilities_v2) {
      const obj = decoded.bgd_capabilities_v2.split(' ').reduce((acc, cur) => {
        const temp = cur.split(':');
        acc[temp[0]] = temp[1];
        return acc;
      }, {});

      decoded = {
        ...decoded,
        bgd_capabilities_v2: obj,
      };
    } else {
      logout();
    }

    dispatch({
      type: BUSINESS_DATA_DECODED.SUCCESS,
      decoded,
    });
  }

  useEffect(() => {
    if (subDomain) {
      checkIfIsTradeView();
      setBusinessID(subDomain.id);
      dispatch({
        type: FETCH_DOMAIN.REQUEST,
        version: '1.2',
        subDomain: subDomain.sub_domain || getSubDomain(),
      });

      if (localBusinessId && localBusinessId !== subDomain.id) {
        dispatch({
          type: AUTHENTICATE_BUSINESS.REQUEST,
          version: '1.2',
        });
      }
    }
  }, [subDomain]);

  useEffect(() => {
    if (!code || !dataUser.email) return;

    if (code === 'root') {
      dispatch({
        type: GET_CURRENT.REQUEST,
        version: '1.1',
      });
      dispatch({
        type: GET_CODE_INFORMATION.RESET,
      });
    } else {
      dispatch({
        type: GET_CODE_INFORMATION.REQUEST,
        version: '1.1',
        code,
      });
      dispatch({
        type: GET_CURRENT_ITEM.REQUEST,
        version: '1.1',
      });
    }
  }, [code, dispatch, dataUser.email]);

  useEffect(() => {
    if (decodedBusinessToken) {
      const decoded = decodedBusinessToken;
      dispatch({
        type: USER_DATA_DECODED.SUCCESS,
        decoded,
      });

      decodeBusinessToken();

      if (!decoded.email || decoded.email.indexOf('bornlogic') > 0) {
        setTrackableCockie(false);
      } else {
        setTrackableCockie(true);
      }
      // Dispatch adicionado para colocar o businessToken no redux
      dispatch({
        type: AUTHENTICATE_BUSINESS.SUCCESS,
        token: businessToken,
      });

      // redirectToGlobal(decoded);
    }
  }, [decodedBusinessToken]);

  useEffect(() => {
    if (subDomain !== null && dataUser?.user_name !== '') {
      let dataUserEmpty =
        subDomain.id === '' ||
        subDomain.name === '' ||
        dataUser.user_id === '' ||
        dataUser.user_name === '' ||
        dataUser.user_last_name === '' ||
        dataUser.email === '';

      if (dataUserEmpty) {
        logout();
      }
    }
  }, [dataUser, subDomain, logout]);

  useEffect(() => {
    if (Object.keys(businessTokenData).length !== 0) {
      checkIfHaveGroupPermission();
      dispatch({
        type: GET_CURRENT_USER.REQUEST,
        version: '1.2',
        id: businessTokenData.unique_name,
        search:
          'fields=user_id,role,status,language,user_name,user_last_name,avatar_url,email,permitted_groups',
      });
      scrollTop();
    }
  }, [businessTokenData]);

  useEffect(() => {
    if (
      location.pathname &&
      history.action === 'PUSH' &&
      typeof gtag === 'function' &&
      getTrackableCockie() === 'true'
    ) {
      gtag('config', 'UA-128083991-1', {
        page_location: window.location.href,
        page_path: window.location.hash.substring(1),
        user_id: dataUser.user_id,
        business_id: businessId,
      });
    }

    if (location.pathname) {
      scrollTop();
      setZendeskVisibility(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    // Update utilizado para exibir o toast na aplicação
    if (toast && toast.message) {
      showToast(toast.message, { type: toast.type });
    }
  }, [toast]);

  useEffect(() => {
    // Seta o language caso o usuário esteja na tela de login e não possua preferencia ainda buscada do usuário.
    if (!language) {
      const language = navigator.language === 'pt-BR' ? 'pt-BR' : 'en-US';
      dispatch({
        type: CHANGE_LANGUAGE.SUCCESS,
        language,
      });
      numeral.locale(language);
    }
  }, [language]);

  useEffect(() => {
    if (dataUser.language) {
      dispatch({
        type: CHANGE_LANGUAGE.SUCCESS,
        language: dataUser.language,
      });
      numeral.locale(dataUser.language);
    }
  }, [dataUser]);

  const adBlockAlert = useMemo(() => {
    if (adBlockDetected) {
      return (
        <Warning icon="ban" infinite>
          Diversas funcionalidades do GD são afetadas pelo ADBLOCK, recomendamos desligar.
        </Warning>
      );
    }

    return;
  }, [adBlockDetected]);

  const invalidTenantAlert = useMemo(() => {
    if (invalidTenant) {
      return <Warning icon="ban">Tenant não autorizado</Warning>;
    }

    return;
  }, [invalidTenant]);

  const handleZendeskLoaded = () => {
    if (location?.pathname) {
      setZendeskVisibility(location.pathname);
    } else {
      ZendeskAPI('messenger', 'show');
    }
  };

  const zendeskWidget = () => {
    if (process.env.REACT_APP_ZENDESK_KEY) {
      return <Zendesk
               defer
               zendeskKey={process.env.REACT_APP_ZENDESK_KEY}
               onLoaded={handleZendeskLoaded}
             />
    }
    return;
  };

  return (
    <TranslatorProvider translations={getTranslation()}>
      {invalidTenantAlert}
      {adBlockAlert}
      {zendeskWidget()}
      <S.AppWrapper isTradeView={isTradeView.current}>
        <MyRouter />
      </S.AppWrapper>
      <Loading isLoading={isLoading} />
      <S.ToastWrapper>
        <ToastContainer />
      </S.ToastWrapper>
    </TranslatorProvider>
  );
}

export default App;
