export function formatCurrency({ value }) {
  if (!value) return;
  let currency = value.replace(/[^a-z0-9]/gi, '');
  currency = currency.replace('R', '');
  return `${currency.substring(0, currency.length - 2)}.${currency.substring(
    currency.length - 2,
    currency.length
  )}`;
}

export function formatPercent(number) {
  return number
    ? Number(parseFloat(number))
        .toLocaleString(undefined, {
          style: 'percent',
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        })
        .replace('.', ',')
    : null;
}
