import type { IHandleRoutes, INewActionMenuLabel, Scope } from './types';

const ACTIONS_SIDEBAR = [{ capability: 'MEDIA_APPROVAL', menu: 'Approvals/New' }];

export const verifyScope: Record<Scope, string> = {
  Report: 'web_reports_clicked',
  Medias: 'web_media_clicked',
  Budget: 'web_budget_clicked',
  Communication: 'web_medias_clicked',
  TradeAds: 'web_tradeads_clicked',
  Planner: 'web_planner_clicked',
  CI: 'web_campaign_clicked',
  Dashboard: 'web_home_clicked',
  Insights: 'web_insights_clicked',
  Tracking: 'web_tracking_clicked',
  TradeCI: 'web_trade_ci_clicked',
};

function newActionMenuLabel({ labelActionsMenu, hasMediaApprovalCapability }: INewActionMenuLabel) {
  let menu = '';

  if (labelActionsMenu !== 'Medias') {
    menu = ACTIONS_SIDEBAR.find((item) => item.capability === labelActionsMenu)?.menu || '';
  }

  if (hasMediaApprovalCapability) {
    return (menu = 'Approvals/New');
  }
  return menu;
}

export function handleRoutes({
  scope,
  code,
  hasGDTVManageCapability,
  hasMediaApprovalCapability,
  labelActionsMenu,
}: IHandleRoutes) {
  const routes = {
    Dashboard: `Dashboard`,
    Report: `Report/Overview/Global`,
    Budget: `Budget/Listing/Groups`,
    Messages: `Messages/Main`,
    Medias: `Medias/${newActionMenuLabel({ hasMediaApprovalCapability, labelActionsMenu })}`,
    Communication: hasGDTVManageCapability ? `Communication/GDTV` : `Communication/Library`,
    TradeAds: `TradeAds`,
    Planner: `Planner`,
    Insights: `Trade/Dashboard`,
    Tracking: `Trade/Tracking`,
    TradeCI: 'CI',
    CI: 'CI',
  };

  return `/${code}/${routes[scope]}`;
}
