import React from 'react';
import { cloneElement } from 'react';
import { UserProvider } from '@utils/contexts';

const Providers = {
  UserProvider,
};

const providers = Object.keys(Providers).reduce((acc, curr) => {
  if (!curr.includes('Provider')) return acc;
  const Component = Providers[curr];
  return [...acc, <Component key={0} />];
}, []);

export const Store = ({ children: initial }) =>
  providers.reduce((children, parent) => cloneElement(parent, { children }), initial);
