const ChooseCompanyPT = {
  ChooseCompany: {
    CHOOSE_COMPANY_TITLE: 'Escolha em qual área de negócios deseja acessar:',
    CHOOSE_GO_BACK: 'Voltar',
  },
};

const ChooseCompanyEN = {
  ChooseCompany: {
    CHOOSE_COMPANY_TITLE: 'Choose which business area you want to access:',
    CHOOSE_GO_BACK: 'Back',
  },
};

export { ChooseCompanyPT, ChooseCompanyEN };
