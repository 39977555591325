import React, { ChangeEvent } from 'react';

import Select from 'components/Select';
import { SAVE_PROFILE } from 'redux/form/types';
import { CHANGE_LANGUAGE } from 'redux/translates/types';
import numeral from 'numeral';
import { useDispatch, useSelector } from 'react-redux';

const values = [
  {
    language: 'pt-BR',
    label: 'Português (BR)',
  },
  {
    language: 'en-US',
    label: 'English (US)',
  },
  {
    language: 'es-ES',
    label: 'Español (ES)',
  },
];

interface SelectLanguageProps {
  authenticationReducer: any;
  translatorReducer: any;
}

function SelectLanguage() {
  const dispatch = useDispatch();

  const { dataUser } = useSelector((state: SelectLanguageProps) => state.authenticationReducer);
  const { language } = useSelector((state: SelectLanguageProps) => state.translatorReducer);

  function handleLanguage(language: string) {
    let data = {};
    numeral.locale(language);
    dispatch({
      type: CHANGE_LANGUAGE.SUCCESS,
      language,
      changedByUser: true,
    });

    data = {
      language,
      user_name: dataUser.user_name,
      user_last_name: dataUser.user_last_name,
    };
    dispatch({
      type: SAVE_PROFILE.REQUEST,
      version: '1.0',
      data,
    });
  }

  return (
    <Select
      onChange={(e: ChangeEvent<HTMLSelectElement>) => handleLanguage(e.target.value)}
      selectValue={language}
    >
      {values.map((value) => (
        <option key={value.language} value={value.language}>
          {value.label}
        </option>
      ))}
    </Select>
  );
}

export default SelectLanguage;
