import { ApiTenant, bffFetcher } from '@utils/api';
import { getTimezone } from '@utils/helpers';

class GdtvService {
  static getGdtv(cursor) {
    let temp = '';
    if (cursor) {
      temp = `&cursor=${cursor}`;
    }
    return ApiTenant.get(`/gdtv/1.0/directory?limit=12&thumbnail_limit=${temp}`);
  }

  static createGdtvFolder(body) {
    return ApiTenant.post('/gdtv/1.0/directory', body);
  }

  static deleteGdtvFolder(id) {
    return ApiTenant.delete(`/gdtv/1.0/directory/${id}`);
  }

  static getSasurl(version) {
    return ApiTenant.get(`/business/${version}/blob/sas`);
  }

  static createMedia(body) {
    return ApiTenant.post('/gdtv/1.0/media', body);
  }

  static createCampaign(body) {
    return ApiTenant.post('/incentivecampaign/1.0/campaign', body);
  }

  static getCampaign(id) {
    return ApiTenant.get(`/incentivecampaign/1.0/campaign/${id}`);
  }

  static getCampaignReport(id) {
    return ApiTenant.get(`/incentivecampaign/1.0/report?incentive_campaign_id=${id}`);
  }

  static getCampaignFinancialMetrics(id) {
    return bffFetcher(`/api/v1/incentivecampaign/report/insights/${id}`);
  }

  static getCampaignFeed(id, permitted_groups) {
    return bffFetcher(
      `/api/v1/incentivecampaign/export/units/post-info?incentive_campaign_id=${id}&user_permitted_group=${permitted_groups}`
    );
  }

  static searchByUnitCampaignFeed({
    incentiveCampaignId,
    unitId,
    limit,
    timezoneOffset,
    pageHash,
  }) {
    let baseURL = `/incentivecampaign/1.0/report/infoposts/unit?incentive_campaign_id=${incentiveCampaignId}&unit_id=${unitId}`;

    if (timezoneOffset) baseURL += `&timezone_offset=${timezoneOffset}`;
    if (limit) baseURL += `&limit=${limit}`;
    if (pageHash) baseURL += `&${pageHash}`;

    return ApiTenant.get(baseURL);
  }

  static createCampaignFromTemplate(id, body) {
    return ApiTenant.post(`/incentivecampaign/1.0/template/${id}/campaign`, body);
  }
}

export default GdtvService;
