// Api
import { ApiTenant } from '@utils/api';

class SchemaService {
  static autoCompleteGroups(version, typed, types) {
    return ApiTenant.get(`/schema/${version}?q=${typed}&types=[${types}]`);
  }

  static example(version) {
    return ApiTenant.get(`/schema/${version}/campus/facebook/fanpages?&fields=name`);
  }

  static current(version) {
    return ApiTenant.get(`/schema/${version}/fundings/current`);
  }

  static getFeedItems(version, id, next, fields, feedtype, orderBy, limit, preset) {
    let urlType;
    let orderType = '';

    if (!preset) {
      preset = 'this_month';
    }

    if (feedtype === 'AD') {
      urlType = 'ads';
      if (orderBy === 'relevance') {
        orderType = '/relevance/v1?';
      } else if (orderBy === 'video_view' || orderBy === 'post_engagement') {
        orderType = `/relevance/v2?algorithm_metric=${orderBy}&date_preset=${preset}&`;
      } else {
        orderType = '?';
      }
    } else {
      urlType = 'fanpageposts?';
    }

    if (!limit) {
      limit = 20;
    }

    if (next) {
      return ApiTenant.get(
        `/schema/${version}/${id}/facebook/${urlType}${orderType}fields=${fields}&page_hash=${next}&limit=${limit}`
      );
    }
    return ApiTenant.get(
      `/schema/${version}/${id}/facebook/${urlType}${orderType}fields=${fields}&limit=${limit}`
    );
  }

  static getAdAccounts(version, id, search) {
    return ApiTenant.get(`/schema/${version}/${id}/facebook/adaccounts${search || ''}`);
  }

  static getFanpages(version, id, search) {
    return ApiTenant.get(`/schema/${version}/${id}/facebook/fanpages${search || ''}`);
  }

  static getReceived() {
    return Promise.resolve({});
  }

  static sendAnswer() {
    return Promise.resolve({});
  }

  static getCodeInfo(version, id, search) {
    return ApiTenant.get(`/schema/${version}/${id}${search}`);
  }

  static getCodeInfoChildren(groupId) {
    return ApiTenant.get(`/neo4j/api/1.0/groups/${groupId}/leaves`);
  }

  static postSearchField(version, data) {
    return ApiTenant.post(`/schema/${version}/search`, data);
  }

  static getParents(code) {
    return ApiTenant.get(`/schema/1.1/${code}/parents`);
  }

  static getCodeInformation(version, code) {
    return ApiTenant.get(`/schema/${version}/${code}?fields=name,type,budget`);
  }

  static postDeposit(version, budgetId, data) {
    return ApiTenant.post(`/schema/${version}/fundings/${budgetId}/deposit`, data);
  }

  static postWithdraw(version, budgetId, data) {
    return ApiTenant.post(`/schema/${version}/fundings/${budgetId}/withdraw`, data);
  }

  static validateWithdraw(sourceId, body) {
    return ApiTenant.post(`/1.0/${sourceId}/budget/batch/withdraw/validate`, body);
  }

  static newPostWithdraw(budgetId, data) {
    return ApiTenant.post(`/1.0/${budgetId}/budget/batch/withdraw`, data);
  }

  static getMediaById(id, code, fields) {
    return ApiTenant.get(`/schema/1.1/${code}/medias/${id}${fields ? `?fields=${fields}` : ''}`);
  }

  static getGroupsPermittedAccess(version, id, fields) {
    return ApiTenant.get(`/schema/${version}/${id}/groups${fields ? `?fields=${fields}` : ''}`);
  }

  static disapproveVideo(version, sourceId, data) {
    return ApiTenant.post(`/schema/${version}/${sourceId}/Videos/Disapprove`, data);
  }

  static disapproveImage(version, sourceId, data) {
    return ApiTenant.post(`/schema/${version}/${sourceId}/Images/Disapprove`, data);
  }

  static approveVideo(version, sourceId, data) {
    return ApiTenant.post(`/schema/${version}/${sourceId}/Videos/Approve`, data);
  }

  static approveImage(version, sourceId, data) {
    return ApiTenant.post(`/schema/${version}/${sourceId}/Images/Approve`, data);
  }

  static approveMultImg(version, sourceId, data) {
    return ApiTenant.post(`/schema/${version}/${sourceId}/creative/${data.image_id}/approve`, data);
  }

  static updateMultImg(version, sourceId, data) {
    return ApiTenant.put(`/schema/${version}/${sourceId}/creative/${data.image_id}`, data);
  }

  static disapproveMultImg(version, sourceId, data) {
    return ApiTenant.post(
      `/schema/${version}/${sourceId}/creative/${data.image_id}/disapprove`,
      data
    );
  }

  static changeDescriptionApprovals(version, permittedGroupId, mediaId, data) {
    return ApiTenant.put(`/schema/${version}/${permittedGroupId}/medias/${mediaId}`, data);
  }

  static changeBudgetAvailable(version, data, urlId) {
    return ApiTenant.post(`/schema/${version}/fundings/${urlId}/batch/deposit`, data);
  }

  static changeMontlyGoal(version, fundingId, data) {
    return ApiTenant.put(`/schema/${version}/fundings/${fundingId}`, data);
  }

  static getListingCount(version, tenantId, unit) {
    return ApiTenant.get(
      `/neo4j/api/${version}/count-entities?tenantId=${tenantId}&grouping=${unit}`
    );
  }

  static createGroup(version, parentId, data) {
    return ApiTenant.post(`/neo4j/api/${version}/${parentId}/groups`, data);
  }

  static moveGroup(version, parentId, data) {
    return ApiTenant.post(`/schema/${version}/${parentId}/relationships/member`, data);
  }

  static deleteGroup(version, sourceId) {
    return ApiTenant.delete(`/schema/${version}/${sourceId}`);
  }

  static renameGroup(version, sourceId, body) {
    return ApiTenant.put(`/schema/${version}/${sourceId}`, body);
  }

  static replaceAccountId(version, body) {
    return ApiTenant.post(`/setup/${version}/replace/adaccounts`, body);
  }

  static replacePageId(version, body) {
    return ApiTenant.post(`/setup/${version}/replace/pages`, body);
  }

  static validateAccountId(version, body) {
    return ApiTenant.post(`/setup/${version}/replace/adaccounts/validate`, body);
  }

  static validatePageId(version, body) {
    return ApiTenant.post(`/setup/${version}/replace/pages/validate`, body);
  }

  static changeGroupColor(version, sourceId, body) {
    return ApiTenant.put(`/neo4j/api/${version}/groups/${sourceId}/color`, body);
  }

  static getGroupColors(version) {
    return ApiTenant.get(`/neo4j/api/${version}/groups/basecolor`);
  }

  static updateAd(version, code, adId, data) {
    return ApiTenant.post(`/schema/${version}/${code}/facebook/ads/${adId}`, data);
  }

  static getSummary(version, code, period) {
    const hasPeriod = period ? `&${period.params}` : '';
    return ApiTenant.get(`/schema/${version}/Activities/${code}/summary?${hasPeriod}`);
  }
  static getChildGroups(groupId, isTiktok = false) {
    return ApiTenant.get(
      `/schema/1.1/${groupId}/ChildGroups?fields=child_groups_count,source_id,name,${
        isTiktok ? 'child_tiktok_units_count,child_tiktok_groups_count' : ''
      }`
    );
  }

  static getLeadForm(sourceId) {
    return ApiTenant.get(`/1.0/${sourceId}/MasterFormLead`);
  }

  static validateAddBudget(sourceId, body) {
    return ApiTenant.post(`/1.0/${sourceId}/budget/batch/deposit/validate`, body);
  }

  static depositBudget(sourceId, body) {
    return ApiTenant.post(`/1.0/${sourceId}/budget/batch/deposit`, body);
  }

  static getElegibleGroups(groupId) {
    return ApiTenant.get(`/schema/1.2/${groupId}/elegible?fields=source_id,name`);
  }

  static getGDTVGroups() {
    return ApiTenant.get('/gdtv/1.0/directory');
  }

  static getLastGroups(groupId) {
    return ApiTenant.get(`/schema/1.1/${groupId}/LastGroups`);
  }

  static getOverallActivities(sourceId, period) {
    return ApiTenant.get(`/schema/1.1/Activities/${sourceId}?${period}&timezone_offset=-3`);
  }

  static getActiveUnits(sourceId, period, limitedToFive = false) {
    return ApiTenant.get(
      `/schema/1.1/Activities/${sourceId}/Unit/Rank?${period}&timezone_offset=-3${
        limitedToFive ? '&limit=5' : ''
      }`
    );
  }

  static getConnectedUsers() {
    return ApiTenant.get('/business/1.1/Users/AccessLog');
  }

  static getChartData(sourceId, period) {
    return ApiTenant.get(`/schema/1.1/Activities/${sourceId}/Grouped?${period}`);
  }

  static getUnitsTopData(sourceId, period) {
    return ApiTenant.get(
      `/schema/1.1/Activities/${sourceId}/Unit/Top?${period}&timezone_offset=-3`
    );
  }

  static getBudgetDates() {
    return ApiTenant.get('/schema/1.0/budget?fields=id,start_date,end_date');
  }

  static getChildBudget(sourceId, budgetId) {
    return ApiTenant.get(
      `/schema/1.1/fundings/${sourceId}/ByDate?budget_id=${budgetId}&since=2027-7-8&until=2027-8-7`
    );
  }

  static getSavedAudiences(version, sourceId) {
    return ApiTenant.get(
      `/schema/${version}/${sourceId}/Facebook/SavedAudiences?budget_type=shared`
    );
  }

  static getMonitoringUnits(source_id, startDate, endDate, withInactive) {
    return ApiTenant.get(
      `/schema/1.1/Activities/${source_id}/List?since=${startDate}&until=${endDate}&with_inactive=${withInactive}&timezone_offset=-3`
    );
  }

  static getFundingHistory(id) {
    return ApiTenant.get(`/schema/1.2/${id}/fundinghistory`);
  }

  static getBrandUnits(source_id, brand) {
    return ApiTenant.get(`/schema/1.1/${source_id}/Leaves?parent_name=${brand}`);
  }

  static getDataBudgetGroups(sourceId, pageHash, budgetId = null) {
    const budgetPeriodFilter = budgetId !== null ? `&budget_id=${budgetId}` : '';

    return ApiTenant.get(
      `/1.0/${sourceId}/budget?page_hash=${pageHash}&limit=20${budgetPeriodFilter}`
    );
  }

  static confirmIntelligentDistribution(sourceId) {
    return ApiTenant.get(`/1.0/${sourceId}/budget/distribution/proposal`);
  }

  static putDistributionProposal(sourceID, body) {
    return ApiTenant.put(`/1.0/${sourceID}/budget/distribution/proposal`, body);
  }

  static getCurrentFundings() {
    return ApiTenant.get('/schema/1.1/fundings/current');
  }

  static getFundingsByHistory(sourceId, historyBudgetId) {
    return ApiTenant.get(`/1.0/${sourceId}/budget/bydate?budget_id=${historyBudgetId}`);
  }

  static getSpendBudget(body, startDate, endDate, timezoneOffset) {
    return ApiTenant.post(
      `/insights/1.1?since=${startDate}&until=${endDate}&timezone_offset=${timezoneOffset}&fields=spend`,
      body
    );
  }

  static getTotalValueBudgetCard(sourceId, budgetId) {
    const budgetPeriodFilter = budgetId !== null ? `?budget_id=${budgetId}` : '';

    return ApiTenant.get(`/1.0/${sourceId}/budget/summary${budgetPeriodFilter}`);
  }

  static putNewGoalPeriodValue(historyBudgetId, body) {
    return ApiTenant.put(`/1.0/root/budget/${historyBudgetId}`, body);
  }

  static getMoneySpent(sourceId) {
    return ApiTenant.get(`/1.0/${sourceId}/budget/situation`);
  }
  static resetBudget(body, budgetID) {
    return ApiTenant.post(`/schema/1.1/fundings/${budgetID}/lock`, body);
  }
}

export default SchemaService;
