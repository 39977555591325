import useSWR from 'swr';
import { bffFetcher } from '@utils/api';

export function useGetActions() {
  const { data, error, mutate } = useSWR('/api/v1/actionscore/all', bffFetcher, {
    revalidateOnFocus: false,
  });

  return {
    response: data || null,
    loading: !error && !data,
    error: error?.message,
    mutate,
  };
}
