const MixedChartsEN = {
  MixedCharts: {
    ALLOCATED_MONEY: 'Allocated money',
    INVESTED_MONEY: 'Invested money',
    BUDGET_CONSUMPTION_RATE: 'Percentage of use',
  },
};

const MixedChartsPT = {
  MixedCharts: {
    ALLOCATED_MONEY: 'Verba alocada',
    INVESTED_MONEY: 'Verba investida',
    BUDGET_CONSUMPTION_RATE: 'Percentual de uso',
  },
};

export { MixedChartsEN, MixedChartsPT };
