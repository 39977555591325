import React from 'react';
import { MixPanelController } from '../../../controllers';

export default function RadioGroupWithMixPanel({
  options = [],
  name,
  value,
  onChange,
  className,
  ...props
}) {
  function handleChange(event) {
    const eventName = event.target.attributes?.eventname?.value;
    if (onChange) onChange(event);
    if (eventName) {
      const mixPanel = new MixPanelController({ eventName, eventType: 'click' });
      if (mixPanel) mixPanel.createEvent();
    }
  }

  return (
    <div data-testid={'testMixPanelRadioGroup'} className="radio-container-mix-panel">
      {options.map((option, index) => {
        return (
          <div key={index} className={className}>
            <input
              type="radio"
              name={name}
              value={option.value}
              onChange={handleChange}
              id={`${name}-${option.value}`}
              checked={value == option.value}
              eventname={option?.eventName}
              {...props}
            />
            <label htmlFor={`${name}-${option.value}`}>{option.label}</label>
          </div>
        );
      })}
    </div>
  );
}
