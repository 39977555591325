export const SelectRoleEN = {
  SelectRole: {
    EMPLOYEE: 'Employee',
    ADMIN: 'Administrator',
  },
};

export const SelectRoleES = {
  SelectRole: {
    EMPLOYEE: 'Colaborador',
    ADMIN: 'Administrador',
  },
};

export const SelectRolePT = {
  SelectRole: {
    EMPLOYEE: 'Colaborador',
    ADMIN: 'Administrador',
  },
};
