// Api
import { ApiTenant } from '@utils/api';

class MediasService {
  static getFolders(body) {
    body.media_tracking_info = true;
    return ApiTenant.post('/csa/media-set/list', body);
  }

  static getGdtvFolders(body) {
    let type = '';

    if (body.type !== 'all') {
      type = `&type=${body.type}`;
    }

    return ApiTenant.get(
      `/gdtv/1.0/directory?limit=${body.limit}&thumbnail_limit=${body.thumbnail_limit}${
        body.cursor ? `&cursor=${body.cursor}` : ''
      }${type}&incentive_campaign_situation=${body.incentive_campaign_situation}`
    );
  }

  static getCampaignFolders(body) {
    let type = '';
    let tagged = '&tagged=false';

    if (body.type !== 'all') {
      type = `&type=${body.type}`;
    }

    if (body.tagged) {
      tagged = '&tagged=true';
    }

    return ApiTenant.get(
      `/gdtv/1.0/directory?limit=${body.limit}&thumbnail_limit=${body.thumbnail_limit}&cursor=${body.cursor}${type}&incentive_campaign_situation=${body.incentive_campaign_situation}&fields=${body.fields}${tagged}`
    );
  }

  static getCreative(id) {
    return ApiTenant.get(`/csa/media-set/get/${id}`);
  }

  static setCreativeInfo(id, body) {
    return ApiTenant.put(`/csa/media-set/rename/${id}`, body);
  }

  static getCreativeMedias(id, body) {
    body.media_tracking_info = true;
    return ApiTenant.post(`/csa/media/list/${id}`, body);
  }

  static downloadMedia(id) {
    return ApiTenant.get(`/csa/link/media/${id}`);
  }

  static downloadGdtvMedia(id) {
    return ApiTenant.get(`/gdtv/1.0/media/${id}`);
  }

  static downloadCampaignMedia(id) {
    return ApiTenant.get(`/gdtv/1.0/media/${id}`);
  }

  static getMedia(id) {
    return ApiTenant.get(`/csa/link/media/${id}/original`);
  }

  static postCreative(body) {
    return ApiTenant.post('/csa/media-set/create', body);
  }

  static postMedia(id, body) {
    return ApiTenant.postFormData(`/csa/media/add/${id}`, body);
  }

  static deleteMedia(id) {
    return ApiTenant.delete(`/csa/media/remove/${id}`);
  }

  static removeCreative(id) {
    return ApiTenant.delete(`/csa/media-set/remove/${id}`);
  }

  static createMedia(body, cancel) {
    return ApiTenant.post('/csa/upload/create', body, cancel);
  }

  static createGdtvMedia(body, cancel) {
    return ApiTenant.post('/csa/upload/create', body, cancel);
  }

  static uploadMediaChunk(body, cancel) {
    return ApiTenant.postFormData('/csa/upload/chunk', body, cancel);
  }

  static getTemplate(id) {
    return ApiTenant.get(`/dc-api/v1.0/templates/${id}`);
  }

  static shareTemplate(id, body) {
    return ApiTenant.post(`/dc-api/v1.0/templates/${id}/image/async`, body);
  }

  static getSharedGroups(id) {
    return ApiTenant.get(`/csa/media-set/shared/${id}`);
  }

  static getTemplateSharedGroups(id) {
    return ApiTenant.get(`/csa/media-set/shared/${id}/groups`);
  }

  static shareToGroups(id, body) {
    return ApiTenant.post(`/csa/media-set/share/${id}/groups`, body);
  }

  static getGdtvFolder(id) {
    return ApiTenant.get(`/gdtv/1.0/directory/${id}`);
  }

  static getCampaignFolder(id) {
    return ApiTenant.get(`/gdtv/1.0/directory/${id}`);
  }

  static deleteGdtvFolder(id) {
    return ApiTenant.delete(`/gdtv/1.0/directory/${id}`);
  }

  static disableGdtvFolder(id) {
    return ApiTenant.patch(`/gdtv/1.0/directory/inactivate/${id}`);
  }

  static enableGdtvFolder(id) {
    return ApiTenant.patch(`/gdtv/1.0/directory/activate/${id}`);
  }

  static getGdtvMedias(id) {
    return ApiTenant.get(`/gdtv/1.0/media?directory_id=${id}`);
  }

  static getCampaignMedias(id) {
    return ApiTenant.get(`/gdtv/1.0/media?directory_id=${id}`);
  }

  static editGdtvFolder(id, body) {
    return ApiTenant.put(`/gdtv/1.0/directory/${id}`, body);
  }

  static deleteGdtvMedia(id) {
    return ApiTenant.delete(`/gdtv/1.0/media/${id}`);
  }

  static getPosts(id, since, until) {
    return ApiTenant.get(
      `/mediatracking/1.0/usage/filter?origin_id=${id}&origin_type=media_set&destination_type=post&since=${since}&until=${until}&limit=10&order=asc&cursor=`
    );
  }

  static getAds(id, since, until) {
    return ApiTenant.get(
      `/mediatracking/1.0/usage/filter?origin_id=${id}&origin_type=media_set&destination_type=ad&since=${since}&until=${until}&limit=10&order=asc&cursor=`
    );
  }

  static getCampaignViews(id) {
    return ApiTenant.get(`/incentivecampaign/1.0/report/campaign/view?incentive_campaign_id=${id}`);
  }

  static getGdtvViews(id) {
    return ApiTenant.get(`/gdtv/1.0/directory/${id}/views`);
  }

  static putCampaignStatus(id, body) {
    return ApiTenant.put(`/incentivecampaign/1.0/campaign/${id}`, body);
  }

  static postAdvertsStatus(id, body) {
    return ApiTenant.post(`/incentivecampaign/1.0/campaign/${id}/Asset/Status`, body);
  }

  static postReward(campaignID, body) {
    return ApiTenant.post(`/incentivecampaign/1.0/campaign/${campaignID}/reward`, body);
  }

  static getCampaignRewards(campaignID) {
    return ApiTenant.get(`/incentivecampaign/1.0/campaign/${campaignID}/reward`);
  }

  static postCampaignChangeStatus(sourceId, facebookAdId, body) {
    return ApiTenant.post(`/schema/1.1/${sourceId}/Facebook/Ads/${facebookAdId}`, body);
  }

  static getTradeAdsMetrics(tenantID, tag, datePreset) {
    if (tenantID == null) return;
    const { since, sinceToCompare } = datePreset;

    const url = {
      since_current: since.startOfCurrentMonth,
      until_current: since.currentDayOfMonth,
      since_previous: sinceToCompare.startOfLastMonth,
      until_previous: sinceToCompare.currentDayOfLastMonth,
      fields: 'group_view',
      incentive_campaign_status: 'Default',
      tenantID,
      tags: tag,
    };

    return ApiTenant.get(
      `/incentivecampaign/1.0/Exchange/Campaigns/Report/TradeAds?${new URLSearchParams(
        url
      ).toString()}`
    );
  }

  static getCampaign(id) {
    return ApiTenant.get(`/incentivecampaign/1.0/campaign/${id}`);
  }

  static async postCampaignCSV(body) {
    return await ApiTenant.postFormData(`/api/v1/incentivecampaign/process-leaves`, body);
  }
}

export default MediasService;
