import React from 'react';
import { ModalConfirmation } from '@bornlogic/gd-design-system';
import { useShowToast } from '@utils/hooks/Common';
import { useTranslate } from 'react-translate';
import { SettingsService } from '@services';

const ActivateUnits = (props) => {
  const { isOpen, onClose, rowsSelected, onSuccess } = props;
  const t = useTranslate('Units');
  const showToast = useShowToast();

  const handleActivateUnits = async () => {
    try {
      const source_id = rowsSelected[0]?.source_id;
      await SettingsService.activateUnits(source_id, {});

      showToast(t('ACTIVATE_UNIT_TOAST_SUCCESS'), 'success');
      onSuccess();
      onClose();
    } catch (err) {
      const errorMessage = err.response.data.error;

      showToast(
        t('ACTIVATE_UNIT_TOAST_ERROR', {
          errorMessage: typeof errorMessage === 'string' ? errorMessage : '',
        }),
        'error'
      );
    }
  };

  return (
    <ModalConfirmation
      isOpen={isOpen}
      title={t('ACTIVATE_UNIT')}
      confirmBtnText={t('CONTINUE')}
      onConfirmBtnClick={handleActivateUnits}
      optionBtnText={t('CANCEL')}
      onOptionBtnClick={onClose}
    />
  );
};

export default ActivateUnits;
