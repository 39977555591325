const ReleaseNotesModalEN = {
  ReleaseNotesModal: {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    10: 'October',
    11: 'November',
    12: 'December',
    MODAL_TITLE: "What's new",
    BUTTON_TEXT: 'See more',
  },
};

const ReleaseNotesModalPT = {
  ReleaseNotesModal: {
    '01': 'Janeiro',
    '02': 'Fevereiro',
    '03': 'Março',
    '04': 'Abril',
    '05': 'Maio',
    '06': 'Junho',
    '07': 'Julho',
    '08': 'Agosto',
    '09': 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro',
    MODAL_TITLE: 'Novidades no produto',
    BUTTON_TEXT: 'Saiba mais',
  },
};

const ReleaseNotesModalES = {
  ReleaseNotesModal: {
    '01': 'Enero',
    '02': 'Febrero',
    '03': 'Marzo',
    '04': 'Abril',
    '05': 'Mayo',
    '06': 'Junio',
    '08': 'Agosto',
    '07': 'Julio',
    '09': 'Septiembre',
    10: 'Octubre',
    11: 'Noviembre',
    12: 'Diciembre',
    MODAL_TITLE: 'Novedades en el producto',
    BUTTON_TEXT: 'Sepa mas',
  },
};

export { ReleaseNotesModalEN, ReleaseNotesModalES, ReleaseNotesModalPT };
