// Modules
import axios from 'axios';

const instancePublic = axios.create({});

class MockService {
  static getRankingMocks() {
    return instancePublic.get('http://www.mocky.io/v2/5db1e81c3500002d00f54dcb');
  }
}

export default MockService;
