import React from 'react';
import ErrorPage from 'containers/ErrorPage';

const INITIAL_ERROR_STATE = {
  hasError: false,
  error: { message: '', stack: '' },
};

class ErrorBoundary extends React.Component {
  state = INITIAL_ERROR_STATE;

  constructor(props) {
    super(props);
  }

  static getDerivedStateFromError = (error) => {
    return { hasError: true, error };
  };

  resetError = () => {
    this.setState(INITIAL_ERROR_STATE);
  };

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;
    return hasError ? (
      <ErrorPage isFrontError error={error} resetError={this.resetError} />
    ) : (
      children
    );
  }
}

export default ErrorBoundary;
