/* Create action types */
import createActionTypes from '../createActionTypes';

export const GET_BUDGET_GROUP = createActionTypes('GET_BUDGET_GROUP');
export const CHANGE_BUDGET = createActionTypes('CHANGE_BUDGET');
export const CHANGE_BUDGET_AVAILABLE = createActionTypes('CHANGE_BUDGET_AVAILABLE');
export const GET_BUDGET_ACCOUNT = createActionTypes('GET_BUDGET_ACCOUNT');
export const POST_DEPOSIT = createActionTypes('POST_DEPOSIT');
export const POST_WITHDRAW = createActionTypes('POST_WITHDRAW');
export const POST_RESET = createActionTypes('POST_RESET');
export const SET_BUDGET_HISTORY = createActionTypes('SET_BUDGET_HISTORY');
export const SET_BUDGET_ORDER = createActionTypes('SET_BUDGET_ORDER');
