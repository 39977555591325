import styled, { css } from 'styled-components';

export const SizeModal = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    width: ${theme.spacing.full};
    padding-bottom: ${theme.spacing.px20};

    @media screen and (max-height: ${theme.breakpoint.sm}) {
      .rdrDefinedRangesWrapper {
        display: none;
      }
      .rdrCalendarWrapper {
        font-size: 60%;
      }
    }
  `}
`;

interface WidthModalProps {
  hideStaticRanges?: boolean;
}

export const WidthModal = styled.div<WidthModalProps>`
  ${({ hideStaticRanges }) => css`
    display: inline-block;
    width: 60%;
    ${hideStaticRanges &&
    css`
      .rdrDefinedRangesWrapper {
        display: none;
      }
    `}
  `}
`;

export const Container = styled.div<WidthModalProps>`
  ${({ theme, hideStaticRanges }) => css`
    margin: ${theme.spacing.px16} 0;

    ${hideStaticRanges &&
    css`
      .rdrDefinedRangesWrapper {
        display: none;
      }
    `}

    @media screen and (max-width: ${theme.breakpoint.lg}) {
      .rdrDefinedRangesWrapper {
        display: none;
      }

      .rdrCalendarWrapper {
        font-size: 60%;
      }
    }
  `}
`;
