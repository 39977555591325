const MediasEN = {
  Medias: {
    OF: 'of',
    PARTICIPATE_INFO: '{{amount}}% of the units participated',
    CONSUMED_BUDGET_INFO: '{{amount}}% of the budget consumed',
    FINISH_CAMPAIGN: 'Finish campaign',
    EDIT_INFO: 'Edit info',
    PERIOD: 'Period',
    MEDIA: 'Media',
    MEDIA_ALT: 'Media',
    SHARE: 'Share link',
    MADE_IN: 'Made in',
    COPY_LINK: 'Copy link',
    LIBRARY_TITLE: 'Media Library',
    CREATE_COM: 'Create notice',
    CREATE_CAMPAIGN: 'Create Campaign',
    CREATE_GDTV: 'Create GDTV',
    CREATE_FOLDER: 'Create folder',
    FINISH: 'Finish',
    NEXT_STEP: 'Next',
    SEND: 'Send',
    GO_BACK: 'Go back',
    CANCEL: 'Cancel',
    SAVE: 'Save',
    CONFIRM: 'Confirm',
    CLOSE: 'Close',
    TYPE_HERE: 'Type here...',
    TYPE_HERE_YOUR_MESSAGE: 'Type your message here...',
    FILE_TITLE: 'Upload your files',
    NAME_TITLE: 'Enter the name for the folder',
    SHARE_TITLE: 'Who do you want to share it with?',
    DESCRIPTION_TITLE: 'Send the description',
    SEARCH_PLACEHOLDER: 'Search',
    SELECT_ALL: 'Select all',
    UNSELECT_ALL: 'Unselect all',
    TAB_GROUPS: 'Groups',
    TAB_ACCOUNTS: 'Accounts',
    TAB_PAGES: 'Pages',
    POST_FOLDER_FAILURE_MESSAGE: 'Failed to create this folder, please try again later!',
    POST_FOLDER_SUCCESS_MESSAGE: 'Folder created successfully!',
    EMPTY_FOLDERS_TITLE: 'Created folders are displayed here.',
    START_NOW: 'Start now:',
    LEVEL_UP: 'Level up',
    OPEN_SUBGROUP: 'Open',
    ACTIVATE_CAMPAIGN: 'Activate Campaign',
    PAUSE_CAMPAIGN: 'Pause Campaign',

    CHOOSE_FILES: 'Choose files',
    FILES_CHOSEN: 'Files chosen',
    FILES_INFO_TITLE: 'Click the button below and choose your images and videos.',
    FILES_INFO_DESCRIPTION: 'Allowed extensions: jpeg, jpg, png, mp4 and webm.',
    CREATE_NEW_CAMPAIGN: 'Create campaign',
    INCENTIVE_CAMPAIGNS_SUBTITLE:
      'Stimulate the creation of ad from your units! Create a campaign, define a theme or an industry partner, period and leverage your results!',

    HOW_IT_WORKS_CI: 'How does it work?',

    PRODUCT_HASHTAG: "Product's Hashtag: ",
    BRAND_HASHTAG: "Brand's Hashtag: ",

    DATA_FROM: `from`,
    DATE_TO: 'to',

    PROGRESS_BAR_MESSAGE_PART_1: '',
    PROGRESS_BAR_MESSAGE_PART_2: 'out of',
    PROGRESS_BAR_MESSAGE_PART_3: 'units participated',

    VIDEOS: 'videos',
    IMAGES: 'images',
    VIDEO: 'video',
    IMAGE: 'image',
    IMAGE_CAPITALIZED: 'Image',
    DESCRIPTION: 'Description',

    GUEST: 'Guest',

    EDIT_FOLDER_TITLE: 'Edit GDTV',
    SET_FOLDER_INFO_SUCCESS: 'Information changed successfully!',
    SET_FOLDER_INFO_FAILURE: 'Failed to change the information, try again later!',

    EDIT_CI_TITLE: 'Edit Incentive campaign',
    SET_CI_INFO_SUCCESS: 'Information saved successfully!',
    INVALID_URL: 'Invalid Url',
    SET_CI_INFO_FAILURE: 'Failed to save the information, try again later!',

    REMOVE_FOLDER: 'Do you really want to remove this folder?',
    REMOVE_FOLDER_SUCCESS: 'Folder removed successfully!',
    REMOVE_FOLDER_FAILURE: 'Failed to remove the folder, try again later!',
    DISABLE_FOLDER_SUCCESS: 'Folder disabled successfully!',
    DISABLE_FOLDER_FAILURE: 'Failed to disable the folder, try again later!',
    ENABLE_FOLDER_SUCCESS: 'Folder enabled successfully!',
    ENABLE_FOLDER_FAILURE: 'Failed to enable the folder, try again later!',
    CREATE_MEDIA_SET: 'Create media set',

    UPLOAD_FAILURE: 'Failed to upload the file, try again later',

    NAME: 'Name',
    SIZE: 'Size',
    TEMPLATES_TITLE: 'Dynamic Creative Library',
    TEMPLATES_SUBTITLE:
      'Your templates can be modified with the information we already have available through the Facebook page.',
    name: 'Page name',
    single_line_address: 'Address',
    phone: 'Phone',
    global_brand_page_name: 'Page name',
    link_clicks: 'Link clicks',
    lead_generation: 'Lead generation',
    revenue: 'Revenue',
    roas: 'ROAS',
    sales_amount: 'Sales amount',
    post_engagement: 'Engagements',
    conversions: 'Conversions',
    CAMPAIGN_SUCCESS_MESSAGE: 'Campaign successfully created!',
    CAMPAIGN_FAILURE_MESSAGE: 'Error creating the campaign',

    POSTS: 'Posts',
    ADS: 'Ads',
    CAMPAIGN_CREATED_AT: 'Created at',

    LINK_COPY_SUCCESS: 'Link copied to clipboard',
    LINK_COPY_ERROR: 'Error copying URL',
    LINK_SHARE: "App's link",
    DOWNLOAD_MEDIA: 'Download media',

    MEDIA_TITLE: 'Media usage',
    CAMPAIGN_NAME: 'Campaign name',
    TITLE_CAMPAIGN: 'Time to create your incentive campaign!',
    TITLE_CAMPAIGN_ONE: 'You can publish',
    TITLE_CAMPAIGN_TWO: 'just an image or video',
    TITLE_CAMPAIGN_THREE: 'at a time.',
    TITLE_CAMPAIGN_FOUR: 'Allowed extensions: jpeg, jpg, png, mp4 and webm.',
    INPUT_DESCRIPTION: 'Content description',
    INPUT_VALID: 'How long will the campaign be valid for?',
    SUBTITLE_LEAD_ADS:
      'Please note: to participate in this campaign as a participating unit you must have the selected form linked to your Facebook Page.',
    SUBTITLE_WARNING:
      'Please note: this period will be the same as the ad serving period for your units',
    PLACEHOLDER_CAMPAIGN: 'Enter the campaign name',
    PLACEHOLDER_DESCRIPTION: 'What will be the description for the unit?',
    PLACEHOLDER_START_DATE: 'Start date',
    PLACEHOLDER_END_DATE: 'End date',
    CAPTURE_FORM: 'What will be the fundraising form?',
    INPUT_OBJECTIVE: 'And the purpose of the ads?',
    CANCEL_BUTTON: 'Cancel',
    SEND_BUTTON: 'Advance',
    NAME_CAMPAIGN: 'Campaign',
    CREATED_AT: 'Created in',
    TO_EDIT: 'Edit',
    DISABLE_GDTV: 'Disable GDTV',
    DELETE: 'Delete',
    REPORT: 'Report',
    FEED: 'Feed',
    PERFORMANCE_TITLE: 'Performance',
    CARD_TITLE_OBJECTIVE: 'Objective',
    CARD_TITLE_INVESTED: 'Valor Amount invested',
    CARD_TITLE_ADS: 'Ads',
    CARD_TITLE_DURATION: 'Duration',
    TITLE_RESULTS: 'Campaign results',
    ITEM_TOTAL: 'Total',
    ITEM_IMPRESSIONS: 'Impressions',
    CPM: 'CPM',
    ITEM_ENGAGEMENT: 'Engagement',
    CPE: 'CPE',
    ER: 'ER',
    ITEM_CLICKS: 'Clicks',
    CPC: 'CPC',
    CTR: 'CTR',
    GDTV: 'GDTV',
    ITEM_UNITS: 'Total units',
    TITLE_COMMUNICATED: 'Your announcements',
    TITLE_MODAL: 'Are you sure you want to delete the content?',
    SUBTITLE_MODAL:
      'The content will be deleted from all folders and cannot be accessed by the drives.',
    BUTTON_DELETE: 'Delete Content',
    CAMPAIGN_LABEL_PERIOD: 'Campaign validity period',
    CAMPAIGN_LABEL_OBJECTIVE: 'Purpose of ads',
    VIEWS_TITLE: 'Views: ',
    SHARED: 'Shared',
    SHARED_EVERYONE: 'Shared with everyone',
    INCLUDE_FILE: 'Include file',
    VIEW_MORE: 'View more',
    EVERYTHING: 'Everything',
    LABEL_PARTICIPATED: 'Participated',
    LABEL_DELIVERED: 'Delivered',
    LABEL_VIEW: 'Viewed',
    LABEL_NOT_DELIVERED: "Didn't receive",
    STATUS_HEADER: 'Status',
    NAME_HEADER: 'Name',
    ANNOUNCEMENT_VIEW_TITLE: 'Announcement views: ',
    CAMPAIGN_VIEW_TITLE: 'Campaign views: ',
    CAMPAIGN: 'Campaign',
    GENERALS_VIEWS: 'General views: ',
    STATUS_CAMPAIGN: 'Campaign status: ',
    LABEL_STATUS_ACTIVE_AD: 'Actives',
    LABEL_STATUS_PAUSED_AD: 'Paused',
    LABEL_STATUS_SCHEDULED_AD: 'Scheduled',
    LABEL_STATUS_FINISHED_AD: 'Finished',
    LABEL_STATUS_ARCHIVED_AD: 'Archived',
    LABEL_STATUS_ACTIVE: 'Active',
    LABEL_STATUS_PAUSED: 'Paused',
    LABEL_STATUS_SCHEDULED: 'Scheduled',
    LABEL_STATUS_FINISHED: 'Finished',
    LABEL_STATUS_ARCHIVED: 'Archived',
    PAUSE_CAMPAIGN_ONLY: 'Pause campaign only',
    PAUSE_BOTH: 'Pause campaign and ads',
    CAMPAIGN_ARCHIVE: 'Archive campaign',
    ERROR_MESSAGE: 'Error trying to change status',
    CLICKABLE_SPAN_EDIT: 'Edit',
    CAMPAIGN_BUDGET: 'What will be the budget for the campaign?',
    INSERT_THE_CAMPAIGN_BUDGET: 'Campaign budget',
    TITLE_SELECT_GROUPS: 'Choose units to run campaign',
    SUB_TITLE_SELECT_GROUPS_1: 'You can select an entire group or just a few',
    SUB_TITLE_SELECT_GROUPS_2: 'units to display your incentive campaign.',
    CAMPAIGN_GOAL: 'Goal',
    SELECT_GOAL: 'Select the purpose of your ad',
    CAPTURE_GOAL: 'Select the fundraising form',
    LEADS_GENERATED: 'Generated leads',
    DOWNLOAD: 'Download',
    NO_DOWNLOADS: 'No downloads',
    ONE_DOWNLOAD: 'Download',
    DOWNLOADS: 'Downloads',
    NO_CONTENT: 'No content',
    NO_ADS: 'No ads were created in this campaign',
    READ_MORE: 'Read more',
    READ_LESS: 'Read less',
    FILTER_LABEL_BRAND_HOUSE: 'Casas Bahia',
    FILTER_LABEL_BRAND_SCORE: 'Ponto',
    MESSAGE_CASAS_BAHIA: 'All Casas Bahia units have been selected.',
    MESSAGE_PONTO: 'All Ponto units have been selected.',

    FILTERS_RESET_1: 'Ops',
    FILTERS_RESET_2: 'Unfortunately we could not find campaigns with those filters!',
    FILTERS_RESET_3: 'Filter again',

    CREATE_CAMPAIGN_1: 'Create your first',
    CREATE_CAMPAIGN_2: 'Incentive Campaign!',
    CREATE_CAMPAIGN_3: 'Incentive campaigns are actions to promote ads creation',
    CREATE_CAMPAIGN_4: 'by the units. Define a theme, period and levarage your results!',

    TRADEADS_NEW_TRADE_CAMPAIGN_TITLE: 'Time to create your trade campaign!',
    TRADEADS_LIMIT_BUDGET_TITLE: 'What will the budget ceiling per store be?',
    CREATE_MEDIAS_1: 'Create your first',
    CREATE_MEDIAS_2: 'Media Set!',
    CREATE_MEDIAS_3:
      'The media set is the space for sharing photos and videos with the drives. Create a folder and choose who to share it with!',

    INCENTIVE_CAMPAIGNS: 'Incentive campaigns',
    INCENTIVE_CAMPAIGN_ACTION_1:
      'Campanhas de Incentivo são ações para estimular a criação de anúncios',
    INCENTIVE_CAMPAIGN_ACTION_2:
      'pelas unidades, defina um tema, período e alavanque os seus resultados!',
    FILTER: 'Filter',
    CREATE_NEW_GDTV: 'Create new GDTV',
    TITLE_CD_01: 'Make your national campaign',
    TITLE_CD_02: 'with the support of gerentesdigitais!',
    CD_DESCRIPTION_01:
      'With Dynamic Ads you can customize your national campaign for each of your units.',
    CD_DESCRIPTION_02:
      "It's quite simple! You define your media, select the units and choose the variables: phone, address and page name!",
    CD_QUOTATION: 'I want a quotation!',

    ATIVATE_BOTH: 'Turn on campaign and ads',
    ATIVATE_CAMPAIGN_ONLY: 'Enable campaign only',

    GDTV_SUBTITLE:
      'Communicate with project units through videos! Create a GDTV with announcements, tips and incentives for units to post more and more!',

    TITLE_MEDIA_GDTV: 'Create your folder',
    TITLE_MEDIA_GDTV_1: 'For ease of organization, your announcements can be grouped by theme.',
    TITLE_MEDIA_GDTV_2: 'Your units will receive your communication in',
    TITLE_MEDIA_GDTV_3: 'App Feed.',
    REQUIRED: 'required field',
    LABEL_NAME: 'Name',
    LABEL_DESCRIPTION: 'Description',
    LABEL_CANCEL: 'Cancel',
    LABEL_ADVANCE: 'Next',

    MEDIA_SET_TITLE: 'Media Set Title',
    MEDIA_SET_DESCRIPTION: 'Enter an explanatory description for the media set',
    MEDIA_SET_TITLE_PLACEHOLDER: 'Enter a title for the media set',
    CREATE_NEW_MEDIA_SET: 'Create media set',
    MEDIA_SET_UPLOAD_AREA_TITLE: 'Add media to your media set here.',
    MEDIA_SET_UPLOAD_AREA_SUB_TITLE: 'Allowed extensions: jpeg, jpg, png and mp4',
    MEDIA_SET_MEDIA_CREATION_ERROR_TOAST:
      'The media set was created, but the media was not successfully added',
    MEDIA_SET_CREATION_ERROR_TOAST: 'Error creating media set',
    MEDIA_UPLOADING_ERROR_TOAST: 'Wait for the media to load',

    REMOVE_VIDEO: 'Remove Video',
    REMOVE_IMAGE: 'Remove Image',
    TITLE_ERROR: 'Mandatory title',
    GDTV_TITLE: 'GDTV Title',
    TITLE: 'Title',
    GDTV_TITLE_PLACEHOLDER: 'Enter a title',
    DESCRIPTION_PLACEHOLDER: 'Enter an explanatory description for the GDTV',
    MEDIA_DESCRIPTION: 'Media Description',
    MEDIA_DESCRIPTION_PLACEHOLDER: 'Enter an explanatory description to the GDTV media',
    GDTV_MEDIA_TITLE: 'GDTV image or video',
    GDTV_MEDIA_SUBTITLE: 'Upload the GDTV image or video with the following specifications:',
    GDTV_MEDIA_MAX_SIZE: 'up to 2 MB',
    GDTV_MEDIA_FORMATS: 'formats jpeg, jpg, png, mp4, webp, webm',
    UPLOAD_MEDIA_GDTV: 'Upload media',
    GDTV_MEDIA_ERROR: 'Required GDTV Content',
    UPLOAD: 'Upload',
    SEE_REVIEW: 'See summary',
    GDTV_GROUP_ERROR: 'Add at least one group',
    GROUP: 'Group: ',
    SELECTED_GROUPS: 'Selected groups',
    UNINFORMED: 'Uninformed',

    TRADEADS_DESCRIPTION_1: 'Trade Ads Campaigns',
    TRADEADS_DESCRIPTION_2: 'Trade Ads campaigns are Industry-sponsored ads. Select one',
    TRADEADS_DESCRIPTION_3: 'partner, define a theme, period and leverage your results!',

    UNSUPPORTED_MEDIA_TYPE: 'Unsupported media type',
    MEDIA_SET_IMAGES_TITLE: 'Media set images',

    TRADEADS_NEW_CAMPAIGN: 'Create new Trade Ads campaign',
    TRADEADS_FORM_OPTIONAL_1: 'Yes',
    TRADEADS_FORM_OPTIONAL_2: 'No',
    TRADEADS_FORM_AWARD: 'Is this campaign awarded?',
    TRADEADS_FORM_PARTNER: 'Select the Trade Ads partner',
    TRADEADS_DEFAULT_CONTENT_1: 'Have your partner industries in gerentesdigitais!',
    TRADEADS_DEFAULT_CONTENT_2: 'Now with',
    TRADEADS_DEFAULT_CONTENT_3: 'you and partner industries will be able to customize campaigns',
    TRADEADS_DEFAULT_CONTENT_4: 'to launch products together!',
    TRADEADS_DEFAULT_CONTENT_5:
      'Know the results, control the campaigns, encourage with more assertiveness and',
    TRADEADS_DEFAULT_CONTENT_6: 'strengthen your bonds.',
    TRADEADS_DEFAULT_CONTENT_7: 'I want to know more!',

    TRADEADS_TENANT_LIST: 'Select tags',

    LIBRARY_NEW_FOLDER: 'Create new folder',
    LIBRARY_SUBTITLE01: 'Share photos and videos with units to aid in content',
    LIBRARY_SUBTITLE02: 'creation. Create a folder, and choose who to share it with!',
    LIBRARY_HOW_IT_WORKS: 'Understand how it works',
    LIBRARY_USERS_USED: 'users used',
    LIBRARY_USER_USED: 'user used',
    MEDIA_FOLDER: 'Media Folder',

    UNIT_NOT_FOUND: 'No ad was found to this unit',
    SEARCH_BY_UNIT: 'Search by unit',
    SEARCH_CAMPAIGN: 'Search campaign',

    IMPORTED_GROUPS_MESSAGE:
      'We import the units pre-selected by you. To share with more units, just go back one step to edit Groups and Units.',
    IMPORTED_GROUPS_OK_MESSAGE: 'Ok!',
    tag: 'Partner: ',
    TRADE_CI_TITLE: 'Trade Campaigns',
    TRADE_CI_SUBTITLE:
      'Trade campaigns are actions to encourage the creation of ads by the units, define a theme or an industry partner, period and leverage your results!',

    OWN_BUDGET: 'Own Budget',
    COOP_BUDGET: 'Co-ordinated budget',
    CHOOSE_GROUPS: 'Choose groups',

    WHATSAPP_SHARE_MSG: 'Checkout this',
    ACTIVE: 'Active',
    DISABLED: 'Disabled',
    DISABLE: 'Disable',
    ENABLE: 'Enable',
    SCHEDULED: 'Scheduled',
    REJECTED: 'Rejected',
    FINISHED: 'Finished',

    ALL: 'All',
    ALL_2: 'All',
    VISUALIZED: 'Visualized',
    RECEIVED: 'Received',
    UNIT: 'Unit',
    PARTICIPATION: 'Participation',
    UNITS_PARTICIPATION: 'Units Participation',
    FEEDBACK_CARD_NEGATIVE_TITLE: 'Warning!',
    FEEDBACK_CARD_POSITIVE_TITLE: 'Well!',
    FEEDBACK_CARD_DESCRIPTION: 'of units visualized this GDTV',
    DISABLE_MEDIA_FOLDER: 'Disable media folder',
    UNITS_USED: 'units used',
    UNIT_USED: 'unit used',
    ADS_PRODUCED: 'ads produced',
    AD_PRODUCED: 'ads produced',
    POSTS_PRODUCED: 'posts produced',
    POST_PRODUCED: 'post produced',
    EDIT_MEDIA_MODAL_TITLE: 'Edit media folder',
    DELETE_MEDIA_MODAL_TITLE: 'Do you really want to delete this media?',
    DELETE_MEDIA_TOOLTIP: 'Delete',
    DELETE_MEDIA_MODAL_SUBTITLE:
      'The media will be deleted from the folder. It can no longer be accessed by the units, but will continue to be active in the publications where it was used.',
    ADD_MEDIA_NAME_MODAL_TITLE: 'Add new media files',
    ADD_MEDIA_NAME_MODAL_SUBTITLE: 'Allowed extensions: jpeg, jpg, png, mp4 and webm.',

    CREATED_ADS: 'Created ads',
    USED_VALUE: 'Used value',
    OFF_CAMPAIGN_BUDGET: 'off campaign budget',
    FEEDBACK_CARD_DESCRIPTION_CI: 'of units participated in this campaign',
    PENDING_REVIEW_LABEL: 'Awaiting approval',
    MEDIA_UPLOAD_ERROR: 'There was an error sending the media',
    ADD_MEDIA_TOOLTIP: 'Add media',
    MEDIA_UPLOAD_SUCCESS_TOAST: 'Successfully uploading media',
    DELETE_MEDIA_FOLDER: 'Delete media folder',
    DELETE_MEDIA_FOLDER_MODAL_TITLE: 'Do you really want to delete this folder?',
    DELETE_MEDIA_FOLDER_MODAL_SUBTITLE: 'The folder will no longer be accessible for the units.',
    LAST_CAMPAIGN_TITLE: 'No campaigns were found in the selected period!',
    LAST_CAMPAIGN_SUBTITLE:
      'Your last campaign was created in {{created}} and made available from {{start}} to {{end}}.',
    LAST_CAMPAIGN_BTN_SCHEDULE: 'View scheduled campaigns',
    LAST_CAMPAIGN_BTN: 'Go to campaign period',

    CREATED_ADS_TOOLTIP: 'Total ads of this Campaign, produced in the period.',
    IMPRESSIONS_TOOLTIP: 'Number of times ads from this Campaign were shown to the audience',
    ENGAGEMENT_TOOLTIP:
      "User interaction level. It's the sum of reactions, comments, shares, clicks, and views.",
    CLICKS_LINK_TOOLTIP: 'Number of clicks on ad links in this Campaign.',
    REVENUE_TOOLTIP: 'The sales revenue (online and offline) made from the ads in this Campaign.',
    SALES_AMOUNT_TOOLTIP:
      'Amount of sales (online and offline) made from the ads of these Campaigns.',
    UNITS_MEAN: 'Average per unit',
    CPA: 'CPA',
    SHARE_BY_WHATSAPP: 'Send by Whatsapp',
    CAMPAIGNS_MEAN: 'Average per campaign',
    SEE_DETAILS: 'See details',
    HIDE_DETAILS: 'Hide details',
    PARTICIPATED: 'Participated',
    OF_UNITS: 'of the units',
    UNITS: 'units',
    OF_BUDGET: 'of the budget',
    SPENT_MESSAGE: '{{spent}} of {{goal}}',
    EMPTY_CI_SUBTITLE:
      'Would you like more control over the content created by your digital managers?',

    RESULTS_SUBTITLE: 'Results of all campaigns from the selected period:',
    TARGET_BUDGET_TEXT: 'of used budget',
    TARGET_BUDGET_TOOLTIP:
      'Percentage of funds used. Only campaigns with a defined budget are considered',

    PARTICIPATING_UNITS: 'Participating units',
    EVERY_CAMPAIGN: 'Every campaign',
    SPENT_MESSAGE: '{{spent}} of {{goal}}',

    INVESTED_RESULTS_TOOLTIP: 'Total spend by your units on Campaign ads',
    REVENUE_RESULTS_TOOLTIP: 'Sales revenue (online and offline) made from Campaign ads',
    SALES_AMOUNT_RESULTS_TOOLTIP: 'Number of sales (online and offline) made from Campaign ads',
    IMPRESSION_RESULTS_TOOLTIP: 'Number of times Campaign ads were shown to the public',
    ENGAGEMENTS_RESULTS_TOOLTIP:
      "Represents the level of user interaction. It's the sum of reactions, comments, shares, clicks, and views.",
    CLICK_RESULTS_TOOLTIP: 'Number of clicks on campaign ad links',
    TOTAL_CAMPAIGNS_RESULTS_TOOLTIP: 'Number of campaigns served in the period',
    PARTICIPATING_UNITS_RESULTS_TOOLTIP:
      'Number of units that participated in Campaigns in the period',
    ADS_RESULTS_TOOLTIP: 'Total Campaign ads, produced in the period',
    FILE_TYPES: 'File types: .jpeg, .jpg, .png and .mp4',
    MAXIMUM_SIZE: 'Maximum size: ',
    CAMPAIGN_FAILURE_MESSAGE: 'Error creating the campaign',
    GDTV_WAS_CREATED: 'GDTV was created',
    GDTV_WAS_CREATED_BUT_THE_MEDIA_WAS_NOT_ADD: 'GDTV was created, but the media was not added',
    GDTV_DEFAULT_1: 'Create your first GDTV',
    GDTV_DEFAULT_2:
      'The GDTV was made especially for the communication between matrix and units. Send your message to the sellers simply, easily and quickly!',
    GDTV_DEFAULT_3: 'Create GDTV',

    PAUSED_MODAL_TITLE: 'Do you really want to pause this campaign?',
    PAUSED_MODAL_INFO:
      'The campaign will be paused. It will no longer be accessible by units, but the ads created will continue to be active.',
    PAUSED_MODAL_ACTION: 'Pause campaign',
    PAUSED_BOTH_MODAL_TITLE: 'Do you really want to pause this campaign and ads?',
    PAUSED_BOTH_MODAL_INFO:
      'The campaign will be paused, as well as ads that have already been created. It will no longer be accessible by units.',
    PAUSED_BOTH_MODAL_ACTION: 'Pause campaign and ads',
    FINISHED_MODAL_TITLE: 'Do you really want to finish this campaign?',
    FINISHED_MODAL_INFO:
      'The campaign will be finished, but the ads created will continue to be active during the scheduled period of each one. You will still be able to access the campaign results in the dashboard.',
    FINISHED_MODAL_ACTION: 'Finish campaign',
    DELETE_CAMPAIGN: 'Delete campaign',
    DELETE_MODAL_TITLE: 'Do you really want to delete this campaign?',
    DELETE_MODAL_INFO:
      "The campaign will be deleted and the ads that have already been created will be finished. It will no longer be listed in the platform's campaign dashboard and will no longer be accessible by units.",
    DELETE_MODAL_ACTION: 'Delete campaign',
    ACTIVATE_MODAL_TITLE: 'Do you really want to activate this campaign?',
    ACTIVATE_MODAL_INFO:
      'The campaign will be activated. Units will be able to participate in the campaign by creating new ads through the app.',
    ACTIVATE_MODAL_ACTION: 'Activate campaign',
    ACTIVATE_BOTH_MODAL_TITLE: 'Do you really want to activate this campaign and ads?',
    ACTIVATE_BOTH_MODAL_INFO:
      'The campaign will be activated, as well as ads that have been created previously. Units will be able to participate in the campaign by creating new ads through the app.',
    ACTIVATE_BOTH_MODAL_ACTION: 'Activate campaign and ads',
    LABEL_STATUS_EXCLUDED: 'Excluded',
    PENDING_REVIEW: 'Pending review',
    PAUSED: 'Paused',
  },
};

const MediasPT = {
  Medias: {
    OF: 'de',
    PARTICIPATE_INFO: '{{amount}}% das unidades participaram',
    CONSUMED_BUDGET_INFO: '{{amount}}% do orçamento utilizado',
    FINISH_CAMPAIGN: 'Finalizar campanha',
    EDIT_INFO: 'Editar informações',
    MEDIA: 'Mídia',
    MEDIA_ALT: 'Mídias',
    SHARE: 'Compartilhar link',
    MADE_IN: 'Feito em',
    COPY_LINK: 'Copiar link',
    LIBRARY_TITLE: 'Biblioteca de Mídias',
    CREATE_COM: 'Criar incentivos',
    CREATE_CAMPAIGN: 'Criar campanha',
    CREATE_GDTV: 'Criar GDTV',
    CREATE_FOLDER: 'Criar pasta',
    FINISH: 'Finalizar',
    NEXT_STEP: 'Próximo',
    SEND: 'Enviar',
    GO_BACK: 'Voltar',
    CONFIRM: 'Confirmar',
    CANCEL: 'Cancelar',
    SAVE: 'Salvar',
    CLOSE: 'Fechar',
    TYPE_HERE: 'Digite aqui...',
    TYPE_HERE_YOUR_MESSAGE: 'Digite aqui sua mensagem...',
    FILE_TITLE: 'Faça o upload de seus arquivos',
    NAME_TITLE: 'Insira o nome para a pasta',
    SHARE_TITLE: 'Deseja compartilhar com quem?',
    DESCRIPTION_TITLE: 'Envie a descrição',
    SEARCH_PLACEHOLDER: 'Buscar',
    SELECT_GOAL: 'Selecione o objetivo do seu anúncio',
    CAPTURE_GOAL: 'Selecione o formulário de captação',
    SELECT_ALL: 'Selecionar todos',
    UNSELECT_ALL: 'Desmarcar todos',
    TAB_GROUPS: 'Grupos',
    TAB_ACCOUNTS: 'Contas',
    TAB_PAGES: 'Páginas',
    POST_FOLDER_FAILURE_MESSAGE: 'Falha ao criar esta pasta, tente novamente mais tarde!',
    POST_FOLDER_SUCCESS_MESSAGE: 'Pasta criada com sucesso!',
    EMPTY_FOLDERS_TITLE: 'As pastas criadas ficam exibidas aqui.',
    START_NOW: 'Comece agora:',
    LEVEL_UP: 'Subir de nível',
    OPEN_SUBGROUP: 'Abrir',

    DATA_FROM: `De`,
    DATE_TO: 'até',

    PRODUCT_HASHTAG: 'Hashtags de Produto: ',
    BRAND_HASHTAG: 'Hashtags de Marca: ',

    FILES_CHOSEN: 'Arquivos escolhidos',
    FILES_INFO_TITLE: 'Clique no botão abaixo e escolha suas imagens e vídeos.',
    FILES_INFO_DESCRIPTION: 'Extensões permitidas: jpeg, jpg, png, mp4 e webm.',

    VIDEOS: 'vídeos',
    IMAGES: 'imagens',
    VIDEO: 'vídeo',
    IMAGE: 'imagem',
    IMAGE_CAPITALIZED: 'Imagem',
    DESCRIPTION: 'Descrição',

    HOW_IT_WORKS_CI: 'Como funciona?',

    PROGRESS_BAR_MESSAGE_PART_1: 'Participaram',
    PROGRESS_BAR_MESSAGE_PART_2: 'de',
    PROGRESS_BAR_MESSAGE_PART_3: 'unidades',

    GUEST: 'Convidado',

    EDIT_FOLDER_TITLE: 'Editar GDTV',
    SET_FOLDER_INFO_SUCCESS: 'Informações alteradas com sucesso!',
    SET_FOLDER_INFO_FAILURE: 'Erro ao alterar as informações, tente novamente mais tarde!',

    EDIT_CI_TITLE: 'Editar Campanha de Incentivo',
    SET_CI_INFO_SUCCESS: 'Informações salvas com sucesso!',
    INVALID_URL: 'Url inválida',
    SET_CI_INFO_FAILURE: 'Falha ao salvar informações, tente novamente!',

    REMOVE_FOLDER: 'Você realmente deseja remover esta pasta?',
    REMOVE_FOLDER_SUCCESS: 'Pasta removida com sucesso!',
    REMOVE_FOLDER_FAILURE: 'Falha ao remover a pasta, tente novamente mais tarde!',
    DISABLE_FOLDER_SUCCESS: 'Pasta desativada com sucesso!',
    DISABLE_FOLDER_FAILURE: 'Falha ao desativar a pasta, tente novamente mais tarde!',
    ENABLE_FOLDER_SUCCESS: 'Pasta ativada com sucesso!',
    ENABLE_FOLDER_FAILURE: 'Falha ao ativar a pasta, tente novamente mais tarde!',
    CREATE_MEDIA_SET: 'Criar biblioteca de mídias',

    OWN_BUDGET: 'Verba própria',
    COOP_BUDGET: 'Verba cooperada',

    WHATSAPP_SHARE_MSG: 'Hey! Olha esse(a)',
    ACTIVE: 'Ativo',
    DISABLED: 'Desativado',
    DISABLE: 'Desativar',
    ENABLE: 'Ativar',
    SCHEDULED: 'Agendado',
    REJECTED: 'Rejeitado',
    FINISHED: 'Finalizado',

    UPLOAD_FAILURE: 'Erro ao subir o arquivo, tente novamente mais tarde',
    NAME: 'Nome',
    SIZE: 'Tamanho',
    TEMPLATES_TITLE: 'Biblioteca de Criativos Dinâmicos',
    TEMPLATES_SUBTITLE:
      'Seus templates poderão ser modificados com as informações que já temos disponíveis através da página do Facebook.',
    name: 'Nome da página',
    single_line_address: 'Endereço',
    phone: 'Telefone',
    global_brand_page_name: 'Nome da página',
    link_clicks: 'Cliques no link',
    lead_generation: 'Geração de cadastro',
    revenue: 'Receita',
    roas: 'ROAS',
    sales_amount: 'Volume de vendas',
    post_engagement: 'Engajamento',
    conversions: 'Conversões',
    CAMPAIGN_SUCCESS_MESSAGE: 'Campanha criada com sucesso!',
    CAMPAIGN_FAILURE_MESSAGE: 'Erro ao criar a campanha',
    GDTV_WAS_CREATED: 'O GDTV foi criado',
    GDTV_WAS_CREATED_BUT_THE_MEDIA_WAS_NOT_ADD:
      'O GDTV foi criado, mas a mídia não foi adicionada com sucesso',

    CAMPAIGN_CREATED_AT: 'Criada em',

    ADS: 'Anúncios',

    MEDIA_TITLE: 'Uso da mídia',
    CAMPAIGN_NAME: 'Nome da campanha',
    TITLE_CAMPAIGN: 'Hora de criar sua campanha de incentivo!',
    TITLE_CAMPAIGN_ONE: 'Você poderá publicar',
    TITLE_CAMPAIGN_TWO: 'apenas uma imagem ou vídeo',
    TITLE_CAMPAIGN_THREE: 'por vez.',
    TITLE_CAMPAIGN_FOUR: 'Extensões permitidas: jpeg, jpg, png, mp4 e webm.',
    INPUT_DESCRIPTION: 'Descrição do conteúdo',
    INPUT_SUBTITLE: 'Legenda do conteúdo',
    INPUT_VALID: 'Qual será o período de validade da campanha?',
    SUBTITLE_LEAD_ADS:
      'Atenção: para participar dessa campanha as unidades participantes devem ter o formulário selecionado vinculado as suas páginas do Facebook.',
    SUBTITLE_WARNING:
      'Atenção: esse será o período limite em que as unidades poderão veicular as suas campanhas de incentivo.',
    PLACEHOLDER_CAMPAIGN: 'Insira o nome da campanha',
    PLACEHOLDER_DESCRIPTION: 'Qual será a descrição para a unidade?',
    PLACEHOLDER_START_DATE: 'Data de início',
    PLACEHOLDER_END_DATE: 'Data de término',
    INPUT_OBJECTIVE: 'E o objetivo dos anúncios?',
    CAPTURE_FORM: 'Qual será o formulário de captação?',
    CANCEL_BUTTON: 'Cancelar',
    SEND_BUTTON: 'Avançar',
    CHOOSE_GROUPS: 'Escolher grupos',
    CHOOSE_UNITS: 'Escolher unidades',
    NAME_CAMPAIGN: 'Campanha',
    CREATED_AT: 'Criado em',
    ACTIVATE_CAMPAIGN: 'Ativar Campanha',
    PAUSE_CAMPAIGN: 'Pausar Campanha',
    TO_EDIT: 'Editar',
    DISABLE_GDTV: 'Desativar GDTV',
    DELETE: 'Excluir',
    REPORT: 'Relatório',
    FEED: 'Feed',
    PERFORMANCE_TITLE: 'Performance',
    CARD_TITLE_OBJECTIVE: 'Objetivo',
    CARD_TITLE_INVESTED: 'Valor investido',
    CARD_TITLE_ADS: 'Anúncios',
    CARD_TITLE_DURATION: 'Duração',
    TITLE_RESULTS: 'Resultados da campanha',
    CREATED_ADS: 'Anúncios criados',
    ITEM_TOTAL: 'Total',
    ITEM_IMPRESSIONS: 'Impressões',
    CPM: 'CPM',
    ITEM_ENGAGEMENT: 'Engajamento',
    CPE: 'CPE',
    ER: 'ER',
    ITEM_CLICKS: 'Cliques',
    CPC: 'CPC',
    CTR: 'CTR',
    GDTV: 'GDTV',
    ITEM_UNITS: 'Total de unidades',
    TITLE_COMMUNICATED: 'Seus comunicados',
    TITLE_MODAL: 'Tem certeza que quer excluir o conteúdo?',
    SUBTITLE_MODAL:
      'O conteúdo será excluído de todas as pastas e não poderá ser acessado pelas unidades.',
    BUTTON_DELETE: 'Excluir conteúdo',
    CAMPAIGN_LABEL_PERIOD: 'Período de validade da campanha',
    CAMPAIGN_LABEL_OBJECTIVE: 'Objetivo dos anúncios',
    VIEWS_TITLE: 'Visualizações: ',
    SHARED: 'Compartilhado',
    SHARED_EVERYONE: 'Compartilhado com todos',
    SHARE_WITH_EVERYONE: 'Compartilhar com todos',
    YOUR_CAMPAIGN: 'Sua campanha de incentivo',
    INCLUDE_FILE: 'Incluir arquivo',
    VIEW_MORE: 'Ver mais',
    EVERYTHING: 'Tudo',
    LABEL_PARTICIPATED: 'Participou',
    LABEL_DELIVERED: 'Recebeu',
    LABEL_VIEW: 'Visualizou',
    LABEL_NOT_DELIVERED: 'Não recebeu',
    STATUS_HEADER: 'Status',
    NAME_HEADER: 'Nome',
    ANNOUNCEMENT_VIEW_TITLE: 'Visualizações do comunicado: ',
    CAMPAIGN_VIEW_TITLE: 'Visualizações da campanha: ',
    CAMPAIGN: 'Campanha',
    GENERALS_VIEWS: 'Visualizações gerais: ',
    STATUS_CAMPAIGN: 'Status de campanha: ',
    LABEL_STATUS_ACTIVE_AD: 'Ativos',
    LABEL_STATUS_SCHEDULED_AD: 'Agendados',
    LABEL_STATUS_PAUSED_AD: 'Pausados',
    LABEL_STATUS_FINISHED_AD: 'Finalizados',
    LABEL_STATUS_ARCHIVED_AD: 'Arquivados',
    LABEL_STATUS_ACTIVE: 'Ativa',
    LABEL_STATUS_PAUSED: 'Pausada',
    LABEL_STATUS_SCHEDULED: 'Agendada',
    LABEL_STATUS_FINISHED: 'Finalizada',
    LABEL_STATUS_ARCHIVED: 'Arquivada',
    PAUSE_CAMPAIGN_ONLY: 'Pausar somente campanha',
    PAUSE_BOTH: 'Pausar campanha e anúncios',
    CAMPAIGN_ARCHIVE: 'Finalizar campanha',
    ERROR_MESSAGE: 'Erro ao tentar alterar o status',
    CLICKABLE_SPAN_EDIT: 'Editar',
    CAMPAIGN_BUDGET: 'Qual será o orçamento para a campanha?',
    INSERT_THE_CAMPAIGN_BUDGET: 'Orçamento da campanha',
    TITLE_SELECT_GROUPS: 'Escolha as unidades para exibir campanha',
    SUB_TITLE_SELECT_GROUPS_1: 'Você poderá selecionar um grupo todo ou apenas algumas',
    SUB_TITLE_SELECT_GROUPS_2: 'unidades para exibir a sua campanha de incentivo.',
    CAMPAIGN_GOAL: 'Meta',
    LEADS_GENERATED: 'Leads gerados',
    ATIVATE_BOTH: 'Ativar campanha e anúncios',
    ATIVATE_CAMPAIGN_ONLY: 'Ativar somente campanha',
    DOWNLOAD: 'Download',
    NO_DOWNLOADS: 'Nenhum download',
    ONE_DOWNLOAD: 'Download',
    DOWNLOADS: 'Downloads',
    NO_CONTENT: 'Sem conteúdo',
    NO_ADS: 'Nenhum anúncio foi criado nesta campanha',
    READ_MORE: 'Ler mais',
    READ_LESS: 'Ler menos',
    FILTER_LABEL_BRAND_HOUSE: 'Casas Bahia',
    FILTER_LABEL_BRAND_SCORE: 'Ponto',
    MESSAGE_CASAS_BAHIA: 'Todas as unidades de Casas Bahia foram selecionadas.',
    MESSAGE_PONTO: 'Todas as unidades de Ponto foram selecionadas.',
    TRADEADS_NEW_TRADE_CAMPAIGN_TITLE: 'Hora de criar sua campanha de trade!',
    TRADEADS_LIMIT_BUDGET_TITLE: 'Deseja ter um orçamento fixo por unidade?',
    TRADEADS_LIMIT_BUDGET_PLACEHOLDER: 'Teto de orçamento por loja',
    TRADEADS_LIMIT_BUDGET_SUBTITLE: 'Permitirá apenas uma participação na campanha.',
    LIBRARY_SUBTITLE01: 'Compartilhe fotos e vídeos com as unidades, para auxiliar na criação de',
    LIBRARY_SUBTITLE02: 'conteúdo. Crie uma pasta e escolha com quem compartilhar!',
    LIBRARY_NEW_FOLDER: 'Criar nova pasta',
    LIBRARY_HOW_IT_WORKS: 'Entenda como funciona',
    LIBRARY_WITHOUT_USER: 'Nenhum usuário utilizou',
    LIBRARY_USERS_USED: 'usuários utilizam',
    LIBRARY_USER_USED: 'usuário utilizou',

    FILTERS_RESET_1: 'Ops',
    FILTERS_RESET_2: 'Infelizmente não encontramos campanhas com este status!',
    FILTERS_RESET_3: 'Filtrar novamente',
    INCENTIVE_CAMPAIGNS: 'Campanhas de Incentivo',
    INCENTIVE_CAMPAIGNS_SUBTITLE:
      'Estimule a criação de anúncios das suas unidades! Crie uma campanha, defina um tema ou um parceiro da indústria, período e alavanque os seus resultados!',
    CREATE_MEDIAS_1: 'Crie a sua primeira',
    CREATE_MEDIAS_2: 'Pasta de Mídias!',
    CREATE_MEDIAS_3:
      'A biblioteca de mídias é o espaço de compartilhamento de fotos e vídeos com as unidades. Crie uma pasta e escolha com quem compartilhar!',

    CREATE_CAMPAIGN_1: 'Crie a sua primeira',
    CREATE_CAMPAIGN_2: 'Campanha de Incentivo!',
    CREATE_CAMPAIGN_3: 'Campanhas de Incentivo são ações para estimular a criação de anúncios',
    CREATE_CAMPAIGN_4: 'pelas unidades. Defina um tema, período e alavanque os seus resultados!',

    FILE_UPLOAD_1: 'Faça upload do seu conteúdo',
    FILE_UPLOAD_2: 'Você poderá publicar ',
    FILE_UPLOAD_3: ' apenas uma imagem ou vídeo',
    FILE_UPLOAD_4: 'por vez.',
    FILE_UPLOAD_5: 'Extensões permitidas: jpeg, jpg, png, mp4 e webm.',
    FILE_UPLOAD_6: 'Incluir arquivo',

    FILE_CANCEL: 'Cancelar',
    FILE_SEND: 'Enviar',
    FILE_SHARED_ALL: 'Compartilhar com todos',
    FILE_CHOOSE_UNITY: 'Escolher unidades',

    GDTV_DEFAULT_1: 'Crie o seu primeiro GDTV',
    GDTV_DEFAULT_2:
      'O GDTV foi feito especialmente para a comunicação entre matriz e unidades. Mande o seu recado para os vendedores de forma simples, fácil e rápida!',
    GDTV_DEFAULT_3: 'Criar GDTV',
    TITLE_CD_01: 'Faça sua campanha nacional',
    TITLE_CD_02: 'com o apoio de gerentesdigitais!',
    CD_DESCRIPTION_01:
      'Com Criativos Dinâmicos você consegue personalizar sua campanha nacional para cada uma de suas unidades.',
    CD_DESCRIPTION_02:
      'É bem simples! Você define sua mídia, seleciona as unidades e escolhe as variáveis: telefone, endereço e nome da página!',
    CD_QUOTATION: 'Quero um orçamento!',
    INCENTIVE_CAMPAIGN_ACTION_1:
      'Campanhas de Incentivo são ações para estimular a criação de anúncios',
    INCENTIVE_CAMPAIGN_ACTION_2:
      'pelas unidades, defina um tema, período e alavanque os seus resultados!',
    FILTER: 'Filtrar',
    CREATE_NEW_CAMPAIGN: 'Criar campanha',
    GDTV_SUBTITLE:
      'Se comunique com as unidades do projeto através de vídeos! Crie um GDTV com comunicados, dicas e incentivos para as unidades postarem cada vez mais!',
    CREATE_NEW_GDTV: 'Criar novo GDTV',

    LINK_COPY_SUCCESS: 'Link copiado para a área de transferência',
    LINK_COPY_ERROR: 'Erro ao copiar URL',
    LINK_SHARE: 'Link no aplicativo',
    DOWNLOAD_MEDIA: 'Baixar mídia',

    TITLE_MEDIA_GDTV: 'Crie sua pasta',
    TITLE_MEDIA_GDTV_1:
      'Para facilitar a organização, seus comunicados podem ser agrupados por temas.',
    TITLE_MEDIA_GDTV_2: 'Suas unidades receberão seu comunicado no',
    TITLE_MEDIA_GDTV_3: 'Feed do App.',
    REQUIRED: 'campo obrigatório',
    LABEL_NAME: 'Nome',
    LABEL_DESCRIPTION: 'Descrição',
    LABEL_CANCEL: 'Cancelar',
    LABEL_ADVANCE: 'Avançar',

    REMOVE_VIDEO: 'Remover vídeo',
    REMOVE_IMAGE: 'Remover imagem',
    TITLE: 'Título',
    TITLE_ERROR: 'Título obrigatório',
    GDTV_TITLE: 'Título do GDTV',
    GDTV_TITLE_PLACEHOLDER: 'Insira um título',
    DESCRIPTION_PLACEHOLDER: 'Insira uma descrição explicativa para o GDTV',
    MEDIA_DESCRIPTION: 'Descrição da mídia',
    MEDIA_DESCRIPTION_PLACEHOLDER: 'Insira uma descrição explicativa à mídia do GDTV',
    GDTV_MEDIA_TITLE: 'Imagem ou vídeo do GDTV',
    GDTV_MEDIA_SUBTITLE: 'Faça upload da imagem ou vídeo do GDTV com as especificações a seguir:',
    GDTV_MEDIA_MAX_SIZE: 'até 2 MB',
    GDTV_MEDIA_FORMATS: 'formatos jpeg, jpg, png, mp4, webp, webm',
    UPLOAD_MEDIA_GDTV: 'Fazer upload da mídia',
    GDTV_MEDIA_ERROR: 'Conteúdo do GDTV obrigatório',
    UPLOAD: 'Fazer upload',
    SEE_REVIEW: 'Ver Resumo',
    GDTV_GROUP_ERROR: 'Adicione ao menos um grupo',
    GROUP: 'Grupo: ',
    SELECTED_GROUPS: 'Grupos selecionados',
    UNINFORMED: 'Não informado',

    MEDIA_SET_TITLE: 'Título da biblioteca de mídias',
    MEDIA_SET_DESCRIPTION: 'Insira uma descrição explicativa para a biblioteca de mídias',
    MEDIA_SET_TITLE_PLACEHOLDER: 'Insira um título para a biblioteca de mídias',
    CREATE_NEW_MEDIA_SET: 'Criar biblioteca de mídias',
    MEDIA_SET_UPLOAD_AREA_TITLE: 'Adicione mídias para sua bibilioteca aqui',
    MEDIA_SET_UPLOAD_AREA_SUB_TITLE: 'Extensões permitas: jpeg, jpg, png e mp4',
    MEDIA_SET_MEDIA_CREATION_ERROR_TOAST:
      'A biblioteca de mídias foi criada, mas a mídia não foi adicionada com sucesso',
    MEDIA_SET_CREATION_ERROR_TOAST: 'Erro ao criar a biblioteca de mídias',
    MEDIA_UPLOADING_ERROR_TOAST: 'Espere a mídia carregar',

    TRADEADS_DESCRIPTION_1: 'Campanhas Trade Ads',
    TRADEADS_DESCRIPTION_2:
      'Campanhas Trade Ads são anúncios patrocinados pela Indústria. Selecione um',
    TRADEADS_DESCRIPTION_3: 'parceiro, defina um tema, período e alavanque os seus resultados!',

    UNSUPPORTED_MEDIA_TYPE: 'Tipo de arquivo não suportado',
    MEDIA_SET_IMAGES_TITLE: 'Imagens da biblioteca',

    TRADEADS_NEW_CAMPAIGN: 'Criar nova campanha TradeAds',
    TRADEADS_FORM_OPTIONAL_1: 'Sim',
    TRADEADS_FORM_OPTIONAL_2: 'Não',
    TRADEADS_FORM_AWARD: 'Essa campanha é premiada?',
    TRADEADS_FORM_PARTNER: 'Selecione o parceiro Trade Ads',
    TRADEADS_DEFAULT_CONTENT_1: 'Tenha suas indústrias parceiras em gerentesdigitais!',
    TRADEADS_DEFAULT_CONTENT_2: 'Agora com',
    TRADEADS_DEFAULT_CONTENT_3: 'você e indústrias parceiras poderão personalizar campanhas',
    TRADEADS_DEFAULT_CONTENT_4: 'para lançar produtos juntos!',
    TRADEADS_DEFAULT_CONTENT_5:
      'Saiba os resultados, controle as campanhas, incentive com mais assertividade e',
    TRADEADS_DEFAULT_CONTENT_6: 'fortaleça seus laços.',
    TRADEADS_DEFAULT_CONTENT_7: 'Quero saber mais!',

    TRADEADS_TENANT_LIST: 'Selecione as tags',
    MEDIA_FOLDER: 'Pasta de mídias',

    UNIT_NOT_FOUND: 'Nenhum anúncio encontrado para esta unidade',
    SEARCH_BY_UNIT: 'Pesquise por unidade',
    SEARCH_CAMPAIGN: 'Procurar campanha',
    IMPORTED_GROUPS_MESSAGE:
      'Importamos as unidades pré selecionadas por você. Para compartilhar com mais unidades, basta voltar uma etapa para editar Grupos e unidades.',
    IMPORTED_GROUPS_OK_MESSAGE: 'Entendi!',
    tag: 'Parceiro: ',
    POST: 'postagem',
    POSTS: 'postagens',
    ANNOUNCEMENT: 'anúncio',
    ADVERTS: 'anúncios',
    NO_DOWNLOAD: 'Nenhum download',
    NO_AD: 'Nenhum anúncio',
    NO_POSTS: 'Nenhuma postagem',
    TRADE_CI_TITLE: 'Campanhas de Trade',
    TRADE_CI_SUBTITLE:
      'Campanhas de Trade são ações para estimular a criação de anúncios pelas unidades, defina um tema ou um parceiro da indústria, período e alavanque os seus resultados!',
    TRADE_CI_TITLE: 'Campanhas de Trade',
    TRADE_CI_SUBTITLE:
      'Campanhas de Trade são ações para estimular a criação de anúncios pelas unidades, defina um tema ou um parceiro da indústria, período e alavanque os seus resultados!',
    POST: 'postagem',
    POSTS: 'postagens',
    ANNOUNCEMENT: 'anúncio',
    ADVERTS: 'anúncios',
    NO_DOWNLOAD: 'Nenhum download',
    NO_AD: 'Nenhum anúncio',
    NO_POSTS: 'Nenhuma postagem',

    ALL: 'Todas',
    ALL_2: 'Todos',
    VISUALIZED: 'Visualizou',
    RECEIVED: 'Recebeu',
    UNIT: 'Unidade',
    PARTICIPATION: 'Participações',
    UNITS_PARTICIPATION: 'Participação das unidades',
    FEEDBACK_CARD_NEGATIVE_TITLE: 'Atenção!',
    FEEDBACK_CARD_POSITIVE_TITLE: 'Muito bem!',
    FEEDBACK_CARD_DESCRIPTION: 'das unidades visualizaram esse GDTV',
    DISABLE_MEDIA_FOLDER: 'Desativar pasta de mídias',
    UNITS_USED: 'unidades utilizaram',
    UNIT_USED: 'unidade utilizou',
    ADS_PRODUCED: 'Anúncios produzidos',
    AD_PRODUCED: 'Anúncio produzido',
    POSTS_PRODUCED: 'Postagens produzidas',
    POST_PRODUCED: 'Postagem produzida',
    EDIT_MEDIA_MODAL_TITLE: 'Editar pasta de mídia',
    DELETE_MEDIA_MODAL_TITLE: 'Você realmente deseja excluir essa mídia?',
    DELETE_MEDIA_TOOLTIP: 'Excluir',
    DELETE_MEDIA_MODAL_SUBTITLE:
      'A mídia será excluída da pasta. Ela não poderá mais ser acessada pelas unidades, mas continuará ativa nas publicações onde foi utilizada.',
    ADD_MEDIA_NAME_MODAL_TITLE: 'Insira novos arquivos de mídia',
    ADD_MEDIA_NAME_MODAL_SUBTITLE: 'Extensões permitidas: jpeg, jpg, png, mp4 e webm.',
    FEEDBACK_CARD_DESCRIPTION_CI: 'das unidades participaram desta Campanha',
    PENDING_REVIEW_LABEL: 'Aguardando aprovação',
    USED_VALUE: 'Valor utilizado',
    OFF_CAMPAIGN_BUDGET: 'do orçamento da campanha',
    MEDIA_UPLOAD_ERROR: 'Ocorreu um erro ao enviar a mídia',
    ADD_MEDIA_TOOLTIP: 'Adicionar mídia',
    MEDIA_UPLOAD_SUCCESS_TOAST: 'Sucesso ao realizar o upload das mídias',
    DELETE_MEDIA_FOLDER: 'Excluir pasta de mídias',
    DELETE_MEDIA_FOLDER_MODAL_TITLE: 'Você realmente deseja excluir essa pasta?',
    DELETE_MEDIA_FOLDER_MODAL_SUBTITLE: 'A pasta não estará mais acessível para as unidades.',
    LAST_CAMPAIGN_TITLE: 'Não foram encontradas campanhas no periodo selecionado!',
    LAST_CAMPAIGN_SUBTITLE:
      'Sua última campanha foi criada em {{created}} e disponibilizada no periodo de {{start}} à {{end}}.',
    LAST_CAMPAIGN_BTN_SCHEDULE: 'Ver campanhas agendadas',
    LAST_CAMPAIGN_BTN: 'Ir para o período da campanha',

    CREATED_ADS_TOOLTIP: 'Total de anúncios dessa Campanha, produzidos no período.',
    IMPRESSIONS_TOOLTIP:
      'Quantidade de vezes que os anúncios dessa Campanha foram exibidos para o público.',
    ENGAGEMENT_TOOLTIP:
      'Nível de interação dos usuários. É a soma de reações, comentários, compartilhamentos, cliques, e visualizações.',
    CLICKS_LINK_TOOLTIP: 'Quantidade de cliques nos links dos anúncios dessa Campanha.',
    REVENUE_TOOLTIP:
      'A receita de vendas (online e offline) feitas a partir dos anúncios dessa Campanha.',
    SALES_AMOUNT_TOOLTIP:
      'Quantidade de vendas (online e offline) realizadas a partir dos anúncios dessa Campanhas.',
    UNITS_MEAN: 'Média por unidade',
    CPA: 'CPA',
    SHARE_BY_WHATSAPP: 'Enviar pelo Whatsapp',
    CAMPAIGNS_MEAN: 'Média por campanha',
    SEE_DETAILS: 'Ver detalhes',
    HIDE_DETAILS: 'Esconder detalhes',
    PARTICIPATED: 'Participaram',
    OF_UNITS: 'das unidades',
    UNITS: 'unidades',
    OF_BUDGET: 'do orçamento',
    EMPTY_CI_SUBTITLE:
      'Gostaria de ter mais controle sobre o conteúdo criado pelos seus gerentes digitais?',

    RESULTS_SUBTITLE: 'Resultados de todas as campanhas vigentes no período selecionado:',
    TARGET_BUDGET_TEXT: 'de verba utilizada',
    TARGET_BUDGET_TOOLTIP:
      'Percentual de verba utilizada. São consideradas apenas as campanhas com orçamento definido',

    PARTICIPATING_UNITS: 'Unidades participantes',
    EVERY_CAMPAIGN: 'Total de campanhas',
    MEDIA_CHATBOT: 'Mídia do Chatbot',
    TEXT_CHATBOT: 'Texto do Chatbot',
    CHOOSE_FILES: 'Escolher arquivo',
    UNIT_GROUP: 'Grupos de unidades',
    CAMPAIGN_OBJECTIVE: 'Objetivo da Campanha',
    CAPTURE_FORM: 'Formulário de captação',
    DESCRIPTION_OPTIONAL: 'Descrição (opcional)',
    BUDGET_OPTIONAL: 'Orçamento (opcional)',
    HASHTAGS_OPTIONAL: '#Hashtags (opcional)',
    ADVERTISED_PRODUCTS: 'Produtos anunciados',
    ADVERTISED_MANUFACTURERS: 'Fabricantes anunciados',
    TYPE_EVENT: 'Tipo de evento',
    START_END_DATE: 'Data de início e término',
    TEXT_ASSISTANTS: 'Texto de Assistentes',
    MEDIA_ASSISTANTS: 'Mídia do Assistentes',
    PREVIOUS: 'Anterior',
    TEXT: 'Texto',
    MESSAGE: 'Recado da central pra você!',
    PERIOD: 'Período',
    OPTIONAL: '(opcional)',
    SEARCH_GROUP: 'Buscar um grupo',
    SPENT_MESSAGE: '{{spent}} de {{goal}}',

    INVESTED_RESULTS_TOOLTIP: 'Total gasto por suas unidades em anúncios de Campanhas',
    REVENUE_RESULTS_TOOLTIP:
      'A receita de vendas (online e offline) feitas a partir dos anúncios de Campanha',
    SALES_AMOUNT_RESULTS_TOOLTIP:
      'Quantidade de vendas (online e offline) realizadas a partir dos anúncios de Campanhas',
    IMPRESSION_RESULTS_TOOLTIP:
      'Quantidade de vezes que os anúncios de Campanha foram exibidos para o público',
    ENGAGEMENTS_RESULTS_TOOLTIP:
      'Representa o nível de interação dos usuários. É a soma de reações, comentários, compartilhamentos, cliques, e visualizações.',
    CLICK_RESULTS_TOOLTIP: 'Quantidade de cliques nos links dos anúncios de Campanha',
    PARTICIPATING_UNITS_RESULTS_TOOLTIP:
      'Quantidade de unidades que participaram de Campanhas no período',
    TOTAL_CAMPAIGNS_RESULTS_TOOLTIP: 'Quantidade de Campanhas veiculadas no período',
    ADS_RESULTS_TOOLTIP: 'Total de anúncios de Campanha, produzidos no período',
    FILE_TYPES: 'Tipos de arquivos: .jpeg, .jpg, .png e .mp4',
    MAXIMUM_SIZE: 'Tamanho máximo: ',

    PAUSED_MODAL_TITLE: 'Você deseja realmente pausar essa campanha?',
    PAUSED_MODAL_INFO:
      'A campanha será pausada. Ela não poderá mais ser acessada pelas unidades, mas os anúncios criados continuarão ativos.',
    PAUSED_MODAL_ACTION: 'Pausar campanha',
    PAUSED_BOTH_MODAL_TITLE: 'Você deseja realmente pausar essa campanha e anúncios?',
    PAUSED_BOTH_MODAL_INFO:
      'A campanha será pausada, assim como anúncios que já tenham sido criados. Ela não poderá mais ser acessada pelas unidades.',
    PAUSED_BOTH_MODAL_ACTION: 'Pausar campanha e anúncios',
    FINISHED_MODAL_TITLE: 'Você deseja realmente finalizar essa campanha?',
    FINISHED_MODAL_INFO:
      'A campanha será finalizada, mas os anúncios criados continuarão ativos durante o período programado de cada um. Você ainda poderá acessar os resultados da campanha no dashboard.',
    FINISHED_MODAL_ACTION: 'Finalizar campanha',
    DELETE_CAMPAIGN: 'Excluir campanha',
    DELETE_MODAL_TITLE: 'Você deseja realmente excluir essa campanha?',
    DELETE_MODAL_INFO:
      'A campanha será excluída e os anúncios que já tenham sido criados serão finalizados. Ela não constará mais no dashboard de campanhas da plataforma e não poderá mais ser acessada pelas unidades.',
    DELETE_MODAL_ACTION: 'Excluir campanha',
    ACTIVATE_MODAL_TITLE: 'Você deseja realmente ativar essa campanha?',
    ACTIVATE_MODAL_INFO:
      'A campanha será ativada. As unidades poderão participar da campanha, criando novos anúncios pelo aplicativo.',
    ACTIVATE_MODAL_ACTION: 'Ativar campanha',
    ACTIVATE_BOTH_MODAL_TITLE: 'Você deseja realmente ativar essa campanha e anúncios?',
    ACTIVATE_BOTH_MODAL_INFO:
      'A campanha será ativada, assim como anúncios que tenham sido criados anteriormente. As unidades poderão participar da campanha, criando novos anúncios pelo aplicativo.',
    ACTIVATE_BOTH_MODAL_ACTION: 'Ativar campanha e anúncios',
    LABEL_STATUS_EXCLUDED: 'Excluída',
    PENDING_REVIEW: 'Aguardando aprovação',
    PAUSED: 'Pausada',
  },
};

const MediasES = {
  Medias: {
    OF: 'de',
    PARTICIPATE_INFO: '{{amount}}% de las unidades participaron',
    CONSUMED_BUDGET_INFO: '{{amount}}% del presupuesto de la campaña utilizado',
    FINISH_CAMPAIGN: 'Finalizar Campanha',
    EDIT_INFO: 'Editar información',
    PERIOD: 'Periodo',
    MEDIA: 'Medio',
    MEDIA_ALT: 'Medios',
    SHARE: 'Compartir link',
    MADE_IN: 'Hecho en',
    COPY_LINK: 'Copiar link',
    LIBRARY_TITLE: 'Biblioteca de medios',
    CREATE_CAMPAIGN: 'Crear Campaña',
    LIBRARY_SUBTITLE01: 'Comparta fotos y videos con unidades para ayudar en la creación de',
    LIBRARY_SUBTITLE02: 'contenido. Crea una carpeta y elige con quién compartir!',
    LIBRARY_NEW_FOLDER: 'Criar nueva carpeta',
    LIBRARY_HOW_IT_WORKS: 'Entender cómo funciona',
    VIDEOS: 'vídeos',
    IMAGES: 'Imagenes',
    VIDEO: 'vídeo',
    IMAGE: 'Imagen',
    LIBRARY_WITHOUT_USER: 'Ningún usuario utilizó',
    LIBRARY_USERS_USED: 'usuarios usan',
    LIBRARY_USER_USED: 'usuario utilizado',
    NEXT_STEP: 'Próximo',

    INCENTIVE_CAMPAIGNS: 'Campañas de incentivos',
    INCENTIVE_CAMPAIGNS_SUBTITLE:
      '¡Estimula la creación de publicidad desde tus unidades! ¡Cree una campaña, defina un tema o un socio de la industria y aproveche sus resultados!',
    HOW_IT_WORKS_CI: '¿Cómo funciona?',
    INCENTIVE_CAMPAIGN_ACTION_1:
      'Las campañas de incentivos son acciones para fomentar la creación de anuncios',
    INCENTIVE_CAMPAIGN_ACTION_2:
      'por las unidades, defina un tema, un período y aproveche sus resultados',
    FILTER: 'Filtrar',
    CREATE_NEW_CAMPAIGN: 'Crear campaña',
    CREATE_MEDIAS_1: 'Crea tu primera',
    CREATE_MEDIAS_2: 'Mediateca',
    CREATE_MEDIAS_3:
      'La biblioteca de medios es el espacio para compartir fotos y videos con las unidades. ¡Crea una carpeta y elige con quién compartirla!',

    TITLE_MEDIA_GDTV: 'Crea tu carpeta',

    PROGRESS_BAR_MESSAGE_PART_1: 'Participaron',
    PROGRESS_BAR_MESSAGE_PART_2: 'de las',
    PROGRESS_BAR_MESSAGE_PART_3: 'unidades',

    PRODUCT_HASHTAG: 'Hashtags de Productos: ',
    BRAND_HASHTAG: 'Hashtags de Marca: ',

    GDTV_SUBTITLE:
      '¡Comuníquese con las unidades del proyecto a través de videos! ¡Cree un GDTV con anuncios, consejos e incentivos para que las unidades publiquen más y más!',
    SHARED: 'Compartido',
    SHARED_EVERYONE: 'Compartido con todos',
    SHARE_WITH_EVERYONE: 'Compartir con todos',
    CREATED_AT: 'Creado en',
    ACTIVATE_CAMPAIGN: 'Activar Campaña',
    PAUSE_CAMPAIGN: 'Pausar Campaña',
    GO_BACK: 'Volver',
    CREATE_NEW_GDTV: '+ Crear nuevo GDTV',
    ATIVATE_BOTH: 'Activar campañas y anuncios',
    ATIVATE_CAMPAIGN_ONLY: 'Habilitar solo campaña',

    EDIT_FOLDER_TITLE: 'Editar GDTV',
    SET_FOLDER_INFO_SUCCESS: '¡La información cambió con éxito!',
    SET_FOLDER_INFO_FAILURE: '¡Error al cambiar la información, inténtalo de nuevo más tarde!',

    EDIT_CI_TITLE: 'Editar campaña de incentivo',
    SET_CI_INFO_SUCCESS: '¡Información guardada con éxito!',
    INVALID_URL: 'Url invalida',
    SET_CI_INFO_FAILURE: '¡No se pudo guardar la información, inténtalo de nuevo!',

    //colocar tradução
    REMOVE_FOLDER: 'Do you really want to remove this folder?',
    REMOVE_FOLDER_SUCCESS: 'Folder removed successfully!',
    REMOVE_FOLDER_FAILURE: 'Failed to remove the folder, try again later!',

    DISABLE_FOLDER_SUCCESS: '¡Carpeta deshabilitada con éxito!',
    DISABLE_FOLDER_FAILURE: 'No se pudo deshabilitar la carpeta, ¡inténtalo de nuevo más tarde!',
    ENABLE_FOLDER_SUCCESS: '¡Carpeta activada con éxito!',
    ENABLE_FOLDER_FAILURE: 'No se pudo activar la carpeta, inténtalo de nuevo más tarde.',

    CREATE_MEDIA_SET: 'Create media set',

    UPLOAD_FAILURE: 'Failed to upload the file, try again later',

    NAME: 'Name',
    SIZE: 'Size',
    TEMPLATES_TITLE: 'Dynamic Creative Library',
    TEMPLATES_SUBTITLE:
      'Your templates can be modified with the information we already have available through the Facebook page.',
    // colocar tradução

    TITLE_MEDIA_GDTV_1:
      'Para facilitar la organización, sus comunicados se pueden agrupar por tema.',
    TITLE_MEDIA_GDTV_2: 'Sus unidades recibirán su comunicado en',
    TITLE_MEDIA_GDTV_3: 'Feed de la aplicación.',
    REQUIRED: 'campo obligatorio',
    LABEL_NAME: 'Nombre',
    LABEL_DESCRIPTION: 'Descripción',
    LABEL_CANCEL: 'Cancelar',
    LABEL_ADVANCE: 'Avanzar',

    REMOVE_VIDEO: 'Quitar vídeoo',
    REMOVE_IMAGE: 'Quita la imagen',
    TITLE_ERROR: 'Título obligatorio',
    GDTV_TITLE: 'Título de GDTV',
    TITLE: 'Título',
    GDTV_TITLE_PLACEHOLDER: 'Introduce un título',
    DESCRIPTION_PLACEHOLDER: 'Introduzca una descripción explicativa para el GDTV',
    MEDIA_DESCRIPTION: 'Descripción de medios',
    MEDIA_DESCRIPTION_PLACEHOLDER: 'Ingrese una descripción explicativa a los medios de GDTV',
    GDTV_MEDIA_TITLE: 'Imagen o video de GDTV',
    GDTV_MEDIA_SUBTITLE: 'Sube la imagen o video GDTV con las siguientes especificaciones:',
    GDTV_MEDIA_MAX_SIZE: 'hasta 2 MB',
    GDTV_MEDIA_FORMATS: 'formatos jpeg, jpg, png, mp4, webp, webm',
    UPLOAD_MEDIA_GDTV: 'Subir medios',
    GDTV_MEDIA_ERROR: 'Contenido GDTV requerido',
    UPLOAD: 'Subir',
    SEE_REVIEW: 'Ver resumen',
    GDTV_GROUP_ERROR: 'Agregar al menos un grupo',
    GROUP: 'Grupo: ',
    SELECTED_GROUPS: 'Grupos seleccionados',
    UNINFORMED: 'No informado',

    CAMPAIGN_CREATED_AT: 'Creada en',

    MEDIA_TITLE: 'Uso de medios',
    TITLE_CAMPAIGN: '¡Es hora de crear su campaña de incentivos!',
    TITLE_CAMPAIGN_ONE: 'Puedes publicar',
    TITLE_CAMPAIGN_TWO: 'solo una imagen o video',
    TITLE_CAMPAIGN_THREE: 'a la vez.',
    TITLE_CAMPAIGN_FOUR: 'Extensiones permitidas: jpeg, jpg, png, mp4 y webm.',
    INPUT_DESCRIPTION: 'Descripción del contenido',
    INPUT_SUBTITLE: 'Subtítulo del contenido',
    INPUT_VALID: '¿Cuál será el período de validez de la campaña?',
    SUBTITLE_LEAD_ADS:
      'Atención: para participar en esta campaña, las unidades participantes deben tener el formulario seleccionado vinculado a sus páginas de Facebook.',
    SUBTITLE_WARNING:
      'Atención: este será el período límite en el que las unidades podrán ejecutar sus campañas de incentivos.',
    PLACEHOLDER_CAMPAIGN: 'Ingrese el nombre de la campaña',
    PLACEHOLDER_DESCRIPTION: '¿Cuál será la descripción de la unidad?',
    PLACEHOLDER_START_DATE: 'Fecha de inicio',
    PLACEHOLDER_END_DATE: 'Fecha de finalización',
    INPUT_OBJECTIVE: '¿Y el propósito de los anuncios?',
    CAPTURE_FORM: '¿Cuál será el formulario de captura?',
    CANCEL_BUTTON: 'Cancelar',
    CHOOSE_GROUPS: 'Elegir grupos',
    CHOOSE_UNITS: 'Elegir unidades',
    NAME_CAMPAIGN: 'Campaña',
    TO_EDIT: 'Editar',
    DISABLE_GDTV: 'Deshabilitar GDTV',
    DELETE: 'Eliminar',
    REPORT: 'Informe',
    FEED: 'Feed',
    PERFORMANCE_TITLE: 'Rendimiento',
    CARD_TITLE_OBJECTIVE: 'Objetivo',
    CARD_TITLE_INVESTED: 'Cantidad invertida',
    CARD_TITLE_ADS: 'Anuncios',
    CARD_TITLE_DURATION: 'Duración',
    TITLE_RESULTS: 'Resultados de la campaña',
    CREATED_ADS: 'Anuncios creados',

    ITEM_TOTAL: 'Total',
    ITEM_IMPRESSIONS: 'Impresiones',
    CPM: 'CPM',
    ITEM_ENGAGEMENT: 'Compromiso',
    CPE: 'CPE',
    ER: 'ER',
    ITEM_CLICKS: 'Clics',
    CPC: 'CPC',
    CTR: 'CTR',
    GDTV: 'GDTV',
    ITEM_UNITS: 'Unidades totales',
    TITLE_COMMUNICATED: 'Tus comunicaciones',
    TITLE_MODAL: '¿Estás seguro de que deseas eliminar el contenido?',
    SUBTITLE_MODAL:
      'El contenido se eliminará de todas las carpetas y no será accesible desde las unidades.',
    BUTTON_DELETE: 'Eliminar contenido',
    CAMPAIGN_LABEL_PERIOD: 'Período de validez de la campaña',
    CAMPAIGN_LABEL_OBJECTIVE: 'Objetivo de los anuncios',
    VIEWS_TITLE: 'Vistas:',

    YOUR_CAMPAIGN: 'Tu campaña de incentivos',
    INCLUDE_FILE: 'Incluir archivo',
    VIEW_MORE: 'Ver más',
    EVERYTHING: 'Tudo',
    LABEL_PARTICIPATED: 'Participado',
    LABEL_DELIVERED: 'Entregado',
    LABEL_VIEW: 'Visto',
    LABEL_NOT_DELIVERED: 'No recibió',
    STATUS_HEADER: 'Estado',
    NAME_HEADER: 'Nombre',
    ANNOUNCEMENT_VIEW_TITLE: 'Vistas de anuncios:',
    CAMPAIGN_VIEW_TITLE: 'Vistas de campaña:',
    CAMPAIGN: 'Campaña',
    GENERALS_VIEWS: 'Vistas generales:',
    STATUS_CAMPAIGN: 'Estado de la campaña:',
    LABEL_STATUS_ACTIVE_AD: 'Activos',
    LABEL_STATUS_PAUSED_AD: 'Detenidos',
    LABEL_STATUS_SCHEDULED_AD: 'Programados',
    LABEL_STATUS_FINISHED_AD: 'Finalizados',
    LABEL_STATUS_ARCHIVED_AD: 'Archivados',
    LABEL_STATUS_ACTIVE: 'Activos',
    LABEL_STATUS_PAUSED: 'Detenido',
    LABEL_STATUS_SCHEDULED: 'Programados',
    LABEL_STATUS_FINISHED: 'Finalizados',
    LABEL_STATUS_ARCHIVED: 'Archivados',
    PAUSE_CAMPAIGN_ONLY: 'Pausar solo campaña',
    PAUSE_BOTH: 'Detener campaña y anuncios',
    CAMPAIGN_ARCHIVE: 'Campaña de archivo',
    ERROR_MESSAGE: 'Error al intentar cambiar el estado',
    CLICKABLE_SPAN_EDIT: 'Editar',
    CAMPAIGN_BUDGET: '¿Cuál será el presupuesto de la campaña?',
    INSERT_THE_CAMPAIGN_BUDGET: 'Presupuesto de campaña',
    SUB_TITLE_SELECT_GROUPS_1: 'Puede seleccionar un grupo completo o solo algunos',
    SUB_TITLE_SELECT_GROUPS_2: 'unidades para mostrar su campaña de incentivos.',
    CAMPAIGN_GOAL: 'Meta',
    OPEN_SUBGROUP: 'Abierto',
    LEADS_GENERATED: 'Leads generados',
    DOWNLOAD: 'Descargar',
    NO_DOWNLOADS: 'Sin descargas',
    ONE_DOWNLOAD: 'Descarga',
    DOWNLOADS: 'Descargas',
    NO_CONTENT: 'Sin contenido',
    FILTER_LABEL_BRAND_HOUSE: 'Casas Bahía',
    FILTER_LABEL_BRAND_SCORE: 'Puntuación',
    MESSAGE_CASAS_BAHIA: 'Todas las unidades de Casas Bahía han sido seleccionadas.',
    MESSAGE_POINT: 'Todas las unidades de puntos han sido seleccionadas.',
    TRADEADS_NEW_TRADE_CAMPAIGN_TITLE: '¡Es hora de crear su campaña comercial!',
    TRADEADS_LIMIT_BUDGET_TITLE: '¿Le gustaría tener un presupuesto fijo por unidad?',
    TRADEADS_LIMIT_BUDGET_PLACEHOLDER: 'Límite de presupuesto por tienda',
    TRADEADS_LIMIT_BUDGET_SUBTITLE: 'Solo permitirá una participación en la campaña.',

    FILTERS_RESET_1: 'Vaya',
    FILTERS_RESET_2: '¡Desafortunadamente, no encontramos campañas con este estado!',
    FILTERS_RESET_3: 'Filtrar de nuevo',

    SEARCH_PLACEHOLDER: 'Búsqueda',
    CREATE_CAMPAIGN_1: 'Crea el primero',
    CREATE_CAMPAIGN_2: '¡Campaña de incentivos!',
    CREATE_CAMPAIGN_3:
      'Las campañas de incentivos son acciones para fomentar la creación de anuncios',
    CREATE_CAMPAIGN_4: 'por las unidades. ¡Defina un tema, un período y aproveche sus resultados!',

    name: 'Nombre de la página',
    single_line_address: 'Dirección',
    phone: 'Teléfono',
    global_brand_page_name: 'Nombre de página',
    link_clicks: 'Clics en enlaces',
    lead_generation: 'Generación de registro',
    revenue: 'Ingresos',
    roas: 'ROAS',
    sales_amount: 'Volumen de ventas',
    post_engagement: 'Compromiso',
    conversions: 'Conversiones',

    FILE_UPLOAD_1: 'Sube tu contenido',
    FILE_UPLOAD_2: 'Puede cargar',
    FILE_UPLOAD_3: 'solo una imagen o video',
    FILE_UPLOAD_4: 'a la vez.',
    FILE_UPLOAD_5: 'Extensiones permitidas: jpeg, jpg, png, mp4 y webm.',
    FILE_UPLOAD_6: 'Agregar archivo',

    FILE_CANCEL: 'Cancelar',
    FILE_SEND: 'Enviar',
    FILE_SHARED_ALL: 'Compartir con todos',
    FILE_CHOOSE_UNITY: 'Elegir unidades',

    DESCRIPTION: 'Descripción',

    GDTV_DEFAULT_1: 'Crea tu primer GDTV',
    TITLE_CD_01: 'Haz tu campaña nacional',
    TITLE_CD_02: 'con el apoyo de gerentes digitales!',
    CD_DESCRIPTION_01:
      'Con Anuncio Dinámico puedes personalizar tu campaña nacional para cada una de tus unidades.',
    CD_DESCRIPTION_02:
      '¡Es muy simple! Tú defines tus medios, seleccionas las unidades y eliges las variables: ¡teléfono, dirección y nombre de la página!',
    CD_QUOTATION: '¡Quiero una cotización!',

    MEDIA_SET_DESCRIPTION: 'Insira uma descrição explicativa para a biblioteca de mídias',
    MEDIA_SET_TITLE_PLACEHOLDER: 'Insira um título para a biblioteca de mídias',

    TITLE_CAMPAIGN: '¡Es hora de crear su campaña de incentivos!',
    TITLE_CAMPAIGN_ONE: 'Puedes publicar',
    TITLE_CAMPAIGN_TWO: 'solo una imagen o video',
    TITLE_CAMPAIGN_THREE: 'a la vez.',
    TITLE_CAMPAIGN_FOUR: 'Extensiones permitidas: jpeg, jpg, png, mp4 y webm.',
    CAMPAIGN_NAME: 'Nombre de campaña',
    PLACEHOLDER_CAMPAIGN: 'Ingrese el nombre de la campaña',
    SELECT_ALL: 'Seleccionar todo',
    PLACEHOLDER_DESCRIPTION: 'Cuál será la descripción de la unidad??',
    PLACEHOLDER_START_DATE: 'Fecha de inicio',
    PLACEHOLDER_END_DATE: 'Fecha final',
    INPUT_DESCRIPTION: 'Descripción del contenido',
    INPUT_VALID: 'Cuál será el período de validez de la campaña?',
    SUBTITLE_WARNING:
      'Atención: este será el período límite en el que las unidades podrán realizar sus campañas de incentivos.',
    INPUT_OBJECTIVE: 'Y el propósito de los anuncios?',
    SELECT_GOAL: 'Seleccione el propósito de su anuncio',
    CANCEL: 'Cancelar',
    SAVE: 'Guardar',
    SEND_BUTTON: 'Avance',

    LINK_COPY_SUCCESS: 'Link copiado al portapapeles ',
    LINK_COPY_ERROR: 'Error al copiar la URL',
    LINK_SHARE: 'Enlace en la aplicación',
    DOWNLOAD_MEDIA: 'Descargar medios',

    FILE_TITLE: 'Sube tus archivos',
    TITLE_SELECT_GROUPS: 'Elija unidades para ejecutar la campaña',

    MEDIA_SET_TITLE: 'Título de la biblioteca de medios',
    MEDIA_SET_DESCRIPTION: 'Ingrese una descripción explicativa para la biblioteca de medios',
    MEDIA_SET_TITLE_PLACEHOLDER: 'Ingrese un título para la biblioteca de medios',
    CREATE_NEW_MEDIA_SET: 'Crear biblioteca de medios',
    MEDIA_SET_UPLOAD_AREA_TITLE: 'Agregue medios a su biblioteca aquí',
    MEDIA_SET_UPLOAD_AREA_SUB_TITLE: 'Extensiones permitidas: jpeg, jpg, png y mp4',
    MEDIA_SET_MEDIA_CREATION_ERROR_TOAST:
      'Se creó la biblioteca de medios, pero los medios no se agregaron correctamente',
    MEDIA_SET_CREATION_ERROR_TOAST: 'Error al crear la biblioteca de medios',
    MEDIA_UPLOADING_ERROR_TOAST: 'Espere a que se carguen los medios',

    TRADEADS_DESCRIPTION_1: 'Campañas Trade Ads',
    TRADEADS_DESCRIPTION_2:
      'Las Campañas Trade Ads son anuncios patrocinados por la Industria. Seleccione uno',
    TRADEADS_DESCRIPTION_3: 'socio, defina un tema, período y aproveche sus resultados!',
    TRADEADS_NEW_CAMPAIGN: 'Crear una nueva campaña de TradeAds',

    UNSUPPORTED_MEDIA_TYPE: 'Tipo de archivo no soportado',
    MEDIA_SET_IMAGES_TITLE: 'Imágenes de biblioteca',

    DATA_FROM: `De`,
    DATE_TO: 'a',

    TRADEADS_FORM_OPTIONAL_1: 'Sí',
    TRADEADS_FORM_OPTIONAL_2: 'No',
    TRADEADS_FORM_AWARD: '¿Se premia esta campaña?',
    TRADEADS_FORM_PARTNER: 'Seleccionar socio de anuncios comerciales',
    TRADEADS_DEFAULT_CONTENT_1: '¡Tenga sus industrias asociadas en administradores digitales!',
    TRADEADS_DEFAULT_CONTENT_2: 'Ahora con',
    TRADEADS_DEFAULT_CONTENT_3: 'usted y las industrias asociadas podrán personalizar campañas',
    TRADEADS_DEFAULT_CONTENT_4: '¡para lanzar productos juntos!',
    TRADEADS_DEFAULT_CONTENT_5:
      'Conocer los resultados, controlar las campañas, animar más asertivamente y',
    TRADEADS_DEFAULT_CONTENT_6: 'fortalece tus lazos.',
    TRADEADS_DEFAULT_CONTENT_7: '¡Quiero saber más!',

    TRADEADS_TENANT_LIST: 'Seleccionar etiquetas',
    MEDIA_FOLDER: 'Carpeta de medios',

    UNIT_NOT_FOUND: 'No se han encontrado anuncios para esta unidad',
    SEARCH_BY_UNIT: 'Buscar por unidad',
    SEARCH_CAMPAIGN: 'Campaña de búsqueda',

    IMPORTED_GROUPS_MESSAGE:
      'Importamos las unidades preseleccionadas por ti. Para compartir con más unidades, simplemente retroceda un paso para editar Grupos y Unidades.',
    IMPORTED_GROUPS_OK_MESSAGE: 'Entendí!',
    tag: 'Compañero: ',

    NO_ADS: 'No se crearon anuncios en esta campaña',
    READ_MORE: 'Leer más',
    READ_LESS: 'Leer menos',

    OWN_BUDGET: 'Presupuesto propio',
    COOP_BUDGET: 'Presupuesto coordinado',

    WHATSAPP_SHARE_MSG: 'Echa un vistazo a',
    ACTIVE: 'Activo',
    DISABLED: 'Desactivado',
    DISABLE: 'Deshabilitar',
    ENABLE: 'Activar',
    SCHEDULED: 'Programado',
    REJECTED: 'Rechazado',
    FINISHED: 'Finalizado',

    TRADE_CI_TITLE: 'Campañas comercial',
    TRADE_CI_SUBTITLE:
      '¡Las campañas comerciales son acciones para fomentar la creación de anuncios por parte de las unidades, definir un tema o un socio de la industria, punto y aprovechar sus resultados!',

    ALL: 'Todos',
    ALL_2: 'Todos',
    VISUALIZED: 'Sierra',
    RECEIVED: 'Recibió',
    UNIT: 'Unidad',
    PARTICIPATION: 'Participaciones',
    UNITS_PARTICIPATION: 'Participaciones de unidades',
    FEEDBACK_CARD_NEGATIVE_TITLE: 'Atención!',
    FEEDBACK_CARD_POSITIVE_TITLE: 'Muy bien!',
    FEEDBACK_CARD_DESCRIPTION: 'de unidades vieron este GDTV',
    DISABLE_MEDIA_FOLDER: 'Deshabilitar carpeta de medios',
    UNITS_USED: 'unidades que usaron',
    UNIT_USED: 'unidad que utilizó',
    ADS_PRODUCED: 'Anuncios producidos',
    AD_PRODUCED: 'Anuncio producido',
    POSTS_PRODUCED: 'Publicaciones producidas',
    POST_PRODUCED: 'Post producido',
    EDIT_MEDIA_MODAL_TITLE: 'Editar carpeta multimedia',
    DELETE_MEDIA_MODAL_TITLE: '¿Realmente desea eliminar este medio?',
    DELETE_MEDIA_TOOLTIP: 'Eliminar',
    DELETE_MEDIA_MODAL_SUBTITLE:
      'Los medios se eliminarán de la carpeta. Las unidades ya no podrán acceder a él, pero seguirá activo en las publicaciones donde se utilizó.',
    ADD_MEDIA_NAME_MODAL_TITLE: 'Insertar nuevos archivos multimedia',
    ADD_MEDIA_NAME_MODAL_SUBTITLE: 'Extensiones permitidas: jpeg, jpg, png, mp4 y webm.',

    USED_VALUE: 'Valor utilizado',
    OFF_CAMPAIGN_BUDGET: 'del presupuesto de campaña',
    FEEDBACK_CARD_DESCRIPTION_CI: 'de las unidades que participaron en esta campaña',
    PENDING_REVIEW_LABEL: 'A la espera de aprobación',
    MEDIA_UPLOAD_ERROR: 'Hubo un error al enviar los medios.',
    ADD_MEDIA_TOOLTIP: 'Agregar medios',
    MEDIA_UPLOAD_SUCCESS_TOAST: 'Subiendo medios con éxito',
    DELETE_MEDIA_FOLDER: 'Eliminar carpeta multimedia',
    DELETE_MEDIA_FOLDER_MODAL_TITLE: '¿Realmente desea eliminar esta carpeta?',
    DELETE_MEDIA_FOLDER_MODAL_SUBTITLE: 'La carpeta ya no será accesible para las unidades.',
    LAST_CAMPAIGN_TITLE: '¡No se encontraron campañas en el período seleccionado!',
    LAST_CAMPAIGN_SUBTITLE:
      'Su última campaña se creó en {{created}} y estuvo disponible desde {{start}} hasta {{end}}.',
    LAST_CAMPAIGN_BTN_SCHEDULE: 'Ver campañas programadas',
    LAST_CAMPAIGN_BTN: 'Ir al período de la campaña',

    CREATED_ADS_TOOLTIP: 'Anuncios totales de esta Campaña, producidos en el período.',
    IMPRESSIONS_TOOLTIP:
      'Número de veces que los anuncios de esta campaña se mostraron a la audiencia.',
    ENGAGEMENT_TOOLTIP:
      'Nivel de interacción del usuario. Es la suma de reacciones, comentarios, acciones compartidas, clics y visualizaciones.',
    CLICKS_LINK_TOOLTIP: 'Número de clics en enlaces de anuncios en esta campaña.',
    REVENUE_TOOLTIP:
      'Los ingresos por ventas (en línea y fuera de línea) generados a partir de los anuncios en esta campaña.',
    SALES_AMOUNT_TOOLTIP:
      'Cantidad de ventas (online y offline) realizadas a partir de los anuncios de estas Campañas.',
    UNITS_MEAN: 'Promedio por unidad',
    CPA: 'CPA',
    SHARE_BY_WHATSAPP: 'Enviar por Whatsapp',
    CAMPAIGNS_MEAN: 'Media por campaña',
    SEE_DETAILS: 'Ver detalles',
    HIDE_DETAILS: 'Ocultar detalles',
    PARTICIPATED: 'Participó',
    OF_UNITS: 'de las unidades',
    UNITS: 'unidades',
    OF_BUDGET: 'del presupuesto',
    SPENT_MESSAGE: '{{spent}} de {{goal}}',
    EMPTY_CI_SUBTITLE:
      '¿Te gustaría tener más control sobre el contenido creado por tus gestores digitales?',

    RESULTS_SUBTITLE: 'Resultados de todas las campañas vigentes en el periodo seleccionado:',
    TARGET_BUDGET_TEXT: 'de dinero utilizado',
    TARGET_BUDGET_TOOLTIP:
      'Porcentaje de fondos utilizados. Solo se consideran campañas con un presupuesto definido',

    PARTICIPATING_UNITS: 'Unidades participantes',
    EVERY_CAMPAIGN: 'Total campañas',

    INVESTED_RESULTS_TOOLTIP: 'Gasto total de tus unidades en anuncios de campaña',
    REVENUE_RESULTS_TOOLTIP:
      'Ingresos por ventas (online y offline) obtenidos de los anuncios de campaña',
    SALES_AMOUNT_RESULTS_TOOLTIP:
      'Número de ventas (online y offline) realizadas a partir de los anuncios de campaña',
    IMPRESSION_RESULTS_TOOLTIP:
      'Número de veces que los anuncios de campaña se mostraron al público',
    ENGAGEMENTS_RESULTS_TOOLTIP:
      'Representa el nivel de interacción del usuario. Es la suma de reacciones, comentarios, acciones, clics y vistas.',
    CLICK_RESULTS_TOOLTIP: 'Número de clics en los enlaces de anuncios de campaña',
    ADS_RESULTS_TOOLTIP: 'Total de anuncios de campaña, producidos en el período',
    PARTICIPATING_UNITS_RESULTS_TOOLTIP:
      'Número de unidades que participaron en Campañas en el período',
    TOTAL_CAMPAIGNS_RESULTS_TOOLTIP: 'Número de campañas servidas en el período',
    FILE_TYPES: 'Tipos de archivo: .jpeg, .jpg, .png y .mp4',
    MAXIMUM_SIZE: 'Talla máxima: ',
    GDTV_WAS_CREATED: 'O GDTV foi criado',
    GDTV_WAS_CREATED_BUT_THE_MEDIA_WAS_NOT_ADD:
      'O GDTV foi criado, mas a mídia não foi adicionada com sucesso',
    CAMPAIGN_FAILURE_MESSAGE: 'Error al crear campaña',
    GDTV_DEFAULT_2:
      'El GDTV fue hecho especialmente para la comunicación entre matriz y unidades. ¡Envía tu mensaje a los vendedores de forma sencilla, fácil y rápida!',
    GDTV_DEFAULT_3: 'Crear GDTV',
    PAUSED_MODAL_TITLE: '¿Realmente deseas pausar esta campaña?',
    PAUSED_MODAL_INFO:
      'La campaña será pausada. Ya no será accesible por las unidades, pero los anuncios creados seguirán activos.',
    PAUSED_MODAL_ACTION: 'Pausar campaña',
    PAUSED_BOTH_MODAL_TITLE: '¿Realmente deseas pausar esta campaña y anuncios?',
    PAUSED_BOTH_MODAL_INFO:
      'La campaña será pausada, así como los anuncios que ya hayan sido creados. Ya no será accesible por las unidades.',
    PAUSED_BOTH_MODAL_ACTION: 'Pausar campaña y anuncios',
    FINISHED_MODAL_TITLE: '¿Realmente deseas finalizar esta campaña?',
    FINISHED_MODAL_INFO:
      'La campaña será finalizada, pero los anuncios creados seguirán activos durante el período programado de cada uno. Aún podrás acceder a los resultados de la campaña en el dashboard.',
    FINISHED_MODAL_ACTION: 'Finalizar campaña',
    DELETE_CAMPAIGN: 'Eliminar campaña',
    DELETE_MODAL_TITLE: '¿Realmente deseas eliminar esta campaña?',
    DELETE_MODAL_INFO:
      'La campaña será eliminada y los anuncios que ya hayan sido creados serán finalizados. Ya no aparecerá en el dashboard de campañas de la plataforma y ya no será accesible por las unidades.',
    DELETE_MODAL_ACTION: 'Eliminar campaña',
    ACTIVATE_MODAL_TITLE: '¿Realmente deseas activar esta campaña?',
    ACTIVATE_MODAL_INFO:
      'La campaña será activada. Las unidades podrán participar de la campaña, creando nuevos anuncios en el aplicativo.',
    ACTIVATE_MODAL_ACTION: 'Activar campaña',
    ACTIVATE_BOTH_MODAL_TITLE: '¿Realmente deseas activar esta campaña y anuncios?',
    ACTIVATE_BOTH_MODAL_INFO:
      'La campaña será activada, así como los anuncios que hayan sido creados anteriormente. Las unidades podrán participar de la campaña, creando nuevos anuncios en el aplicativo.',
    ACTIVATE_BOTH_MODAL_ACTION: 'Activar campaña y anuncios',
    LABEL_STATUS_EXCLUDED: 'Excluída',
    PENDING_REVIEW: 'A la espera de aprobación',
    PAUSED: 'Pausado'
  },
};

export { MediasPT, MediasEN, MediasES };
