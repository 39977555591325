import { default as jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { getBusinessID, getBusinessToken } from '@utils/auth';
import { reLoginUser } from '@utils/oAuth';

import { BFF_BASE_URL } from '@utils/constants';

const APIInstance = axios.create({});

APIInstance.interceptors.request.use(
  (config) => {
    config.headers.Pragma = 'no-cache';
    config.headers['Access-Control-Allow-Origin'] = '*';
    config.headers['X-Company'] = getBusinessID();
    config.headers.Authorization = `Bearer ${getBusinessToken()}`;

    return config;
  },
  (error) => Promise.reject(error)
);

const BFFApiExecutor = (uri) => {
  const baseURL = `${BFF_BASE_URL}${uri}`;

  return {
    async get() {
      return APIInstance.get(baseURL);
    },
    async post(data) {
      return APIInstance.post(baseURL, data);
    },
    async put(data) {
      return APIInstance.put(baseURL, data);
    },
    async patch(data) {
      return APIInstance.patch(baseURL, data);
    },
    async delete() {
      return APIInstance.delete(baseURL);
    },
  };
};

const reAuthenticateUser = () => {
  reLoginUser();
};

const checkBusinessTokenExpired = () => {
  const token = getBusinessToken();

  if (token) {
    const decoded = jwtDecode(token);

    if (new Date(decoded.exp * 1000) < new Date()) {
      reAuthenticateUser();
      return;
    }
    reAuthenticateUser();
  }

  /**
   * TO-DO -> BFF ainda não tem rota de refresh token,
   * será necessário redirecionar usuário para o login por enquanto.
   */
  return {
    message: 'Invalid Token, redirect user to login.',
  };
};

const handleError = (error) => {
  if (error.message && error.message.indexOf('failed with status code 401') > -1) {
    return checkBusinessTokenExpired();
  }
  return {
    message:
      '(Request with error)TO-DO -> Aplicar tratamento de exceções para todos os tipos de requests.',
    errorData: error?.response?.data || {},
  };
};

const executeRequest = async (type, uri, data) => {
  let response;

  try {
    if (data) {
      response = await BFFApiExecutor(uri)[type](data);

      if (response.status === 200) {
        return response;
      }

      throw response;
    }

    response = await BFFApiExecutor(uri)[type]();

    if (response.status === 200 || response.status === 204) {
      return response;
    }

    throw response;
  } catch (err) {
    return handleError(err);
  }
};

const BFFApi = {
  get: (uri) => executeRequest('get', uri),
  post: (uri, data) => executeRequest('post', uri, data),
  put: (uri, data) => executeRequest('put', uri, data),
  patch: (uri, data) => executeRequest('patch', uri, data),
  delete: (uri) => executeRequest('delete', uri),
};

export default BFFApi;
