import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Container from '@components/Container';
import ReportSidebar from './AllSidebars/ReportSidebar';
import SettingsSidebar from './AllSidebars/SettingsSidebar';
import * as S from './styles';
import MediasSidebar from './AllSidebars/MediasSidebar';

function Sidebar() {
  const { pathname } = useLocation();

  const [showSidebar, setShowSidebar] = useState(true);

  useEffect(() => {
    if (pathname.split('/').includes('Report')) {
      setShowSidebar(false);
    }

    setShowSidebar(true);
  }, [pathname]);

  const getSidebar = useMemo(() => {
    if (pathname.split('/').includes('Report')) {
      return <ReportSidebar />;
    } else if (pathname.split('/').includes('Settings')) {
      return <SettingsSidebar />;
    } else if (pathname.split('/').includes('Medias')) {
      return <MediasSidebar />;
    }
  }, [pathname]);

  return (
    !!getSidebar && (
      <S.SidebarWrapper showSidebar={showSidebar}>
        <Container>
          <S.LinksWrapper>{getSidebar}</S.LinksWrapper>
        </Container>
      </S.SidebarWrapper>
    )
  );
}

export default Sidebar;
