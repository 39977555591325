/* eslint-disable react/jsx-filename-extension */
import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslate } from 'react-translate';

import {
  Text,
  Title,
  SwitchButton,
  TooltipWrapper,
  HelpTooltip,
} from '@bornlogic/gd-design-system';
import AdvancedConfigsContext from '@utils/contexts/AdvancedConfig';
import { AdvancedDialogContainer } from '../styles';
import Box from '../../../../../components/Box';

import { primaryTheme as theme } from '../../../../../styles/themes';

const TooltipSpacing = styled.div`
  margin: -4px 0px 0px 3px;
`;

const PublicationForm = () => {
  const {
    facebookAutomaticPositions,
    setFacebookAutomaticPositions,
    instagramAutomaticPositions,
    setInstagramAutomaticPositions,
    feed,
    setFeed,
    stream,
    setStream,
    rightHandColumn,
    setRightHandColumn,
    instagramStory,
    setInstagramStory,
    instantArticle,
    setInstantArticle,
    marketplace,
    setMarketplace,
    story,
    setStory,
    suggestedVideo,
    setSuggestedVideo,
    instreamVideo,
    setInstreamVideo,
  } = useContext(AdvancedConfigsContext);

  const t = useTranslate('Units');

  return (
    <AdvancedDialogContainer>
      <Box flex="1">
        <Text>Defina aqui a plataforma e a posição dos seus anúncios.</Text>
      </Box>
      <Box flex="1" mt={theme.spacing.px24} mb={theme.spacing.px24}>
        <Box flex="18" alignItems="center">
          <Title as="h5">Facebook</Title>
        </Box>
        <Box flex="18" alignItems="center">
          <Title as="h5">Instagram</Title>
        </Box>
      </Box>
      <Box flex="1">
        <Box flex="50">
          <Box>
            <SwitchButton
              toggled={facebookAutomaticPositions}
              onChange={() => setFacebookAutomaticPositions(!facebookAutomaticPositions)}
            />
          </Box>
          <Box alignItems="center" ml={theme.spacing.px8}>
            <Text>{t('AUTOMATIC_PLACEMENTS')}</Text>
            <TooltipSpacing>
              <TooltipWrapper
                padding="10px"
                size="250px"
                orientation="right"
                content={t('AUTOMATIC_PLACEMENTS_HELP_TOOLTIP', { platform: 'Facebook' })}
              >
                <HelpTooltip color="blueGrey4" size="px12" iconSize="1" />
              </TooltipWrapper>
            </TooltipSpacing>
          </Box>
        </Box>
        <Box flex="50" alignItems="center" ml={theme.spacing.px8}>
          <Box>
            <SwitchButton
              toggled={instagramAutomaticPositions}
              onChange={() => setInstagramAutomaticPositions(!instagramAutomaticPositions)}
            />
          </Box>
          <Box ml={theme.spacing.px8}>
            <Text>Posicionamentos automáticos</Text>
            <TooltipSpacing>
              <TooltipWrapper
                padding="10px"
                size="250px"
                orientation="left"
                content={t('AUTOMATIC_PLACEMENTS_HELP_TOOLTIP', { platform: 'Instagram' })}
              >
                <HelpTooltip color="blueGrey4" size="px12" iconSize="1" />
              </TooltipWrapper>
            </TooltipSpacing>
          </Box>
        </Box>
      </Box>
      <Box flex="1" mt={theme.spacing.px16}>
        <Box flex="50">
          <Box>
            <SwitchButton
              toggled={!facebookAutomaticPositions && feed}
              onChange={() => setFeed(!feed)}
            />
          </Box>
          <Box alignItems="center" ml={theme.spacing.px8}>
            <Text>Feed</Text>
            <TooltipSpacing>
              <TooltipWrapper
                padding="10px"
                size="250px"
                orientation="right"
                content={t('FEED_HELP_TOOLTIP')}
              >
                <HelpTooltip color="blueGrey4" size="px12" iconSize="1" />
              </TooltipWrapper>
            </TooltipSpacing>
          </Box>
        </Box>
        <Box flex="50" alignItems="center" ml={theme.spacing.px8}>
          <Box>
            <SwitchButton
              toggled={!instagramAutomaticPositions && stream}
              onChange={() => setStream(!stream)}
            />
          </Box>
          <Box ml={theme.spacing.px8}>
            <Text>Stream</Text>
            <TooltipSpacing>
              <TooltipWrapper
                padding="10px"
                size="250px"
                orientation="left"
                content={t('INSTREAM_VIDEO_HELP_TOOLTIP')}
              >
                <HelpTooltip color="blueGrey4" size="px12" iconSize="1" />
              </TooltipWrapper>
            </TooltipSpacing>
          </Box>
        </Box>
      </Box>
      <Box flex="1" mt={theme.spacing.px16}>
        <Box flex="50">
          <Box>
            <SwitchButton
              toggled={!facebookAutomaticPositions && rightHandColumn}
              onChange={() => setRightHandColumn(!rightHandColumn)}
            />
          </Box>
          <Box alignItems="center" ml={theme.spacing.px8}>
            <Text>Right hand column</Text>
            <TooltipSpacing>
              <TooltipWrapper
                padding="10px"
                size="250px"
                orientation="right"
                content={t('RIGHT_HAND_COLUMN_HELP_TOOLTIP')}
              >
                <HelpTooltip color="blueGrey4" size="px12" iconSize="1" />
              </TooltipWrapper>
            </TooltipSpacing>
          </Box>
        </Box>
        <Box flex="50" alignItems="center" ml={theme.spacing.px8}>
          <Box>
            <SwitchButton
              toggled={!instagramAutomaticPositions && instagramStory}
              onChange={() => setInstagramStory(!instagramStory)}
            />
          </Box>
          <Box ml={theme.spacing.px8}>
            <Text>Story</Text>
            <TooltipSpacing>
              <TooltipWrapper
                padding="10px"
                size="250px"
                orientation="left"
                content={t('STORY_HELP_TOOLTIP')}
              >
                <HelpTooltip color="blueGrey4" size="px12" iconSize="1" />
              </TooltipWrapper>
            </TooltipSpacing>
          </Box>
        </Box>
      </Box>
      <Box flex="1" mt={theme.spacing.px16}>
        <Box>
          <SwitchButton
            toggled={!facebookAutomaticPositions && instantArticle}
            onChange={() => setInstantArticle(!instantArticle)}
          />
        </Box>
        <Box alignItems="center" ml={theme.spacing.px8}>
          <Text>Instant article</Text>
          <TooltipSpacing>
            <TooltipWrapper
              padding="10px"
              size="250px"
              orientation="right"
              content={t('INSTANT_ARTICLE_HELP_TOOLTIP')}
            >
              <HelpTooltip color="blueGrey4" size="px12" iconSize="1" />
            </TooltipWrapper>
          </TooltipSpacing>
        </Box>
      </Box>
      <Box flex="1" mt={theme.spacing.px16}>
        <Box>
          <SwitchButton
            toggled={!facebookAutomaticPositions && marketplace}
            onChange={() => setMarketplace(!marketplace)}
          />
        </Box>
        <Box alignItems="center" ml={theme.spacing.px8}>
          <Text>Marketplace</Text>
          <TooltipSpacing>
            <TooltipWrapper
              padding="10px"
              size="250px"
              orientation="right"
              content={t('MARKETPLACE_HELP_TOOLTIP')}
            >
              <HelpTooltip color="blueGrey4" size="px12" iconSize="1" />
            </TooltipWrapper>
          </TooltipSpacing>
        </Box>
      </Box>
      <Box flex="1" mt={theme.spacing.px16}>
        <Box>
          <SwitchButton
            toggled={!facebookAutomaticPositions && story}
            onChange={() => setStory(!story)}
          />
        </Box>
        <Box alignItems="center" ml={theme.spacing.px8}>
          <Text>Story</Text>
          <TooltipSpacing>
            <TooltipWrapper
              padding="10px"
              size="250px"
              orientation="right"
              content={t('STORY_HELP_TOOLTIP')}
            >
              <HelpTooltip color="blueGrey4" size="px12" iconSize="1" />
            </TooltipWrapper>
          </TooltipSpacing>
        </Box>
      </Box>
      <Box flex="1" mt={theme.spacing.px16}>
        <Box>
          <SwitchButton
            toggled={!facebookAutomaticPositions && suggestedVideo}
            onChange={() => setSuggestedVideo(!suggestedVideo)}
          />
        </Box>
        <Box alignItems="center" ml={theme.spacing.px8}>
          <Text>Suggested video</Text>
          <TooltipSpacing>
            <TooltipWrapper
              padding="10px"
              size="250px"
              orientation="top"
              content={t('SUGGESTED_VIDEO_HELP_TOOLTIP')}
            >
              <HelpTooltip color="blueGrey4" size="px12" iconSize="1" />
            </TooltipWrapper>
          </TooltipSpacing>
        </Box>
      </Box>
      <Box flex="1" mt={theme.spacing.px16}>
        <Box>
          <SwitchButton
            toggled={!facebookAutomaticPositions && instreamVideo}
            onChange={() => setInstreamVideo(!instreamVideo)}
          />
        </Box>
        <Box alignItems="center" ml={theme.spacing.px8}>
          <Text>Instream video</Text>
          <TooltipSpacing>
            <TooltipWrapper
              padding="10px"
              size="250px"
              orientation="top"
              content={t('INSTREAM_VIDEO_HELP_TOOLTIP')}
            >
              <HelpTooltip color="blueGrey4" size="px12" iconSize="1" />
            </TooltipWrapper>
          </TooltipSpacing>
        </Box>
      </Box>
    </AdvancedDialogContainer>
  );
};

export default PublicationForm;
