const PreviewModalEN = {
  INFORMATION: 'Information',
  SUBTITLE: 'Subtitle',
  CHANNEL: 'Channel / Placement',
  POST_NOW_QUESTION: 'Do you want to post now?',
  GO_BACK: 'Go back',
  REVIEW_APPROVE: 'Approve',
  PREVIEW_TITLE: 'Preview of publication',
  CAMPAING_GOAL: 'Campaign Objective',
  INVESTMENT: 'Investment',
  post_engagement: 'Engagement',
};

const PreviewModalPT = {
  INFORMATION: 'Informações',
  SUBTITLE: 'Legenda',
  CHANNEL: 'Canal / Posição',
  POST_NOW_QUESTION: 'Deseja postar agora?',
  GO_BACK: 'Voltar',
  REVIEW_APPROVE: 'Aprovar',
  PREVIEW_TITLE: 'Prévia da publicação',
  CAMPAING_GOAL: 'Objetivo da Campanha',
  INVESTMENT: 'Investimento',
  post_engagement: 'Engajamento',
};

const PreviewModalES = {
  INFORMATION: 'Información',
  SUBTITLE: 'Subtitular',
  CHANNEL: 'Canal / Posición',
  POST_NOW_QUESTION: '¿Quieres publicar ahora?',
  GO_BACK: 'Regresar',
  REVIEW_APPROVE: 'Aprobar',
  PREVIEW_TITLE: 'Vista previa de la publicación',
  CAMPAING_GOAL: 'Objetivo de la campaña',
  INVESTMENT: 'Inversión',
  post_engagement: 'Involucramiento',
};

export { PreviewModalPT, PreviewModalEN, PreviewModalES };
