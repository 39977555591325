import styled, { css } from 'styled-components';

export const BreadCrumbItens = styled.div`
  ${({ theme, noTabs }) => css`
    width: 250px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: ${theme.spacing.px50};
    flex-basis: ${theme.spacing.full};
    background: ${theme.colors.grey3};
    margin-left: -${theme.spacing.px25};
    padding-left: ${theme.spacing.px25};
    ${noTabs ? 'margin-left: 220px;' : ''}
    @media screen and (max-width: ${theme.breakpoint.md}) {
      display: flex;
      padding: ${theme.spacing.px15};
      justify-content: center;
      margin: ${theme.spacing.px15} 0;
      align-items: center;
    }
  `}
`;

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.px20};
  `}
`;

export const TooltipWrapper = styled.div`
  position: absolute;
  top: 50px;
  font-size: 13px;
`;

export const Title = styled.div`
  color: #4c5564;
  font-weight: 600;
  font-size: 16px;
`;

export const SubTitle = styled.div`
  color: #8ca4b3;
  font-size: 12px;
`;

export const Wrapper = styled.div`
  display: block;
  width: 100%;
  max-height: 70vh;
`;

export const SelectButton = styled.div`
  border: 0.5px solid #c3cfd6;
  border-radius: 10px;
  padding: 12px;
  color: #577584;
  cursor: pointer;
  width: 120px;
  text-align: center;
  position: absolute;
  right: 32px;
  top: 32px;
`;
