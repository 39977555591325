import React, { useState } from 'react';
import { useTranslate } from 'react-translate';
import { useDispatch } from 'react-redux';
import SelectOnTab from '@components/SelectOnTab/SelectOnTab';
import { FILTER_CHANNEL } from 'redux/feed/types';
import * as S from './styles';

const CHANNELS = ['all', 'facebook', 'instagram', 'tiktok']

function ButtonMode() {
  const dispatch = useDispatch();
  const t = useTranslate('Feed');

  const [itemSelected, setItemSelected] = useState('all');

  const setExhibitionMode = (feedChannel) => {
    dispatch({
      type: FILTER_CHANNEL.SUCCESS,
      feedFilterChannel: feedChannel,
    });
  };

  const selectMode = (feedChannel) => {
    setItemSelected(feedChannel);
    setExhibitionMode(feedChannel);
  };


  return (
    <S.Wrapper>
      <SelectOnTab
        iconSelect={['fal', 'filter']}
        onChange={(e) => selectMode(e.target.value)}
        selectValue={itemSelected}
      >
        {CHANNELS.map((value) => (
          <S.OptionStyled key={value} value={value}>
            {t(value)}
          </S.OptionStyled>
        ))}
      </SelectOnTab>
    </S.Wrapper>
  );
}

export default ButtonMode;
