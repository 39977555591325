import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate } from 'react-translate';
import { ModalDefault, ModalConfirmation, Title, Select } from '@bornlogic/gd-design-system';
import { SHOW_TOAST } from '@redux/toast/types';
import SettingsService from '../../../../services/settings';
import GroupsPagesAccountsMultiSelectCheckbox from '../../../../components/GroupsPagesAccountsMultiSelectCheckbox';
import * as S from './styles';
import ErrorLogModal from './ErrorLogModal';

const ReplicateAudience = ({ isOpen, onClose, ad_account_id }) => {
  const [publics, setPublics] = useState([]);
  const [selectedPublic, setSelectedPublic] = useState(null);
  const [selectedLeafs, setSelectedLeafs] = useState([]);
  const [isOpenBypassConfirmationModal, setIsOpenBypassConfirmationModal] = useState(false);
  const [errorLog, setErrorLog] = useState(null);

  const dispatch = useDispatch();
  const t = useTranslate('Units');

  const normalizePublics = (data) => {
    const result = [];

    data.forEach((item) => {
      result.push({
        title: item.name,
        value: item.id,
      });
    });

    return result;
  };

  const loadPublics = async () => {
    const response = await SettingsService.getPresetsAudience('1.0', ad_account_id);

    if (response.data && response.data.length > 0) {
      setPublics(normalizePublics(response.data));
    }
  };

  const _handleSelect = (value) => {
    setSelectedPublic(value);
  };

  const cleanUp = () => {
    setPublics([]);
    setSelectedPublic(null);
  };

  const cleanUpAndClose = () => {
    cleanUp();
    onClose();
  };

  const handleReplicateAudience = async ({ shouldBypassInvalidAudienceToClone }) => {
    if (!selectedLeafs || selectedLeafs.length === 0) {
      dispatch({
        type: SHOW_TOAST.SUCCESS,
        message: t('INVALID_GROUPS_SELECION'),
        messageType: 'error',
      });
      return;
    }

    if (!selectedPublic) {
      dispatch({
        type: SHOW_TOAST.SUCCESS,
        message: t('INVALID_PUBLIC'),
        messageType: 'error',
      });
      return;
    }

    const arrAdAccountIds = [];

    selectedLeafs.forEach((item) => {
      if (item.ad_account_ids && item.ad_account_ids.length > 0) {
        item.ad_account_ids.forEach((ad_account) => arrAdAccountIds.push(ad_account));
      }
    });

    const ad_account_ids = [...arrAdAccountIds];
    const body = {
      origin_ad_account_id: ad_account_id,
      origin_audience_key: [selectedPublic],
      ad_account_ids,
      by_pass: shouldBypassInvalidAudienceToClone || false,
    };

    const response = await SettingsService.replicateAudience(body);
    setErrorLog(response?.data);

    if (response.status === 200) {
      cleanUpAndClose();
      dispatch({
        type: SHOW_TOAST.SUCCESS,
        message: t('REPLICATE_AUDIENCE_SUCCESS'),
        messageType: 'success',
      });
      return;
    }

    if (response.status === 400 && response.data?.error === 'INVALID_AUDIENCE_TO_CLONE') {
      setIsOpenBypassConfirmationModal(true);
      return;
    }

    dispatch({
      type: SHOW_TOAST.SUCCESS,
      message: t('REPLICATE_AUDIENCE_ERROR'),
      messageType: 'error',
    });
  };

  useEffect(() => {
    if (ad_account_id !== '') {
      loadPublics();
    }
  }, [ad_account_id]);

  useEffect(() => {
    if (ad_account_id !== '') {
      loadPublics();
    }
  }, [isOpen]);

  const totalAvailable = 0;

  return (
    <>
      <ModalDefault
        isOpen={isOpen}
        title={t('REPLICATE_AUDIENCE')}
        confirmBtnText={t('CONFIRM')}
        onConfirmBtnClick={handleReplicateAudience}
        confirmBtnDisabled={false}
        optionBtnText={t('CANCEL')}
        onOptionBtnClick={cleanUpAndClose}
        thirdBtnDisabled
        onClose={() => onClose()}
        size="40%"
        maxHeight="90%"
        subTitle={`${t('REPLICATE_SUBTITLE1')} ${totalAvailable} ${t('REPLICATE_SUBTITLE2')}`}
      >
        <S.Container>
          <S.InputWrapper>
            <S.LabelSpace>
              <Title as="h6">{t('PUBLIC')}</Title>
            </S.LabelSpace>
            <Select
              items={publics}
              onChange={_handleSelect}
              value={selectedPublic}
              placeholder={t('SELECT_AUDIENCE')}
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <S.LabelSpace>
              <Title as="h6">{t('UNITS_OPTION')}</Title>
            </S.LabelSpace>
            <GroupsPagesAccountsMultiSelectCheckbox
              onSelectLeafs={(data) => setSelectedLeafs(data)}
            />
          </S.InputWrapper>
        </S.Container>
      </ModalDefault>

      <ModalConfirmation
        isOpen={isOpenBypassConfirmationModal}
        title={t('REPLICATE_AUDIENCE_BYPASS_INVALID_AUDIENCE_PROMPT')}
        variant="NEGATIVE"
        confirmBtnText={t('CONTINUE')}
        onConfirmBtnClick={() => {
          handleReplicateAudience({ shouldBypassInvalidAudienceToClone: true });
          setIsOpenBypassConfirmationModal(false);
        }}
        optionBtnText={t('CANCEL')}
        onOptionBtnClick={() => setIsOpenBypassConfirmationModal(false)}
      />
      <ErrorLogModal errorLog={errorLog} onClose={() => setErrorLog(null)} />
    </>
  );
};

export default ReplicateAudience;
