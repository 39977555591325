const ManageGroupsEN = {
  ManageGroups: {
    MOVE_GROUP_TO: 'Move to',
    MOVE_GROUP_TO_TIP: ' (leave blank to move on main group)',
    CHOOSED_GROUP: 'Choosed group',
    CREATE_SUCCESS: 'New group created successfully.',
    REMOVE_SUCCESS: 'Group removed successfully.',
    REMOVE_SUCCESS_PAGE: 'Page removed successfully.',
    MOVE_SUCCESS: 'Group moved successfully.',
    RENAME_SUCCESS: 'Renamed successfully.',
    REPLACE_SUCCESS: 'ID replace successfully.',
    CHANGE_COLOR_SUCCESS: 'Group color changed successfully.',
    CREATE_ERROR: 'An error has occurred while trying to create the new group.',
    REMOVE_ERROR:
      'An error has occurred while trying to remove the group. Make sure that this group has any user and try again',
    REMOVE_NODE_NOT_EMPTY_ERROR: "You can't remove a group that contais child groups.",
    REMOVE_NODE_NOT_EMPTY_ERROR_PAGE: 'Could not delete page.',
    MOVE_ERROR: 'An error has occurred while trying to move the group.',
    MOVE_INVALID_GROUP_ERROR: "You can't move this group to the group that has been selected.",
    RENAME_ERROR: 'An error has occurred while trying to rename.',
    REPLACE_ERROR_SAME_NAME_ID: 'New ID and Name must be different from the ones being replaced.',
    CHANGE_COLOR_ERROR: 'An error has occurred while trying to change the group color.',
    EDIT: 'Edit',
    EDIT_UNIT: 'Edit - {{name}}',
    REPLACE_ID: 'Edit ID',
    INTEGRATIONS: 'Integrations',
    RENAME: 'Renomear',
    RENAME: 'Rename',
    DELETE: 'Delete',
    DELETE_PAGE: 'Delete - {{name}}',
    MOVE_GROUP: 'Move group',
    MOVE: 'Move',
    CREATE: 'Create',
    CANCEL: 'Cancel',
    CONFIRM: 'Confirm',
    RENAME_MODAL_PLACEHOLDER: 'Type here the new name',
    REPLACE_MODAL_PLACEHOLDER: 'Type here the new ID',
    DELETE_CONFIRM_TEXT: 'Do you really want to delete the entire group?',
    DELETE_CONFIRM_TEXT_PAGE: 'Do you really want to delete the page?',
    SEARCH: 'Search',
    NEW_GROUP: 'Novo grupo',
    NAME: 'Name',
    SELECT_COLOR: 'Select a color to the group',
    SELECT_PARENT_GROUP: 'Select a parent group',
    SELECT_PARENT_TIP_GROUP: ' (leave blank to create on main group)',
    IGNORE_BUDGET: 'Ignore budget available in new group',
    VALIDATE_ERROR: 'Invalid ID',
    SUCCESS_INTEGRATION: 'Successfully linked',
    UNLIKED_INTEGRATION: 'Successfully unlinked',
    ERROR_INTEGRATION: 'Could not link',
    MISSING_UNIT_ID: 'Unit ID not provided',
    MISSING_INSTAGRAM_ACCOUNT_ID: 'Instagram ID not provided',
    FAILED_CREDENTIALS: 'Failure to communicate with Market Network. Contact support',
    FAILED_UNIT_ASSET_GROUP_MAP:
      'Error finding Asset Group relative to the given unit. Contact support.',
    MISSING_UNIT_MAP: 'There is no Asset Group for the unit in question. Contact support',
    FAILED_TO_UNMAP: 'Error unlinking Instagram account from the Market Network.',
    FAILED_TO_MAP: 'Error linking Instagram account from the Market Network.',
    FAILED_TO_FETCH_FACEBOOK_BUSINESS_USERS:
      'Error getting user that may be linked to Instagram account in Facebook Business Manager',
    MISSING_BUSINESS_ID: 'Could not identify a valid business manager ID to find IG accounts.',
    SELECT: 'Select',
    UNIT_NAME: 'Unit ID',
    PROFILE_NAME: 'Instagram profile',
    UNIT: 'Unit',
    MAKING_CHANGES: 'Making the changes...',
    EDIT_GROUP_INPUT_LABEL: 'Group name',
    EDIT_PAGE_ID_INPUT_LABEL: 'Page Id',
    EDIT_AD_ACCOUNT_ID_INPUT_LABEL: 'Ad Account Id',
    FAILED_TO_MAP_INSTAGRAM_BUSINESS_ACCOUNTS_TO_USERS:
      'There is no user linked to the Instagram account so the setup can continue. The administrator must make the link.',
    INVALID_INSTAGRAM_BUSINESS_ACCOUNT: 'The account selected to link does not have a professional Instagram profile.'
  },
};

const ManageGroupsPT = {
  ManageGroups: {
    MOVE_GROUP_TO: 'Mover para',
    MOVE_GROUP_TO_TIP: ' (deixe em branco para mover para o grupo principal)',
    CHOOSED_GROUP: 'Grupo escolhido',
    CREATE_SUCCESS: 'Novo grupo criado com sucesso.',
    REMOVE_SUCCESS: 'Grupo removido com sucesso.',
    REMOVE_SUCCESS_PAGE: 'Página removida com sucesso.',
    MOVE_SUCCESS: 'Grupo movido com sucesso.',
    RENAME_SUCCESS: 'Renomeado com sucesso.',
    REPLACE_SUCCESS: 'ID substituido com sucesso.',
    CHANGE_COLOR_SUCCESS: 'Cor alterada com sucesso.',
    CREATE_ERROR: 'Ocorreu um erro ao tentar criar um novo grupo. Tente novamente mais tarde',
    REMOVE_ERROR:
      'Ocorreu um erro ao tentar remover o grupo. Certifique-se que não há usuários atrelados a esse grupo e tente novamente.',
    REMOVE_NODE_NOT_EMPTY_ERROR: 'Não é possível excluir um grupo que possua grupos filhos.',
    REMOVE_NODE_NOT_EMPTY_ERROR_PAGE: 'Não é possível excluir a página.',
    MOVE_ERROR: 'Ocorreu um erro ao tentar mover o grupo. Tente novamente mais tarde',
    MOVE_INVALID_GROUP_ERROR: 'Você não pode mover este grupo para o grupo que foi selecionado.',
    RENAME_ERROR: 'Ocorreu um erro ao tentar renomear. Tente novamente mais tarde',
    REPLACE_ERROR_SAME_NAME_ID:
      'Novo ID e Nome devem ser diferentes dos que estão sendo substituídos.',
    CHANGE_COLOR_ERROR:
      'Ocorreu um erro ao tentar alterar a cor do grupo. Tente novamente mais tarde',
    EDIT: 'Editar',
    EDIT_UNIT: 'Editar - {{name}}',
    REPLACE_ID: 'Editar ID',
    INTEGRATIONS: 'Integrações',
    RENAME: 'Renomear',
    DELETE: 'Excluir',
    DELETE_PAGE: 'Excluir - {{name}}',
    MOVE_GROUP: 'Mover grupo',
    MOVE: 'Mover',
    CREATE: 'Criar',
    CANCEL: 'Cancelar',
    CONFIRM: 'Confirmar',
    RENAME_MODAL_PLACEHOLDER: 'Digite aqui o novo nome',
    REPLACE_MODAL_PLACEHOLDER: 'Digite aqui o novo ID',
    DELETE_CONFIRM_TEXT: 'Deseja mesmo excluir o grupo inteiro?',
    DELETE_CONFIRM_TEXT_PAGE: 'Deseja mesmo excluir a página?',
    SEARCH: 'Procurar',
    NEW_GROUP: 'Novo grupo',
    NAME: 'Nome',
    SELECT_COLOR: 'Selecione uma cor para o grupo',
    SELECT_PARENT_GROUP: 'Selecione um grupo pai',
    SELECT_PARENT_TIP_GROUP: ' (deixe em branco para criar no grupo principal)',
    IGNORE_BUDGET: 'ignorar orçamento disponível no novo grupo',
    VALIDATE_ERROR: 'ID Inválido',
    SUCCESS_INTEGRATION: 'Vinculado com sucesso',
    UNLIKED_INTEGRATION: 'Desvinculado com sucesso',
    ERROR_INTEGRATION: 'Não foi possível vincular',
    MISSING_UNIT_ID: 'ID da unidade não foi informado.',
    MISSING_INSTAGRAM_ACCOUNT_ID: 'ID do instagram não foi informado.',
    FAILED_CREDENTIALS:
      'Falha ao se comunicação com a Market Network. Entrar em contato com o suporte',
    FAILED_UNIT_ASSET_GROUP_MAP:
      'Erro ao encontrar Asset Group relativo à unidade informada. Entrar em contato com o suporte.',
    MISSING_UNIT_MAP:
      'Não existe um Asset Group relativo à unidade informada. Entrar em contato com o suporte.',
    FAILED_TO_UNMAP: 'Erro ao desvincular a conta de Instagram da Market Network.',
    FAILED_TO_MAP: 'Erro ao vincular a conta de Instagram da Market Network.',
    FAILED_TO_FETCH_FACEBOOK_BUSINESS_USERS:
      'Erro ao obter usuário que pode estar vinculado à conta do Instagram no Facebook Business Manager.',
    FAILED_TO_MAP_INSTAGRAM_BUSINESS_ACCOUNTS_TO_USERS:
      'Não há usuário vinculado à conta do Instagram para que a configuração possa continuar. O administrador deve fazer o vínculo.',
    MISSING_BUSINESS_ID:
      'Não foi possível identificar um ID do Business Manager válido para encontrar contas do IG.',
    SELECT: 'Selecione',
    UNIT_ID: 'ID da unidade',
    PROFILE_NAME: 'Perfil do instagram',
    UNIT: 'Unidade',
    MAKING_CHANGES: 'Fazendo as alterações...',
    EDIT_GROUP_INPUT_LABEL: 'Nome do grupo',
    EDIT_PAGE_ID_INPUT_LABEL: 'ID da página',
    EDIT_AD_ACCOUNT_ID_INPUT_LABEL: 'ID da conta',
    INVALID_INSTAGRAM_BUSINESS_ACCOUNT: 'A conta selecionada para vincular não possui um perfil profissional no Instagram.'
  },
};

const ManageGroupsES = {
  ManageGroups: {
    MOVE_GROUP_TO: 'Mover a',
    MOVE_GROUP_TO_TIP: ' (déjelo en blanco para pasar al grupo principal)',
    CHOOSED_GROUP: 'Grupo escolhido',
    CREATE_SUCCESS: 'Nuevo grupo creado con éxito.',
    REMOVE_SUCCESS: 'Grupo eliminado correctamente.',
    REMOVE_SUCCESS_PAGE: 'Página eliminada con éxito.',
    MOVE_SUCCESS: 'El grupo se há movido con éxito.',
    RENAME_SUCCESS: 'Renombrado correctamente.',
    REPLACE_SUCCESS: 'Identificación reemplazada con éxito.',
    CHANGE_COLOR_SUCCESS: 'El color se ha cambiado con éxito.',
    CREATE_ERROR:
      'Se produjo un error al intentar crear un grupo nuevo. Inténtalo de nuevo más tarde.',
    REMOVE_ERROR:
      'Se produjo un error al intentar eliminar el grupo. Asegúrese de que no haya usuarios asignados a este grupo y vuelva a intentarlo.',
    REMOVE_NODE_NOT_EMPTY_ERROR: 'No puede eliminar un grupo que tenga grupos secundarios.',
    REMOVE_NODE_NOT_EMPTY_ERROR_PAGE: 'No se pudo eliminar la página.',
    MOVE_ERROR: 'Se produjo un error al intentar mover el grupo. Inténtalo de nuevo más tarde.',
    MOVE_INVALID_GROUP_ERROR: 'No puede mover este grupo al grupo que ha sido seleccionado.',
    RENAME_ERROR: 'Se produjo un error al intentar cambiar el nombre. Inténtalo de nuevo más tarde',
    REPLACE_ERROR_SAME_NAME_ID:
      'El nuevo ID y el nombre deben ser diferentes de los que se reemplazan.',
    CHANGE_COLOR_ERROR:
      'Se produjo un error al intentar cambiar el color del grupo. Inténtalo de nuevo más tarde.',
    EDIT: 'Editar',
    EDIT_UNIT: 'Editar - {{name}}',
    REPLACE_ID: 'Reemplazar ID',
    RENAME: 'Rebautizar',
    DELETE: 'Eliminar',
    DELETE_PAGE: 'Eliminar - {{name}}',
    MOVE_GROUP: 'Mover grupo',
    MOVE: 'Mover',
    CREATE: 'Crear',
    CANCEL: 'Cancelar',
    CONFIRM: 'Confirmar',
    RENAME_MODAL_PLACEHOLDER: 'Ingrese el nuevo nombre aquí',
    REPLACE_MODAL_PLACEHOLDER: 'Ingrese el nuevo ID aquí',
    DELETE_CONFIRM_TEXT: '¿Realmente quieres eliminar todo el grupo?',
    DELETE_CONFIRM_TEXT_PAGE: '¿Realmente quieres eliminar la página',
    SEARCH: 'Buscar',
    NEW_GROUP: 'Nuevo grupo',
    NAME: 'Nombre',
    SELECT_COLOR: 'Seleccione un color para el grupo',
    SELECT_PARENT_GROUP: 'Seleccione un grupo padre',
    SELECT_PARENT_TIP_GROUP: ' (dejar en blanco para crear en el grupo principal)',
    IGNORE_BUDGET: 'ignorar el presupuesto disponible en el nuevo grupo',
    VALIDATE_ERROR: 'ID invalida',
    SUCCESS_INTEGRATION: 'Vinculado con éxito',
    UNLIKED_INTEGRATION: 'Desvinculado con éxito',
    ERROR_INTEGRATION: 'No se pudo vincular',
    MISSING_UNIT_ID: 'ID de unidad no proporcionado',
    MISSING_INSTAGRAM_ACCOUNT_ID: 'ID de Instagram no proporcionado',
    FAILED_CREDENTIALS: 'Falta de comunicación con Market Network. Soporte de contacto',
    FAILED_UNIT_ASSET_GROUP_MAP:
      'Error al encontrar el grupo de activos relativo a la unidad dada. Soporte de contacto.',
    MISSING_UNIT_MAP:
      'No hay ningún grupo de activos para la unidad especificada. Soporte de contacto',
    FAILED_TO_UNMAP: 'Error al desvincular la cuenta de Instagram de la Market Network.',
    FAILED_TO_MAP: 'Error al vincular la cuenta de Instagram de la Market Network.',
    FAILED_TO_FETCH_FACEBOOK_BUSINESS_USERS:
      'Error al obtener un usuario que puede estar vinculado a una cuenta de Instagram en Facebook Business Manager.',
    FAILED_TO_MAP_INSTAGRAM_BUSINESS_ACCOUNTS_TO_USERS:
      'No hay ningún usuario vinculado a la cuenta de Instagram, por lo que la configuración puede continuar. El administrador debe hacer el enlace.',
    MISSING_BUSINESS_ID:
      'No se pudo identificar una ID de Business Manager válida para encontrar cuentas de IG.',
    SELECT: 'Seleccione',
    UNIT_ID: 'ID de la unidad',
    PROFILE_NAME: 'Perfil de instagram',
    UNIT: 'Unidad',
    MAKING_CHANGES: 'Making the changes...',
    EDIT_GROUP_INPUT_LABEL: 'Nombre del grupo',
    EDIT_PAGE_ID_INPUT_LABEL: 'ID de la página',
    EDIT_AD_ACCOUNT_ID_INPUT_LABEL: 'ID de la conta',
    INVALID_INSTAGRAM_BUSINESS_ACCOUNT: 'La cuenta seleccionada para vincular no tiene un perfil profesional de Instagram.'
  },
};

export { ManageGroupsPT, ManageGroupsEN, ManageGroupsES };
