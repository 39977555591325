import styled, { css } from 'styled-components';

export const VideoOverwrite = styled('div')`
  ${({ theme, isPaused, isEnded }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.black};
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    &:hover {
      a[title='Download'],
      .close-video {
        opacity: 1;
        visibility: visible;
      }
    }
    ${isPaused || isEnded
      ? css`
          a[title='Download'],
          .close-video {
            opacity: 1;
            visibility: visible;
          }
        `
      : ''}
  `}
`;

export const CloseVideo = styled('div')`
  ${({ theme }) => css`
    position: absolute;
    top: ${theme.spacing.px20};
    right: ${theme.spacing.px20};
    cursor: pointer;
    width: ${theme.spacing.px30};
    height: ${theme.spacing.px30};
    background: ${theme.colors.white};
    border-radius: ${theme.rounded.full};
    border: 1px solid ${theme.colors.blueGrey3};
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    z-index: 3;
    svg {
      font-size: ${theme.fontSize.px20};
      color: ${theme.colors.blueGrey3};
    }
  `}
`;

export const DownloadButtonConteiner = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: ${theme.spacing.px20};
    right: ${theme.spacing.px15};
  `}
`;
