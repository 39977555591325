import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'react-translate';
import { ModalDefault } from '@bornlogic/gd-design-system';
import SchemaService from '../../../../services/schema';
import AddUnitTabs from './AddUnitTabs';
import { ADD_UNIT } from '../../../../redux/settings/types';

const AddUnit = ({ isOpen, onClose, confirmCloseEvent }) => {
  const t = useTranslate('Units');
  const dispatch = useDispatch();
  const {
    breadCrumb: {
      data: { currentCode: breadcrumbCode },
    },
  } = useSelector((state) => state.genericReducer);

  const [inviteUsersToBM, setInviteUsersToBM] = useState(false);
  const [accountMethod, setAccountMethod] = useState(null);
  const [creditLine, setCreditLine] = useState(null);
  const [monthlyBudget, setMonthlyBudget] = useState('');
  const [actuationRadius, setActuationRadius] = useState('');
  const [autoApprove, setAutoApprove] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [unitName, setUnitName] = useState('');
  const [facebookId, setFacebookId] = useState('');
  const [facebookIdError, setFacebookIdError] = useState('');
  const [accountIdError, setAccountIdError] = useState('');
  const [adAccountId, setAdAccountId] = useState('');
  const [elegibleGroups, setElegibleGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedCreditLine, setSelectedCreditLine] = useState(null);

  const normalizeElegibleGroupsData = (groups) =>
    groups.map((group) => ({
      value: group.source_id,
      title: group.name,
    }));

  useEffect(() => {
    let sourceId = 'root';
    if (breadcrumbCode && breadcrumbCode.source_id && breadcrumbCode.type === 'group') {
      sourceId = breadcrumbCode.source_id;
    }

    SchemaService.getElegibleGroups(sourceId).then((response) => {
      if (response && response.data && response.data.length > 0)
        setElegibleGroups(normalizeElegibleGroupsData(response.data));
    });
  }, [breadcrumbCode]);

  const cleanAddUnitState = () => {
    setCreditLine(null);
    setMonthlyBudget('');
    setActuationRadius('');
    setAutoApprove(false);
    setUserEmail('');
    setUnitName('');
    setFacebookId('');
    setAdAccountId('');
    setSelectedGroup('');
    setAccountMethod(null);
  };

  const cleanAndCloseAddUnitModal = (isClose = false) => {
    if (isClose && confirmCloseEvent) confirmCloseEvent();

    onClose();
    cleanAddUnitState();
  };

  const verifyBtnsIsDisable = () => {
    if (
      actuationRadius === '' ||
      autoApprove === false ||
      unitName === '' ||
      facebookId === '' ||
      (accountMethod === 2 && adAccountId === '') ||
      selectedGroup === '' ||
      creditLine === null ||
      (creditLine === 2 && selectedCreditLine === null) ||
      (creditLine === 2 && monthlyBudget === '')
    ) {
      return true;
    }
    return false;
  };

  const handleAddUnit = (onSuccess) => {
    let ignore_bm_funding = true;
    let funding_id = null;
    let budget = monthlyBudget;

    if (creditLine === 1) {
      budget = 0;
    } else if (creditLine === 2) {
      funding_id = selectedCreditLine;
      ignore_bm_funding = false;
    }

    const body = {
      entries: [
        {
          force_ad_account_as_shared_budget: true,
          budget,
          targeting_rules: {
            radius: actuationRadius,
          },
          user_gd_profile: autoApprove,
          manager_email: userEmail,
          parent_id: selectedGroup,
          name: unitName,
          page_id: facebookId,
          ad_account_id: adAccountId,
          invite_user_to_facebook_business_manager: inviteUsersToBM,
        },
      ],
      ignore_delay: true,
      invite_user_to_gd: true,
      funding_id,
      ignore_bm_funding,
    };

    dispatch({
      type: ADD_UNIT.REQUEST,
      version: '1.1',
      payload: body,
      feedbackMessage: {
        error: t('FEEDBACK_ERROR'),
        success: t('FEEDBACK_SUCCESS'),
      },
      onSuccess,
    });
  };

  const handleAddOneMoreUnit = () => {
    handleAddUnit(() => {
      cleanAddUnitState();
    });
  };

  return (
    <ModalDefault
      isOpen={isOpen}
      title={t('ADD_UNIT')}
      confirmBtnText={t('ADD')}
      onConfirmBtnClick={() => handleAddUnit(cleanAndCloseAddUnitModal)}
      confirmBtnDisabled={verifyBtnsIsDisable()}
      optionBtnText={t('CANCEL')}
      onOptionBtnClick={() => cleanAndCloseAddUnitModal(true)}
      thirdBtnText={t('ADD_ONE_MORE')}
      onThirdBtnClick={handleAddOneMoreUnit}
      thirdBtnDisabled={verifyBtnsIsDisable()}
      onClose={() => cleanAndCloseAddUnitModal(true)}
      size="40%"
      maxHeight="110%"
    >
      <AddUnitTabs
        creditLine={creditLine}
        setCreditLine={setCreditLine}
        monthlyBudget={monthlyBudget}
        setMonthlyBudget={setMonthlyBudget}
        actuationRadius={actuationRadius}
        setActuationRadius={setActuationRadius}
        autoApprove={autoApprove}
        setAutoApprove={setAutoApprove}
        userEmail={userEmail}
        setUserEmail={setUserEmail}
        unitName={unitName}
        setUnitName={setUnitName}
        facebookId={facebookId}
        setFacebookId={setFacebookId}
        adAccountId={adAccountId}
        setAdAccountId={setAdAccountId}
        elegibleGroups={elegibleGroups}
        setSelectedGroup={setSelectedGroup}
        selectedGroup={selectedGroup}
        setSelectedCreditLine={setSelectedCreditLine}
        selectedCreditLine={selectedCreditLine}
        setAccountMethod={setAccountMethod}
        accountMethod={accountMethod}
        setInviteUsersToBM={setInviteUsersToBM}
        inviteUsersToBM={inviteUsersToBM}
        facebookIdError={facebookIdError}
        setFacebookIdError={setFacebookIdError}
        accountIdError={accountIdError}
        setAccountIdError={setAccountIdError}
      />
    </ModalDefault>
  );
};

export default AddUnit;
