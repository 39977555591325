// Modules
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import Image from '@components/Image';

// Images
import images from '@images';

const LoadingStyled = styled('div')`
  ${({ theme }) => `
        width: ${theme.spacing.full};
        height: ${theme.spacing.full};

        max-height: 16px;
        max-width: 16px;

        position: fixed;
        background: rgba(255, 255, 255, 0.85);
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 100000;
        display: flex;
        justify-content: center;
        align-items: center;
    `}
`;

function Loading({ isLoading }) {
  return (
    isLoading && (
      <LoadingStyled>
        <Image src={images.loading} alt="Loading" />
      </LoadingStyled>
    )
  );
}

Loading.propTypes = {
  isLoading: PropTypes.bool,
};

Loading.defaultProps = {
  isLoading: false,
};

export default Loading;
