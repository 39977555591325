import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslate } from 'react-translate';

import { getCount, handleInsightsValues } from './consts';

import { hasCapabilities, validateCapability } from '@utils/helpers';

import { UPDATE_AD, FETCH_FEED } from '@redux/feed/types';

import dayjs from 'dayjs';

import MediaChunkBody from '@components/MediaCards/MediaCardChunks/Type1/Body/Body';
import MediaChunkFooter from 'components/MediaCards/MediaCardChunks/Type1/Footer/Footer';
import MediaChunkHeader from './MediaCardHeader';

export const useGetFeed = () => {
  const dispatch = useDispatch();
  const {
    params: { code },
  } = useRouteMatch();

  const { feedOrderBy, feedType, preset } = useSelector((state) => state.feedReducer);
  const { period } = useSelector((state) => state.genericReducer);

  const triggerFetchFeed = useCallback(
    (next = '') => {
      if (!!period.params) {
        const isViewOrEngagementOrderedBy = ['video_view', 'post_engagement'].includes(feedOrderBy);
        const dispatchPresetProps = isViewOrEngagementOrderedBy ? preset : period.params;

        dispatch({
          type: FETCH_FEED.REQUEST,
          code,
          feedtype: feedType || 'AD',
          next,
          orderBy: feedOrderBy || '',
          preset: dispatchPresetProps,
          limit: getCount(),
        });
      }
    },
    [code, dispatch, feedOrderBy, feedType, period.params, preset]
  );

  useEffect(() => {
    triggerFetchFeed();

    return () => {
      dispatch({
        type: FETCH_FEED.RESET,
      });
    };
  }, [dispatch, triggerFetchFeed]);

  return { triggerFetchFeed };
};

export const useCheckCapabilities = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const { businessTokenData } = useSelector((state) => state.authenticationReducer);

  const [permissions, setPermissions] = useState({
    hasInvolvementMetric: false,
    hasEditAdCapability: false,
    hasBatchReplicateCapability: false,
    hasBatchPromoteCapability: false,
    hasFeedAwards: false,
  });

  const verify = useCallback(() => {
    if (!validateCapability(businessTokenData, 'FEED')) {
      history.push(`/${match?.params?.code}/Report/`);
    }

    const hasInvolvementMetric = validateCapability(businessTokenData, 'POST_INVOLVEMENT');
    const hasEditAdCapability = validateCapability(businessTokenData, 'EDIT_AD');

    const hasBatchPromoteCapability = validateCapability(businessTokenData, 'BATCH_PROMOTE');
    const hasBatchReplicateCapability = validateCapability(businessTokenData, 'BATCH_REPLICATE');

    const hasFeedAwards = validateCapability(businessTokenData, 'FEED_AWARDS');

    setPermissions({
      hasInvolvementMetric,
      hasEditAdCapability,
      hasBatchPromoteCapability,
      hasBatchReplicateCapability,
      hasFeedAwards,
    });
  }, [businessTokenData, history, match?.params?.code]);

  useEffect(() => {
    if (hasCapabilities(businessTokenData)) {
      verify();
    }
  }, [businessTokenData, verify]);

  return permissions;
};

export const useBuildFeedActions = ({
  setShouldShowPreviewModal,
  setMediaToShow,
  setCanShowEditModal,
}) => {
  const dispatch = useDispatch();
  const {
    params: { code },
  } = useRouteMatch();

  const { feedType, isLoadingStatus } = useSelector((state) => state.feedReducer);
  const { feedChannel } = useSelector((state) => state.genericReducer);

  const [showEditModal, setShowEditModal] = useState(null);

  const changeAdStatus = useCallback(
    (media) => {
      if (!isLoadingStatus) {
        let status = 'paused';

        if (media.status === 'paused') {
          status = 'active';
          media.status = 'active';
        } else if (media.status === 'active') {
          status = 'paused';
          media.status = 'paused';
        }

        dispatch({
          type: UPDATE_AD.REQUEST,
          version: '1.1',
          code,
          adId: media.id,
          body: {
            start_date: null,
            end_date: null,
            budget: null,
            call_to_action_link_url: null,
            timezone_offset: -3,
            status,
          },
        });
      }
    },
    [code, dispatch, isLoadingStatus]
  );

  const loadPreviews = useCallback(
    (media) => {
      setShouldShowPreviewModal(true);
      setMediaToShow(media);
    },
    [setMediaToShow, setShouldShowPreviewModal]
  );

  const checkMediaType = useCallback(
    (media) => {
      const mediaType = media.creative_type || media.media_type;

      const openOnInstragem = () => window.open(media.permalink);

      if (mediaType === 'VIDEO') {
        return 'showMedia';
      } else if (feedType === 'INSTAGRAM_STORIES') {
        return () => openOnInstragem();
      } else if (feedType === 'POST' && feedChannel === 'instagram') {
        return () => openOnInstragem();
      } else {
        return () => loadPreviews(media);
      }
    },
    [feedChannel, feedType, loadPreviews]
  );

  const closeModal = () => {
    setShowEditModal(false);
    setShouldShowPreviewModal(false);
  };

  const openEdit = (id, media = '') => {
    setShowEditModal(id);

    setCanShowEditModal(true);
    setMediaToShow(media);
  };

  return {
    checkMediaType,
    changeAdStatus,
    closeModal,
    showEditModal,
    loadPreviews,
    openEdit,
  };
};

export const useFeedData = ({
  checkMediaType,
  openEdit,
  showEditModal,
  closeModal,
  loadPreviews,
  changeAdStatus,
  setMarkedMedias,
  markedMedias,
  shouldShowPreviewModal,
}) => {
  const t = useTranslate('Feed');

  const { data, feedType } = useSelector((state) => state.feedReducer);
  const { feedChannel } = useSelector((state) => state.genericReducer);

  const permissions = useCheckCapabilities();

  const reshapedFeedData = useMemo(() => {
    if (!data) return [];

    return data.map((media) => {
      const mediaType = media.creative_type || media.media_type;
      const onClickBgImage = checkMediaType(media);

      return {
        id: media.id,
        creative_type: media.creative_type,
        attachment_urls: media.attachment_urls,
        source_id: media.fan_page ? media.fan_page.source_id : 'ID não encontrado',
        name: media.fan_page ? media.fan_page.name : 'Nome não encontrado',
        thumbnail_url: (media.image_urls && media.image_urls[0]) || media.thumbnail_url,
        media_url: (media.image_urls && media.image_urls[0]) || media.media_url,
        media_type: mediaType,
        description: media.name,
        insights: handleInsightsValues({
          insights: media.insights,
          feedType,
          capabilities: permissions,
        }),
        previews: media.previews,
        link_url: media.link_url,
        status: media.status,
        start_time: media.start_time,
        created_time: media.created_time,
        permalink: media.permalink,
        end_time: media.end_time,
        lifetime_budget: media.lifetime_budget,
        daily_budget: media.daily_budget,
        page_name: media.page_name,
        like_count: media.like_count,
        video_url: mediaType === 'VIDEO' && media.video_urls && media.video_urls[0],
        showImageBg: true,
        onClickBgImage,
        imageBottomRounded: true,
        changeSwitch: () => changeAdStatus(media),
        checked: media.status === 'active',
        page_post_id: media?.page_post_id,
        loadPreviews,
        openEdit,
        mediaCardChunk: {
          header: (
            <MediaChunkHeader
              media={media}
              isInstagram={
                feedType === 'INSTAGRAM_STORIES' ||
                (feedType === 'POST' && feedChannel === 'instagram')
              }
              isAd={feedType === 'AD'}
              markingCapability={permissions.hasBatchPromoteCapability && permissions.hasBatchReplicateCapability}
              setMarkedMedias={setMarkedMedias}
              markedMedias={markedMedias}
            />
          ),
          body: (
            <MediaChunkBody
              mediaType={mediaType}
              videoUrl={mediaType === 'VIDEO' && media.video_urls && media.video_urls[0]}
              channelType={media.permalink ? 'INSTAGRAM' : 'FACEBOOK'}
              status={media.status}
              t={t}
            />
          ),
          footer: (
            <MediaChunkFooter
              media={media}
              loadPreviews={loadPreviews}
              feedType={feedType}
              showPreviewModal={shouldShowPreviewModal}
              openEdit={openEdit}
              showEditModal={showEditModal}
              insightsTransformed={handleInsightsValues({
                insights: media.insights,
                feedType,
                capabilities: permissions,
              })}
              periodTransformed={`${dayjs(media.start_time).format('DD/MM/YY')} -
                                    ${
                                      media.end_time
                                        ? dayjs(media.end_time).format('DD/MM/YY')
                                        : ' contínua'
                                    }`}
              closeModal={closeModal}
              canEditAd={permissions.hasEditAdCapability}
            />
          ),
        },
      };
    });
  }, [
    data,
    checkMediaType,
    feedType,
    feedChannel,
    permissions,
    setMarkedMedias,
    markedMedias,
    t,
    loadPreviews,
    shouldShowPreviewModal,
    openEdit,
    showEditModal,
    closeModal,
    changeAdStatus,
  ]);

  return reshapedFeedData;
};
