// Api
import { ApiTenant } from '@utils/api';
import BFFApi from '../utils/bffApi';

class SettingsService {
  static getUnits(version, source_id, page_hash, limit) {
    return ApiTenant.get(
      `/schema/${version}/${source_id}/leaves-settings-units?fields=status,name,source_id,parent,page_ids,ad_account_ids,instagram_accounts&page_hash=${page_hash}&limit=${
        limit ?? 20
      }`
    );
  }

  static getUnitsSummary(sourceId) {
    return ApiTenant.get(`/schema/1.2/${sourceId}/leaves/summary-settings-units`);
  }

  static inactivateUnits(sourceId, body) {
    return BFFApi.patch(`/api/v1/unit/${sourceId}`, body);
  }

  static activateUnits(unitId, body) {
    return ApiTenant.patch(`/setup/1.1/finish-setup/${unitId}`, body);
  }

  static addUnit(version, body) {
    return ApiTenant.post(`/setup/${version}/Add`, body);
  }

  static getEAGroups(version, source_id) {
    return ApiTenant.get(
      `/schema/${version}/${source_id}/ContainsEasyAccessLink?fields=name,color`
    );
  }

  static getEAGroupLink(version, source_id) {
    return ApiTenant.get(`/easy-access/${version}/admin/${source_id}/invitationlink`);
  }

  static getEAUsersByGroup(version, source_id) {
    return ApiTenant.get(
      `/easy-access/${version}/admin/users?pending_users_only=true&fields=email,name,current_step,last_update,status,budget&limit=20&root_permitted_group=${source_id}`
    );
  }

  static generateLink(body) {
    return ApiTenant.post('/easy-access/1.0/Admin/InvitationLink', body);
  }

  static getCreditLines() {
    return ApiTenant.get('/setup/1.1/Facebook/Funding');
  }

  static validatePageId(pageId) {
    return ApiTenant.get(`/setup/1.1/validate/facebook/page?value=${pageId}`);
  }

  static validateAccountId(accountId) {
    return ApiTenant.get(`/setup/1.1/validate/facebook/adaccount?value=${accountId}`);
  }

  static getPresetsAudience(version, adAccountId) {
    return ApiTenant.get(`/presets/${version}/Audience/AdAccount/${adAccountId}`);
  }

  static getGroupsPageAdAccountBySourceID(sourceID) {
    return ApiTenant.get(
      `/schema/1.1/${sourceID}/ChildGroups?fields=child_groups_count,source_id,name,ad_account_ids,page_ids,color,child_groups_count&types=['group']`
    );
  }

  static replicateAudience(body) {
    return ApiTenant.post('/presets/1.0/Audience/Clone', body);
  }

  static deleteAudience(originId, body) {
    return ApiTenant.delete(`/presets/1.0/Audience/Origin/${originId}`, body);
  }

  static getForms(page_id) {
    return ApiTenant.get(`/formlead/1.0/frompageid/${page_id}`);
  }

  static replicateLeads(body) {
    return ApiTenant.post('/formlead/1.0/clone', body);
  }

  static createGroup(body, source_id) {
    return ApiTenant.post(`/schema/1.1/${source_id}/groups`, body);
  }

  static deleteGroup(source_id) {
    return ApiTenant.delete(`/schema/1.1/${source_id}`);
  }

  static renameGroup(sourceId, body) {
    return ApiTenant.put(`/schema/1.1/${sourceId}`, body);
  }

  static changeParentGroup(parentSourceId, body) {
    return ApiTenant.post(`/schema/1.1/${parentSourceId}/relationships/member`, body);
  }

  static getAdvancedConfigByAccoundId(adAccountId) {
    return ApiTenant.get(`/rule/1.0/facebook/targetingrestriction/byadaccount/${adAccountId}`);
  }

  static createAdvancedConfigRule(body) {
    return ApiTenant.post('/rule/1.0/Facebook/TargetingRestriction', body);
  }

  static updateAdvancedConfigRule(body) {
    return ApiTenant.put(`/rule/1.0/Facebook/TargetingRestriction/${body.id}`, body);
  }

  static updateGoalsAndCtaRules({ body, unitId }) {
    return ApiTenant.post(`/schema/1.1/${unitId}/medias/approvalrule`, body);
  }

  static getGoalsAndCtaRules(unitId) {
    return ApiTenant.get(`/schema/1.1/${unitId}/medias/approvalrule`);
  }
}

export default SettingsService;
