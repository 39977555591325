import styled, { css } from 'styled-components';

export const Container = styled.section`
  padding: ${({ theme }) => theme.spacing.px10} ${({ theme }) => theme.spacing.px40} ${({ theme }) => theme.spacing.px25} ${({ theme }) => theme.spacing.px35};
`;

export const InputWrapper = styled.section`
  padding: ${({ theme }) => theme.spacing.px10} 0;
`;

export const LabelSpace = styled.section`
  padding-bottom: ${({ theme }) => theme.spacing.px10};
`;

export const ObservationField = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.px12};
    color: ${theme.colors.blueGrey4};
    text-align: right;
    margin-top: ${theme.spacing.px5};
  `}
`;

export const RadioContainer = styled.div`
  span {
    margin-right: ${({ theme }) => theme.spacing.px25};
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;

  label {
    margin-right: ${({ theme }) => theme.spacing.px10};
  }
`;
