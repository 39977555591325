/* Contants */
export const BFF_BASE_URL = process.env.REACT_APP_BFF_BASE_URL;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const DATA_BASE_URL = process.env.REACT_APP_DATA_BASE_URL;
export const BASE_URL_LOCAL = 'https://localhost:5001';
export const MAGAZINE_LUIZA_ID = '5c9386f9504d3100012e95e5';
export const TYPE_MESSAGES = 'GOOGLE_REVIEW';
export const FACEBOOK_URL = 'https://www.facebook.com/';

export const CommonKeys = {
  MEDIA_LIBRARY: 'MEDIA_LIBRARY',
  MEDIA_LIBRARY_PLACEHOLDER: 'MEDIA_LIBRARY_PLACEHOLDER',
  OPTIONAL: 'OPTIONAL',
  TOOLTIP_CONTENT: 'TOOLTIP_CONTENT',
  LOADING: 'LOADING',
};

const { MEDIA_LIBRARY, OPTIONAL, LOADING } = CommonKeys;

export const CommonText = {
  en: {
    [MEDIA_LIBRARY]: 'Media library',
    [OPTIONAL]: 'Optional',
    [LOADING]: 'Loading',
  },
  pt: {
    [MEDIA_LIBRARY]: 'Biblioteca de Mídias',
    [OPTIONAL]: 'Opcional',
    [LOADING]: 'Carregando',
  },
  es: {
    [MEDIA_LIBRARY]: 'Biblioteca de medios',
    [OPTIONAL]: 'Opcional',
    [LOADING]: 'Cargando',
  },
};

export const COMMA_CHAR = ',';
export const HASHTAG_CHAR = '#';
export const UNDERLINE_CHAR = '_';
export const SPACE_CHAR = ' ';

export const americanasTenants = ['americanas', 'lasa'];
export const viaVarejoTenants = ['via', 'viavarejo'];

export const URL_CALLBACK_CAMPAIGN_FIELD_TENANTS = ['panvel', 'bornlogic'];
