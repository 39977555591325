import BFFApi from '@utils/bffApi';

const CompanyService = {
  getCompany() {
    return BFFApi.get('/api/v1/company');
  },
  getCompanyStatusHistory() {
    return BFFApi.get('/api/v1/company/logs');
  },
  getLastCompanyStatusHistory() {
    return BFFApi.get('/api/v1/company/logs/last');
  },
  updateCompany(body) {
    return BFFApi.patch('/api/v1/company', body);
  },
};

export default CompanyService;
