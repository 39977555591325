import React, { useEffect, useState } from 'react';
import { gapi } from 'gapi-script';
import * as S from './styles.js';
import new_logo_bornlogic from '@images/new_logo_bornlogic.png';
import FilesUpload from './upload/FilesUpload';
import { postToGetSasUrl } from './services';
import { useTranslate } from 'react-translate';

const GoogleLogin = () => {
  const t = useTranslate('GoogleLogin');
  const [user, setUser] = useState();
  const [isLogged, setIsLogged] = useState(false);
  const GoogleAuth = gapi?.auth2?.getAuthInstance();
  const [sasDetails, setSasDetails] = useState({});

  const render = () => {
    gapi.signin2.render('myGoogleButton', {
      scope: 'profile email',
      width: 250,
      height: 50,
      longtitle: true,
      theme: 'light',
    });
  };

  useEffect(() => {
    gapi.load('auth2', () => {
      gapi.auth2
        .init({
          client_id: '169427736083-2oc3dtedu9lli1mkcg2p217ll9b0d9vf.apps.googleusercontent.com',
          scope:
            'openid https://www.googleapis.com/auth/youtube.upload https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/youtube.force-ssl',
        })
        .then(() => {
          render();
          const instance = gapi?.auth2?.getAuthInstance();
          if (instance?.isSignedIn?.get()) {
            setUser(instance.currentUser.get());
          }
          setIsLogged(instance?.isSignedIn?.get());
        });
    });
  }, []);

  const getSasURL = async () => {
    const { access_token } = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse();
    if (access_token) {
      const response = await postToGetSasUrl(access_token);
      setSasDetails(response);
    }
  };

  useEffect(() => {
    if (user && !sasDetails?.token) {
      getSasURL();
    }
  }, [user, sasDetails]);

  const listener = (googleUser) => {
    if (gapi?.auth2?.getAuthInstance()?.isSignedIn?.get()) {
      setUser(googleUser);
    }

    setIsLogged(GoogleAuth?.isSignedIn?.get());
  };

  useEffect(() => {
    if (GoogleAuth) {
      GoogleAuth.currentUser.listen(listener);
    }
  }, [GoogleAuth]);

  const Details = () => {
    const sair = () => {
      GoogleAuth?.signOut();
      setIsLogged(false);
      setUser('');
      document.location.reload(true);
    };

    const removeLogout = () => {
      GoogleAuth?.disconnect();
      setIsLogged(false);
      setUser('');
      document.location.reload(true);
    };
    const basicData = user?.getBasicProfile();

    return (
      <S.LoggedContainer>
        <div className="header">
          <img src={basicData ? basicData?.getImageUrl() : ''} alt={'user photo'} />
          <h1>{basicData ? basicData?.getGivenName() : ''}</h1>
          <span>
            <button onClick={sair}>{t('exit')}</button>
            <button onClick={removeLogout}>{t('exit_and_remove_permissions')}</button>
          </span>
        </div>

        <S.URLContainer>
          <FilesUpload sasDetails={sasDetails} />
        </S.URLContainer>
      </S.LoggedContainer>
    );
  };

  return (
    <S.Container>
      <S.LogoContainer>
        <img src={new_logo_bornlogic} />
      </S.LogoContainer>

      {isLogged ? <Details /> : <div id="myGoogleButton"></div>}
      {isLogged && (
        <div style={{ maxWidth: '500px', margin: '0 auto', padding: '20px' }}>
          {t('credential_1')}{' '}
          <a href="https://security.google.com/settings/" target="_blank">
            {t('here')}
          </a>{' '}
          {t('credential_2')}
        </div>
      )}
    </S.Container>
  );
};

export default GoogleLogin;
