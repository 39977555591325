const DataInfoPT = {
  DataInfo: {
    TITLE: 'Índice de classificação de conteúdos',
    GENERAL_CLASSIFICATION: 'Classificação geral',
    GENERAL_CLASSIFICATION_SUB:
      'Veja como conseguimos identificar palavras-chave em seus conteúdos',
    PIE_GRAPH_DESCRIPTION:
      'do total dos conteúdos criados pelos seus gerentes digitais foram classificados',
    ADS: 'Anúncios',
    ORGANIC_POST: 'Posts orgânicos',
    GENERAL_DETAILS: 'O que é a classificação de conteúdo?',
    GENERAL_DETAILS_SUB:
      'A classificação de conteúdo é a possibilidade de categorizar seus conteúdos, entender através das palavas-chave localizadas nas legendas e hashtags a qual produto, categoria ou marca aquele anúncio diz respeito.',
    ADVISER_TITLE: 'Como melhorar essa classificação?',
    ADVISER_SUB: 'A padronização das hashtags é a chave de uma classificação assertiva.',
    ADVISER_DETAILS_TITLE: 'Preenchimento correto de conteúdo',
    ADVISER_DETAILS_SUB:
      'A principal e mais rápida maneira de melhorar nossa capacidade de predição, é com a ajuda de seus vendedores. Oriente seus gerentes digitais a preencher informações relevantes no campo de hashtag dos conteúdos.',
    ADVISER_TAGS_MSG:
      'Exemplos de hashtags que trazem informações de produtos, categorias e marcas úteis para os insights:',
    FAQ_TITLE: 'Você pode estar se perguntando:',
    FAQ_SEE_ALL_BTN: 'Ver todas as perguntas',
  },
};

const DataInfoEN = {
  DataInfo: {
    TITLE: 'Content rating index',
    GENERAL_CLASSIFICATION: 'General classification',
    GENERAL_CLASSIFICATION_SUB: 'See how we were able to identify keywords in your content',
    PIE_GRAPH_DESCRIPTION: 'of the total contents created by your digital managers were classified',
    ADS_CREATED: 'Ads boosted',
    ORGANIC_POST: 'Organic Posts',
    GENERAL_DETAILS: 'What is content rating?',
    GENERAL_DETAILS_SUB:
      'The classification of content is the possibility of categorizing your content, understanding through the keywords located in the captions and hashtags which product, category or brand that ad refers to.',
    ADVISER_TITLE: 'How to improve this rating?',
    ADVISER_SUB: 'The standardization of hashtags is the key to assertive classification.',
    ADVISER_DETAILS_TITLE: 'Correct Content Fill',
    ADVISER_DETAILS_SUB:
      'The main and fastest way to improve our prediction ability is with the help of your salespeople. Guide your digital managers to fill in relevant information in the content hashtag field.',
    ADVISER_TAGS_MSG:
      'Examples of hashtags that provide useful product, category and brand information for insights:',
    FAQ_TITLE: 'You might be wondering:',
    FAQ_SEE_ALL_BTN: 'See all questions',
  },
};

const DataInfoES = {
  DataInfo: {
    TITLE: 'Índice de calificación de contenido',
    GENERAL_CLASSIFICATION: 'Clasificación general',
    GENERAL_CLASSIFICATION_SUB: 'Vea cómo pudimos identificar palabras clave en su contenido',
    PIE_GRAPH_DESCRIPTION:
      'del total de contenidos creados por sus gestores digitales fueron clasificados',
    ADS_CREATED: 'Anuncios potenciados',
    ORGANIC_POST: 'Publicaciones orgánicas',
    GENERAL_DETAILS: '¿Qué es la calificación de contenido?',
    GENERAL_DETAILS_SUB:
      'La clasificación de contenido es la posibilidad de categorizar tu contenido, entendiendo a través de las palabras clave ubicadas en los subtítulos y hashtags a qué producto, categoría o marca se refiere ese anuncio.',
    ADVISER_TITLE: '¿Cómo mejorar esta calificación?',
    ADVISER_SUB: 'La estandarización de los hashtags es la clave para una clasificación asertiva.',
    ADVISER_DETAILS_TITLE: 'Relleno de contenido correcto',
    ADVISER_DETAILS_SUB:
      'La forma principal y más rápida de mejorar nuestra capacidad de predicción es con la ayuda de sus vendedores. Guíe a sus gerentes digitales para que completen la información relevante en el campo de hashtag de contenido.',
    ADVISER_TAGS_MSG:
      'Ejemplos de hashtags que brindan información útil sobre productos, categorías y marcas para obtener información:',
    FAQ_TITLE: 'Quizás te estés preguntando:',
    FAQ_SEE_ALL_BTN: 'Ver todas las preguntas',
  },
};

export { DataInfoEN, DataInfoPT, DataInfoES };
