// Types
import {
  HEADER_AND_FOOTER_INFO, CHART, AUDIENCE, TABLE,
} from './types';

const INITIAL_STATE = {
  headerAndFooterInfo: {
    data: {},
    isLoading: false,
  },
  chart: {
    data: {},
    isLoading: false,
  },
  audience: {
    data: {},
    isLoading: false,
  },
  table: {
    data: {},
    isLoading: false,
  },
};

const videoViewReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HEADER_AND_FOOTER_INFO.REQUEST:
      return {
        ...state,
        headerAndFooterInfo: {
          ...state.headerAndFooterInfo,
          isLoading: true,
        },
      };
    case HEADER_AND_FOOTER_INFO.SUCCESS:
      return {
        ...state,
        headerAndFooterInfo: {
          data: action.data,
          isLoading: false,
        },
      };
    case HEADER_AND_FOOTER_INFO.FAILURE:
      return {
        ...state,
        headerAndFooterInfo: {
          data: {},
          isLoading: false,
        },
      };
    case CHART.REQUEST:
      return {
        ...state,
        chart: {
          ...state.chart,
          isLoading: true,
        },
      };
    case CHART.SUCCESS:
      return {
        ...state,
        chart: {
          data: action.data,
          isLoading: false,
        },
      };
    case CHART.FAILURE:
      return {
        ...state,
        chart: {
          data: {},
          isLoading: false,
        },
      };
    case AUDIENCE.REQUEST:
      return {
        ...state,
        audience: {
          ...state.audience,
          isLoading: true,
        },
      };
    case AUDIENCE.SUCCESS:
      return {
        ...state,
        audience: {
          data: action.data,
          isLoading: false,
        },
      };
    case AUDIENCE.FAILURE:
      return {
        ...state,
        audience: {
          data: {},
          isLoading: false,
        },
      };
    case TABLE.REQUEST:
      return {
        ...state,
        table: {
          ...state.table,
          isLoading: true,
        },
      };
    case TABLE.SUCCESS:
      return {
        ...state,
        table: {
          data: action.data,
          isLoading: false,
        },
      };
    case TABLE.FAILURE:
      return {
        ...state,
        table: {
          data: {},
          isLoading: false,
        },
      };

    default:
      return state;
  }
};

export default videoViewReducer;
