import {
  ActionsFeedCardModalEN,
  ActionsFeedCardModalES,
  ActionsFeedCardModalPT,
} from '../Components/ActionsFeedCardModal/translate';
import {
  CardImageAndActionsEN,
  CardImageAndActionsPT,
  CardImageAndActionsES,
} from '../Components/CardImageAndActions/translate';
import { CardInfosEN, CardInfosPT, CardInfosES } from '../Components/CardInfos/translate';
import {
  MediaFormatAndStatusEN,
  MediaFormatAndStatusPT,
  MediaFormatAndStatusES,
} from '../Components/MediaFormatAndStatus/translate';

const FeedCardEN = {
  ...MediaFormatAndStatusEN,
  ...CardImageAndActionsEN,
  ...CardInfosEN,
  ...ActionsFeedCardModalEN,
};
const FeedCardPT = {
  ...MediaFormatAndStatusPT,
  ...CardImageAndActionsPT,
  ...CardInfosPT,
  ...ActionsFeedCardModalPT,
};
const FeedCardES = {
  ...MediaFormatAndStatusES,
  ...CardImageAndActionsES,
  ...CardInfosES,
  ...ActionsFeedCardModalES,
};

export { FeedCardEN, FeedCardPT, FeedCardES };
