import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => css`
    gap: ${theme.spacing.px4};
    width: ${theme.spacing.full};
  `}

  input:checked + span {
    background-color: ${({ theme, hasError }) => hasError && theme.colors.errorRed};
  }
`;

export const TooltipSpacing = styled.div`
  ${({ theme }) => css`
    margin-top: -${theme.spacing.px4};
    margin-left: ${theme.spacing.px3};
  `}
`;
