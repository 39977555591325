import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { LISTING_PAGE_COUNT } from '@redux/generic/types';
import { getBusinessID } from '@utils/auth';
import MenuLink from '../MenuLink';
import { CompanyService } from '../../../../services';

function getStoreValues(state) {
  return {
    authenticationReducer: state.authenticationReducer,
  };
}

function SettingsSidebar({}) {
  const dispatch = useDispatch();
  const { code } = useParams();
  const {
    authenticationReducer: {
      dataUser: { role, email },
    },
  } = useSelector(getStoreValues);

  const [company, setCompany] = useState({});

  const loadCompany = async () => {
    dispatch({
      type: LISTING_PAGE_COUNT.REQUEST,
      version: '1.0',
      tenantId: getBusinessID(),
      unit: code,
    });

    const response = await CompanyService.getCompany();
    const { is_success: isSuccess, payload } = response.data;

    if (isSuccess) {
      setCompany(payload);
    }
  };

  const initializeCompanyState = useCallback(async () => {
    await loadCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initializeCompanyState();
  }, [initializeCompanyState]);

  function getLinks() {
    const links = [];
    const isAdmin = role === 'admin';
    const isBornlogic = email?.includes('@bornlogic');

    links.push(
      {
        name: 'UserTable',
        conditional: '',
        icon: ['fal', 'user'],
        translator: 'USER_TABLE',
        disabled: false,
      },
      {
        name: 'Units',
        conditional: '',
        icon: ['fal', 'store'],
        translator: 'UNITS',
        disabled: false,
        subMenus: [
          {
            name: 'Units',
            tabMenus: ['Groups'],
            conditional: '',
            translator: 'LIST_ALL',
            disabled: false,
          },
        ],
      }
    );

    if (isAdmin || isBornlogic) {
      links.push(
        {
          name: 'users_management',
          conditional: '',
          icon: ['fal', 'users-cog'],
          translator: 'USERS_MANAGEMENT',
          disabled: true,
        },
        {
          name: 'Setup',
          conditional: '',
          icon: ['fal', 'cogs'],
          translator: 'SETUP',
          disabled: true,
        }
      );
      if (isBornlogic) {
        links.push(
          {
            name: 'Company',
            conditional: '',
            icon: ['fal', 'building'],
            translator: 'COMPANY',
            disabled: false,
          },
          {
            name: 'report_settings',
            conditional: '',
            icon: ['fal', 'cog'],
            translator: 'REPORT_SETTINGS',
            disabled: false,
          }
        );
      }

      if (company?.status === 'poc') {
        links.push({
          name: 'poc',
          conditional: '',
          icon: ['fal', 'globe'],
          translator: 'WEEKLY_REPORT',
          disabled: false,
        });
      }
    }

    return links;
  }

  return <MenuLink links={getLinks()} page="Settings" />;
}

export default SettingsSidebar;
