export const EditUserGroupModalEN = {
  EditUserGroupModal: {
    EDIT_GROUP: 'Edit group',
    GROUP: 'Group',
    SAVE: 'Save',
    CANCEL: 'Cancel',
    SELECT_GROUP: 'Select a group',
    EDIT_USER_GROUP_SUCCESS_MESSAGE: 'Group changed successfully!',
    EDIT_USER_GROUP_ERROR_MESSAGE: 'Failed to change user group!',
  },
};

export const EditUserGroupModalES = {
  EditUserGroupModal: {
    EDIT_GROUP: 'Editar grupo',
    GROUP: 'Grupo',
    SAVE: 'Salvar',
    SELECT_GROUP: 'Seleccione un grupo',
    EDIT_USER_GROUP_SUCCESS_MESSAGE: '¡Grupo cambiado con éxito!',
    EDIT_USER_GROUP_ERROR_MESSAGE: '¡No se pudo cambiar el grupo del usuario!',
  },
};

export const EditUserGroupModalPT = {
  EditUserGroupModal: {
    EDIT_GROUP: 'Editar grupo',
    GROUP: 'Grupo',
    SAVE: 'Salvar',
    CANCEL: 'Cancelar',
    SELECT_GROUP: 'Selecione um grupo',
    EDIT_USER_GROUP_SUCCESS_MESSAGE: 'Grupo alterado com sucesso!',
    EDIT_USER_GROUP_ERROR_MESSAGE: 'Falha ao alterar a grupo do usuário!',
  },
};
