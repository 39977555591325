import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const HeaderWrapper = styled.header`
  ${({ theme, isTradeView, shouldFixPosition }) => css`
    width: ${theme.spacing.full};
    background: ${theme.colors.white};
    height: ${theme.spacing.px80};
    z-index: ${isTradeView ? 2 : 0};

    ${shouldFixPosition &&
    css`
      position: fixed;
    `}
  `}
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderInitial = styled.div`
  flex-basis: 75%;
  display: flex;
`;

export const LogoWrapper = styled(Link)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    margin: ${theme.spacing.none};
    padding: ${theme.spacing.none};
    height: ${theme.spacing.px80};
  `}
`;

export const HeaderFinal = styled.div`
  flex-basis: 25%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.xl}) {
    display: none;
  }
`;

export const HeaderFinalMobile = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.xl}) {
    display: none;
  }
`;

export const MenuWrapper = styled.div`
  ${({ theme }) => css`
    @media screen and (max-width: ${theme.breakpoint.xl}) {
      display: none;
    }
    height: ${theme.spacing.px80};
  `}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  ${({ theme, avatarButton }) => css`
    ${avatarButton &&
    css`
      border-radius: ${theme.rounded.full};
      overflow: hidden;
    `}
    @media screen and (min-width: ${theme.breakpoint.xl}) {
      margin-right: ${theme.spacing.px20};
      ${avatarButton ? `margin-right: ${theme.spacing.none};` : ''}
    }
  `}
`;

export const ToggleMenu = styled.button`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;

  ${({ theme }) => css`
    padding: ${theme.spacing.none};
    & > svg {
      font-size: ${theme.fontSize.px20};
    }
  `}
`;

export const MenuMobile = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;

  ${({ theme, open }) => css`
    height: ${theme.spacing.full};
    z-index: ${open ? '3000' : '-1'};
  `}
`;

export const ButtonSearch = styled.button`
  display: flex;
  align-items: center;
  border: none;
  padding: 0;
  line-height: 1;
  background: none;
  cursor: pointer;
  outline: none;

  ${({ theme }) => css`
    p {
      margin-right: ${theme.spacing.px40};
      color: ${theme.colors.blueGrey3};
    }
    svg {
      margin-right: ${theme.spacing.px16};
      font-size: ${theme.fontSize.px16};
      color: ${theme.colors.blueGrey3};
    }
  `}
`;

export const LogoImage = styled.img`
  height: 43px;
`;

export const LogoTag = styled.div`
  position: absolute;
  top: 57px;

  ${({ theme }) => css`
    left: ${theme.spacing.px50};
    font-size: ${theme.fontSize.px8};
  `}
`;
