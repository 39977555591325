// Api
import { ApiTenant } from '@utils/api';

class RuleService {
  static getTargetingRestriction(version, adAccountId) {
    return ApiTenant.get(
      `/rule/${version}/Facebook/TargetingRestriction/ByAdAccount/${adAccountId}`
    );
  }

  static setTargetingRestriction(version, adAccountId, body) {
    return ApiTenant.post(
      `/rule/${version}/Facebook/TargetingRestriction/ByAdAccount/${adAccountId}/Targeting`,
      body
    );
  }
}

export default RuleService;
