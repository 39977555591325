// Api
import { ApiTenant } from '@utils/api';

class ActionsService {
  static postCoverProfileImage(version, code, data) {
    return ApiTenant.post(`/schema/${version}/${code}/Facebook/Fanpage/Picture`, data);
  }

  static postReplicateAccounts(version, group_id, data) {
    return ApiTenant.post(`/schema/${version}/${group_id}/facebook/ads/duplicate`, data);
  }

  static postReplicateFanpages(version, group_id, data) {
    return ApiTenant.post(`/schema/${version}/${group_id}/facebook/fanpageposts/duplicate`, data);
  }

  static postPromoteFanpages(version, group_id, data) {
    return ApiTenant.post(`/schema/${version}/${group_id}/facebook/fanpageposts/promote`, data);
  }

  static promoteStories(version, group_id, data) {
    return ApiTenant.post(`/schema/${version}/${group_id}/instagram/stories/promote`, data);
  }

  static promoteInstagram(version, group_id, data) {
    return ApiTenant.post(`/schema/${version}/${group_id}/instagram/posts/promote`, data);
  }

  static changeGroupInBatch(version, source_id, data) {
    return ApiTenant.post(`/schema/${version}/${source_id}/relationships/batch/member`, data);
  }

  static getPixels(source_id) {
    return ApiTenant.get(`/schema/1.1/${source_id}/facebook/info?fields=pixels`);
  }

  static getForms(fan_page_id) {
    return ApiTenant.get(`/formlead/1.0/frompageid/${fan_page_id}`);
  }
}

export default ActionsService;
