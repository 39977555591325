// Api
import { ApiTenant } from '@utils/api';

import { getTimezone } from '@utils/helpers';

class FeedService {
  static getAds(version, preset, ids, fields) {
    return ApiTenant.get(
      `/insights/${version}/ad?${
        preset ? `${preset}&` : ''
      }timezone_offset=${getTimezone()}&fields=${fields}&ad_ids=[${ids}]`
    );
  }

  static getPosts(version, ids) {
    return ApiTenant.get(`/insights/${version}/pagepost?page_post_ids=[${ids}]`);
  }

  static getFeeds(code, feedType, orderType, next, limit) {
    return ApiTenant.get(
      `/schema/1.1/${code}/facebook/${feedType}${orderType}&fixed_date=true&fields=attachment_urls,name,page_post_id,creative_type,image_urls,video_urls,link_url,created_time,fan_page,texts,start_time,end_time,status,lifetime_budget,daily_budget&timezone_offset=-3${
        next ? `&page_hash=${next}` : ''
      }${limit ? `&limit=${limit}` : ''}`
    );
  }

  static getAdReach(data) {
    return ApiTenant.post('/es-api/insights/fb/ad/reach/lifetime', data);
  }

  static getItemPreviews(version, id, fields, feedtype, assetIds) {
    let urlType = '';
    if (feedtype === 'AD') {
      urlType = 'ads';
    } else {
      urlType = 'fanpageposts';
    }

    return ApiTenant.get(
      `/schema/${version}/${id}/facebook/${urlType}?fields=${fields}&asset_ids=['${assetIds}']`
    );
  }

  // static getInstagramStories(data) {
  //     return ApiTenant.post(`/es-api/insights/ig/media/stories`, data);
  // }

  // static getInstagramPosts(data) {
  //     return ApiTenant.post(`/es-api/insights/ig/media/posts`, data);
  // }
}

export default FeedService;
