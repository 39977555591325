const UnitsEN = {
  Units: {
    USER_OPTION: 'Users',
    UNITS_OPTION: 'Units',
    ADD_UNIT: 'Add unit',
    ADD: 'Add',
    CANCEL: 'Cancel',
    ADD_ONE_MORE: 'Add one more',
    FEEDBACK_ERROR: 'Error adding unit, try again shortly.',
    FEEDBACK_SUCCESS: 'Unit added! Follow the status updates.',
    GENERAL_CONFIG: 'General configuration',
    CREDIT_LINE: 'What is the credit line?',
    SHARED: 'Shared',
    PRIVATE: 'Private',
    BUDGET: 'What monthly budget will this unit have?',
    BUDGET_PLACEHOLDER: '$ 0',
    AD_RADIUS: 'What is the radius of the ads?',
    AD_RADIUS_PLACEHOLDER: '0 km',
    AUTO_APPROVE: 'Will this unit have automatic approval?',
    NO: 'No',
    YES: 'Yes',
    USER_EMAIL_LABEL: 'User email*',
    USER_EMAIL_PLACEHOLDER: 'Enter email to send invitation',
    UNIT_CONFIG: 'Unit settings',
    UNIT_NAME: 'Unit name',
    UNIT_NAME_PLACEHOLDER: 'Enter unit name',
    FACEBOOK_ID: 'Facebook ID',
    FACEBOOK_ID_PLACEHOLDER: 'Enter Facebook page ID',
    ACCOUNT_ID_LABEL: 'Ad account ID',
    ACCOUNT_ID_PLACEHOLDER: 'Enter ad account ID',
    GROUP: 'Group',
    SELECT_GROUP: 'Select a group',
    PENDING: 'Pending',
    PENDING_PLURAL: 'Pending',
    RUNNING: 'In progress',
    FINISHED: 'Active',
    INACTIVE: 'Inactive',
    INACTIVE_PLURAL: 'Inactive',
    ERROR: 'With error',
    CHANGE_IMAGE: 'Change profile and cover',
    EDIT: 'Edit',
    REPLICATE_AUDIENCE: 'Replicate audience',
    REPLICATE_SUBTITLE1: 'The unit you have chosen has',
    REPLICATE_SUBTITLE2:
      'audiences available. Select the audience and which units you want to replicate to.',
    INACTIVATE_UNIT: 'Inactivate unit',
    ACTIVATE_UNIT: 'Activate unit',
    ACTIVATE_UNIT_TOAST_SUCCESS: 'Unit activated successfully.',
    ACTIVATE_UNIT_TOAST_ERROR: 'Failed to activate unit: {{errorMessage}}',
    TRY_AGAIN: 'Try again',
    EDIT_AUDIENCE: 'Edit audience',
    UNLIST_AUDIENCE: 'Unlist audience',
    UNLIST_AUDIENCE_SUCCESS: 'Audience unlisted successfully.',
    UNLIST_AUDIENCE_ERROR: 'Error trying to unlist audience.',
    UNLIST_AUDIENCE_SHOULD_DELETE_CLONES_LABEL:
      'Unlist duplicates of this audience in other units.',
    NO_SAVED_AUDIENCES: 'This unit has no saved audience.',
    INACTIVATE: 'Inactivate',
    ACTIVES: 'Actives',
    CLEAR_FILTER: 'Clear selection',
    MISSING_IMAGE: 'Please insert one image!',
    AVAILABLE_AUDIENCE: 'Available audience',
    SELECT_AUDIENCE: 'Select audience',
    CHANGE_AUDIENCE_SUBTITLE: 'Choose what audience will be default to the ads of this unit.',
    CHANGE_TARGETING_FEEDBACK_ERROR:
      'Error trying to change the unit targeting,  try again shortly.',
    CHANGE_TARGETING_FEEDBACK_SUCCESS: 'Unit audience changed!',
    SINGLE_SELECTED_UNIT: 'selected unit',
    SOME_SELECTED_UNIT: 'selected units',
    C_NAME: 'Name',
    C_GROUP: 'Group',
    C_STATUS: 'Status',
    PUBLIC: 'Public',
    MONETARY_CONFIG_ACCOUNT: 'How you want to link your ad accounts',
    CHOSE_CREDIT_LINE: 'Choose credit line',
    TICKET: 'Ticket',
    MONTHLY_BUDGET: 'Monthly budget',
    PAYMENT_METHOD: 'What will be the payment method?',
    EXISTING_ACCOUNT: 'Insert existing account',
    CREATE_ACCOUNT: 'Register new account',
    INVITE_USER: 'Invite users to the business manager.',
    INVALID_PAGE_ID: 'Error with page ID, check the number and try again.',
    INVALID_AD_ACCOUNT_ID: 'Error in the ad account, check the number and try again.',
    CONTINUE: 'Continue',
    REPLICATE_AUDIENCE_SUCCESS: 'Audiences successfully replicated!',
    REPLICATE_AUDIENCE_ERROR: 'Failed to replicate audiences!',
    REPLICATE_AUDIENCE_BYPASS_INVALID_AUDIENCE_PROMPT:
      "This is a unit's custom audience. Are you sure you want to replicate to other units? The functionality is not warranted in this case.",
    INVALID_GROUPS_SELECION: 'Select at least one group!',
    INVALID_PUBLIC: 'Select a public!',
    CONFIRM: 'Confirm',
    REPLICATE_LEADS: 'Replicate registration form',
    FORM: 'Registration form',
    SELECT_FORM: 'Select a form',
    CREDIT_CARD: 'Credit card',
    MODAL_INACTIVATE_UNIT: 'Do you really want to disable {{nameUnit}}?',
    INFORMATION_BUDGET_MODAL:
      'Alert: if you have an allocated balance, withdraw before inactivation.',
    TOAST_SUCESS: 'Status changed successfully',
    EMPTY_UNITS: 'No units to be shown.',

    // Tela de configuração avançada
    ADVANCED_CONFIG_LOCALIZATION_FORM_TITLE_1:
      'Define here the region where your audience will be impacted by your ads.',
    ADVANCED_CONFIG_LOCALIZATION_FORM_TITLE_2:
      'You can choose between latitude and longitude or a list of zip codes.',
    ADVANCED_CONFIG_LOCALIZATION_FORM_LATITUDE_SWITCH_LABEL: 'Latitude and longitude',
    ADVANCED_CONFIG_LOCALIZATION_FORM_LATITUDE_SWITCH_DESCRIPTION:
      'Set latitude and longitude using only positive or negative numbers.',
    ADVANCED_CONFIG_LOCALIZATION_FORM_LATITUDE_LABEL: 'Latitude',
    ADVANCED_CONFIG_LOCALIZATION_FORM_LONGITUDE_LABEL: 'Longitude',
    ADVANCED_CONFIG_LOCALIZATION_FORM_RADIUS_TITLE: 'Radius',
    ADVANCED_CONFIG_LOCALIZATION_FORM_RADIUS_DESCRIPTION:
      'Set the coverage area for your ads, which will be represented by Km.',
    ADVANCED_CONFIG_LOCALIZATION_FORM_CITIES_TITLE: 'Cities',
    ADVANCED_CONFIG_LOCALIZATION_FORM_CITIES_DESCRIPTION:
      'Target your ads to people in specific cities.',
    ADVANCED_CONFIG_LOCALIZATION_FORM_CITIES_LABEL: 'Cities',
    ADVANCED_CONFIG_LOCALIZATION_FORM_CITIES_PLACEHOLDER: 'Search for a city...',
    ADVANCED_CONFIG_LOCALIZATION_FORM_CITIES_EMPTY_RESULTS: 'No cities found.',
    ADVANCED_CONFIG_LOCALIZATION_FORM_CITIES_EMPTY_QUERY: 'Start typing the city name.',
    ADVANCED_CONFIG_LOCALIZATION_FORM_ADRESS_LABEL: 'Zip code',
    ADVANCED_CONFIG_LOCALIZATION_FORM_ADRESS_DESCRIPTION:
      'Enter in the field below only the 5 initial numbers of the zip code, separating by comma, if you enter more than one.',
    ADVANCED_CONFIG_LOCALIZATION_FORM_ADRESS_PLACEHOLDER: 'Enter the zip code',
    ADVANCED_CONFIG_TITLE_MODAL: 'Advanced Settings',
    AUTOMATIC_PLACEMENTS: 'Automatic placements',
    AUTOMATIC_PLACEMENTS_HELP_TOOLTIP:
      '{{platform}} will try to make the most of your budget and increase the exposure of your ads by allowing them to be seen on all available placements for your settings.',
    FEED_HELP_TOOLTIP:
      'Feed is an ever-updating list of stories from your homepage. In it updates contain statuses, photos, videos, links, app activity and likes from people, pages and groups you follow on Facebook.',
    RIGHT_HAND_COLUMN_HELP_TOOLTIP:
      "Facebook's right column ads are in desktop format only. These ads typically appear in the right column of Facebook Pages, but may appear in other areas of the site.",
    INSTANT_ARTICLE_HELP_TOOLTIP:
      'An Instant Article is an HTML document that is quickly uploaded to Facebook. Instant Articles provide a faster, Facebook-native way to deliver content that publishers have already produced on their sites.',
    MARKETPLACE_HELP_TOOLTIP:
      'Marketplace is a destination on Facebook where people can find, buy and sell items. People can browse ads, search for items for sale in their region, or find products available to ship.',
    STORY_HELP_TOOLTIP:
      'Stories is a great way to connect with your audience and share content between video posts scheduled with photos and short videos. All content posted on Stories only lasts 24 hours.',
    SUGGESTED_VIDEO_HELP_TOOLTIP:
      'Suggested video are those videos that appear as you go down. You access a video and from there, go down to continue watching more videos.',
    INSTREAM_VIDEO_HELP_TOOLTIP:
      'Facebook In-Stream Video allows you to serve video ads to people who watch Facebook videos from well-known digital media content publishers and creators.',
    LATITUDE_LOGINTUDE_HELP_TOOLTIP:
      'Geographic coordinates for the exact location of points on the surface of planet Earth.',
    LATITUDE_HELP_TOOLTIP:
      'Open Google Maps and enter the desired address. In the URL, copy the sequence of numbers between /@ for the comma. This is LATITUDE.',
    LONGITUDE_HELP_TOOLTIP:
      "Open Google Maps and enter the desired address. In the URL, copy the sequence of numbers between the commas. That's LONGITUDE.",
    RADIUS_HELP_TOOLTIP:
      'Your ads will impact users within the given radius / Number in kilometers from the page address.',
    ADRESS_HELP_TOOLTIP: 'Adjust the geolocation of your ads at the zip code level.',

    ENGAGEMENT: 'Engagement',
    MESSAGES: 'Messages',
    LINK_CLICK: 'Link clicks',
    CONVERSION: 'Conversion',
    LEADS: 'Leads',
    GOALS_TITLE: 'Goals',
    CTA_TITLE: 'CTA',
    KNOW_MORE: 'Know more',
    BUY_NOW: 'Buy now',
    WHATSAPP: 'WhatsApp',
    REGISTER: 'Register',
    BUY: 'Buy',
    CONTACT_US: 'Contact us',
    APPLY: 'Apply',
    GOALS_AND_CTA_TITLE: 'Define the goals and call to action',
    GOALS_AND_CTA_ERROR: "An error occurred when saving goals and CTA's",
    DISABLED_CTA_WARNING_TEXT:
      'A configuration error was found on the Facebook page that prevents the use of this CTA.',
    DISABLED_WHATSAPP_CTA_WARNING_TEXT:
      'WhatsApp is not configured correctly on the Facebook page.',

    ERROR_LOG_TITLE: 'Ops! Something went wrong.',
    ERROR_LOG_DESCRIPTION: 'The pages presented the following errors:',
    ALREADY_CONTAINS_AUDIENCE: 'Already contains this audience.',
    ERROR_LOG_PAGE_ID: 'The page of id',
  },
};

const UnitsPT = {
  Units: {
    USER_OPTION: 'Usuários',
    UNITS_OPTION: 'Unidades',
    ADD_UNIT: 'Incluir unidade',
    ADD: 'Adicionar',
    CANCEL: 'Cancelar',
    ADD_ONE_MORE: 'Adicionar mais uma',
    FEEDBACK_ERROR: 'Erro ao adicionar unidade, tente novamente em instantes.',
    FEEDBACK_SUCCESS: 'Unidade adicionada! Fique de olho nos status.',
    GENERAL_CONFIG: 'Configuração geral',
    CREDIT_LINE: 'Qual a linha de crédito?',
    SHARED: 'Compartilhada',
    PRIVATE: 'Privada',
    BUDGET: 'Qual orçamento mensal essa unidade terá?',
    BUDGET_PLACEHOLDER: 'R$ 0',
    AD_RADIUS: 'Qual raio de atuação dos anúncios?',
    AD_RADIUS_PLACEHOLDER: '0 km',
    AUTO_APPROVE: 'Essa unidade terá aprovação automática?',
    NO: 'Não',
    YES: 'Sim',
    USER_EMAIL_LABEL: 'Email do usuário*',
    USER_EMAIL_PLACEHOLDER: 'Digite o email para enviar o convite',
    UNIT_CONFIG: 'Configuração da unidade',
    UNIT_NAME: 'Nome da unidade',
    UNIT_NAME_PLACEHOLDER: 'Digite o nome da unidade',
    FACEBOOK_ID: 'ID da página',
    FACEBOOK_ID_PLACEHOLDER: 'Digite o ID da página',
    ACCOUNT_ID_LABEL: 'ID da conta de anúncio ',
    ACCOUNT_ID_PLACEHOLDER: 'Digite o ID da conta',
    GROUP: 'Grupo',
    SELECT_GROUP: 'Selecione um grupo',
    PENDING: 'Pendente',
    PENDING_PLURAL: 'Pedentes',
    RUNNING: 'Em andamento',
    FINISHED: 'Ativa',
    INACTIVE: 'Inativa',
    INACTIVE_PLURAL: 'Inativas',
    ERROR: 'Com erro',
    CHANGE_IMAGE: 'Mudar Perfil e Capa',
    EDIT: 'Editar',
    REPLICATE_AUDIENCE: 'Replicar público',
    REPLICATE_SUBTITLE1: 'A unidade que você escolheu tem',
    REPLICATE_SUBTITLE2:
      'públicos disponíveis. Selecione o público e para quais unidades deseja replicar.',
    INACTIVATE_UNIT: 'Inativar unidade',
    ACTIVATE_UNIT: 'Ativar unidade',
    ACTIVATE_UNIT_TOAST_SUCCESS: 'Unidade ativada com sucesso.',
    ACTIVATE_UNIT_TOAST_ERROR: 'Erro ao ativar unidade: {{errorMessage}}.',
    TRY_AGAIN: 'Tentar novamente',
    EDIT_AUDIENCE: 'Alterar público',
    UNLIST_AUDIENCE: 'Desvincular público',
    UNLIST_AUDIENCE_SUCCESS: 'Público desvinculado com sucesso.',
    UNLIST_AUDIENCE_ERROR: 'Erro ao desvincular público.',
    UNLIST_AUDIENCE_SHOULD_DELETE_CLONES_LABEL:
      'Desvincular de outras unidades os públicos duplicados a partir deste.',
    NO_SAVED_AUDIENCES: 'Nenhum público vinculado a essa unidade.',
    INACTIVATE: 'Inativar',
    ACTIVES: 'Ativas',
    CLEAR_FILTER: 'Limpar seleção',
    MISSING_IMAGE: 'Por favor insira uma imagem!',
    CHANGE: 'Alterar',
    AVAILABLE_AUDIENCE: 'Públicos disponíveis',
    SELECT_AUDIENCE: 'Selecione o público',
    CHANGE_AUDIENCE_SUBTITLE:
      'Escolha qual será o público automático dos anúncios nestas unidades.',
    CHANGE_TARGETING_FEEDBACK_ERROR:
      'Erro ao alterar público da unidade, tente novamente em instantes.',
    CHANGE_TARGETING_FEEDBACK_SUCCESS: 'Público da unidade alterado!',
    SINGLE_SELECTED_UNIT: 'unidade selecionada',
    SOME_SELECTED_UNIT: 'unidades selecionadas',
    C_NAME: 'Nome',
    C_GROUP: 'Grupo',
    C_STATUS: 'Status',
    PUBLIC: 'Públicos',
    MONETARY_CONFIG_ACCOUNT: 'Como deseja vincular as contas de anúncio?',
    CHOSE_CREDIT_LINE: 'Escolher linha de crédito',
    TICKET: 'Boleto',
    MONTHLY_BUDGET: 'Orçamento mensal',
    PAYMENT_METHOD: 'Qual será a forma de pagamento?',
    EXISTING_ACCOUNT: 'Inserir conta já existente',
    CREATE_ACCOUNT: 'Criar nova conta',
    INVITE_USER: 'Convidar usuários para o gerenciador de negócios.',
    INVALID_PAGE_ID: 'Erro no ID da página, confira o número e tente novamente.',
    INVALID_AD_ACCOUNT_ID: 'Erro na conta de anúncios, confira o número e tente novamente.',
    CONTINUE: 'Continuar',
    REPLICATE_AUDIENCE_SUCCESS: 'Públicos replicados com sucesso!',
    REPLICATE_AUDIENCE_ERROR: 'Erro ao replicar público!',
    REPLICATE_AUDIENCE_BYPASS_INVALID_AUDIENCE_PROMPT:
      'Este é um público customizado da unidade. Tem certeza de que deseja replicar para outra(s) unidade(s)? A funcionalidade não é garantida nesse caso.',
    INVALID_GROUPS_SELECION: 'Selecione pelo menos um grupo!',
    INVALID_PUBLIC: 'Selecione um público!',
    CONFIRM: 'Confirmar',
    REPLICATE_LEADS: 'Replicar formulário de cadastro',
    REPLICATE_LEADS_SUBTITLE1: 'A unidade que você escolheu tem',
    REPLICATE_LEADS_SUBTITLE2:
      'formulários disponíveis. Selecione o formulário e para quais unidades deseja replicar.',
    FORM: 'Formulário de cadastro',
    SELECT_FORM: 'Selecione o formulário',
    REPLICATE_LEADS_SUCCESS: 'Formulário replicado com sucesso!',
    CREDIT_CARD: 'Cartão de crédito',
    MODAL_INACTIVATE_UNIT: 'Deseja realmente inativar {{nameUnit}}?',
    INFORMATION_BUDGET_MODAL: 'Alerta: caso tenha saldo alocado, retirar antes da inativação.',
    TOAST_SUCESS: 'Status alterado com sucesso!',
    EMPTY_UNITS: 'Nenhuma unidade para exibir.',

    // Tela de configuração avançada
    ADVANCED_CONFIG_LOCALIZATION_FORM_TITLE_1:
      'Defina aqui a região onde seu público será impactado por seus anúncios.',
    ADVANCED_CONFIG_LOCALIZATION_FORM_TITLE_2:
      'Você pode optar entre latitude e longitude ou lista de CEPs.',
    ADVANCED_CONFIG_LOCALIZATION_FORM_LATITUDE_SWITCH_LABEL: 'Latitude e Longitude',
    ADVANCED_CONFIG_LOCALIZATION_FORM_LATITUDE_SWITCH_DESCRIPTION:
      'Defina a latitude e longitude usando somente números positivos ou negativos.',
    ADVANCED_CONFIG_LOCALIZATION_FORM_LATITUDE_LABEL: 'Latitude',
    ADVANCED_CONFIG_LOCALIZATION_FORM_LONGITUDE_LABEL: 'Longitude',
    ADVANCED_CONFIG_LOCALIZATION_FORM_RADIUS_TITLE: 'Raio',
    ADVANCED_CONFIG_LOCALIZATION_FORM_RADIUS_DESCRIPTION:
      'Defina a área de cobertura para seus anúncios, que serão representados por Km.',
    ADVANCED_CONFIG_LOCALIZATION_FORM_CITIES_TITLE: 'Cidades',
    ADVANCED_CONFIG_LOCALIZATION_FORM_CITIES_DESCRIPTION:
      'Direcione seus anúncios para pessoas de cidades específicas.',
    ADVANCED_CONFIG_LOCALIZATION_FORM_CITIES_LABEL: 'Cidades',
    ADVANCED_CONFIG_LOCALIZATION_FORM_CITIES_PLACEHOLDER: 'Procure por uma cidade...',
    ADVANCED_CONFIG_LOCALIZATION_FORM_CITIES_EMPTY_RESULTS: 'Nenhuma cidade encontrada.',
    ADVANCED_CONFIG_LOCALIZATION_FORM_CITIES_EMPTY_QUERY: 'Digite o nome da cidade.',
    ADVANCED_CONFIG_LOCALIZATION_FORM_ADRESS_LABEL: 'CEP',
    ADVANCED_CONFIG_LOCALIZATION_FORM_ADRESS_DESCRIPTION:
      'Insira no campo abaixo somente os 5 números iniciais do CEP, separando por vírgula, caso insira mais de um.',
    ADVANCED_CONFIG_LOCALIZATION_FORM_ADRESS_PLACEHOLDER: 'Digite o CEP',
    ADVANCED_CONFIG_TITLE_MODAL: 'Configurações avançadas',
    AUTOMATIC_PLACEMENTS: 'Posicionamentos automáticos',
    AUTOMATIC_PLACEMENTS_HELP_TOOLTIP:
      'O {{platform}} tentará aproveitar ao máximo o seu orçamento e aumentar a exposição dos seus anúncios, permitindo que eles sejam vistos em todos os posicionamentos disponíveis para as suas configurações.',
    FEED_HELP_TOOLTIP:
      'O Feed é uma lista de histórias da sua página inicial em constante atualização. Nele contém atualizações de status, fotos, vídeos, links, atividades de aplicativos e curtidas de pessoas, páginas e grupos que você segue no Facebook.',
    RIGHT_HAND_COLUMN_HELP_TOOLTIP:
      'Os anúncios da coluna da direita do Facebook são apenas em formato desktop. Esses anúncios geralmente aparecem na coluna da direita das Páginas do Facebook, mas podem aparecer em outras áreas do site.',
    INSTANT_ARTICLE_HELP_TOOLTIP:
      'Um Instant Article é um documento HTML que é carregado rapidamente no Facebook. Os Instant Articles oferecem uma forma mais rápida e nativa do Facebook de distribuir o conteúdo que os publishers já produziram para os próprios sites.',
    MARKETPLACE_HELP_TOOLTIP:
      'O Marketplace é um destino no Facebook no qual as pessoas podem encontrar, comprar e vender itens. As pessoas podem navegar pelos anúncios, pesquisar itens à venda na região ou encontrar produtos disponíveis para envio.',
    STORY_HELP_TOOLTIP:
      'O Stories é uma ótima maneira de se conectar com o público e compartilhar conteúdo entre as publicações de vídeo programadas com fotos e vídeos curtos. Todo conteúdo publicado no Stories dura apenas 24 horas.',
    SUGGESTED_VIDEO_HELP_TOOLTIP:
      'No seu feed no Facebook, há uma seção chamada Watch. Vídeos sugeridos são aqueles vídeos que vão aparecendo conforme você for navegando no feed. Você acessa um vídeo e a partir dele, desce na tela para continuar a ver mais vídeos.',
    INSTREAM_VIDEO_HELP_TOOLTIP:
      'O vídeo in-stream do Facebook permite que você veicule anúncios de vídeo para as pessoas que assistem aos vídeos no Facebook de publishers e criadores de conteúdo de mídia digital conhecidos.',
    LATITUDE_LOGINTUDE_HELP_TOOLTIP:
      'Coordenadas geográficas para a localização exata de pontos na superfície do planeta Terra.',
    LATITUDE_HELP_TOOLTIP:
      'Abra o Google Maps e digite o endereço desejado. Na URL, copie a sequência de números entre /@ até a vírgula. Isso é LATITUDE.',
    LONGITUDE_HELP_TOOLTIP:
      'Abra o Google Maps e digite o endereço desejado. Na URL, copie a sequência de números entre as vírgulas. Isso é LONGITUDE.',
    RADIUS_HELP_TOOLTIP: 'Número em quilometros a partir do endereço da página.',
    ADRESS_HELP_TOOLTIP: 'Ajuste a geolocalização dos seus anúncios em nível de CEP',

    ENGAGEMENT: 'Engajamento',
    MESSAGES: 'Mensagens',
    LINK_CLICK: 'Cliques no link',
    CONVERSION: 'Conversão',
    LEADS: 'Leads',
    GOALS_TITLE: 'Objetivos',
    CTA_TITLE: 'CTA',
    KNOW_MORE: 'Saber mais',
    BUY_NOW: 'Comprar agora',
    WHATSAPP: 'Whatsapp',
    REGISTER: 'Registrar',
    BUY: 'Comprar',
    CONTACT_US: 'Contatar-nos',
    APPLY: 'Aplicar',
    GOALS_AND_CTA_TITLE: 'Defina os objetivos e chamadas para ação',
    GOALS_AND_CTA_ERROR: "Ocorreu um erro ao tentar salvar as configurações de objetivos e CTA's.",
    DISABLED_CTA_WARNING_TEXT:
      'Foi encontrado um erro de configuração na página de Facebook que impede o uso desse CTA.',
    DISABLED_WHATSAPP_CTA_WARNING_TEXT:
      'O WhatsApp não está configurado corretamente na página do Facebook.',

    ERROR_LOG_TITLE: 'Ops! Algo deu errado.',
    ERROR_LOG_DESCRIPTION: 'As páginas apresentaram os seguintes erros:',
    ALREADY_CONTAINS_AUDIENCE: 'Já contem esta audiência.',
    ERROR_LOG_PAGE_ID: 'A pagina de id',
  },
};

const UnitsES = {
  Units: {
    SER_OPTION: 'Usuarios',
    UNITS_OPTION: 'Unidades',
    ADD_UNIT: 'Incluir unidad',
    ADD: 'Agregar',
    CANCEL: 'Cancelar',
    ADD_ONE_MORE: 'Agrega una más',
    FEEDBACK_ERROR: 'Error al agregar la unidad, intente nuevamente en un momento.',
    FEEDBACK_SUCCESS: '¡Unidad añadida! Esté atento al estado.',
    GENERAL_CONFIG: 'Configuración general',
    CREDIT_LINE: '¿Qué línea de crédito?',
    SHARED: 'Compartido',
    PRIVATE: 'Privada',
    BUDGET: '¿Qué presupuesto mensual tendrá esta unidad?',
    BUDGET_PLACEHOLDER: 'R$ 0',
    AD_RADIUS: '¿Cuál es el alcance de los anuncios?',
    AD_RADIUS_PLACEHOLDER: '0 km',
    AUTO_APPROVE: '¿Esta unidad tendrá aprobación automática?',
    NO: 'No',
    YES: 'Sí',
    USER_EMAIL_LABEL: 'Correo electrónico del usuario*',
    USER_EMAIL_PLACEHOLDER: 'Introduce el email para enviar la invitación',
    UNIT_CONFIG: 'Configuración de la unidad',
    UNIT_NAME: 'Nombre de la unidad',
    UNIT_NAME_PLACEHOLDER: 'Introduzca el nombre de la unidad',
    FACEBOOK_ID: 'ID de la página',
    FACEBOOK_ID_PLACEHOLDER: 'Ingrese la ID de la página',
    ACCOUNT_ID_LABEL: 'ID de la cuenta publicitaria ',
    ACCOUNT_ID_PLACEHOLDER: 'Ingrese la ID de la cuenta',
    GROUP: 'Grupo',
    SELECT_GROUP: 'Seleccione un grupo',
    PENDING_PLURAL: 'Pendientes',
    PENDING: 'Pendiente',
    RUNNING: 'En proceso',
    FINISHED: 'Activo',
    INACTIVE: 'Inactiva',
    INACTIVE_PLURAL: 'Inactivas',
    ERROR: 'Con erro',
    CHANGE_IMAGE: 'Cambiar perfil y portada',
    EDIT: 'Editar',
    REPLICATE_AUDIENCE: 'Replicar público',
    REPLICATE_SUBTITLE1: 'La unidad que ha elegido tiene',
    REPLICATE_SUBTITLE2:
      'audiencias disponibles. Seleccione la audiencia y las unidades a las que desea replicar.',
    INACTIVATE_UNIT: 'Inactivar unidad',
    ACTIVATE_UNIT: 'Activar unidad',
    ACTIVATE_UNIT_TOAST_SUCCESS: 'Unidad activada con éxito.',
    ACTIVATE_UNIT_TOAST_ERROR: 'No se ha podido activar la unidade: {{errorMessage}}.',
    TRY_AGAIN: 'Intentar nuevamente',
    EDIT_AUDIENCE: 'Cambiar de audiencia',
    UNLIST_AUDIENCE: 'Desvincular audiencia',
    UNLIST_AUDIENCE_SUCCESS: 'Audiencia desvinculada con éxito.',
    UNLIST_AUDIENCE_ERROR: 'No se ha podido desvincular la audiencia.',
    UNLIST_AUDIENCE_SHOULD_DELETE_CLONES_LABEL:
      'Desvincular duplicados de esta audiencia de otras unidades.',
    NO_SAVED_AUDIENCES: 'No hay audiencias vinculadas a esta unidad.',
    INACTIVATE: 'Inactivar',
    ACTIVES: 'Activo',
    CLEAR_FILTER: 'Selección clara',
    MISSING_IMAGE: '¡Por favor inserta una imagen!',
    CHANGE: 'Cambio',
    AVAILABLE_AUDIENCE: 'Audiencias disponibles',
    SELECT_AUDIENCE: 'Seleccionar audiencia',
    CHANGE_AUDIENCE_SUBTITLE: 'Elija la audiencia automática para los anuncios en estas unidades.',
    CHANGE_TARGETING_FEEDBACK_ERROR:
      'Error al cambiar la unidad pública, intente nuevamente en un momento.',
    CHANGE_TARGETING_FEEDBACK_SUCCESS: '¡La audiencia de la unidad cambió!',
    SINGLE_SELECTED_UNIT: 'unidad seleccionada',
    SOME_SELECTED_UNIT: 'unidades seleccionadas',
    C_NAME: 'Nombre',
    C_GROUP: 'Grupo',
    C_STATUS: 'Status',
    PUBLIC: 'Audiencias',
    MONETARY_CONFIG_ACCOUNT: '¿Cómo quieres vincular las cuentas publicitarias?',
    CHOSE_CREDIT_LINE: 'Elige línea de crédito',
    TICKET: 'Boleto',
    MONTHLY_BUDGET: 'Presupuesto mensual',
    PAYMENT_METHOD: '¿Cuál será el método de pago?',
    EXISTING_ACCOUNT: 'Insertar cuenta existente',
    CREATE_ACCOUNT: 'Crear una nueva cuenta',
    INVITE_USER: 'Invitar a los usuarios al administrador comercial.',
    INVALID_PAGE_ID: 'Error de ID de página, comprueba el número e inténtalo de nuevo.',
    INVALID_AD_ACCOUNT_ID:
      'Error de cuenta publicitaria, verifique el número y vuelva a intentarlo.',
    CONTINUE: 'Continuar',
    REPLICATE_AUDIENCE_SUCCESS: 'Audiencias replicadas con éxito!',
    REPLICATE_AUDIENCE_ERROR: '¡No se pudo replicar las audiencias!',
    REPLICATE_AUDIENCE_BYPASS_INVALID_AUDIENCE_PROMPT:
      'Esta es una audiencia personalizada de la unidad. ¿Está seguro de que desea replicar en otras unidades? La funcionalidad no está garantizada en este caso.',
    INVALID_GROUPS_SELECION: '¡Seleccione al menos un grupo!',
    INVALID_PUBLIC: '¡Seleccione una audiencia!',
    CONFIRM: 'Confirmar',
    REPLICATE_LEADS: 'Replicar formulario de registro',
    REPLICATE_LEADS_SUBTITLE1: 'La unidad que ha elegido tiene',
    REPLICATE_LEADS_SUBTITLE2:
      'formularios disponibles. Seleccione el formulario y las unidades en las que desea replicar.',
    FORM: 'Formulario de inscripción',
    SELECT_FORM: 'Seleccionar formulario',
    REPLICATE_LEADS_SUCCESS: 'Formulario replicado con éxito!',
    CREDIT_CARD: 'Tarjeta de crédito',
    MODAL_INACTIVATE_UNIT: '¿Realmente quieres desactivar {{nameUnit}}?',
    INFORMATION_BUDGET_MODAL: 'Alerta: si tienes saldo asignado, retira antes de la inactivación.',
    TOAST_SUCESS: 'El estado cambió con éxito',
    EMPTY_UNITS: 'No hay unidades para mostrar.',

    // Tela de configuração avançada
    ADVANCED_CONFIG_LOCALIZATION_FORM_TITLE_1:
      'Defina aquí la región en la que su audiencia se verá afectada por sus anuncios.',
    ADVANCED_CONFIG_LOCALIZATION_FORM_TITLE_2:
      'Puede elegir entre latitud y longitud o una lista de códigos postales.',
    ADVANCED_CONFIG_LOCALIZATION_FORM_LATITUDE_SWITCH_LABEL: 'Latitud y longitud',
    ADVANCED_CONFIG_LOCALIZATION_FORM_LATITUDE_SWITCH_DESCRIPTION:
      'Establezca la latitud y la longitud usando solo números positivos o negativos.',
    ADVANCED_CONFIG_LOCALIZATION_FORM_LATITUDE_LABEL: 'Latitud',
    ADVANCED_CONFIG_LOCALIZATION_FORM_LONGITUDE_LABEL: 'Longitud',
    ADVANCED_CONFIG_LOCALIZATION_FORM_RADIUS_TITLE: 'Radio',
    ADVANCED_CONFIG_LOCALIZATION_FORM_RADIUS_DESCRIPTION:
      'Establece el área de cobertura de tus anuncios, que estará representada por Km.',
    ADVANCED_CONFIG_LOCALIZATION_FORM_CITIES_TITLE: 'Ciudades',
    ADVANCED_CONFIG_LOCALIZATION_FORM_CITIES_DESCRIPTION:
      'Oriente sus anuncios a personas en ciudades específicas.',
    ADVANCED_CONFIG_LOCALIZATION_FORM_CITIES_LABEL: 'Ciudades',
    ADVANCED_CONFIG_LOCALIZATION_FORM_CITIES_PLACEHOLDER: 'Buscar una ciudad...',
    ADVANCED_CONFIG_LOCALIZATION_FORM_CITIES_EMPTY_RESULTS: 'Ninguna ciudad encontrada.',
    ADVANCED_CONFIG_LOCALIZATION_FORM_CITIES_EMPTY_QUERY: 'Ingrese el nombre de la ciudad.',
    ADVANCED_CONFIG_LOCALIZATION_FORM_ADRESS_LABEL: 'Código postal',
    ADVANCED_CONFIG_LOCALIZATION_FORM_ADRESS_DESCRIPTION:
      'Ingrese en el campo de abajo solo los 5 números iniciales del código postal, separados por coma, si ingresa más de uno.',
    ADVANCED_CONFIG_LOCALIZATION_FORM_ADRESS_PLACEHOLDER: 'Ingrese el código postal',
    ADVANCED_CONFIG_TITLE_MODAL: 'Configuraciones avanzadas',
    AUTOMATIC_PLACEMENTS: 'Posiciones automáticas',
    AUTOMATIC_PLACEMENTS_HELP_TOOLTIP:
      '{{platform}} intentará aprovechar al máximo su presupuesto y aumentar la exposición de sus anuncios al permitir que se vean en todas las ubicaciones disponibles para su configuración.',
    FEED_HELP_TOOLTIP:
      'Feed es una lista siempre actualizada de historias de tu página de inicio. Contiene actualizaciones de estado, fotos, vídeos, enlaces, actividad de aplicaciones y "me gusta" de las personas, páginas y grupos que sigues en Facebook.',
    RIGHT_HAND_COLUMN_HELP_TOOLTIP:
      'Los anuncios de la columna derecha de Facebook solo están en formato desktop. Estos anuncios suelen aparecer en la columna derecha de las páginas de Facebook, pero pueden aparecer en otras zonas de la página web.',
    INSTANT_ARTICLE_HELP_TOOLTIP:
      'Un artículo instantáneo es un documento HTML que se carga rápidamente en Facebook. Los artículos instantáneos ofrecen una forma más rápida y nativa de Facebook para entregar el contenido que los editores ya han producido en sus sitios web.',
    MARKETPLACE_HELP_TOOLTIP:
      'Marketplace es un destino en Facebook donde las personas pueden encontrar, comprar y vender artículos. Las personas pueden buscar anuncios, buscar artículos a la venta en su región o encontrar productos disponibles para enviar.',
    STORY_HELP_TOOLTIP:
      'Las historias son una excelente manera de conectarse con su audiencia y compartir contenido entre publicaciones de video programadas con fotos y videos cortos. Todo el contenido publicado en Stories solo dura 24 horas.',
    SUGGESTED_VIDEO_HELP_TOOLTIP:
      'Los vídeos sugeridos son aquellos que aparecen a medida que te desplazas hacia abajo. Accedes a un vídeo y desde ahí te desplazas hacia abajo para seguir viendo más vídeos.',
    INSTREAM_VIDEO_HELP_TOOLTIP:
      'Facebook In-Stream Video te permite publicar anuncios de vídeo a las personas que ven los vídeos de Facebook de conocidos editores y creadores de contenidos digitales.',
    LATITUDE_LOGINTUDE_HELP_TOOLTIP:
      'Coordenadas geográficas para la ubicación exacta de puntos en la superficie del planeta Tierra.',
    LATITUDE_HELP_TOOLTIP:
      'Abra Google Maps e ingrese la dirección deseada. En la URL, copie la secuencia de números entre /@ para la coma. Esta es la LATITUD.',
    LONGITUDE_HELP_TOOLTIP:
      'Abra Google Maps e ingrese la dirección deseada. En la URL, copie la secuencia de números entre las comas. Eso es LONGITUD.',
    RADIUS_HELP_TOOLTIP:
      'Sus anuncios impactarán a los usuarios dentro del radio dado / Número en kilómetros desde la dirección de la página.',
    ADRESS_HELP_TOOLTIP: 'Ajusta la geolocalización de tus anuncios a nivel de código postal.',

    ENGAGEMENT: 'Compromisso',
    MESSAGES: 'Publicaciones',
    LINK_CLICK: 'Clics en el enlace',
    CONVERSION: 'Conversión',
    LEADS: 'Leads',
    GOALS_TITLE: 'Metas',
    CTA_TITLE: 'CTA',
    KNOW_MORE: 'Saber mas',
    BUY_NOW: 'Comprar ahora',
    WHATSAPP: 'Whatsapp',
    REGISTER: 'Registrar',
    BUY: 'Comprar',
    CONTACT_US: 'Contáctenos',
    APPLY: 'Aplicar',
    GOALS_AND_CTA_TITLE: 'Establecer objetivos y llamadas a la acción',
    GOALS_AND_CTA_ERROR:
      'Se produjo un error al intentar guardar los objetivos y la configuración de CTA.',
    DISABLED_CTA_WARNING_TEXT:
      'Se encontró un error de configuración en la página de Facebook que impide el uso de esta CTA.',
    DISABLED_WHATSAPP_CTA_WARNING_TEXT:
      'WhatsApp no está configurado correctamente en la página de Facebook.',

    ERROR_LOG_TITLE: '¡Ups! Algo salió mal.',
    ERROR_LOG_DESCRIPTION: 'Las páginas presentaron los siguientes errores:',
    ALREADY_CONTAINS_AUDIENCE: 'Ya contiene esta audiencia.',
    ERROR_LOG_PAGE_ID: 'La página de id',
  },
};

export { UnitsEN, UnitsPT, UnitsES };
