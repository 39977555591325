// Modules
import { all } from 'redux-saga/effects';

// Saga
import { formSaga } from './form/saga';
import { authenticationSaga } from './auth/saga';
import { genericSaga } from './generic/saga';
import { businessSaga } from './business/saga';
import { actionsSaga } from './actions/saga';
import { mediasSaga } from './medias/saga';
import reportSaga from './report/saga';
import { budgetSaga } from './budget/saga';
import { feedSaga } from './feed/saga';
import { easyAccessSaga } from './easyaccess/saga';
import { videoViewSaga } from './videoView/saga';
import { surveySaga } from './survey/saga';
import { settingsSaga } from './settings/saga';
import { campaignsSaga } from './campaigns/saga';

function* rootSagas() {
  // here we initialize all the saga from different files
  yield all([
    ...formSaga,
    ...authenticationSaga,
    ...genericSaga,
    ...businessSaga,
    ...actionsSaga,
    ...mediasSaga,
    ...reportSaga,
    ...budgetSaga,
    ...feedSaga,
    ...easyAccessSaga,
    ...videoViewSaga,
    ...surveySaga,
    ...settingsSaga,
    ...campaignsSaga,
  ]);
}

export default rootSagas;
