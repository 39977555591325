const GuestPT = {
  Guest: {
    GUEST_TITLE: 'Seja bem-vindo!',
    GUEST_DESCRIPTION: 'Crie sua conta para acessar seu Gerente Digital.',
    GUEST_FORM_DESCRIPTION: 'Insira seus dados básicos:',
    GUEST_FORM_NAME: 'Nome',
    GUEST_FORM_LASTNAME: 'Sobrenome',
    GUEST_FORM_EMAIL: 'Seu email de acesso',
    GUEST_FORM_BUTTON: 'Próximo',
    VALIDATION_REQUIRED: 'Campo obrigatório',
    GUEST_RESPONSE_INITIAL: 'Bem vindo(a) ao Gerentes Digitais. Um e-mail foi enviado para',
    GUEST_RESPONSE_FINAL: 'Você precisa verifica-lo e aprovar seu registro.',
    MESSAGE_EMAIL_ALREADY_REGISTERED: 'Email já cadastrado',
    MESSAGE_SUCCESS: 'Convite enviado com sucesso',
  },
};

const GuestEN = {
  Guest: {
    GUEST_TITLE: 'Welcome!',
    GUEST_DESCRIPTION: 'Create your account to access your Digital Manager.',
    GUEST_FORM_DESCRIPTION: 'Enter your basic data:',
    GUEST_FORM_NAME: 'Name',
    GUEST_FORM_LASTNAME: 'Last name',
    GUEST_FORM_EMAIL: 'Your email access',
    GUEST_FORM_BUTTON: 'Next',
    VALIDATION_REQUIRED: 'Required field',
    GUEST_RESPONSE_INITIAL: 'Welcome to Digital Managers. An email was sent to',
    GUEST_RESPONSE_FINAL: 'You must check it and approve your registration.',
    MESSAGE_EMAIL_ALREADY_REGISTERED: 'Email already registered',
    MESSAGE_SUCCESS: 'Invitation sent successfully',
  },
};

export { GuestPT, GuestEN };
