// Types
import { SET_FILTERS } from './types';

const INITIAL_STATE = {
  filters: {
    query: '',
    status: 'all',
    role: 'all',
  },
};

const usersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_FILTERS.SUCCESS:
      return {
        ...state,
        filters: action.filters,
      };

    case SET_FILTERS.RESET:
      return {
        ...state,
        filters: {
          query: '',
          status: 'all',
          role: 'all',
        },
      };

    default:
      return state;
  }
};

export default usersReducer;
