import { IKey } from './types';

export const getKey = ({ pageKey, previousPage, period, allData, URL }: IKey) => {
  const hasDate = period?.start && period?.end;
  if (!hasDate) return null;
  else if (previousPage?.payload?.count_total) {
    const hasMoreData = allData && allData?.length < previousPage?.payload?.count_total;
    if (!hasMoreData) {
      return null;
    }
  } else if (pageKey === 0) return URL;

  return `${URL}&skip=${+QUERY_SIZE * pageKey}`;
};

export const options = {
  revalidateOnFocus: false,
};

export const QUERY_SIZE: string = '12';
