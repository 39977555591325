import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonWithMixPanel } from '@components/MixPanel';

export const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    cursor: pointer;
    position: relative;
    padding: ${theme.spacing.px8};
    font-size: 2rem !important;
    transition: background 0.8s;
  `}
`;

export const MenuProfileWrapper = styled('div')`
  ${({ theme, feedType }) => css`
    position: absolute;
    z-index: 2;

    background: ${theme.colors.white};
    margin-top: ${feedType === 'POST' ? '-130px' : '-170px'};

    border: 1px solid ${theme.colors.grey2};

    border-radius: ${theme.rounded.px10};
    box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 16px 24px 2px rgba(0, 0, 0, 0.14),
      0 6px 30px 5px rgba(0, 0, 0, 0.12);

    &:after,
    &:before {
      top: 100%;
      right: 119px;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-top-color: ${theme.colors.white};
      border-width: 11px;
      margin-left: -11px;
      bottom: -20px;
    }
    &:before {
      border-color: rgba(204, 204, 204, 0);
      border-top-color: ${theme.colors.grey2};
      border-width: 11px;
      margin-left: -11px;
    }
  `}
`;

export const MenuProfileRelative = styled('div')`
  ${({ theme }) => css`
    position: relative;
    width: ${theme.spacing.full};
    display: flex;
  `}
`;

export const MenuProfileContent = styled('div')`
  ${({ theme }) => css`
    width: ${theme.spacing.full};
    border-radius: ${theme.rounded.px10};
    transition: all 0.5s;
    overflow: hidden;
    max-height: 80vh;
  `}
`;

export const MenuButton = styled(ButtonWithMixPanel)`
  ${({ theme }) => css`
    button {
      all: unset;

      width: ${theme.spacing.full};
      padding: ${theme.spacing.px10} ${theme.spacing.px15};
      border-bottom: 2px solid ${theme.colors.grey2};

      font-size: ${theme.fontSize.px14};
      color: ${theme.colors.blueGrey2};

      :hover {
        width: ${theme.spacing.full};
        background: ${theme.colors.blue5};
        padding: ${theme.spacing.px10} ${theme.spacing.px15};
      }
    }
  `}
`;
