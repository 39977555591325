// Modules
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import Image from '@components/Image';

// Images
import images from '@images';

const LoadingStyled = styled('div')`
  position: relative;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingOld = ({ isLoading }) =>
  isLoading && (
    <LoadingStyled>
      <Image src={images.loading} alt="Loading" />
    </LoadingStyled>
  );

LoadingOld.propTypes = {
  isLoading: PropTypes.bool,
};

LoadingOld.defaultProps = {
  isLoading: false,
};

export { LoadingOld };

export default LoadingOld;
