import { useMemo } from 'react';
import useSWR from 'swr';
import { getFormattedPeriod } from '../../../utils/helpers';
import { bffFetcher } from '../../../utils/api';

import type { BaseResponse, MetricsResponse, PayloadResponse } from 'model';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useCampaign } from 'utils/contexts';
import { useParams } from 'react-router';

export function usePerformanceMetrics(): BaseResponse<MetricsResponse[]> {
  const { filters } = useCampaign();
  const genericReducer = useSelector((state: RootStateOrAny) => state.genericReducer);
  const { code } = useParams<{ code: string }>();

  const URL = useMemo(() => {
    const period = genericReducer.period;
    const { previousEnd, previousStart } = getFormattedPeriod(period);

    const url = {
      since_current: period.start,
      until_current: period.end,
      since_previous: previousStart,
      until_previous: previousEnd,
      intersection_period: true,
      permitted_group: code,
    };

    let baseURL = '/api/v1/incentivecampaign/report/period?';
    if (period.start)
      baseURL += `since_current=${url.since_current}&until_current=${url.until_current}&since_previous=${url.since_previous}&until_previous=${url.until_previous}&intersection_period=${url.intersection_period}`;

    if (filters?.incentive_campaign_status?.length) {
      const campaignStatus = filters?.incentive_campaign_status?.toLowerCase();
      baseURL += `&incentive_campaign_status=${campaignStatus}`;
    }

    if (url.permitted_group) {
      baseURL += `&permitted_group=${url.permitted_group}`;
    }

    return baseURL;
  }, [filters?.incentive_campaign_status, genericReducer.period, code]);

  const { data, error } = useSWR<PayloadResponse<MetricsResponse[]>, Error>(URL, bffFetcher, {
    revalidateOnFocus: false,
  });

  return {
    data: data?.payload,
    isLoading: !error && !data,
    error: error?.message,
  };
}
