// Types
import {
  FETCH_LIST_USERS,
  CHANGE_STATUS_USER,
  CHANGE_GROUP_USER,
  REVOKE_INVITE,
  INVITE_USER,
  VERIFY_EMAIL_USER,
  GET_SAS_URL,
  EDIT_USER_ROLE,
  VERIFY_BUSINESS,
  CHANGE_CAPABILITIES,
  GET_USERS_SUMMARY,
  CLONE_CAPABILITIES,
} from './types';

const INITIAL_STATE = {
  listUsers: {
    data: {
      data: [],
      paging: {},
    },
    isLoading: false,
    error: '',
  },
  permittedUsers: {
    isLoading: false,
  },
  statusUser: {
    data: [],
    isLoading: false,
    error: '',
  },
  revokeInvite: {
    data: [],
    isLoading: false,
    error: '',
  },
  inviteUser: {
    data: [],
    isLoading: false,
    error: '',
  },
  verifyEmailUser: {
    data: [],
    error: [],
  },
  sasUrl: {
    data: { sas_url: '' },
    isLoading: false,
    error: '',
  },
  userRole: {
    response: '',
    isLoading: false,
  },
  verifyBusiness: {
    data: '',
    isLoading: false,
    error: '',
  },
  capabilities: {
    data: {},
    isLoading: false,
    error: '',
  },
  usersSummary: {
    data: {
      easyAccess: {},
    },
    isLoading: false,
    error: '',
  },
  cloneCapabilities: {
    isLoading: false,
  },
};

const businessReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_LIST_USERS.REQUEST:
      return {
        ...state,
        listUsers: {
          ...state.listUsers,
          isLoading: true,
        },
      };

    case FETCH_LIST_USERS.SUCCESS:
      return {
        ...state,
        listUsers: {
          ...state.listUsers,
          data: {
            ...state.listUsers.data,
            data: [...state.listUsers.data.data, ...action.listUsers.data],
            paging: action.listUsers.paging,
          },
          isLoading: false,
        },
      };

    case FETCH_LIST_USERS.FAILURE:
      return {
        ...state,
        listUsers: {
          data: INITIAL_STATE.listUsers.data,
          isLoading: false,
          error: action.listUsersError,
        },
      };

    case FETCH_LIST_USERS.RESET:
      return {
        ...state,
        listUsers: INITIAL_STATE.listUsers,
      };

    case CHANGE_STATUS_USER.REQUEST:
      return {
        ...state,
        statusUser: {
          isLoading: true,
        },
      };

    case CHANGE_STATUS_USER.SUCCESS:
      return {
        ...state,
        statusUser: {
          data: action.statusUser,
          isLoading: false,
        },
      };

    case CHANGE_STATUS_USER.FAILURE:
      return {
        ...state,
        statusUser: {
          isLoading: false,
          error: action.statusUserError,
        },
      };

    case CHANGE_GROUP_USER.REQUEST:
      return {
        ...state,
        permittedUsers: {
          isLoading: true,
        },
      };

    case CHANGE_GROUP_USER.SUCCESS:
      return {
        ...state,
        permittedUsers: {
          isLoading: false,
        },
      };

    case CHANGE_GROUP_USER.FAILURE:
      return {
        ...state,
        permittedUsers: {
          isLoading: false,
        },
      };

    case REVOKE_INVITE.REQUEST:
      return {
        ...state,
        revokeInvite: {
          isLoading: true,
        },
      };

    case REVOKE_INVITE.SUCCESS:
      return {
        ...state,
        revokeInvite: {
          data: action.revokeInvite,
          isLoading: false,
        },
      };

    case REVOKE_INVITE.FAILURE:
      return {
        ...state,
        revokeInvite: {
          isLoading: false,
          error: action.revokeInviteError,
        },
      };
    case INVITE_USER.REQUEST:
      return {
        ...state,
        inviteUser: {
          isLoading: true,
        },
      };

    case INVITE_USER.SUCCESS:
      return {
        ...state,
        inviteUser: {
          data: action.inviteUser,
          isLoading: false,
        },
      };

    case INVITE_USER.FAILURE:
      return {
        ...state,
        inviteUser: {
          isLoading: false,
          error: action.inviteUserError,
        },
      };

    case VERIFY_EMAIL_USER.REQUEST:
      return {
        ...state,
        verifyEmailUser: {
          isLoading: true,
        },
      };

    case VERIFY_EMAIL_USER.SUCCESS:
      return {
        ...state,
        verifyEmailUser: {
          data: action.verifyEmailUser,
          isLoading: false,
        },
      };

    case VERIFY_EMAIL_USER.FAILURE:
      return {
        ...state,
        verifyEmailUser: {
          isLoading: false,
          error: action.verifyEmailUserError,
        },
      };

    case GET_SAS_URL.REQUEST:
      return {
        ...state,
        sasUrl: {
          isLoading: true,
        },
      };

    case GET_SAS_URL.SUCCESS:
      return {
        ...state,
        sasUrl: {
          data: action.sasUrl,
          isLoading: false,
        },
      };

    case GET_SAS_URL.FAILURE:
      return {
        ...state,
        sasUrl: {
          isLoading: false,
          error: action.error,
        },
      };

    case EDIT_USER_ROLE.REQUEST:
      return {
        ...state,
        userRole: {
          response: '',
          isLoading: true,
        },
      };

    case EDIT_USER_ROLE.SUCCESS:
      return {
        ...state,
        userRole: {
          response: action.newUserRole,
          isLoading: false,
        },
      };

    case EDIT_USER_ROLE.FAILURE:
      return {
        ...state,
        userRole: {
          response: action.newUserRoleError,
          isLoading: false,
        },
      };

    case VERIFY_BUSINESS.REQUEST:
      return {
        ...state,
        verifyBusiness: {
          ...state.verifyBusiness,
          isLoading: true,
        },
      };

    case VERIFY_BUSINESS.SUCCESS:
      return {
        ...state,
        verifyBusiness: {
          data: action.userToken,
          isLoading: false,
        },
      };

    case VERIFY_BUSINESS.FAILURE:
      return {
        ...state,
        verifyBusiness: {
          data: '',
          isLoading: false,
          error: action.error,
        },
      };
    case CHANGE_CAPABILITIES.REQUEST:
      return {
        ...state,
        capabilities: {
          isLoading: true,
        },
      };

    case CHANGE_CAPABILITIES.SUCCESS:
      return {
        ...state,
        capabilities: {
          isLoading: false,
        },
      };

    case CHANGE_CAPABILITIES.FAILURE:
      return {
        ...state,
        capabilities: {
          isLoading: false,
        },
      };
    case GET_USERS_SUMMARY.REQUEST:
      return {
        ...state,
        usersSummary: {
          isLoading: true,
        },
      };

    case GET_USERS_SUMMARY.SUCCESS:
      return {
        ...state,
        usersSummary: {
          data: action.usersSummary,
          isLoading: false,
        },
      };

    case GET_USERS_SUMMARY.FAILURE:
      return {
        ...state,
        usersSummary: {
          isLoading: false,
          error: action.usersSummaryError,
        },
      };

    case CLONE_CAPABILITIES.REQUEST:
      return {
        ...state,
        cloneCapabilities: {
          ...state.cloneCapabilities,
          isLoading: true,
        },
      };

    case CLONE_CAPABILITIES.SUCCESS:
      const fromUser = action.fromUser;
      const toUserIds = action.toUserIds;

      const oldListUsersData = state.listUsers.data.data;
      const newListUsersData = oldListUsersData.map((user) => {
        if (toUserIds.includes(user.user_id)) {
          return {
            ...user,
            capabilities: fromUser.capabilities,
          };
        }

        return user;
      });

      return {
        ...state,
        listUsers: {
          ...state.listUsers,
          data: {
            ...state.listUsers.data,
            data: newListUsersData,
          },
        },
        cloneCapabilities: {
          ...state.cloneCapabilities,
          isLoading: false,
        },
      };

    case CLONE_CAPABILITIES.FAILURE:
      return {
        ...state,
        cloneCapabilities: {
          ...state.cloneCapabilities,
          isLoading: false,
        },
      };

    default:
      return state;
  }
};

export default businessReducer;
