import BFFApi from '@utils/bffApi';

const GDScoreService = {
  getActionScore() {
    return BFFApi.get('/api/v1/actionscore/all');
  },

  saveActionScore(body) {
    return BFFApi.post('/api/v1/actionscore/', body);
  },

  updateAction(body) {
    return BFFApi.put('/api/v1/actionscore/', body);
  },
};

export default GDScoreService;
