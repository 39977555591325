const ModalMetricsTablePT = {
  ModalMetricsTable: {
    TITLE: 'Personalizado',
    SUBTITLE: 'Crie relatórios personalizados de acordo com as ',
    SUBTITLE_TWO: 'métricas que mais se adequam ao seu negócio',
    SPEND: 'Valor investido',
    IMPRESSIONS: 'Impressões',
    SPEND_IMPRESSIONS: 'CPM',
    LINK_CLICK: 'Cliques no link',
    SPEND_LINK_CLICK: 'Custo por cliques no link',
    LINK_CLICK_IMPRESSIONS: 'Taxa de cliques no link',
    VIDEO_VIEW_VIEW: 'Visualização de vídeo',
    SPEND_VIDEO_VIEW: 'Custo por visualização de vídeo',
    VIDEO_VIEW_VIEW_IMPRESSIONS: 'Taxa de visualização de vídeo',
    POST_ENGAGEMENT: 'Engajamento',
    SPEND_POST_ENGAGEMENT: 'Custo por engajamento',
    POST_ENGAGEMENT_IMPRESSIONS: 'Taxa de engajamento',
    POST_REACTION: 'Reações',
    SPEND_POST_REACTION: 'Custo por reações',
    POST_REACTION_IMPRESSIONS: 'Taxa de reações',
    PHOTO_VIEW: 'Visualizações de foto',
    SPEND_PHOTO_VIEW: 'Custo por visualizações de foto',
    PHOTO_VIEW_IMPRESSIONS: 'Taxa de visualizações de foto',
    COMMENT: 'Comentário',
    SPEND_COMMENT: 'Custo por comentários',
    COMMENT_IMPRESSIONS: 'Taxa de comentários',
    POST: 'Compartilhamentos',
    SPEND_POST: 'Custo por compartilhamentos',
    POST_IMPRESSIONS: 'Taxa de compartilhamentos',
    LIKE: 'Curtidas na página',
    SPEND_LIKE: 'Custo por curtidas na página',
    LIKE_IMPRESSIONS: 'Taxa de curtidas na página',
    OFFLINE_CONVERSION_PURCHASE: 'Venda offline',
    SPEND_OFFLINE_CONVERSION_PURCHASE: 'Custo por venda offline',
    OFFLINE_CONVERSION_PURCHASE_IMPRESSIONS: 'Taxa de venda offline',
    OFFLINE_CONVERSION_PURCHASE_VALUE: 'Receita de venda offline',
    OFFLINE_CONVERSION_PURCHASE_VALUE_SPEND: 'ROAS venda offline',
    OFFSITE_CONVERSION_FB_PIXEL_PURCHASE: 'Venda online',
    SPEND_OFFSITE_CONVERSION_FB_PIXEL_PURCHASE: 'Custo por venda online',
    OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_IMPRESSIONS: 'Taxa de venda online',
    OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_VALUE: 'Receita venda online',
    OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_VALUE_SPEND: 'ROAS venda online',
    OFFSITE_CONVERSION_FB_PIXEL_ADD_TO_CART: 'Adicionar ao carrinho (online)',
    SPEND_OFFSITE_CONVERSION_FB_PIXEL_ADD_TO_CART: 'Custo adicionar ao carrinho (online)',
    OFFSITE_CONVERSION_FB_PIXEL_ADD_TO_CART_IMPRESSIONS: 'Taxa adicionar ao carrinho (online)',
    OFFSITE_CONVERSION_FB_PIXEL_VIEW_CONTENT: 'Visualização de conteúdo (online)',
    SPEND_OFFSITE_CONVERSION_FB_PIXEL_VIEW_CONTENT: 'Custo visualização de conteúdo (online)',
    OFFSITE_CONVERSION_FB_PIXEL_VIEW_CONTENT_IMPRESSIONS: 'Taxa visualização de conteúdo (online)',
    OFFLINE_CONVERSION_PURCHASE_CONVERSION_FB_PIXEL_PURCHASE: 'Venda geral',
    SPEND_OFFLINE_CONVERSION_PURCHASE_OFFSITE_FB_PIXEL_PURCHASE: 'Custo por venda geral',
    OFFLINE_CONVERSION_PURCHASE_OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_IMPRESSIONS:
      'Taxa de venda geral',
    OFFLINE_CONVERSION_PURCHASE_VALUE_OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_VALUE:
      'Receita venda geral',
    OFFLINE_CONVERSION_PURCHASE_VALUE_OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_VALUE_SPEND:
      'ROAS venda geral',
    ONSITE_CONVERSION_LEAD_GROUPED: 'Cadastros gerados',
    SPEND_ONSITE_CONVERSION_LEAD_GROUPED: 'Custo por cadastros gerados',
    ONSITE_CONVERSION_LEAD_GROUPED_IMPRESSIONS: 'Taxa de cadastros gerados',
    ONSITE_COVERSION_MESSAGING_CONVERSATION_STARTED_7D: 'Conversas iniciadas',
    SPEND_ONSITE_COVERSION_MESSAGING_CONVERSATION_STARTED_7D: 'Custo por conversas iniciadas',
    ONSITE_COVERSION_MESSAGING_CONVERSATION_STARTED_7D_IMPRESSIONS: 'Taxa de conversas iniciadas',
    OFFSITE_CONVERSION_FB_PIXEL_INITIATED_CHECKOUT: 'Checkout iniciado (online)',
    SPEND_OFFSITE_CONVERSION_FB_PIXEL_INITIATED_CHECKOUT: 'Custo por checkout iniciado (online)',
    OFFSITE_CONVERSION_FB_PIXEL_INITIATED_CHECKOUT_IMPRESSIONS:
      'Taxa de checkout iniciado (online)',
    METRIC_ONE: 'Métricas',
    METRIC_TWO: 'Métrica 2',
    METRICS_SUB_TEXT: 'Selecione as métricas que deseja aplicar',
    CLEAN_FILTER: 'Limpar filtro',
    TO_APPLY: 'Aplicar',
    FILTER_REGIONAL: 'Regional',
    FILTER_UNITIES: 'Unidades',
    AD_COUNT: 'Quantidade de anúncios criados',
    POST_COUNT: 'Quantidade de postagens criadas',
    CONTENT_COUNT: 'Total de conteúdos criados',
    UNITS_COUNT: 'Total de lojas',
    UNITS_WITH_POSTS: 'Quantidade de lojas postando',
    UNITS_WITH_ADS: 'Quantidade de lojas anunciando',
    UNITS_WITH_POSTS_AND_ADS: 'Quantidade de lojas postando e anunciando',
    UNITS_WITH_POSTS_OR_ADS: 'Quantidade de lojas postando ou anunciando',
    AD_PERCENTAGE: 'Taxa de lojas anunciando',
    POST_PERCENTAGE: 'Taxa de lojas postando',
    UNITS_ACTIVE_FEE: 'Taxa de lojas ativas',
  },
};

const ModalMetricsTableEN = {
  ModalMetricsTable: {
    TITLE: 'Custom',
    SUBTITLE: 'Create custom reports based on the metrics that best fit your business',
    SUBTITLE_TWO: 'Metrics that best fit your business',
    SPEND: 'Invested value',
    IMPRESSIONS: 'Impressions',
    SPEND_IMPRESSIONS: 'CPM',
    LINK_CLICK: 'Link clicks',
    SPEND_LINK_CLICK: 'Cost per link clicks',
    LINK_CLICK_IMPRESSIONS: 'Link click through rate',
    VIDEO_VIEW_VIEW: 'Video viewing',
    SPEND_VIDEO_VIEW: 'Cost per video viewing',
    VIDEO_VIEW_VIEW_IMPRESSIONS: 'Video view rate',
    POST_ENGAGEMENT: 'Engagement',
    SPEND_POST_ENGAGEMENT: 'Cost per engagement',
    POST_ENGAGEMENT_IMPRESSIONS: 'Engagement rate',
    POST_REACTION: 'Reactions',
    SPEND_POST_REACTION: 'Cost per reactions',
    POST_REACTION_IMPRESSIONS: 'Reaction rate',
    PHOTO_VIEW: 'Photo views',
    SPEND_PHOTO_VIEW: 'Cost per photo views',
    PHOTO_VIEW_IMPRESSIONS: 'Photo view rate',
    COMMENT: 'Comment',
    SPEND_COMMENT: 'Cost per reviews',
    COMMENT_IMPRESSIONS: 'Feedback rate',
    POST: 'Shares',
    SPEND_POST: 'Cost per shares',
    POST_IMPRESSIONS: 'Sharing fee',
    LIKE: 'Likes on the page',
    SPEND_LIKE: 'Cost per likes on the page',
    LIKE_IMPRESSIONS: 'Rate of likes on the page',
    OFFLINE_CONVERSION_PURCHASE: 'Offline selling',
    SPEND_OFFLINE_CONVERSION_PURCHASE: 'Cost for offline sales',
    OFFLINE_CONVERSION_PURCHASE_IMPRESSIONS: 'Offline sales fee',
    OFFLINE_CONVERSION_PURCHASE_VALUE: 'Offline sales revenue',
    OFFLINE_CONVERSION_PURCHASE_VALUE_SPEND: 'ROAS offline sale',
    OFFSITE_CONVERSION_FB_PIXEL_PURCHASE: 'Online sale',
    SPEND_OFFSITE_CONVERSION_FB_PIXEL_PURCHASE: 'Cost per online sale',
    OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_IMPRESSIONS: 'Online sales fee',
    OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_VALUE: 'Online sales revenue',
    OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_VALUE_SPEND: 'ROAS online sale',
    OFFSITE_CONVERSION_FB_PIXEL_ADD_TO_CART: 'Add to cart (online)',
    SPEND_OFFSITE_CONVERSION_FB_PIXEL_ADD_TO_CART: 'Cost add to cart (online)',
    OFFSITE_CONVERSION_FB_PIXEL_ADD_TO_CART_IMPRESSIONS: 'Fee add to cart (online)',
    OFFSITE_CONVERSION_FB_PIXEL_VIEW_CONTENT: 'Content view (online)',
    SPEND_OFFSITE_CONVERSION_FB_PIXEL_VIEW_CONTENT: 'Content viewing cost (online)',
    OFFSITE_CONVERSION_FB_PIXEL_VIEW_CONTENT_IMPRESSIONS: 'Content viewing fee (online)',
    OFFLINE_CONVERSION_PURCHASE_CONVERSION_FB_PIXEL_PURCHASE: 'General sale',
    SPEND_OFFLINE_CONVERSION_PURCHASE_OFFSITE_FB_PIXEL_PURCHASE: 'General sale cost',
    OFFLINE_CONVERSION_PURCHASE_OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_IMPRESSIONS:
      'General sale rate',
    OFFLINE_CONVERSION_PURCHASE_VALUE_OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_VALUE:
      'General sales revenue',
    OFFLINE_CONVERSION_PURCHASE_VALUE_OFFSITE_CONVERSION_FB_PIXEL_PURCHASE_VALUE_SPEND:
      'ROAS general sale',
    ONSITE_CONVERSION_LEAD_GROUPED: 'Records generated',
    SPEND_ONSITE_CONVERSION_LEAD_GROUPED: 'Cost for generated registrations',
    ONSITE_CONVERSION_LEAD_GROUPED_IMPRESSIONS: 'Registration fee generated',
    ONSITE_COVERSION_MESSAGING_CONVERSATION_STARTED_7D: 'Conversations started',
    SPEND_ONSITE_COVERSION_MESSAGING_CONVERSATION_STARTED_7D: 'Cost for initiated conversations',
    ONSITE_COVERSION_MESSAGING_CONVERSATION_STARTED_7D_IMPRESSIONS: 'Conversations initiated rate',
    METRIC_ONE: 'Metric 1',
    METRIC_TWO: 'Metric 2',
    CLEAN_FILTER: 'Clean filter',
    TO_APPLY: 'To apply',
    FILTER_REGIONAL: 'Regional',
    FILTER_UNITIES: 'Unities',
    AD_COUNT: 'Quantity of ads created',
    POST_COUNT: 'Quantity of posts created',
    CONTENT_COUNT: 'Total of content created',
    UNITS_COUNT: 'Total of units',
    UNITS_WITH_POSTS: 'Quantity of units that are posting',
    UNITS_WITH_ADS: 'Quantity of units that are making ads',
    UNITS_WITH_POSTS_AND_ADS: 'Quantity of units that are posting and making ads',
    UNITS_WITH_POSTS_OR_ADS: 'Quantity of units that are posting or making ads',
    AD_PERCENTAGE: 'Stores advertising fee',
    POST_PERCENTAGE: 'Stores posting fee',
    UNITS_ACTIVE_FEE: 'Active stores fee',
  },
};

export { ModalMetricsTableEN, ModalMetricsTablePT };
