/* Modules */
import { call, put, takeEvery } from 'redux-saga/effects';

// Services
import { CampaignService } from '@services';

/* Types */
import {
  GET_CAMPAIGN,
  GET_CAMPAIGN_REPORT,
  GET_CAMPAIGN_FINANCIAL_METRICS,
  GET_CAMPAIGN_FEED,
} from './types';

function* getCampaign(action) {
  try {
    const response = yield call(CampaignService.getCampaign, action.id);

    if (response.status === 200) {
      yield put({ type: GET_CAMPAIGN.SUCCESS, data: response.data });
    }
  } catch (e) {
    yield put({ type: GET_CAMPAIGN.FAILURE, data: e.response });
  }
}

function* getCampaignReport(action) {
  try {
    const response = yield call(CampaignService.getCampaignReport, action.id, action.permitted_group);

    if (response.status === 200) {
      yield put({ type: GET_CAMPAIGN_REPORT.SUCCESS, data: response.data });
    }
  } catch (e) {
    yield put({ type: GET_CAMPAIGN_REPORT.FAILURE, data: e.response });
  }
}

function* getCampaignFinancialMetrics(action) {
  try {
    const response = yield call(CampaignService.getCampaignFinancialMetrics, action.id, action.permitted_group);

    if (response.is_success) {
      yield put({ type: GET_CAMPAIGN_FINANCIAL_METRICS.SUCCESS, data: response.payload });
    }
  } catch (e) {
    yield put({ type: GET_CAMPAIGN_FINANCIAL_METRICS.FAILURE, data: e.response });
  }
}

function* getCampaignFeed(action) {
  try {
    const queryParams = {
      id: action?.id,
      unitId: action?.unitId,
      status: action?.status,
      permitted_group: action?.permitted_group
    };
    const response = yield call(CampaignService.getCampaignFeed, queryParams, action?.body?.paging);

    if (response?.is_success) {
      yield put({
        type: GET_CAMPAIGN_FEED.SUCCESS,
        data: response?.payload,
        queryParams,
        isFetchMore: !!action?.body?.paging ,
      });
    }
  } catch (e) {
    yield put({ type: GET_CAMPAIGN_FEED.FAILURE, data: e.response });
  }
}

export const campaignsSaga = [
  takeEvery(GET_CAMPAIGN.REQUEST, getCampaign),
  takeEvery(GET_CAMPAIGN_REPORT.REQUEST, getCampaignReport),
  takeEvery(GET_CAMPAIGN_FINANCIAL_METRICS.REQUEST, getCampaignFinancialMetrics),
  takeEvery(GET_CAMPAIGN_FEED.REQUEST, getCampaignFeed),
];
