import React from 'react';
import { defineds } from './consts';
import dayjs from 'dayjs';

import { useTranslate } from 'react-translate';
import { useSelector } from 'react-redux';

import { defaultInputRanges } from 'react-date-range';

import type { RootStateOrAny } from 'react-redux';
import type { Range, Preview } from 'react-date-range';
import type { ParcialStaticRange, StaticRange } from './types';

export function useRanges() {
  const t = useTranslate('DateRange');
  const { current, currentItem } = useSelector((state: RootStateOrAny) => state.genericReducer);

  const inputRanges = [
    {
      ...defaultInputRanges[0],
      label: t('DAYS-UP-TO-TODAY'),
    },
  ];

  const getStaticRanges = () => {
    function getCustomPeriod(): Preview {
      let startDate = dayjs().startOf('day').subtract(3, 'month');
      let endDate = dayjs();

      if (!!current.data?.total && !!current.data?.end_date) {
        startDate = dayjs(current.data.start_date);
        endDate = dayjs(current.data.end_date);
      } else if (!!currentItem?.data?.start_date && !!currentItem?.data?.end_date) {
        startDate = dayjs(currentItem.data.start_date);
        endDate = dayjs(currentItem.data.end_date);
      }

      return { startDate: startDate.toDate(), endDate: endDate.toDate() };
    }

    function createStaticRanges(ranges: ParcialStaticRange[]): StaticRange[] {
      return ranges.map((range) => ({
        ...range,
        isSelected: (receivedRange: Range) =>
          dayjs(receivedRange.startDate).isSame(range.range().startDate, 'day') &&
          dayjs(receivedRange.endDate).isSame(range.range().endDate, 'day'),
      }));
    }

    return createStaticRanges([
      {
        label: t('TODAY'),
        range: () => ({
          startDate: defineds.startOfToday,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: t('YESTERDAY'),
        range: () => ({
          startDate: defineds.startOfYesterday,
          endDate: defineds.endOfYesterday,
        }),
      },
      {
        label: t('LAST_7D'),
        range: () => ({
          startDate: defineds.sevenDaysAgo,
          endDate: defineds.endOfYesterday,
        }),
      },
      {
        label: t('THIS_MONTH'),
        range: () => ({
          startDate: defineds.startOfMonth,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: t('LAST_MONTH'),
        range: () => ({
          startDate: defineds.startOfLastMonth,
          endDate: defineds.endOfLastMonth,
        }),
      },
      {
        label: t('THIS_YEAR'),
        range: () => ({
          startDate: defineds.startOfYear,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: t('CUSTOM_PERIOD'),
        isCustomOption: true,
        range: getCustomPeriod,
      },
    ]);
  };

  return { inputRanges, staticRanges: getStaticRanges() };
}
