import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import {
  useGetCoverage,
  useGetInsights,
  useGetInvestmentsTips,
  useGetInvestmentSummary,
  useGetLocations,
} from 'utils/hooks/Trade';
import TradeService from 'services/trade';
import imageDefault from 'images';
import {
  checkIfIsTradeExperiment,
  checkIfIsTradeView,
  getCategoryKey,
  isMarketplaceTrade,
} from '../../helpers';
import {
  AppliedFiltersType,
  ContextTypes,
  DefaultFunctionType,
  GetReportDetailsType,
  LocationDataType,
  ManipulateFeedPostType,
  ParamsType,
  Period,
  ResponseType,
  FeedResponse,
} from 'containers/Trade/types';

const TradeInsightsContext = createContext<ContextTypes | null>(null);

export const TradeInsightsProvider = ({ children }: any) => {
  const [insights, setInsights] = useState<object | null>({});
  const [locations, setLocations] = useState<LocationDataType>();
  const [reportDetails, setReportDetails] = useState<object>([]);
  const [appliedFilters, setAppliedFilters] = useState<AppliedFiltersType>({
    investment: [],
    location: [],
  });
  const [investmentSummary, setInvestmentSummary] = useState<object | null>();
  const [investmentLocation, setInvestmentLocation] = useState<object | null>();
  const [period, setPeriod] = useState<Period>({ since: '', until: '' });
  const [selectedPeriod, setSelectedPeriod] = useState('LAST_THREE_MONTHS');
  const [filters, setFilters] = useState({ searchInvestment: '', searchLocation: '' });
  const [showProgress, setShowProgress] = useState(true);
  const [showContent, setShowContent] = useState(false);
  const [feedPosts, setFeedPosts] = useState({});
  const [hasError, setHasError] = useState(false);
  const [generalLoading, setGeneralLoading] = useState(true);
  const [feedLoading, setFeedLoading] = useState(false);
  const [mapLoading, setMapLoading] = useState(true);
  const [reportLoading, setReportLoading] = useState(true);
  const [highlightsLoading, setHighlightsLoading] = useState(false);
  const [updateController, setUpdateController] = useState(false);
  const [isReportVisible, setIsReportVisible] = useState(false);
  const [investmentType, setInvestmentType] = useState<Array<string>>([]);
  const [locationType, setLocationType] = useState<string>();
  const [ranking, setRanking] = useState<Array<object> | undefined>([]);
  const [rankingLoading, setRankingLoading] = useState(false);
  const loadings = {
    generalResults: generalLoading,
    feed: feedLoading,
    map: mapLoading,
    highlights: highlightsLoading,
    investChampions: generalLoading,
    report: reportLoading,
    ranking: rankingLoading,
  };
  const controller = useMemo(() => new AbortController(), [updateController]);

  useEffect(() => {
    const until = dayjs().format('YYYY-MM-DD');
    const since =
      !checkIfIsTradeView() && checkIfIsTradeExperiment()
        ? dayjs().subtract(1, 'month').format('YYYY-MM-DD')
        : dayjs().subtract(3, 'month').format('YYYY-MM-DD');
    setPeriod({ since, until });
  }, []);

  const locationsResponse = useGetLocations();
  const { data: investmentsTips } = useGetInvestmentsTips(period);
  const generalInsightsResponse = useGetInsights(period);
  const generalInvestmentSummary = useGetInvestmentSummary(period);
  const coverageResponse = useGetCoverage(period);
  const isMarketplace = isMarketplaceTrade();
  const manipulateLocations = useMemo(() => {
    const locationData: LocationDataType = { region: [], state: [], city: [] };

    if (locationsResponse?.data?.is_success) {
      const { payload } = locationsResponse.data;

      payload?.map((data) => {
        if (data.type) {
          locationData[data.type].push(data);
        }
      });
    }
    return locationData;
  }, [locationsResponse]);

  useEffect(() => {
    if (locationsResponse.data && !locationsResponse.isError && !locations?.region) {
      setLocations(manipulateLocations);
    }
    if (
      generalInsightsResponse.data &&
      !appliedFilters?.investment.length &&
      !appliedFilters?.location.length
    ) {
      setInsights(generalInsightsResponse.data);
      setGeneralLoading(false);
    }
    if (
      generalInvestmentSummary.data &&
      !appliedFilters?.investment.length &&
      !appliedFilters?.location.length
    ) {
      setInvestmentSummary(generalInvestmentSummary.data);
      setInvestmentLocation(generalInvestmentSummary.data);
      setMapLoading(false);
    }
  }, [locationsResponse, generalInsightsResponse, generalInvestmentSummary]);

  useEffect(() => {
    if (appliedFilters.investment.length || appliedFilters.location.length) {
      handleSelectOption({
        label: appliedFilters.investment[0]?.label || '',
        category: appliedFilters.investment[0]?.category_type || '',
        location_id: appliedFilters.location[0]?.id || undefined,
        location_type: appliedFilters.location[0]?.type || '',
        locationName: appliedFilters.location[0]?.label || '',
      });
    }
  }, [period]);
  const handleError = () => {
    controller.abort();
    setUpdateController(!updateController);
    return setHasError(true);
  };
  const handlePostFeedReview = async (params: { ad_id: string; user_review: string }) => {
    const { investment, location } = appliedFilters;
    const categoryKey = investment?.length ? getCategoryKey(investment[0].category_type) : '';

    const body: { [key: string]: string | number | undefined } = {
      since: period.since,
      until: period.until,
      ...params,
    };

    if (categoryKey) {
      body[categoryKey] = investment[0].label;
      body.type = investment[0].category_type;
    }
    if (location?.length) {
      body.location_id = location[0].id;
    }

    return await TradeService.postFeedReview(body);
  };

  const getFeedPosts = async (
    parameters: ParamsType,
    since = period.since,
    until = period.until
  ) => {
    setFeedPosts({});
    setFeedLoading(true);

    const queryParameters = { ...parameters, since, until, controller };

    const handleResponse = (response: FeedResponse) => {
      if (response.is_success) {
        if (isMarketplace) {
          const newPosts = manipulatePostFeed(response.payload?.data?.posts);

          const manipulatedData: FeedResponse = {
            ...response,
            payload: {
              ...response.payload,
              data: { posts: newPosts, related_words: response?.payload?.data.related_words },
            },
          };

          setFeedPosts(manipulatedData);
          return setFeedLoading(false);
        }
        setFeedLoading(false);
        return setFeedPosts(response);
      }
      return setFeedPosts({});
    };

    if (queryParameters?.label) {
      try {
        const response = await TradeService.getFeedPosts(queryParameters);
        return handleResponse(response);
      } catch (error) {
        setFeedPosts({});
      }
    }
  };

  const getInsightsInvestments: DefaultFunctionType = async (
    value,
    since = period.since,
    until = period.until
  ) => {
    const handleResponse = (response: ResponseType) => {
      const { payload, is_success } = response;
      if (!payload?.summary?.total_ads && !payload?.summary?.spend) {
        return handleError();
      }

      if (is_success) {
        setInsights(response);
        setGeneralLoading(false);
        return response;
      }
    };

    const queryParameters = { ...value, since, until, controller };

    try {
      setGeneralLoading(true);
      const response = await TradeService.getInsightsInvestments(queryParameters);
      return handleResponse(response);
    } catch (error) {
      return handleError();
    }
  };

  const getInvestmentSummary: DefaultFunctionType = async (
    value,
    since = period.since,
    until = period.until
  ) => {
    const handleResponse = (response: ResponseType) => {
      if (response.is_success) {
        setInvestmentSummary(response);
        setInvestmentLocation(response);
        setMapLoading(false);
        return setHighlightsLoading(false);
      }
    };

    const queryParameters = { ...value, since, until, controller };

    try {
      setMapLoading(true);
      setHighlightsLoading(true);
      const response = await TradeService.getSummary(queryParameters);
      return handleResponse(response);
    } catch (error) {
      return handleError();
    }
  };

  const getReportDetails: GetReportDetailsType = async (
    values,
    since = period.since,
    until = period.until
  ) => {
    const { type, word, location_id, location_type } = values;
    const queryParameters = {
      category: type[0],
      since,
      until,
      location_id,
      location_type,
      label: word,
    };
    const response = await TradeService.getReportDetails(queryParameters);
    if (response.is_success) {
      setInvestmentType(type);
      setLocationType(location_type);
      setReportDetails(response);
      setReportLoading(false);
    }
    return response;
  };

  const getRanking: DefaultFunctionType = useCallback(
    async (values, since = period.since, until = period.until) => {
      const { queryType, category, label, limit, location_id } = values;
      const queryParameters = {
        queryType,
        category,
        label,
        location_id,
        since,
        until,
        limit,
      };

      const handleResponse = (response: ResponseType) => {
        if (response.is_success) {
          if (isMarketplace) {
            const manipulatedRanking = response?.payload?.data?.map((unit, index) => {
              return {
                ...unit,
                unit_name: `Unidade ${index + 1}`,
              };
            });
            setRanking(manipulatedRanking);
            return setRankingLoading(false);
          }
          setRanking(response.payload?.data);
          return setRankingLoading(false);
        }
      };

      try {
        setRankingLoading(true);
        const response = await TradeService.getRanking(queryParameters);
        return handleResponse(response);
      } catch (error) {
        setRanking([]);
        return setRankingLoading(false);
      }
    },
    [isMarketplace, period.since, period.until]
  );
  const resetFilters = () => {
    setIsReportVisible(false);
    window.scrollTo({
      top: 0,
    });
    controller.abort();
    setUpdateController(!updateController);
    setAppliedFilters({ investment: [], location: [] });
    setFilters({ searchInvestment: '', searchLocation: '' });
    setGeneralLoading(true);
    setMapLoading(true);

    setTimeout(() => {
      setInsights(generalInsightsResponse.data);
      setInvestmentSummary(generalInvestmentSummary.data);
      setInvestmentLocation(generalInvestmentSummary.data);
      setFeedPosts({});
      setGeneralLoading(false);
      setMapLoading(false);
      setHasError(false);
    }, 150);
  };

  const handleSelectOption = (queryParameters: ParamsType) => {
    window.scrollTo({
      top: 0,
    });

    getFeedPosts(queryParameters);
    getInsightsInvestments(queryParameters, period.since, period.until);

    if (queryParameters?.location_type !== 'city') {
      getInvestmentSummary(queryParameters, period.since, period.until);
    }

    const location = {
      label: queryParameters?.locationName || '',
      id: queryParameters?.location_id || 0,
      type: queryParameters?.location_type || '',
    };

    const investment = {
      label: queryParameters?.label || '',
      category_type: queryParameters?.category || '',
    };

    setFilters({
      searchInvestment: investment?.label || '',
      searchLocation: location?.label || '',
    });

    setAppliedFilters({
      investment: investment?.label ? [investment] : [],
      location: location?.label ? [location] : [],
    });
  };

  const manipulatePostFeed: ManipulateFeedPostType = (data) => {
    if (!data || !data.length) return [];
    return data.map((post: object, index: number) => {
      return {
        ...post,
        description: `Post número ${index + 1}`,
        link_url: 'https://www.facebook.com/bornlogic',
        image_url: imageDefault,
      };
    });
  };

  return (
    <TradeInsightsContext.Provider
      value={{
        manipulatePostFeed,
        getReportDetails,
        getInsightsInvestments,
        locations,
        insights,
        reportDetails,
        setAppliedFilters,
        appliedFilters,
        getInvestmentSummary,
        investmentSummary,
        investmentLocation,
        period,
        setPeriod,
        resetFilters,
        investmentsTips,
        setFilters,
        filters,
        coverageResponse,
        setShowProgress,
        showProgress,
        setShowContent,
        showContent,
        getFeedPosts,
        feedPosts,
        hasError,
        setHasError,
        loadings,
        handleSelectOption,
        isReportVisible,
        setIsReportVisible,
        investmentType,
        locationType,
        setReportDetails,
        setReportLoading,
        controller,
        setInsights,
        setGeneralLoading,
        handlePostFeedReview,
        selectedPeriod,
        setSelectedPeriod,
        setMapLoading,
        getRanking,
        ranking,
        setRankingLoading,
      }}
    >
      {children}
    </TradeInsightsContext.Provider>
  );
};

export const useTradeInsights = () => {
  const context = useContext(TradeInsightsContext);

  if (!context) throw new Error('Expected to be wrapped in a Trade Provider');

  return context;
};
