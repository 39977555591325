import { CommonText } from '@utils/constants';

const { en, pt, es } = CommonText;

const TabsEN = {
  Tabs: {
    LEADS: 'Leads',
    ALL: 'All',
    GROUPS: 'Groups',
    ACCOUNTS: 'Accounts',
    PAGES: 'Pages',
    STRATEGIES: 'Strategies',
    'AGE-GENDER': 'Age and Gender',
    CHANNEL: 'Channel',
    DEVICE: 'Device',
    NEW: 'New',
    APPROVED: 'Approved',
    DISAPPROVED: 'Disapproved',
    CARD_MODE: 'See cards',
    LIST_MODE: 'See list',
    SWITCH_MODE: 'Switch between list mode and card mode!',
    LIBRARY: en.MEDIA_LIBRARY,
    TEMPLATES: 'Dynamic Creatives',
    UNITS: 'Units',
    CAMP: 'Campaigns',
    TRADEADS: 'Trade Ads',
    GDTV: 'GDTV',
    LIST: 'List',
    RESULTS: 'Results'
  },
};

const TabsPT = {
  Tabs: {
    LEADS: 'Leads',
    GROUPS: 'Grupos',
    ACCOUNTS: 'Contas',
    PAGES: 'Páginas',
    STRATEGIES: 'Estratégias',
    'AGE-GENDER': 'Idade e gênero',
    CHANNEL: 'Canal',
    DEVICE: 'Dispositivo',
    NEW: 'Novos',
    APPROVED: 'Aprovados',
    DISAPPROVED: 'Reprovados',
    CARD_MODE: 'Ver cartões',
    LIST_MODE: 'Ver lista',
    SWITCH_MODE: 'Alterne entre o modo lista e o modo cartão!',
    LIBRARY: pt.MEDIA_LIBRARY,
    TEMPLATES: 'Criativos Dinâmicos',
    UNITS: 'Unidades',
    CAMP: 'Campanhas de Incentivo',
    TRADEADS: 'Trade Ads',
    GDTV: 'GDTV',
    LIST: 'Listagem',
    RESULTS: 'Resultados'
  },
};

const TabsES = {
  Tabs: {
    LEADS: 'Leads',
    GROUPS: 'Grupos',
    ACCOUNTS: 'Cuentas',
    PAGES: 'Páginas',
    STRATEGIES: 'Estrategias',
    'AGE-GENDER': 'Edad y género',
    CHANNEL: 'Canal',
    DEVICE: 'Herramienta',
    NEW: 'Nuevos',
    APPROVED: 'Aprobado',
    DISAPPROVED: 'Fallido',
    CARD_MODE: 'Ver tarjetas',
    LIST_MODE: 'Ver lista',
    SWITCH_MODE: '¡Cambie entre el modo de lista y el modo de tarjeta!',
    LIBRARY: es.MEDIA_LIBRARY,
    TEMPLATES: 'Anuncio Dinámico',
    UNITS: 'Unidades',
    CAMP: 'Campañas de incentivos',
    TRADEADS: 'Trade Ads',
    GDTV: 'GDTV',
    TEMPLATES: 'Anuncio Dinámico',
    NEW: 'Nuevo',
    APPROVED: 'Aprobado',
    DISAPPROVED: 'Desaprobado',
    LIST: 'Listado',
    RESULTS: 'Resultados'
  },
};

export { TabsPT, TabsEN, TabsES };
