import styled from 'styled-components';

export const Container = styled.section`
  padding: ${({ theme }) => theme.spacing.px16} ${({ theme }) => theme.spacing.px32};
`;

export const CheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.px8};
  margin-top: ${({ theme }) => theme.spacing.px16};
`;
