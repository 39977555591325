import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Wrapper = styled.div`
  display: flex;
  margin-left: 8px;
  border-bottom: ${({ open }) => (open ? 'none' : '0.5px solid #cecece')};
  padding-top: 20px;
  padding-bottom: 20px;
  transition: all 0.5s ease-in-out;
  display: 'flex';
  margin-right: 10px;
`;

export const GoBackIcon = styled(FontAwesomeIcon)`
  cursor: pointer;

  ${({ theme }) => css`
    color: ${theme.colors.blueGrey8};
    margin-right: ${theme.spacing.px24};
    margin-top: ${theme.spacing.px2};
  `}
`;

export const Color = styled.div`
  width: 4px;
  height: ${({ open }) => (open ? '16px' : '40px')};
  background-color: ${({ color }) => color};
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 16px;
`;

export const Name = styled.div`
  text-align: left;
  color: #4c5564;
  font-size: ${({ open }) => (open ? '16px' : '14px')};
  font-weight: ${({ open }) => (open ? '600' : '400')};
  width: 90%;
`;

export const OpenChilds = styled.div`
  text-align: left;
  color: #187db5;
  font-size: 14px;
  cursor: pointer;
  display: ${({ open }) => (open ? 'none' : 'block')};
`;

export const CheckWrapper = styled.div`
  display: ${({ open }) => (open ? 'none' : 'flex')};
  margin-right: 10px;
`;
