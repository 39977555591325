import styled from 'styled-components';
import Select from 'react-select';
import { primaryTheme as theme } from '@styles/themes';

export const CustomModalContent = styled('div')`
  padding: ${theme.spacing.px60} 0 ${theme.spacing.px30};
`;

export const CustomSelect = styled(Select)`
  font-size: ${theme.fontSize.px14};
  margin-bottom: 20px;

  > div {
    border-radius: 8px;
    border: 1px solid ${theme.colors.grey};
  }

  &:hover {
    border: 0;
    outline: none;
  }
`;

export const InputDescription = styled('div')`
  display: block;
  @media screen and (max-width: ${theme.breakpoint.md}) {
    textarea {
      height: 70vh;
      font-size: ${theme.fontSize.px18};
    }
  }
`;

export const IntegrationWrapper = styled('div')`
  padding: 0 25px 10px 25px;

  label {
    display: block;
    margin-bottom: 10px;
    font-size: ${theme.fontSize.px14};
    font-weight: ${theme.fontWeight.bold};
  }
`;

export const TabSelector = styled('div')`
  display: flex;
  height: 50px;
  margin-bottom: ${(props) => props.theme.spacing.px24};
`;

export const TabItem = styled('div')`
  width: 20%;
  padding-top: ${(props) => props.theme.spacing.px12};
  margin-right: ${(props) => props.theme.spacing.px20};
  margin-left: ${(props) => props.theme.spacing.px12};
  padding: ${(props) => props.theme.spacing.px10};
  cursor: pointer;
  border-bottom: ${(props) =>
    props.selected === true ? '2px solid' + props.theme.colors.blue2 : 'none'};
`;

export const SectionWrapper = styled('div')`
  display: ${(props) => (props.show === true ? 'block' : 'none')};
`;

export const Loading = styled('p')`
  font-size: ${theme.fontSize.px16};
  padding: 30px 7px;
`;
