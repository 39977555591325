const OrganicPostsEN = {
  OrganicPosts: {
    CARD_TITLE: 'Organic Posts',
    CARD_SUBTITLE: 'A brief of your organic metrics',
    CARD_BUTTON_TEXT: 'More details',
    PAGE_FANS: 'Page fans',
    ORGANIC_IMPRESSIONS: 'Organic Impressions',
    ORGANIC_VIEWS: 'Organic Views',
    ORGANIC_REACTIONS: 'Organic Reactions',
    PAGE_FANS_TOOLTIP:
      'Indicates how many fans do you have in all Gerentes Digitais participant pages',
    ORGANIC_IMPRESSIONS_TOOLTIP:
      "Indicates how many times your contents were shown on the feed to your public without an investment on the publication. If it appears in different moments to the same person, another impression is counted. Be aware: for videos an impression is counted even if the video doesn't start.",
    ORGANIC_VIEWS_TOOLTIP:
      'Indicates how many organic (without investment) video views, calculated only after 3 seconds.',
    ORGANIC_REACTIONS_TOOLTIP: 'Indicates the amount of reactions submitted directly on your page',
  },
};

const OrganicPostsPT = {
  OrganicPosts: {
    CARD_TITLE: 'Postagens orgânicas',
    CARD_SUBTITLE: 'Resumo das suas métricas orgânicas',
    CARD_BUTTON_TEXT: 'Mais detalhes',
    PAGE_FANS: 'Fãs da Página',
    ORGANIC_IMPRESSIONS: 'Impressões orgânicas',
    ORGANIC_VIEWS: 'Visualizações orgânicas',
    ORGANIC_REACTIONS: 'Reações orgânicas',
    PAGE_FANS_TOOLTIP:
      'Mostra a quantidade de fãs nas suas páginas que participam de Gerentes Digitais.',
    ORGANIC_IMPRESSIONS_TOOLTIP:
      'Mostra a quantidade de vezes que os conteúdos foram exibidos no feed para o público, sem investimento na publicação. Caso o conteúdo apareça em diferentes momentos para a mesma pessoa, mais uma impressão é contada. Mas atenção: para vídeos a impressão é contada mesmo sem o vídeo ser iniciado.',
    ORGANIC_VIEWS_TOOLTIP:
      'Mostra a quantidade de visualizações orgânicas (sem verba investida) em vídeos, a partir de 3 segundos.',
    ORGANIC_REACTIONS_TOOLTIP: 'Mostra a quantidade de reações feitas diretas em sua página',
  },
};

const OrganicPostsES = {
  OrganicPosts: {
    CARD_TITLE: 'Publicaciones orgánicas',
    CARD_SUBTITLE: 'Resumen de sus resultados orgánicos',
    CARD_BUTTON_TEXT: 'Más detalles',
    PAGE_FANS: 'Fans de la página',
    ORGANIC_IMPRESSIONS: 'Impresiones orgánicas',
    ORGANIC_VIEWS: 'Visualizaciones orgánicas',
    ORGANIC_REACTIONS: 'Reações Orgânicas',
    PAGE_FANS_TOOLTIP:
      'Muestra la cantidad de fans en sus páginas que participan en Gerentes Digitais.',
    ORGANIC_IMPRESSIONS_TOOLTIP:
      'Muestra el número de veces que se mostró el contenido en el feed al público, sin inversión en publicación. Si el contenido aparece en diferentes momentos para la misma persona, se cuenta una impresión más. Pero cuidado: en el caso de los videos, la impresión se cuenta incluso sin que se haya iniciado el video.',
    ORGANIC_VIEWS_TOOLTIP:
      'Muestra la cantidad de vistas orgánicas (sin fondos invertidos) en videos, durante más de 3 segundos.',
    ORGANIC_REACTIONS_TOOLTIP: 'Muestra la cantidad de reacciones realizadas directamente en su página.',
  },
};

export { OrganicPostsEN, OrganicPostsPT, OrganicPostsES };
