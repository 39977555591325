const chartDetails = ({
  size,
  value,
  fontSize,
  color,
  backgroundColor,
  textMarginTop,
  backgroundLineColor,
  outerLine,
  innerLine,
}) => {
  const graphSize = size || 100;
  const data = Number(value);
  const title = `${value.toFixed()}%`;

  const chartConfigs = {
    chart: {
      fill: 'rgb(0,0,0)',
      type: 'solidgauge',
      height: graphSize,
      width: graphSize,
      backgroundColor: backgroundColor || '#fff',
    },
    title: {
      text: title,
      verticalAlign: 'middle',
      y: textMarginTop || 20,
      style: {
        fontWeight: 700,
        fontSize: fontSize || '16px',
        display: 'flex',
      },
    },

    tooltip: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    pane: {
      fill: 'black',
      center: ['50%', '50%'],
      startAngle: 0,
      endAngle: 360,
      background: [
        {
          outerRadius: outerLine,
          innerRadius: innerLine,
          backgroundColor: backgroundLineColor || '#ccc',

          borderWidth: 0,
        },
      ],
    },
    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      tickPositions: [],
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          enabled: false,
        },
        rounded: true,
      },
    },
    series: [
      {
        data: [
          {
            color: color || {
              linearGradient: { cx: 0.5, cy: 0.5, r: 0.5 },
              stops: [
                [0, '#591AAB'],
                [1, '#EB2387'],
              ],
            },
            radius: outerLine,
            innerRadius: innerLine,
            y: data,
          },
        ],
      },
    ],
  };

  return chartConfigs;
};

export { chartDetails };
