export const BlockUserModalEN = {
  BlockUserModal: {
    BLOCK_USER_MODAL_BLOCK: 'Block',
    BLOCK_USER_MODAL_CANCEL: 'Cancel',
    BLOCK_USER_MODAL_TITLE: 'Are you sure you want to block this user?',
    BLOCK_USER_MODAL_SUCCESS_MESSAGE: 'User blocked successfully.',
    BLOCK_USER_MODAL_ERROR_MESSAGE: 'Failed to block user.',
  },
};

export const BlockUserModalES = {
  BlockUserModal: {
    BLOCK_USER_MODAL_BLOCK: 'Bloquear',
    BLOCK_USER_MODAL_CANCEL: 'Cancelar',
    BLOCK_USER_MODAL_TITLE: '¿Estás seguro de que quieres bloquear a este usuario?',
    BLOCK_USER_MODAL_SUCCESS_MESSAGE: '¡Usuario bloqueado con éxito!',
    BLOCK_USER_MODAL_ERROR_MESSAGE: '¡No se pudo bloquear el usuario!',
  },
};

export const BlockUserModalPT = {
  BlockUserModal: {
    BLOCK_USER_MODAL_BLOCK: 'Bloquear',
    BLOCK_USER_MODAL_CANCEL: 'Cancelar',
    BLOCK_USER_MODAL_TITLE: 'Tem certeza de que deseja bloquear este usuário?',
    BLOCK_USER_MODAL_SUCCESS_MESSAGE: 'Usuário bloqueado com sucesso.',
    BLOCK_USER_MODAL_ERROR_MESSAGE: 'Erro ao bloquear usuário(a).',
  },
};
