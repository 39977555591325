import styled from 'styled-components';

export const PageOverlay = styled.div`
  position: fixed;
  left: ${({ theme }) => theme.spacing.none};
  right: ${({ theme }) => theme.spacing.none};
  top: ${({ theme }) => theme.spacing.none};
  bottom: ${({ theme }) => theme.spacing.none};
  width: ${({ theme }) => theme.spacing.full};
  height: ${({ theme }) => theme.spacing.full};
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
`;

export const ModalContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.rounded.px10};
  padding: ${({ theme }) => theme.spacing.px90} ${({ theme }) => theme.spacing.px50};
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.blueGrey2};
  margin: ${({ theme }) => theme.spacing.none} auto;
`;
