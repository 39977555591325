/* eslint-disable react/no-children-prop */
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { hasCapabilities, validateCapability } from '@utils/helpers';
import RedirectWithParams from './RedirectWithParams';
import { ReportMetricsProvider } from '@utils/contexts/ReportMetrics';
import { TradeInsightsProvider, GDScoreProvider, CampaignsProvider } from '@utils/contexts';
import { useSelector } from 'react-redux';

// Containers
import Dashboard from '@containers/Dashboard';
import { LoadingOld } from '../components/LoadingOld/LoadingOld';

const NewDashboard = lazy(() => import('@containers/Scope/Report/Dashboard/DashboardLoader'));
const ProfileSettings = lazy(() => import('@containers/ProfileSettings'));
const Users = lazy(() => import('@containers/Users'));
const Settings = lazy(() => import('@containers/Settings/UserTable/Settings'));
const Report = lazy(() => import('@containers/Scope/Report'));
const Budget = lazy(() => import('@containers/Scope/Budget'));
const MediasCenter = lazy(() => import('@containers/Scope/MediasCenter'));
const ProfileAndCover = lazy(() => import('@containers/Scope/ProfileAndCover'));
const ErrorPage = lazy(() => import('@containers/ErrorPage'));
const Medias = lazy(() => import('@containers/Scope/Medias'));
const MediaItem = lazy(() => import('@containers/Scope/Medias/Main/Library/Item'));
const GDTVItem = lazy(() => import('@containers/Scope/Medias/Main/GDTV/Item'));
const CampaignItem = lazy(() => import('@containers/Scope/Medias/Main/Campaigns/Item'));
const HelpCenter = lazy(() => import('@containers/HelpCenter'));
const ContactUs = lazy(() => import('@containers/HelpCenter/ContactUs'));
const Onboarding = lazy(() => import('@containers/Onboarding'));
const GDScore = lazy(() => import('@containers/GDScore'));
const GDScoreForm = lazy(() => import('@containers/GDScore/Register'));
const TrendsApp = lazy(() => import('@containers/Scope/Trends'));
const Planner = lazy(() => import('@containers/Scope/Planner'));
const Insights = lazy(() => import('@containers/Trade/Insights'));
const Tracking = lazy(() => import('@containers/Trade/Tracking'));
const PrivacyTermsLogged = lazy(() => import('@containers/PrivacyTerms/LoggedIn'));
const GMV = lazy(() => import('@containers/GMV'));
const Reports = lazy(() => import('@containers/Trade/Insights/Reports'));
const ReleaseNotesHistory = lazy(() => import('@containers/ReleaseNotesHistory'));
const PostsFeed = lazy(() => import('@containers/Trade/PostsFeed'));
const RankingReport = lazy(() => import('@containers/Trade/Insights/Reports/RankingReport'));
const DataInfo = lazy(() => import('@containers/Trade/DataInfo'));
const Extract = lazy(() => import('@containers/GMV/Extract'));
const Campaigns = lazy(() => import('@containers/Scope/Medias/Main/Campaigns'));
const CampaignsResult = lazy(() => import('@containers/Scope/Medias/Main/Campaigns/Results'));

function AuthenticatedComponents({}) {
  const { businessTokenData } = useSelector((state) => state.authenticationReducer);

  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    function blockRoutes() {
      if (
        (!validateCapability(businessTokenData, 'GDTV_MANAGE') &&
          pathname.split('/')[3] === 'Campaigns') ||
        (!validateCapability(businessTokenData, 'GDTV_MANAGE') &&
          pathname.split('/')[3] === 'GDTV') ||
        (!validateCapability(businessTokenData, 'REPORT') &&
          pathname.split('/')[3] === 'Overview') ||
        (!validateCapability(businessTokenData, 'GENERAL_LISTING') &&
          pathname.split('/')[3] === 'Listing') ||
        (!validateCapability(businessTokenData, 'ENGAGEMENT') &&
          pathname.split('/')[3] === 'Engagement') ||
        (!validateCapability(businessTokenData, 'VIDEO_VIEW') &&
          pathname.split('/')[3] === 'VideoView') ||
        (false && pathname.split('/')[3] === 'Units') ||
        (false && pathname.split('/')[3] === 'UserTable') ||
        (!validateCapability(businessTokenData, 'ACTIVITY') &&
          pathname.split('/')[3] === 'Activity') ||
        (!validateCapability(businessTokenData, 'REPORT_RANKING') &&
          pathname.split('/')[3] === 'Ranking') ||
        (!validateCapability(businessTokenData, 'REPORT_AUDIENCE') &&
          pathname.split('/')[3] === 'Audience') ||
        (!validateCapability(businessTokenData, 'REPORT_AUDIENCE') &&
          pathname.split('/')[3] === 'Custom') ||
        (!validateCapability(businessTokenData, 'CONVERSION_FLOW') &&
          pathname.split('/')[2] === 'ConversionFlow') ||
        (!validateCapability(businessTokenData, 'ADACCOUNT_BUDGET') &&
          pathname.split('/')[2] === 'Budget') ||
        (!validateCapability(businessTokenData, 'FEED') &&
          !validateCapability(businessTokenData, 'MEDIA_APPROVAL') &&
          pathname.split('/')[3] === 'Feed') ||
        (!validateCapability(businessTokenData, 'MEDIA_APPROVAL') &&
          !validateCapability(businessTokenData, 'FEED') &&
          pathname.split('/')[3] === 'Approvals') ||
        (!validateCapability(businessTokenData, 'USER_LISTING') &&
          pathname.split('/')[2] === 'Users') ||
        (!validateCapability(businessTokenData, 'USER_PROFILE') &&
          pathname.split('/')[2] === 'Me') ||
        (!validateCapability(businessTokenData, 'MEDIA_SETS') &&
          pathname.split('/')[2] === 'Communication' &&
          pathname.split('/')[3] === 'Main') ||
        (!validateCapability(businessTokenData, 'BATCH_UPDATE_PROFILE_COVER') &&
          pathname.split('/')[2] === 'Profile&Cover')
      ) {
        history.push(`/${pathname.split('/')[1]}/ErrorPage`);
      }
    }

    if (hasCapabilities(businessTokenData)) {
      blockRoutes();
    }
  }, [businessTokenData, history, pathname]);

  function Rules1(props) {
    const rule = props.location.pathname.split('/')[2];

    switch (rule) {
      case 'Report':
      case 'Budget':
      case 'Users':
      case 'Me':
      case 'Communication':
      case 'MediaItem':
      case 'GDTVItem':
      case 'CampaignItem':
      case 'ErrorPage':
      case 'Settings':
      case 'Dashboard':
      case 'Onboarding':
      case 'HelpCenter':
      case 'ContactUs':
      case 'Trade':
      case 'PrivacyTerms':
      case 'GMV':
      case 'Extract':
      case 'ReleaseNotes':
      case 'CI':
      case 'Medias':
      case 'Profile&Cover':
        return true;

      default:
        return false;
    }
  }

  function Rules2(props) {
    const rule = props.location.pathname.split('/')[4];

    switch (rule) {
      case 'Global':
      case 'ConversionFlow':
      case 'Leads':
      case 'Groups':
      case 'Accounts':
      case 'Pages':
      case 'Strategies':
      case 'Age&Gender':
      case 'Channel':
      case 'Device':
      case 'New':
      case 'Approved':
      case 'Disapproved':
      case 'All':
      case 'MediaItem':
      case 'GDTVItem':
      case 'Ranking':
      case '':
      case undefined:
        return true;

      default:
        return false;
    }
  }

  return (
    <Route
      exact
      path="/"
      children={(props) => (
        <>
          <RedirectWithParams {...props} to="/AuthLogin" />
          <Route
            exact
            path="/:code"
            children={(props) => (
              <>
                <RedirectWithParams {...props} to="/:code/Report" />
                <Route
                  exact
                  path="/:code/Report"
                  children={(props) => (
                    <>
                      <ReportMetricsProvider>
                        <RedirectWithParams {...props} to="/:code/Report/Overview" />
                        <Route
                          exact
                          path="/:code/Report/:module"
                          children={(props) => (
                            <>
                              <RedirectWithParams {...props} to="/:code/Report/Overview/Global" />
                              <Route
                                exact
                                path="/:code/Report/:module/:group"
                                render={(props) => (
                                  <Dashboard {...props} showButtonCalendar>
                                    <Suspense fallback={<LoadingOld isLoading />}>
                                      <Report {...props} />
                                    </Suspense>
                                  </Dashboard>
                                )}
                              />
                            </>
                          )}
                        />
                      </ReportMetricsProvider>
                    </>
                  )}
                />
                <Route
                  exact
                  path="/:code/Budget"
                  children={(props) => (
                    <>
                      <RedirectWithParams {...props} to="/:code/Budget/Listing" />
                      <Route
                        exact
                        path="/:code/Budget/:module"
                        children={() => (
                          <>
                            <Route
                              path="/:code/Budget/:module/:group"
                              render={(props) => (
                                <Dashboard {...props} showButtonCalendar>
                                  <Suspense fallback={<LoadingOld isLoading />}>
                                    <Budget {...props} />
                                  </Suspense>
                                </Dashboard>
                              )}
                            />
                          </>
                        )}
                      />
                    </>
                  )}
                />
                <ReportMetricsProvider>
                  <Route
                    exact
                    path="/:code/Dashboard"
                    render={(props) => (
                      <Dashboard {...props} noSidebar>
                        <Suspense fallback={<LoadingOld isLoading />}>
                          <NewDashboard {...props} />
                        </Suspense>
                      </Dashboard>
                    )}
                  />
                </ReportMetricsProvider>
                <Route
                  exact
                  path="/:code/Profile&Cover"
                  render={(props) => (
                    <Dashboard {...props} noSidebar>
                      <Suspense fallback={<LoadingOld isLoading />}>
                        <ProfileAndCover {...props} />
                      </Suspense>
                    </Dashboard>
                  )}
                />

                <Route
                  exact
                  path="/:code/Medias"
                  children={() => (
                    <>
                      <Route
                        exact
                        path="/:code/Medias/:module"
                        render={(props) => (
                          <>
                            {props.match.params.module === 'Approvals' ? (
                              <RedirectWithParams {...props} to="/:code/Medias/Approvals/New" />
                            ) : (
                              <Dashboard {...props} showButtonCalendar>
                                <Suspense fallback={<LoadingOld isLoading />}>
                                  <MediasCenter {...props} />
                                </Suspense>
                              </Dashboard>
                            )}
                          </>
                        )}
                      />
                      <Route
                        exact
                        path="/:code/Medias/:module/:group"
                        render={(props) =>
                          props.match.params.group ? (
                            <Dashboard {...props} showButtonCalendar>
                              <Suspense fallback={<LoadingOld isLoading />}>
                                <MediasCenter {...props} />
                              </Suspense>
                            </Dashboard>
                          ) : (
                            <RedirectWithParams {...props} to="/:code/Medias/Approvals/New" />
                          )
                        }
                      />
                      <RedirectWithParams {...props} to="/:code/Medias/Approvals/New" />
                    </>
                  )}
                />

                <ReportMetricsProvider>
                  <Route
                    exact
                    path="/:code/Communication"
                    children={() => (
                      <>
                        <Route
                          exact
                          path="/:code/Communication/:module"
                          render={(props) => (
                            <>
                              <Dashboard {...props} noSidebar showButtonCalendar>
                                <Suspense fallback={<LoadingOld isLoading />}>
                                  <Medias {...props} />
                                </Suspense>
                              </Dashboard>
                            </>
                          )}
                        />
                      </>
                    )}
                  />
                </ReportMetricsProvider>
                <Route
                  exact
                  path="/:code/MediaItem/"
                  children={() => (
                    <>
                      <Route
                        exact
                        path="/:code/MediaItem/:module"
                        render={(props) => (
                          <Dashboard noSidebar {...props}>
                            <Suspense fallback={<LoadingOld isLoading />}>
                              <MediaItem {...props} />
                            </Suspense>
                          </Dashboard>
                        )}
                      />
                    </>
                  )}
                />
                <Route
                  exact
                  path="/:code/GDTVItem/"
                  children={() => (
                    <>
                      <Route
                        exact
                        path="/:code/GDTVItem/:module"
                        render={(props) => (
                          <Dashboard noSidebar {...props}>
                            <Suspense fallback={<LoadingOld isLoading />}>
                              <GDTVItem {...props} />
                            </Suspense>
                          </Dashboard>
                        )}
                      />
                    </>
                  )}
                />

                <ReportMetricsProvider>
                  <Route
                    exact
                    path="/:code/CampaignItem/"
                    children={() => (
                      <>
                        <Route
                          exact
                          path="/:code/CampaignItem/:module"
                          render={(props) => (
                            <Dashboard noSidebar {...props}>
                              <Suspense fallback={<LoadingOld isLoading />}>
                                <CampaignItem {...props} />
                              </Suspense>
                            </Dashboard>
                          )}
                        />
                      </>
                    )}
                  />
                </ReportMetricsProvider>
                <Route
                  exact
                  path="/:code/Me"
                  render={(props) => (
                    <Dashboard noSidebar noTabs {...props} hideBreadcrumb>
                      <Suspense fallback={<LoadingOld isLoading />}>
                        <ProfileSettings {...props} />
                      </Suspense>
                    </Dashboard>
                  )}
                />
                <Route
                  exact
                  path="/:code/Users"
                  children={(props) => (
                    <>
                      <RedirectWithParams {...props} to="/:code/Users/Management" />
                      <Route
                        path="/:code/Users/:module"
                        render={(props) => (
                          <Dashboard {...props}>
                            <Suspense fallback={<LoadingOld isLoading />}>
                              <Users {...props} />
                            </Suspense>
                          </Dashboard>
                        )}
                      />
                    </>
                  )}
                />
                <Route
                  exact
                  path="/:code/Settings"
                  children={(props) => (
                    <>
                      <RedirectWithParams {...props} to="/:code/Settings/Units" />
                      <ReportMetricsProvider>
                        <Route
                          exact
                          path="/:code/Settings/:module"
                          render={(props) => (
                            <Dashboard {...props} showButtonCalendar>
                              <Suspense fallback={<LoadingOld isLoading />}>
                                <Settings {...props} />
                              </Suspense>
                            </Dashboard>
                          )}
                        />
                      </ReportMetricsProvider>
                    </>
                  )}
                />
                <Route
                  exact
                  path="/:code/ErrorPage"
                  render={(props) => (
                    <>
                      <Dashboard noSidebar noTabs {...props} hideBreadcrumb>
                        <Suspense fallback={<LoadingOld isLoading />}>
                          <ErrorPage {...props} />
                        </Suspense>
                      </Dashboard>
                    </>
                  )}
                />
                <Route
                  exact
                  path="/:code/*"
                  children={(props) => (
                    <>
                      {!(Rules1(props) && Rules2(props)) ? (
                        <RedirectWithParams {...props} to="/:code/ErrorPage" />
                      ) : null}
                    </>
                  )}
                />
                <Route
                  exact
                  path="/:code/Onboarding"
                  children={(props) => (
                    <>
                      <RedirectWithParams {...props} to="/:code/Onboarding" />
                      <Route
                        exact
                        path="/:code/Onboarding"
                        render={(props) => (
                          <Dashboard {...props}>
                            <Suspense fallback={<LoadingOld isLoading />}>
                              <Onboarding {...props} />
                            </Suspense>
                          </Dashboard>
                        )}
                      />
                    </>
                  )}
                />
                <Route
                  exact
                  path="/:code/HelpCenter"
                  render={(props) => (
                    <>
                      <Dashboard noSidebar {...props} hideBreadcrumb>
                        <Suspense fallback={<LoadingOld isLoading />}>
                          <HelpCenter {...props} />
                        </Suspense>
                      </Dashboard>
                    </>
                  )}
                />
                <Route
                  exact
                  path="/:code/ContactUs"
                  render={(props) => (
                    <>
                      <Dashboard noSidebar {...props} hideBreadcrumb>
                        <Suspense fallback={<LoadingOld isLoading />}>
                          <ContactUs {...props} />
                        </Suspense>
                      </Dashboard>
                    </>
                  )}
                />
                <Route
                  exact
                  path="/:code/GMV"
                  render={() => (
                    <>
                      <Dashboard>
                        <Suspense fallback={<LoadingOld isLoading />}>
                          <GMV />
                        </Suspense>
                      </Dashboard>
                    </>
                  )}
                />
                <Route
                  exact
                  path="/:code/GMV/Extract"
                  render={() => (
                    <>
                      <Dashboard>
                        <Suspense fallback={<LoadingOld isLoading />}>
                          <Extract />
                        </Suspense>
                      </Dashboard>
                    </>
                  )}
                />
                <Route
                  exact
                  path="/:code/PrivacyTerms"
                  render={(props) => (
                    <>
                      <Dashboard noSidebar {...props} hideBreadcrumb>
                        <Suspense fallback={<LoadingOld isLoading />}>
                          <PrivacyTermsLogged {...props} />
                        </Suspense>
                      </Dashboard>
                    </>
                  )}
                />
                <Route
                  exact
                  path="/:code/ReleaseNotes"
                  render={(props) => (
                    <>
                      <Dashboard noSidebar {...props} hideBreadcrumb>
                        <Suspense fallback={<LoadingOld isLoading />}>
                          <ReleaseNotesHistory />
                        </Suspense>
                      </Dashboard>
                    </>
                  )}
                />
                <GDScoreProvider>
                  <Route
                    exact
                    path="/:code/GDScore"
                    children={(props) => (
                      <>
                        <RedirectWithParams {...props} to="/:code/GDScore" />
                        <Route
                          exact
                          path="/:code/GDScore"
                          render={(props) => (
                            <Dashboard {...props}>
                              <Suspense fallback={<LoadingOld isLoading />}>
                                <GDScore {...props} />
                              </Suspense>
                            </Dashboard>
                          )}
                        />
                      </>
                    )}
                  />
                  <Route
                    path="/:code/GDScore/cadastrar"
                    render={() => (
                      <>
                        <Route
                          exact
                          path="/:code/GDScore/cadastrar/:id"
                          render={(props) => (
                            <Dashboard {...props}>
                              <Suspense fallback={<LoadingOld isLoading />}>
                                <GDScoreForm {...props} />
                              </Suspense>
                            </Dashboard>
                          )}
                        />
                        <Route
                          exact
                          path="/:code/GDScore/cadastrar"
                          render={(props) => (
                            <Dashboard {...props}>
                              <Suspense fallback={<LoadingOld isLoading />}>
                                <GDScoreForm {...props} />
                              </Suspense>
                            </Dashboard>
                          )}
                        />
                      </>
                    )}
                  />
                </GDScoreProvider>
                <Route
                  exact
                  path="/:code/Trends"
                  children={(props) => (
                    <>
                      <RedirectWithParams {...props} to="/:code/Trends" />
                      <Route
                        exact
                        path="/:code/Trends"
                        render={(props) => (
                          <Dashboard {...props}>
                            <Suspense fallback={<LoadingOld isLoading />}>
                              <TrendsApp {...props} />
                            </Suspense>
                          </Dashboard>
                        )}
                      />
                    </>
                  )}
                />
                <Route
                  exact
                  path="/:code/Planner"
                  children={(props) => (
                    <>
                      <RedirectWithParams {...props} to="/:code/Planner" />
                      <Route
                        exact
                        path="/:code/Planner"
                        render={(props) => (
                          <Dashboard {...props} shouldNotAddMargin>
                            <Suspense fallback={<LoadingOld isLoading />}>
                              <Planner {...props} />
                            </Suspense>
                          </Dashboard>
                        )}
                      />
                    </>
                  )}
                />
                <TradeInsightsProvider>
                  <Route
                    exact
                    path="/:code/Trade/Tracking"
                    children={(props) => (
                      <>
                        <RedirectWithParams {...props} to="/:code/Trade/Tracking" />
                        <Route
                          exact
                          path="/:code/Trade/Tracking"
                          render={(props) => (
                            <Dashboard {...props} noTabs>
                              <Suspense fallback={<LoadingOld isLoading />}>
                                <Tracking />
                              </Suspense>
                            </Dashboard>
                          )}
                        />
                      </>
                    )}
                  />
                  <Route
                    exact
                    path="/:code/Trade/Dashboard"
                    children={(props) => (
                      <>
                        <RedirectWithParams {...props} to="/:code/Trade/Dashboard" />
                        <Route
                          exact
                          path="/:code/Trade/Dashboard"
                          render={(props) => (
                            <Dashboard {...props} noTabs>
                              <Suspense fallback={<LoadingOld isLoading />}>
                                <Insights />
                              </Suspense>
                            </Dashboard>
                          )}
                        />
                      </>
                    )}
                  />
                  <Route
                    exact
                    path="/:code/Trade/Report"
                    children={() => (
                      <>
                        <Route
                          exact
                          path="/:code/Trade/Report"
                          render={(props) => (
                            <Dashboard {...props} noSidebar noTabs>
                              <Suspense fallback={<LoadingOld isLoading />}>
                                <Reports />
                              </Suspense>
                            </Dashboard>
                          )}
                        />
                      </>
                    )}
                  />
                  <Route
                    exact
                    path="/:code/Trade/Report/Ranking"
                    children={() => (
                      <Route
                        exact
                        path="/:code/Trade/Report/Ranking"
                        render={(props) => (
                          <Dashboard {...props} noSidebar noTabs>
                            <Suspense fallback={<LoadingOld isLoading />}>
                              <RankingReport />
                            </Suspense>
                          </Dashboard>
                        )}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/:code/Trade/PostsFeed"
                    children={() => (
                      <Route
                        exact
                        path="/:code/Trade/PostsFeed"
                        render={(props) => (
                          <Dashboard {...props} noSidebar noTabs>
                            <Suspense fallback={<LoadingOld isLoading />}>
                              <PostsFeed />
                            </Suspense>
                          </Dashboard>
                        )}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/:code/Trade/DataInfo"
                    children={() => (
                      <Route
                        exact
                        path="/:code/Trade/DataInfo"
                        render={(props) => (
                          <Dashboard {...props} noSidebar noTabs>
                            <Suspense fallback={<LoadingOld isLoading />}>
                              <DataInfo />
                            </Suspense>
                          </Dashboard>
                        )}
                      />
                    )}
                  />
                </TradeInsightsProvider>
                <ReportMetricsProvider>
                  <CampaignsProvider>
                    <Route
                      exact
                      path="/:code/CI"
                      children={() => (
                        <>
                          <Route
                            exact
                            path="/:code/CI"
                            render={(props) => (
                              <>
                                <Dashboard {...props} noSidebar showButtonCalendar>
                                  <Suspense fallback={<LoadingOld isLoading />}>
                                    <Campaigns {...props} />
                                  </Suspense>
                                </Dashboard>
                              </>
                            )}
                          />

                          <Route
                            exact
                            path="/:code/CI/Results"
                            render={(props) => (
                              <>
                                <Dashboard {...props} noSidebar showButtonCalendar>
                                  <Suspense fallback={<LoadingOld isLoading />}>
                                    <CampaignsResult {...props} />
                                  </Suspense>
                                </Dashboard>
                              </>
                            )}
                          />
                        </>
                      )}
                    />
                  </CampaignsProvider>
                </ReportMetricsProvider>
              </>
            )}
          />
        </>
      )}
    />
  );
}

export default AuthenticatedComponents;
