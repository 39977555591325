import React, { FC } from 'react';
import { Button } from '@bornlogic/reborn';
import { MixPanelController } from '../../../../controllers';

import type { ButtonProps } from '@bornlogic/reborn';

interface PropsType extends ButtonProps {
  eventName?: string;
  className?: string;
}

const ButtonWithMixPanel: FC<PropsType> = ({
  eventName,
  onClick,
  className,
  children,
  ...rest
}): JSX.Element => {
  function onClickWithEvent(event: React.MouseEvent<Element, MouseEvent>) {
    if (eventName) {
      const mixPanel = new MixPanelController({ eventName, eventType: 'click' });
      mixPanel.createEvent();
    }

    if (onClick) {
      onClick(event);
    }
  }

  return (
    <div className={className} data-testid="buttonWithMixPanel">
      <Button onClick={onClickWithEvent} {...rest}>
        {children}
      </Button>
    </div>
  );
};

export default ButtonWithMixPanel;
