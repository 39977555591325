import { EventsService } from '../services';
import { store } from '../providers';
import platform from 'platform';
import { createBrowserHistory } from 'history';

class MixPanelController {
  origin = 'WEB';
  service = EventsService;
  history = createBrowserHistory();
  userData = null;
  publicData = {
    $browser: platform.name,
    $browser_version: platform.version,
    $os: platform.os.toString(),
    language: window.navigator.language,
  };

  constructor({ eventName, eventType }) {
    this.getUserData();

    this.eventName = eventName;
    this.publicPayload = {
      origin: this.origin,
      values: {
        ...this.publicData,
        type: eventType,
      },
    };
  }

  getUserData() {
    const { authenticationReducer: auth } = store.getState();
    if (auth?.businessToken) {
      this.userData = {
        user_id: auth?.dataUser?.user_id,
        user_role: auth?.dataUser?.role || auth?.userTokenData?.role,
        $email: auth?.dataUser?.email || auth?.userTokenData?.email,
        $name: `${auth?.dataUser?.user_name} ${auth?.dataUser?.user_last_name}`,
        tenant_id: auth?.subDomain?.id,
        tenant_name: auth?.subDomain?.name,
      };
    }
  }

  getPayload() {
    if (this?.userData) {
      return {
        event: this.eventName,
        origin: this.origin,
        values: { ...this.publicPayload.values, ...this.userData },
      };
    }
    return {
      event: this.eventName,
      ...this.publicPayload,
    };
  }

  getUnityId() {
    const hash = this.history?.location?.hash;
    const [_, unityId] = hash?.split('/');
    return unityId;
  }

  async createEvent() {
    // removing mixpanel event creation for the moment
    return;
    const payload = this.getPayload();
    const unityId = this.getUnityId();

    if (Boolean(this?.userData)) {
      return this.service.createMixPanelEventWithTenant({ data: payload, unityId });
    }
    return this.service.createPublicMixPanelEvent(payload);
  }
}

export default MixPanelController;
