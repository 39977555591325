const TradeInsightsEN = {
  TradeInsights: {
    GREETING: 'Hello, ',
    CHECK_MSG: 'Check the results for {{company}} in the last 3 months.',
    APPLY_FILTER_MSG: 'Apply a filter to explore more data and boost your fundraising strategy!',
    APPLY_FILTER: 'Apply filters',
    DISCLAIME_MSG:
      'All data and information presented here are collected with the express authorization of the contracting parties of the GerentesDigitais platform.',
    LOCATION_FILTER_PLACEHOLDER: 'City, state or region (Brazil only)',
    INVESTMENT_FILTER_PLACEHOLDER: 'Product, category or brand',
    APPLY: 'Apply',
    INVEST_TITLE: 'Invested in the period',
    INVESTED: 'Invested',
    INVEST_TOOLTIP: 'Amount spent on ads in the analyzed period.',
    INVEST_SUB_MSG: '{{value}}% of total available',
    ADS_TITLE: 'Total ads created',
    ADS_TOOLTIP: 'Amount of paid ads created by units during the selected period.',
    PERIOD_MSG: 'in the period',
    CPM_TOOLTIP:
      "The number of impressions shows the number of times the ad was shown in the feed to the audience. If it appears at different times for the same person, more than one impression is counted. CPM (cost per thousand) calculates the price per thousand ad impressions. Its formula is: how much you've invested, divided by the number of impressions and multiplied by 1,000.",
    CPM_SUB_MSG: '{{value}}% in period relative to its average',
    ENGAGEMENT_TITLE: 'Engagement',
    ENGAGEMENT_TOOLTIP:
      'Shows the sum of reactions, comments and shares, photo or video view, link click or order in offers.',
    ENGAGEMENT_SUB_MSG: '{{value}}% of engagement from all your ads.',
    ENGAGEMENT_RATE_TITLE: 'Engagement rate',
    ENGAGEMENT_RATE_TOOLTIP:
      'Percentage of people engaged in published content. Its formula is: number of engagements divided by impressions, multiplied by 100.',
    CPE_TOOLTIP:
      'Cost per Engagement: Calculates the amount invested for each engagement. Its formula is: amount invested divided by engagement.',
    CPE_SUB_MSG: 'CPM of {{value}}',
    IDENTIFIED_CATEGORIES_TOOLTIP: 'Number of categories identified in the selected period.',
    IDENTIFIED_BRANDS_TOOLTIP: 'Number of brands identified in the selected period.',
    IDENTIFIED_PRODUCTS_TOOLTIP: 'Number of products identified in the selected period.',
    IDENTIFIED_REGIONS_TOOLTIP: 'Number of regions identified in the selected period.',
    IDENTIFIED_STATES_TOOLTIP: 'Number of states identified in the selected period.',
    IDENTIFIED_CITIES_TOOLTIP: 'Number of cities identified in the selected period.',
    INVESTMENT_BRAND: 'for the brand ',
    INVESTMENT_PRODUCT: 'for the product ',
    INVESTMENT_CATEGORY: 'for the category ',
    LOCATION_REGION: ' in region ',
    LOCATION_STATE: ' in state ',
    LOCATION_CITY: ' in the city of ',
    IN_ADS: 'in ads',
    GENERAL_RESULTS: 'Content performance',
    BROADCASTED: ' broadcasted',
    SEE_FULL_REPORT: 'See full report',
    NO_DATA_FOUND: 'No data found.',
    BRANDS: 'brand',
    CATEGORIES: 'category',
    PRODUCTS: 'product',
    STATE: 'State',
    CITY: 'City',
    REGION: 'Region',
    INVEST_CHAMPIONS: 'Investment Opportunity',
    INVEST_CHAMPIONS_SUB: 'Check out the content created by your most invested sellers.',
    INVEST_COLUMN_CATEGORIES: 'Categories ',
    INVEST_COLUMN_BRANDS: 'Brands ',
    INVEST_COLUMN_PRODUCTS: 'Products ',
    INVEST_DETAILS_MODAL: 'View more',
    INVESTMENT: 'Investment',
    CLICKS: 'Clicks',
    LINK_CLICKED: 'Link clicked',
    ADS_CREATED: 'Ads created',
    IMPRESSION: 'Impressions',
    IDENTIFIED_CATEGORIES: 'Identified categories',
    IDENTIFIED_PRODUCTS: 'Identified products',
    IDENTIFIED_BRANDS: 'Identified brands',
    IDENTIFIED_REGIONS: 'Identified regions',
    IDENTIFIED_STATE: 'Identified states',
    IDENTIFIED_CITIES: 'Identified cities',
    REPORT_INVEST_TITLE: 'Investment',
    REPORT_INVEST_IN_TITLE: 'Investment in',
    REPORT_INVEST_TITLE_BY_STATE: 'Ad investment by state in region',
    REPORT_INVEST_TITLE_BY_CITY: 'Ad investment by city in state',
    ADS_MAP: 'ADS',
    MAP_TITLE_NO_FILTER: 'Results by region',
    MAP_TITLE_WITH_FILTER: 'Distribution by region of investments for ',
    MAP_SUB_TITLE: 'See where your content is getting the most investment.',
    BRANDS_INVEST: 'who invested the most in the brand ',
    PRODUCTS_INVEST: 'who invested the most in the product ',
    CATEGORIES_INVEST: 'who invested the most in the category ',
    STATES: 'Estados',
    CITIES: 'Cities',
    IN_CONTENT: 'who invested the most in content',
    TOTAL_UNITIES: 'Total units',
    THREE_MONTHS_INVESTIMENT: 'Invested in the last 3 months',
    INVEST_OPPORTUNITIES: 'Opportunities for Engagement',
    INVEST_OPPORTUNITIES_SUB:
      'Check out products, categories and brands with better engagement rates that aren’t getting as much investment:',
    INVESTMENT_WITHOUT_OPPORTUNITIES: 'Opportunities without investment',
    INVESTMENT_WITHOUT_OPPORTUNITIES_SUB:
      'By not investing in organic content created, you cannot reach potential customers and may be losing sales.',
    INVESTMENT_WITHOUT_OPPORTUNITIES_INFO: 'of content without investment',
    FUNDRAISING_ASSISTANT: 'Fundraising assistant',
    LOCATION_TOOLTIP_ONE:
      'Check out the content created by your salespeople who received the most investment.',
    LOCATION_TOOLTIP_TWO: 'See where your content is getting the most investment.',
    DATA_CAPACITY: 'Data capacity',
    INFO_LOADING_TITLE: 'Ranked content:',
    INFO_LOADING_SUB_DOWN: 'Low prediction and analysis capacity',
    INFO_LOADING_SUB_MID: 'Reasonable prediction and analysis capability',
    INFO_LOADING_SUB_UP: 'Considerable prediction and analysis capability',
    INFO_LOADING_DETAIL_ONE_DOWN:
      "That means we can't find keywords in most of the content created by their digital managers.",
    INFO_LOADING_DETAIL_ONE_MID:
      'This means that we were able to find keywords in a moderate amount of your content but there is still a great opportunity to improve your ranking',
    INFO_LOADING_DETAIL_ONE_UP:
      'That means we were able to find keywords in most of your content. Keep looking to increase the rating to reach 100%',
    INFO_LOADING_DETAIL_TWO:
      'All the insights provided below will be related to the percentage of rated content and not the totality of your created content.',
    INFO_LOADING_LINK: 'Understand how to improve this index',
    INFO_LOADING_CONTINUE: 'Continue to trends',
    FEED_TITLE: 'How are your customers seeing your ads',
    FEED_SUB_TITLE: 'See the most engaged ads created by your units, related to your search',
    BADGE_WARNING: 'This content will no longer appear with this filter.',
    BADGE_BTN: 'Undo',
    LAST_SEVEN_DAYS: 'Last 7 days',
    LAST_FIFTEEN_DAYS: 'Last 15 days',
    LAST_THIRTY_DAYS: 'Last 30 days',
    LAST_THREE_MONTHS: 'Last 3 months',
    RANKING_TITLE: 'Champion performance units',
    RANKING_REPORT_TITLE: 'Unit performance',
    RANKING_SUBTITLE: '',
    REVENUE: 'Revenue',
    UNITY: 'Unity',
    POSITION: 'Position',
    ROAS: 'ROAS',
    SALES_AMOUNT: 'Sales amount',
    EXPORT_CSV: 'Download csv',
    SHARE: 'Share',
    CREATE_TRADE_CAMPAIGN_BTN: 'Create trade campaign',
    CREATE_CAMPAIGN_BTN: 'Create campaign',
    REPORT_SEARCH_PLACEHOLDER: 'Do a search',
    ITEMS_PER_PAGE: 'Items per page',
    TRADE_EXPERIMENT_TITLE: 'Cycle insights',
    TRADE_EXPERIMENT_SUBTITLE_1:
      'Check out the categories, brands, and products that are receiving the most ad spend.',
    TRADE_EXPERIMENT_SUBTITLE_2: 'Take the opportunity to ',
    TRADE_EXPERIMENT_SUBTITLE_3:
      'create campaigns to target your creators and boost these results even more!',
    TRADE_EXPERIMENT_WARNING:
      'Important: The Insights session will be available on your home screen for the next 15 days. Any questions, please contact our customer service.',
    TRADE_EXPERIMENT_INFO:
      '*Data referring to ads created that contain valid hashtags for ranking.',
    NEW: 'New!',
    TRADE_EXPERIMENT_FEEDBACK: 'Did you find it helpful to receive this kind of information?',
    TRADE_EXPERIMENT_CONTACT_SOON: 'Soon we will contact you!',
    TRADE_EXPERIMENT_CONTACT_SOON_INFO:
      'Soon a specialist from our team will contact you to better explain how the collection and availability of strategic data for your company works. Hold!',
    TRADE_EXPERIMENT_CONTACT_SOON_OK: 'Ok, I got it.',
    TRADE_EXPERIMENT_MODAL_TITLE: 'Insights to help your strategy!',
    TRADE_EXPERIMENT_MODAL_INFO:
      'We are bringing unprecedented data and analytics about your ads to help you fundraise with your partners and improve the performance of your content on the networks.',
    TRADE_EXPERIMENT_QUESTION: 'Would you like to know more about it?',
    TRADE_EXPERIMENT_NOT_NOW: 'Not now',
    TRADE_EXPERIMENT_WANTS_TO_KNOW_MORE: 'I want to know more',
    LOADING_MESSAGE_ONE: 'Rating your contents...',
    LOADING_MESSAGE_TWO: "Identifying keywords... we're almost there!",
    DATA_CAPACITY_TOOLTIP: 'Understand better how this percentage is calculated',
    THIS_MONTH: 'This month',
    LAST_MONTH: 'Last month',
    ADS_DATA_CAPACITY: 'Ads Data Capacity',
    VIEW_MORE_BUTTON: 'View more',
    DATA_CAPACITY_LOW: 'Low',
    DATA_CAPACITY_REASONABLE: 'Reasonable',
    DATA_CAPACITY_OPTIMAL: 'Excellent',
    ONLINE_REVENUE: 'Online revenue',
    OFFLINE_REVENUE: 'Offline revenue',
    ENGAGEMENTS: 'Engagements',
    CREATED_CONTENT: 'Created content',
    CREATED_ADS: 'Created ads',
    CLASSIFIED_ADS: 'classified ads',
    CLASSIFIED_POSTS: 'Posts without investment',
    CLASSIFICATION: 'Classification',
    GENERAL_CLASSIFICATION: 'Ads general classification',
    TO: 'to',
    CLASSIFIED_POSTS_INFO:
      'Organic publications mean setbacks. Encourage the creation of ads and increase your collection.',
    WARNING_MESSAGE: 'Your sales data is not linked.',
    DO_NOT_SHOW: "Don't show anymore",
    INCLUDE_DATA: 'Include data',
    OF_THE_TOTAL: 'of the total',
    REVENUE_TOOLTIP: 'Total amount of online and offline revenue for the period.',
    INVESTED_TOOLTIP: 'Amount invested in advertisements for the period.',
    SALES_AMOUNT_TOOLTIP: 'Total amount of online and offline sales for the period.',
    ONLINE_REVENUE_TOOLTIP: 'Revenue accounted for by online sales data.',
    OFFLINE_REVENUE_TOOLTIP: 'Revenue accounted for by offline sales data.',
    IMPRESSIONS_TOOLTIP: 'Number of times the ad was shown in the feed to the audience.',
    CLASSIFIED_ADS_TOOLTIP:
      'Number of classified ads based on hashtags and keywords in the caption.',
    CLASSIFIED_POSTS_TOOLTIP:
      'Amount of content without investment ranked based on hashtags and keywords in the caption.',
    DATA_CAPACITY_INFO_TOOLTIP:
      'Ratio between the sum of all content containing hashtags or keywords that could be identified by our ranking system, and the content that could not be classified.',
    REGION_ADS_CREATED_TOOLTIP:
      'Total boosted ads created in the region, including those with and without hashtags or keywords that could be identified by our ranking system.',
    STATE_ADS_CREATED_TOOLTIP:
      'Total boosted ads created in the State, including those with and without hashtags or keywords that could be identified by our ranking system.',
    CITY_ADS_CREATED_TOOLTIP:
      'Total boosted ads created in the city, including those with and without hashtags or keywords that could be identified by our ranking system.',
    INCLUDE_SALES_DATA: 'Include sales data',
    INVESTED_AMOUNT: 'Invested amount',
  },
};

const TradeInsightsPT = {
  TradeInsights: {
    GREETING: 'Olá, ',
    CHECK_MSG: 'Confira os resultados de {{company}} nos últimos 3 meses.',
    APPLY_FILTER_MSG:
      'Aplique um filtro para explorar mais dados e dê um gás na sua estratégia de arrecadação!',
    APPLY_FILTER: 'Aplicar filtros',
    DISCLAIME_MSG:
      'Todos os dados e informações aqui apresentados são coletados com expressa autorização dos contratantes da plataforma GerentesDigitais.',
    LOCATION_FILTER_PLACEHOLDER: 'Cidade, estado ou região',
    INVESTMENT_FILTER_PLACEHOLDER: 'Produto, categoria ou marca',
    APPLY: 'Aplicar',
    INVEST_TITLE: 'Investido no periodo',
    INVESTED: 'Investido',

    INVEST_TOOLTIP: 'Valor gasto em anúncios no período analisado.',
    INVEST_SUB_MSG: '{{value}}% do total disponível',
    ADS_TITLE: 'Total de anúncios criados',
    ADS_TOOLTIP:
      'Quantidade de anúncios pagos criados pelas unidades durante o período selecionado.',
    PERIOD_MSG: 'no periodo',
    CPM_TOOLTIP:
      'O número de impressões mostra a quantidade de vezes que o anúncio foi exibido no feed para o público. Caso apareça em diferentes momentos para a mesma pessoa, mais de uma impressão é contabilizada. O CPM (custo por mil) calcula o preço a cada mil impressões de anúncio. Sua fórmula é: quanto já investiu, dividido pelo número de impressões e multiplicado por 1.000.',
    CPM_SUB_MSG: 'CPM de {{value}}',
    ENGAGEMENT_TITLE: 'Engajamento',
    ENGAGEMENT_TOOLTIP:
      'Mostra a soma de reações, comentários e compartilhamentos, visualização de foto ou vídeo, clique em link ou pedido em ofertas.',
    ENGAGEMENT_SUB_MSG: '{{value}}% do engajamento de todos os seus anúncios.',
    ENGAGEMENT_RATE_TITLE: 'Taxa de engajamento',
    ENGAGEMENT_RATE_TOOLTIP:
      'Porcentagem de pessoas engajadas no conteúdo publicado. Sua fórmula é: número de engajamentos dividido por impressões, multiplicado por 100.',
    CPE_TOOLTIP:
      'Custo por engajamento: calcula o valor investido para cada engajamento. Sua fórmula é: valor investido dividido por engajamento.',
    CPE_SUB_MSG: '{{value}}% no período em relação à sua média',
    IDENTIFIED_CATEGORIES_TOOLTIP: 'Número de categorias identificadas no período selecionado.',
    IDENTIFIED_BRANDS_TOOLTIP: 'Número de marcas identificadas no período selecionado.',
    IDENTIFIED_PRODUCTS_TOOLTIP: 'Número de produtos identificados no período selecionado.',
    IDENTIFIED_REGIONS_TOOLTIP: 'Número de regiões identificadas no período selecionado.',
    IDENTIFIED_STATES_TOOLTIP: 'Número de estados identificados no período selecionado.',
    IDENTIFIED_CITIES_TOOLTIP: 'Número de cidades identificadas no período selecionado.',
    INVESTMENT_BRAND: 'para a marca ',
    INVESTMENT_PRODUCT: 'para o produto ',
    INVESTMENT_CATEGORY: 'para a categoria ',
    LOCATION_REGION: ' na região ',
    LOCATION_STATE: ' no estado ',
    LOCATION_CITY: ' na cidade de ',
    LOCATION_CITY_ALT: ' na cidade ',
    IN_ADS: 'em anúncios',
    GENERAL_RESULTS: 'Performance dos conteúdos',
    BROADCASTED: ' veiculados',
    SEE_FULL_REPORT: 'Ver relatório completo',
    NO_DATA_FOUND: 'Nenhum dado encontrado.',
    BRANDS: 'marca ',
    CATEGORIES: 'categoria ',
    PRODUCTS: 'produto ',
    STATE: 'Estado',
    CITY: 'Cidade',
    REGION: 'Região',
    INVEST_CHAMPIONS: 'Oportunidade de Investimentos',
    INVEST_CHAMPIONS_SUB:
      'Confira os conteúdos criados por seus vendedores que mais receberam investimento.',
    INVEST_COLUMN_CATEGORIES: 'Categorias ',
    INVEST_COLUMN_BRANDS: 'Marcas ',
    INVEST_COLUMN_PRODUCTS: 'Produtos ',
    INVEST_DETAILS_MODAL: 'Ver mais',
    INVESTMENT: 'Investimento',
    CLICKS: 'Cliques',
    LINK_CLICKED: 'Links clicados',
    ADS_CREATED: 'Anúncios criados',
    IMPRESSION: 'Impressões',
    IDENTIFIED_CATEGORIES: 'Categorias identificadas',
    IDENTIFIED_PRODUCTS: 'Produtos identificados',
    IDENTIFIED_BRANDS: 'Marcas identificadas',
    IDENTIFIED_REGIONS: 'Regiões identificadas',
    IDENTIFIED_STATE: 'Estados identificados',
    IDENTIFIED_CITIES: 'Cidades identificadas',
    REPORT_INVEST_TITLE: 'Investimento',
    REPORT_INVEST_IN_TITLE: 'Investimento em',
    REPORT_INVEST_TITLE_BY_STATE: 'Investimento em anúncios por estado na região',
    REPORT_INVEST_TITLE_BY_CITY: 'Investimento em anúncios por cidade no estado',
    ADS_MAP: 'Anúncios',

    MAP_TITLE_NO_FILTER: 'Resultados por região',
    MAP_TITLE_WITH_FILTER: 'Distribuição por região dos investimentos para ',
    MAP_SUB_TITLE: 'Confira onde seus conteúdos estão recebendo mais investimento.',
    BRANDS_INVEST: 'que mais investiram na marca ',
    PRODUCTS_INVEST: 'que mais investiram no produto ',
    CATEGORIES_INVEST: 'que mais investiram na categoria ',
    STATES: 'Estados',
    CITIES: 'Cidades',
    IN_CONTENT: 'que mais investiram em conteúdo',
    TOTAL_UNITIES: 'Total de unidades',
    THREE_MONTHS_INVESTIMENT: 'Investido nos últimos 3 meses',
    INVEST_OPPORTUNITIES: 'Oportunidades de engajamento',
    INVEST_OPPORTUNITIES_SUB:
      'Confira produtos, categorias e marcas com melhores taxas de engajamento e que não estão recebendo tanto investimento:',
    INVESTMENT_WITHOUT_OPPORTUNITIES: 'Oportunidades sem investimento',
    INVESTMENT_WITHOUT_OPPORTUNITIES_SUB:
      'Não investindo nos conteúdos orgânicos criados, você não consegue alcançar potenciais clientes e pode estar perdendo vendas.',
    INVESTMENT_WITHOUT_OPPORTUNITIES_INFO: 'de conteúdos sem investimento',
    FUNDRAISING_ASSISTANT: 'Assistente de arrecadação',
    LOCATION_TOOLTIP_ONE:
      'Confira os conteúdos criados por seus vendedores que mais receberam investimento.',
    LOCATION_TOOLTIP_TWO: 'Confira onde seus conteúdos estão recebendo mais investimento.',
    DATA_CAPACITY: 'Capacidade de Dados',
    INFO_LOADING_TITLE: 'Conteúdos classificados:',
    INFO_LOADING_SUB_DOWN: 'Capacidade de predição e análise baixa',
    INFO_LOADING_SUB_MID: 'Capacidade de predição e análise razoável',
    INFO_LOADING_SUB_UP: 'Capacidade de predição e análise considerável',
    INFO_LOADING_DETAIL_ONE_DOWN:
      'Isso significa que não conseguimos encontrar palavras-chave na maioria dos conteúdos criados por seus gerentes digitais.',
    INFO_LOADING_DETAIL_ONE_MID:
      'Isso significa que conseguimos encontrar palavras-chave em uma quantidade moderada de seus conteúdos mas ainda existe uma grande oportunidade de melhorar sua classificação',
    INFO_LOADING_DETAIL_ONE_UP:
      'Isso significa que conseguimos encontrar palavras-chave na maioria de seus conteúdos. Continue buscando aumentar a classificação para atingir os 100%',
    INFO_LOADING_DETAIL_TWO:
      'Todos os insights fornecidos a seguir serão relativos à porcentagem de conteúdos classificados e não à totalidade dos seus conteúdos criados.',
    INFO_LOADING_LINK: 'Entenda como melhorar esse índice',
    INFO_LOADING_CONTINUE: 'Continuar para tendências',
    FEED_TITLE: 'Como seus clientes estão vendo seus anúncios',
    FEED_SUB_TITLE:
      'Veja os anúncios com maior engajamento veiculados pelas suas unidades, relacionados à sua busca',
    BADGE_WARNING: 'Esse conteúdo não vai mais aparecer com esse filtro.',
    BADGE_BTN: 'Desfazer',
    LAST_SEVEN_DAYS: 'Últimos 7 dias',
    LAST_FIFTEEN_DAYS: 'Últimos 15 dias',
    LAST_THIRTY_DAYS: 'Últimos 30 dias',
    LAST_THREE_MONTHS: 'Últimos 3 meses',
    RANKING_TITLE: 'Unidades campeãs de performance',
    RANKING_REPORT_TITLE: 'Performance das unidades',
    RANKING_SUBTITLE: '',
    REVENUE: 'Receita',
    UNITY: 'Unidade',
    POSITION: 'Posição',
    ROAS: 'ROAS',
    SALES_AMOUNT: 'Volume de vendas',
    EXPORT_CSV: 'Baixar csv',
    SHARE: 'Compartilhar',
    CREATE_TRADE_CAMPAIGN_BTN: 'Criar campanha de trade',
    CREATE_CAMPAIGN_BTN: 'Criar campanha',
    REPORT_SEARCH_PLACEHOLDER: 'Faça uma pesquisa',
    ITEMS_PER_PAGE: 'Itens por página:',
    TRADE_EXPERIMENT_TITLE: 'Insights do ciclo',
    TRADE_EXPERIMENT_SUBTITLE_1:
      'Confira as categorias, marcas e produtos que mais estão recebendo investimento em anúncios.',
    TRADE_EXPERIMENT_SUBTITLE_2: 'Aproveite para ',
    TRADE_EXPERIMENT_SUBTITLE_3:
      'criar campanhas para direcionar seus criadores e impulsionar ainda mais esses resultados!',
    TRADE_EXPERIMENT_WARNING:
      'Importante: a sessão de Insights ficará disponível na sua tela inicial pelos próximos 15 dias. Qualquer dúvida, entre em contato com nosso atendimento.',
    TRADE_EXPERIMENT_INFO:
      '*Dados referentes aos anúncios criados que contém hashtags válidas para classificação.',
    NEW: 'Novidade!',
    TRADE_EXPERIMENT_FEEDBACK: 'Você achou útil receber esse tipo de informação?',
    TRADE_EXPERIMENT_CONTACT_SOON: 'Em breve entraremos em contato!',
    TRADE_EXPERIMENT_CONTACT_SOON_INFO:
      'Logo um especialista da nossa equipe vai entrar em contato para explicar melhor como funciona a coleta e disponibilização dos dados estratégicos para a sua empresa. Aguarde!',
    TRADE_EXPERIMENT_CONTACT_SOON_OK: 'Ok, entendi.',
    TRADE_EXPERIMENT_MODAL_TITLE: 'Insights para ajudar na sua estratégia!',
    TRADE_EXPERIMENT_MODAL_INFO:
      'Estamos trazendo dados e análises inéditas sobre seus anúncios, para ajudar na captação de recursos com seus parceiros e melhorar a performance dos seus conteúdos nas redes.',
    TRADE_EXPERIMENT_QUESTION: 'Gostaria de saber mais a respeito?',
    TRADE_EXPERIMENT_NOT_NOW: 'Agora não',
    TRADE_EXPERIMENT_WANTS_TO_KNOW_MORE: 'Quero saber mais',
    LOADING_MESSAGE_ONE: 'Classificando seus conteúdos...',
    LOADING_MESSAGE_TWO: 'Identificando palavras-chave... estamos quase lá!',
    DATA_CAPACITY_TOOLTIP: 'Entenda melhor como esse percentual é calculado',
    THIS_MONTH: 'Este mês',
    LAST_MONTH: 'Último mês',
    ADS_DATA_CAPACITY: 'Capacidade de Dados de Anúncios',
    VIEW_MORE_BUTTON: 'Ver mais',
    DATA_CAPACITY_LOW: 'Baixo',
    DATA_CAPACITY_REASONABLE: 'Razoável',
    DATA_CAPACITY_OPTIMAL: 'Ótimo',
    ONLINE_REVENUE: 'Receita online',
    OFFLINE_REVENUE: 'Receita offline',
    ENGAGEMENTS: 'Engajamentos',
    CREATED_CONTENT: 'Conteúdos criados',
    CREATED_ADS: 'Anúncios criados',
    CLASSIFIED_ADS: 'anúncios classificados',
    CLASSIFIED_POSTS: 'Publicações sem investimento',
    CLASSIFICATION: 'Classificação',
    GENERAL_CLASSIFICATION: 'Classificação geral de anúncios',
    TO: 'para',
    CLASSIFIED_POSTS_INFO:
      'Publicações orgânicas significam verba parada. Incentive a criação de anúncios e aumente sua arrecadação.',
    WARNING_MESSAGE: 'Seus dados de venda não estão vinculados.',
    DO_NOT_SHOW: 'Não mostrar mais',
    INCLUDE_DATA: 'Incluir dados',
    OF_THE_TOTAL: 'do total',
    REVENUE_TOOLTIP: 'Valor total de receita online e offine do período.',
    INVESTED_TOOLTIP: 'Valor investido nos anúncios do período.',
    SALES_AMOUNT_TOOLTIP: 'Número total de vendas online e offine do período.',
    ONLINE_REVENUE_TOOLTIP: 'Receita contabilizada pelos dados de vendas online.',
    OFFLINE_REVENUE_TOOLTIP: 'Receita contabilizada pelos dados de vendas offline.',
    IMPRESSIONS_TOOLTIP: 'Quantidade de vezes que o anúncio foi exibido no feed para o público.',
    CLASSIFIED_ADS_TOOLTIP:
      'Quantidade de anúncios classificados a partir de hashtags e palavras-chave na legenda.',
    CLASSIFIED_POSTS_TOOLTIP:
      'Quantidade de conteúdos sem investimento classificados a partir de hashtags e palavras-chave na legenda.',
    DATA_CAPACITY_INFO_TOOLTIP:
      'Relação entre a soma de todos os conteúdos que contém hashtags ou palavras-chave que puderam ser identificadas pelo nosso sistema de classificação, e os conteúdos que não puderam ser classificados.',
    REGION_ADS_CREATED_TOOLTIP:
      'Total de anúncios impulsionados criados na região, incluindo os que tinham e os que não tinham hashtags ou palavras-chave que puderam ser identificadas pelo nosso sistema de classificação.',
    STATE_ADS_CREATED_TOOLTIP:
      'Total de anúncios impulsionados criados no Estado, incluindo os que tinham e os que não tinham hashtags ou palavras-chave que puderam ser identificadas pelo nosso sistema de classificação.',
    CITY_ADS_CREATED_TOOLTIP:
      'Total de anúncios impulsionados criados na cidade, incluindo os que tinham e os que não tinham hashtags ou palavras-chave que puderam ser identificadas pelo nosso sistema de classificação.',
    INCLUDE_SALES_DATA: 'Incluir dados de vendas',
    INVESTED_AMOUNT: 'Valor investido',
  },
};

const TradeInsightsES = {
  TradeInsights: {
    GREETING: '¡Holá, ',
    CHECK_MSG: 'Consulte los resultados de {{company}} en los últimos 3 meses.',
    APPLY_FILTER_MSG:
      '¡Aplica un filtro para explorar más datos y potenciar tu estrategia de recaudación de fondos!',
    APPLY_FILTER: 'Aplicar filtros',
    DISCLAIME_MSG:
      'Todos los datos e información aquí presentados son recabados con autorización expresa de los contratantes de la plataforma GerentesDigitais.',
    LOCATION_FILTER_PLACEHOLDER: 'Ciudad, estado o región (solo Brasil)',
    INVESTMENT_FILTER_PLACEHOLDER: 'Producto, categoría o marca',
    APPLY: 'Aplicar',
    INVEST_TITLE: 'Invertido en el período',
    INVESTED: 'Invertido',
    INVEST_TOOLTIP: 'Cantidad gastada en anuncios en el período analizado.',
    INVEST_SUB_MSG: '{{value}}% del total disponible',
    ADS_TITLE: 'Total de anuncios creados',
    ADS_TOOLTIP:
      'Cantidad de anuncios pagados creados por unidades durante el período seleccionado.',
    PERIOD_MSG: 'en período',
    CPM_TOOLTIP:
      'La cantidad de impresiones muestra la cantidad de veces que se mostró el anuncio en el feed a la audiencia. Si aparece en diferentes momentos para la misma persona, se cuenta más de una impresión. CPM (costo por mil) calcula el precio por cada mil impresiones de anuncios. Su fórmula es: cuánto has invertido, dividido por el número de impresiones y multiplicado por 1.000.',
    CPM_SUB_MSG: 'CPM de {{value}}',
    ENGAGEMENT_TITLE: 'Interacción',
    ENGAGEMENT_TOOLTIP:
      'Muestra la suma de reacciones, comentarios y acciones compartidas, vista de foto o video, clic en enlace o pedido en ofertas',
    ENGAGEMENT_SUB_MSG: '{{valor}}% de participación de todos sus anuncios.',

    ENGAGEMENT_RATE_TITLE: 'Tasa de participación',
    ENGAGEMENT_RATE_TOOLTIP:
      'Porcentaje de personas comprometidas con el contenido publicado. Su fórmula es: número de compromisos dividido por impresiones, multiplicado por 100.',
    CPE_TOOLTIP:
      'Coste por compromiso: Calcula la cantidad invertida para cada compromiso. Su fórmula es: cantidad invertida dividida por compromiso.',
    CPE_SUB_MSG: '{{value}}% en el período relativo a su promedio',
    IDENTIFIED_CATEGORIES_TOOLTIP: 'Número de categorías identificadas en el período seleccionado.',
    IDENTIFIED_BRANDS_TOOLTIP: 'Número de marcas identificadas en el período seleccionado.',
    IDENTIFIED_PRODUCTS_TOOLTIP: 'Número de productos identificadas en el período seleccionado.',
    IDENTIFIED_REGIONS_TOOLTIP: 'Número de regiones identificadas en el período seleccionado.',
    IDENTIFIED_STATES_TOOLTIP: 'Número de estados identificados en el período seleccionado.',
    IDENTIFIED_CITIES_TOOLTIP: 'Número de ciudades identificadas en el período seleccionado.',
    INVESTMENT_BRAND: 'para la marca ',
    INVESTMENT_PRODUCT: 'para el producto ',
    INVESTMENT_CATEGORY: 'para la categoría ',
    LOCATION_REGION: ' en la región ',
    LOCATION_STATE: ' en estado ',
    LOCATION_CITY: ' en la ciudad ',
    IN_ADS: 'en anuncios',
    GENERAL_RESULTS: 'Rendimiento del contenido',
    BROADCASTED: ' transmitido',
    SEE_FULL_REPORT: 'Ver informe completo',
    NO_DATA_FOUND: 'Datos no encontrados.',
    BRANDS: 'marca ',
    CATEGORIES: 'categoría ',
    PRODUCTS: 'producto ',
    STATE: 'Estado',
    CITY: 'Ciudad',
    REGION: 'Región',
    INVEST_CHAMPIONS: 'Oportunidad de Inversión',
    INVEST_CHAMPIONS_SUB:
      'Vea el contenido creado por sus vendedores que recibieron la mayor inversión.',
    INVEST_COLUMN_CATEGORIES: 'Categorías ',
    INVEST_COLUMN_BRANDS: 'Marcas ',
    INVEST_COLUMN_PRODUCTS: 'Productos ',
    INVEST_DETAILS_MODAL: 'Ver más',
    INVESTMENT: 'Inversión',
    CLICKS: 'Clics',
    LINK_CLICKED: 'Clic en el enlace',
    ADS_CREATED: 'Anuncios creados',
    IMPRESSION: 'Impresiones',
    IDENTIFIED_CATEGORIES: 'Categorías identificadas',
    IDENTIFIED_PRODUCTS: 'Productos identificados',
    IDENTIFIED_BRANDS: 'Marcas identificadas',
    IDENTIFIED_REGIONS: 'Regiones identificadas',
    IDENTIFIED_STATE: 'Estados identificados',
    IDENTIFIED_CITIES: 'Ciudades identificadas',
    REPORT_INVEST_TITLE: 'Inversión',
    REPORT_INVEST_IN_TITLE: 'Inversión en',
    REPORT_INVEST_TITLE_BY_STATE: 'Inversión publicitaria por estado en la región',
    REPORT_INVEST_TITLE_BY_CITY: 'Inversión publicitaria por ciudad en el estado',
    ADS_MAP: 'Anuncios',
    MAP_TITLE_NO_FILTER: 'Resultados por región',
    MAP_TITLE_WITH_FILTER: 'Distribución por región de inversiones para ',
    MAP_SUB_TITLE: 'Vea dónde su contenido obtiene la mayor inversión.',
    BRANDS_INVEST: 'quién invirtió más en la marca ',
    PRODUCTS_INVEST: 'quién invirtió más en el producto ',
    CATEGORIES_INVEST: 'quién invirtió más en la categoría ',
    STATES: 'Estados',
    CITIES: 'ciudades',
    IN_CONTENT: 'quién invirtió más en contenido',
    TOTAL_UNITIES: 'Unidades totales',
    THREE_MONTHS_INVESTIMENT: 'Invertido en los últimos 3 meses',
    INVEST_OPPORTUNITIES: 'Oportunidades de participación',
    INVEST_OPPORTUNITIES_SUB:
      'Consulte productos, categorías y marcas con mejores tasas de participación que no reciben tanta inversión:',
    INVESTMENT_WITHOUT_OPPORTUNITIES: 'Opotunidades sin inversión',
    INVESTMENT_WITHOUT_OPPORTUNITIES_SUB:
      'Al no invertir en contenido orgánico creado, no puedes llegar a clientes potenciales y puedes estar perdiendo ventas.',
    INVESTMENT_WITHOUT_OPPORTUNITIES_INFO: 'de contenido sin inversión',
    FUNDRAISING_ASSISTANT: 'Asistente de financiación',
    LOCATION_TOOLTIP_ONE:
      'Vea el contenido creado por sus vendedores que recibieron la mayor inversión.',
    LOCATION_TOOLTIP_TWO: 'Vea dónde su contenido obtiene la mayor inversión.',
    DATA_CAPACITY: 'Capacidad de datos',
    INFO_LOADING_TITLE: 'Contenido clasificado:',
    INFO_LOADING_SUB_DOWN: 'Baja capacidad de predicción y análisis',
    INFO_LOADING_SUB_MID: 'Capacidad razonable de predicción y análisis',
    INFO_LOADING_SUB_UP: 'Considerable capacidad de predicción y análisis',
    INFO_LOADING_DETAIL_ONE_DOWN:
      'Eso significa que no podemos encontrar palabras clave en la mayoría del contenido creado por sus administradores digitales.',
    INFO_LOADING_DETAIL_ONE_MID:
      'Esto significa que pudimos encontrar palabras clave en una cantidad moderada de su contenido, pero todavía hay una gran oportunidad para mejorar su clasificación',
    INFO_LOADING_DETAIL_ONE_UP:
      'Eso significa que pudimos encontrar palabras clave en la mayor parte de su contenido. Seguir buscando aumentar la calificación para llegar al 100%',
    INFO_LOADING_DETAIL_TWO:
      'Todos los conocimientos proporcionados a continuación estarán relacionados con el porcentaje de contenido calificado y no con la totalidad de su contenido creado.',
    INFO_LOADING_LINK: 'Entender cómo mejorar este índice',
    INFO_LOADING_CONTINUE: 'Continuar a las tendencias',
    FEED_TITLE: '¿Cómo ven sus clientes sus anuncios?',
    FEED_SUB_TITLE:
      'Vea los anuncios más comprometidos publicados por sus unidades, relacionados con su búsqueda',
    BADGE_WARNING: 'Este contenido ya no aparecerá con este filtro.',
    BADGE_BTN: 'Deshacer',
    LAST_SEVEN_DAYS: 'Últimos 7 días',
    LAST_FIFTEEN_DAYS: 'Últimos 15 días',
    LAST_THIRTY_DAYS: 'Últimos 30 días',
    LAST_THREE_MONTHS: 'Últimos 3 meses',
    RANKING_TITLE: 'Unidades de rendimiento campeón',
    RANKING_REPORT_TITLE: 'Rendimiento de la unidad',
    RANKING_SUBTITLE: '',
    REVENUE: 'Ingresos',
    UNITY: 'Unidad',
    POSITION: 'Posición',
    ROAS: 'ROAS',
    SALES_AMOUNT: 'Volumen de ventas',
    EXPORT_CSV: 'Descargar csv',
    SHARE: 'Compartir',
    CREATE_TRADE_CAMPAIGN_BTN: 'Crear campaña de trade',
    CREATE_CAMPAIGN_BTN: 'Crear campaña',
    REPORT_SEARCH_PLACEHOLDER: 'Hacer una búsqueda',
    ITEMS_PER_PAGE: 'Elementos por página:',
    TRADE_EXPERIMENT_TITLE: 'Insights del ciclo',
    TRADE_EXPERIMENT_SUBTITLE_1:
      'Consulte las categorías, marcas y productos que reciben la mayor inversión publicitaria.',
    TRADE_EXPERIMENT_SUBTITLE_2: '¡Aprovecha la oportunidad de ',
    TRADE_EXPERIMENT_SUBTITLE_3:
      'crear campañas dirigidas a tus creadores y potencia aún más estos resultados!',
    TRADE_EXPERIMENT_WARNING:
      'Importante: La sesión de Insights estará disponible en su pantalla de inicio durante los próximos 15 días. Cualquier pregunta, por favor póngase en contacto con nuestro servicio de atención al cliente.',
    TRADE_EXPERIMENT_INFO:
      '*Datos referentes a anuncios creados que contienen hashtags válidos para el ranking.',
    NEW: '¡Nuevo!',
    TRADE_EXPERIMENT_FEEDBACK: '¿Le resultó útil recibir este tipo de información?',
    TRADE_EXPERIMENT_CONTACT_SOON: '¡Pronto nos pondremos en contacto con usted!',
    TRADE_EXPERIMENT_CONTACT_SOON_INFO:
      'Próximamente un especialista de nuestro equipo se comunicará contigo para explicarte mejor cómo funciona la recolección y disponibilidad de datos estratégicos para tu empresa. ¡Mantener!',
    TRADE_EXPERIMENT_CONTACT_SOON_OK: 'Ok, entendí.',
    TRADE_EXPERIMENT_MODAL_TITLE: '¡Perspectivas para ayudar a su estrategia!',
    TRADE_EXPERIMENT_MODAL_INFO:
      'Estamos brindando datos y análisis sin precedentes sobre sus anuncios para ayudarlo a recaudar fondos con sus socios y mejorar el rendimiento de su contenido en las redes.',
    TRADE_EXPERIMENT_QUESTION: '¿Te gustaría saber más al respecto?',
    TRADE_EXPERIMENT_NOT_NOW: 'Ahora no',
    TRADE_EXPERIMENT_WANTS_TO_KNOW_MORE: 'Quiero saber más',
    LOADING_MESSAGE_ONE: 'Calificación de su contenido...',
    LOADING_MESSAGE_TWO: 'Identificando palabras clave... ¡casi listos!',
    DATA_CAPACITY_TOOLTIP: 'Entender mejor cómo se calcula este porcentaje',
    THIS_MONTH: 'Este mes',
    LAST_MONTH: 'Último mes',
    ADS_DATA_CAPACITY: 'Capacidad de Datos de Anuncios',
    VIEW_MORE_BUTTON: 'Ver más',
    DATA_CAPACITY_LOW: 'Bajo',
    DATA_CAPACITY_REASONABLE: 'Razonable',
    DATA_CAPACITY_OPTIMAL: 'Excelente',
    ONLINE_REVENUE: 'Ingresos en línea',
    OFFLINE_REVENUE: 'Ingresos sin conexión',
    ENGAGEMENTS: 'Participación',
    CREATED_CONTENT: 'Contenidos creados',
    CREATED_ADS: 'Anuncios creados',
    CLASSIFIED_ADS: 'anuncios clasificados',
    CLASSIFIED_POSTS: 'Publicaciones sin inversión',
    CLASSIFICATION: 'Clasificación',
    GENERAL_CLASSIFICATION: 'Clasificación general de anuncios',
    TO: 'para',
    CLASSIFIED_POSTS_INFO:
      'Las publicaciones orgánicas significan retrocesos. Fomenta la creación de anuncios y aumenta tu colección.',
    WARNING_MESSAGE: 'Sus datos de ventas no están vinculados.',
    DO_NOT_SHOW: 'No mostrar mas',
    INCLUDE_DATA: 'Incluir datos',
    OF_THE_TOTAL: 'del total',
    REVENUE_TOOLTIP: 'Cantidad total de ingresos en línea y fuera de línea para el período.',
    INVESTED_TOOLTIP: 'Monto invertido en publicidad para el período.',
    SALES_AMOUNT_TOOLTIP: 'Número total de ventas en línea y fuera de línea para el período.',
    ONLINE_REVENUE_TOOLTIP: 'Ingresos contabilizados por los datos de ventas en línea.',
    OFFLINE_REVENUE_TOOLTIP: 'Ingresos contabilizados por datos de ventas fuera de línea.',
    IMPRESSIONS_TOOLTIP: 'Número de veces que se mostró el anuncio en el feed a la audiencia.',
    CLASSIFIED_ADS_TOOLTIP:
      'Número de anuncios clasificados basados en hashtags y palabras clave en el pie de foto.',
    CLASSIFIED_POSTS_TOOLTIP:
      'Cantidad de contenido sin inversión clasificada según hashtags y palabras clave en el pie de foto.',
    DATA_CAPACITY_INFO_TOOLTIP:
      'Relación entre la suma de todo el contenido que contiene hashtags o palabras clave que nuestro sistema de clasificación podría identificar y el contenido que no se pudo clasificar.',
    REGION_ADS_CREATED_TOOLTIP:
      'Total de anuncios promocionados creados en la región, incluidos aquellos con y sin hashtags o palabras clave que nuestro sistema de clasificación podría identificar.',
    STATE_ADS_CREATED_TOOLTIP:
      'Anuncios promocionados totales creados en el estado, incluidos aquellos con y sin hashtags o palabras clave que podrían identificarse mediante nuestro sistema de clasificación.',
    CITY_ADS_CREATED_TOOLTIP:
      'Total de anuncios promocionados creados en la ciudad, incluidos aquellos con y sin hashtags o palabras clave que podrían identificarse mediante nuestro sistema de clasificación.',
    INCLUDE_SALES_DATA: 'Incluir datos de ventas',
    INVESTED_AMOUNT: 'Cantidad invertida',
  },
};

export { TradeInsightsEN, TradeInsightsES, TradeInsightsPT };
