export const SUBDOMAIN_DICT = {
  beta: 'vBeta',
  comunicacao: 'vComunicação',
  estrategia: 'vEstratégia',
  'workflow-incentivos': '#Incentivos',
  'workflow-assistentes': '#Assistentes',
  'workflow-canais': '#Canais',
  'workflow-criadores': '#Criadores',
  'marketplace-trade': '#Trade',
  'network-perfil': '#Perfil',
};

export const developmentLogoTag = '#VamoDale';
