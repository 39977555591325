const CardImageAndActionsEN = {
  SELECT: 'Select',
};
const CardImageAndActionsPT = {
  SELECT: 'Selecionar',
};
const CardImageAndActionsES = {
  SELECT: 'Seleccione',
};

export { CardImageAndActionsEN, CardImageAndActionsPT, CardImageAndActionsES };
