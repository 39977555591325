import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import { maskValue, MASK_TYPE } from '@utils/helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Input({
  right = false,
  label = 'Digite um texto',
  onChange = () => {},
  onKeyUp = () => {},
  disabled = false,
  color = 'blue2',
  type,
  name,
  id,
  defaultValue,
  value,
  placeholder,
  errors,
  innerRef,
  onShowToggle,
  ...rest
}) {
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (value?.length) {
      setFocus(true);
    }
  }, [value]);

  const moreThanOne = value?.length > 0;

  return (
    <div>
      <S.InputWrapper moreThanOne={moreThanOne} color={color} focusIn={focus} errors={errors}>
        <S.InputStyled
          right={right}
          type={type}
          name={name}
          id={id}
          defaultValue={!!defaultValue ? maskValue(defaultValue, MASK_TYPE.ABSOLUTE) : ''}
          value={value}
          placeholder={placeholder}
          onBlur={() => setFocus(false)}
          onKeyUp={(e) => {
            onKeyUp(e);
          }}
          onFocus={() => setFocus(true)}
          onChange={onChange}
          disabled={disabled}
          moreThanOne={moreThanOne}
          focusIn={focus}
          ref={innerRef}
          {...rest}
        />
        <S.LabelStyled>{label}</S.LabelStyled>
        {id === 'password' && !!onShowToggle && (
          <S.IconEye onClick={onShowToggle} type="button">
            <FontAwesomeIcon icon={['far', 'eye']} />
          </S.IconEye>
        )}
      </S.InputWrapper>
      <S.MessageValidation>{errors}</S.MessageValidation>
    </div>
  );
}

Input.propTypes = {
  right: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.node,
  defaultValue: PropTypes.node,
  value: PropTypes.node,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  focusIn: PropTypes.bool,
};

export default Input;
