/* Create action types */
import createActionTypes from '../createActionTypes';

export const SET_DATE_RANGE = createActionTypes('SET_DATE_RANGE');
export const FETCH_AUTO_COMPLETE_GROUP = createActionTypes('FETCH_AUTO_COMPLETE_GROUP');
export const GET_CURRENT = createActionTypes('GET_CURRENT');
export const GET_CURRENT_ITEM = createActionTypes('GET_CURRENT_ITEM');
export const GET_CODE_INFO = createActionTypes('GET_CODE_INFO');
export const GET_CODE_INFORMATION = createActionTypes('GET_CODE_INFORMATION');
export const GET_GROUPS_PERMITTED_ACCESS = createActionTypes('GET_GROUPS_PERMITTED_ACCESS');
export const CHANGE_GROUP_TOTAL_CURRENT = createActionTypes('CHANGE_GROUP_TOTAL_CURRENT');
export const CHANGE_BUDGET = createActionTypes('CHANGE_BUDGET');
export const CHANGE_MONTLY_GOAL = createActionTypes('CHANGE_MONTLY_GOAL');
export const LISTING_PAGE_COUNT = createActionTypes('LISTING_PAGE_COUNT');
export const RECOUNT_LISTING_GROUP_TOTAL = createActionTypes('RECOUNT_LISTING_GROUP_TOTAL');
export const SET_EXHIBITION_MODE = createActionTypes('SET_EXHIBITION_MODE');
export const SET_FEED_CHANNEL = createActionTypes('SET_FEED_CHANNEL');
