const TableBudgetAccountsEN = {
  TableBudgetAccounts: {
    ACCOUNT: 'Account',
    TOTAL: 'Total',
    SPEND: 'Spend',
    REMANING: 'Remaning budget',
  },
};

const TableBudgetAccountsPT = {
  TableBudgetAccounts: {
    ACCOUNT: 'Conta',
    TOTAL: 'Total',
    SPEND: 'Gasto',
    REMANING: 'Verba restante',
  },
};

const TableBudgetAccountsES = {
  TableBudgetAccounts: {
    ACCOUNT: 'Cuenta',
    TOTAL: 'Total',
    SPEND: 'Gastado',
    REMANING: 'Fondos restantes',
  },
};

export { TableBudgetAccountsPT, TableBudgetAccountsEN, TableBudgetAccountsES };
