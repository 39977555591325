import React from 'react';
import { useTranslate } from 'react-translate';
import SelectOnTab from '../../../../components/SelectOnTab/SelectOnTab';

const FilterRole = ({ onChange, disabled, role }) => {
  const t = useTranslate('User');

  const values = [
    {
      status: 'all',
      preset: 'all',
      label: t('ROLE'),
    },
    {
      status: 'admin',
      preset: 'admin',
      label: t('ADMIN'),
    },
    {
      status: 'emloyee',
      preset: 'default_user',
      label: t('EMPLOYEE'),
    },
  ];

  return (
    <SelectOnTab
      iconSelect={['far', 'user']}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
      selectValue={role}
    >
      {values.map((value) => (
        <option key={value.status} value={value.preset}>
          {value.label}
        </option>
      ))}
    </SelectOnTab>
  );
};

export default FilterRole;
