import styled, { css } from 'styled-components';

export const OptionStyled = styled('option')`
  color: #0a3b5b;
  font-size: 13px;
`;

export const Wrapper = styled('div')`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.px20};
    margin-right: ${theme.spacing.px10};
    position: relative;
  `}
`;
