// Modules
import React from 'react';

import PropTypes from 'prop-types';

// Styles
import * as S from './styles';



const Container = ({
  children, noLeft, spacingDefault, maxWidth,
}) => (
    <S.ContainerWrapper>
      <S.ContainerItem data-testid="container" noLeft={noLeft} maxWidth={maxWidth} spacingDefault={spacingDefault}>
        {children}
      </S.ContainerItem>
    </S.ContainerWrapper>
);

Container.propTypes = {
  children: PropTypes.node,
  noLeft: PropTypes.bool,
  spacingDefault: PropTypes.bool,
  maxWidth: PropTypes.string,
};

Container.defaultProps = {
  children: '',
  noLeft: false,
  spacingDefault: false,
};

export default Container;
