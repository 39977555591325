const RankingEN = {
  Ranking: {
    TITLE_GROUPS: 'ranking groups',
    Groups: 'Groups ',
    Accounts: 'Accounts ',
    Pages: 'Pages ',
    BEST: 'Best',
    WORST: 'Worst',
    GROUP: 'group',
    IMPRESSIONS: 'Impression',
    REACH: 'Reach',
    LINK_CLICK: 'Link click',
    SPEND: 'Spend',
    'OFFSITE_CONVERSION.FB_PIXEL_PURCHASE': 'Purchase',
    POST_ENGAGEMENT: 'Engagement',
    BY_IMPRESSIONS: 'by impression',
    BY_REACH: 'by reach',
    BY_LINK_CLICK: 'by link click',
    BY_SPEND: 'by spend',
    'BY_OFFSITE_CONVERSION.FB_PIXEL_PURCHASE': 'by purchase',
    BY_POST_ENGAGEMENT: 'by engagement',
    BY_POST_INVOLVEMENT: 'by involvement',
    BY_POST_INVOLVEMENT_RATE: 'by involvement rate',
    EMPYT_CONTENT_GROUP_TITLE: 'Groups: ',
    EMPYT_CONTENT_GROUP_MESSAGE: 'There is no groups for this account.',
    EMPYT_CONTENT_ACCOUNT_TITLE: 'Account: ',
    EMPYT_CONTENT_ACCOUNT_MESSAGE: 'There is no accounts.',
    EMPYT_CONTENT_PAGES_TITLE: 'Pages: ',
    EMPYT_CONTENT_PAGES_MESSAGE: 'There is no pages.',
    REPORT: 'Report',
    POST_INVOLVEMENT: 'Involvement',
    POST_INVOLVEMENT_RATE: 'Involvement Rate',
    ERROR_FETCH_RANKING: 'An error occurred while trying to get ranking data.',
    RANKING_EVOLUTION: 'Ranking evolution',
    POSITION: 'Position',
    POSITIONS: 'Positions',
    VALUE: 'Value',
    LINK_CLICK_TOOLTIP:
      'Shows the number of total ad clicks. It counts clicks on the link, clicks on the page name, clicks to view the image and video, clicks to react and comment on the content.',
    IMPRESSIONS_TOOLTIP:
      'Shows the number of times the ad has been shown in the feed to the public. If it appears at different times for the same person, one more impression is counted. But beware: for videos the impression is counted even without the video being started.',
    POST_ENGAGEMENT_TOOLTIP:
      'Shows the total number of engagements (sum of reactions, comments and shares, photo or video preview, click on link or order in offers).',
    'OFFSITE_CONVERSION.FB_PIXEL_PURCHASE_TOOLTIP':
      'Shows the number of purchases made on the website that have been finalized through an advertisement. It is monitored by Pixel from Facebook installed on its website.',
    TITLE: 'Ranking',
    SUBTITLE: 'Find out how your units are ranking in this report',
  },
};

const RankingPT = {
  Ranking: {
    TITLE_GROUPS: 'ranking de grupos',
    Groups: 'Grupos',
    Accounts: 'Contas',
    Pages: 'Páginas',
    BEST: 'Melhores',
    WORST: 'Piores',
    GROUP: 'grupo',
    IMPRESSIONS: 'Impressões',
    REACH: 'Alcance',
    LINK_CLICK: 'Clique no link',
    SPEND: 'Investido',
    'OFFSITE_CONVERSION.FB_PIXEL_PURCHASE': 'Compra',
    POST_ENGAGEMENT: 'Engajamentos',
    BY_IMPRESSIONS: 'por impressões',
    BY_REACH: 'por alcance',
    BY_LINK_CLICK: 'por cliques no link',
    BY_SPEND: 'por investimento',
    'BY_OFFSITE_CONVERSION.FB_PIXEL_PURCHASE': 'por compra',
    BY_POST_ENGAGEMENT: 'por engajamentos',
    BY_POST_INVOLVEMENT: 'por envolvimento',
    BY_POST_INVOLVEMENT_RATE: 'por taxa de envolvimento',
    EMPYT_CONTENT_GROUP_TITLE: 'Grupos: ',
    EMPYT_CONTENT_GROUP_MESSAGE: 'Não há grupos para esta conta.',
    EMPYT_CONTENT_ACCOUNT_TITLE: 'Conta: ',
    EMPYT_CONTENT_ACCOUNT_MESSAGE: 'Não há nenhuma conta.',
    EMPYT_CONTENT_PAGES_TITLE: 'Páginas: ',
    EMPYT_CONTENT_PAGES_MESSAGE: 'Não há nenhuma página.',
    REPORT: 'Relatório',
    POST_INVOLVEMENT: 'Envolvimento',
    POST_INVOLVEMENT_RATE: 'Taxa de Envolvimento',
    ERROR_FETCH_RANKING: 'Ocorreu um erro ao tentar obter os dados de ranking.',
    RANKING_EVOLUTION: 'Evolução no ranking',
    POSITION: 'Posição',
    POSITIONS: 'Posições',
    VALUE: 'Valor',
    LINK_CLICK_TOOLTIP:
      'Mostra a quantidade de cliques totais em anúncios. Contabiliza cliques no link, cliques no nome da página, cliques para visualizar a imagem e vídeo, cliques para reagir e comentar no conteúdo.',
    IMPRESSIONS_TOOLTIP:
      'Mostra a quantidade de vezes que o anúncio foi exibido no feed para o público. Caso apareça em diferentes momentos para a mesma pessoa, mais uma impressão é contada. Mas atenção: para vídeos a impressão é contada mesmo sem o vídeo ser iniciado.',
    POST_ENGAGEMENT_TOOLTIP:
      'Mostra o número total de engajamentos (soma de reações, comentários e compartilhamentos, visualização de foto ou vídeo, clique em link ou pedido em ofertas).',
    'OFFSITE_CONVERSION.FB_PIXEL_PURCHASE_TOOLTIP':
      'Mostra o número de compras feitas no site que tenham sido finalizadas através de algum anúncio. É monitorado pelo Pixel do próprio Facebook instalado em seu site.',
    TITLE: 'Ranking',
    SUBTITLE: 'Saiba como está o ranking de suas unidades neste relatório',
  },
};

const RankingES = {
  Ranking: {
    TITLE_GROUPS: 'ranking de grupo',
    Groups: 'Grupos',
    Accounts: 'Cuentas',
    Pages: 'Paginas',
    BEST: 'Mejores',
    WORST: 'Peores',
    GROUP: 'grupo',
    IMPRESSIONS: 'Impresiones',
    REACH: 'Alcanzar',
    LINK_CLICK: 'Clics en enlaces',
    SPEND: 'Invertido',
    'OFFSITE_CONVERSION.FB_PIXEL_PURCHASE': 'Compra',
    POST_ENGAGEMENT: 'Involucramientos',
    BY_IMPRESSIONS: 'por impresiones',
    BY_REACH: 'por alcance',
    BY_LINK_CLICK: 'por clics en el enlace',
    BY_SPEND: 'por inversión',
    'BY_OFFSITE_CONVERSION.FB_PIXEL_PURCHASE': 'por compra',
    BY_POST_ENGAGEMENT: 'por compromisos',
    BY_POST_INVOLVEMENT: 'por participación',
    BY_POST_INVOLVEMENT_RATE: 'por tasa de participación',
    EMPYT_CONTENT_GROUP_TITLE: 'Grupos: ',
    EMPYT_CONTENT_GROUP_MESSAGE: 'No hay grupos para esta cuenta.',
    EMPYT_CONTENT_ACCOUNT_TITLE: 'Cuenta: ',
    EMPYT_CONTENT_ACCOUNT_MESSAGE: 'No hay cuenta.',
    EMPYT_CONTENT_PAGES_TITLE: 'Paginas: ',
    EMPYT_CONTENT_PAGES_MESSAGE: 'No hay página.',
    REPORT: 'Report',
    POST_INVOLVEMENT: 'Envolvimiento',
    POST_INVOLVEMENT_RATE: 'Cuota de participación',
    ERROR_FETCH_RANKING: 'Se produjo un error al intentar obtener datos de clasificación.',
    RANKING_EVOLUTION: 'Evolución del ranking',
    POSITION: 'Posición',
    POSITIONS: 'Posiciones',
    VALUE: 'Valor',
    LINK_CLICK_TOOLTIP:
      'Muestra la cantidad total de clics en anuncios. Cuenta los clics en el enlace, los clics en el nombre de la página, los clics para ver la imagen y el video, los clics para reaccionar y comentar el contenido.',
    IMPRESSIONS_TOOLTIP:
      'Muestra el número de veces que el anuncio se ha mostrado en el feed a la audiencia. Si aparece en diferentes momentos para la misma persona, se cuenta una impresión más. Pero cuidado: en el caso de los videos, la impresión se cuenta incluso sin que se haya iniciado el video.',
    POST_ENGAGEMENT_TOOLTIP:
      'Muestra o cantidad total de elementos (suma de reacciones, comentarios y publicaciones, vista previa de foto o video, haga clic en el enlace o solicite ofertas).',
    'OFFSITE_CONVERSION.FB_PIXEL_PURCHASE_TOOLTIP':
      'Muestra el número de compras realizadas en el sitio que se han completado a través de un anuncio. Es monitoreado por el Pixel de Facebook instalado en su sitio web.',
    TITLE: 'Ranking',
    SUBTITLE: 'Sepa cómo está el ranking de sus unidades en este informe',
  },
};

export { RankingPT, RankingEN, RankingES };
