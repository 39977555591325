const ProfileSettingsPT = {
  ProfileSettings: {
    MY_PROFILE: 'Meu perfil',
    USER_STATUS_ACTIVE: 'Ativo',
    USER_STATUS_INVITED: 'Convidado',
    USER_STATUS_BLOCKED: 'Bloqueado',
    USER_ROLE_COLABORATOR: 'Colaborador',
    USER_ROLE_SYSTEM_ADM: 'Administrador do sistema',
    USER_ROLE_ADMIN: 'Administrador',
    FIELD_REQUIRED: 'Campo obrigatório',
    LABEL_NAME: 'Nome',
    LABEL_LAST_NAME: 'Sobrenome',
    BUTTON_SAVE: 'Salvar',
    LAST_ACCESS: 'Último acesso',
    ACCESS_ACTIVE: 'Suas sessões ativas',
    CHECK_SESSIONS: 'Marque as sessões que deseja desconectar',
    DISCONNECT: 'Desconectar',
    CURRENT_SESSION: 'Sessão atual',
    DISCONNECT_SUCCESS: 'Sessão desconectada',
    CHANGE_AVATAR: 'Alterar',
    DRAG_N_CROP_IMAGE: 'Arraste um arquivo ou clique para adicionar a mídia',
    ACCEPT_TYPES: 'Extensões permitidas: ',
    INVALID_FILE_TYPE: 'Tipo de arquivo inválido!',
    FILE_SIZE_TOO_GREAT: 'O tamanho do arquivo deve ser menor que 1GB!',
    CLEAN_TEXT: 'Limpar',
    SAVE_TEXT: 'Salvar',
    UPLOAD_ERROR_MESSAGE: 'Houve um problema ao gravar, tente novamente mais tarde!',
  },
};

const ProfileSettingsEN = {
  ProfileSettings: {
    MY_PROFILE: 'My profile',
    USER_STATUS_ACTIVE: 'Active',
    USER_STATUS_INVITED: 'Invited',
    USER_STATUS_BLOCKED: 'Blocked',
    USER_ROLE_COLABORATOR: 'Colaborator',
    USER_ROLE_SYSTEM_ADM: 'System administrator',
    USER_ROLE_ADMIN: 'Administrator',
    FIELD_REQUIRED: 'Required field',
    LABEL_NAME: 'Name',
    LABEL_LAST_NAME: 'Last name',
    BUTTON_SAVE: 'Save',
    LAST_ACCESS: 'Last Access',
    ACCESS_ACTIVE: 'your active sessions',
    CHECK_SESSIONS: 'Check the sessions you want to disconnect',
    DISCONNECT: 'To disconnect',
    CURRENT_SESSION: 'Current session',
    DISCONNECT_SUCCESS: 'Session disconnected',
    CHANGE_AVATAR: 'Change',
    DRAG_N_CROP_IMAGE: 'Drag-n-drop a file or click to add the media',
    ACCEPT_TYPES: 'Accepted file types: ',
    INVALID_FILE_TYPE: 'Invalid file type!',
    FILE_SIZE_TOO_GREAT: 'File size must be less than 1GB!',
    CLEAN_TEXT: 'Clean',
    SAVE_TEXT: 'Save',
    UPLOAD_ERROR_MESSAGE: 'There was a problem recording, try again later!',
  },
};

const ProfileSettingsES = {
  ProfileSettings: {
    MY_PROFILE: 'Mi perfil',
    USER_STATUS_ACTIVE: 'Activo',
    USER_STATUS_INVITED: 'Invitado',
    USER_STATUS_BLOCKED: 'Obstruido',
    USER_ROLE_COLABORATOR: 'Colaborador',
    USER_ROLE_SYSTEM_ADM: 'Administrador del sistema',
    USER_ROLE_ADMIN: 'Administrador',
    FIELD_REQUIRED: 'Campo obligatorio',
    LABEL_NAME: 'Nombre',
    LABEL_LAST_NAME: 'Apellido',
    BUTTON_SAVE: 'Salvar',
    LAST_ACCESS: 'Último acceso',
    ACCESS_ACTIVE: 'Tus sesiones activas',
    CHECK_SESSIONS: 'Consulta las sesiones que quieres desconectar',
    DISCONNECT: 'Desconectar',
    CURRENT_SESSION: 'Sesión actual',
    DISCONNECT_SUCCESS: 'Sesión desconectada',
    CHANGE_AVATAR: 'Alterar',
    DRAG_N_CROP_IMAGE: 'Arrastra un archivo o haz clic para agregar la imagen',
    ACCEPT_TYPES: 'Tipos de archivo permitidos: ',
    INVALID_FILE_TYPE: 'Tipo de archivo no válido!',
    FILE_SIZE_TOO_GREAT: 'El tamaño del archivo debe ser menor a 1GB!',
    CLEAN_TEXT: 'Limpiar',
    SAVE_TEXT: 'Guardar',
    UPLOAD_ERROR_MESSAGE: 'Hubo un problema al guardar, inténtalo de nuevo más tarde!',
  },
};

export { ProfileSettingsPT, ProfileSettingsEN, ProfileSettingsES };
