import React, { useEffect } from 'react';
import { LoadingOld } from '@components/LoadingOld/LoadingOld';
import Container from '@components/Container';
import images from '@images';
import { useUser } from '@utils/contexts';
import Image from '@components/Image';
import Button from 'components/Button';
import SelectLanguages from '../ProfileSettings/SelectLanguage';
import ChooseCompany from './ChooseCompany';
import { isTenantBornlogic } from '@utils/helpers';
import * as S from './styles';
import { checkIfIsTradeView } from '../../utils/helpers';
import { useHistory } from 'react-router';

// ERM-369 review this
const showCreatePassword = false;

const Login = () => {
  const history = useHistory();
  const { login, logout, subDomain, hasUser, tenantOptions, decodedBusinessToken } = useUser();
  const hasParams = window.location.href.includes('?');
  const hasTrickParams =
    window.location.search.includes('verification_token') ||
    window.location.hash.includes('error=access_denied');
  const isLoading = hasParams && !hasTrickParams && tenantOptions.length === 0;

  useEffect(() => {
    let isTradeView = false;
    if (checkIfIsTradeView()) {
      isTradeView = true;
    }

    if (decodedBusinessToken) {
      if (decodedBusinessToken.bgd_capabilities_v2.includes('TRADE_ADS_INDUSTRY')) {
        history.push('/root/TradeAds');
      } else if (isTradeView && isTenantBornlogic()) {
        history.push('/root/Trade/Tracking');
      } else if (isTradeView) {
        history.push('/root/Trade/Dashboard');
      } else if (decodedBusinessToken.permitted_group) {
        history.push(`/${decodedBusinessToken.permitted_group}/Report/Overview/Global`);
      } else {
        history.push('/root/Report/Overview/Global');
      }
    }
  }, [decodedBusinessToken]);

  const getPic = () =>
    !!subDomain && !!subDomain.picture_url && !!subDomain.name ? (
      <Image src={subDomain.picture_url} alt={subDomain.name} title={subDomain.name} logo />
    ) : (
      <Image src={images.logoHorizontal} logo />
    );

  const renderContent = () => {
    const content = {
      default: (
        <S.CardLoginWrapper>
          <S.ImageWrapper>{getPic()}</S.ImageWrapper>
          <S.Wrapper>
            <Button
              backgroundColor="blueSecurity"
              type="button"
              onClick={() => (hasUser() ? logout() : login())}
            >
              {hasUser() ? 'LOGOUT' : 'Entrar com Bornlogic'}
            </Button>
          </S.Wrapper>
        </S.CardLoginWrapper>
      ),
      chooseCompany: <ChooseCompany />,
    };
    if (hasParams && tenantOptions.length > 0) return content.chooseCompany;
    return content.default;
  };

  if (isLoading) return <LoadingOld isLoading />;

  let logo = null;

  if (subDomain && subDomain.picture_url && subDomain.name) {
    logo = (
      <S.ImageWrapper>
        <img
          src={images.logoGd}
          alt="Gerentes digitais"
          style={{ maxHeight: '60px', marginTop: '80px' }}
        />
      </S.ImageWrapper>
    );
  }

  return (
    <S.LoginWrapper {...{ showCreatePassword }}>
      <Container>
        {logo}
        <S.LoginWrapperCard>{renderContent()}</S.LoginWrapperCard>
        <S.LoginWrapperLanguage>
          <SelectLanguages />
        </S.LoginWrapperLanguage>
      </Container>
      <S.LegalWrapper>
        © 2022 Bornlogic®. Todos os direitos reservados.
        <S.PrivacyButton onClick={() => history.push('/PrivacyTerms')}>Privacidade</S.PrivacyButton>
      </S.LegalWrapper>
    </S.LoginWrapper>
  );
};
export default Login;
