import UploadService from '../services/upload';

const makeid = () => {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < 5; i += 1) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

const generatePad = (number, length) => {
  let str = `${number}`;
  while (str.length < length) {
    str = `0${str}`;
  }
  return str;
};

const generateSubmitUri = (sasUrl, hash) => {
  const baseUrl = sasUrl;
  const indexOfQueryStart = baseUrl.indexOf('?');
  return `${baseUrl.substring(0, indexOfQueryStart)}/${hash}${baseUrl.substring(
    indexOfQueryStart
  )}`;
};

const uploadFiles = (partsToSend) => {
  return Promise.all(
    [].map.call(partsToSend, function (file) {
      return new Promise(async function (resolve) {
        return resolve(await UploadService.uploadImage(file.uri, file.fileData));
      });
    })
  ).then(function (results) {
    return results;
  });
};

function uploadVideo(
  file,
  sasUrl,
  kickassId,
  commitUploadSucessCallback = null,
  commitUploadErrorCallback = null
) {
  const hash = Date.now() + makeid() + file.name.substring(file.name.lastIndexOf('.'));
  const submitUri = generateSubmitUri(sasUrl, hash);
  const fileSize = file.size;
  const maxBlockSize = 1024 * 1024;
  const blockIds = [];
  const BLOCK_ID_PREFIX = 'block-';
  let consumedBytes = 0;
  let status = 'PREPARED';
  const filesProcessed = [];

  const handleUpload = async () => {
    const AMOUNT_REQUESTS_PER_CICLE = 50;

    for (let i = 0; i <= filesProcessed.length; i += AMOUNT_REQUESTS_PER_CICLE) {
      const partsToSend = filesProcessed.slice(i, i + AMOUNT_REQUESTS_PER_CICLE);
      await uploadFiles(partsToSend);
    }
    upload();
  };

  const readAsBuffer = (filesParts) => {
    return Promise.all(
      [].map.call(filesParts, function (file) {
        return new Promise(function (resolve) {
          const reader = new FileReader();
          reader.onloadend = function () {
            let uri = `${submitUri}&comp=block&blockid=${file.id}`;
            let fileData = new Uint8Array(reader.result);
            resolve({ result: reader.result, file: file.file, id: file.id, uri, fileData });
          };
          reader.readAsArrayBuffer(file.file);
        });
      })
    ).then(function (results) {
      results.forEach(function (result) {
        filesProcessed.push(result);
      });
      handleUpload();
    });
  };

  const readBlockFile = () => {
    let start = 0;
    let end = maxBlockSize;
    let processed = 0;
    let remain = fileSize;
    const filesParts = [];

    while (remain > 0) {
      const filePiece = file.slice(start, end);
      if (filePiece.size > 0) {
        start = end;
        end += maxBlockSize;
        processed += maxBlockSize;
        remain -= maxBlockSize;
        const pieceId = btoa(`${BLOCK_ID_PREFIX}${generatePad(blockIds.length, 6)}`);
        blockIds.push(pieceId);
        filesParts.push({ file: filePiece, id: pieceId });
      }
    }
    consumedBytes = processed;
    if (filesParts.length) {
      readAsBuffer(filesParts);
    }
  };

  const commit = () => {
    const uri = `${submitUri}&comp=blocklist`;
    let requestBody = '<?xml version="1.0" encoding="utf-8"?><BlockList>';
    for (let i = 0; i < blockIds.length; i += 1) {
      requestBody += `<Latest>${blockIds[i]}</Latest>`;
    }
    requestBody += '</BlockList>';
    UploadService.uploadImage(uri, requestBody)
      .then((data) => {
        if (commitUploadSucessCallback) {
          commitUploadSucessCallback(kickassId, data.request.responseURL.split('?')[0]);
        }
        status = 'UPLOADED';
      })
      .catch((xhr, desc, newErr) => {
        status = 'ERROR';
        if (commitUploadErrorCallback) commitUploadErrorCallback(desc, newErr);
      });
  };

  const upload = () => {
    const remainingBytes = fileSize - consumedBytes;

    if (status === 'PAUSED' || status === 'CANCELLED') {
      return;
    }
    if (remainingBytes > 0) {
      status = 'UPLOADING';
      readBlockFile();
    } else {
      status = 'COMMITING';
      commit();
    }
  };

  upload();
}

export default uploadVideo;
