const DateRangeEN = {
  DateRange: {
    TODAY: 'Today',
    YESTERDAY: 'Yesterday',
    LAST_7D: 'Last seven days',
    LAST_MONTH: 'Last Month',
    THIS_MONTH: 'This Month',
    'DAYS-UP-TO-TODAY': 'Days up to today',
    'DAYS-STARTING-TODAY': 'Days starting today',
    THIS_YEAR: 'This Year',
    CUSTOM_PERIOD: 'Custom period',
  },
};

const DateRangePT = {
  DateRange: {
    TODAY: 'Hoje',
    YESTERDAY: 'Ontem',
    LAST_7D: 'Últimos sete dias',
    LAST_MONTH: 'Mês passado',
    THIS_MONTH: 'Este mês',
    'DAYS-UP-TO-TODAY': 'Dias até hoje',
    'DAYS-STARTING-TODAY': 'Dias a partir de hoje',
    THIS_YEAR: 'Este ano',
    CUSTOM_PERIOD: 'Período customizado',
  },
};

const DateRangeES = {
  DateRange: {
    TODAY: 'Hoy',
    YESTERDAY: 'Ayer',
    LAST_7D: 'Últimos siete días',
    LAST_MONTH: 'Mes pasado',
    THIS_MONTH: 'Este mes',
    'DAYS-UP-TO-TODAY': 'Días hasta hoy',
    'DAYS-STARTING-TODAY': 'Días a partir de hoy',
    THIS_YEAR: 'Este año',
    CUSTOM_PERIOD: 'Período personalizado',
  },
};

export { DateRangePT, DateRangeEN, DateRangeES };
