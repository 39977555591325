const ContentActionsEN = {
  ContentActions: {
    DESCRIPTION: 'Description',

    'MARKED-TITLE': 'Add an action',
    'MARKED-NO-POSTS-TITLE': 'Marked posts',
    'MARKED-NO-POSTS-DESCRIPTION': 'No posts selecteds',
    'MARKED-NO-ADS-TITLE': 'Marked ads',
    'MARKED-NO-ADS-DESCRIPTION': 'No ads selected',
    'MARKED-NO-STORIES-TITLE': 'Marked stories',
    'MARKED-NO-STORIES-DESCRIPTION': 'No stories selected',
    NOT_SUPPORTED: 'Your browser does not support HTML5 video',

    CONFIRM: 'Confirm',
    CANCEL: 'Cancel',
    APPROVED_BID: 'Max bid',
    APPROVED_DAILY_BUDGET: 'Daily Budget',
    APPROVED_AUTO_BID: 'Use auto-bid',
    APPROVED_DTA_INIT: 'Date init',
    APPROVED_DTA_END: 'Date end',
    INCORRECT_DATE: "The final date can't be smaller than the initial date",
    MESSAGE_REQUIRED: 'This field is required',
    EMPTY_CONTENT_TITLE:
      'The contents sent by your shopkeepers are on this page for your evaluation.',
    EMPTY_CONTENT_MESSAGE:
      'You have no content for approval. Send your first request to your shopkeeper.',
    TOTAL_INVEST_TITLE: 'Enter the total amount of the investment',
    TOTAL_INVEST_LABEL: 'Total investment',
    TOTAL_INVEST_DAILY_LABEL: 'Daily investment',
    NEXT: 'Next',
    PUBLISH: 'Publish',

    MARKED_ANALYSE: 'Analyse',
    MARKED_SEE: 'See',
    MARKED_EDIT: 'Edit',
    MARKED_SEND: 'Send',
    MARKED_UNMARK: 'Unmark',
    SEARCH_PLACEHOLDER: 'Search...',
    SELECT_ALL: 'Select all',
    SHARE: 'Share',

    INFORMATION: 'Information',
    SUBTITLE: 'Subtitle',

    // Media Cards
    APPROVED_TITLE: 'Approve this ad',
    APPROVED_TEXT_INFO:
      'Choose whether this content will be organic or whether it will be sponsored content.',

    REVIEW_DISAPPROVE: 'Disapprove',
    REVIEW_APPROVE: 'Approve',
    TYPE_HERE: 'Type here',
    SEND: 'Send',
    REPLICATE: 'Replicate',
    PROMOTE: 'Promote',
    REPORT: 'Report',
    EDIT: 'Edit',
    PREVIEW: 'See preview',
    FACEBOOK: 'See on facebook',
    UNMARK: 'Unmark',
    GO_BACK: 'Go back',
    START_DATE_LABEL: 'Start date:',
    END_DATE_LABEL: 'End date:',
    REACHED_PEOPLE: 'People reached',
    POST_SUCCESS_MESSAGE: 'Successful replication!',
    POST_FAILURE_MESSAGE: 'Failed to replicate, try again later!',
    POST_PROMOTE_SUCCESS_MESSAGE: 'Success promoting post!',
    POST_PROMOTE_FAILURE_MESSAGE: 'Failed to promote post, please try again later!',
    AVAILABLE: 'available',
    EDIT_DESCRIPTION: 'Edit description',
    INSERT_DESCRIPTION: 'Inserir descrição',

    TOTAL_VALUE: 'Total value',
    TO: 'to',
    PERIOD: 'Period',
    ACCOUNT_BALLANCE: 'Account balance',
    APPROVE_SUCCESS: 'Media approved successfully!',
    APPROVE_ERROR: 'Error on approving media, try again later or contact an administrator.',
    APPROVE_WITH_BUDGET: 'Approve sponsored content',
    MADE_AT: 'Created at',
    MADE_BY: 'Created by ',
    ON: ' on ',
    AT: ' at ',
    SEE_ON_FACEBOOK: 'View on facebook',
    SEE_MORE: 'See more',
    SEE_LESS: 'See less',
    APPROVAL: 'Approved by ',
    DISAPPROVING: 'Rejected by  ',
    POST_COMMENT: 'Post comment',

    BLOCK: 'Block',
    REMOVE: 'Remove',
    UNBLOCK: 'Unblock',

    post_engagement: 'Engagement',
    messages: 'Messages',
    store_visits: 'Store Visits',
    link_clicks: 'Link Clicks',
    conversions: 'Conversions',
    video_views: 'Video Views',
    lead_generation: 'Leads',
    DATA_ERROR: 'Attention, the ad start date has passed!',
    DATA_ERROR_CASO_1: 'Attention, the time of publication has passed!',
    DATA_ERROR_CASO_2:
      "Attention, the ad's end date has passed! You need to edit to be able to approve.",
    DATA_ERROR_CASO_3: 'Attention, the date and time of publication have passed!',
    BUDGET_EDIT_ERROR: 'Editing the budget is not allowed.',
    LINK_ACTION: 'Ad Action Link**',
    CAMPAING_GOAL: 'Campaign Objective',

    CHANNEL: 'Channel / Placement',
    CALL_TO_ACTION_BUTTON: 'Action Button',

    shop_now: 'Book now',
    whatsapp_message: 'Call on store whatsapp',
    learn_more: 'See more ',
    get_directions: 'Get directions',
    sign_up: 'Register',
    buy_now: 'Buy now',
    contact_us: 'Contact us',
    apply_now: 'Apply now',

    INVESTMENT: 'Investimento',
    AUDIENCE: 'Audience',
    BUDGET_TYPE: 'Budget Type',
    shared: 'Shared',
    private: 'Private',

    SELECTED_PIXEL: 'Selected Pixel',
    SELECTED_FORM: 'Selected Formulário',

    SCHEDULED_POST: 'Scheduled Post',
    TIME: 'Time',
    stream: 'Feed',
    feed: 'Feed',
    story: 'Story',

    SAVE: 'Save',
    SAVE_AND_APPROVE: 'Save and approve',
    CANCEL_EDIT: 'Cancel',
    EDIT_DESCRITION_SUCCESS: 'The media was changed successfully.',
    EDIT_DESCRITION_FAILURE: 'An error has occurred. Try to change the media.',
    INSUFFICIENT_THIRD_PARTY_BUDGET: 'Budget is too low for the selected period',
    MULTI_IMAGE_MEDIA_CANNOT_HAVE_CALL_TO_ACTION: 'MULTI IMAGE MEDIA CANNOT HAVE CALL TO ACTION',
    INVALID_MEDIA_CALL_TO_ACTION: 'INVALID MEDIA CALL TO ACTION',
    MISSING_THIRD_PARTY_PAYMENT_METHOD: 'MISSING THIRD PARTY PAYMENT METHOD',
    INVALID_INCENTIVE_CAMPAIGN_END_TIME: 'Invalid incentive campaign end date',
    facebook_feed: 'Facebook Feed',
    facebook_story: 'Facebook Story',
    instagram_stream: 'Instagram Feed',
    instagram_story: 'Instagram Story',
    tiktok: 'TikTok',
    review: 'Review',
    comment: 'Comment',
    link: 'Link',

    EMPTY_VIDEO_URL: 'No video'
  },
};

const ContentActionsPT = {
  ContentActions: {
    DESCRIPTION: 'Descrição',
    'MARKED-TITLE': 'Adicione uma ação',
    'MARKED-NO-POSTS-TITLE': 'Publicações marcadas',
    'MARKED-NO-POSTS-DESCRIPTION': 'Nenhuma publicação selecionada',
    'MARKED-NO-ADS-TITLE': 'Anúncios marcados',
    'MARKED-NO-ADS-DESCRIPTION': 'Nenhum anúncio selecionado',
    'MARKED-NO-STORIES-TITLE': 'Stories marcados',
    'MARKED-NO-STORIES-DESCRIPTION': 'Nenhum stories selecionado',
    NOT_SUPPORTED: 'Seu navegador não suporta vídeo em HTML5',

    CONFIRM: 'Confirmar',
    CANCEL: 'Cancelar',
    APPROVED_BID: 'Lance máximo',
    APPROVED_DAILY_BUDGET: 'Investimento diário',
    APPROVED_AUTO_BID: 'Usar lance automático',
    APPROVED_DTA_INIT: 'Data inicial',
    APPROVED_DTA_END: 'Data final',
    INCORRECT_DATE: 'A data final não pode ser menor que a data inicial.',
    MESSAGE_REQUIRED: 'Este campo é obrigatório',
    EMPTY_CONTENT_TITLE:
      'Os conteúdos enviados pelo seus lojistas ficam neste página para sua avaliação.',
    EMPTY_CONTENT_MESSAGE:
      'Você ainda não tem nenhum conteúdo para aprovar. Envie sua primeira solicitação para seu lojista.',
    TOTAL_INVEST_TITLE: 'Insira o valor total do investimento',
    TOTAL_INVEST_LABEL: 'Investimento total',
    TOTAL_INVEST_DAILY_LABEL: 'Investimento diário',
    NEXT: 'Próximo',
    PUBLISH: 'Publicar',

    MARKED_ANALYSE: 'Analisar',
    MARKED_SEE: 'Ver',
    MARKED_EDIT: 'Editar',
    MARKED_SEND: 'Enviar',
    MARKED_UNMARK: 'Desmarcar',
    SEARCH_PLACEHOLDER: 'Procurar...',
    SELECT_ALL: 'Selecionar todos',
    SHARE: 'Compartilhar',

    INFORMATION: 'Informações',
    SUBTITLE: 'Legenda',

    // Media Cards
    APPROVED_TITLE: 'Aprovar este anúncio',
    APPROVED_TEXT_INFO:
      'Escolha se este conteúdo será um orgânico ou se vai ser um conteúdo patrocinado.',

    REVIEW_DISAPPROVE: 'Reprovar',
    REVIEW_APPROVE: 'Aprovar',
    TYPE_HERE: 'Digite aqui',
    SEND: 'Enviar',
    REPLICATE: 'Replicar',
    PROMOTE: 'Promover',
    REPORT: 'Relatório',
    EDIT: 'Editar',
    PREVIEW: 'Ver prévia',
    FACEBOOK: 'Ver no facebook',
    UNMARK: 'Desmarcar',
    GO_BACK: 'Voltar',
    START_DATE_LABEL: 'Data inicial:',
    END_DATE_LABEL: 'Data final:',
    REACHED_PEOPLE: 'Pessoas atingidas',
    POST_SUCCESS_MESSAGE: 'Replicação realizada com sucesso!',
    POST_FAILURE_MESSAGE: 'Falha ao replicar, tente novamente mais tarde!',
    POST_PROMOTE_SUCCESS_MESSAGE: 'Sucesso ao promover a postagem!',
    POST_PROMOTE_FAILURE_MESSAGE: 'Falha ao promover a postagem, tente novamente mais tarde!',
    AVAILABLE: 'disponíveis',
    EDIT_DESCRIPTION: 'Editar descrição',
    INSERT_DESCRIPTION: 'Inserir descrição',
    TOTAL_VALUE: 'Valor total',
    TO: 'à',
    PERIOD: 'Período',
    ACCOUNT_BALLANCE: 'Saldo da conta',
    APPROVE_SUCCESS: 'Mídia aprovada com sucesso!',
    APPROVE_ERROR:
      'Erro ao aprovar a mídia, tente novamente mais tarde ou entre em contato com um administrador.',
    APPROVE_WITH_BUDGET: 'Aprovar conteúdo patrocinado',
    MADE_AT: 'Feito em',
    MADE_BY: 'Feito por ',
    ON: ' em ',
    AT: ' às ',
    SEE_ON_FACEBOOK: 'Ver no facebook',
    SEE_MORE: 'Ver mais',
    SEE_LESS: 'Ver menos',
    APPROVAL: 'Aprovado por ',
    DISAPPROVING: 'Reprovado por ',
    POST_COMMENT: 'Comentário sobre a postagem',

    BLOCK: 'Bloquear',
    REMOVE: 'Remover',
    UNBLOCK: 'Desbloquear',

    post_engagement: 'Engajamento',
    messages: 'Mensagens',
    store_visits: 'Visitas ao Estabelecimento',
    link_clicks: 'Cliques no Link',
    conversions: 'Conversões',
    video_views: 'Visualizações de Video',
    lead_generation: 'Geração de Cadastros',

    DATA_ERROR: 'Atenção, a data de início do anúncio já passou!',
    DATA_ERROR_CASO_1: 'Atenção, o horário da publicação já passou!',
    DATA_ERROR_CASO_2:
      'Atenção, a data de fim do anúncio já passou! Você precisa editar para poder aprovar.',
    DATA_ERROR_CASO_3: 'Atenção, a data e o horário da publicação já passaram!',
    BUDGET_EDIT_ERROR: 'Não é permitido editar o orçamento.',
    LINK_ACTION: 'Link de ação do anúncio*',
    CAMPAING_GOAL: 'Objetivo da Campanha',

    CHANNEL: 'Canal / Posição',
    CALL_TO_ACTION_BUTTON: 'Botão de ação',

    shop_now: 'Reserve agora',
    whatsapp_message: 'Chame no whatsapp da loja',
    learn_more: 'Saiba mais',
    get_directions: 'Obter direções',
    sign_up: 'Cadastre-se',
    buy_now: 'Compre agora',
    contact_us: 'Fale conosco',
    apply_now: 'Solicite agora',

    INVESTMENT: 'Investimento',
    AUDIENCE: 'Público',
    BUDGET_TYPE: 'Tipo de verba',
    shared: 'Compartilhada',
    private: 'Privada',

    SELECTED_PIXEL: 'Pixel Selecionado',
    SELECTED_FORM: 'Formulário Selecionado',

    SCHEDULED_POST: 'Post Programado',
    TIME: 'Horário',

    stream: 'Feed',
    feed: 'Feed',
    story: 'Story',

    SAVE: 'Salvar',
    SAVE_AND_APPROVE: 'Salvar e aprovar',
    CANCEL_EDIT: 'Cancelar',
    EDIT_DESCRITION_SUCCESS: 'A mídia foi alterada com sucesso.',
    EDIT_DESCRITION_FAILURE: 'Ocorreu um erro ao tentar alterar a mídia.',
    INSUFFICIENT_THIRD_PARTY_BUDGET: 'O orçamento é muito baixo para o período selecionado',
    MULTI_IMAGE_MEDIA_CANNOT_HAVE_CALL_TO_ACTION: 'A MÍDIA MULTI-IMAGE NÃO PODE TER CALL TO ACTION',
    INVALID_MEDIA_CALL_TO_ACTION: 'CHAMADA PARA AÇÃO DE MÍDIA INVÁLIDA',
    MISSING_THIRD_PARTY_PAYMENT_METHOD: 'FALTA DO MÉTODO DE PAGAMENTO DE TERCEIROS',
    INVALID_INCENTIVE_CAMPAIGN_END_TIME: 'Data de término da camapanha de incentivo inválida',
    facebook_feed: 'Facebook Feed',
    facebook_story: 'Facebook Story',
    instagram_stream: 'Instagram Feed',
    instagram_story: 'Instagram Story',
    tiktok: 'TikTok',
    review: 'Revisão',
    comment: 'Comentário',
    link: 'Link',

    EMPTY_VIDEO_URL: 'Sem vídeo'
  },
};

const ContentActionsES = {
  ContentActions: {
    DESCRIPTION: 'Descripción',
    'MARKED-TITLE': 'Agregar una acción',
    'MARKED-NO-POSTS-TITLE': 'Publicaciones marcadas',
    'MARKED-NO-POSTS-DESCRIPTION': 'No se seleccionaron publicaciones',
    'MARKED-NO-ADS-TITLE': 'Anuncios marcados',
    'MARKED-NO-ADS-DESCRIPTION': 'No se seleccionaron anuncios',
    'MARKED-NO-STORIES-TITLE': 'Stories marcados',
    'MARKED-NO-STORIES-DESCRIPTION': 'No se seleccionaron stories',
    NOT_SUPPORTED: 'Su navegador no es compatible con video HTML5',

    CONFIRM: 'Confirmar',
    CANCEL: 'Cancelar',
    APPROVED_BID: 'Oferta maxima',
    APPROVED_DAILY_BUDGET: 'Inversión diaria',
    APPROVED_AUTO_BID: 'Usar ofertas automáticas',
    APPROVED_DTA_INIT: 'La fecha de inicio',
    APPROVED_DTA_END: 'Fecha final',
    INCORRECT_DATE: 'La fecha de finalización no puede ser anterior a la fecha de inicio.',
    MESSAGE_REQUIRED: 'Este campo es obligatorio',
    EMPTY_CONTENT_TITLE:
      'Los contenidos enviados por sus comerciantes están en esta página para su evaluación.',
    EMPTY_CONTENT_MESSAGE:
      'Aún no tienes ningún contenido para aprobar. Envíe su primera solicitud a su comerciante.',
    TOTAL_INVEST_TITLE: 'Ingrese el monto total de la inversión',
    TOTAL_INVEST_LABEL: 'Inversión total',
    TOTAL_INVEST_DAILY_LABEL: 'Inversión diaria',
    NEXT: 'Próximo',
    PUBLISH: 'Publicar',

    MARKED_ANALYSE: 'Analizar',
    MARKED_SEE: 'Para ver',
    MARKED_EDIT: 'Para editar',
    MARKED_SEND: 'Mandar',
    MARKED_UNMARK: 'Deseleccionar',
    SEARCH_PLACEHOLDER: 'Buscar...',
    SELECT_ALL: 'Seleccionar todo',
    SHARE: 'Compartir',

    INFORMATION: 'Información',
    SUBTITLE: 'Subtitular',

    // Media Cards
    APPROVED_TITLE: 'Aprobar este anuncio',
    APPROVED_TEXT_INFO: 'Elija si este contenido será contenido orgánico o patrocinado.',
    REVIEW_DISAPPROVE: 'Desaprobar',
    REVIEW_APPROVE: 'Aprobar',
    TYPE_HERE: 'Digite aquí',
    SEND: 'Mandar',
    REPLICATE: 'Replicar',
    PROMOTE: 'Promover',
    REPORT: 'Informe',
    EDIT: 'Editar',
    PREVIEW: 'Avance',
    FACEBOOK: 'Ver en Facebook',
    UNMARK: 'Desmarcar',
    GO_BACK: 'Volver',
    START_DATE_LABEL: 'La fecha de inicio:',
    END_DATE_LABEL: 'Fecha final:',
    REACHED_PEOPLE: 'Personas afectadas',
    POST_SUCCESS_MESSAGE: '¡Replicación exitosa!',
    POST_FAILURE_MESSAGE: 'No se pudo replicar, ¡inténtalo de nuevo más tarde!',
    POST_PROMOTE_SUCCESS_MESSAGE: '¡Éxito promocionando la publicación!',
    POST_PROMOTE_FAILURE_MESSAGE:
      'No se pudo promocionar la publicación. Inténtalo de nuevo más tarde.',
    AVAILABLE: 'Disponible',
    EDIT_DESCRIPTION: 'Editar Descripción',
    INSERT_DESCRIPTION: 'Insertar descripción',
    TOTAL_VALUE: 'Valor total',
    TO: 'al',
    PERIOD: 'Periodo',
    ACCOUNT_BALLANCE: 'Saldo de la cuenta',
    APPROVE_SUCCESS: '¡Medios aprobados con éxito!',
    APPROVE_ERROR:
      'Error al aprobar los medios. Vuelva a intentarlo más tarde o póngase en contacto con un administrador.',
    APPROVE_WITH_BUDGET: 'Aprobar contenido patrocinado',
    MADE_AT: 'Hecho en',
    MADE_BY: 'Creado por ',
    ON: ' en ',
    AT: 'a las',
    SEE_ON_FACEBOOK: 'Ver en facebook',
    SEE_MORE: 'Ver más',
    SEE_LESS: 'ver menos',
    APPROVAL: 'Aprobado por ',
    DISAPPROVING: 'Rechazado por ',
    POST_COMMENT: 'Publicar comentario',
    BLOCK: 'Bloquear',
    REMOVE: 'Eliminar',
    UNBLOCK: 'Desbloquear',
    post_engagement: 'Involucramiento',
    messages: 'Mensajes',
    store_visits: 'Visitas a la tienda',
    link_clicks: 'Clics en el enlace',
    conversions: 'Conversión',
    video_views: 'Reproducciones de video',
    lead_generation: 'Leads',
    DATA_ERROR: '¡Atención, la fecha de inicio del anuncio ha pasado!',
    DATA_ERROR_CASO_1: '¡Atención, ha pasado el momento de la publicación!',
    DATA_ERROR_CASO_2:
      '¡Atención, la fecha de finalización del anuncio ya pasó! Necesita editar para poder aprobar.',
    DATA_ERROR_CASO_3: '¡Atención, la fecha y hora de publicación han pasado!',
    BUDGET_EDIT_ERROR: 'No se permite editar el presupuesto.',
    LINK_ACTION: 'Enlace de acción publicitaria*',
    CAMPAING_GOAL: 'Objetivo de la campaña',

    CHANNEL: 'Canal / Posición',
    CALL_TO_ACTION_BUTTON: 'Botón de acción',

    shop_now: 'Reserva ahora',
    whatsapp_message: 'Llama al whatsapp de la tienda',
    learn_more: 'Sepa más',
    get_directions: 'Obtener direcciones',
    sign_up: 'Registrarse',
    buy_now: 'Compra ahora',
    contact_us: 'Hable con nosotros ',
    apply_now: 'Solicita ahora ',

    INVESTMENT: 'Investimento',
    AUDIENCE: 'Público',
    BUDGET_TYPE: 'Tipo de verba',
    shared: 'Compartido',
    private: 'Privada',

    SELECTED_PIXEL: 'Pixel Selecionado',
    SELECTED_FORM: 'Formulário Selecionado',

    SCHEDULED_POST: 'Publicación programada',
    TIME: 'Hora',

    stream: 'Feed',
    feed: 'Feed',
    story: 'Story',

    SAVE: 'Guardar',
    SAVE_AND_APPROVE: 'Guardar y aprobar',
    CANCEL_EDIT: 'Cancelar',
    EDIT_DESCRITION_SUCCESS: 'El medio se cambió con éxito.',
    EDIT_DESCRITION_FAILURE: 'Se ha producido un error. Intente cambiar el medio.',
    INSUFFICIENT_THIRD_PARTY_BUDGET:
      'El presupuesto es demasiado bajo para el período seleccionado',
    MULTI_IMAGE_MEDIA_CANNOT_HAVE_CALL_TO_ACTION:
      'LOS MEDIOS DE IMÁGENES MÚLTIPLES NO PUEDEN TENER LLAMADA A LA ACCIÓN',
    INVALID_MEDIA_CALL_TO_ACTION: 'LLAMADO A LA ACCIÓN DE MEDIOS NO VÁLIDO',
    MISSING_THIRD_PARTY_PAYMENT_METHOD: 'FALTA EL MÉTODO DE PAGO DE TERCEROS',
    INVALID_INCENTIVE_CAMPAIGN_END_TIME:
      'Fecha de finalización de la campaña de incentivos no válida',
    facebook_feed: 'Facebook Feed',
    facebook_story: 'Facebook Story',
    instagram_stream: 'Instagram Feed',
    instagram_story: 'Instagram Story',
    tiktok: 'TikTok',
    review: 'Revisión',
    comment: 'Comentario',
    link: 'Enlace',

    EMPTY_VIDEO_URL: 'Sin video'
  },
};

export { ContentActionsPT, ContentActionsEN, ContentActionsES };
