const OnboardingEN = {
  Onboarding: {
    initial_settings: 'Initial settings',
    goal_definitions: 'Goal definitions',
    environment_settings: 'Environment settings',
    company_training: 'Company training',
    finalization: 'Finalization',
  },
};

const OnboardingPT = {
  Onboarding: {
    initial_settings: 'Configurações iniciais',
    goal_definitions: 'Definições de objetivos',
    environment_settings: 'Configurações de ambiente',
    company_training: 'Treinamentos da central',
    finalization: 'Finalização',
    client: 'Cliente',
    bornlogic: 'Bornlogic',
    client_bornlogic: 'Cliente / Bornlogic',
    access_release_from_facebook_fm: 'Liberação de acesso do bm do facebook',
    access_brand_page: 'Acesso à página mãe da marca',
    filling_out_units_sheet: 'Preenchimento da planilha de unidades',
    groups_definition: 'Definição de grupos',
    units_investment_definition: 'Definição de investimento / unidade',
    emails_release: 'Liberação de e-mails (bgd-noreply)',
    vpn_release: 'Liberação de vpn/app no aparelho corporativo',
    ad_account_limit_release: 'Liberação do limite de ad accounts no bm',
    facebook_billing_release_and_definition: 'Definição e liberação do faturamento no Facebook',
    follow_up_media_metrics_definition: 'Definição das métricas de mídia de acompanhamento',
    target_definition: 'Definição de target',
    user_profile_permissions_settings: 'Definição das permissões para perfis de usuários',
    digital_managers_environment_creation: 'Criação do ambiente gerentes digitais',
    units_setup: 'Setup das unidades em gerentes digitais',
    content_schedule_approval: 'Aprovação do cronograma de conteúdo',
    company_teams_web_platform_training: 'Treinamento para times company na plataforma web',
    app_sellers_training: 'Treinamento para vendedores no app',
    doubt_rooms_scheduling: 'Agendamento das salas de dúvidas',
  },
};

export { OnboardingEN, OnboardingPT };
