import styled, { css } from 'styled-components';
import ReactModal from 'react-modal';

export const Container = styled.section`
  padding: ${({ theme }) => theme.spacing.px16} ${({ theme }) => theme.spacing.px32};
`;

export const InputWrapper = styled.section`
  padding: ${({ theme }) => theme.spacing.px10} 0;
`;

export const LabelSpace = styled.section`
  padding-bottom: ${({ theme }) => theme.spacing.px10};
`;

export const LogModal = styled(ReactModal)`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    height: 430px;
    width: 500px;
    overflow: hidden scroll;

    background: ${theme.colors.white};
    border-radius: ${theme.rounded.px10};
    padding: ${theme.spacing.px8};

    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`;

export const LogContainer = styled.div`
  ${({ theme }) => css`
  margin-top: ${theme.spacing.px35}
  
  display: flex;
  flex-direction: column;
  align-items: center;
  
  gap: ${theme.spacing.px8};
  `};
`;

export const Divider = styled.div`
  align-self: stretch;
  border-bottom: 1px solid grey;
  margin: 2px;
`;
