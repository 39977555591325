import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  ${({ theme }) => css`
    padding: ${theme.spacing.px16} ${theme.spacing.px32};
    width: ${theme.spacing.full};
    gap: ${theme.spacing.px20};
  `}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${({ theme }) => css`
    gap: ${theme.spacing.px16};
  `}
`;

export const Content = styled.div`
  display: flex;
  ${({ theme }) => css`
    width: ${theme.spacing.full};
  `}
`;

export const Title = styled.h5`
  ${({ theme }) => css`
    color: ${theme.colors.blueGrey2};
    font-weight: ${theme.fontWeight.bold};
  `}
`;
