import React from 'react';
import * as S from './styles';
import new_logo_bornlogic from '@images/new_logo_bornlogic.png';

const PrivacyHeader = () => {
  return (
    <S.OuterContainer>
      <img src={new_logo_bornlogic} />
    </S.OuterContainer>
  );
};

export default PrivacyHeader;
