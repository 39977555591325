import React, { useEffect, useState, useCallback } from 'react';
import { ModalDefault, Select, Checkbox, Text } from '@bornlogic/gd-design-system';
import { useFormik } from 'formik';
import { useTranslate } from 'react-translate';
import SchemaService from '@services/schema';
import SettingsService from '@services/settings';
import { useShowToast } from '@utils/hooks/Common';
import { LoadingOld } from 'components/LoadingOld/LoadingOld';
import * as S from './styles';

function UnlistAudience({ isOpen, onClose, adAccountId, unitId }) {
  const t = useTranslate('Units');

  const [isLoadingSavedAudiences, setIsLoadingSavedAudiences] = useState(false);
  const [savedAudiences, setSavedAudiences] = useState([]);

  const showToast = useShowToast();

  const getSavedAudiences = useCallback(async () => {
    if (!unitId) {
      return;
    }

    setIsLoadingSavedAudiences(true);

    const { data } = await SchemaService.getSavedAudiences('1.1', unitId);
    setSavedAudiences(data);

    setIsLoadingSavedAudiences(false);
  }, [unitId]);

  useEffect(() => {
    getSavedAudiences();
  }, [getSavedAudiences]);

  const formik = useFormik({
    initialValues: {
      audientToUnlistId: '',
      shouldDeleteClones: false,
    },
    onSubmit: async (values) => {
      const audientToUnlistId = values.audientToUnlistId;
      const shouldDeleteClones = values.shouldDeleteClones;

      const body = {
        ad_account_ids: shouldDeleteClones ? [] : [adAccountId],
        delete_clones: shouldDeleteClones,
      };

      const getPresetsAudienceResponse = await SettingsService.getPresetsAudience(
        '1.0',
        adAccountId
      );

      if (getPresetsAudienceResponse.status !== 200) {
        showToast(t('UNLIST_AUDIENCE_ERROR'), 'error');
        return;
      }

      const presetAudience = getPresetsAudienceResponse.data.find(
        (presetAudience) => presetAudience.id === audientToUnlistId
      );

      const audienceOriginId =
        presetAudience?.gd_ad_account_origin_id || presetAudience?.facebook_ad_account_origin_id;

      if (!audienceOriginId) {
        showToast(t('UNLIST_AUDIENCE_ERROR'), 'error');
        return;
      }

      const response = await SettingsService.deleteAudience(audienceOriginId, body);

      if (response.status === 200) {
        showToast(t('UNLIST_AUDIENCE_SUCCESS'), 'success');
        getSavedAudiences();
        return;
      }

      showToast(t('UNLIST_AUDIENCE_ERROR'), 'error');
    },
  });

  return (
    <ModalDefault
      isOpen={isOpen}
      title={t('UNLIST_AUDIENCE')}
      confirmBtnText={t('CONFIRM')}
      onConfirmBtnClick={formik.handleSubmit}
      confirmBtnDisabled={formik.isSubmitting}
      optionBtnText={t('CANCEL')}
      onOptionBtnClick={onClose}
      onClose={onClose}
    >
      <S.Wrapper>
        {isLoadingSavedAudiences ? (
          <LoadingOld isLoading />
        ) : savedAudiences.length === 0 ? (
          <Text type="paragraph">{t('NO_SAVED_AUDIENCES')}</Text>
        ) : (
          <S.Form onSubmit={formik.handleSubmit}>
            <Select
              placeholder={t('SELECT_AUDIENCE')}
              id="audientToUnlistId"
              name="audientToUnlistId"
              value={formik.values.audientToUnlistId}
              onChange={formik.handleChange('audientToUnlistId')}
              items={savedAudiences.map(({ id, name }) => {
                return {
                  value: id,
                  title: name,
                };
              })}
            />

            <S.CheckboxWrapper>
              <Checkbox
                id="shouldDeleteClones"
                name="shouldDeleteClones"
                checked={formik.values.shouldDeleteClones}
                onChange={formik.handleChange}
              />

              <label htmlFor="shouldDeleteClones">
                <Text>{t('UNLIST_AUDIENCE_SHOULD_DELETE_CLONES_LABEL')}</Text>
              </label>
            </S.CheckboxWrapper>
          </S.Form>
        )}
      </S.Wrapper>
    </ModalDefault>
  );
}

export default UnlistAudience;
