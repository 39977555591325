// Modules
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TooltipStyled = styled('div')`
  ${({ theme, orientation, margin, padding, border, width, align, background }) => `
        position: relative;
        margin: ${margin || theme.spacing.none};
        width: ${width || 'max-content'};
        background: ${background};
        padding: ${padding || theme.spacing.none};
        border-radius: ${theme.spacing.px10};
        box-shadow: 0 8px 10px -5px rgb(0 0 0 / 20%), 0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%);
        ${
          border
            ? `
                border: 1px solid ${theme.colors.grey2};
                `
            : ''
        }
        box-sizing: border-box;
        z-index: 999;
        & > div {
            width: ${theme.spacing.full};
            color: ${theme.colors.blueGrey3};
            text-align: ${align || 'center'};
            transition: all .3s;
            margin-top: ${theme.spacing.none} !important;
            display: flex;
            justify-content: ${align === 'left' ? 'flex-start' : 'center'};
        }
        ${
          orientation === 'right'
            ? `
                & > div {
                    right: calc(${theme.spacing.full} + ${theme.spacing.px15});

                    &:after, &:before {
                        left: 100%;
                        top: 50%;
                        border: solid transparent;
                        content: " ";
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                    }

                    &:after {
                        border-color: rgba(255, 255, 255, 0);
                        border-left-color: ${background};
                        border-width: 8px;
                        margin-top: -8px;
                    }
                    &:before {
                        border-color: rgba(206, 206, 206, 0);
                        border-left-color: #cecece;
                        border-width: 10px;
                        margin-top: -10px;
                    }
                }
                `
            : ''
        }
        ${
          orientation === 'left'
            ? `
                & > div {
                    left: calc(${theme.spacing.full} + ${theme.spacing.px15});

                    &:after, &:before {
                        right: 100%;
                        top: 50%;
                        border: solid transparent;
                        content: " ";
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                    }

                    &:after {
                        border-color: rgba(255, 255, 255, 0);
                        border-right-color: ${background};
                        border-width: 4px;
                        margin-top: -4px;
                    }
                    &:before {
                        border-color: rgba(206, 206, 206, 0);
                        border-right-color: ${background};
                        border-width: 6px;
                        margin-top: -6px;
                    }
                }
                `
            : ''
        }
        ${
          orientation === 'bottom'
            ? `
                & > div {
                    bottom: calc(${theme.spacing.full} + ${theme.spacing.px15});

                    &:after, &:before {
                        top: 100%;
                        left: 50%;
                        border: solid transparent;
                        content: " ";
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                    }

                    &:after {
                        border-color: rgba(255, 255, 255, 0);
                        border-top-color: ${background};
                        border-width: 8px;
                        margin-left: -8px;
                    }
                    &:before {
                        border-color: rgba(206, 206, 206, 0);
                        border-top-color: #cecece;
                        border-width: 10px;
                        margin-left: -10px;
                    }
                }
                `
            : ''
        }
        ${
          orientation === 'top'
            ? `
                & > div {
                    top: calc(${theme.spacing.full} + ${theme.spacing.px15});

                    &:after, &:before {
                        bottom: 100%;
                        left: 10%;
                        border: solid transparent;
                        content: " ";
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                    }

                    &:after {
                        border-color: rgba(255, 255, 255, 0);
                        border-bottom-color: ${background};
                        border-width: 8px;
                        margin-left: -8px;
                    }
                    &:before {
                        border-color: rgba(206, 206, 206, 0);
                        border-bottom-color: #cecece;
                        border-width: 10px;
                        margin-left: -10px;
                    }
                }
                `
            : ''
        }
    `}
`;

const TooltipInner = styled('div')`
  ${({ align }) => `
        display: block;
        text-align: ${align || 'center'};
    `}
`;

function Tooltip(props) {
  const { children, orientation, margin, padding, border, width, align, fixed } = props;
  const background = props?.background || '#fff';
  const [mf, setMf] = useState('');

  useEffect(() => {
    if (width) {
      const elementAlign = () => {
        const myWidth = +width.replace('px', '');
        const mfValue = myWidth / 2;
        const marginLeft = `${mfValue}px`;

        setMf(marginLeft);
      };

      elementAlign();
    }
  }, [width]);

  return (
    <Wrapper id="tooltip" fixed={fixed} mf={mf}>
      <TooltipStyled
        orientation={orientation}
        margin={margin}
        padding={padding}
        border={border}
        width={width}
        align={align}
        fixed={fixed}
        background={background}
      >
        <TooltipInner className="arrow" align={align}>
          {children}
        </TooltipInner>
      </TooltipStyled>
    </Wrapper>
  );
}

export default Tooltip;
