import React, { useState, createContext, useEffect, useCallback } from 'react';
import { OnboardingService } from '@services';

export const OnboardingContext = createContext();

export const OnboardingProvider = ({ children }) => {
  const [trails, setTrails] = useState([]);
  const [steps, setSteps] = useState([]);

  const loadTrails = async () => {
    const resp = await OnboardingService.getTrails();

    const { is_success: isSuccess, payload } = resp.data;

    if (isSuccess) {
      setTrails(payload);
    }
  };

  const loadSteps = async () => {
    const resp = await OnboardingService.getOnboardingSteps();

    const { is_success: isSuccess, payload } = resp.data;

    if (isSuccess) {
      setSteps(payload);
    }
  };

  const initializeContext = useCallback(async () => {
    await loadTrails();
    await loadSteps();
  }, []);

  useEffect(() => {
    initializeContext();
  }, [initializeContext]);

  const saveComment = (stepID, comment) => {
    const newStep = steps.find((step) => step.id === stepID);

    newStep.step_comments = [...newStep.step_comments, comment];

    setSteps(
      steps.map((step) => {
        if (step.id === newStep.id) {
          return newStep;
        }
        return step;
      })
    );
  };

  const deleteComment = (stepID, commentID) => {
    const newComments = [];
    const newStep = steps.find((step) => step.id === stepID);

    newStep.step_comments.forEach((comment) => {
      if (comment.id !== commentID) {
        newComments.push(comment);
      }
    });

    newStep.step_comments = newComments;

    setSteps(
      steps.map((step) => {
        if (step.id === newStep.id) {
          return newStep;
        }
        return step;
      })
    );
  };

  const editComment = (stepID, commentID, data) => {
    const newComments = [];
    const newStep = steps.find((step) => step.id === stepID);

    newStep.step_comments.forEach((comment) => {
      if (comment.id === commentID) {
        newComments.push({
          ...comment,
          comment: data,
        });
      } else {
        newComments.push(comment);
      }
    });

    newStep.step_comments = newComments;

    setSteps(
      steps.map((step) => {
        if (step.id === newStep.id) {
          return newStep;
        }
        return step;
      })
    );
  };

  const startStep = (stepID, startDate) => {
    const newStep = steps.find((step) => step.id === stepID);

    newStep.start_date = startDate;

    setSteps(
      steps.map((step) => {
        if (step.id === newStep.id) {
          return newStep;
        }
        return step;
      })
    );
  };

  const finishStep = (stepID, endDate) => {
    const newStep = steps.find((step) => step.id === stepID);

    newStep.end_date = endDate;

    setSteps(
      steps.map((step) => {
        if (step.id === newStep.id) {
          return newStep;
        }
        return step;
      })
    );
  };

  const updateLastStatusTrail = (trailID, last_status) => {
    const newTrail = trails.find((trail) => trail.id === trailID);

    newTrail.last_update = last_status;

    setTrails(
      trails.map((trail) => {
        if (trail.id === trailID) {
          return newTrail;
        }
        return trail;
      })
    );
  };

  const providerStructure = {
    trails,
    setTrails,

    updateLastStatusTrail,

    steps,
    setSteps,

    startStep,
    finishStep,

    deleteComment,
    editComment,
    saveComment,
  };

  return (
    <OnboardingContext.Provider value={{ ...providerStructure }}>
      {children}
    </OnboardingContext.Provider>
  );
};
