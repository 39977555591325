import styled from 'styled-components';

export const CounterBallWrapper = styled('div')`
${({
    theme, moreThanTen, alignTop, backgroundColor, fontColor,
  }) => `
    display: inline-flex;
    position: relative;
    span {
        font-size: ${theme.fontSize.px11};
        color: ${fontColor ? theme.colors[fontColor] : theme.colors.white};
        background: ${backgroundColor ? theme.colors[backgroundColor] : theme.colors.blueGrey3};
        border-radius: 10px;
        padding: 2px 6px 3px;
        margin: 0 1px;
    }
    span:nth-child(2) {
        background: #187db5;
    }
    ${alignTop
    ? `
            top: -13px;
        ` : ''
}
    ${moreThanTen
    ? `span:nth-child(1) {
            padding: 2px 4px 3px;
        }` : ''
}
`}`;
