import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { LISTING_PAGE_COUNT } from '@redux/generic/types';
import { getBusinessID } from '@utils/auth';
import MenuLink from '../MenuLink';
import { validateCapability } from '@utils/helpers';

function ReportSidebar() {
  const { businessTokenData } = useSelector((state) => state.authenticationReducer);

  const {
    listingPageCount: {
      data: { groupTotalCount },
    },
  } = useSelector((state) => state.genericReducer);

  const dispatch = useDispatch();
  const match = useRouteMatch();

  useEffect(() => {
    dispatch({
      type: LISTING_PAGE_COUNT.REQUEST,
      version: '1.0',
      tenantId: getBusinessID(),
      unit: match.params.code,
    });
  }, [match.params.code, dispatch]);

  const links = [];

  if (validateCapability(businessTokenData, 'REPORT')) {
    links.push({
      name: 'Overview',
      conditional: 'Global',
      icon: ['fal', 'globe'],
      translator: 'OVERVIEW',
      disabled: false,
    });
  }

  if (validateCapability(businessTokenData, 'GENERAL_LISTING')) {
    links.push({
      name: 'Listing',
      conditional: 'Groups',
      icon: ['fal', 'layer-group'],
      translator: 'LIST_ALL',
      disabled: false,
    });
  }

  if (validateCapability(businessTokenData, 'ENGAGEMENT')) {
    links.push({
      name: 'Engagement',
      conditional: 'All',
      icon: ['fal', 'thumbs-up'],
      translator: 'ENGAGEMENT',
      disabled: false,
    });
  }

  if (validateCapability(businessTokenData, 'VIDEO_VIEW')) {
    links.push({
      name: 'VideoView',
      conditional: 'All',
      icon: ['fal', 'video'],
      translator: 'VIDEO_VIEW',
      disabled: false,
    });
  }

  if (validateCapability(businessTokenData, 'REPORT_COMMENTS')) {
    links.push({
      name: 'Comments',
      conditional: 'All',
      icon: ['fal', 'comment'],
      translator: 'COMMENTS',
      disabled: false,
    });
  }

  if (validateCapability(businessTokenData, 'ACTIVITY')) {
    if (groupTotalCount > 0) {
      links.push({
        name: 'Activity',
        conditional: 'All',
        icon: ['fal', 'heart-rate'],
        translator: 'ACTIVITY',
        disabled: false,
      });
    }
  }

  links.push({
    name: 'Dashboard',
    conditional: 'All',
    icon: ['fal', 'store-alt'],
    translator: 'FREQUENCY',
    disabled: false,
  });

  if (validateCapability(businessTokenData, 'REPORT_RANKING')) {
    links.push({
      name: 'Ranking',
      conditional: 'Accounts',
      icon: ['fal', 'child'],
      translator: 'RANKING',
      disabled: false,
    });
  }

  if (validateCapability(businessTokenData, 'REPORT_AUDIENCE')) {
    links.push({
      name: 'Audience',
      conditional: 'Age&Gender',
      icon: ['fal', 'glasses'],
      translator: 'AUDIENCE',
      disabled: false,
    });
  }

  if (validateCapability(businessTokenData, 'REPORT')) {
    links.push({
      name: 'Custom',
      conditional: 'All',
      icon: ['fal', 'heart'],
      translator: 'CUSTOM',
      disabled: false,
    });
  }

  return <MenuLink links={links} page="Report" />;
}

export default ReportSidebar;
