const BreadCrumbEN = {
  BreadCrumb: {
    OVERALL: 'Overall',
    ALL_GROUPS: 'All Groups',
    SELECT_WHICH_ONE: 'Select which one will be displayed',
    YOU_CAN_CHOOSE_ONE_GROUP: 'You can choose one group or all of them',
    CHOOSE_THIS_LEVEL: 'Select this level',
    SEARCH: 'Search',
    OPEN: 'Open',
  },
};

const BreadCrumbPT = {
  BreadCrumb: {
    OVERALL: 'Geral',
    ALL_GROUPS: 'Todos os grupos',
    SELECT_WHICH_ONE: 'Selecione qual irá exibir',
    YOU_CAN_CHOOSE_ONE_GROUP: 'Você pode selecionar um grupo ou todos',
    CHOOSE_THIS_LEVEL: 'Selecionar esse nível',
    SEARCH: 'Buscar',
    OPEN: 'Abrir',
  },
};

const BreadCrumbES = {
  BreadCrumb: {
    OVERALL: 'Geral',
    ALL_GROUPS: 'Todos los grupos',
    SELECT_WHICH_ONE: 'Seleccione cuál mostrar',
    YOU_CAN_CHOOSE_ONE_GROUP: 'Puede seleccionar un grupo o todos',
    CHOOSE_THIS_LEVEL: 'Seleccione este nivel',
    SEARCH: 'Búsqueda',
    OPEN: 'Abrir',
  },
};

export { BreadCrumbPT, BreadCrumbEN, BreadCrumbES };
