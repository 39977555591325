const VideoViewEN = {
  VideoView: {
    TITLE: 'Video View',
    SUBTITLE: 'Find out how your customers are viewing your videos in this report',
    OF_THE_TOTAL: 'of the total',
    AMOUNT_INVESTED: 'Amount invested',
    AMOUNT_AVAILABLE: 'Amount available',
    OF: 'of',
    video_view: 'Video views',
    post_engagement: 'Video engagement',
    impressions: 'Impressions',
    reach: 'People reached',
    video_thruplay: 'Thruplays',
    clicks: 'Clicks',
    total_videos: 'Videos total',
    average_video_length: 'Average video length',
    video_display_time: 'Video display time',
    video_view_warn: 'Number of times the video was watched for more than 3 seconds',
    post_engagement_warn: 'Sum of reactions, likes, comments, shares, clicks and video views',
    impressions_warn: 'Number of times the video was shown',
    reach_warn: 'How many people have seen your video',
    video_thruplay_warn:
      'Number of times the video was played to completion, or for at least 15 seconds',
    clicks_warn: 'Total number of video clicks received',
    PLACEMENT: 'Placement',
    COMPARED_TO: 'Compared to the',
    AND: 'when the value was',
    FOOTER_TITLE: 'More information about video production',
    engagementRate: 'Engagement rate on video',
    costPerView: 'Cost per View',
    AUDIENCE_TITLE: 'Audience data in videos',
    PUBLIC: 'Public',
    DEVICES: 'Devices',
    GENRE: 'Genre',
    ORGANIC: 'Organic',
    PAID: 'Paid',
    OTHERS: 'Others',
    FEMALE: 'Female',
    MALE: 'Male',
    UNKNOWN: 'Unknown',
    TITLE_TOP: 'best videos',
    VIEWS: 'Views',
    PERIOD: 'Period',
    INVESTMENT: 'Investment',
    EMPTY_DESCRIPTION: 'There is no description',
    SEE_PREVIEW: 'See',
    AD: 'Ads',
    POST: 'Posts',
    NO_VIDEOS: 'There is no videos for ',
    SEE_MORE: 'See more',
    SEE_LESS: 'See less',
    NAME: 'Name',
    DESCRIPTION: 'Description',
    TO: 'to',
    METRICS: 'Metrics',
    twenty_five: '25%',
    fifty: '50%',
    seventy_five: '75%',
    ninety_five: '95%',
    one_hundred: '100%',
    GO_TO_FEED: 'Go to feed',
    PUBLICATION_DATE: 'Publication Date',
    PLACEMENT_TOOLTIP:
      'Depending on the goal chosen when creating a campaign, ads can appear on Facebook, Instagram, Messenger and the Audience Network.',
    AMOUNT_INVESTED_TOOLTIP: 'Total spent by your units on video content',
    PUBLIC_TOOLTIP:
      'Indicates the number of people who received any publication from the Page on their screen',
    DEVICES_TOOLTIP: 'Number of views by device types',
    GENRE_TOOLTIP:
      'They refer to the gender of the people reached with the content produced. People who do not report their gender appear as "Unknown"',
    FILTER: 'Filter',
    CPE_TOOLTIP:
      "Cost per engagement: calculates the investment for each engagement. It's calculated by the total amount spent divided by the number of engagements.",
    ER_TOOLTIP:
      "Cost per engagement: calculates the investment for each engagement. It's calculated by the total amount spent divided by the number of engagements.",
    CPM_TOOLTIP:
      "Cost per 1,000 impressions: calculates the price of 1,000 impressions in ads. It's calculated by the total amount spent, divided by the number of impressions, and multiplied by 1,000.",
    CPV_TOOLTIP:
      "Cost per view: calculates the price of video views in ads. It's calculated by the total amount spent, divided by the number of views.",
    VR_TOOLTIP:
      "View Rate: calculates the percentage of views related to the number of times an ad was shown. It's calculated by the total video views, divided by the total impressions, and multiplied by 100.",
    CPR_TOOLTIP:
      'Cost per reach: calculates the price of a thousand users reached. It’s calculated by the total amount spent, divided by the number of reached users multiplied by thousand.',
    CPC_TOOLTIP:
      "Cost per click: calculates the price of each click on ads. It's calculated by the total amount spent, divided by total clicks.",
    P_95: 'Video views of at least 95%',
  },
};

const VideoViewPT = {
  VideoView: {
    TITLE: 'Visualização em vídeo',
    SUBTITLE: 'Saiba como seus consumidores estão visualizando seus vídeos neste relatório',
    OF_THE_TOTAL: 'do total',
    AMOUNT_INVESTED: 'Verba investida',
    AMOUNT_AVAILABLE: 'Verba disponível',
    OF: 'de',
    video_view: 'Total visualizações',
    post_engagement: 'Engajamento nos vídeos',
    impressions: 'Impressões',
    reach: 'Pessoas alcançadas',
    video_thruplay: 'Thruplays',
    clicks: 'Cliques',
    total_videos: 'Número de vídeos produzidos',
    average_video_length: 'Média de duração dos vídeos',
    video_display_time: 'Tempo de exibição (horas)',
    video_view_warn: 'Quantidade de vezes que o vídeo foi assistido por mais de 3 segundos',
    post_engagement_warn:
      'Soma das reações, curtidas, comentários, compartilhamentos, cliques e visualizações de vídeos',
    impressions_warn: 'Quantidade de vezes que seu vídeo foi mostrado para um usuário',
    reach_warn: 'Quantidade de pessoas que viram seu vídeo',
    video_thruplay_warn:
      'Número de vezes que seu vídeo foi reproduzido até a conclusão ou por pelo menos 15 segundos',
    clicks_warn: 'Cliques que os videos receberam',
    PLACEMENT: 'Posicionamentos',
    COMPARED_TO: 'Comparado ao periodo de',
    AND: 'quando o valor foi de',
    FOOTER_TITLE: 'Mais informações da produção de vídeos',
    engagementRate: 'Taxa de engajamento no vídeo',
    costPerView: 'Custo por visualização',
    AUDIENCE_TITLE: 'Dados de audiência nos vídeos',
    PUBLIC: 'Público',
    DEVICES: 'Dispositivos',
    GENRE: 'Gênero',
    ORGANIC: 'Orgânico',
    PAID: 'Pago',
    OTHERS: 'Outros',
    FEMALE: 'Feminino',
    MALE: 'Masculino',
    UNKNOWN: 'Desconhecido',
    TITLE_TOP: 'melhores vídeos',
    VIEWS: 'Visualizações',
    PERIOD: 'Período',
    INVESTMENT: 'Investimento',
    EMPTY_DESCRIPTION: 'Não há descrição',
    SEE_PREVIEW: 'Ver',
    AD: 'Anúncios',
    POST: 'Postagens',
    NO_VIDEOS: 'Não há vídeos para ',
    SEE_MORE: 'Ver mais',
    SEE_LESS: 'Ver menos',
    NAME: 'Nome',
    DESCRIPTION: 'Descrição',
    TO: 'a',
    METRICS: 'Métricas',
    twenty_five: '25%',
    fifty: '50%',
    seventy_five: '75%',
    ninety_five: '95%',
    one_hundred: '100%',
    GO_TO_FEED: 'Ir para o Feed',
    PUBLICATION_DATE: 'Data da Publicação',
    PLACEMENT_TOOLTIP:
      'Dependendo do objetivo escolhido ao criar uma campanha, os anúncios podem aparecer no Facebook, no Instagram, no Messenger e no Audience Network.',
    AMOUNT_INVESTED_TOOLTIP: 'Total gasto por suas unidades em conteúdos de vídeo',
    PUBLIC_TOOLTIP:
      'Indica o número de pessoas que receberam qualquer publicação da Página na tela delas',
    DEVICES_TOOLTIP: 'Quantidade de visualizações por tipos de dispositivos',
    GENRE_TOOLTIP:
      'Referem-se à ao gênero das pessoas alcançadas com os conteúdos produzidos. Pessoas que não informam o próprio gênero aparecem como "Desconhecido"',
    FILTER: 'Filtrar',
    CPE_TOOLTIP:
      'Custo por engajamento: calcula o valor investido para cada engajamento. Sua fórmula é: valor investido dividido por engajamento.',
    ER_TOOLTIP:
      'Taxa de engajamento: calcula a porcentagem de pessoas que engajaram com seu conteúdo. Sua fórmula é: número de engajamentos dividido por impressões, vezes 100.',
    CPM_TOOLTIP:
      'Custo por mil: calcula o preço a cada mil impressões de anúncio. Sua fórmula é: quanto já investiu, dividido pelo número de impressões e multiplicado por mil.',
    CPV_TOOLTIP:
      'Custo por visualização: calcula o preço de cada visualização de vídeo anunciado. Sua fórmula é: quanto já investiu, dividido pelo número de visualizações.',
    VR_TOOLTIP:
      'Taxa de Visualização: calcula a porcentagem de visualizações em relação à quantidade de vezes que o anúncio foi exibido. Sua fórmula é: visualizações dividido pelas impressões vezes 100.',
    CPR_TOOLTIP:
      'Custo por alcance: calcula o preço de mil pessoas alcançadas. Sua fórmula é: quanto já investiu, dividido pelo número de pessoas alcançadas vezes mil.',
    CPC_TOOLTIP:
      'Custo por clique: calcula o preço de cada clique em anúncios. Sua fórmula é: valor investido dividido por quantidade de cliques.',
    P_95: 'Visualizações de mais de 95% do vídeo',
  },
};

const VideoViewES = {
  VideoView: {
    TITLE: 'Visualización de vídeo',
    SUBTITLE: 'Descubra cómo sus consumidores ven sus videos en este informe',
    OF_THE_TOTAL: 'del total',
    AMOUNT_INVESTED: 'Importes invertidos',
    AMOUNT_AVAILABLE: 'Importes disponibles',
    OF: 'de',
    video_view: 'Total visualizaciones',
    post_engagement: 'Participación en vídeos',
    impressions: 'Impresiones',
    reach: 'Personas alcanzadas',
    video_thruplay: 'Thruplays',
    clicks: 'Clics',
    total_videos: 'Cantidad de vídeos producidos',
    average_video_length: 'Duración media de los vídeos',
    video_display_time: 'Tiempo de visualización (horas)',
    video_view_warn: 'Número de veces que se vio el video durante más de 3 segundos',
    post_engagement_warn:
      'Suma de reacciones, me gusta, comentarios, acciones, clics y visualizaciones de videos',
    impressions_warn: 'Número de veces que se mostró su video a un usuario',
    reach_warn: 'Cantidad de personas que vieron tu video',
    video_thruplay_warn:
      'Número de veces que su video se reprodujo hasta su finalización o durante al menos 15 segundos',
    clicks_warn: 'Clics que recibieron los videos',
    PLACEMENT: 'Posicionamientos',
    COMPARED_TO: 'Comparado con el período de',
    AND: 'cuando el valor era',
    FOOTER_TITLE: 'Más informaciones sobre producción de vídeos',
    engagementRate: 'Índice de participación de vídeos',
    costPerView: 'Costo por visualización',
    AUDIENCE_TITLE: 'Datos de audiencia en videos',
    PUBLIC: 'Público',
    DEVICES: 'Herramientas',
    GENRE: 'Género',
    ORGANIC: 'Orgánico',
    PAID: 'Pagado',
    OTHERS: 'Otros',
    FEMALE: 'Femenino',
    MALE: 'Masculino',
    UNKNOWN: 'Desconocido',
    TITLE_TOP: 'mejores vídeos',
    VIEWS: 'Visualizaciones',
    PERIOD: 'Periodo',
    INVESTMENT: 'Inversión',
    EMPTY_DESCRIPTION: 'No hay descripción',
    SEE_PREVIEW: 'Ver',
    AD: 'Anuncios',
    POST: 'Publicaciones',
    NO_VIDEOS: 'No hay videos para ',
    SEE_MORE: 'Ver más',
    SEE_LESS: 'Ver menos',
    NAME: 'Nombre',
    DESCRIPTION: 'Descripción',
    TO: 'a',
    METRICS: 'Indicadores',
    twenty_five: '25%',
    fifty: '50%',
    seventy_five: '75%',
    ninety_five: '95%',
    one_hundred: '100%',
    GO_TO_FEED: 'Ir a Feed',
    PUBLICATION_DATE: 'Fecha de Publicación',
    PLACEMENT_TOOLTIP:
      'Dependiendo del objetivo elegido al crear una campaña, los anuncios pueden aparecer en Facebook, Instagram, Messenger y Audience Network.',
    AMOUNT_INVESTED_TOOLTIP: 'Total gastado por sus unidades en contenido de video',
    PUBLIC_TOOLTIP:
      'Indica el número de personas que han recibido alguna publicación de la página en su pantalla.',
    DEVICES_TOOLTIP: 'Número de vistas por tipo de dispositivo',
    GENRE_TOOLTIP:
      'Se refieren al género de las personas alcanzadas con el contenido producido. Las personas que no informan sobre su género aparecen como "Desconocido".',
    FILTER: 'Filtrar',
    CPE_TOOLTIP:
      'Costo por participación: calcula la cantidad invertida para cada participación. Su fórmula es: monto invertido dividido por compromiso.',
    ER_TOOLTIP:
      'Tasa de interacción: calcula el porcentaje de personas que han interactuado con su contenido. Su fórmula es: número de interacciones dividido por impresiones multiplicado por 100.',
    CPM_TOOLTIP:
      'Costo por mil: calcula el precio por mil impresiones de anuncios. Su fórmula es: cuánto has invertido ya, dividido por el número de impresiones y multiplicado por mil.',
    CPV_TOOLTIP:
      'Costo por vista: calcula el precio de cada vista de video anunciada. Su fórmula es: cuánto ya ha invertido, dividido por el número de visitas.',
    VR_TOOLTIP:
      'Tasa de vistas: calcula el porcentaje de vistas en relación con la cantidad de veces que se mostró el anuncio. Su fórmula es: vistas divididas por impresiones multiplicadas por 100.',
    CPR_TOOLTIP:
      'Costo por alcance: calcula el precio de mil personas alcanzadas. Su fórmula es: cuánto ya has invertido, dividido por el número de personas alcanzadas por mil.',
    CPC_TOOLTIP:
      'Costo por clic: calcula el precio de cada clic en un anuncio. Su fórmula es: cantidad invertida dividida por la cantidad de clics.',
    P_95: 'Reproducciones de más del 95% del vídeo',
  },
};

export { VideoViewEN, VideoViewPT, VideoViewES };
