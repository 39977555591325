import create from 'zustand';
import { devtools } from 'zustand/middleware';

const createTradeAdsSlice = (set) => ({
  exchangeTenant: null,
  setExchangeTenant: (tenant) => {
    set(() => ({ exchangeTenant: tenant }));
  },
});

const createInstagramSlice = (set) => ({
  code: null,
  setCode: (code) => {
    set(() => ({ code: code }));
  },
});

const useStore = create(
  devtools((set, get) => ({
    ...createTradeAdsSlice(set, get),
    ...createInstagramSlice(set, get),
  }))
);

export default useStore;
