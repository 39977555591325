import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { LoadingOld } from 'components/LoadingOld/LoadingOld';
import InputDefault from 'components/InputDefault/InputDefault';

import DatePicker from 'react-datepicker';

import ReactModal from 'react-modal';
import Switch from 'react-switch';
import { useTranslate } from 'react-translate';

import 'react-datepicker/dist/react-datepicker.css';

import { UPDATE_AD } from '@redux/feed/types';

import * as S from './styles';
import { primaryTheme } from 'styles/themes';

const ABSOLUTE_STATUS = ['paused', 'active', 'finished'];

function EditModal({ media, closeModal, showEditModal }) {
  const dispatch = useDispatch();
  const t = useTranslate('Feed');
  const {
    params: { code },
  } = useRouteMatch();
  const { editModal } = useSelector((state) => state.feedReducer);

  const [checked, setChecked] = useState(media.status === 'active');
  const [startDate, setStartDate] = useState(media.start_time ? new Date(media.start_time) : '');
  const [endDate, setEndDate] = useState(media.end_time ? new Date(media.end_time) : '');
  const [linkValue, setLinkValue] = useState(media.link_url || '');
  const [budget, setBudget] = useState(media.daily_budget || media.lifetime_budget || '');

  function handleSubmit() {
    const tzoffset = new Date().getTimezoneOffset() * 60000;
    const endDateToSend = new Date(new Date(endDate) - tzoffset).toISOString().slice(0, -1);
    const startDateToSend = new Date(startDate).toISOString().split('T')[0];
    const budgetToSend =
      typeof budget === 'string' ? budget.split('$')[1].replace(/\./g, '') : budget;

    let status = '';

    if (checked) {
      status = 'active';
    } else if (media.status === 'active') {
      status = 'paused';
    } else {
      status = media.status;
    }

    dispatch({
      type: UPDATE_AD.REQUEST,
      version: '1.1',
      code: code,
      adId: media.id,
      body: {
        start_date: ABSOLUTE_STATUS.includes(media.status) ? null : startDateToSend,
        end_date: endDateToSend,
        budget: budgetToSend,
        call_to_action_link_url: linkValue !== media.link_url ? linkValue : null,
        timezone_offset: -3,
        status,
      },
    });

    closeModal();
  }

  if (showEditModal !== media.id) {
    return null;
  }

  return (
    <ReactModal
      isOpen={showEditModal === media.id}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={closeModal}
      style={{
        content: {
          height: 'auto',
          overflow: 'hidden',
          maxHeight: '50%',
          minHeight: '360px',
          width: '30%',
          margin: 'auto',
          background: primaryTheme.colors.white,
          borderRadius: primaryTheme.rounded.px10,
          padding: primaryTheme.spacing.px32,
          overflow: 'visible',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: '2004',
        },
      }}
    >
      {editModal.isLoading && (
        <S.LoadingWrapper>
          <LoadingOld isLoading={true} centerIcon />
        </S.LoadingWrapper>
      )}
      <S.Title>{t('EDIT')}</S.Title>
      {(media.status === 'active' || media.status === 'paused') && (
        <S.SwitchWrapper>
          {checked ? (
            <S.SwitchText>{t('PAUSE_AD')}</S.SwitchText>
          ) : (
            <S.SwitchText>{t('ACTIVE_AD')}</S.SwitchText>
          )}
          <Switch
            checked={checked}
            onChange={() => setChecked((state) => !state)}
            onColor={primaryTheme.colors.blueSecurity}
            onHandleColor={primaryTheme.colors.white}
            handleDiameter={13}
            uncheckedIcon={false}
            checkedIcon={false}
            height={16}
            width={32}
            disabled={media.status !== 'active' && media.status !== 'paused'}
            className="react-switch"
            id="material-switch"
          />
        </S.SwitchWrapper>
      )}

      <S.Subtitle>{t('EDIT_MODAL_SUBTITLE')}</S.Subtitle>
      <S.Wrapper>
        <S.Period>
          <S.PeriodText>{t('PERIOD')}</S.PeriodText>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            locale="pt-BR"
            disabled={ABSOLUTE_STATUS.includes(media.status)}
          />
          <S.DateText>{t('TO')}</S.DateText>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            locale="pt-BR"
          />
        </S.Period>
        <S.Budget>
          <S.PeriodText>
            {media.daily_budget ? t('DAILY_BUDGET') : t('LIFETIME_BUDGET')}
          </S.PeriodText>
          <div>
            <S.CustomInput
              prefix="R$"
              thousandSeparator={'.'}
              decimalSeparator={','}
              placeholder={''}
              value={budget}
              onChangeEvent={(e) => setBudget(e.target.value)}
              precision="0"
            />
          </div>
        </S.Budget>
      </S.Wrapper>
      <S.LinkWrapper>
        <S.LinkLabel>{t('AD_ACTION_LINK')}</S.LinkLabel>
        <InputDefault
          type="text"
          id="link"
          name="link"
          onChange={(e) => setLinkValue(e.target.value)}
          value={linkValue}
        />
        <S.OptionalLabel>{t('OPTIONAL')}</S.OptionalLabel>
      </S.LinkWrapper>
      <S.FooterWrapper>
        <S.CancelButton onClick={closeModal}>{t('CANCEL')}</S.CancelButton>
        <S.SaveApproveButton
          onClick={handleSubmit}
          eventName="click_confirm_edit_ad_feed_web_button"
        >
          {t('CONFIRM')}
        </S.SaveApproveButton>
      </S.FooterWrapper>
    </ReactModal>
  );
}

export default EditModal;
