// Types
import { SHOW_TOAST } from './types';

const INITIAL_STATE = {
  toast: {
    message: '',
    type: '',
  },
};

// message: string
// messageType: string ("success", "info", "orange3", "error")

const toastReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_TOAST.SUCCESS:
      return {
        ...state,
        toast: {
          message: action.message,
          type: action.messageType,
        },
      };

    case SHOW_TOAST.RESET:
      return {
        ...state,
        toast: {
          message: '',
          type: '',
        },
      };

    default:
      return state;
  }
};

export default toastReducer;
