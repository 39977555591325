import styled from 'styled-components';
import { primaryTheme as theme } from '@styles/themes';

export const OptionStyled = styled.option`
  color: #0a3b5b;
  font-size: 13px;
`;

export const Wrapper = styled.div`
  margin-left: ${theme.spacing.px20};
  position: relative;
  cursor: pointer;
  width: 20px;
  height: 20px;
  background: ${theme.colors.blueGrey6};
  border-radius: 100%;
  padding: 9px 7px 9px 11px;
  svg {
    color: ${theme.colors.blueGrey3};
  }
`;
