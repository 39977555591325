// Modules
import React from 'react';

import * as S from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Active({ name }) {
  return (
    <S.GroupActive data-testid="active">
      <FontAwesomeIcon
        icon={['fal', 'layer-group']}
        style={{ fontSize: '12px', marginRight: '8px', marginBottom: '1px' }}
      />
      {name}
    </S.GroupActive>
  );
}

export default Active;
