import { useState, useEffect, useCallback } from 'react';

import { OnboardingService } from '@services';

const useTenantActiveTrial = () => {
  const [activeTrail, setActiveTrail] = useState([]);
  const [isOnboardingFinished, setOnboardingFinished] = useState(false);
  const [trails, setTrails] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadTenantActiveTrial = useCallback(
    async (index) => {
      let trailFinished = true;

      if (!trails[index]) return;

      const trail = trails[index];
      const respSteps = await OnboardingService.getStepsByTrail(trail.id);

      const { is_success: isSuccess, payload: trailSteps } = respSteps.data;

      if (isSuccess && trailSteps) {
        trailSteps.forEach((step) => {
          if (trailFinished && step.is_required && (!step.start_date || !step.end_date)) {
            trailFinished = false;
          }
        });

        if (!trailFinished) {
          setLoading(false);
          setActiveTrail({ ...trail, steps: trailSteps });
        }

        if (trailFinished && trails[index + 1]) {
          loadTenantActiveTrial(index + 1);
        }

        if (trailFinished && !trails[index + 1]) {
          setLoading(false);
          setOnboardingFinished(true);
        }
      } else {
        setActiveTrail(trail);
        setOnboardingFinished(true);
        setLoading(false);
      }
    },
    [trails]
  );

  const orderTrails = (a, b) => {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }

    return 0;
  };

  useEffect(() => {
    async function loadTrails() {
      const res = await OnboardingService.getTrails();

      const { is_success: isSuccess, payload } = res.data;

      if (isSuccess) {
        setTrails(payload.sort(orderTrails));
      }
    }

    loadTrails();
  }, []);

  useEffect(() => {
    if (trails.length > 0) {
      setLoading(true);
      loadTenantActiveTrial(0);
    }
  }, [trails, loadTenantActiveTrial]);

  return {
    activeTrail,
    isOnboardingFinished,
    loading,
    trails,
  };
};

export default useTenantActiveTrial;
