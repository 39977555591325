import CryptoJS from 'crypto-js';
import Cookies from 'universal-cookie';

const isDEV = process.env.NODE_ENV === 'development';
const host = 'gerentesdigitais.com';
const defaultCompany = process.env.REACT_APP_TENANT;
const auth_server_url = 'https://sso.bornlogic.com';
const getSubDomain = () => {
  if (isDEV) {
    return defaultCompany;
  }
  return window.location.host.split('.')[0];
};
const client_id = 'gd-web';
const LOCALHOST_URI = 'http://localhost:3000/#/AuthLogin';
const redirect_uri = isDEV
  ? LOCALHOST_URI
  : `https://${getSubDomain()}.gerentesdigitais.com/#/AuthLogin`;

const cookies = new Cookies();
const scopes = [
  'email',
  'openid',
  'profile',
  'graph_api_access',
  'manage_businesses',
  'manage_facebook_assets',
  'manage_instagram_assets',
  'manage_files',
  'offline_access',
];

const randomStr = (length) => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const generateCodeChallenge = (code_verifier) => {
  let sha256 = CryptoJS.SHA256(code_verifier);
  let base64 = sha256
    .toString(CryptoJS.enc.Base64)
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');

  return base64;
};

const setCookies = (state, code_verifier) => {
  //validate value after authserver callback
  document.cookie = 'auth_state=' + state + '; path=/';
  //required for obtaining access token
  document.cookie = 'auth_code_verifier=' + code_verifier + '; path=/';
};

function getCookie(cname) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

const logoutBusiness = () => {
  localStorage.removeItem('business-token');
};

export const adjustSubDomain = (subdomain) =>
  isDEV ? LOCALHOST_URI : `https://${subdomain}.gerentesdigitais.com/#/AuthLogin`;

export const logoutOldUser = () => {
  if (getCookie('user-token') !== '') {
    cookies.remove('user-token', { domain: isDEV ? 'localhost' : `.${host}` });
    logoutBusiness();
  }
};

export const validateObject = (object) => object !== null && object !== undefined;

export const setBusinessTokenStorage = (businessToken) =>
  localStorage.setItem('business-token', businessToken);

export const getBusinessTokenStorage = () => localStorage.getItem('business-token');

export const hasBusinessTokenStorage = () => validateObject(getBusinessTokenStorage());

export { auth_server_url, getSubDomain };

export const setTokens = ({ access_token, id_token }) => {
  localStorage.setItem('authservertoken', access_token);
  localStorage.setItem('authserveridtoken', id_token);
};

export const getTokens = () => {
  return {
    access_token: localStorage.getItem('authservertoken'),
    id_token: localStorage.getItem('authserveridtoken'),
  };
};

export const hasTokens = () =>
  validateObject(localStorage.authservertoken) && validateObject(localStorage.authserveridtoken);

export const getParamsForToken = (code) => {
  const params = new URLSearchParams();
  params.append('code', code);
  params.append('grant_type', 'authorization_code');
  params.append('code_verifier', getCookie('auth_code_verifier'));
  params.append('client_id', client_id);
  params.append('redirect_uri', redirect_uri);
  return params;
};

export const getCallbackParams = () => {
  const queryString = window.location.hash.split('?')[1];
  const urlParams = new URLSearchParams(queryString);

  const code = urlParams.get('code');
  const state = urlParams.get('state');
  const session_state = urlParams.get('session_state');
  const scope = urlParams.get('scope');

  if (!!code && !!state) {
    return {
      code,
      state,
      session_state,
      scope,
    };
  }
  return false;
};

export const isValidState = (state) => state === getCookie('auth_state');

export const getCookies = () => {
  const state = getCookie('auth_state');
  const code_verifier = getCookie('auth_code_verifier');
  return {
    state,
    code_verifier,
  };
};

export const mountUrlToLogin = () => {
  const code_challenge_method = 'S256';
  const response_type = 'code';
  const prompt = 'consent';
  const code_verifier = randomStr(100);
  const nonce = randomStr(100);
  const state = randomStr(100);
  const code_challenge = generateCodeChallenge(code_verifier);

  const loginUrl = new URL(auth_server_url + '/connect/authorize');
  loginUrl.searchParams.append('client_id', client_id);
  loginUrl.searchParams.append('redirect_uri', redirect_uri);
  loginUrl.searchParams.append('response_type', response_type);
  loginUrl.searchParams.append('prompt', prompt);
  loginUrl.searchParams.append('scope', scopes.join(' '));
  loginUrl.searchParams.append('code_challenge_method', code_challenge_method);
  loginUrl.searchParams.append('code_challenge', code_challenge);
  loginUrl.searchParams.append('nonce', nonce);
  loginUrl.searchParams.append('state', state);
  setCookies(state, code_verifier);
  window.location.href = loginUrl;
};

export const mountUrlToLogout = (shouldReload = true) => {
  const url = new URL(`${auth_server_url}/connect/endsession`);
  const token = localStorage.authserveridtoken;
  if (token) {
    url.searchParams.append('id_token_hint', token);
    url.searchParams.append('post_logout_redirect_uri', redirect_uri);
    window.location.href = url.href;
    localStorage.clear();
    return;
  }
  localStorage.clear();
  if (shouldReload) window.location.reload();
};

export const reLoginUser = () => {
  localStorage.clear();
  window.location.reload();
};

export const fallbackRelogin = () => {
  const hasCallbackParams =
    window.location.href.includes('code') && window.location.href.includes('state');
  if (hasCallbackParams) {
    localStorage.clear();
    window.location.href = redirect_uri;
  }
};
