export const RevokeInviteModalEN = {
  RevokeInviteModal: {
    REVOKE_INVITE_MODAL_TITLE: 'Revoke invitation',
    REVOKE_INVITE_MODAL_SUBTITLE: 'Do you really want to delete the invitation?',
    REVOKE_INVITE_MODAL_CONFIRM: 'Revoke',
    REVOKE_INVITE_MODAL_CANCEL: 'Cancel',
    REVOKE_INVITE_MODAL_SUCCESS_MESSAGE: 'Invitation revoked successfully!',
    REVOKE_INVITE_MODAL_ERROR_MESSAGE: 'Failed to revoke invitation!',
  },
};

export const RevokeInviteModalPT = {
  RevokeInviteModal: {
    REVOKE_INVITE_MODAL_TITLE: 'Revogar convite',
    REVOKE_INVITE_MODAL_SUBTITLE: 'Deseja realmente excluir o convite?',
    REVOKE_INVITE_MODAL_CONFIRM: 'Revogar',
    REVOKE_INVITE_MODAL_CANCEL: 'Cancelar',
    REVOKE_INVITE_MODAL_SUCCESS_MESSAGE: 'Convite revogado com sucesso!',
    REVOKE_INVITE_MODAL_ERROR_MESSAGE: 'Falha ao revogar convite!',
  },
};

export const RevokeInviteModalES = {
  RevokeInviteModal: {
    REVOKE_INVITE_MODAL_TITLE: 'Revocar invitación',
    REVOKE_INVITE_MODAL_SUBTITLE: '¿Realmente quieres revocar la invitación?',
    REVOKE_INVITE_MODAL_CONFIRM: 'Revocar',
    REVOKE_INVITE_MODAL_CANCEL: 'Cancelar',
    REVOKE_INVITE_MODAL_SUCCESS_MESSAGE: 'Invitación revocada correctamente.',
    REVOKE_INVITE_MODAL_ERROR_MESSAGE: '¡No se pudo revocar el envío de la invitación!',
  },
};
