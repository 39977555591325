import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ButtonProfile from '@components/ButtonProfile/ButtonProfile';
import ClickOutHandler from 'react-clickout-handler';
import MenuProfile from '@containers/Menus/MenuProfile';
import { ButtonsWrapper } from '../styles';

function Profile() {
  const [showMenuProfile, setShowMenuProfile] = useState(false);

  const {
    subDomain: { name, picture_url },
    dataUser: { user_name, user_last_name, avatar_url },
  } = useSelector((state) => state.authenticationReducer);

  return (
    <>
      <ButtonsWrapper avatarButton onClick={() => { setShowMenuProfile(true) } }>
        <ButtonProfile
          alt={name}
          src={picture_url}
          userName={user_name}
          userLastName={user_last_name}
          avatarUrl={avatar_url}
          isOpen={showMenuProfile}
          iconSize="px40"
        />
      </ButtonsWrapper>
      <ClickOutHandler onClickOut={() => setShowMenuProfile(false)} refProp="innerRef">
        <MenuProfile open={showMenuProfile} />
      </ClickOutHandler>
    </>
  );
}

export default Profile;
