const FeedbackModalEN = {
  FeedbackModal: {
    FeedbackTitleOfRepprove: 'Disapproval details',
    Comment: 'Comment',
    CommentPlaceholder: 'Include relevant details that complement your feedback...',
    CancelButton: 'Cancel',
    ConfirmButtonOfRepprove: 'Confirm disapproval',
    ConfirmButtonOfApprove: 'Confirm approval',
    ErrorMessage: 'Comment and/or tags are required',
    Link: 'Link',
    LinkPlaceholder: 'Enter a link to share files',
    LinkError: 'Oops, this link seems to be invalid...',
    DISAPPROVE_SUCCESS: 'Media disapproved successfully!',
    DISAPPROVE_ERROR: 'Error on disapprove media, try again later or contact an administrator.',
    FeedbackTitleOfApprove: 'Approval details',
    APPROVE_SUCCESS: 'Media approved successfully!',
    APPROVE_ERROR: 'Error on approving media, try again later or contact an administrator.',

    tagsSpan: 'You can select quick replies to indicate positive or negative points of the post',
    very_creative: '\ud83d\udc4c  Very creative',
    smashed_the_content: '\ud83c\udf08  Rocked the content',
    well_written_caption: '\ud83d\udcac  Well written subtitles',
    composition_quality: '🖼  Composition quality',
    humorous: '\ud83d\ude02  Humorous',
    long_video: '\u23f0  Long video',
    short_video: '\ud83c\udfa5  Short Video',
    app_logo_not_accepted: '\ud83d\udeab  App logo not accepted',
    typing_error: '\u2328  Typing error',
    does_not_follow_brand_identity: '\uD83D\uDDE3  Does not follow brand identity',
    copyrighted_music: '\ud83c\udfa7  Copyrighted music',
    filter_usage: '\u2728  Filter usage',
    inappropriate_content: '\ud83d\ude26  Inappropriate content',
  },
};

const FeedbackModalPT = {
  FeedbackModal: {
    FeedbackTitleOfRepprove: 'Detalhes de reprovação',
    Comment: 'Comentário',
    CommentPlaceholder: 'Inclua detalhes relevantes que complementem o seu feedback...',
    CancelButton: 'Cancelar',
    ConfirmButtonOfRepprove: 'Confirmar reprovação',
    ConfirmButtonOfApprove: 'Confirmar aprovação',
    ErrorMessage: 'Comentário e/ou tags são necessários',
    Link: 'Link',
    LinkPlaceholder: 'Insira um link para compartilhar arquivos',
    LinkError: 'Ops, parece que esse link é inválido...',
    DISAPPROVE_SUCCESS: 'Mídia reprovada com sucesso!',
    DISAPPROVE_ERROR:
      'Erro ao reprovar a mídia, tente novamente mais tarde ou entre em contato com um administrador.',
    FeedbackTitleOfApprove: 'Detalhes de aprovação',
    APPROVE_SUCCESS: 'Mídia aprovada com sucesso!',
    APPROVE_ERROR:
      'Erro ao aprovar a mídia, tente novamente mais tarde ou entre em contato com um administrador.',

    tagsSpan:
      'Você pode selecionar respostas rápidas para indicar pontos positivos ou negativos da postagem',
    very_creative: '\ud83d\udc4c  Muito criativo',
    smashed_the_content: '\ud83c\udf08  Arrasou no conteúdo',
    well_written_caption: '\ud83d\udcac  Legenda bem escrita',
    composition_quality: '🖼  Qualidade de composição',
    humorous: '\ud83d\ude02  Bem humorado',
    long_video: '\u23f0  Video longo',
    short_video: '\ud83c\udfa5  Video curto',
    app_logo_not_accepted: '\ud83d\udeab  Logo de app não aceito',
    typing_error: '\u2328  Erro de digitação',
    does_not_follow_brand_identity: '\uD83D\uDDE3  Não segue identidade da marca',
    copyrighted_music: '\ud83c\udfa7  Música com direitos autorais',
    filter_usage: '\u2728  Uso de filtro',
    inappropriate_content: '\ud83d\ude26  Conteúdo inapropriado',
  },
};

const FeedbackModalES = {
  FeedbackModal: {
    FeedbackTitleOfRepprove: 'Detalles de desaprobación',
    Comment: 'Comentario',
    CommentPlaceholder: 'Incluya detalles relevantes que complementen sus comentarios...',
    CancelButton: 'Cancelar',
    ConfirmButtonOfRepprove: 'Confirmar desaprobación',
    ConfirmButtonOfApprove: 'Confirmar aprobación',
    ErrorMessage: 'Se requieren comentarios y/o etiquetas',
    Link: 'Enlace',
    LinkPlaceholder: 'Introduce un enlace para compartir archivos',
    LinkError: 'Vaya, parece que este enlace no es válido...',
    DISAPPROVE_SUCCESS: '¡Medios rechazados con éxito!',
    DISAPPROVE_ERROR:
      'Error en el medio, inténtelo de nuevo más tarde o póngase en contacto con un administrador.',
    FeedbackTitleOfApprove: 'Detalles de aprobación',
    APPROVE_SUCCESS: '¡Medios aprobados con éxito!',
    APPROVE_ERROR:
      'Error al aprobar los medios. Vuelva a intentarlo más tarde o póngase en contacto con un administrador.',

    tagsSpan:
      'Puede seleccionar respuestas rápidas para indicar puntos positivos o negativos de la publicación.',
    very_creative: '\ud83d\udc4c  Muy creativo',
    smashed_the_content: '\ud83c\udf08  Sacudió el contenido',
    well_written_caption: '\ud83d\udcac  Título bien escrito',
    composition_quality: '🖼  Calidad de la composición',
    humorous: '\ud83d\ude02  Bien humorado',
    long_video: '\u23f0  Video largo',
    short_video: '\ud83c\udfa5  Video corto',
    app_logo_not_accepted: '\ud83d\udeab  Logotipo de la aplicación no aceptado',
    typing_error: '\u2328  Error de digitacion',
    does_not_follow_brand_identity: '\uD83D\uDDE3  No sigue la identidad de la marca.',
    copyrighted_music: '\ud83c\udfa7  Musica con derechos de autor',
    filter_usage: '\u2728  Uso del filtro',
    inappropriate_content: '\ud83d\ude26  Contenido inapropiado',
  },
};

export { FeedbackModalPT, FeedbackModalEN, FeedbackModalES };
