import { ApiTenant, bffFetcher } from '@utils/api';
import BFFApi from '@utils/bffApi';

export default class CampaignService {
  static getCampaign(id) {
    return ApiTenant.get(`/incentivecampaign/1.0/campaign/${id}`);
  }

  static getCampaignReport(id, permitted_group) {
    return ApiTenant.get(
      `/incentivecampaign/1.0/report?incentive_campaign_id=${id}&permitted_group=${permitted_group}`
    );
  }

  static getCampaignFinancialMetrics(id, permitted_group) {
    return bffFetcher(`/api/v1/incentivecampaign/report/insights/${id}?user_permitted_group=${permitted_group}`);
  }

  static getCampaignFeed({ id, unitId, status, permitted_group }, next) {
    let URL = `/api/v1/incentivecampaign/units/post-info?incentive_campaign_id=${id}`;
    if (unitId) URL += `&unit_id=${unitId}`;
    if (status) URL += `&ad_status=${status}`;
    if (permitted_group) URL += `&user_permitted_group=${permitted_group}`;
    return bffFetcher(next ? next : URL);
  }

  static createCampaign(body) {
    return BFFApi.post(`/api/v1/incentivecampaign`, body);
  }
}
