// Api
import { ApiPublic, ApiUsers } from '@utils/api';

class FormService {
  static forgotPassword(version, data) {
    return ApiPublic.post(`/user/${version}/password/recover`, data);
  }

  static sendGuest(version, data) {
    return ApiPublic.post(`/user/${version}`, data);
  }

  static saveProfile(version, data) {
    return ApiUsers.put(`/user/${version}`, data);
  }
}

export default FormService;
