import React, { createContext, useState, useContext, useEffect } from 'react';
import * as Yup from 'yup';
import steps1Image from '@images/step_1.png';
import steps2Image from '@images/step_2.png';
import steps3Image from '@images/step_3.png';
import { SHOW_TOAST } from '@redux/toast/types';
import { useTranslate } from 'react-translate';
import { GdtvService } from '@services';
import { yupValidate } from '@utils/validators';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { GET_GDTV_FOLDERS } from 'redux/medias/types';

const INITIAL_CONTEXT = {
  activeStep: 0,
  data: {
    name: '',
    description: '',
    image_description: '',
  },
};

const progressBarImages = [steps1Image, steps2Image, steps3Image];

const GDTVFormStepContext = createContext(INITIAL_CONTEXT);

export function GDTVFormStepProvider({ children }) {
  const dispatch = useDispatch();
  const authenticationReducer = useSelector((state) => state.authenticationReducer);
  const {
    breadCrumb: {
      data: { currentCode },
    },
  } = useSelector((state) => state.genericReducer);
  const { gdtvType } = useSelector((state) => state.mediasReducer);

  const match = useRouteMatch();
  const t = useTranslate('Medias');

  const successResponseStatus = [200, 201];

  const [formData, setFormData] = useState(INITIAL_CONTEXT.data);
  const [firstRenderFlag, setFirstRenderFlag] = useState(true);

  const [file, setFile] = useState([]);

  const [groups, setGroups] = useState([]);
  const [groupsName, setGroupsName] = useState([]);
  const [groupLevel, setGroupLevel] = useState({ source_id: 'root' });

  const [shoulShowRequiredGroups, setShouldShowRequiredGroups] = useState(false);
  const [shouldShowRequiredFile, setShouldShowRequiredFile] = useState(false);

  const [creationLoading, setCreationLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(INITIAL_CONTEXT.activeStep);
  const [isCreating, setIsCreating] = useState(!!localStorage.getItem('isCreatingGDTV'));

  const [showComunicationCard, setShowComunicationCard] = useState(false);

  const img = progressBarImages[currentStep];

  useEffect(() => {
    if (firstRenderFlag && isCreating) {
      setFirstRenderFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRenderFlag, isCreating]);

  useEffect(() => {
    setFirstRenderFlag(true);
  }, [isCreating]);

  useEffect(() => {
    if (currentCode) {
      setGroupLevel(currentCode);
    }
  }, [currentCode]);

  function getValidationSchema(t) {
    const errorMessage = t ? t('TITLE_ERROR') : '';
    return Yup.object().shape({
      name: Yup.string().required(errorMessage),
      description: Yup.string().notRequired(),
      image_description: Yup.string().notRequired(),
    });
  }

  function skipStep() {
    const units = JSON.parse(localStorage.getItem('preSelectedUnitsGDTV'));
    const hasPreSelectedUnits = units?.length;

    if (hasPreSelectedUnits && currentStep === 0) {
      localStorage.removeItem('preSelectedUnitsGDTV');
      setGroups(units.map((unit) => unit.source_id));
      setGroupsName(units.map((unit) => unit.name));
      setShowComunicationCard(true);
      return true;
    }

    return false;
  }

  function nextStep() {
    if (currentStep === 0 && file.length < 1) {
      return setShouldShowRequiredFile(true);
    }

    if (currentStep === 1 && groups.length < 1) {
      return setShouldShowRequiredGroups(true);
    }

    if (skipStep()) return setCurrentStep(2);

    setCurrentStep(currentStep + 1);
  }

  function previousStep() {
    if (currentStep === 1 && groupLevel.source_id !== match.params.code) {
      const rootGroup = match.params.code === 'root' ? { source_id: 'root' } : currentCode;
      return setGroupLevel(rootGroup);
    }

    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  }

  function handleCancelCreating() {
    setIsCreating(false);
    localStorage.removeItem('isCreatingGDTV');
    setFile([]);
    setFormData(INITIAL_CONTEXT.data);
    setCurrentStep(INITIAL_CONTEXT.activeStep);
    setGroups([]);
    setGroupsName([]);
  }

  function registerData(data) {
    setFormData(data);
    nextStep();
  }

  function getGDTVCreationPaylod() {
    const { name, description } = formData;

    return {
      name,
      description,
      permitted_groups: groups || [],
    };
  }

  function dispatchMediaCreationIfSucced(gdtvCreationResponse) {
    if (successResponseStatus.includes(gdtvCreationResponse.status)) {
      createMedia(gdtvCreationResponse.data.id);
    } else {
      throw Error('Não foi possível criar o GDTV');
    }
  }

  async function createGDTV() {
    setCreationLoading(true);
    try {
      const gdtvCreationResponse = await GdtvService.createGdtvFolder(getGDTVCreationPaylod());
      dispatchMediaCreationIfSucced(gdtvCreationResponse);
    } catch (e) {
      dispatch({
        type: SHOW_TOAST.SUCCESS,
        message: t('CAMPAIGN_FAILURE_MESSAGE'),
        messageType: 'error',
      });
      setCreationLoading(false);
    }
  }

  function getMediaCreationPayload(gdtvFolderId) {
    const { image_description } = formData;
    const { blobUrls, files = [] } = file[0];

    return {
      directory_id: gdtvFolderId,
      type: files[0]?.file?.type?.includes('image') ? 'SINGLE_PICTURE' : 'VIDEO',
      medias: blobUrls?.map((item) => item.url),
      description: image_description || '',
      permitted_groups: groups || [],
    };
  }

  function processMediaCreation(mediaCreationResponse) {
    if (successResponseStatus.includes(mediaCreationResponse.status)) {
      dispatch({
        type: SHOW_TOAST.SUCCESS,
        message: t('GDTV_WAS_CREATED'),
        messageType: 'success',
      });
      dispatch({
        type: GET_GDTV_FOLDERS.RESET,
      });
      dispatch({
        type: GET_GDTV_FOLDERS.REQUEST,
        body: {
          limit: '12',
          thumbnail_limit: '6',
          type: gdtvType,
          incentive_campaign_situation: 'without_incentive_campaign',
        },
      });
      handleCancelCreating();
      setCreationLoading(false);
    } else {
      throw Error('Não foi possível adicionar a mídia');
    }
  }

  async function createMedia(gdtvFolderId) {
    try {
      const mediaCreationResponse = await GdtvService.createMedia(
        getMediaCreationPayload(gdtvFolderId)
      );

      processMediaCreation(mediaCreationResponse);
    } catch (e) {
      dispatch({
        type: SHOW_TOAST.SUCCESS,
        message: t('GDTV_WAS_CREATED_BUT_THE_MEDIA_WAS_NOT_ADD'),
        messageType: 'error',
      });
      setCreationLoading(false);
    }
  }

  async function checkIsNextStepAble(props) {
    if (currentStep === 0) {
      const { values } = props;
      const response = await yupValidate({ schema: getValidationSchema(), data: values });
      return response.isValid && file.length;
    } else if (currentStep === 1) {
      return !!groups.length;
    }
    return true;
  }

  return (
    <GDTVFormStepContext.Provider
      value={{
        formData,
        setFormData,
        currentStep,
        nextStep,
        previousStep,
        file,
        setFile,
        groups,
        setGroups,
        img,
        createGDTV,
        shouldShowRequiredFile,
        setShouldShowRequiredFile,
        shoulShowRequiredGroups,
        setShouldShowRequiredGroups,
        groupsName,
        setGroupsName,
        creationLoading,
        isCreating,
        setIsCreating,
        handleCancelCreating,
        registerData,
        getValidationSchema,
        groupLevel,
        setGroupLevel,
        showComunicationCard,
        checkIsNextStepAble,
      }}
    >
      {children}
    </GDTVFormStepContext.Provider>
  );
}

export const useGDTVFormStep = () => {
  const context = useContext(GDTVFormStepContext);

  if (!context) throw new Error('Expected to be wrapped in a GDTVFormStep');

  return context;
};
