const SidebarEN = {
  Sidebar: {
    OVERVIEW: 'Overview',
    LIST_ALL: 'List All',
    ENGAGEMENT: 'Engagement',
    VIDEO_VIEW: 'Video View',
    RANKING: 'Ranking',
    AUDIENCE: 'Audience',
    CUSTOM: 'Custom',
    BUDGET: 'Budget',
    TRANSACTIONS: 'Transactions',
    RECEIVED: 'Received',
    PROFILECOVER: 'Profile & Cover',
    APPROVALS: 'Approvals',
    FEED: 'Feed',
    COMMUNICATED: 'Comunicated',
    USERS_ACCESS_PROFILE: 'Access Profile',
    USERS_FACEBOOK: 'Facebook',
    ACTIVITY: 'Activity',
    COMMENTS: 'Comments',
    UNITS: 'Units',
    USER_TABLE: 'Users',
    EA: 'Easy Access',
    GROUPS: 'Groups',
    COMPANY: 'Company',
    SETUP: 'Setup da central ',
    WEEKLY_REPORT: 'Weekly Report',
    REPORT_SETTINGS: 'General visibility settings',
    USERS_MANAGEMENT: 'Users configs',
    FREQUENCY: 'Frequency',
  },
};

const SidebarPT = {
  Sidebar: {
    OVERVIEW: 'Visão Global',
    LIST_ALL: 'Listagem Geral',
    ENGAGEMENT: 'Engajamento',
    VIDEO_VIEW: 'Visualização em Vídeo',
    RANKING: 'Ranking',
    AUDIENCE: 'Audiência',
    CUSTOM: 'Personalizado',
    BUDGET: 'Verba',
    TRANSACTIONS: 'Extrato',
    RECEIVED: 'Recebidos',
    PROFILECOVER: 'Perfil e capa',
    APPROVALS: 'Aprovações',
    FEED: 'Feed',
    COMMUNICATED: 'Comunicados',
    USERS_ACCESS_PROFILE: 'Perfil de acesso',
    USERS_FACEBOOK: 'Facebook',
    ACTIVITY: 'Atividade',
    COMMENTS: 'Comentários',
    UNITS: 'Unidades',
    USER_TABLE: 'Usuários',
    EA: 'Easy Access',
    GROUPS: 'Grupos',
    COMPANY: 'Empresa',
    SETUP: 'Setup da central',
    WEEKLY_REPORT: 'Relatório semanal',
    REPORT_SETTINGS: 'Configurações gerais de visibilidade',
    USERS_MANAGEMENT: 'Configurações de usuários',
    FREQUENCY: 'Frequência',
  },
};

const SidebarES = {
  Sidebar: {
    OVERVIEW: 'Visión Global',
    LIST_ALL: 'Lista General',
    ENGAGEMENT: 'Involucramiento',
    VIDEO_VIEW: 'Visualización de Vídeo',
    RANKING: 'Ranking',
    AUDIENCE: 'Público',
    CUSTOM: 'Personalizado',
    BUDGET: 'Importe',
    TRANSACTIONS: 'Extracto',
    RECEIVED: 'Recibió',
    PROFILECOVER: 'Perfil y portada',
    APPROVALS: 'Aprobaciones',
    FEED: 'Feed',
    COMMUNICATED: 'Anuncios',
    USERS_ACCESS_PROFILE: 'Perfil de acceso',
    USERS_FACEBOOK: 'Facebook',
    ACTIVITY: 'Actividad',
    COMMENTS: 'Comentarios',
    UNITS: 'Unidades',
    USER_TABLE: 'Usuarios',
    EA: 'Easy Access',
    GROUPS: 'Grupos',
    COMPANY: 'Empresa',
    SETUP: 'Setup de central',
    WEEKLY_REPORT: 'Informe semanal',
    REPORT_SETTINGS: 'Configuraciones generales de visibilidad',
    USERS_MANAGEMENT: 'Ajustes de usuario',
    FREQUENCY: 'Frecuencia',
  },
};

export { SidebarPT, SidebarEN, SidebarES };
