import * as Yup from 'yup';
import steps1 from '@images/step_1.png';
import steps2 from '@images/step_2.png';
import steps3 from '@images/step_3.png';
import { checkIfIsTradeView, unileverPOC } from '@utils/helpers';
import { formatCurrency } from '@utils/numbers';

export const campaignFormModel = {
  formId: 'newCampaignForm',
  formField: {
    name: {
      name: 'titulo',
      label: 'Titulo*',
      requiredErrorMsg: 'Titulo da campanha é necessário',
    },
    startDate: {
      name: 'titulo',
      label: 'Conteúdo*',
      requiredErrorMsg: 'Data de inicio é necessária',
    },
    endDate: {
      name: 'titulo',
      label: 'Local de Entrega*',
      requiredErrorMsg: 'Data fim é necessária',
    },
    description: {
      name: 'titulo',
      label: 'Local de Utilização*',
      requiredErrorMsg: 'Descrição é necessária',
    },
    default_budget: {
      name: 'titulo',
      label: 'O valor é necessário',
      requiredErrorMsg: 'Valor é necessário',
    },
    campaign_objective: {
      name: 'titulo',
      label: 'Data/Hora de Necessidade',
      requiredErrorMsg: 'Objetivo da campanha é necessário',
    },
    max_budget: {
      name: 'titulo',
      label: 'Data/Hora de Necessidade',
      requiredErrorMsg: 'Orçamento é necessário',
    },
    max_budget_per_store: {
      name: 'titulo',
      label: 'Data/Hora de Necessidade',
      requiredErrorMsg: 'Orçamento por loja é necessário',
    },
    hashtags: {
      name: 'titulo',
      label: 'Data/Hora de Necessidade',
    },
    share_budget: {
      name: 'titulo',
    },
    tags: {
      name: 'titulo',
      label: 'O parceiro é necessário',
      requiredErrorMsg: 'Parceiro é necessário',
    },
    channel: {
      name: 'titulo',
      label: 'Canal para a campanha é necessário',
      requiredErrorMsg: 'Canal para a campanha é necessário',
    },
    url_callback: {
      invalidURL: 'Url inválida, deve começar com http:// ou https://',
    },
  },
};

export const formInitialValues = {
  name: '',
  startDate: '',
  endDate: '',
  description: '',
  default_budget: '',
  campaign_objective: null,
  channel: null,
  callback_url: '',
  campaign_lead_form: null,
  max_budget: '',
  max_budget_per_store: '',
  product_hashtag: '',
  brand_hashtag: '',
  hashtags: '',
  share_budget: false,
  category: {
    category: '',
    sku: '',
    model: '',
  },
  tags: '',
};

const {
  formField: { name, startDate, endDate, campaign_objective, tags, channel, url_callback },
} = campaignFormModel;

export const URL_CALLBACK_OBJECTIVE_VALUES = ['link_clicks', 'conversions'];
export const HTTP_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

export const validationSchema = Yup.object().shape({
  name: Yup.string().required(`${name.requiredErrorMsg}`),
  startDate: Yup.string().required(`${startDate.requiredErrorMsg}`),
  endDate: Yup.string().required(`${endDate.requiredErrorMsg}`),
  campaign_objective: Yup.string().nullable().required(`${campaign_objective.requiredErrorMsg}`),
  channel: Yup.string().nullable().required(`${channel.requiredErrorMsg}`),
  max_budget: Yup.string(),
  callback_url: Yup.string().test({
    name: 'must be a valid url',
    message: `${url_callback.invalidURL}`,
    test: (val, context) => {
      if (!URL_CALLBACK_OBJECTIVE_VALUES.includes(context.parent.campaign_objective) || !val)
        return true;

      return val?.match(HTTP_REGEX);
    },
  }),
  tags: Yup.string().test({
    name: 'required if is in trade',
    message: `${tags.requiredErrorMsg}`,
    test: (val) => {
      const shouldValidate = checkIfIsTradeView() || unileverPOC();

      return !(shouldValidate && !val?.length);
    },
  }),
  default_budget: Yup.string()
    .test({
      name: 'minimum value',
      message: 'Caso definido, o valor mínimo é de R$ 10,00',
      test: (value) => {
        if (value) {
          const formatedValue = formatCurrency({ value });
          return formatedValue >= 10 || formatedValue === 0;
        }
        return true;
      },
    })
    .test({
      name: 'greater than budget',
      message: 'Deve ser menor ou igual ao orçamento',
      test: (value, context) => {
        if (value) {
          campaign_objective;
          const formatedDefaultBudget = formatCurrency({ value });
          const formatedMaxBudget = formatCurrency({ value: context.parent.max_budget });

          return parseFloat(formatedMaxBudget) >= parseFloat(formatedDefaultBudget);
        }
        return true;
      },
    }),
  share_budget: Yup.boolean().test({
    name: 'has default budget',
    message: 'Só é possível ativar se definir orçamento por unidade',
    test: (value, context) => {
      return !(!context.parent.default_budget && value);
    },
  }),
});

export const INITIAL_CONTEXT = {
  currentStep: 0,
  data: [],
  hasBudgetPerUnit: false,
};

export const steps = [
  {
    id: 'information',
    img: steps1,
    current: 'Informação',
    previous: null,
    next: 'groupsAndUnits',
  },
  {
    id: 'groupsAndUnits',
    img: steps2,
    current: 'Grupos e Unidades',
    previous: 'information',
    next: 'resume',
  },
  {
    id: 'resume',
    img: steps3,
    current: 'Resume',
    previous: 'groupsAndUnits',
    next: null,
  },
];

export const campaignObjectiveEvent = (objective) => {
  const hash = {
    link_clicks: 'click_objective_ci_click_link_button',
    post_engagement: 'click_objective_ci_engagement_button',
    conversions: 'click_objective_ci_conversion_button',
    lead_generation: 'click_objective_ci_lead_ads_button',
  };
  return hash[objective];
};
