const MenuNotificationEN = {
  MenuNotification: {
    TITLE_NOTIFICATION: 'Notification',
    NO_NOTIFICATION: "There aren't notifications at the moment!",
  },
};

const MenuNotificationPT = {
  MenuNotification: {
    TITLE_NOTIFICATION: 'Notificações',
    NO_NOTIFICATION: 'Não há notificações no momento!',
  },
};

const MenuNotificationES = {
  MenuNotification: {
    TITLE_NOTIFICATION: 'Notificaciones',
    NO_NOTIFICATION: '¡Actualmente no hay notificaciones!',
  },
};

export { MenuNotificationPT, MenuNotificationEN, MenuNotificationES };
