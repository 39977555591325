const MarkedGroupsEN = {
  GROUPS_TITLE_PROMOTE: 'Where do you want to promote?',
  GROUPS_TITLE: 'Where do you want to replicate?',
  NEXT_STEP: 'Next',
  REPLICATE: 'Replicate',
  SEARCH_PLACEHOLDER: 'Search!',
  AVAILABLE: 'available',
  LINK_PLACEHOLDER: 'If you wish, insert a link in the post.',
};
const MarkedGroupsPT = {
  GROUPS_TITLE_PROMOTE: 'Onde deseja promover?',
  GROUPS_TITLE: 'Onde deseja replicar?',
  NEXT_STEP: 'Próximo',
  REPLICATE: 'Replicar',
  SEARCH_PLACEHOLDER: 'Procurar',
  AVAILABLE: 'disponíveis',
  LINK_PLACEHOLDER: 'Caso queira, insira um link na publicação.',
};
const MarkedGroupsES = {
  GROUPS_TITLE: '¿Dónde quieres replicar?',
  GROUPS_TITLE_PROMOTE: '¿Dónde quieres promocionar?',
  NEXT_STEP: 'Próximo',
  REPLICATE: 'Reproducir exactamente',
  SEARCH_PLACEHOLDER: 'Buscar',
  AVAILABLE: 'disponible',
  LINK_PLACEHOLDER: 'Si quieres, inserta un enlace en la publicación.',
};

export { MarkedGroupsEN, MarkedGroupsPT, MarkedGroupsES };
