import { bffFetcher } from 'utils/api';
import { getLocationKey, getCategoryKey } from 'utils/helpers';
import { ApiTenant } from 'utils/api';
import BFFApi from 'utils/bffApi';
import { FeedResponse } from '../containers/Trade/types';

const BASE_URI = '/api/v1/trends';

type QueryParamsType = {
  category?: string;
  location_id?: string | number;
  label?: string;
  forceRequest?: boolean;
  since: string;
  until: string;
  controller?: AbortController;
  limit?: number;
  page?: string | number;
  location_type?: string;
  queryType?: string;
};
type ResponseType = {
  is_success: boolean;
  payload?: {
    data?: Array<object>;
    summary?: { spend?: number; total_ads?: number };
  };
};

const TradeService = {
  getFeedPosts(queryParameters: QueryParamsType): Promise<FeedResponse> {
    const {
      category,
      location_id = '',
      label,
      forceRequest = false,
      since,
      until,
      controller,
      limit = 4,
      page = '',
    } = queryParameters;
    const periodQuery = `since=${since}&until=${until}`;

    const feedWordBaseUrl = `${BASE_URI}/feed/${category}?${periodQuery}&${getCategoryKey(
      category
    )}=${label}${location_id && `&location_id=${location_id}`}&force=${forceRequest}${
      limit && `&limit=${limit}`
    }${page && `&page=${page}`}`;

    const feedLocationBaseUrl = `${BASE_URI}/feed/location?${periodQuery}${
      location_id && `&location_id=${location_id}`
    }&force=${forceRequest}${limit && `&limit=${limit}`}${page && `&page=${page}`}`;

    return bffFetcher(label ? feedWordBaseUrl : feedLocationBaseUrl, controller);
  },

  getInsightsInvestments(queryParameters: QueryParamsType): Promise<ResponseType> {
    const {
      category,
      location_id = '',
      label = '',
      forceRequest = false,
      since,
      until,
      controller,
    } = queryParameters;
    const periodQuery = `since=${since}&until=${until}`;

    const insightsBaseUrl = `${BASE_URI}/insights/${category || 'location'}?${periodQuery}${
      location_id && `&location_id=${location_id}`
    }${label && `&${getCategoryKey(category)}=${label}`}&force=${forceRequest}`;

    return bffFetcher(insightsBaseUrl, controller);
  },
  getSummary(queryParameters: QueryParamsType): Promise<ResponseType> {
    const {
      category,
      location_id = '',
      label = '',
      forceRequest = false,
      since,
      until,
      controller,
      location_type,
    } = queryParameters;
    const periodQuery = `since=${since}&until=${until}`;

    const summaryBaseUrl = `${BASE_URI}/insights/investments/summary/${
      category || 'location'
    }?${periodQuery}${
      location_id && `&location_id=${location_id}&location_type=${getLocationKey(location_type)}`
    }${label && `&${getCategoryKey(category)}=${label}`}&force=${forceRequest}`;

    return bffFetcher(summaryBaseUrl, controller);
  },

  getReportDetails(queryParameters: QueryParamsType): Promise<ResponseType> {
    const {
      category,
      location_id = '',
      label = '',
      forceRequest = false,
      since,
      until,
      controller,
      location_type = '',
    } = queryParameters;

    const periodQuery = `since=${since}&until=${until}`;

    const reportDetailsUrl = `${BASE_URI}/words/${category}?${periodQuery}${
      label && `&${getCategoryKey(category)}=${label}`
    }${location_type && `&location_type=${location_type}`}${
      location_id && `&location_id=${location_id}`
    }&force=${forceRequest}`;

    return bffFetcher(reportDetailsUrl, controller);
  },

  getRanking(queryParameters: QueryParamsType): Promise<ResponseType> {
    const {
      queryType,
      category,
      label,
      location_id,
      forceRequest = false,
      since,
      until,
      limit = 6,
      controller,
    } = queryParameters;

    const periodQuery = `since=${since}&until=${until}`;

    const rankingUrl = `${BASE_URI}/insights/units-ranking/${queryType}?${periodQuery}${
      label ? `&${getCategoryKey(category)}=${label}` : ''
    }${location_id ? `&location_id=${location_id}` : ''}${
      limit && `&limit=${limit}`
    }&force=${forceRequest}`;

    return bffFetcher(rankingUrl, controller);
  },

  postTradePartner(data: object) {
    const body = { key: 'trade_partner', value: JSON.stringify(data) };

    return ApiTenant.post('/settings/1.0/keyvalue', body);
  },

  postFeedReview(body: object) {
    return BFFApi.post(`${BASE_URI}/feed/logs`, body);
  },

  postTradeChampionsExp(data: object) {
    const body = { key: 'trade_champions_exp', value: JSON.stringify(data) };

    return ApiTenant.post('/settings/1.0/keyvalue', body);
  },
  postTradeExpWantsMore(data: object) {
    const body = { key: 'trade_exp_wants_to_know_more', value: JSON.stringify(data) };

    return ApiTenant.post('/settings/1.0/keyvalue', body);
  },
  clearTradeCache() {
    return BFFApi.delete(`${BASE_URI}/cache`)
  }
};

export default TradeService;
