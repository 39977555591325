/* Modules */
import { call, put, takeEvery } from 'redux-saga/effects';

import { BusinessService, AuthenticationService, EasyAccessService } from '@services';

/* Types */
import {
  FETCH_LIST_USERS,
  CHANGE_STATUS_USER,
  CHANGE_GROUP_USER,
  REVOKE_INVITE,
  INVITE_USER,
  VERIFY_EMAIL_USER,
  GET_SAS_URL,
  EDIT_USER_ROLE,
  VERIFY_BUSINESS,
  CHANGE_CAPABILITIES,
  GET_USERS_SUMMARY,
  CLONE_CAPABILITIES,
} from './types';
import { SHOW_TOAST } from '../toast/types';
import { IS_LOADING } from '../loading/types';

function* fetchListUsers(action) {
  const response = yield call(
    BusinessService.listUsers,
    action.version,
    action.search,
    action.next
  );
  let eaResponse = [];

  if (
    (!action.search.includes('&status') && !action.search.includes('&role=admin')) ||
    (action.search.includes('&status=pending_approval') && !action.search.includes('&role=admin'))
  ) {
    eaResponse = yield call(EasyAccessService.getUsers, '1.0', action.search, action.next);
    response.data.data.push(...eaResponse.data.data);
  }

  if (response.status === 200) {
    yield put({ type: FETCH_LIST_USERS.SUCCESS, listUsers: response.data });
  } else {
    yield put({ type: FETCH_LIST_USERS.FAILURE, listUsersError: response.data.error });
  }
}

function* updateStatusUser(action) {
  const response = yield call(
    BusinessService.updateStatus,
    action.version,
    action.id,
    action.status,
    action.updateThirdPartySettings
  );

  if (response.status === 200) {
    yield put({ type: CHANGE_STATUS_USER.SUCCESS, statusUser: 'success' });

    if (action.successMessage) {
      yield put({
        type: SHOW_TOAST.SUCCESS,
        message: action.successMessage,
        messageType: 'success',
      });
    }

    if (action.onSuccess) {
      action.onSuccess();
    }
  } else {
    yield put({ type: CHANGE_STATUS_USER.FAILURE, statusUserError: 'error' });

    if (action?.errorMessage) {
      yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
    }
  }
}

function* updateGroupUser(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });
  const response = yield call(
    BusinessService.updateUserGroups,
    action.version,
    action.id,
    action.groups
  );

  if (response.status === 200) {
    yield put({ type: CHANGE_GROUP_USER.SUCCESS, statusUser: 'success' });
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.successMessage, messageType: 'success' });
    if (action.onSuccess) {
      action.onSuccess();
    }
  } else {
    yield put({ type: CHANGE_GROUP_USER.FAILURE, statusUserError: 'error' });
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
    if (action.onError) {
      action.onError();
    }
  }

  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
}

function* revokeInvite(action) {
  const response = yield call(BusinessService.revokeInvite, action.version, action.id);

  if (response.status === 200) {
    yield put({ type: REVOKE_INVITE.SUCCESS, revokeInvite: response.data });
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.successMessage, messageType: 'success' });
    if (action.onSuccess) {
      action.onSuccess();
    }
  } else {
    yield put({ type: REVOKE_INVITE.FAILURE, revokeInviteError: response.data.error });
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
  }
}

function* inviteUser(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });

  const response = yield call(
    BusinessService.inviteUser,
    action.version,
    action.user_email,
    action.role,
    action.redirect_url,
    action.capabilities,
    action.send_verification_email,
    action.permitted_groups
  );

  if (response.status === 200) {
    yield put({ type: INVITE_USER.SUCCESS, inviteUser: response.data });
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.successMessage, messageType: 'success' });

    if (action.onSuccess) {
      action.onSuccess();
    }
  } else {
    yield put({ type: INVITE_USER.FAILURE, inviteUserError: response.data.error });
    if (response.data.error === 'BUSINESS_USER_ALREADY_EXISTS') {
      yield put({
        type: SHOW_TOAST.SUCCESS,
        message: action.errorMessageUserAlreadyExists,
        messageType: 'error',
      });
    } else {
      yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
    }
    if (action.onError) {
      action.onError();
    }
  }

  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
}

function* verifyEmailUser(action) {
  try {
    const response = yield call(AuthenticationService.checkEmail, action.version, action.email);

    if (response.status === 200) {
      yield put({ type: VERIFY_EMAIL_USER.SUCCESS, verifyEmailUser: response.data });
    }
  } catch (e) {
    yield put({ type: VERIFY_EMAIL_USER.FAILURE, verifyEmailUserError: e });
  }
}

function* getSasUrl(action) {
  const response = yield call(BusinessService.getSasurl, action.version);

  if (response.status === 200) {
    yield put({ type: GET_SAS_URL.SUCCESS, sasUrl: response.data });
  } else {
    yield put({ type: GET_SAS_URL.FAILURE, error: response.data.error });
  }
}

function* editUserRole(action) {
  const response = yield call(BusinessService.editUserRole, action.version, action.id, action.data);

  if (response.status === 200) {
    yield put({ type: EDIT_USER_ROLE.SUCCESS, newUserRole: response.data });
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.successMessage, messageType: 'success' });
    if (action.onSuccess) {
      action.onSuccess();
    }
  } else {
    yield put({ type: EDIT_USER_ROLE.FAILURE, newUserRoleError: response.data.error });
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
    if (action.onError) {
      action.onError();
    }
  }
}

function* verifyBusiness(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });

  const response = yield call(BusinessService.verifyBusiness, action.version);

  if (response.status === 200) {
    yield put({ type: VERIFY_BUSINESS.SUCCESS, userToken: response.data.token });
  } else {
    yield put({ type: VERIFY_BUSINESS.FAILURE, userTokenError: response.data.error });
  }

  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
}

function* updateCapabilities(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });
  const response = yield call(
    BusinessService.updateCapabilities,
    action.version,
    action.id,
    action.capabilities,
    action.groups
  );

  if (response.status === 200) {
    yield put({ type: CHANGE_CAPABILITIES.SUCCESS, statusUser: 'success' });
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.successMessage, messageType: 'success' });
    if (action.onSuccess) {
      action.onSuccess();
    }
  } else {
    yield put({ type: CHANGE_CAPABILITIES.FAILURE, statusUserError: 'error' });
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
    if (action.onError) {
      action.onError();
    }
  }

  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
}

function* getUsersSummary(action) {
  const response = yield call(BusinessService.getUsersSummary, action.version, action.search);

  const eaResponse = yield call(EasyAccessService.getSummary, '1.0', action.search);

  response.data.easyAccess = eaResponse.data;

  if (response.status === 200) {
    yield put({ type: GET_USERS_SUMMARY.SUCCESS, usersSummary: response.data });
  } else {
    yield put({ type: GET_USERS_SUMMARY.FAILURE, usersSummaryError: response.data.error });
  }
}

function* cloneCapabilities(action) {
  const fromUserId = action.fromUser.user_id;
  const toUserIds = action.toUsers.map((user) => user.user_id);

  const response = yield call(
    BusinessService.cloneCapabilities,
    action.version,
    fromUserId,
    toUserIds
  );

  if (response.status === 200) {
    yield put({
      type: CLONE_CAPABILITIES.SUCCESS,
      toUserIds: toUserIds,
      fromUser: action.fromUser,
    });

    if (action.onSuccess) {
      action.onSuccess();
    }
  } else {
    yield put({ type: CLONE_CAPABILITIES.FAILURE });

    if (action.onError) {
      action.onError();
    }
  }
}

export const businessSaga = [
  takeEvery(FETCH_LIST_USERS.REQUEST, fetchListUsers),
  takeEvery(CHANGE_STATUS_USER.REQUEST, updateStatusUser),
  takeEvery(CHANGE_GROUP_USER.REQUEST, updateGroupUser),
  takeEvery(REVOKE_INVITE.REQUEST, revokeInvite),
  takeEvery(INVITE_USER.REQUEST, inviteUser),
  takeEvery(VERIFY_EMAIL_USER.REQUEST, verifyEmailUser),
  takeEvery(GET_SAS_URL.REQUEST, getSasUrl),
  takeEvery(EDIT_USER_ROLE.REQUEST, editUserRole),
  takeEvery(VERIFY_BUSINESS.REQUEST, verifyBusiness),
  takeEvery(CHANGE_CAPABILITIES.REQUEST, updateCapabilities),
  takeEvery(GET_USERS_SUMMARY.REQUEST, getUsersSummary),
  takeEvery(CLONE_CAPABILITIES.REQUEST, cloneCapabilities),
];
