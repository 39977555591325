/** Declare any other action type here * */

type Types = 'REQUEST' | 'SUCCESS' | 'FAILURE' | 'CANCEL' | 'RESET';

const TYPES: Types[] = ['REQUEST', 'SUCCESS', 'FAILURE', 'CANCEL', 'RESET'];

const createActionTypes = (base: string): Record<Types, string> => {
  const ref: Record<Types, string> = {
    CANCEL: '',
    FAILURE: '',
    REQUEST: '',
    RESET: '',
    SUCCESS: '',
  };

  TYPES.forEach((type) => {
    ref[type] = `${base}_${type}`;
  });

  return ref;
};

export default createActionTypes;
