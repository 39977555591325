// Modules
import axios from 'axios';

// Constants
import { BASE_URL, BASE_URL_LOCAL, BFF_BASE_URL, DATA_BASE_URL } from '@utils/constants';
import jwt_decode from 'jwt-decode';

/* Utils */
import {
  logoutUser,
  getUserToken,
  getBusinessID,
  hasVerificationToken,
  getBusinessToken,
} from './auth';

import { reLoginUser } from '@utils/oAuth';

const instancePublic = axios.create({});

instancePublic.interceptors.request.use(
  (config) => {
    // Don't IE request cache
    config.headers.Pragma = 'no-cache';
    config.headers['Access-Control-Allow-Origin'] = '*';

    return config;
  },
  (error) => Promise.reject(error)
);

class ApiPublic {
  static get(uri) {
    return instancePublic.get(`${BASE_URL}${uri}`);
  }

  static post(uri, data) {
    return instancePublic.post(`${BASE_URL}${uri}`, data);
  }

  static put(uri, data) {
    return instancePublic.put(`${BASE_URL}${uri}`, data);
  }

  static patch(uri, data) {
    return instancePublic.patch(`${BASE_URL}${uri}`, data);
  }

  static delete(uri) {
    return instancePublic.delete(`${BASE_URL}${uri}`);
  }

  static getPDF(uri) {
    return instancePublic.get(uri, { responseType: 'blob' });
  }
}

const instanceUsers = axios.create({});

instanceUsers.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${getBusinessToken()}`;
    // Don't IE request cache
    config.headers.Pragma = 'no-cache';
    config.headers['X-Company'] = getBusinessID();

    return config;
  },
  (error) => Promise.reject(error)
);

class ApiUsers {
  static async get(uri) {
    let res;

    try {
      res = await instanceUsers.get(`${BASE_URL}${uri}`);
    } catch (e) {
      if (e.response.status === 401) {
        this.checkUserTokenExpired();
      }
    }
    return res;
  }

  static post(uri, data) {
    return instanceUsers.post(`${BASE_URL}${uri}`, data);
  }

  static put(uri, data) {
    return instanceUsers.put(`${BASE_URL}${uri}`, data);
  }

  static patch(uri, data) {
    return instanceUsers.patch(`${BASE_URL}${uri}`, data);
  }

  static delete(uri) {
    return instanceUsers.delete(`${BASE_URL}${uri}`);
  }

  static getPDF(uri) {
    return instanceUsers.get(uri, { responseType: 'blob' });
  }

  static checkUserTokenExpired() {
    const userToken = jwt_decode(getUserToken());

    if (new Date(userToken.exp * 1000) < new Date()) {
      return this.logout();
    }
  }

  static logout() {
    logoutUser();
    window.location.reload();
  }
}

const instanceTenant = axios.create({});

instanceTenant.interceptors.request.use(
  (config) => {
    // Don't IE request cache
    config.headers.Pragma = 'no-cache';
    config.headers['X-Company'] = getBusinessID();
    config.headers.Authorization = `Bearer ${getBusinessToken()}`;


    const tokenRecoveryPass = hasVerificationToken();
    if (tokenRecoveryPass) {
      config.headers.Authorization = `Bearer ${tokenRecoveryPass}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

const fetcher = (url) => instanceTenant.get(`${BASE_URL}${url}`).then((res) => res.data);

const homologFetcher = (url) =>
  instanceTenant.get(`https://api-homolog.gerentesdigitais.com${url}`).then((res) => res.data);

const bffFetcher = (url, controller) => {
  if (controller?.signal) {
    return instanceTenant
      .get(`${BFF_BASE_URL}${url}`, { signal: controller.signal })
      .then((res) => res.data);
  }
  return instanceTenant.get(`${BFF_BASE_URL}${url}`).then((res) => res.data);
};

const dataFetcher = (url) => instanceTenant.get(`${DATA_BASE_URL}${url}`).then((res) => res.data);

class ApiTenant {
  static handleError = (e) => {
    // Comentado para verificar possivel correção ao BUG-427
    // if (e.toString() === 'Error: Network Error') {
    //   localStorage.setItem('business-token', '');
    //   this.reLogin();
    // }
    if (e.response !== undefined) {
      if (e.response.status === 403) {
        if (e.response.data && e.response.data.error === 'FORBIDDEN_NODE') {
          return this.redirectToPermittedCode();
        }
        return this.logout();
      }
      if (e.response.status === 401) {
        return this.checkBusinessTokenExpired();
      }
      if (e.response.status === 400 || e.response.status === 404 || e.response.status === 409) {
        return e.response;
      }
      if (e.response.status === 500) {
        return e.response;
      }
      this.redirectToErrorPage();
    }
  };

  static async get(uri) {
    try {
      const response = await instanceTenant.get(`${BASE_URL}${uri}`);

      if (response.status === 200 || response.status === 204) {
        return response;
      }
    } catch (e) {
      return this.handleError(e);
    }
  }

  static async post(uri, data, cancel) {
    try {
      const response = await instanceTenant.post(`${BASE_URL}${uri}`, data, {
        cancelToken: cancel,
      });
      if (response.status === 200 || response.status === 201) {
        return response;
      }
    } catch (e) {
      return this.handleError(e);
    }
  }

  static async postFormData(uri, data, cancel) {
    try {
      const response = await instanceTenant.post(`${BASE_URL}${uri}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress() {},
        cancelToken: cancel,
      });
      if (response.status === 200 || response.status === 201) {
        return response;
      }
    } catch (e) {
      return e;
    }
  }

  static async put(uri, data) {
    try {
      const response = await instanceTenant.put(`${BASE_URL}${uri}`, data);

      if (response.status === 200) {
        return response;
      }
    } catch (e) {
      return this.handleError(e);
    }
  }

  static patch(uri, data) {
    return instanceTenant.patch(`${BASE_URL}${uri}`, data);
  }

  static async delete(uri, data) {
    try {
      const response = await instanceTenant.delete(`${BASE_URL}${uri}`, data ? { data } : data);

      if (response.status === 200) {
        return response;
      }
    } catch (e) {
      return this.handleError(e);
    }
  }

  static getPDF(uri) {
    return instanceTenant.get(uri, { responseType: 'blob' });
  }

  static redirectToErrorPage() {
    if (process.env.NODE_ENV === 'production') {
      const url = window.location;
      const split = url.hash.split('/');
      let permittedGroup = jwt_decode(getBusinessToken()).permitted_group;
      permittedGroup = permittedGroup || 'root';

      if (split[0] === '#') {
        url.replace(`${url.origin}/${split[0]}/${permittedGroup}/ErrorPage?serverError=true`);
      }

      window.location.reload();
    }
  }

  static redirectToPermittedCode() {
    const url = window.location;
    const split = url.hash.split('/');
    let permittedGroup = jwt_decode(getBusinessToken()).permitted_group;
    permittedGroup = permittedGroup || 'root';

    if (split[0] === '#') {
      url.replace(`${url.origin}/${split[0]}/${permittedGroup}`);
    }

    window.location.reload();
  }

  static checkBusinessTokenExpired() {
    const token = getBusinessToken();
    if (token) {
      const decoded = jwt_decode(token);
      if (new Date(decoded.exp * 1000) < new Date()) {
        this.reLogin();
        return;
      }
      this.reLogin();
    } else {
      this.logout();
    }
  }

  static reLogin = () => {
    reLoginUser();
  };

  static logout() {
    this.reLogin();
  }
}

class ApiTenantLocal {
  static async get(uri) {
    try {
      const response = await instanceTenant.get(`${BASE_URL_LOCAL}${uri}`);

      if (response.status === 200) {
        return response;
      }
    } catch (e) {}
  }

  static async post(uri, data) {
    try {
      const response = await instanceTenant.post(`${BASE_URL_LOCAL}${uri}`, data);

      if (response.status === 200 || response.status === 201) {
        return response;
      }
    } catch (e) {}
  }

  static async postFormData(uri, data, cancel) {
    try {
      const response = await instanceTenant.post(`${BASE_URL_LOCAL}${uri}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress() {},
        cancelToken: cancel,
      });
      if (response.status === 200 || response.status === 201) {
        return response;
      }
    } catch (e) {
      return e;
    }
  }

  static async put(uri, data) {
    try {
      const response = await instanceTenant.put(`${BASE_URL_LOCAL}${uri}`, data);

      if (response.status === 200) {
        return response;
      }
    } catch (e) {}
  }

  static async delete(uri) {
    try {
      const response = await instanceTenant.delete(`${BASE_URL_LOCAL}${uri}`);

      if (response.status === 200) {
        return response;
      }
    } catch (e) {}
  }
}

export {
  ApiPublic,
  ApiUsers,
  ApiTenant,
  ApiTenantLocal,
  fetcher,
  homologFetcher,
  bffFetcher,
  dataFetcher,
};
