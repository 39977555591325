import './utils/numeralLocale';
import './index.css';
import { TranslatorProvider } from 'react-translate';
import { translationsPT } from './translate';

// redux
import React from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import rootReducers from '@redux/rootReducers';
import createSagaMiddleware from 'redux-saga'; // SAGA
import rootSagas from '@redux/rootSagas';

// Styles
import { ThemeProvider } from 'styled-components';
import { DefaultTheme } from './styles';

// Router
import { HashRouter } from 'react-router-dom';
import {
  faArrowLeft as fadArrowLeft,
  faCircle as fadCircle,
  faEllipsisVAlt as fadEllipsisVAlt,
  faStoreAlt as fadStoreAlt,
} from '@fortawesome/pro-duotone-svg-icons';
// Icons
import { library } from '@fortawesome/fontawesome-svg-core';
// regular
import {
  faAbacus,
  faAd,
  faAngleDown,
  faAngleUp,
  faArrowRight as farArrowRight,
  faArrowUp,
  faBan,
  faBookmark as farBookmark,
  faBriefcase,
  faBuilding,
  faBullhorn,
  faCalendarAlt,
  faChartBar,
  faCheck as farCheck,
  faCheckDouble as farCheckDouble,
  faChevronDown as farChevronDown,
  faChevronLeft,
  faChevronUp as farChevronUp,
  faChild as farChild,
  faCircleNotch as farCircleNotch,
  faCoffee,
  faCogs,
  faDonate as farDonate,
  faEllipsisV as farEllipsisV,
  faEmptySet,
  faEnvelope,
  faEnvelopeOpen,
  faEraser,
  faExchangeAlt,
  faExclamation,
  faEye as farEye,
  faFilter as farFilter,
  faFlag as farFlag,
  faFunnelDollar,
  faGlasses as farGlasses,
  faGlobe as farGlobe,
  faGlobeAmericas,
  faHandHoldingUsd,
  faHistory,
  faImages,
  faKey,
  faLayerGroup as farLayergroup,
  faLightbulbExclamation,
  faMapMarkerAlt,
  faMedal,
  faMinus as farMinus,
  faPen,
  faPiggyBank,
  faPlay as farPlay,
  faPlus as farPlus,
  faPrint as farPrint,
  faQuestionCircle as farQuestionCircle,
  faShieldAlt,
  faSignOutAlt,
  faSitemap,
  faSortAmountDown,
  faSortAmountUp,
  faStop,
  faStore,
  faSyncAlt,
  faTrash,
  faTrophy,
  faUser,
  faUserCrown,
  faUserEdit,
  faUserPlus,
  faUsers,
  faUserShield,
  faVideo as farVideo,
  faWallet,
  faWifiSlash,
} from '@fortawesome/pro-regular-svg-icons';
// solid
import {
  faArrowAltDown,
  faArrowAltUp,
  faBackward,
  faBadge,
  faBookmark,
  faCamera as fasCamera,
  faCheckCircle as fasCheckCircle,
  faCircle as fasCircle,
  faDesktop,
  faEdit as fasEdit,
  faEllipsisH,
  faExclamationCircle,
  faLayerGroup,
  faMousePointer as fasMousePointer,
  faPeopleCarry,
  faPlay as fasPlay,
  faPrint as fasPrint,
  faSignalAlt1,
  faStar as fasStar,
  faThumbsUp as fasThumbsUp,
  faTimesCircle,
  faVideo as fasVideo,
} from '@fortawesome/pro-solid-svg-icons';
// light
import {
  faAddressCard,
  faAngleDoubleDown,
  faAngleRight,
  faArrowAltUp as falArrowAltUp,
  faBars,
  faBell,
  faCalendarCheck,
  faCamera as falCamera,
  faChartLine,
  faCheck as falCheck,
  faCheckCircle as falCheckCircle,
  faCheckDouble,
  faChevronDoubleDown,
  faChevronDoubleUp,
  faChevronDown as falChevronDown,
  faChevronRight,
  faChevronUp,
  faChild as falChild,
  faCircleNotch as falCircleNotch,
  faClock,
  faClone,
  faCog,
  faComment,
  faCommentAlt,
  faDesktop as falDesktop,
  faDice,
  faDownload,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye as falEye,
  faFilmAlt,
  faFilter as falFilter,
  faFlag as fasFlag,
  faGlasses as falGlasses,
  faGlobe as falGlobe,
  faHandshakeAlt,
  faHeart,
  faHeartRate,
  faHistory as falHistory,
  faIdCard,
  faImage,
  faInboxOut,
  faIndustryAlt,
  faInfoCircle,
  faLayerGroup as falLayergroup,
  faLifeRing,
  faLink,
  faListUl,
  faLocation,
  faLocationCircle,
  faLongArrowLeft,
  faMinus as falMinus,
  faMinusOctagon as falMinusOctagon,
  faMobileAlt,
  faMoneyBillAlt,
  faMoneyCheckEditAlt,
  faMousePointer as falMousePointer,
  faPaperPlane,
  faPencil,
  faPencilAlt,
  faPiggyBank as falPiggyBank,
  faPlayCircle as falPlayCircle,
  faPlus as falPlus,
  faPrint as falPrint,
  faQuestion,
  faQuestionCircle as falQuestionCircle,
  faReceipt,
  faRobot,
  faSearch,
  faSearchPlus as falSearchPlus,
  faShare,
  faShareAlt,
  faShieldCheck,
  faSortAmountDown as falSortAmountDown,
  faSortAmountUp as falSortAmountUp,
  faSquare,
  faStar as falStar,
  faStopCircle as falStopCircle,
  faThList as falFaThList,
  faThumbsDown,
  faThumbsUp as falThumbsUp,
  faTimes,
  faTimes as falTimes,
  faTrashAlt,
  faUpload as falUpload,
  faUsdCircle,
  faUserCheck,
  faUserCircle,
  faUserClock,
  faUserCog,
  faUserFriends as falUserFriends,
  faUserLock,
  faUserMinus,
  faUsersCog,
  faUserSlash,
  faUserTag,
  faUserTie,
  faUserTimes,
  faVideo as falVideo,
  faWindowClose,
} from '@fortawesome/pro-light-svg-icons';
// brands
import {
  faAmazon,
  faAndroid,
  faApple,
  faFacebook,
  faFacebookF,
  faFacebookSquare,
  faGoogle,
  faGooglePlusG,
  faInstagram,
  faSpotify,
  faTwitter,
  faWhatsapp,
  faWindows,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { Store } from './components/Store';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  rootReducers,
  {},
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

// run the saga
sagaMiddleware.run(rootSagas);

// Register Icons
library.add(
  falChevronDown,
  farChevronDown,
  falPlayCircle,
  faChevronUp,
  falUpload,
  falSearchPlus,
  falFaThList,
  faListUl,
  farArrowRight,
  faChevronLeft,
  farEllipsisV,
  fasEdit,
  farCheckDouble,
  falStopCircle,
  faCoffee,
  falUserFriends,
  farCircleNotch,
  falCircleNotch,
  faChartBar,
  faUser,
  falQuestionCircle,
  farQuestionCircle,
  faBriefcase,
  faSitemap,
  faGlobeAmericas,
  farPrint,
  farVideo,
  falVideo,
  faPen,
  fadCircle,
  fadArrowLeft,
  faLongArrowLeft,
  fadStoreAlt,
  fadEllipsisVAlt,
  faSignOutAlt,
  farCheck,
  faTrophy,
  faWallet,
  faAngleDown,
  faAngleUp,
  faCalendarAlt,
  faChartLine,
  faLightbulbExclamation,
  farPlus,
  farMinus,
  faEmptySet,
  faCogs,
  faMedal,
  faAd,
  farFilter,
  faEraser,
  faKey,
  faUsers,
  faUserEdit,
  faUserPlus,
  faSyncAlt,
  faExclamation,
  faUserCrown,
  faUserShield,
  faShieldAlt,
  faHandHoldingUsd,
  faBuilding,
  faBan,
  faExchangeAlt,
  faEnvelope,
  faEnvelopeOpen,
  faStore,
  farGlobe,
  farChild,
  farGlasses,
  faTrash,
  faSortAmountUp,
  faSortAmountDown,
  faHistory,
  faPiggyBank,
  faAbacus,
  faImages,
  faFunnelDollar,
  farFlag,
  faBullhorn,
  farLayergroup,
  faMapMarkerAlt,
  faArrowUp,
  faStop,
  farPlay,
  fasStar,
  falStar,
  faDesktop,
  falArrowAltUp,
  faArrowAltUp,
  faArrowAltDown,
  fasCheckCircle,
  falCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faTimesCircle,
  fasCircle,
  faBadge,
  fasMousePointer,
  falMousePointer,
  faMoneyBillAlt,
  fasThumbsUp,
  fasVideo,
  fasPrint,
  faPeopleCarry,
  faUserTie,
  faUsersCog,
  faAddressCard,
  faUserCircle,
  faUserCheck,
  faUserLock,
  faUserMinus,
  faUserClock,
  faClock,
  faDice,
  faRobot,
  faUserCog,
  faLifeRing,
  faIndustryAlt,
  faSearch,
  faBell,
  faQuestion,
  faBars,
  faTimes,
  faChevronDoubleUp,
  faChevronDoubleDown,
  faPencilAlt,
  faTrashAlt,
  falThumbsUp,
  faThumbsDown,
  faUsdCircle,
  faInboxOut,
  faCalendarCheck,
  faReceipt,
  faAngleRight,
  faUserTimes,
  faUserSlash,
  faLink,
  faExternalLinkAlt,
  faClone,
  faAngleDoubleDown,
  falCamera,
  faShare,
  falEye,
  farEye,
  farDonate,
  faUserTag,
  faFilmAlt,
  falLayergroup,
  falTimes,
  fasFlag,
  faLocation,
  faLocationCircle,
  faInfoCircle,
  faChevronRight,
  falGlobe,
  falChild,
  falGlasses,
  falFilter,
  faSquare,
  faComment,
  falCheck,
  faCommentAlt,
  faDownload,
  faFacebook,
  faInstagram,
  faFacebookF,
  faSpotify,
  faAmazon,
  faGoogle,
  faTwitter,
  faWindows,
  faApple,
  faAndroid,
  faFacebookSquare,
  faGooglePlusG,
  faYoutube,
  faBookmark,
  falSortAmountUp,
  falSortAmountDown,
  falMinusOctagon,
  falHistory,
  falPiggyBank,
  faMobileAlt,
  falDesktop,
  faSignalAlt1,
  farBookmark,
  faImage,
  faCheckDouble,
  faPaperPlane,
  faShieldCheck,
  faWindowClose,
  faCog,
  faEllipsisH,
  faPencil,
  falMinus,
  falPlus,
  fasCamera,
  fasPlay,
  faWifiSlash,
  faShareAlt,
  falPrint,
  faHeartRate,
  faBackward,
  faWhatsapp,
  faLayerGroup,
  faIdCard,
  faHandshakeAlt,
  faHeart,
  farChevronUp,
  faMoneyCheckEditAlt
);

const Providers = ({ children }) => (
  <ThemeProvider theme={DefaultTheme}>
    <Store>
      <Provider store={store}>
        <HashRouter>
          <TranslatorProvider translations={translationsPT}>{children}</TranslatorProvider>
        </HashRouter>
      </Provider>
    </Store>
  </ThemeProvider>
);

export default Providers;
