import React, { ChangeEvent, DragEvent, useState } from 'react';
import { useTranslate } from 'react-translate';
import { Image } from '../../types';
import * as S from './styles';

interface ImageDropZoneProps {
  width: number;
  height: number;
  imagePicked: (props: Image) => void;
}

function ImageDropZone({ width, height, imagePicked }: ImageDropZoneProps) {
  const [imageUrl, setImageUrl] = useState<string>('');
  const [over, setOver] = useState<boolean>(false);

  const t = useTranslate('ImageDropZone');

  function handleFile(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files?.[0]) return;

    const selectedUrl = URL.createObjectURL(event.target.files[0]);
    const selectedFile = event.target.files[0];

    setImageUrl(selectedUrl);

    imagePicked({
      file: selectedFile,
      url: selectedUrl,
    });
  }

  function deleteFile() {
    imagePicked({});

    setImageUrl('');
  }

  function onDragOver(event: DragEvent<HTMLDivElement>) {
    event.preventDefault();
  }

  function onDragEnter() {
    setOver(true);
  }

  function onDragLeave() {
    setOver(false);
  }

  function onDrop(event: DragEvent<HTMLDivElement>) {
    event.preventDefault();
    const selectedFile = event.dataTransfer.files[0];
    const selectedUrl = URL.createObjectURL(selectedFile);

    setImageUrl(selectedUrl);
    setOver(false);

    imagePicked({
      file: selectedFile,
      url: selectedUrl,
    });
  }

  return (
    <S.DropZoneWrapper>
      <S.DropZone
        onDrop={onDrop}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDragEnter={onDragEnter}
        over={over}
        image={imageUrl}
        width={width}
        height={height}
      >
        {!!imageUrl ? (
          <img src={imageUrl} alt={imageUrl} width={0} height={0} />
        ) : (
          <S.EmptyUploadArea>
            <S.UploadAreaPlaceholder>{t('DROP-HERE')}</S.UploadAreaPlaceholder>
          </S.EmptyUploadArea>
        )}
      </S.DropZone>

      <S.ButtonsContainer>
        <S.Label>
          {t('UPLOAD-BUTTON')}
          <S.FileUploadButton type="file" value="" onChange={handleFile} />
        </S.Label>

        {!!imageUrl && (
          <S.DeleteButton type="button" onClick={deleteFile}>
            {t('DELETE-BUTTON')}
          </S.DeleteButton>
        )}
      </S.ButtonsContainer>
    </S.DropZoneWrapper>
  );
}

export default ImageDropZone;
