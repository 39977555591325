/* Modules */
import { call, put, takeEvery } from 'redux-saga/effects';

/* Service */
import { AuthenticationService, BusinessService } from '@services';

/* Types */
import { setBusinessID, removeVerificationToken, logoutUser } from '@utils/auth';
import {
  FETCH_DOMAIN,
  EMAIL_IS_VALID,
  AUTHENTICATE_LOGIN,
  FETCH_LIST_COMPANIES,
  AUTHENTICATE_BUSINESS,
  FETCH_USER_ME,
  RECOVERY_PASSWORD,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  GET_USERS,
  GET_SESSIONS,
  INVALIDATE_SESSION,
  LOGOUT,
  GET_CURRENT_USER,
  GET_CAPABILITY_CATEGORY,
} from './types';

import { SHOW_TOAST } from '../toast/types';
import { IS_LOADING } from '../loading/types';
import { CHANGE_LANGUAGE } from '../translates/types';

/* Utils */

function* fetchDomain(action) {
  try {
    const response = yield call(
      AuthenticationService.checkSubDomain,
      action.version,
      action.subDomain
    );

    if (response.status === 200) {
      yield put({ type: FETCH_DOMAIN.SUCCESS, response: response.data, status: response.status });
      setBusinessID(response.data.id);
    }
  } catch (e) {
    yield put({ type: FETCH_DOMAIN.FAILURE, status: e.response.status });
  }
}

function* emailIsValid(action) {
  try {
    const response = yield call(AuthenticationService.emailIsValid, action.version, action.email);

    if (response.status === 200) {
      yield put({
        type: EMAIL_IS_VALID.SUCCESS,
        response: response.data,
        status: response.status,
        email: action.email,
      });
    }
  } catch (e) {
    yield put({
      type: EMAIL_IS_VALID.FAILURE,
      response: e.response,
      status: e.response.status,
      email: action.email,
    });
  }
}

function* authenticateLogin(action) {
  try {
    const response = yield call(AuthenticationService.authenticate, action.version, action.data);

    if (response.status === 200) {
      yield put({ type: AUTHENTICATE_LOGIN.SUCCESS, response: response.data.token });
      removeVerificationToken();
    }
  } catch (e) {
    let errorMessage = 'Erro no login. Por favor, entre em contato com um administrador!';
    if (e.response.data.error === 'EMAIL_LOGIN_ATTEMPTS_LIMIT_REACHED') {
      const data = new Date(e.response.data.blocked_until);
      const currentDate = new Date();
      const blocked = data - currentDate;
      const diffMins = Math.round(((blocked % 86400000) % 3600000) / 60000);
      errorMessage =
        'Você excedeu o limite de tentativas de login e sua conta está bloqueada por ' +
        diffMins +
        ' minutos';
    }
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: errorMessage,
      messageType: 'error',
    });

    yield put({ type: AUTHENTICATE_LOGIN.FAILURE });
  }
}

function* authenticateBusiness(action) {
  try {
    const response = yield call(AuthenticationService.authenticateBusiness, action.version);

    if (response.status === 200) {
      yield put({ type: AUTHENTICATE_BUSINESS.SUCCESS, token: response.data.token });
    }
  } catch (e) {
    if (e.response.status === 401) {
      let errorMessage = 'Erro no login. Por favor, entre em contato com um administrador!';
      if (e.response.data.error === 'UNVERIFIED_USER') {
        errorMessage =
          'Usuário não verificado. Você deve acessar através do link que recebeu em seu email.';
      }
      yield put({
        type: SHOW_TOAST.SUCCESS,
        message: errorMessage,
        messageType: 'error',
      });
    }
    yield put({ type: AUTHENTICATE_BUSINESS.FAILURE });
  }
}

function* fetchListCompanies(action) {
  try {
    const response = yield call(
      AuthenticationService.fetchListCompanies,
      action.version,
      action.userToken
    );

    if (response.status === 200) {
      yield put({ type: FETCH_LIST_COMPANIES.SUCCESS, list: response.data });
    }
  } catch (e) {
    yield put({ type: FETCH_LIST_COMPANIES.FAILURE });
  }
}

function* fetchUserMe(action) {
  try {
    const response = yield call(AuthenticationService.userMe, action.version);
    if (response.status === 200) {
      yield put({ type: FETCH_USER_ME.SUCCESS, userMe: response.data });

      // Alterado o language aqui pois quando usuário loga ou a tela é recarregada, o didMount do Dashboard
      // chama esta função e só precisa ser setado uma vez o language global com base na preferencia do usuario.
      // Caso o usuário altere sua preferência no selectLanguage, o CHANGE_LANGUAGE também é chamado.
      yield put({ type: CHANGE_LANGUAGE.SUCCESS, language: response.data.language });
    }
  } catch (e) {
    yield put({ type: FETCH_USER_ME.FAILURE });
  }
}

function* getUser(action) {
  try {
    const response = yield call(BusinessService.getUser, action.version, action.id, action.search);

    if (response.status === 200) {
      yield put({ type: GET_CURRENT_USER.SUCCESS, currentUser: response.data });
    }
  } catch (e) {
    logoutUser(action);
  }
}

function* forgotPassword(action) {
  try {
    const response = yield call(AuthenticationService.forgotPassword, action.version, action.data);

    if (response.status === 200) {
      yield put({ type: FORGOT_PASSWORD.SUCCESS, forgotPassword: true });
    }
  } catch (e) {
    yield put({ type: FORGOT_PASSWORD.FAILURE, forgotPassword: false });
  }
}

function* getUsers(action) {
  try {
    const response = yield call(AuthenticationService.getUsers, action.version, action.id);

    if (response.status === 200) {
      yield put({ type: GET_USERS.SUCCESS, users: response.data });
    }
  } catch (e) {
    yield put({ type: GET_USERS.FAILURE, usersError: e.response });
  }
}

function* getSessions(action) {
  try {
    const response = yield call(AuthenticationService.getSessions, action.version);
    if (response.status === 200) {
      yield put({ type: GET_SESSIONS.SUCCESS, sessions: response.data });
    }
  } catch (e) {
    yield put({ type: GET_SESSIONS.FAILURE, sessionsError: e.response });
  }
}

function* invalidateSession(action) {
  try {
    const dataInvalidate = { device_id: action.id };
    const response = yield call(
      AuthenticationService.invalidateSession,
      action.version,
      dataInvalidate
    );
    if (response.status === 200) {
      yield put({ type: INVALIDATE_SESSION.SUCCESS, invalidateSessions: response.data });
    }
  } catch (e) {
    yield put({ type: INVALIDATE_SESSION.FAILURE, invalidateSessionsError: e.response });
  }
}

function* logout(action) {
  try {
    const response = yield call(AuthenticationService.logout, action.version);
    if (response.status === 200) {
      yield put({ type: LOGOUT.SUCCESS, logout: response.data });
    }
  } catch (e) {
    yield put({ type: LOGOUT.FAILURE, logout: e.response });
  }
}

function* recoveryPassword(action) {
  try {
    const response = yield call(AuthenticationService.getUsers, action.version, action.id);

    if (response.status === 200) {
      yield put({ type: RECOVERY_PASSWORD.SUCCESS, recoveryPass: response.data });
    }
  } catch (e) {
    yield put({ type: RECOVERY_PASSWORD.FAILURE, recoveryPass: e.response });
  }
}

function* resetPassword(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });
  try {
    const response = yield call(AuthenticationService.resetPassword, action.version, action.data);

    if (response.status === 200) {
      yield put({ type: RESET_PASSWORD.SUCCESS, recoveryPass: 'Success' });
      yield put({
        type: SHOW_TOAST.SUCCESS,
        message: action.successMessage,
        messageType: 'success',
      });
      yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
    }
  } catch (e) {
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
    yield put({ type: RESET_PASSWORD.FAILURE, errorRecoveryPass: e.response });
    yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
  }
}

function* getCapabilityCategory(action) {
  try {
    const response = yield call(AuthenticationService.getCapabilityCategory, action.version);

    if (response.status === 200) {
      yield put({ type: GET_CAPABILITY_CATEGORY.SUCCESS, capabilityCategory: response.data });
    }
  } catch (e) {
    yield put({ type: GET_CAPABILITY_CATEGORY.FAILURE, error: e.response });
  }
}

export const authenticationSaga = [
  takeEvery(FETCH_DOMAIN.REQUEST, fetchDomain),
  takeEvery(EMAIL_IS_VALID.REQUEST, emailIsValid),
  takeEvery(AUTHENTICATE_LOGIN.REQUEST, authenticateLogin),
  takeEvery(FETCH_LIST_COMPANIES.REQUEST, fetchListCompanies),
  takeEvery(AUTHENTICATE_BUSINESS.REQUEST, authenticateBusiness),
  takeEvery(FETCH_USER_ME.REQUEST, fetchUserMe),
  takeEvery(RECOVERY_PASSWORD.REQUEST, recoveryPassword),
  takeEvery(RESET_PASSWORD.REQUEST, resetPassword),
  takeEvery(FORGOT_PASSWORD.REQUEST, forgotPassword),
  takeEvery(GET_USERS.REQUEST, getUsers),
  takeEvery(GET_SESSIONS.REQUEST, getSessions),
  takeEvery(INVALIDATE_SESSION.REQUEST, invalidateSession),
  takeEvery(LOGOUT.REQUEST, logout),
  takeEvery(GET_CURRENT_USER.REQUEST, getUser),
  takeEvery(GET_CAPABILITY_CATEGORY.REQUEST, getCapabilityCategory),
];
