import styled from 'styled-components';
import { primaryTheme as theme } from '../../../styles/themes';

export const UnitsContainer = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const ContentContainer = styled.section`
  width: 100%;
`;

export const HeaderUnitsContainer = styled.section`
  flex: 12;
  padding: ${theme.spacing.px25} 0px;
  display: flex;
`;

export const TotalizerContainer = styled.section`
  flex: 10;
`;

export const AddUnitContainer = styled.section`
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const TableContainer = styled.section`
  flex: 12;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${theme.spacing.px10} 0px;
  margin-right: ${theme.spacing.px15};
`;
