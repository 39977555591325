import React, { useEffect, useState } from 'react';
import { useExchangeTenants } from '@utils/hooks/TradeAds';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as S from './styles';
import useStore from '../../../utils/stores/useStore';

const MOCK_TENANTS = [
  {
    target_tenant_id: '5ff6078656ffae00018e3871',
    target_tenant_name: 'Polishop',
  },
  {
    target_tenant_id: '5c9386f9504d3100012e95e5',
    target_tenant_name: 'Magalu',
  },
  {
    target_tenant_id: '5c4b20593e77dc00011399d9',
    target_tenant_name: 'Via varejo',
  },
  {
    target_tenant_id: '5ea6d0c40e82150001b22fc2',
    target_tenant_name: 'Americanas',
  },
  {
    target_tenant_id: '5f99b636b5b67400014c0b42',
    target_tenant_name: 'Havan',
  },
];

const ExchangeTenantSelector = () => {
  const { exchangeTenants, isLoading, isError } = useExchangeTenants();

  const authenticationReducer = useSelector((state) => state.authenticationReducer);

  const [showPopup, setShowPopup] = useState(false);
  const [tenantName, setTenantName] = useState('');

  const setExchangeTenant = useStore((state) => state.setExchangeTenant);
  const exchangeTenant = useStore((state) => state.exchangeTenant);

  const { campaignId, group } = useParams();

  const closePopup = () => {
    setShowPopup(false);
  };

  const ref = useDetectClickOutside({ onTriggered: closePopup });

  useEffect(() => {
    if (exchangeTenants) setExchangeTenant(exchangeTenants[0]?.target_tenant_id);
  }, [exchangeTenants]);

  useEffect(() => {
    if (exchangeTenants) {
      const tenant = exchangeTenants.filter((tenant) => {
        if (tenant.target_tenant_id === exchangeTenant) return tenant;
      });
      setTenantName(tenant[0]?.target_tenant_name);
    }
  }, [exchangeTenant]);

  if (isLoading) {
    return (
      <S.MainWrapper>
        <S.Wrapper>
          <Skeleton width={150} />
        </S.Wrapper>
      </S.MainWrapper>
    );
  }
  if (isError) {
    return null;
  }

  const validateWithMocks = (tenants) => {
    const arrTenantIds = [];
    const dataTenants = [];

    tenants.forEach((tenant) => {
      if (!arrTenantIds.find((code) => code === tenant.target_tenant_id)) {
        dataTenants.push({ ...tenant });
        arrTenantIds.push(tenant.target_tenant_id);
      }
    });

    const withLinkTenants = [...dataTenants];

    MOCK_TENANTS.forEach((tenant) => {
      if (!tenants.find((t) => t.target_tenant_id === tenant.target_tenant_id)) {
        withLinkTenants.push({
          ...tenant,
          disabled: true,
        });
      }
    });

    return withLinkTenants;
  };

  const openWhatapp = () => {
    window.open(
      'https://api.whatsapp.com/send/?phone=5511939584443&text=Ol%C3%A1+Nathalia!+Gostaria+de+me+conectar+com+outros+varejistas+na+plataforma+Trade+Ads!&app_absent=0',
      '_blank'
    );
  };

  const handleClickTenant = (tenant) => {
    if (tenant.disabled) {
      openWhatapp();
    } else {
      setShowPopup(false);
      setExchangeTenant(tenant.target_tenant_id);

    }
  };

  const handleOpenTenantExchanges = () => {
    setShowPopup(!showPopup);
  };

  return campaignId ? (
    <S.BlockWrapper>
      Parceiro: <span>{tenantName}</span>
    </S.BlockWrapper>
  ) : (
    <S.MainWrapper ref={ref}>
      <S.Wrapper onClick={handleOpenTenantExchanges}>
        {tenantName}
        <FontAwesomeIcon icon={['fal', 'chevron-down']} style={{ marginTop: '13px' }} />
      </S.Wrapper>
      {showPopup && (
        <S.Popup>
          <S.SelectorTitle>Alternar parceiro</S.SelectorTitle>
          {validateWithMocks(exchangeTenants).map((tenant) => (
            <S.Option
              key={tenant.target_tenant_id}
              onClick={() => handleClickTenant(tenant)}
              selected={exchangeTenant === tenant.target_tenant_id}
              disabled={tenant.disabled}
            >
              {exchangeTenant === tenant.target_tenant_id && (
                <FontAwesomeIcon
                  icon={['fal', 'check-circle']}
                  style={{ fontSize: '16px', marginRight: '10px' }}
                />
              )}
              {tenant.disabled && (
                <FontAwesomeIcon
                  icon={['fal', 'lock']}
                  style={{ fontSize: '16px', marginRight: '10px' }}
                />
              )}
              {tenant.target_tenant_name}
            </S.Option>
          ))}

          <S.Footer>
            <FontAwesomeIcon
              icon={['fal', 'lock']}
              style={{ marginTop: '13px', marginRight: '10px' }}
            />
            <div>
              Quer se conectar com outros varejistas? Saiba mais{' '}
              <S.ClickHere onClick={openWhatapp}>clicando aqui</S.ClickHere>.
            </div>
          </S.Footer>
        </S.Popup>
      )}
    </S.MainWrapper>
  );
};

export { ExchangeTenantSelector };
