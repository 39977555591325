import React, { createContext, useState, useContext, useEffect } from 'react';
import * as Yup from 'yup';
import steps1Image from '@images/step_1.png';
import steps2Image from '@images/step_2.png';
import steps3Image from '@images/step_3.png';
import { SHOW_TOAST } from '@redux/toast/types';
import { MediasService } from '@services';
import { yupValidate } from '@utils/validators';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';

const INITIAL_CONTEXT = {
  currentStep: 0,
  data: {
    name: '',
    description: '',
    image_description: '',
  },
};

const progressBarImages = [steps1Image, steps2Image, steps3Image];

const MediaSetFormStepContext = createContext(INITIAL_CONTEXT);

export const MediaSetFormStepProvider = ({ children }) => {
  const dispatch = useDispatch();
  const authenticationReducer = useSelector((state) => state.authenticationReducer);
  const {
    breadCrumb: {
      data: { currentCode },
    },
  } = useSelector((state) => state.genericReducer);
  const match = useRouteMatch();

  const successResponseStatus = [200, 201];

  const [formData, setFormData] = useState(INITIAL_CONTEXT.data);

  const [files, setFiles] = useState([]);

  const [groups, setGroups] = useState([]);
  const [groupsName, setGroupsName] = useState([]);
  const [groupLevel, setGroupLevel] = useState({ source_id: 'root' });

  const [shoulShowRequiredGroups, setShouldShowRequiredGroups] = useState(false);

  const [creationLoading, setCreationLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(INITIAL_CONTEXT.currentStep);
  const [isCreating, setIsCreating] = useState(!!localStorage.getItem('isCreatingMediaSet'));

  const img = progressBarImages[currentStep];

  useEffect(() => {
    if (currentCode) {
      setGroupLevel(currentCode);
    }
  }, [currentCode]);

  function getValidationSchema(t) {
    const errorText = t ? t('TITLE_ERROR') : '';
    return Yup.object().shape({
      name: Yup.string().required(errorText),
      description: Yup.string().notRequired(),
    });
  }

  function nextStep() {
    if (currentStep === 0 && files?.filter((file) => !!file.error).length > 0) {
      return;
    }

    if (currentStep === 1 && groups.length < 1) {
      return setShouldShowRequiredGroups(true);
    }

    setCurrentStep(currentStep + 1);
  }

  function previousStep() {
    if (currentStep === 1 && groupLevel.source_id !== match.params.code) {
      const rootGroup = match.params.code === 'root' ? { source_id: 'root' } : currentCode;
      return setGroupLevel(rootGroup);
    }

    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  }

  function handleCancelCreating() {
    setIsCreating(false);
    localStorage.removeItem('isCreatingMediaSet');
  }

  function registerData(data) {
    setFormData(data);
    nextStep();
  }

  function getMediaSetCreationPaylod() {
    const { name, description } = formData;

    return {
      name,
      description,
      permitted_groups: groups || [],
    };
  }

  function dispatchMediaCreationIfSucced(mediaSetCreationResponse) {
    if (successResponseStatus.includes(mediaSetCreationResponse.status)) {
      createMedia(mediaSetCreationResponse.data.id);
    } else {
      throw Error('Não foi possível criar o GDTV');
    }
  }

  async function createMediaSet() {
    try {
      setCreationLoading(true);
      const mediaSetCreationResponse = await MediasService.postCreative(
        getMediaSetCreationPaylod()
      );

      dispatchMediaCreationIfSucced(mediaSetCreationResponse);
    } catch (e) {
      dispatch({
        type: SHOW_TOAST.SUCCESS,
        message: t('MEDIA_SET_CREATION_ERROR_TOAST'),
      });
      setCreationLoading(false);
    }
  }

  function getMediaCreationPayload({ mediaSetID, file }) {
    const { fileSpecs } = file;

    return {
      mediaSetID,
      parts: 1,
      name: fileSpecs?.name,
      size: fileSpecs?.size,
    };
  }

  async function processMediaCreation({ status, id, file }) {
    if (successResponseStatus.includes(status)) {
      try {
        const { fileSpecs } = file;
        const formData = new FormData();
        formData.append('id', id);
        formData.append('index', 1);
        formData.append('data', fileSpecs);
        return MediasService.uploadMediaChunk(formData);
      } catch (e) {
        setCreationLoading(false);
        throw Error('Não foi possível adicionar a mídia');
      }
    }
  }

  async function createMedia(mediaSetID) {
    try {
      await Promise.all(
        files.map(async (item) => {
          const mediaCreationResponse = await MediasService.createMedia(
            getMediaCreationPayload({ mediaSetID, file: item })
          );

          return processMediaCreation({
            status: mediaCreationResponse.status,
            id: mediaCreationResponse.data.id,
            file: item,
          });
        })
      );
      setCreationLoading(false);

      handleCancelCreating();
      window.location.reload();
    } catch (e) {
      setCreationLoading(false);
      dispatch({
        type: SHOW_TOAST.SUCCESS,
        message: t('MEDIA_SET_MEDIA_CREATION_ERROR_TOAST'),
        messageType: 'error',
      });
    }
  }

  async function checkIsNextStepAble(props) {
    if (currentStep === 0) {
      const { values } = props;
      const response = await yupValidate({ schema: getValidationSchema(), data: values });
      return response.isValid && files.length;
    } else if (currentStep === 1) {
      return !!groups.length;
    }
    return true;
  }

  return (
    <MediaSetFormStepContext.Provider
      value={{
        formData,
        setFormData,
        currentStep,
        nextStep,
        previousStep,
        files,
        setFiles,
        groups,
        setGroups,
        img,
        registerData,
        createMediaSet,
        creationLoading,
        shoulShowRequiredGroups,
        setShouldShowRequiredGroups,
        groupsName,
        setGroupsName,
        isCreating,
        setIsCreating,
        handleCancelCreating,
        getValidationSchema,
        groupLevel,
        setGroupLevel,
        checkIsNextStepAble,
      }}
    >
      {children}
    </MediaSetFormStepContext.Provider>
  );
};

export const useMediaSetFormStep = () => {
  const context = useContext(MediaSetFormStepContext);

  if (!context) throw new Error('Expected to be wrapped in a MediaSetFormStep');

  return context;
};
