const ActivityEN = {
  Activity: {
    IMAGES: 'Images',
    TITLE: 'Overall Productivity',
    Complete: 'Overall data',
    Ad: 'Ads',
    Post: 'Posts',
    Money_spent: 'Money Spent',
    Sunday: 'Sun',
    Monday: 'Mon',
    Tuesday: 'Tue',
    Wednesday: 'Wed',
    Thursday: 'Thu',
    Friday: 'Fri',
    Saturday: 'Sat',
    NOT_ENOUGH: 'Not enough data in the period',
    UNITS: 'Units',
    CONNECTED_UNITS: 'Connected Units',
    UNITS_ADVERTISING: 'Units advertising',
    UNITS_POSTING: 'Units posting',
    UNITS_POSTING_ADVERTISING: 'Units posting and advertising',
    SHOW: 'Show',
    OVERVIEW_DATA: 'Overview data',
    CONTENTS_CREATED: 'Contents created',
    MONTHLY_AVERAGE: 'Monthly average',
    DAILY_AVERAGE: 'Daily average',
    VIDEOS: 'Videos',
    STATICS: 'Statics',
    IMPRESSIONS_BY_CONTENT: 'Impressions by content',
    MOST_ADVERTISED: 'Unit that most advertised',
    MOST_POSTED: 'Unit that most posted',
    MOST_ADVERTISED_POSTED: 'Unit that most advertised and posted',
    ACTIVE_UNITS: 'Active Units',
    PERIOD_ACTIVE: 'Most active units in the period',
    ON_PERIOD: 'On Period',
    CONNECTED_USERS: 'Connected users',
    MOST_CONNECTED: 'Users who have connected the most',
    TODAY: 'Today',
    SEE_ALL: 'see all',
    NAME: 'Name',
    SEARCH_THE_UNIT: 'Search for an unit',
    SEARCH_THE_USER: 'Search for an user',
    EMAIL: 'Email',
    ACCESS: 'Access',
    COUNT_TOOLTIP: 'Total ads and posts produced in the period',
    AVERAGE_MONTH_TOOLTIP: 'Average content produced *per month* in the selected period',
    AVERAGE_DAY_TOOLTIP: 'Average content produced *per day* in the selected period',
    VIDEO_TOOLTIP: 'Percentage of content created on video',
    IMAGE_TOOLTIP: 'Percentage of content created with images',
    MOST_ADVERTISED_TOOLTIP: 'This unit has excelled in ad production!',
    MOST_POSTED_TOOLTIP: 'This unit has stood out in the production of organic posts',
    COMPARED_TO: 'Compared to the',
    WHEN_VALUE_WAS: 'when the value was',
    MONITORING_OF_UNITS: 'Monitoring of units',
    GDTV: 'Create GDTV',
    CREATE_CAMPAIGN: 'Create Campaign',
    CAMPAIGN_NAME: 'Campaign name',
    TITLE_CAMPAIGN: 'Time to create your incentive campaign!',
    TITLE_CAMPAIGN_ONE: 'You can publish',
    TITLE_CAMPAIGN_TWO: 'just an image or video',
    TITLE_CAMPAIGN_THREE: 'at a time.',
    TITLE_CAMPAIGN_FOUR: 'Allowed extensions: jpeg, jpg, png, mp4 and webm.',
    INPUT_DESCRIPTION: 'Content description',
    INPUT_VALID: 'How long will the campaign be valid for?',
    SUBTITLE_WARNING:
      'Please note: this period will be the same as the ad serving period for your units',
    PLACEHOLDER_CAMPAIGN: 'Enter the campaign name',
    PLACEHOLDER_DESCRIPTION: 'What will be the description for the unit?',
    PLACEHOLDER_START_DATE: 'Start date',
    PLACEHOLDER_END_DATE: 'End date',
    INPUT_OBJECTIVE: 'And the purpose of the ads?',
    CANCEL_BUTTON: 'Cancel',
    SEND_BUTTON: 'Advance',
    CAMPAIGN_BUDGET: 'What will be the budget for the campaign?',
    INSERT_THE_CAMPAIGN_BUDGET: 'Campaign budget',
    SELECT_GOAL: 'Select what your ad is for',
    TITLE_GDTV: 'Create your folder',
    TITLE_GDTV_ONE: 'For ease of organization, your releases can be grouped by subject.',
    TITLE_GDTV_TWO: 'Your units will receive your communication on the',
    TITLE_GDTV_THREE: 'Feed do App.',
    GDTV_NAME: 'Name*',
    PLACEHOLDER_GDTV: 'Enter your folder name',
    INPUT_DESCRIPTION_GDTV: 'Description',
    INPUT_DESCRIPTION_GDTV_ONE: 'What will be the subject of your release',
    TITLE_GDTV_FOUR: ' ',
    INPUT_VALID: 'How long will the campaign be valid for?',
    POST_FOLDER_SUCCESS_MESSAGE: 'Folder created successfully!',
    POST_FOLDER_FAILURE_MESSAGE: 'Failed to create this folder, please try again later!',
    TITLE_SELECT_GROUPS_GDTV: 'Upload your content',
    SUB_TITLE_SELECT_GROUPS_1_GDTV: 'You can only post one image or video at a time.',
    SUB_TITLE_SELECT_GROUPS_2_GDTV: 'Allowed extensions: jpeg, jpg, png, mp4 and webm.',
    SHARE_GDTV: 'Share',
    VIEW_PREVIOUSLY: 'View previously',
    CAMPAIGN_SUCCESS_MESSAGE: 'Campaign successfully created!',
    CAMPAIGN_FAILURE_MESSAGE: 'Error creating the campaign',
    POST_GDTV: 'Post',
    TITLE_SELECT_GROUPS: 'Choose units to run campaign',
    SUB_TITLE_SELECT_GROUPS_1: 'You can select an entire group or just a few',
    SUB_TITLE_SELECT_GROUPS_2: 'units to display your incentive campaign.',
    CAMPAIGN_LABEL_PERIOD: 'Campaign validity period',
    CAMPAIGN_LABEL_OBJECTIVE: 'Purpose of ads',
    YOUR_CAMPAIGN: 'Your incentive campaign',
    link_clicks: 'Link clicks',
    post_engagement: 'Engagements',
    conversions: 'Conversions',
    FILE_TITLE: 'Upload your files',
    CHOOSE_UNITS: 'Choose units',
    CANCEL: 'Cancel',
    SHARE_WITH_EVERYONE: 'Share with everyone',
    INCLUDE_FILE: 'Include file',
    FILTER_LABEL_POSTING: 'Posting',
    FILTER_LABEL_ANNOUNCING: 'Announcing',
    FILTER_LABEL_BRAND_HOUSE: 'Casas Bahia',
    FILTER_LABEL_BRAND_SCORE: 'Ponto',
    FILTER_LABEL_LOW_INVESTMENT: 'Low investment',
    FILTER_LABEL_HIGH_INVESTMENT: 'High investment',
    FILTER_LABEL_EXPECTED_INVESTMENT: 'Expected investment',
    FILTER_LABEL_TOTAL_USE_OF_INVESTMENT: 'Total use of investment',
    TO: 'to',
  },
};

const ActivityPT = {
  Activity: {
    TITLE: 'Produtividade Geral',
    Complete: 'Dados gerais',
    Ad: 'Anúncios',
    Post: 'Postagens',
    Money_spent: 'Verba Gasta',
    Sunday: 'Dom',
    Monday: 'Seg',
    Tuesday: 'Ter',
    Wednesday: 'Qua',
    Thursday: 'Qui',
    Friday: 'Sex',
    Saturday: 'Sab',
    NOT_ENOUGH: 'Não há dados suficientes no período',
    UNITS: 'Unidades',
    CONNECTED_UNITS: 'Unidades conectadas',
    UNITS_ADVERTISING: 'Unidades anunciando',
    UNITS_POSTING: 'Unidades postando',
    UNITS_POSTING_ADVERTISING: 'Unidades postando e anunciando',
    SHOW: 'Exibir',
    OVERVIEW_DATA: 'Dados gerais',
    CONTENTS_CREATED: 'Conteúdos produzidos',
    MONTHLY_AVERAGE: 'Média mensal',
    DAILY_AVERAGE: 'Média diária',
    VIDEOS: 'Vídeos',
    IMAGES: 'Imagens',
    STATICS: 'Estáticos',
    IMPRESSIONS_BY_CONTENT: 'Impressões por conteúdo',
    MOST_ADVERTISED: 'Unidade que mais anunciou',
    MOST_ADVERTISED_TOOLTIP: 'Esta unidade tem se destacado na produção de anúncios!',
    MOST_POSTED: 'Unidade que mais postou',
    MOST_POSTED_TOOLTIP: 'Esta unidade tem se destacado na produção de postagens orgânicas',
    MOST_ADVERTISED_POSTED: 'Unidade que mais anunciou e postou',
    ACTIVE_UNITS: 'Unidades Ativas',
    PERIOD_ACTIVE: 'Unidades mais ativas do período',
    ON_PERIOD: 'No período',
    CONNECTED_USERS: 'Usuários conectados',
    MOST_CONNECTED: 'Usuários que mais se conectaram',
    TODAY: 'Hoje',
    SEE_ALL: 'ver todos',
    NAME: 'Nome',
    SEARCH_THE_UNIT: 'Pesquise a unidade',
    SEARCH_THE_USER: 'Pesquise o usuário',
    EMAIL: 'Email',
    ACCESS: 'Acessos',
    COUNT_TOOLTIP: 'Total de anúncios e postagens produzidos no período',
    AVERAGE_MONTH_TOOLTIP: 'Média dos conteúdos produzidos *por mês* no período selecionado',
    AVERAGE_DAY_TOOLTIP: 'Média dos conteúdos produzidos *por dia* no período selecionado',
    VIDEO_TOOLTIP: 'Percentual de conteúdos criados em vídeo',
    IMAGE_TOOLTIP: 'Percentual de conteúdos criados com imagens',
    COMPARED_TO: 'Comparado ao periodo de',
    WHEN_VALUE_WAS: 'quando o valor foi de',
    MONITORING_OF_UNITS: 'Acompanhamento das unidades',
    GDTV: 'Criar GDTV',
    CREATE_CAMPAIGN: 'Criar Campanha',
    CAMPAIGN_NAME: 'Nome da campanha',
    TITLE_CAMPAIGN: 'Hora de criar sua campanha de incentivo!',
    TITLE_CAMPAIGN_ONE: 'Você poderá publicar',
    TITLE_CAMPAIGN_TWO: 'apenas uma imagem ou vídeo',
    TITLE_CAMPAIGN_THREE: 'por vez.',
    TITLE_CAMPAIGN_FOUR: 'Extensões permitidas: jpeg, jpg, png, mp4 e webm.',
    INPUT_DESCRIPTION: 'Descrição do conteúdo',
    INPUT_SUBTITLE: 'Legenda do conteúdo',
    INPUT_VALID: 'Qual será o período de validade da campanha?',
    SUBTITLE_WARNING:
      'Atenção: esse período poderá ser agendado e será o mesmo de veiculação dos anúncios das suas unidades.',
    PLACEHOLDER_CAMPAIGN: 'Insira o nome da campanha',
    PLACEHOLDER_DESCRIPTION: 'Qual será a descrição para a unidade?',
    PLACEHOLDER_START_DATE: 'Data de início',
    PLACEHOLDER_END_DATE: 'Data de término',
    INPUT_OBJECTIVE: 'E o objetivo dos anúncios?',
    CANCEL_BUTTON: 'Cancelar',
    SEND_BUTTON: 'Avançar',
    CHOOSE_GROUPS: 'Escolher grupos',
    CHOOSE_UNITS: 'Escolher unidades',
    CAMPAIGN_BUDGET: 'Qual será o orçamento para a campanha?',
    INSERT_THE_CAMPAIGN_BUDGET: 'Orçamento da campanha',
    SELECT_GOAL: 'Selecione qual o objetivo do seu anúncio',
    TITLE_GDTV: 'Crie sua pasta',
    TITLE_GDTV_ONE: 'Para facilitar a organização, seus comunicados podem ser agrupados por temas.',
    TITLE_GDTV_TWO: 'Suas unidades receberão seu comunicado no',
    TITLE_GDTV_THREE: 'Feed do App.',
    GDTV_NAME: 'Nome*',
    PLACEHOLDER_GDTV: 'Insira o nome da sua pasta',
    INPUT_DESCRIPTION_GDTV: 'Descrição',
    INPUT_DESCRIPTION_GDTV_ONE: 'Qual será o tema do seu comunicado',
    TITLE_GDTV_FOUR: ' ',
    POST_FOLDER_SUCCESS_MESSAGE: 'Pasta criada com sucesso!',
    POST_FOLDER_FAILURE_MESSAGE: 'Falha ao criar esta pasta, tente novamente mais tarde!',
    TITLE_SELECT_GROUPS_GDTV: 'Faça upload do seu conteúdo',
    SUB_TITLE_SELECT_GROUPS_1_GDTV: 'Você poderá publicar apenas uma imagem ou vídeo por vez.',
    SUB_TITLE_SELECT_GROUPS_2_GDTV: 'Extensões permitidas: jpeg, jpg, png, mp4 e webm.',
    SHARE_GDTV: 'Compartilhar',
    VIEW_PREVIOUSLY: 'Ver prévia',
    CAMPAIGN_SUCCESS_MESSAGE: 'Campanha criada com sucesso!',
    CAMPAIGN_FAILURE_MESSAGE: 'Erro ao criar a campanha',
    POST_GDTV: 'Postar',
    TITLE_SELECT_GROUPS: 'Escolha as unidades para exibir campanha',
    SUB_TITLE_SELECT_GROUPS_1: 'Você poderá selecionar um grupo todo ou apenas algumas',
    SUB_TITLE_SELECT_GROUPS_2: 'unidades para exibir a sua campanha de incentivo.',
    YOUR_CAMPAIGN: 'Sua campanha de incentivo',
    CAMPAIGN_LABEL_PERIOD: 'Período de validade da campanha',
    CAMPAIGN_LABEL_OBJECTIVE: 'Objetivo dos anúncios',
    link_clicks: 'Cliques no link',
    post_engagement: 'Engajamento',
    conversions: 'Conversões',
    FILE_TITLE: 'Faça o upload de seus arquivos',

    CANCEL: 'Cancelar',
    SHARE_WITH_EVERYONE: 'Compartilhar com todos',
    INCLUDE_FILE: 'Incluir arquivo',
    FILTER_LABEL_POSTING: 'Postando',
    FILTER_LABEL_ANNOUNCING: 'Anunciando',
    FILTER_LABEL_NOT_POSTING: 'Não Postando',
    FILTER_LABEL_NOT_ANNOUNCING: 'Não Anunciando',
    FILTER_LABEL_BRAND_HOUSE: 'Casas Bahia',
    FILTER_LABEL_BRAND_SCORE: 'Ponto',
    FILTER_LABEL_LOW_INVESTMENT: 'Baixo investimento',
    FILTER_LABEL_HIGH_INVESTMENT: 'Alto investimento',
    FILTER_LABEL_EXPECTED_INVESTMENT: 'Investimento esperado',
    FILTER_LABEL_TOTAL_USE_OF_INVESTMENT: 'Uso total do investimento',
    TO: 'a',
  },
};

const ActivityES = {
  Activity: {
    TITLE: 'Productividad general',
    Complete: 'Datos generales',
    Ad: 'Anuncios',
    Post: 'Publicaciones',
    Money_spent: 'Dinero gastado',
    Sunday: 'Dom',
    Monday: 'Lun',
    Tuesday: 'Mar',
    Wednesday: 'Mié',
    Thursday: 'Jue',
    Friday: 'Vie',
    Saturday: 'Sab',
    NOT_ENOUGH: 'No hay suficientes datos en el período.',
    UNITS: 'Unidades',
    CONNECTED_UNITS: 'Unidades conectadas',
    UNITS_ADVERTISING: 'Unidades anunciando',
    UNITS_POSTING: 'Unidades de publicación',
    UNITS_POSTING_ADVERTISING: 'Unidades publicando y anunciando',
    SHOW: 'Monitor',
    OVERVIEW_DATA: 'Datos generales',
    CONTENTS_CREATED: 'Contenidos producidos',
    MONTHLY_AVERAGE: 'Média mensal',
    DAILY_AVERAGE: 'Objetivo del día',
    VIDEOS: 'videos',
    IMAGES: 'Imagenes',
    STATICS: 'Estáticos',
    IMPRESSIONS_BY_CONTENT: 'Impresiones por contenido',
    MOST_ADVERTISED: 'Unidad que más anunció',
    MOST_ADVERTISED_TOOLTIP: '¡Esta unidad se ha destacado en la producción de anuncios!',
    MOST_POSTED: 'Unidad que más publicó',
    MOST_POSTED_TOOLTIP: 'Esta unidad se ha destacado en la producción de postes orgánicos',
    MOST_ADVERTISED_POSTED: 'Unidad que más anunció y publicó',
    ACTIVE_UNITS: 'Unidades activas',
    PERIOD_ACTIVE: 'Unidades más activas del período',
    ON_PERIOD: 'En el periodo',
    CONNECTED_USERS: 'Usuarios conectados',
    MOST_CONNECTED: 'Usuarios que más se conectaron',
    TODAY: 'Hoy',
    SEE_ALL: 'ver todos',
    NAME: 'Nombre',
    SEARCH_THE_UNIT: 'Buscar la unidad',
    SEARCH_THE_USER: 'Buscar el usuario',
    EMAIL: 'Email',
    ACCESS: 'Accesos',
    COUNT_TOOLTIP: 'Total de anuncios y publicaciones producidos en el período',
    AVERAGE_MONTH_TOOLTIP: 'Contenido promedio producido * por mes * en el período seleccionado',
    AVERAGE_DAY_TOOLTIP: 'Promedio de contenido producido * por día * en el período seleccionado',
    VIDEO_TOOLTIP: 'Porcentaje de contenido creado en video',
    IMAGE_TOOLTIP: 'Porcentaje de contenido creado con imágenes',
    COMPARED_TO: 'Comparado con el período de',
    WHEN_VALUE_WAS: 'cuando el valor era',
    MONITORING_OF_UNITS: 'Seguimiento de unidades',
    GDTV: 'Crear GDTV',
    CREATE_CAMPAIGN: 'Haga campaña',
    CAMPAIGN_NAME: 'Nombre de campaña',
    TITLE_CAMPAIGN: '¡Es hora de crear su campaña de incentivos!',
    TITLE_CAMPAIGN_ONE: 'Solo puede publicar',
    TITLE_CAMPAIGN_TWO: 'una imagen o vídeo',
    TITLE_CAMPAIGN_THREE: 'a la vez.',
    TITLE_CAMPAIGN_FOUR: 'Formatos permitidos: jpeg, jpg, png, mp4 y webm.',
    INPUT_DESCRIPTION: 'Descripción del contenido',
    INPUT_SUBTITLE: 'Título de contenido',
    INPUT_VALID: '¿Cuál será el período de validez de la campaña?',
    SUBTITLE_WARNING:
      'Atención: este período se puede programar y será el mismo que el de la colocación de anuncios para sus unidades.',
    PLACEHOLDER_CAMPAIGN: 'Ingrese el nombre de la campaña',
    PLACEHOLDER_DESCRIPTION: '¿Cuál será la descripción de la unidad?',
    PLACEHOLDER_START_DATE: 'Fecha de inicio',
    PLACEHOLDER_END_DATE: 'Fecha de finalización',
    INPUT_OBJECTIVE: '¿Y el propósito de los anuncios?',
    CANCEL_BUTTON: 'Cancelar',
    SEND_BUTTON: 'Avanzar',
    CHOOSE_GROUPS: 'Elegir grupos',
    CHOOSE_UNITS: 'Elegir unidades',
    CAMPAIGN_BUDGET: '¿Cuál será el presupuesto de la campaña?',
    INSERT_THE_CAMPAIGN_BUDGET: 'Presupuesto Campaña',
    SELECT_GOAL: 'Seleccione para qué es su anuncio',
    TITLE_GDTV: 'Crea su carpeta',
    TITLE_GDTV_ONE: 'Para facilitar la organización, sus comunicados se pueden agrupar por tema..',
    TITLE_GDTV_TWO: 'Sus unidades recibirán su comunicado en',
    TITLE_GDTV_THREE: 'Feed de la aplicación.',
    GDTV_NAME: 'Nombre*',
    PLACEHOLDER_GDTV: 'Ingrese el nombre de su carpeta',
    INPUT_DESCRIPTION_GDTV: 'Descripción',
    INPUT_DESCRIPTION_GDTV_ONE: '¿Cuál será el tema de tu liberación?',
    TITLE_GDTV_FOUR: ' ',
    POST_FOLDER_SUCCESS_MESSAGE: '¡Carpeta creada con éxito!',
    POST_FOLDER_FAILURE_MESSAGE: 'No se pudo crear esta carpeta. Vuelve a intentarlo más tarde.',
    TITLE_SELECT_GROUPS_GDTV: 'Sube su contenido',
    SUB_TITLE_SELECT_GROUPS_1_GDTV: 'Solo puede publicar una imagen o vídeo a la vez.',
    SUB_TITLE_SELECT_GROUPS_2_GDTV: 'Formatos permitidos: jpeg, jpg, png, mp4 y webm.',
    SHARE_GDTV: 'Cuota',
    VIEW_PREVIOUSLY: 'Ver vista previa',
    CAMPAIGN_SUCCESS_MESSAGE: '¡Campaña creada con éxito!',
    CAMPAIGN_FAILURE_MESSAGE: 'Error al crear la campaña',
    POST_GDTV: 'Correo',
    TITLE_SELECT_GROUPS: 'Elija unidades para mostrar la campaña',
    SUB_TITLE_SELECT_GROUPS_1: 'Puede seleccionar un grupo completo o solo algunos',
    SUB_TITLE_SELECT_GROUPS_2: 'unidades para mostrar su campaña de incentivos.',
    YOUR_CAMPAIGN: 'Tu campaña de incentivos',
    CAMPAIGN_LABEL_PERIOD: 'Período de validez de la campaña',
    CAMPAIGN_LABEL_OBJECTIVE: 'Propósito de los anuncios',
    link_clicks: 'Clics en enlaces',
    post_engagement: 'Involucramiento',
    conversions: 'Conversiones',
    FILE_TITLE: 'Sube tus archivos',
    CANCEL: 'Cancelar',
    SHARE_WITH_EVERYONE: 'Compartir con todos',
    INCLUDE_FILE: 'Incluir archivo',
    FILTER_LABEL_POSTING: 'publicación',
    FILTER_LABEL_ANNOUNCING: 'Anunciando',
    FILTER_LABEL_NOT_POSTING: 'Sin Publicar',
    FILTER_LABEL_NOT_ANNOUNCING: 'Sin Anunciar',
    FILTER_LABEL_BRAND_HOUSE: 'Casas Bahia',
    FILTER_LABEL_BRAND_SCORE: 'Ponto',
    FILTER_LABEL_LOW_INVESTMENT: 'Baja inversión',
    FILTER_LABEL_HIGH_INVESTMENT: 'Alta inversión',
    FILTER_LABEL_EXPECTED_INVESTMENT: 'Inversión esperada',
    FILTER_LABEL_TOTAL_USE_OF_INVESTMENT: 'Uso total de la inversión',
    TO: 'hasta',
  },
};

export { ActivityEN, ActivityPT, ActivityES };
