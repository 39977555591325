import styled, { css } from 'styled-components';
import { spacing as DSspacing } from '@bornlogic/reborn';

export const DashboardWrapper = styled('div')``;

export const RouterWrapper = styled('div')`
  ${({ theme: { spacing } }) => css`
    width: ${spacing.full};
  `}
`;

export const ContentWrapper = styled('div')`
  ${({ theme: { breakpoint } }) => css`
    display: flex;
    @media screen and (max-width: ${breakpoint.md}) {
      flex-direction: column;
    }
  `}
`;

export const MainWrapper = styled('div')`
  ${({ theme: { spacing }, shouldNotAddMargin }) => css`
    width: ${spacing.full};
    padding-bottom: ${shouldNotAddMargin ? spacing.px0 : spacing.px40};
    margin-top: ${shouldNotAddMargin ? spacing.px0 : DSspacing.xs};
  `}
`;
