export const UnblockUserModalEN = {
  UnblockUserModal: {
    UNBLOCK_USER_MODAL_CONFIRM: 'Unblock',
    UNBLOCK_USER_MODAL_CANCEL: 'Cancel',
    UNBLOCK_USER_MODAL_TITLE: 'Are you sure you want to unblock this user?',
    UNBLOCK_USER_MODAL_SUCCESS_MESSAGE: 'User unblocked successfully.',
    UNBLOCK_USER_MODAL_ERROR_MESSAGE: 'Failed to unblock user.',
  },
};

export const UnblockUserModalES = {
  UnblockUserModal: {
    UNBLOCK_USER_MODAL_CONFIRM: 'Desbloquear',
    UNBLOCK_USER_MODAL_CANCEL: 'Cancelar',
    UNBLOCK_USER_MODAL_TITLE: '¿Estás seguro de que quieres desbloquear a este usuario?',
    UNBLOCK_USER_MODAL_SUCCESS_MESSAGE: '¡Usuario desbloqueado con éxito!',
    UNBLOCK_USER_MODAL_ERROR_MESSAGE: '¡No se pudo desbloquear el usuario!',
  },
};

export const UnblockUserModalPT = {
  UnblockUserModal: {
    UNBLOCK_USER_MODAL_CONFIRM: 'Desbloquear',
    UNBLOCK_USER_MODAL_CANCEL: 'Cancelar',
    UNBLOCK_USER_MODAL_TITLE: 'Tem certeza de que deseja desbloquear este usuário?',
    UNBLOCK_USER_MODAL_SUCCESS_MESSAGE: 'Usuário desbloqueado com sucesso.',
    UNBLOCK_USER_MODAL_ERROR_MESSAGE: 'Erro ao desbloquear usuário(a).',
  },
};
