import styled from 'styled-components';

export const MainWrapper = styled.div`
  position: absolute;
  right: 130px;
  top: 19px;
`;

export const BlockWrapper = styled.div`
  position: absolute;
  right: 140px;
  top: 30px;

  span {
    color: #999999;
  }
`;

export const Wrapper = styled.div`
  background: #ffffff;
  color: #2328eb;
  height: 40px;
  padding: 0 16px;
  line-height: 40px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #cccccc;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;

  svg {
    color: #cccccc;
  }
`;

export const Option = styled.div`
  background: ${(props) => {
    if (props.disabled && !props.selected) return '#CCCCCC';
    if (props.selected) return '#2328eb';

    return '#ffffff';
  }};
  color: ${(props) => (props.selected ? '#ffffff' : '#333333')};
  height: 49px;
  padding: 0 16px;
  font-size: 14px;
  cursor: pointer;
  border: ${(props) => (props.selected ? '1px solid #2328eb' : '1px solid #cccccc')};
  border-radius: 10px;
  width: 210px;
  margin-bottom: 8px;

  display: flex;
  align-items: center;
`;

export const Popup = styled.div`
  top: 85px;
  margin-left: 10px;
  background: #ffffff;
  box-shadow: 3px 1px 24px 2px rgba(153, 153, 153, 0.1);
  border-radius: 10px;
  padding: 24px;
  position: absolute;
  transition: all 0.3s ease-in-out;
  z-index: 2;
  right: -40px;
`;

export const ClickHere = styled.span`
  cursor: pointer;
  color: #2328eb;
  font-weight: 500;
`;

export const Footer = styled.div`
  display: flex;
  color: #cccccc;
  font-size: 14px;
  font-weight: 400;
`;

export const SelectorTitle = styled.div`
  color: #333333;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 12px;
`;
