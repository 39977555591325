import React, { createContext, useState, useContext, useEffect } from 'react';
import { SHOW_TOAST } from '@redux/toast/types';
import { useDispatch } from 'react-redux';
import { GDScoreService } from '@services';
import { useGetActions } from '../../hooks/GDScore';

const INITIAL_CONTEXT = {
  actions: [],
};

const GDScoreContext = createContext(INITIAL_CONTEXT);

export const GDScoreProvider = ({ children }) => {
  const [actions, setActions] = useState([]);
  const dispatch = useDispatch();
  const { response, loading, mutate, error } = useGetActions();

  useEffect(() => {
    if (response) {
      setActions(response.payload?.map((action) => ({ actionKey: action.key, ...action })));
    }
  }, [response]);

  useEffect(() => {
    if (error) {
      dispatch({
        type: SHOW_TOAST.SUCCESS,
        message: error,
        messageType: 'error',
      });
    }
  }, [error, dispatch]);

  const toggleActionStatus = async (id, payload) => {
    try {
      await GDScoreService.updateAction(payload);
      setActions(
        actions.map((action) =>
          action.id === id ? { ...action, is_active: !action.is_active } : action
        )
      );
    } catch (e) {
      dispatch({
        type: SHOW_TOAST.SUCCESS,
        message: 'Não foi possível alterar o status da ação!',
        messageType: 'error',
      });
    }
  };

  const retrieveAction = (id) => {
    return actions.find((action) => action.id === parseInt(id));
  };

  const saveEditing = async (props) => {
    const {
      id,
      title,
      description,
      points,
      is_repeatable,
      validity_days,
      is_active,
      event_type,
      actionKey,
      created_date,
    } = props;

    try {
      if (!id) {
        const { data } = await GDScoreService.saveActionScore({
          title,
          description,
          is_repeatable,
          is_active,
          event_type,
          validity_days: parseInt(validity_days),
          points: parseInt(points),
          key: actionKey,
          created_date,
        });

        mutate();
        return data.payload.id;
      } else {
        const { data } = await GDScoreService.updateAction({
          id,
          title,
          description,
          is_repeatable,
          is_active,
          event_type,
          validity_days: parseInt(validity_days),
          points: parseInt(points),
          key: actionKey,
          created_date,
        });
        if (!data.is_success) {
          throw new Error('update error');
        }
      }
      mutate();
    } catch (error) {
      dispatch({
        type: SHOW_TOAST.SUCCESS,
        message: 'Não foi possível salvar a ação!',
        messageType: 'error',
      });
      throw new Error('saving error');
    }

    return id;
  };

  return (
    <GDScoreContext.Provider
      value={{
        actions,
        loading,
        toggleActionStatus,
        retrieveAction,
        saveEditing,
      }}
    >
      {children}
    </GDScoreContext.Provider>
  );
};

export const useGDScore = () => {
  const context = useContext(GDScoreContext);

  if (!context) throw new Error('Expected to be wrapped in a GDScore');

  return context;
};
