import styled from 'styled-components';

export const AppWrapper = styled.main`
  background: ${({ isTradeView, theme }) => (isTradeView ? '#fff' : `${theme.colors.grey3}`)};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${({ theme }) => theme.spacing.full};
  position: relative;
`;

export const ToastWrapper = styled.div`
  z-index: 999999;
  position: fixed;
  .Toastify__toast.Toastify__toast--success {
    background: ${({ theme }) => theme.colors.successfulGreen2};
  }
  .Toastify__toast.Toastify__toast--error {
    background: ${({ theme }) => theme.colors.red3};
  }
  .Toastify__toast.Toastify__toast--info {
    background: ${({ theme }) => theme.colors.blue2};
  }
  .Toastify__toast.Toastify__toast--warning {
    background: ${({ theme }) => theme.colors.orange3};
  }
`;
