import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import type { IColor, IFontSize, ISpacing } from 'types/styled';
import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

function sizeInput(spacing: ISpacing, size?: string, fieldValidation?: boolean) {
  switch (size) {
    case 'px20':
      return css`
        ${fieldValidation
          ? css`
              padding: ${spacing.px20} ${spacing.px60} ${spacing.px20} ${spacing.px20};
            `
          : css`
              padding: ${spacing.px20} ${spacing.px20};
            `}
      `;

    case 'px15':
      return css`
        padding: ${spacing.px10} ${spacing.px15};
      `;

    default:
      return css`
        padding: ${spacing.px10} ${spacing.px15};
      `;
  }
}

interface InputDefaultWrapperProps {
  sizeWidth?: string;
}

export const InputDefaultWrapper = styled.div<InputDefaultWrapperProps>`
  ${({ theme, sizeWidth }) => css`
    position: relative;
    background: ${theme.colors.white};
    border-radius: ${theme.rounded.px10};
    ${sizeWidth &&
    css`
      width: ${sizeWidth};
    `}
  `}
`;

interface InputDefaultContentProps {
  mobile: boolean;
  searchAbsolute: boolean;
}

export const InputDefaultContent = styled.div<InputDefaultContentProps>`
  ${({ theme, searchAbsolute, mobile }) => css`
    ${searchAbsolute &&
    css`
      background: ${theme.colors.white};
      position: absolute;
      right: 0;
      top: -19px;
      border-radius: ${theme.rounded.px10};
    `}
    ${mobile &&
    css`
      max-width: ${theme.spacing.full};
      background: transparent;
      width: ${theme.spacing.full};
      ${searchAbsolute &&
      css`
        position: static;
      `}
    `}
  `}
`;

interface InputDefaultStyledProps {
  fontSize?: keyof IFontSize;
  bgColor?: keyof IColor;
  inputSize?: string;
  icon?: boolean;
  fieldValidation?: boolean;
  typed?: string;
  optionsAutoComplete?: string;
  autoResize?: boolean;
  block?: boolean;
  withAutoComplete?: boolean;
  withoutBorder?: boolean;
}

export const InputDefaultStyled = styled.input<InputDefaultStyledProps>`
  ${({
    theme,
    icon,
    autoResize,
    withAutoComplete,
    optionsAutoComplete,
    block,
    inputSize,
    fontSize = 'px14',
    fieldValidation,
    bgColor,
    typed,
    withoutBorder,
  }) => css`
    border: 1px solid ${theme.colors.grey2};
    font-size: ${theme.fontSize[fontSize]};

    font-weight: ${theme.fontWeight.light};
    color: ${theme.colors.blueGrey2};
    background: none;
    outline: none;
    box-sizing: border-box;
    transition: width 0.5s;
    position: relative;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    &:disabled {
      background: ${theme.colors.blue5};
      color: ${theme.colors.blueGrey3};
      cursor: not-allowed;
    }
    ${bgColor &&
    css`
      background-color: ${theme.colors[bgColor]};
      border: none;
      color: black;
    `}
    ${withAutoComplete && optionsAutoComplete && typed && typed.length
      ? css`
          border-radius: ${theme.rounded.px10};
          &:active,
          &:focus,
          &:focus-within {
            border-radius: ${theme.rounded.px10} ${theme.rounded.px10} ${theme.rounded.none}
              ${theme.rounded.none};
          }
        `
      : css`
          border-radius: ${theme.rounded.px10};
        `}
    ${icon
      ? css`
          padding: ${theme.spacing.px10} ${theme.spacing.px15} ${theme.spacing.px10}
            ${theme.spacing.px40};
        `
      : sizeInput(theme.spacing, inputSize, fieldValidation)}
    ${autoResize
      ? css`
          width: 210px;
          &:active,
          &:focus,
          &:focus-within {
            width: 400px;
            max-width: ${theme.spacing.full};
          }
        `
      : css`
          width: ${theme.spacing.full};
          max-width: ${theme.spacing.full};
        `}
    ${block &&
    css`
      width: ${theme.spacing.full};
      max-width: ${theme.spacing.full};
    `}
    ${withoutBorder &&
    css`
      border: none;
    `}
  `}
`;

export const CustomIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.px14};
    color: ${theme.colors.blueGrey3};
    position: absolute;
    left: ${theme.spacing.px15};
    top: 13px;
  `}
`;

export const CustomValidation = styled.span`
  ${({ theme }) => css`
    position: absolute;
    right: ${theme.spacing.px20};
    top: ${theme.spacing.px20};
  `}
`;

interface IconColorProps extends Omit<FontAwesomeIconProps, 'color'> {
  color: keyof IColor;
}

export const CustomValidationIcon = styled(FontAwesomeIcon)<IconColorProps>`
  ${({ theme, color }) => css`
    display: block;
    font-size: ${theme.fontSize.px25};
    color: ${theme.colors[color]};
  `}
`;

export const MessageValidation = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.px12};
    color: ${theme.colors.red3};
    margin-top: ${theme.spacing.px3};
  `}
`;
