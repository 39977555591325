import React from 'react';
import ProgressChart from '../ProgressChart';
import { useTradeInsights } from '@utils/contexts';
import * as S from './styles';
import { Title } from '@bornlogic/gd-design-system';
import { Tooltip } from '@bornlogic/reborn';
import { useTranslate } from 'react-translate';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router';

const TradeHeaderTrackChart = () => {
  const t = useTranslate('TradeInsights');
  const { coverageResponse, showProgress } = useTradeInsights();
  const history = useHistory();

  const graphParameters = {
    size: 75,
    value: coverageResponse?.data?.payload?.word_ads_coverage || '',
    fontSize: '14px',
    backgroundLineColor: '#E6E6E6',
    backgroundColor: 'rgba(0,0,0,0)',
    textMarginTop: 14,
    outerLine: '110%',
    innerLine: '95%',
  };

  const handleRedirect = () => {
    history.push('/root/Trade/DataInfo');
  };

  return (
    <>
      {!showProgress ? (
        <Tooltip placement="bottom" content={t('DATA_CAPACITY_TOOLTIP')}>
          <div>
            <S.Container onClick={handleRedirect}>
              {graphParameters?.value ? (
                <ProgressChart graphParameters={graphParameters} />
              ) : (
                <Skeleton height={45} width={45} circle />
              )}
              <Title as="h5">{t('ADS_DATA_CAPACITY')}</Title>
            </S.Container>
          </div>
        </Tooltip>
      ) : null}
    </>
  );
};

export default TradeHeaderTrackChart;
