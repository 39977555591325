const MarkedActionsFooterEN = {
  ITEMS_SELECTED: 'items selected',
  ITEM_SELECTED: 'item selected',
  UNSELECT_ALL: 'Unselect all',
  MARKED_REPLICATE: 'Replicate',
  MARKED_PROMOTE: 'Promote',
  UNSELECT: 'Unselect',
};
const MarkedActionsFooterPT = {
  ITEMS_SELECTED: 'itens selecionados',
  ITEM_SELECTED: 'item selecionado',
  UNSELECT_ALL: 'Desmarcar todos',
  MARKED_REPLICATE: 'Replicar',
  MARKED_PROMOTE: 'Impulsionar',
  UNSELECT: 'Desmarcar',
};
const MarkedActionsFooterES = {
  ITEMS_SELECTED: 'Elementos seleccionados',
  ITEM_SELECTED: 'Item seleccionado',
  UNSELECT_ALL: 'Deseleccionar todo',
  MARKED_REPLICATE: 'Replicar',
  MARKED_PROMOTE: 'Promover',
  UNSELECT: 'Deseleccionar',
};

export { MarkedActionsFooterEN, MarkedActionsFooterPT, MarkedActionsFooterES };
