import useSWR from 'swr';
import { fetcher } from '@utils/api';

const useGetPendingSurveys = () => {
  const { data, error } = useSWR('/surveys/2.0/pending?origin=WEB', fetcher, {
    revalidateOnFocus: false,
  });

  return {
    surveys: data || null,
    isLoading: !data && !error,
    isError: error ? error.message : error,
  };
};

export { useGetPendingSurveys };
