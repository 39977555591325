import React from 'react';
import { ModalDefault } from '@bornlogic/gd-design-system';
import { useShowToast } from '@utils/hooks/Common';
import { useTranslate } from 'react-translate';
import { useDispatch } from 'react-redux';
import { SettingsService } from '@services';
import { GET_UNITS } from '@redux/settings/types';

import * as S from './styles';

const InactivateUnits = (props) => {
  const { isOpen, onClose, rowsSelected, onSuccess } = props;
  const t = useTranslate('Units');
  const dispatch = useDispatch();
  const showToast = useShowToast();

  const handleInactivateUnits = async () => {
    const source_id = rowsSelected[0]?.source_id;
    const response = await SettingsService.inactivateUnits(source_id, {});

    if (response.data.is_success) {
      showToast(t('TOAST_SUCESS'), 'success');

      dispatch({
        type: GET_UNITS.RESET,
      });

      onSuccess();
      onClose();
    }
  };

  const handleCancelStatus = () => {
    onClose();
  };

  return (
    <ModalDefault
      isOpen={isOpen}
      title={t('INACTIVATE_UNIT')}
      confirmBtnText="Confirmar"
      optionBtnText="Cancelar"
      onOptionBtnClick={handleCancelStatus}
      onConfirmBtnClick={handleInactivateUnits}
      thirdBtnDisabled
      size="40%"
      maxHeight="90%"
    >
      <S.Container>
        {rowsSelected.length > 0 && (
          <>
            <p>{t('MODAL_INACTIVATE_UNIT', { nameUnit: rowsSelected[0]?.name })}</p>
            <p>{t('INFORMATION_BUDGET_MODAL')}</p>
          </>
        )}
      </S.Container>
    </ModalDefault>
  );
};

export default InactivateUnits;
