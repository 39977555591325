import React from 'react';
import { MixPanelController } from '../../../controllers';
import { Link } from 'react-router-dom';

function LinkWithMixPanel({ eventName, onClick, children, className, ...rest }) {
  function onClickWithEvent(event) {
    if (eventName) {
      const mixPanel = new MixPanelController({ eventName, eventType: 'click' });
      mixPanel.createEvent();
    }

    if (onClick) {
      onClick(event);
    }
  }

  return (
    <Link className={className} data-testid="linkWithMixPanel" onClick={onClickWithEvent} {...rest}>
      {children}
    </Link>
  );
}

export default LinkWithMixPanel;
