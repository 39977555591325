import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { getTimezone } from '../helpers';

export const DATE_QUERY_FORMAT = 'YYYY-MM-DD';

export const useDateQueryParams = () => {
  const { search } = useLocation();

  let start;
  let end;
  let params;
  let dt = '';

  const paramsObject = useMemo(() => new URLSearchParams(search), [search]);
  const datePreset = paramsObject.get('date_preset');
  const since = paramsObject.get('since');
  const until = paramsObject.get('until');

  if (datePreset || (since && until)) {
    const config = {
      startOfToday: dayjs(new Date()).startOf('day').format(DATE_QUERY_FORMAT),
      endOfToday: dayjs(new Date()).endOf('day').format(DATE_QUERY_FORMAT),
      startOfYesterday: dayjs(new Date())
        .subtract(1, 'day')
        .startOf('day')
        .format(DATE_QUERY_FORMAT),
      endOfYesterday: dayjs(new Date()).subtract(1, 'day').endOf('day').format(DATE_QUERY_FORMAT),
      lastSevenDays: dayjs(new Date()).subtract(6, 'day').endOf('day').format(DATE_QUERY_FORMAT),
      startOfMonth: dayjs(new Date()).startOf('month').format(DATE_QUERY_FORMAT),
      startOfYear: dayjs(new Date()).startOf('year').format(DATE_QUERY_FORMAT),
      startOfLastMonth: dayjs(new Date())
        .subtract(1, 'month')
        .startOf('month')
        .format(DATE_QUERY_FORMAT),
      endOfLastMonth: dayjs(new Date())
        .subtract(1, 'month')
        .endOf('month')
        .format(DATE_QUERY_FORMAT),
      startCustomPeriod: dayjs(since).subtract(1, 'month').endOf('month').format(DATE_QUERY_FORMAT),
      endCustomPeriod: dayjs(until).subtract(1, 'month').endOf('month').format(DATE_QUERY_FORMAT),
    };

    if (datePreset) {
      dt = datePreset.toUpperCase();
      params = `date_preset=${datePreset}`;

      if (dt === 'TODAY') {
        start = config.startOfToday;
        end = config.endOfToday;
      } else if (dt === 'YESTERDAY') {
        start = config.startOfYesterday;
        end = config.endOfYesterday;
      } else if (dt === 'LAST_7D') {
        start = config.lastSevenDays;
        end = config.endOfToday;
      } else if (dt === 'LAST_MONTH') {
        start = config.startOfLastMonth;
        end = config.endOfLastMonth;
      } else if (dt === 'THIS_MONTH') {
        start = config.startOfMonth;
        end = config.endOfToday;
      } else {
        start = config.startOfYear;
        end = config.endOfToday;
      }

      // Used only to call the Api right way.
    } else {
      start = dayjs(new Date(`${since} GMT${getTimezone()}`)).format(DATE_QUERY_FORMAT);
      end = dayjs(new Date(`${until} GMT${getTimezone()}`)).format(DATE_QUERY_FORMAT);

      params = `since=${start}&until=${end}`;

      if (since === config.startOfToday && until === config.endOfToday) {
        dt = 'TODAY';
      } else if (since === config.startOfYesterday && until === config.endOfYesterday) {
        dt = 'YESTERDAY';
      } else if (since === config.lastSevenDays && until === config.endOfToday) {
        dt = 'LAST_7D';
      } else if (since === config.startOfLastMonth && until === config.endOfLastMonth) {
        dt = 'LAST_MONTH';
      } else if (since === config.startOfMonth && until === config.endOfToday) {
        dt = 'THIS_MONTH';
      } else if (since === config.startOfYear && until === config.endOfToday) {
        dt = 'THIS_YEAR';
      } else if (since === config.startCustomPeriod && until === config.endCustomPeriod) {
        params = `since=${config.startCustomPeriod}&until=${config.endCustomPeriod}`;
      }
    }
  }

  return useMemo(
    () => ({
      datePreset: dt,
      start,
      end,
      params: params?.toLowerCase(),
    }),
    [dt, end, params, start]
  );
};
