import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

const CounterBall = ({ backgroundColor, fontColor, counter, alignTop }) => {
  return (
    <>
      <S.CounterBallWrapper
        moreThanTen={counter > 10}
        backgroundColor={backgroundColor}
        fontColor={fontColor}
        alignTop={alignTop}
      >
        <span>{counter}</span>
      </S.CounterBallWrapper>
    </>
  );
};

CounterBall.propTypes = {
  backgroundColor: PropTypes.string,
  fontColor: PropTypes.string,
  counter: PropTypes.number,
  alignTop: PropTypes.bool,
};

CounterBall.defaultProps = {
  backgroundColor: '',
  fontColor: '',
  counter: 0,
  alignTop: false,
};

export default CounterBall;
