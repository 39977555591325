import React, { createContext, useContext, useState } from 'react';
import { formatCurrency } from '@utils/numbers';
import { yupValidate } from '@utils/validators';
import {
  campaignFormModel,
  formInitialValues,
  INITIAL_CONTEXT,
  steps,
  validationSchema,
} from './constants';
import { useCampaign } from '@utils/contexts';
import { CampaignService } from '@services';
import { useParams } from 'react-router';
import { useGetMetrics } from 'utils/hooks/ReportMetrics';
import { isKeyValueTrue } from 'utils/helpers';

const CampaignFormStepContext = createContext(INITIAL_CONTEXT);

export const CampaignFormStepProvider = ({ children }) => {
  const [formData, setFormData] = useState(INITIAL_CONTEXT.data);
  const [file, setFile] = useState([]);
  const { code } = useParams();

  const [groups, setGroups] = useState([]);
  const [groupsName, setGroupsName] = useState([]);

  const [getTags, setGetTags] = useState([]);

  const [leadForm, setLeadForm] = useState('');
  const [currentStep, setCurrentStep] = useState(INITIAL_CONTEXT.currentStep);

  const [shoulShowRequiredGroups, setShouldShowRequiredGroups] = useState(false);
  const [shouldShowRequiredFile, setShouldShowRequiredFile] = useState(false);
  const [mediaLibraryFolderForm, setMediaLibraryFolderForm] = useState('');

  const isLastStep = currentStep === steps.length - 1;
  const { id, img } = steps[currentStep];

  const [creationLoading, setCreationLoading] = useState(false);

  const [showComunicationCard, setShowComunicationCard] = useState(false);

  const { getMetrics } = useGetMetrics('CI_BUDGET_PER_UNIT');
  const hasBudgetPerUnit = isKeyValueTrue(getMetrics?.value);

  const {
    campaigns: { mutateCampaigns },
  } = useCampaign();

  function skipStep() {
    const units = JSON.parse(localStorage.getItem('preSelectedUnitsCI'));
    const hasPreSelectedUnits = units?.length;

    if (hasPreSelectedUnits && currentStep === 0) {
      localStorage.removeItem('preSelectedUnitsCI');
      setGroups(units.map((unit) => unit.source_id));
      setGroupsName(units.map((unit) => unit.name));
      setShowComunicationCard(true);
      return true;
    }

    return false;
  }

  function nextStep() {
    if (currentStep === 0 && file.length < 1) {
      setShouldShowRequiredFile(true);
      return;
    }
    if (currentStep === 1 && groups.length < 1) {
      setShouldShowRequiredGroups(true);
      return;
    }
    if (skipStep()) {
      setCurrentStep(2);
      return;
    }
    setCurrentStep(currentStep + 1);
  }

  function previousStep() {
    setCurrentStep(currentStep - 1);
  }

  async function createCampaign() {
    setCreationLoading(true);

    const tzoffset = new Date().getTimezoneOffset() * 60000;
    const { max_budget, startDate, endDate, campaign_lead_form, default_budget, tags } = formData;
    const { files, blobUrls } = file[0];
    const shouldRenderDefaultBudget =
      default_budget !== '' &&
      formatCurrency({ value: max_budget }) !== '.' &&
      formatCurrency({ value: max_budget }) !== '0.00';

    const shouldRenderMaxBudget =
      max_budget !== '' &&
      formatCurrency({ value: max_budget }) !== '.' &&
      formatCurrency({ value: max_budget }) !== '0.00';

    const body = {
      ...formData,
      start_time: new Date(startDate).toISOString(),
      end_time: new Date(new Date(endDate) - tzoffset).toISOString().slice(0, -1),
      creative_specs: { lead_form_id: campaign_lead_form },
      ...(shouldRenderMaxBudget && {
        max_budget: formatCurrency({ value: max_budget }),
      }),
      ...(shouldRenderDefaultBudget && {
        default_budget: formatCurrency({ value: default_budget }),
      }),
      permitted_groups: groups ? groups : [],
      media_type: files.length && files[0].file.type.includes('image') ? 'SINGLE_PICTURE' : 'VIDEO',
      medias: blobUrls.map((item) => item.url),
      user_permitted_group: code,
    };

    if (tags) body.tags = [tags];
    if (!shouldRenderMaxBudget) delete body.max_budget;
    if (!shouldRenderDefaultBudget) delete body.default_budget;

    const response = await CampaignService.createCampaign(body);
    setCreationLoading(false);
    if (response?.data?.is_success) {
      mutateCampaigns();
      return 'Campanha criada com sucesso!';
    } else {
      throw response;
    }
  }

  async function checkIsNextStepAble({ values }) {
    if (currentStep === 0) {
      if (hasBudgetPerUnit) return true;

      const response = await yupValidate({ schema: validationSchema, data: values });
      return response.isValid && file.length;
    } else if (currentStep === 1) {
      return groups.length >= 1;
    }
    return true;
  }

  return (
    <CampaignFormStepContext.Provider
      value={{
        formData,
        setFormData,
        currentStep,
        nextStep,
        previousStep,
        file,
        setFile,
        groups,
        setGroups,
        id,
        img,
        isLastStep,
        submitForm: createCampaign,
        creationLoading,
        shouldShowRequiredFile,
        setShouldShowRequiredFile,
        shoulShowRequiredGroups,
        setShouldShowRequiredGroups,
        groupsName,
        setGroupsName,
        leadForm,
        setLeadForm,
        mediaLibraryFolderForm,
        setMediaLibraryFolderForm,
        setGetTags,
        getTags,
        showComunicationCard,
        checkIsNextStepAble,
        validationSchema,
        campaignFormModel,
        formInitialValues,
        hasBudgetPerUnit,
      }}
    >
      {children}
    </CampaignFormStepContext.Provider>
  );
};

export const useCampaignFormStep = () => {
  const context = useContext(CampaignFormStepContext);

  if (!context) throw new Error('Expected to be wrapped in a CampaignFormStep');

  return context;
};
