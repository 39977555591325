const HeaderMarkedGroupsEN = {
  ACCOUNTS: 'Accounts',
  UNSELECT_ALL: 'Unselect all',
  SELECT_ALL: 'Select all',
  GROUPS: 'Groups',
  FANPAGES: 'Fanpages',
};
const HeaderMarkedGroupsPT = {
  ACCOUNTS: 'Contas',
  UNSELECT_ALL: 'Desmarcar todos',
  SELECT_ALL: 'Selecionar todos',
  GROUPS: 'Grupos',
  FANPAGES: 'Fanpages',
};
const HeaderMarkedGroupsES = {
  ACCOUNTS: 'Contas',
  UNSELECT_ALL: 'Desmarcar todos',
  SELECT_ALL: 'Selecionar todos',
  GROUPS: 'Grupos',
  FANPAGES: 'Fanpages',
};

export { HeaderMarkedGroupsEN, HeaderMarkedGroupsPT, HeaderMarkedGroupsES };
