import React from 'react';
import { useLocation, useParams } from 'react-router';
import { useTranslate } from 'react-translate';
import MenuLinkItem from './MenuLinkItem';

function MenuLink({ links, page }) {
  const t = useTranslate('Sidebar');
  const params = useParams();
  const { search } = useLocation();

  function getLink(link) {
    if (link?.active) {
      return link.active;
    }

    return params.module === link?.name ? 1 : 0;
  }

  function formatSubMenus(subMenus) {
    subMenus.forEach((subMenu) => {
      if (subMenu.translator) {
        subMenu.text = `${t(subMenu.translator)}`;
        subMenu.to = {
          pathname: `/${params.code}/${page}/${subMenu.name}/${subMenu.conditional || ''}`,
          search,
        };
        subMenu.active =
          params.module === subMenu.name ||
          (subMenu.tabMenus && subMenu.tabMenus.includes(params.module))
            ? 1
            : 0;
      }
    });
  }

  return links.map((link, index) => {
    const active = getLink(link);

    if (link.subMenus) {
      formatSubMenus(link.subMenus);
    }

    return (
      <MenuLinkItem
        disabled={link.disabled}
        key={index}
        to={{
          pathname: `/${params.code}/${page}/${link.name}/${link.conditional || ''}`,
          search,
        }}
        isCustom={link.isCustom}
        active={active}
        icon={link.icon}
        text={t(link.translator)}
        subMenus={link.subMenus}
        onClick={link.logger ? link.logger : () => {}}
      />
    );
  });
}

export default MenuLink;
