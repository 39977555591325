import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';

interface IWindowDimensions {
  width: number;
  height: number;
}

export function useWindowDimensions(): IWindowDimensions {
  const [dimensions, setDimension] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const getDimension = debounce(() => {
    setDimension({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, 1000);

  useEffect(() => {
    window.addEventListener('resize', getDimension);

    return () => {
      window.removeEventListener('resize', getDimension);
    };
  }, [getDimension]);

  return { width: dimensions.width, height: dimensions.height };
}
