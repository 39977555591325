// Modules
import React from 'react';
import { useSelector } from 'react-redux';
import * as S from './styles';

// Components
import EditModal from './EditModal';
import EllipsisButton from 'containers/Scope/MediasCenter/pages/Feed/EllipsisButton';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utils
import { handleValueTooBig } from '@utils/helpers';
import { maskValue, MASK_TYPE } from 'utils/helpers';
import dayjs from 'dayjs';

function Footer({
  media,
  reach,
  loadPreviews,
  openEdit,
  showEditModal,
  feedType,
  insightsTransformed,
  periodTransformed,
  closeModal,
  canEditAd,
}) {
  const { feedChannel } = useSelector((state) => state.genericReducer);
  const { subDomain } = useSelector((state) => state.authenticationReducer);

  const hasEllipsisButton = media.link_url ? true : false;

  return (
    <S.Wrapper>
      <EllipsisButton
        media={media}
        loadPreviews={() => loadPreviews(media)}
        isInstagram={feedType === 'INSTAGRAM_STORIES' || feedChannel === 'instagram'}
        openEdit={openEdit}
        feedType={feedType}
        canEditAd={canEditAd}
      />
      <S.Info
        full={!hasEllipsisButton}
        idAd={feedType === 'AD'}
        isInstagram={
          feedType === 'INSTAGRAM_STORIES' || (feedType === 'POST' && feedChannel === 'instagram')
        }
      >
        {subDomain.sub_domain === 'kroton' ? (
          <>
            <div className="first-content">
              {feedType === 'INSTAGRAM_STORIES' ||
              (feedType === 'POST' && feedChannel === 'instagram') ? (
                <>
                  <div title={media.like_count}>
                    <FontAwesomeIcon style={{ color: '#6A51A8' }} icon={['fal', 'thumbs-up']} />
                    {handleValueTooBig(media.like_count)}
                  </div>
                  <div>
                    <FontAwesomeIcon style={{ color: '#6A51A8' }} icon={['fal', 'clock']} />
                    {dayjs(new Date(media.timestamp)).format('DD/MM/YY - HH:mm')}
                  </div>
                </>
              ) : (
                <>
                  <div title={insightsTransformed.thumbsValue}>
                    <FontAwesomeIcon style={{ color: '#6A51A8' }} icon={['fal', 'thumbs-up']} />
                    {handleValueTooBig(insightsTransformed.thumbsValue)}
                  </div>

                  {!!media.reach || media.reach === '0' ? (
                    <div title={reach}>
                      <FontAwesomeIcon
                        style={{ color: '#6A51A8' }}
                        icon={['fal', 'user-friends']}
                      />
                      {handleValueTooBig(media.reach)}
                    </div>
                  ) : (
                    <div title={insightsTransformed.printValue}>
                      <FontAwesomeIcon style={{ color: '#6A51A8' }} icon={['fas', 'print']} />
                      {handleValueTooBig(insightsTransformed.printValue)}
                    </div>
                  )}

                  <div title={insightsTransformed.pointerValue}>
                    <FontAwesomeIcon style={{ color: '#6A51A8' }} icon={['fal', 'mouse-pointer']} />
                    {handleValueTooBig(insightsTransformed.pointerValue)}
                  </div>
                </>
              )}
            </div>
            {feedType === 'INSTAGRAM_STORIES' ||
            (feedType === 'POST' && feedChannel === 'instagram') ? null : (
              <S.SecondContentWrapper>
                <S.FirstElement title={insightsTransformed.linkValue}>
                  <FontAwesomeIcon style={{ color: '#6A51A8' }} icon={['fal', 'link']} />
                  <S.ValueLink>{handleValueTooBig(insightsTransformed.linkValue)}</S.ValueLink>
                </S.FirstElement>
                <S.SecondElement title={insightsTransformed.linkValue}>
                  <FontAwesomeIcon style={{ color: '#6A51A8' }} icon={['fal', 'donate']} />
                  <S.ValueDonate>
                    {handleValueTooBig(insightsTransformed.conversionPurchaseValue)}
                  </S.ValueDonate>
                </S.SecondElement>
              </S.SecondContentWrapper>
            )}
          </>
        ) : (
          <div className="first-content">
            {feedType === 'INSTAGRAM_STORIES' ||
            (feedType === 'POST' && feedChannel === 'instagram') ? (
              <>
                <div title={media.like_count}>
                  <FontAwesomeIcon style={{ color: '#6A51A8' }} icon={['fal', 'thumbs-up']} />
                  {handleValueTooBig(media.like_count)}
                </div>
                <div>
                  <FontAwesomeIcon style={{ color: '#6A51A8' }} icon={['fal', 'clock']} />
                  {moment(new Date(media.timestamp)).format('DD/MM/YY - HH:mm')}
                </div>
              </>
            ) : (
              <>
                <div title={insightsTransformed.thumbsValue}>
                  <FontAwesomeIcon style={{ color: '#6A51A8' }} icon={['fal', 'thumbs-up']} />
                  {handleValueTooBig(insightsTransformed.thumbsValue)}
                </div>

                {!!media.reach || media.reach === '0' ? (
                  <div title={reach}>
                    <FontAwesomeIcon style={{ color: '#6A51A8' }} icon={['fal', 'user-friends']} />
                    {handleValueTooBig(media.reach)}
                  </div>
                ) : (
                  <div title={insightsTransformed.printValue}>
                    <FontAwesomeIcon style={{ color: '#6A51A8' }} icon={['fas', 'print']} />
                    {handleValueTooBig(insightsTransformed.printValue)}
                  </div>
                )}

                <div title={insightsTransformed.pointerValue}>
                  <FontAwesomeIcon style={{ color: '#6A51A8' }} icon={['fal', 'mouse-pointer']} />
                  {handleValueTooBig(insightsTransformed.pointerValue)}
                </div>

                <div title={insightsTransformed.linkValue}>
                  <FontAwesomeIcon style={{ color: '#6A51A8' }} icon={['fal', 'link']} />
                  {handleValueTooBig(insightsTransformed.linkValue)}
                </div>
              </>
            )}
          </div>
        )}

        {feedType === 'AD' && (
          <S.AdDataWrapper>
            <div>{maskValue(insightsTransformed.spendValue, MASK_TYPE.CURRENCY)}</div>
            {periodTransformed && (
              <div>
                <FontAwesomeIcon
                  icon={['far', 'calendar-alt']}
                  style={{ marginRight: '6px', color: '#6A51A8' }}
                />
                {periodTransformed}
              </div>
            )}
          </S.AdDataWrapper>
        )}
      </S.Info>

      <EditModal showEditModal={showEditModal} media={media} closeModal={closeModal} />
    </S.Wrapper>
  );
}

export default Footer;
