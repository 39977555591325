import React, { useState, useCallback, useEffect } from 'react';

import { SettingsService } from '@services';

const INITIAL_CONTEXT = {
  facebook: false,
  instagram: false,
  feed: false,
  rightHandColumn: false,
  instantArticle: false,
  marketplace: false,
  story: false,
  suggestedVideo: false,
  instreamVideo: false,
  stream: false,
  instagramStory: false,
  strZipCodes: '',
  localizationOption: 1,
  latitude: '',
  longitude: '',
  radius: '',
  zipCodes: [],
  male: false,
  female: false,
  minAge: '',
  maxAge: '',
};

export const LOCALIZATION_OPTION = {
  GEO_LOCATION: 1,
  CITIES: 2,
  ZIP_CODE: 3,
};

const AdvancedConfigsContext = React.createContext(INITIAL_CONTEXT);

export const useAdvancedConfig = (sourceId, unitSourceId) => {
  const [ruleId, setRuleId] = useState('');
  const [adAccountId, setAdAccountId] = useState('');
  const [shouldCreateRule, setShouldCreateRule] = useState(false);

  const [facebookAutomaticPositions, setFacebookAutomaticPositions] = useState(false);
  const [instagramAutomaticPositions, setInstagramAutomaticPositions] = useState(false);
  const [feed, setFeed] = useState(false);
  const [rightHandColumn, setRightHandColumn] = useState(false);
  const [instantArticle, setInstantArticle] = useState(false);
  const [marketplace, setMarketplace] = useState(false);
  const [story, setStory] = useState(false);
  const [suggestedVideo, setSuggestedVideo] = useState(false);
  const [instreamVideo, setInstreamVideo] = useState(false);
  const [stream, setStream] = useState(false);
  const [instagramStory, setInstagramStory] = useState(false);
  const [strZipCodes, setStrZipCodes] = useState('');
  const [localizationOption, setLocalizationOption] = useState(1);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [radius, setRadius] = useState('');
  const [zipCodes, setZipCodes] = useState([]);
  const [citiesIds, setCitiesIds] = useState([]);
  const [male, setMale] = useState(false);
  const [female, setFemale] = useState(false);
  const [minAge, setMinAge] = useState('');
  const [maxAge, setMaxAge] = useState('');
  const [goals, setGoals] = useState([]);
  const [ctas, setCtas] = useState([]);
  const [disabledCtas, setDisabledCtas] = useState([]);

  const tKey = 'ADVANCED_CONFIG';

  const functions = {
    setFacebookAutomaticPositions,
    setInstagramAutomaticPositions,
    setFeed,
    setRightHandColumn,
    setInstantArticle,
    setMarketplace,
    setStory,
    setSuggestedVideo,
    setInstreamVideo,
    setStream,
    setInstagramStory,
    setStrZipCodes,
    setLocalizationOption,
    setLatitude,
    setLongitude,
    setRadius,
    setZipCodes,
    setCitiesIds,
    setMale,
    setFemale,
    setMinAge,
    setMaxAge,
    setGoals,
    setCtas,
    setDisabledCtas,
  };

  const values = {
    facebookAutomaticPositions,
    instagramAutomaticPositions,
    feed,
    rightHandColumn,
    instantArticle,
    marketplace,
    story,
    suggestedVideo,
    instreamVideo,
    stream,
    instagramStory,
    strZipCodes,
    localizationOption,
    latitude,
    longitude,
    radius,
    zipCodes,
    citiesIds,
    male,
    female,
    minAge,
    maxAge,
    goals,
    ctas,
    disabledCtas,
  };

  const processDataAdvancedConfigs = (data) => {
    if (data?.geo_location_specs && data?.geo_location_specs.length > 0) {
      setLocalizationOption(LOCALIZATION_OPTION.GEO_LOCATION);
      setLatitude(data?.geo_location_specs[0].latitude);
      setLongitude(data?.geo_location_specs[0].longitude);
      setRadius(data?.geo_location_specs[0].radius);
    } else if (data?.city_key_codes) {
      setLocalizationOption(LOCALIZATION_OPTION.CITIES);
      setCitiesIds(data?.city_key_codes);
    } else if (data?.zip_key_codes && data?.zip_key_codes.length > 0) {
      setLocalizationOption(LOCALIZATION_OPTION.ZIP_CODE);
      setZipCodes(data?.zip_key_codes);
    }

    if (data?.facebook_positions && data?.facebook_positions.length > 0) {
      setFeed(data?.facebook_positions.find((position) => position === 'feed'));
      setRightHandColumn(
        data?.facebook_positions.find((position) => position === 'right_hand_column')
      );
      setInstantArticle(
        data?.facebook_positions.find((position) => position === 'instant_article')
      );
      setMarketplace(data?.facebook_positions.find((position) => position === 'marketplace'));
      setStory(data?.facebook_positions.find((position) => position === 'story'));
      setSuggestedVideo(
        data?.facebook_positions.find((position) => position === 'suggested_video')
      );
      setInstreamVideo(data?.facebook_positions.find((position) => position === 'instream_video'));
    }

    if (data?.instagram_positions && data?.instagram_positions.length > 0) {
      setStream(data?.instagram_positions.find((position) => position === 'stream'));
      setInstagramStory(data?.instagram_positions.find((position) => position === 'story'));
    }

    if (data?.publisher_platforms?.includes('facebook') && data?.facebook_positions?.length === 0) {
      setFacebookAutomaticPositions(true);
    }

    if (
      data?.publisher_platforms?.includes('instagram') &&
      data?.instagram_positions?.length === 0
    ) {
      setInstagramAutomaticPositions(true);
    }

    if (data?.min_age) {
      setMinAge(data?.min_age);
    }

    if (data?.max_age) {
      setMaxAge(data?.max_age);
    }

    if (data?.genders && data?.genders.length > 0) {
      data?.genders.forEach((gender) => {
        if (gender === 'male') {
          setMale(true);
        }

        if (gender === 'female') {
          setFemale(true);
        }
      });
    }
  };

  const getAdAccountAdvancedConfig = useCallback(async () => {
    if (unitSourceId) {
      const { data } = await SettingsService.getGoalsAndCtaRules(unitSourceId);
      setCtas(
        [...(data?.valid_call_to_actions ?? []), ...(data?.disabled_call_to_actions ?? [])] || []
      );
      setGoals(data?.valid_campaign_objectives || []);
      setDisabledCtas(data?.disabled_call_to_actions || []);
    }

    if (sourceId) {
      const response = await SettingsService.getAdvancedConfigByAccoundId(sourceId);

      if (response.status === 200) {
        const advancedConfigs = response.data?.specs_to_force;
        processDataAdvancedConfigs(advancedConfigs);
        setRuleId(response.data?.id);
        setAdAccountId(response.data?.ad_account_id);
      } else if (response.status === 404) {
        setAdAccountId(sourceId);
        setShouldCreateRule(true);
      }
    }
  }, [sourceId, unitSourceId]);

  const formatZipKeyCodes = () => {
    const formatedCodes = [];
    const splitedCodes = strZipCodes.split(',');

    splitedCodes.forEach((code) => {
      formatedCodes.push(code.trim());
    });

    return formatedCodes;
  };

  const processPublisherPlatformsAndPositions = () => {
    let facebook_positions = [];
    let instagram_positions = [];
    const publisher_platforms = [];

    if (feed) {
      facebook_positions.push('feed');
    }

    if (rightHandColumn) {
      facebook_positions.push('right_hand_column');
    }

    if (instantArticle) {
      facebook_positions.push('instant_article');
    }

    if (marketplace) {
      facebook_positions.push('marketplace');
    }

    if (story) {
      facebook_positions.push('story');
    }

    if (suggestedVideo) {
      facebook_positions.push('suggested_video');
    }

    if (instreamVideo) {
      facebook_positions.push('instream_video');
    }

    if (stream) {
      instagram_positions.push('stream');
    }

    if (instagramStory) {
      instagram_positions.push('story');
    }

    if (instagram_positions.length > 0 || instagramAutomaticPositions) {
      publisher_platforms.push('instagram');
    }

    if (facebook_positions.length > 0 || facebookAutomaticPositions) {
      publisher_platforms.push('facebook');
    }

    if (facebookAutomaticPositions) {
      facebook_positions = [];
    }

    if (instagramAutomaticPositions) {
      instagram_positions = [];
    }

    return { facebook_positions, instagram_positions, publisher_platforms };
  };

  const processGenders = () => {
    const genders = [];

    if (male) {
      genders.push('male');
    }

    if (female) {
      genders.push('female');
    }

    if (genders.length === 0) return null;

    return { genders };
  };

  const processLocalization = () => {
    let geo_location_specs = null;

    if (localizationOption === LOCALIZATION_OPTION.ZIP_CODE) {
      return { zip_key_codes: formatZipKeyCodes() };
    }

    if (localizationOption === LOCALIZATION_OPTION.CITIES) {
      return {
        city_key_codes: citiesIds,
      };
    }

    if (
      localizationOption === LOCALIZATION_OPTION.GEO_LOCATION &&
      (latitude || longitude || radius)
    ) {
      geo_location_specs = [{}];

      if (latitude) {
        geo_location_specs[0].latitude = latitude;
      }

      if (longitude) {
        geo_location_specs[0].longitude = longitude;
      }

      if (radius) {
        geo_location_specs[0].radius = radius;
        geo_location_specs[0].radius_distance_unit = 'kilometer';
      }
    }

    if (!latitude && !longitude && !radius) return null;

    return { geo_location_specs };
  };

  const processMinAge = () => {
    if (minAge) {
      return { min_age: Number(minAge) };
    }

    return null;
  };

  const processMaxAge = () => {
    if (maxAge) {
      return { max_age: Number(maxAge) };
    }

    return null;
  };

  const processRequestBody = () => {
    let processedBodyRequest = {
      ad_account_id: adAccountId,
    };

    if (!shouldCreateRule) processedBodyRequest.id = ruleId;

    processedBodyRequest = {
      ...processedBodyRequest,
      specs_to_force: {
        ...processLocalization(),
        ...processGenders(),
        ...processMinAge(),
        ...processMaxAge(),
        ...processPublisherPlatformsAndPositions(),
      },
    };

    return processedBodyRequest;
  };

  const requestBody = processRequestBody();

  const resetFields = () => {
    setFacebookAutomaticPositions(false);
    setInstagramAutomaticPositions(false);
    setFeed(false);
    setRightHandColumn(false);
    setInstantArticle(false);
    setMarketplace(false);
    setStory(false);
    setSuggestedVideo(false);
    setInstreamVideo(false);
    setStream(false);
    setInstagramStory(false);
    setStrZipCodes('');
    setLocalizationOption(1);
    setLatitude('');
    setLongitude('');
    setRadius('');
    setZipCodes([]);
    setMale(false);
    setFemale(false);
    setMinAge('');
    setMaxAge('');
    setCtas([]);
    setGoals([]);
    setDisabledCtas([]);
  };

  const resetAddAccount = () => {
    setAdAccountId('');
  };

  useEffect(() => {
    if (sourceId !== adAccountId) {
      resetFields();
      getAdAccountAdvancedConfig();
    }
  }, [sourceId, getAdAccountAdvancedConfig, adAccountId]);

  return {
    shouldCreateRule,
    setShouldCreateRule,
    tKey,
    ...functions,
    ...values,
    requestBody,
    resetAddAccount,
  };
};

export default AdvancedConfigsContext;
