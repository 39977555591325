// Api
import { ApiTenant } from '@utils/api';

class VideoViewService {
  static headerAndFooterInfo(data) {
    return ApiTenant.post('/engagement/report/groups/facebook/videos', data);
  }

  static chart(data) {
    return ApiTenant.post('/engagement/report/groups/facebook/videos/watch-time', data);
  }

  static audience(data) {
    return ApiTenant.post('/engagement/report/groups/facebook/videos/audience', data);
  }

  static table(data) {
    return ApiTenant.post('/engagement/report/groups/facebook/videos/best-performing', data);
  }
}

export default VideoViewService;
