import React, { useState } from 'react';
import ClickOutHandler from 'react-clickout-handler';
import { useTranslate } from 'react-translate';
import { useSelector } from 'react-redux';
import { FACEBOOK_URL } from '@utils/constants';
import * as S from './styles';

export default function EllipsisButton({
  listMode,
  media,
  loadPreviews,
  isInstagram,
  openEdit,
  canEditAd,
}) {
  const t = useTranslate('Feed');

  const { feedType } = useSelector((state) => state.feedReducer);

  const [isVisible, setIsVisible] = useState(false);

  const openOnFacebook = () => {
    window.open(FACEBOOK_URL + media.page_post_id.replace('_', '/posts/'));
    setIsVisible(!isVisible);
  };

  const openPreview = () => {
    loadPreviews(media.id);
    setIsVisible(!isVisible);
  };

  const openOnInstagram = () => {
    window.open(media.permalink);
    setIsVisible(!isVisible);
  };

  const handleOpenEdit = () => {
    openEdit(media.id);
    setIsVisible(!isVisible);
  };

  if (listMode) return null;

  return (
    <ClickOutHandler onClickOut={() => setIsVisible(false)}>
      <S.Icon icon={['far', 'ellipsis-v']} onClick={() => setIsVisible(!isVisible)} />
      {isVisible && (
        <S.MenuProfileWrapper feedType={feedType}>
          <S.MenuProfileRelative>
            <S.MenuProfileContent>
              {isInstagram ? (
                <S.MenuButton onClick={openOnInstagram} eventName="click_feed_open_on_instagram">
                  {t('INVOLVMENT')}
                </S.MenuButton>
              ) : (
                <>
                  <S.MenuButton onClick={openPreview} eventName="click_preview_feed_web_button">
                    {t('SEE_PREVIEW')}
                  </S.MenuButton>
                  <S.MenuButton
                    onClick={openOnFacebook}
                    eventName="click_view_facebook_feed_web_button"
                  >
                    {t('VIEW_FACEBOOK')}
                  </S.MenuButton>
                </>
              )}
              {feedType === 'AD' && canEditAd && (
                <S.MenuButton onClick={handleOpenEdit} eventName="click_edit_ad_feed_web_button">
                  {t('EDIT')}
                </S.MenuButton>
              )}
            </S.MenuProfileContent>
          </S.MenuProfileRelative>
        </S.MenuProfileWrapper>
      )}
    </ClickOutHandler>
  );
}
