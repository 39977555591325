import useSWR from 'swr';
import { fetcher } from '@utils/api';
import { setAttributionWindows } from '@utils/helpers';
import { getSubDomain } from '../../auth';

function useGetMetrics(key) {
  const { data, error } = useSWR(`/settings/1.0/keyvalue/${key}`, fetcher, {
    revalidateOnFocus: false,
  });

  return {
    getMetrics: data || null,
    isLoading: !error && data === undefined,
    isError: error ? error.message : error,
  };
}

function useGetAllMetrics() {
  const subDomain = getSubDomain();
  const window = setAttributionWindows(subDomain);
  const { data, error } = useSWR(`/settings/1.0/keyvalue${window}`, fetcher, {
    revalidateOnFocus: false,
  });
  return {
    getAllMetrics: data || null,
    shouldLoading: !error && !data,
    isError: error ? error.message : error,
  };
}

export { useGetMetrics, useGetAllMetrics };
