import React, { useState, useEffect } from 'react';
import { useCampaignFormStep } from '@utils/contexts';
import { useTranslate } from 'react-translate';
import { LoadingOld } from '@components/LoadingOld/LoadingOld';
import Select from 'react-select';

import Input from '@components/Input/Input';
import { Preview } from './Preview';
import * as C from './styles';
import * as S from './styles';
import { postVideo } from '../services';
import useShowToast from '@utils/hooks/Common/useShowToast';
import uploadVideo from '@utils/videoUploader';

const FilesUpload = ({ setTradeFile, sasDetails }) => {
  const t = useTranslate('GoogleLogin');
  const t_media = useTranslate('Medias');

  const [newFiles, setNewFiles] = useState([]);
  const [blobUrls, setBlobUrls] = useState([]);
  const [hasFileUploading, setHasFileUploading] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [info, setInfo] = useState({ title: '', description: '' });
  const [isCompleted, setIsCompleted] = useState(false);
  const [agreeWithTerms, setAgreeWithTerms] = useState(true);
  const [postType, setPostType] = useState('privacy');
  const showToast = useShowToast();

  const { file, setFile, shouldShowRequiredFile, setShouldShowRequiredFile } =
    useCampaignFormStep();

  useEffect(() => {
    if (file && file.length > 0) {
      setIsPreview(true);
      setNewFiles(file[0].files);
      setBlobUrls(file[0].blobUrls);
    }
  }, [file]);

  useEffect(() => {
    if (!isPreview && !hasFileUploading && newFiles.length > 0) {
      upMedia();
    }
  }, [newFiles]);

  const handleWithTerms = () => {
    setAgreeWithTerms((oldValue) => !oldValue);
  };

  const handleChange = (files) => {
    const newFilesStructure = [];

    for (let i = 0; i < files.target.files.length; i++) {
      newFilesStructure.push({
        file: files.target.files[i],
        uniqueCode: new Date().getTime() + files.target.files[i].name,
        uploadPercentage: 0,
      });
    }
    setNewFiles([...newFilesStructure]);
  };
  const upMedia = async () => {
    const position = blobUrls.length;

    newFiles[position].uploadPercentage = 50;
    setHasFileUploading(true);

    const commitUploadSucessCallback = (_id, newProfileUrl) => {
      const profile_picture_url = newProfileUrl;
      const copy = blobUrls;
      const newFilesUpdate = newFiles;

      newFilesUpdate[position].uploadPercentage = 100;

      const item = { url: profile_picture_url, uniqueCode: newFilesUpdate[position].uniqueCode };
      copy.push(item);
      setHasFileUploading(false);

      if (!newFiles[0].file.type.includes('video')) {
        setBlobUrls([]);
        setNewFiles([]);
        return showToast('Formato inválido. Insira um arquivo de vídeo!', 'error');
      }

      setFile([{ blobUrls: copy, files: newFiles }]);
      setShouldShowRequiredFile(false);
      setBlobUrls(copy);
    };

    uploadVideo(
      newFiles[position].file,
      sasDetails.blob_sas_url,
      'youtube',
      commitUploadSucessCallback
    );
  };

  const hasImage = () => blobUrls.length > 0;
  const hasImg = blobUrls.length > 0;

  const customClass = () => {
    if (shouldShowRequiredFile) return '--required';
    if (hasImage()) return '-no-border';
    return '';
  };
  useEffect(() => {
    if (info.title.length && info.description.length && hasImg && agreeWithTerms) {
      setIsCompleted(true);
    } else {
      setIsCompleted(false);
    }
  }, [info, hasImg, agreeWithTerms]);

  const handleSubmit = async () => {
    if (!isCompleted) return;
    const body = {
      title: info.title,
      description: info.description,
      video_url: blobUrls[0].url,
      privacy_status: postType.value,
    };

    try {
      const response = await postVideo(body, sasDetails.token);
      if (response?.status === 200) {
        showToast('Video enviado com sucesso!', 'success');
      }
    } catch (e) {
      showToast('Houve algum erro, tente novamente!', 'error');
    }
  };

  return (
    <S.Container>
      <S.DetailsContainer className="headerinfo">
        <span className="info">
          <p>{t('title')}:</p>
          <input type="text" onChange={(e) => setInfo({ ...info, title: e.target.value })} />
        </span>
        <p>{t('video_privacy')}</p>
        <Select
          onChange={(value) => setPostType(value)}
          value={postType}
          options={['public', 'private', 'unlisted'].map((item) => ({
            label: t(item),
            value: item,
          }))}
        />
        <span className="info">
          <p>{t('description')}:</p>
          <textarea rows="3" onChange={(e) => setInfo({ ...info, description: e.target.value })} />
        </span>
      </S.DetailsContainer>
      <S.Wrapper width="384px" className={`image_container${customClass()}`}>
        <C.UploadMultipleFiles>
          {hasImage() && (
            <Preview
              newFiles={newFiles}
              blobUrls={blobUrls}
              setTradeFile={setTradeFile}
              setBlobUrls={setBlobUrls}
              setNewFiles={setNewFiles}
              t={t_media}
            />
          )}
          <div style={{ marginBottom: '2px' }}>
            {hasFileUploading && <LoadingOld isLoading={hasFileUploading} />}
          </div>

          {newFiles.length < 1 && (
            <S.Wrapper direction="column" className="image_container-content">
              <S.Wrapper align="center">{t('upload')}</S.Wrapper>
              <S.Wrapper align="left" className="text list" gap="0px">
                <S.Wrapper gap="0px"></S.Wrapper>
              </S.Wrapper>

              <C.LabelStyled htmlFor="multiple-files">{t('selectVideo')}</C.LabelStyled>

              <C.InputStyled>
                <C.ButtonWrapper>
                  <Input
                    type="file"
                    id="multiple-files"
                    label=""
                    accept="video/*"
                    onChange={handleChange}
                  />
                </C.ButtonWrapper>
              </C.InputStyled>
            </S.Wrapper>
          )}
        </C.UploadMultipleFiles>
      </S.Wrapper>
      <S.DetailsContainer className="submit" isCompleted={isCompleted}>
        <S.TermsContainer>
          <input type={'checkbox'} checked={agreeWithTerms} onChange={handleWithTerms} />{' '}
          {t('terms_and_conditions_part1')}
          <a href="https://www.youtube.com/t/terms" target="_blank" rel="noreferrer">
            {t('terms_and_conditions_part2')}
          </a>
        </S.TermsContainer>
        <button onClick={handleSubmit}>{t('send')}</button>
      </S.DetailsContainer>
    </S.Container>
  );
};

export default FilesUpload;
