// Types
import {
  GET_STEP,
  UPDATE_EMAIL,
  GET_PAGES,
  SET_PAGE,
  UPDATE_INVITE_LINK,
  GET_COMPANY_INFO,
} from './types';

const INITIAL_STATE = {
  pages: [],
  step: {
    name: null,
    company_name: null,
    email: null,
    current_step: null,
    has_shared_budget_ad_account: null,
    facebook_user_is_on_business_manager: null,
    facebook_business_manager_invitation_link: null,
    facebook_page_is_on_business_manager: null,
    facebook_page_invite_acceptance_link: null,
    isLoading: false,
    error: null,
    page_ID: '',
  },
  companyInfo: {},
};

const easyAccessReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_STEP.REQUEST:
      return {
        ...state,
        step: {
          isLoading: true,
        },
      };

    case GET_STEP.SUCCESS:
      return {
        ...state,
        step: {
          ...state.step,
          current_step: action.step,
          email: action.email,
          name: action.name,
          facebook_business_manager_invitation_link: action.invitationLink,
          facebook_encrypted_token: action.token,
          has_shared_budget_ad_account: action.sharedBudget,
          facebook_user_is_on_business_manager: action.userIsOnBusiness,
          facebook_page_is_on_business_manager: action.pageIsOnBusiness,
          facebook_page_invite_acceptance_link: action.pageLink,
          isLoading: false,
        },
      };

    case GET_STEP.FAILURE:
      return {
        ...state,
        step: {
          isLoading: false,
          error: action.stepError,
        },
      };
    case UPDATE_EMAIL.REQUEST:
      return {
        ...state,
        step: {
          isLoading: true,
        },
        token: action.token,
        body: action.body,
      };
    case UPDATE_EMAIL.SUCCESS:
      return {
        ...state,
        step: {
          ...state.step,
          email: action.email,
          current_step: action.step,
          isLoading: false,
        },
      };
    case UPDATE_EMAIL.FAILURE:
      return {
        ...state,
        step: {
          error: action.stepError,
          isLoading: false,
        },
      };

    case GET_PAGES.REQUEST:
      return {
        ...state,
        step: {
          isLoading: true,
        },
      };

    case GET_PAGES.SUCCESS:
      return {
        ...state,
        pages: action.pages,
        step: {
          ...state.step,
          isLoading: false,
        },
      };

    case GET_PAGES.FAILURE:
      return {
        ...state,
        step: {
          isLoading: false,
          error: action.stepError,
        },
      };

    case SET_PAGE.REQUEST:
      return {
        ...state,
        step: {
          isLoading: true,
        },
        token: action.token,
        body: action.body,
      };
    case SET_PAGE.SUCCESS:
      return {
        ...state,
        step: {
          ...state.step,
          current_step: action.step.current_step,
          facebook_business_manager_invitation_link:
            action.step.facebook_business_manager_invitation_link,
          facebook_page_invite_acceptance_link: action.step.facebook_page_invite_acceptance_link,
          facebook_user_is_on_business_manager: action.step.facebook_user_is_on_business_manager,
          facebook_page_is_on_business_manager: action.step.facebook_page_is_on_business_manager,
          isLoading: false,
        },
      };
    case SET_PAGE.FAILURE:
      return {
        ...state,
        step: {
          ...state.step,
          error: action.error,
          isLoading: false,
        },
      };
    case UPDATE_INVITE_LINK.REQUEST:
      return {
        ...state,
        step: {
          isLoading: true,
        },
        token: action.token,
      };

    case UPDATE_INVITE_LINK.SUCCESS:
      return {
        ...state,
        step: {
          ...state.step,
          facebook_business_manager_invitation_link: action.link,
          isLoading: false,
        },
      };

    case UPDATE_INVITE_LINK.FAILURE:
      return {
        ...state,
        step: {
          isLoading: false,
          error: action.error,
        },
      };
    case GET_COMPANY_INFO.REQUEST:
      return {
        ...state,
        companyInfo: {
          isLoading: true,
        },
      };

    case GET_COMPANY_INFO.SUCCESS:
      return {
        ...state,
        companyInfo: action.companyInfo,
      };

    case GET_COMPANY_INFO.FAILURE:
      return {
        ...state,
        companyInfo: {
          isLoading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export default easyAccessReducer;
