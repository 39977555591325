// Types
import {
  GET_UNITS,
  CHANGE_UNITS_STATUS_FILTER,
  CHANGE_UNITS_NAMES_FILTER,
  ADD_UNIT,
  GET_EA_GROUPS,
  GET_EA_USERS,
  UPDATE_UNITS_AFTER_RENAME,
} from './types';

const INITIAL_STATE = {
  units: {
    data: {
      data: [],
      paging: {},
    },
    isLoading: false,
    statusFilter: '',
    namesFilter: [],
    error: null,
  },
  easyAccess: {
    data: {
      data: [],
      paging: {},
    },
    isLoading: false,
    error: null,
    users: [],
  },
};

const settingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_UNITS.REQUEST:
      return {
        ...state,
        units: {
          ...state.units,
          isLoading: true,
          error: null,
        },
      };
    case GET_UNITS.SUCCESS:
      return {
        ...state,
        units: {
          ...state.units,
          data: {
            ...state.units.data,
            data: [...state.units.data.data, ...action.units.data],
            paging: {
              ...action.units.paging,
            },
          },
          isLoading: false,
          error: null,
        },
      };

    case GET_UNITS.FAILURE:
      return {
        ...state,
        units: {
          ...state.units,
          isLoading: false,
          error: action.unitsError,
        },
      };

    case GET_UNITS.RESET:
      return {
        ...state,
        units: {
          data: {
            data: [],
            paging: {},
          },
          isLoading: false,
          error: null,
        },
      };

    case UPDATE_UNITS_AFTER_RENAME:
      return {
        ...state,
        units: {
          ...state.units,
          data: {
            ...state.units.data,
            data: state.units.data.data.map((row) => {
              if (row.source_id === action.sourceId) {
                return {
                  ...row,
                  name: action.newName,
                };
              }
              return row;
            }),
          },
        },
      };

    case CHANGE_UNITS_STATUS_FILTER:
      return {
        ...state,
        units: {
          ...state.units,
          statusFilter: action.statusFilter,
        },
      };

    case CHANGE_UNITS_NAMES_FILTER:
      return {
        ...state,
        units: {
          ...state.units,
          namesFilter: action.namesFilter
        },
      };

    case ADD_UNIT.SUCCESS:
      return {
        ...state,
        units: {
          ...state.units,
          data: {
            ...state.units.data,
            data: [...state.units.data.data, action.payload],
          },
          isLoading: false,
          error: null,
        },
      };

    case GET_EA_GROUPS.REQUEST:
      return {
        ...state,
        easyAccess: {
          ...state.easyAccess,
          isLoading: true,
          error: null,
          users: [],
        },
      };

    case GET_EA_GROUPS.SUCCESS:
      return {
        ...state,
        easyAccess: {
          ...state.easyAccess,
          data: {
            ...state.easyAccess.data,
            data: [...state.easyAccess.data.data, ...action.easyAccess],
          },
          isLoading: false,
          error: null,
          users: [],
        },
      };

    case GET_EA_GROUPS.FAILURE:
      return {
        ...state,
        easyAccess: {
          data: {
            data: [],
            paging: {},
          },
          isLoading: false,
          error: action.easyAccessError,
          users: [],
        },
      };

    case GET_EA_GROUPS.RESET:
      return {
        ...state,
        easyAccess: {
          data: {
            data: [],
            paging: {},
          },
          isLoading: false,
          error: null,
          users: [],
        },
      };

    case GET_EA_USERS.REQUEST:
      return {
        ...state,
        easyAccess: {
          ...state.easyAccess,
          isLoading: true,
          error: null,
          users: [],
        },
      };

    case GET_EA_USERS.SUCCESS:
      return {
        ...state,
        easyAccess: {
          ...state.easyAccess,
          data: {
            ...state.easyAccess.data,
            data: [...state.easyAccess.data, ...action.easyAccess],
            paging: action.easyAccess.paging,
          },
          isLoading: false,
          error: null,
          users: {
            ...state.easyAccess.users,
            data: [...state.easyAccess.data, ...action.easyAccess.users],
            paging: action.easyAccess.paging,
          },
        },
      };

    case GET_EA_USERS.FAILURE:
      return {
        ...state,
        easyAccess: {
          data: {
            data: [],
            paging: {},
          },
          isLoading: false,
          error: action.easyAccessError,
          users: [],
        },
      };

    case GET_EA_USERS.RESET:
      return {
        ...state,
        easyAccess: {
          data: {
            data: [],
            paging: {},
          },
          isLoading: false,
          error: null,
          users: [],
        },
      };

    default:
      return state;
  }
};

export default settingsReducer;
