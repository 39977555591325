/* Create action types */
import createActionTypes from '../createActionTypes';

export const FETCH_DOMAIN = createActionTypes('FETCH_DOMAIN');
export const EMAIL_IS_VALID = createActionTypes('EMAIL_IS_VALID');
export const AUTHENTICATE_LOGIN = createActionTypes('AUTHENTICATE_LOGIN');
export const FETCH_LIST_COMPANIES = createActionTypes('FETCH_LIST_COMPANIES');
export const AUTHENTICATE_BUSINESS = createActionTypes('AUTHENTICATE_BUSINESS');
export const SAVE_TOKENS = createActionTypes('SAVE_TOKENS');
export const FETCH_USER_ME = createActionTypes('FETCH_USER_ME');
export const RECOVERY_PASSWORD = createActionTypes('RECOVERY_PASSWORD');
export const RESET_PASSWORD = createActionTypes('RESET_PASSWORD');
export const USER_DATA_DECODED = createActionTypes('USER_DATA_DECODED');
export const BUSINESS_DATA_DECODED = createActionTypes('BUSINESS_DATA_DECODED');
export const FORGOT_PASSWORD = createActionTypes('FORGOT_PASSWORD');
export const GET_USERS = createActionTypes('GET_USERS');
export const GET_SESSIONS = createActionTypes('GET_SESSIONS');
export const INVALIDATE_SESSION = createActionTypes('INVALIDATE_SESSION');
export const LOGOUT = createActionTypes('LOGOUT');
export const GET_CURRENT_USER = createActionTypes('GET_CURRENT_USER');
export const GET_CAPABILITY_CATEGORY = createActionTypes('GET_CAPABILITY_CATEGORY');
