import React from 'react';
import Marks from '../Marks';
import * as S from './styles';

export default function Header({
  media,
  isInstagram,
  markingCapability,
  isAd,
  setMarkedMedias,
  markedMedias,
}) {
  return (
    <S.Wrapper>
      <S.Title>{isInstagram ? media.page_name : media.fan_page && media.fan_page.name}</S.Title>
      {markingCapability && (
        <Marks
          media={media}
          isAd={isAd}
          setMarkedMedias={setMarkedMedias}
          markedMedias={markedMedias}
        />
      )}
    </S.Wrapper>
  );
}
