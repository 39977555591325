// Reducer utilizado para listar a quantidade de bolinhas deve exibir no menu
import {
  GET_ALL_ACCOUNTS,
  GET_ALL_FANPAGES,
  GET_RECEIVED,
  SEND_ANSWER,
  EDIT_DESCRIPTION_MEDIA,
  DISAPPROVE_MEDIA,
  APPROVE_MEDIA,
  SHOW_MODAL_APPROVE_OR_DISAPPROVE,
  APPROVE_MEDIA_MULT_IMG,
  DISAPPROVE_MEDIA_MULT_IMG,
  UPDATE_MEDIA_MULT_IMG,
} from './types';

const INITIAL_STATE = {
  received: {
    data: [
      {
        id: '276142325016792',
        source_id: '2376711322388122',
        name: 'Decathlon Brasil (Loja Jundiaí)',
        thumbnail_url:
          'https://bgdk8slrs.blob.core.windows.net/fb-extracted-media/uploaded-637056184987064659-11f6672c-2933-4a7f-9e81-52e0148efc35.jpg',
        media_url:
          'https://bgdk8slrs.blob.core.windows.net/fb-extracted-media/uploaded-637056184987064659-11f6672c-2933-4a7f-9e81-52e0148efc35.jpg',
        media_type: 'VIDEO',
        description: 'IG Abertura Jundiaí - vídeo inspiracional da cidade',
        link_url: 'https://www.facebook.com/decathlonjundiai/videos/3546174325396574/',
        video_url:
          'https://bgdk8slrs.blob.core.windows.net/fb-extracted-media/uploaded-637056184997284176-0728e68b-67d3-4e28-8b95-b4381c642b20.mp4',
        showImageBg: true,
        onClickBgImage: 'showMedia',
        imageBottomRounded: true,
        creative_type: 'VIDEO',
        rate: 'inspiration',
        reach: '12',
        feedType: 'POST',
        comments: [
          {
            user: 'Adriana Souza',
            image: 'https://bgdk8slrs.blob.core.windows.net/bornlogic/15693517224786u1IG.jpg',
            time: new Date(),
            comment:
              'Esse é um comentário extenso. Esse é um comentário extenso. Esse é um comentário extenso. Esse é um comentário extenso. Esse é um comentário extenso. ',
          },
          {
            user: 'João das Couves',
            image: 'https://bgdk8slrs.blob.core.windows.net/bornlogic/15693517224786u1IG.jpg',
            time: new Date(),
            comment:
              'Esse é um comentário extenso. Esse é um comentário extenso. Esse é um comentário extenso. Esse é um comentário extenso. Esse é um comentário extenso. ',
          },
          {
            user: 'Adriana Souza',
            image: 'https://bgdk8slrs.blob.core.windows.net/bornlogic/15693517224786u1IG.jpg',
            time: new Date(),
            comment:
              'Esse é um comentário extenso. Esse é um comentário extenso. Esse é um comentário extenso. Esse é um comentário extenso. Esse é um comentário extenso. ',
          },
          {
            user: 'João das Couves',
            image: 'https://bgdk8slrs.blob.core.windows.net/bornlogic/15693517224786u1IG.jpg',
            time: new Date(),
            comment:
              'Esse é um comentário extenso. Esse é um comentário extenso. Esse é um comentário extenso. Esse é um comentário extenso. Esse é um comentário extenso. ',
          },
          {
            user: 'Adriana Souza',
            image: 'https://bgdk8slrs.blob.core.windows.net/bornlogic/15693517224786u1IG.jpg',
            time: new Date(),
            comment:
              'Esse é um comentário extenso. Esse é um comentário extenso. Esse é um comentário extenso. Esse é um comentário extenso. Esse é um comentário extenso. ',
          },
          {
            user: 'João das Couves',
            image: 'https://bgdk8slrs.blob.core.windows.net/bornlogic/15693517224786u1IG.jpg',
            time: new Date(),
            comment:
              'Esse é um comentário extenso. Esse é um comentário extenso. Esse é um comentário extenso. Esse é um comentário extenso. Esse é um comentário extenso. ',
          },
        ],
      },
      {
        id: '23843863405800102',
        source_id: '2376711322388122',
        name: 'Decathlon Brasil (Loja Jundiaí)',
        thumbnail_url:
          'https://bgdk8slrs.blob.core.windows.net/fb-extracted-media/uploaded-637056185001399476-552f2885-0569-4be0-b818-f71c5548b44d.jpg',
        media_url:
          'https://bgdk8slrs.blob.core.windows.net/fb-extracted-media/uploaded-637056185001399476-552f2885-0569-4be0-b818-f71c5548b44d.jpg',
        media_type: 'VIDEO',
        description: 'Instagram Post',
        link_url: 'https://www.facebook.com/decathlonjundiai/videos/384246312269777/',
        video_url:
          'https://bgdk8slrs.blob.core.windows.net/fb-extracted-media/uploaded-637056185008163083-b8730548-f33b-48d1-96f2-600cecba58e4.mp4',
        showImageBg: true,
        onClickBgImage: 'showMedia',
        imageBottomRounded: true,
        creative_type: 'VIDEO',
        rate: 'bad',
        reach: '123',
        feedType: 'AD',
      },
      {
        id: '23843931847940626',
        source_id: '1510025045742125',
        name: 'Decathlon Brasil (Loja Villa Lobos)',
        thumbnail_url:
          'https://bgdk8slrs.blob.core.windows.net/fb-extracted-media/uploaded-637056078834398438-cbcdd971-c7c6-4168-a044-e4d985ce8d46.png',
        media_url:
          'https://bgdk8slrs.blob.core.windows.net/fb-extracted-media/uploaded-637056078834398438-cbcdd971-c7c6-4168-a044-e4d985ce8d46.png',
        media_type: 'PICTURE_CAROUSEL',
        description: 'Publicação do Instagram: Está preparado(a) para o...',
        link_url: 'https://instagram.com/decathlonvillalobos',
        video_url: false,
        showImageBg: true,
        imageBottomRounded: true,
        creative_type: 'IMAGE',
        rate: 'good',
        reach: '42312',
        feedType: 'AD',
      },
    ],
    isLoading: false,
  },
  accounts: {
    data: [],
    isLoading: false,
  },
  fanpages: {
    data: [],
    isLoading: false,
    error: null,
  },
  medias: {
    data: {},
    isLoading: false,
    error: null,
  },
  editDescriptionMedia: {
    status: null,
    isLoading: false,
    mediaId: null,
    error: null,
  },
  approved: {
    response: null,
  },
  disapproved: {
    response: null,
  },
  showModalApproveOrDisapprove: [],
};

const actionsReducer = (state = INITIAL_STATE, action) => {
  const newReceivedData = state.received.data;

  switch (action.type) {
    case GET_ALL_ACCOUNTS.REQUEST:
      return {
        ...state,
        accounts: {
          data: [],
          isLoading: true,
        },
      };

    case GET_ALL_ACCOUNTS.SUCCESS:
      return {
        ...state,
        accounts: {
          data: action.data,
          isLoading: false,
        },
      };

    case GET_ALL_FANPAGES.REQUEST:
      return {
        ...state,
        fanpages: {
          data: [],
          isLoading: true,
        },
      };

    case GET_ALL_FANPAGES.SUCCESS:
      return {
        ...state,
        fanpages: {
          data: action.data,
          isLoading: false,
        },
      };

    case GET_RECEIVED.REQUEST:
      return {
        ...state,
        received: {
          data: [],
          isLoading: true,
        },
      };

    case GET_RECEIVED.SUCCESS:
      return {
        ...state,
        received: {
          data: action.data,
          isLoading: false,
        },
      };

    case SEND_ANSWER.REQUEST:
      return {
        ...state,
        received: {
          data: [...newReceivedData],
          isLoading: true,
        },
      };

    case SEND_ANSWER.SUCCESS:
      newReceivedData
        .find((item) => item.id === action.mediaId)
        .comments.push({
          user: action.user.user_name,
          image: action.user.avatar_url,
          time: new Date(),
          comment: action.answer,
        });

      return {
        ...state,
        received: {
          data: [...newReceivedData],
        },
      };

    case EDIT_DESCRIPTION_MEDIA.REQUEST:
      return {
        ...state,
        editDescriptionMedia: {
          ...state.editDescriptionMedia,
          status: null,
          isLoading: true,
          mediaId: null,
        },
      };

    case EDIT_DESCRIPTION_MEDIA.SUCCESS:
      // let mediaData = state.medias.data.data.filter(x => x.id === action.mediaId)[0];
      // mediaData.description = action.newDescription;

      return {
        ...state,
        editDescriptionMedia: {
          ...state.editDescriptionMedia,
          status: action.status,
          isLoading: false,
          mediaId: action.mediaId,
        },
      };

    case EDIT_DESCRIPTION_MEDIA.FAILURE:
      return {
        ...state,
        editDescriptionMedia: {
          ...state.editDescriptionMedia,
          status: action.status,
          isLoading: false,
          mediaId: action.mediaId,
          error: action.error,
        },
      };

    case DISAPPROVE_MEDIA.REQUEST:
      return {
        ...state,
        disapproved: {
          ...state.disapproved,
          response: null,
        },
      };

    case DISAPPROVE_MEDIA.SUCCESS:
      return {
        ...state,
        medias: {
          data: {},
          isLoading: false,
          error: null,
        },
        disapproved: {
          ...state.disapproved,
          response: action.response,
        },
      };

    case DISAPPROVE_MEDIA.FAILURE:
      return {
        ...state,
        medias: {
          data: {},
          isLoading: false,
          error: null,
        },
        disapproved: {
          ...state.disapproved,
          response: action.response,
        },
      };

    case DISAPPROVE_MEDIA_MULT_IMG.REQUEST:
      return {
        ...state,
        disapproved: {
          ...state.disapproved,
          response: null,
        },
      };

    case DISAPPROVE_MEDIA_MULT_IMG.SUCCESS:
      return {
        ...state,
        medias: {
          data: {},
          isLoading: false,
          error: null,
        },
        disapproved: {
          ...state.disapproved,
          response: action.response,
        },
      };

    case DISAPPROVE_MEDIA_MULT_IMG.FAILURE:
      return {
        ...state,
        medias: {
          data: {},
          isLoading: false,
          error: null,
        },
        disapproved: {
          ...state.disapproved,
          response: action.response,
        },
      };

    case APPROVE_MEDIA.REQUEST:
      return {
        ...state,
        approved: {
          ...state.approved,
          response: null,
        },
      };

    case APPROVE_MEDIA.SUCCESS:
      return {
        ...state,
        medias: {
          data: {},
          isLoading: false,
          error: null,
        },
        approved: {
          ...state.approved,
          response: action.response,
        },
      };

    case APPROVE_MEDIA.FAILURE:
      return {
        ...state,
        medias: {
          data: {},
          isLoading: false,
          error: null,
        },
        approved: {
          ...state.approved,
          response: action.response,
        },
      };

    case APPROVE_MEDIA_MULT_IMG.REQUEST:
      return {
        ...state,
        approved: {
          ...state.approved,
          response: null,
        },
      };

    case APPROVE_MEDIA_MULT_IMG.SUCCESS:
      return {
        ...state,
        medias: {
          data: {},
          isLoading: false,
          error: null,
        },
        approved: {
          ...state.approved,
          response: action.response,
        },
      };

    case APPROVE_MEDIA_MULT_IMG.FAILURE:
      return {
        ...state,
        medias: {
          data: {},
          isLoading: false,
          error: null,
        },
        approved: {
          ...state.approved,
          response: action.response,
        },
      };

    case UPDATE_MEDIA_MULT_IMG.REQUEST:
      return {
        ...state,
      };

    case UPDATE_MEDIA_MULT_IMG.SUCCESS:
      return {
        ...state,
      };

    case UPDATE_MEDIA_MULT_IMG.FAILURE:
      return {
        ...state,
      };

    case SHOW_MODAL_APPROVE_OR_DISAPPROVE.REQUEST:
      return {
        ...state,
        showModalApproveOrDisapprove: action.modal,
      };

    default:
      return state;
  }
};

export default actionsReducer;
