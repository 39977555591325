// Modules
import React from 'react';
import styled from 'styled-components';

// Components
import Tooltip from '@components/Tooltip/Tooltip';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DownloadButton from 'components/DownloadButton';

const Wrapper = styled('div')`
  position: absolute;
  width: 270px;
  display: flex;
  bottom: 0;
  padding: 12px 16px;
  padding-bottom: 0;
  justify-content: space-between;
  align-items: flex-end;
`;

const SeeMore = styled('span')`
  cursor: pointer;
  color: #0087c8;
`;

const IconType = styled('div')`
  ${({ theme }) => `
        padding-top:2px;
        display: flex;
        flex-direction: column;
        z-index: 1;
        svg {
            font-size: ${theme.fontSize.px18};
            color: ${theme.colors.white};
            margin: ${theme.spacing.px3} 0;
        }
    `}
`;

const Status = styled('div')`
  background-color: #f8f8f8;
  color: #304e5c;
  border-radius: 6px;
  padding: 5px 8px;
  text-transform: capitalize;
  height: fit-content;

  display: flex;
  align-items: center;
  svg {
    margin-right: 3px;
    font-size: 10px;
  }

  p {
    font-size: 10px;
  }
  .red {
    color: #b51d42;
  }
  .green {
    color: #00967d;
  }
  .yellow {
    color: #f1a539;
  }
  .blue {
    color: #2dbff3;
  }
  .gray {
    color: #d2d5e8;
  }
  .orange {
    color: #f37238;
  }
`;

const StatusWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  > div:nth-child(1),
  #tooltip {
    visibility: hidden;
    opacity: 0;
    margin-bottom: -10px;
    transition: all 0.2s;
    p {
      font-size: 10px;
    }
    :hover {
      visibility: visible;
      opacity: 1;
    }
  }
  > div:nth-child(1) {
    :hover {
      visibility: visible;
      opacity: 1;
    }
  }
  #tooltip {
    :hover {
      visibility: visible;
      opacity: 1;
    }
  }
  &:hover {
    > div:nth-child(1),
    #tooltip {
      visibility: visible;
      opacity: 1;
    }
    #tooltip {
      margin-bottom: 10px;
    }
  }
`;

const TooltipWrapper = styled.div`
  max-width: 250px;
  position: absolute;
  bottom: 12px;
  right: 15px;
  padding-bottom: 39px;
  z-index: 9999;
  .arrow:before {
    ${(props) => props.larger && 'margin-left: 86px;'}
    ${(props) => props.smaller && 'margin-left: 18px;'}
            ${(props) => props.medium && 'margin-left: 60px;'}
  }
  .arrow:after {
    ${(props) => props.larger && 'margin-left: 88px;'}
    ${(props) => props.smaller && 'margin-left: 20px;'}
            ${(props) => props.medium && 'margin-left: 62px;'}
  }
  p {
    text-align: left;
  }
`;

function handleColor(status) {
  switch (status) {
    case 'paused':
      return 'orange';
    case 'active':
      return 'green';
    case 'pending_review':
      return 'yellow';
    case 'scheduled':
      return 'yellow';
    case 'finished':
      return 'blue';
    case 'rejected':
      return 'red';
    case 'deleted':
      return 'gray';
    default:
      return '';
  }
}

function openGuidelines() {
  window.open('https://www.facebook.com/policies/ads/ ', '_blank');
}

const Body = ({ mediaType, status, t, channelType, videoUrl }) => (
  <Wrapper>
    <IconType>
      <FontAwesomeIcon
        icon={channelType === 'INSTAGRAM' ? ['fab', 'instagram'] : ['fab', 'facebook-square']}
      />
      <FontAwesomeIcon
        icon={
          mediaType === 'VIDEO'
            ? ['fas', 'video']
            : mediaType === 'ALBUM'
            ? ['fas', 'th-large']
            : ['fas', 'camera']
        }
      />
    </IconType>
    {mediaType === 'VIDEO' && videoUrl && (
      <IconType>
        <DownloadButton donwloadUrl={videoUrl} />
      </IconType>
    )}

    {status && (
      <StatusWrapper>
        <TooltipWrapper
          smaller={
            status === 'deleted' ||
            status === 'paused' ||
            status === 'finished' ||
            status === 'unknow'
          }
          medium={status === 'finished' || status === 'active'}
          larger={status === 'pending_review' || 'scheduled'}
        >
          <Tooltip orientation="bottom" padding="7px" border>
            <p>
              {t(`${status}_tooltip`)}{' '}
              {status === 'rejected' && (
                <SeeMore onClick={() => openGuidelines()}> {t('SEE_MORE')}</SeeMore>
              )}{' '}
            </p>
          </Tooltip>
        </TooltipWrapper>
        <Status>
          <FontAwesomeIcon icon={['fas', 'circle']} className={handleColor(status)} />
          <p>{t(status)}</p>
        </Status>
      </StatusWrapper>
    )}
  </Wrapper>
);

export default Body;
