import React from 'react';
import { VideoPlay } from '@components/VideoPlay';
import * as C from './styles';
import * as S from './styles';

export const Preview = ({
  newFiles,
  blobUrls,
  setBlobUrls,
  setNewFiles,
  type = 'form',
  setTradeFile,
  t,
}) => {
  const handleRemove = () => {
    setBlobUrls([]);
    setNewFiles([]);
    if (setTradeFile) {
      setTradeFile([]);
    }
  };

  return newFiles.length && newFiles[0].file.type.includes('image') ? (
    <>
      <C.ZoomMedia src={blobUrls[0].url} />
      <S.CancelButton
        onClick={() => {
          handleRemove();
        }}
      >
        {type === 'form' && 'Remover Imagem'}
      </S.CancelButton>
    </>
  ) : (
    <>
      <C.Thumbnail>
        <VideoPlay
          media={newFiles.length && newFiles[0].file}
          video={blobUrls.length && blobUrls[0].url}
          t={t}
          noClose
          noDownload
        />
      </C.Thumbnail>
      <S.CancelButton
        onClick={() => {
          handleRemove();
        }}
      >
        {type === 'form' && 'Remover vídeo'}
      </S.CancelButton>
    </>
  );
};
