// Types
import {
  SET_DATE_RANGE,
  FETCH_AUTO_COMPLETE_GROUP,
  GET_CURRENT,
  GET_CURRENT_ITEM,
  GET_CODE_INFO,
  GET_CODE_INFORMATION,
  GET_GROUPS_PERMITTED_ACCESS,
  CHANGE_GROUP_TOTAL_CURRENT,
  CHANGE_MONTLY_GOAL,
  LISTING_PAGE_COUNT,
  RECOUNT_LISTING_GROUP_TOTAL,
  SET_EXHIBITION_MODE,
  SET_FEED_CHANNEL,
} from './types';

const BREADCRUMB_INITIAL_DATA = {
  data: {
    currentCode: '',
  },
  isLoading: false,
  status: null,
};

const INITIAL_STATE = {
  period: {
    datePreset: '',
    end: '',
    params: '',
    start: '',
  },
  autoCompleteGroup: {
    data: [],
    typed: '',
    isLoading: false,
    error: null,
  },
  current: {
    data: {},
    isLoading: false,
    error: null,
    status: null,
  },
  currentItem: {
    data: {},
    isLoading: false,
    error: null,
    status: null,
  },
  codeInfo: {
    data: [],
    isLoading: false,
    error: null,
  },
  breadCrumb: BREADCRUMB_INITIAL_DATA,
  groupsPermitted: {
    data: [],
    isLoading: false,
    error: null,
  },
  listingPageCount: {
    data: {
      groupTotalCount: 0,
      adAccountTotalCount: 0,
      contentTotalCount: 0,
      strategyTotalCount: 0,
    },
    isLoading: false,
  },
  exhibitionMode: 'list', // list || card
  feedChannel: 'facebook', // facebook || instagram
};

const genericReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DATE_RANGE.REQUEST:
      return {
        ...state,
        period: {
          datePreset: '',
          end: '',
          params: !!state.period.params ? state.period.params : '',
          start: '',
          isLoading: true,
        },
      };

    case SET_DATE_RANGE.SUCCESS:
      return {
        ...state,
        period: {
          ...action.preset,
          isLoading: false,
        },
      };

    case FETCH_AUTO_COMPLETE_GROUP.REQUEST:
      return {
        ...state,
        autoCompleteGroup: {
          ...state.autoCompleteGroup,
          isLoading: true,
        },
      };

    case FETCH_AUTO_COMPLETE_GROUP.SUCCESS:
      return {
        ...state,
        autoCompleteGroup: {
          ...state.autoCompleteGroup,
          data: action.autoCompleteGroup,
          typed: action.typed,
          isLoading: false,
        },
      };

    case FETCH_AUTO_COMPLETE_GROUP.FAILURE:
      return {
        ...state,
        autoCompleteGroup: {
          ...state.autoCompleteGroup,
          isLoading: false,
          error: action.autoCompleteGroupError,
        },
      };

    case FETCH_AUTO_COMPLETE_GROUP.RESET:
      return {
        ...state,
        autoCompleteGroup: { ...INITIAL_STATE.autoCompleteGroup },
      };

    case GET_CURRENT.REQUEST:
      return {
        ...state,
        current: {
          ...state.current,
          isLoading: true,
          status: null,
        },
      };

    case GET_CURRENT.SUCCESS:
      return {
        ...state,
        current: {
          data: action.current,
          isLoading: false,
          error: null,
          status: action.status,
        },
      };

    case GET_CURRENT.FAILURE:
      return {
        ...state,
        current: {
          data: {},
          isLoading: false,
          error: action.currentError,
          status: action.status,
        },
      };

    case GET_CURRENT.RESET:
      return {
        ...state,
        current: INITIAL_STATE.current,
      };

    case GET_CURRENT_ITEM.REQUEST:
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          isLoading: true,
          status: null,
        },
      };

    case GET_CURRENT_ITEM.SUCCESS:
      return {
        ...state,
        currentItem: {
          data: action.currentItem,
          isLoading: false,
          error: null,
          status: action.status,
        },
      };

    case GET_CURRENT_ITEM.FAILURE:
      return {
        ...state,
        currentItem: {
          data: {},
          isLoading: false,
          error: action.currentItemError,
          status: action.status,
        },
      };

    case GET_CURRENT_ITEM.RESET:
      return {
        ...state,
        currentItem: INITIAL_STATE.currentItem,
      };

    case GET_CODE_INFO.REQUEST:
      return {
        ...state,
        codeInfo: {
          data: [],
          isLoading: true,
          error: null,
        },
      };

    case GET_CODE_INFO.SUCCESS:
      return {
        ...state,
        codeInfo: {
          data: action.code,
          isLoading: false,
          error: null,
        },
      };

    case GET_CODE_INFO.FAILURE:
      return {
        ...state,
        codeInfo: {
          data: [],
          isLoading: false,
          error: action.status,
        },
      };

    case GET_CODE_INFORMATION.REQUEST:
      return {
        ...state,
        breadCrumb: {
          ...state.breadCrumb,
          isLoading: true,
        },
        current: {
          ...state.current,
          isLoading: true,
        },
      };

    case GET_CODE_INFORMATION.SUCCESS:
      return {
        ...state,
        breadCrumb: {
          ...state.breadCrumb,
          data: { ...state.breadCrumb.data, currentCode: action.currentCode },
          isLoading: false,
        },
        current: {
          ...state.current,
          data: {
            ...state.current.data,
            budget: {
              total: action.currentCode.budget ? action.currentCode.budget.total : 0,
            },
          },
          isLoading: false,
        },
      };

    case GET_CODE_INFORMATION.FAILURE:
      return {
        ...state,
        breadCrumb: {
          ...state.breadCrumb,
          data: {},
          isLoading: false,
        },
        current: {
          ...state.current,
          isLoading: false,
        },
      };

    case GET_CODE_INFORMATION.RESET:
      return {
        ...state,
        breadCrumb: BREADCRUMB_INITIAL_DATA,
      };

    case GET_GROUPS_PERMITTED_ACCESS.REQUEST:
      return {
        ...state,
        groupsPermitted: {
          data: [],
          isLoading: true,
          error: null,
        },
      };
    case GET_GROUPS_PERMITTED_ACCESS.SUCCESS:
      return {
        ...state,
        groupsPermitted: {
          data: action.groupsPermitted,
          isLoading: false,
          error: null,
        },
      };
    case GET_GROUPS_PERMITTED_ACCESS.FAILURE:
      return {
        ...state,
        groupsPermitted: {
          data: [],
          isLoading: false,
          error: action.groupsPermittedError,
        },
      };

    case CHANGE_GROUP_TOTAL_CURRENT.SUCCESS:
      return {
        ...state,
        current: {
          ...state.current,
          data: {
            budget: {
              total: action.total,
            },
          },
        },
      };

    case CHANGE_MONTLY_GOAL.REQUEST:
      return {
        ...state,
      };

    case CHANGE_MONTLY_GOAL.SUCCESS:
      return {
        ...state,
        current: {
          ...state.current,
          data: {
            ...state.current.data,
            total: action.newValue,
          },
        },
      };

    case LISTING_PAGE_COUNT.REQUEST:
      return {
        ...state,
        listingPageCount: {
          ...INITIAL_STATE.listingPageCount,
          isLoading: true,
        },
      };

    case LISTING_PAGE_COUNT.SUCCESS:
      return {
        ...state,
        listingPageCount: {
          isLoading: false,
          data: action.data,
        },
      };
    case LISTING_PAGE_COUNT.FAILURE:
      return {
        ...state,
        listingPageCount: {
          ...INITIAL_STATE.listingPageCount,
        },
      };

    case RECOUNT_LISTING_GROUP_TOTAL.REQUEST:
      let newTotal = state.listingPageCount.data.groupTotalCount;
      if (action.sum) {
        newTotal++;
      } else {
        newTotal--;
      }
      return {
        ...state,
        listingPageCount: {
          ...state.listingPageCount,
          data: {
            ...state.listingPageCount.data,
            groupTotalCount: newTotal,
          },
        },
      };

    case SET_EXHIBITION_MODE.REQUEST:
      return {
        ...state,
        exhibitionMode: action.mode,
      };

    case SET_FEED_CHANNEL.REQUEST:
      return {
        ...state,
        feedChannel: action.feedChannel,
      };

    default:
      return state;
  }
};

export default genericReducer;
