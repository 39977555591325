import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate } from 'react-translate';
import { ModalDefault, Select, Text, Title } from '@bornlogic/gd-design-system';
import RuleService from '../../../../services/rule';
import SchemaService from '../../../../services/schema';
import { CHANGE_TARGETING } from '../../../../redux/settings/types';
import * as S from './styles';

const normalizeTargetingOptions = (options) =>
  options.map((option) => ({
    value: option.id,
    title: option.name,
  }));

const ChangeTargeting = ({ isOpen, onClose, unitAccountId, unitId }) => {
  const [audiences, setAudiences] = useState([]);
  const [selectedAudienceId, setSelectedAudienceId] = useState('');
  const [targeting, setTargeting] = useState({});

  const t = useTranslate('Units');
  const dispatch = useDispatch();

  useEffect(() => {
    if (unitAccountId && unitId) {
      RuleService.getTargetingRestriction('1.0', unitAccountId).then((targeting) => {
        setTargeting(targeting.data);

        SchemaService.getSavedAudiences('1.1', unitId).then((audience) => {
          setAudiences(normalizeTargetingOptions(audience.data));
        });
      });
    }
  }, [unitAccountId, unitId]);

  const handleAudienceSelection = (selectedOption) => {
    setSelectedAudienceId(selectedOption.id);
  };

  const handleChangeTargeting = () => {
    const body = JSON.parse(JSON.stringify(targeting));
    body.specs_to_force.saved_target_id = selectedAudienceId;

    dispatch({
      type: CHANGE_TARGETING.REQUEST,
      version: '1.0',
      payload: body,
      adAccountId: unitAccountId,
      feedbackMessage: {
        error: t('CHANGE_TARGETING_FEEDBACK_ERROR'),
        success: t('CHANGE_TARGETING_FEEDBACK_SUCCESS'),
      },
      onSuccess: onClose,
    });
  };

  return (
    <ModalDefault
      isOpen={isOpen}
      title={t('EDIT_AUDIENCE')}
      confirmBtnText={t('CHANGE')}
      onConfirmBtnClick={handleChangeTargeting}
      confirmBtnDisabled={selectedAudienceId === ''}
      optionBtnText={t('CANCEL')}
      onOptionBtnClick={onClose}
      onClose={onClose}
      size="40%"
      maxHeight="55%"
    >
      <S.Container>
        <Text>{t('CHANGE_AUDIENCE_SUBTITLE')}</Text>
        <S.InputWrapper>
          <S.LabelSpace>
            <Title as="h6" weight="normal">
              {t('AVAILABLE_AUDIENCE')}
            </Title>
          </S.LabelSpace>
          <Select
            placeholder={t('SELECT_AUDIENCE')}
            items={audiences}
            defaultValue=""
            onChange={handleAudienceSelection}
          />
        </S.InputWrapper>
      </S.Container>
    </ModalDefault>
  );
};

export default ChangeTargeting;
