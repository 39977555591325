import styled, { css } from 'styled-components';

export const InputWrapper = styled.div`
  ${({ theme, color, moreThanOne, focusIn, errors }) => css`
    position: relative;
    background: ${theme.colors.white};
    &::after {
      content: '';
      display: flex;
      justify-content: center;
      width: ${theme.spacing.full};
      height: 1px;
      background: ${theme.colors.grey2};
    }
    &::before {
      content: '';
      display: block;
      margin: 0 auto;
      width: ${theme.spacing.none};
      height: 1px;
      border-bottom: 1px solid transparent;
      transition: all 0.5s;
      ${!!errors || !!moreThanOne || !!focusIn
        ? css`
            width: ${theme.spacing.full};
            height: 1px;
            background: ${theme.colors[!errors ? color : 'red3']};
            position: relative;
            bottom: -39px;
          `
        : css`
            position: relative;
            bottom: -39px;
            height: 1px;
            background: ${theme.colors.grey2};
          `}
    }
  `}
`;

export const LabelStyled = styled.label`
  ${({ theme }) => css`
    width: ${theme.spacing.full};
    color: ${theme.colors.blueGrey2};
    font-size: ${theme.fontSize.px16};
    bottom: 12px;
    position: absolute;
    left: 0;
    z-index: 0;
    transition: all 0.2s;
  `}
`;

export const InputStyled = styled.input`
  ${({ theme, moreThanOne, focusIn, errors, right }) => css`
    width: ${theme.spacing.full};
    padding: ${theme.spacing.px10} ${theme.spacing.none} ${theme.spacing.px5};
    color: ${theme.colors.blueGrey2};

    font-size: ${theme.fontSize.px16};
    background: ${theme.colors.none};
    margin-bottom: ${theme.spacing.px3};
    border: none;
    transition: all 0.2s;
    outline: none;
    position: relative;
    z-index: 1;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-transition: 'color 99999999s ease-out, background-color 99999999s ease-out';
      -webkit-transition-delay: 99999999s;
    }
    ${!!errors || !!moreThanOne || !!focusIn
      ? css`
            & + label {
                    font-size: ${theme.fontSize.px12};
                    bottom: ${theme.spacing.px35};
                }
            }
            `
      : css`
            & + label {
                    font-size: ${theme.fontSize.px16};
                    bottom: 12px;
                }
            }
            `}
    text-align: ${right ? 'right' : 'left'};
    &:focus,
    &:focus-within,
    &:active,
    &:visited {
      & + label {
        font-size: ${theme.fontSize.px12};
        bottom: ${theme.spacing.px35};
      }
    }
  `}
`;

export const MessageValidation = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.px10};
    color: ${theme.colors.red3};
    margin-top: ${theme.spacing.px3};
  `}
`;

export const IconEye = styled.button`
  ${({ theme }) => css`
    padding: ${theme.spacing.none};
    border-radius: ${theme.rounded.full};
    width: ${theme.spacing.px30};
    height: ${theme.spacing.px30};
    border: none;
    background: none;
    position: absolute;
    right: 0;
    bottom: 1px;
    cursor: pointer;
    outline: none;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: ${theme.colors.blue5};
    }
  `}
`;
