/* Modules */
import { call, put, takeEvery } from 'redux-saga/effects';
import { EasyAccessService } from '@services';

/* Types */
import {
  GET_STEP,
  UPDATE_EMAIL,
  GET_PAGES,
  SET_PAGE,
  UPDATE_INVITE_LINK,
  GET_COMPANY_INFO,
} from './types';

function* getStep(action) {
  const response = yield call(
    EasyAccessService.getStep,
    action.version,
    action.facebookAccessToken,
    action.invitationToken
  );
  if (response.status === 200) {
    yield put({
      type: GET_STEP.SUCCESS,
      pageIsOnBusiness: response.data.facebook_page_is_on_business_manager,
      pageLink: response.data.facebook_page_invite_acceptance_link,
      invitationLink: response.data.facebook_business_manager_invitation_link,
      userIsOnBusiness: response.data.facebook_user_is_on_business_manager,
      sharedBudget: response.data.has_shared_budget_ad_account,
      step: response.data.current_step,
      email: response.data.email,
      name: response.data.name,
      token: response.data.encrypted_facebook_access_token,
    });
  } else {
    yield put({ type: GET_STEP.FAILURE, error: response.data.error });
  }
}

function* updateEmail(action) {
  const response = yield call(
    EasyAccessService.updateEmail,
    action.version,
    action.token,
    action.body
  );
  if (response.status === 200) {
    yield put({
      type: UPDATE_EMAIL.SUCCESS,
      email: response.data.email,
      step: response.data.current_step,
    });
  } else {
    yield put({ type: UPDATE_EMAIL.FAILURE, error: response.data.error });
  }
}

function* getPages(action) {
  const response = yield call(
    EasyAccessService.getPages,
    action.version,
    action.facebookAccessToken
  );
  if (response.status === 200) {
    yield put({ type: GET_PAGES.SUCCESS, pages: response.data.data });
  } else {
    yield put({ type: GET_PAGES.FAILURE, error: response.data.error });
  }
}

function* setPage(action) {
  const response = yield call(EasyAccessService.setPage, action.version, action.token, action.body);
  if (response.status === 200) {
    yield put({ type: SET_PAGE.SUCCESS, step: response.data });
  } else {
    yield put({ type: SET_PAGE.FAILURE, error: response.data.error });
  }
}

function* updateInviteLink(action) {
  const response = yield call(EasyAccessService.updateInviteLink, action.version, action.token);
  if (response.status === 200) {
    yield put({
      type: UPDATE_INVITE_LINK.SUCCESS,
      link: response.data.facebook_business_manager_invitation_link,
    });
  } else {
    yield put({
      type: UPDATE_INVITE_LINK.FAILURE,
      error: response.data.invite_user_to_facebook_business_manager_error_message,
    });
  }
}

function* getCompanyInfo(action) {
  const response = yield call(
    EasyAccessService.getCompanyInfo,
    action.version,
    action.invitationToken
  );
  if (response.status === 200) {
    yield put({
      type: GET_COMPANY_INFO.SUCCESS,
      companyInfo: response.data,
    });
  } else {
    yield put({ type: GET_STEP.FAILURE, error: response.data.error });
  }
}

export const easyAccessSaga = [
  takeEvery(GET_STEP.REQUEST, getStep),
  takeEvery(UPDATE_EMAIL.REQUEST, updateEmail),
  takeEvery(GET_PAGES.REQUEST, getPages),
  takeEvery(SET_PAGE.REQUEST, setPage),
  takeEvery(UPDATE_INVITE_LINK.REQUEST, updateInviteLink),
  takeEvery(GET_COMPANY_INFO.REQUEST, getCompanyInfo),
];
