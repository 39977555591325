import { useEffect, useState, useCallback } from 'react';
import { CompanyService } from '@services';

const useLastCompanyHistory = () => {
  const [loading, setLoading] = useState(false);
  const [lastHistory, setLastHistory] = useState(null);

  async function fetchData() {
    const res = await CompanyService.getLastCompanyStatusHistory();

    const { is_success: isSuccess, payload } = res.data;

    if (isSuccess) {
      setLastHistory(payload);
    }
  }

  const loadLastStatusHistory = useCallback(async () => {
    setLoading(true);

    await fetchData();

    setLoading(false);
  }, []);

  async function refetch() {
    fetchData();
  }

  useEffect(() => {
    loadLastStatusHistory();
  }, [loadLastStatusHistory]);

  return {
    lastHistory,
    loading,
    shoulShowLastHistory: !loading && lastHistory !== null,
    refetch,
  };
};

export default useLastCompanyHistory;
