const MainMenuEN = {
  MainMenu: {
    Report: 'Report',
    Budget: 'Budget',
    Medias: 'Medias',
    Messages: 'Messages',
    Communication: 'Communication',
    Overview: 'Overview',
    Listing: 'Listing',
    Ranking: 'Ranking',
    Audience: 'Audience',
    PINNED_ASSETS: 'Marked',
    BATCH_UPDATE_PROFILE_COVER: 'Profile & Cover',
    NEWS: 'Announcements',
    APPROVAL: 'Approvals',
    Actions: 'Actions',
    TradeAds: 'Início',
    Dashboard: 'Dashboard',
    Trends: 'Trends',
    Planner: 'Planner',
    Insights: 'Trends',
    TradeCI: 'Trade Campaigns',
    Tracking: 'Reports',
    Settings: 'Settings',
    CI: 'Campaigns',
  },
};

const MainMenuPT = {
  MainMenu: {
    Report: 'Relatórios',
    Budget: 'Orçamentos',
    Medias: 'Mídias',
    Messages: 'Mensagens',
    Communication: 'Comunicação',
    Overview: 'Visão Global',
    Listing: 'Listagem Geral',
    Ranking: 'Ranking',
    Audience: 'Audiência',
    PINNED_ASSETS: 'Marcados',
    BATCH_UPDATE_PROFILE_COVER: 'Perfil e capa',
    NEWS: 'Comunicados',
    APPROVAL: 'Aprovações',
    Actions: 'Acões',
    TradeAds: 'Início',
    Dashboard: 'Início',
    Trends: 'Tendências',
    Planner: 'Planejamento',
    Insights: 'Tendências',
    TradeCI: 'Campanha de Trade',
    Tracking: 'Relatórios',
    Settings: 'Configurações',
    CI: 'Campanhas',
  },
};

const MainMenuES = {
  MainMenu: {
    Report: 'Informes',
    Budget: 'Presupuestos',
    Medias: 'Multimedia',
    Messages: 'Mensajes',
    Communication: 'Comunicación',
    Overview: 'Visión global',
    Listing: 'Listado general',
    Ranking: 'Clasificación',
    Audience: 'Audiencia',
    PINNED_ASSETS: 'Marcado',
    BATCH_UPDATE_PROFILE_COVER: 'Perfil y cubierta',
    NEWS: 'Anuncios',
    APPROVAL: 'Aprobaciones',
    Actions: 'Acciones',
    TradeAds: 'Comienzo',
    Dashboard: 'Comienzo',
    Trends: 'Tendencias',
    Planner: 'Planificador',
    Insights: 'Tendencias',
    TradeCI: 'Campañas de Trade',
    Tracking: 'Informes',
    Settings: 'Ajustes',
    CI: 'Campañas',
  },
};

export { MainMenuPT, MainMenuEN, MainMenuES };
