import React from 'react';
import * as S from './style';

function Alert({ children }) {
  return (
    <S.PageOverlay>
      <S.ModalContainer>{children}</S.ModalContainer>
    </S.PageOverlay>
  );
}

export default Alert;
