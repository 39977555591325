const HeatMapEN = {
  HeatMap: {
    CARD_CONTENT_TITLE: 'content frequency',
    CARD_CONTENT_SUBTITLE: 'Summary of the frequency of content produced by the units',
    CARD_INVESTMENT_TITLE: 'investment frequency',
    CARD_INVESTMENT_SUBTITLE: 'Summary of the frequency of investment produced by the units',
    SEE_TOO: 'To see',
    SEE_ALL: 'See all',
    HEATMAP: 'Heat Chart',
    BAR: 'Bar Graph',
    PRODUCED_CONTENT: 'Produced Content',
    TOOLTIP_PRODUCED_CONTENT: 'Total number of ads and publications produced per month',
    DAILY_AVERAGE: 'Daily average',
    TOOLTIP_DAILY_AVERAGE: 'Average of content produced per day in the selected period',
    NO_CONTENT_PRODUCED: 'Did not create any content',
    TOOLTIP_NO_CONTENT: 'Total number of units that did not create any content in the month',
    EMPTY_TEXT: 'No content produced by the unit in the reported period was found.',
    PRODUCED_CONTENT_SPEND: 'Total investment',
    DESCRIPTION_PRODUCED_CONTENT_SPEND: 'Total ad spend for the month',
    DAILY_AVERAGE_SPEND: 'Daily average',
    DESCRIPTION_DAILY_AVERAGE_SPEND: 'Average ad spend per day for selected period',
    NO_CONTENT_PRODUCED_SPEND: 'Not invested',
    DESCRIPTION_NO_CONTENT_PRODUCED_SPEND: 'Total units that have not invested in any ads in the month',
  },
};

const HeatMapPT = {
  HeatMap: {
    CARD_CONTENT_TITLE: 'frequência de conteúdo',
    CARD_CONTENT_SUBTITLE: 'Resumo da frequência de conteúdo produzido pelas unidades',
    CARD_INVESTMENT_TITLE: 'frequência de investimento',
    CARD_INVESTMENT_SUBTITLE: 'Resumo da frequência de investimento produzido pelas unidades',
    SEE_TOO: 'Ver',
    SEE_ALL: 'Ver todos',
    HEATMAP: 'Gráfico de calor',
    BAR: 'Gráfico de barras',
    PRODUCED_CONTENT: 'Conteúdos produzidos',
    TOOLTIP_PRODUCED_CONTENT: 'Total de anúncios e publicações produzidos no mês',
    DAILY_AVERAGE: 'Média diária',
    TOOLTIP_DAILY_AVERAGE: 'Média dos conteúdos produzidos por dia no período selecionado',
    NO_CONTENT_PRODUCED: 'Não criaram conteúdo',
    TOOLTIP_NO_CONTENT: 'Total de unidades que não criaram nenhum conteúdo no mês',
    EMPTY_TEXT: 'Não foi encontrado conteúdo produzido pela unidade no período informado. ',
    PRODUCED_CONTENT_SPEND: 'Total de investimento',
    DESCRIPTION_PRODUCED_CONTENT_SPEND: 'Total de investimento em anúncios no mês',
    DAILY_AVERAGE_SPEND: 'Média diária',
    DESCRIPTION_DAILY_AVERAGE_SPEND: 'Média de investimento em anúncios por dia no período selecionado',
    NO_CONTENT_PRODUCED_SPEND: 'Não investiram',
    DESCRIPTION_NO_CONTENT_PRODUCED_SPEND: 'Total de unidades que não investiram em nenhum anúncio no mês',
  },
};

const HeatMapES = {
  HeatMap: {
    CARD_CONTENT_TITLE: 'frecuencia de contenido',
    CARD_CONTENT_SUBTITLE: 'Resumen de la frecuencia de contenidos producidos por las unidades',
    CARD_INVESTMENT_TITLE: 'investment frequency',
    CARD_INVESTMENT_SUBTITLE: 'Summary of the frequency of investment produced by the units',
    SEE_TOO: 'Para ver',
    SEE_ALL: 'Ver todo',
    HEATMAP: 'Gráfico de calor',
    BAR: 'Gráfico de barras',
    PRODUCED_CONTENT: 'Contenido producido',
    TOOLTIP_PRODUCED_CONTENT: 'Total de anuncios y publicaciones producidos en el mes',
    DAILY_AVERAGE: 'Promedio diario',
    TOOLTIP_DAILY_AVERAGE: 'Promedio de contenido producido por día en el período seleccionado',
    NO_CONTENT_PRODUCED: 'No creó contenido',
    TOOLTIP_NO_CONTENT: 'Unidades totales que no crearon ningún contenido en el mes',
    EMPTY_TEXT: 'No se encontró ningún contenido producido por la unidad en el período informado.',
    PRODUCED_CONTENT_SPEND: 'Inversión total',
    DESCRIPTION_PRODUCED_CONTENT_SPEND: 'Gasto publicitario total del mes',
    DAILY_AVERAGE_SPEND: 'Promedio diario',
    DESCRIPTION_DAILY_AVERAGE_SPEND: 'Inversión publicitaria promedio por día para el período seleccionado',
    NO_CONTENT_PRODUCED_SPEND: 'No invertido',
    DESCRIPTION_NO_CONTENT_PRODUCED_SPEND: 'Total de unidades que no han invertido en ningún anuncio en el mes',
  },
};

export { HeatMapEN, HeatMapPT, HeatMapES };
