import React from 'react';
import localePT from 'date-fns/locale/pt';
import { DateRangePicker as ReactDateRangePicker } from 'react-date-range';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'components/DateRange/customDatePickerWidth.css';

import * as S from './styles';

import { useRanges } from './hooks';
import { defineds } from './consts';
import dayjs from 'dayjs';
import type { DateRangeProps, Period, OnChangeProps, StartEndDate } from './types';
import { useWindowDimensions } from 'utils/hooks';

function DateRange({
  minDate,
  hideStaticRanges,
  maxDate,
  ranges,
  setRanges,
  handleSelectButtonCalendar,
  ...props
}: DateRangeProps) {
  const { width } = useWindowDimensions();
  const { inputRanges, staticRanges } = useRanges();

  function handleSelect(ranges: OnChangeProps) {
    if (setRanges) setRanges([ranges.selection]);

    function getDatePreset() {
      const startSelectionDate = ranges.selection.startDate?.getTime();
      const endSelectionDate = ranges.selection.endDate?.getTime();

      function checkIfIsSameDate({ startDate, endDate }: StartEndDate) {
        return startSelectionDate === startDate.getTime() && endSelectionDate === endDate.getTime();
      }

      let datePreset = '';

      if (
        checkIfIsSameDate({
          startDate: defineds.startOfToday,
          endDate: defineds.endOfToday,
        })
      ) {
        datePreset = 'TODAY';
      } else if (
        checkIfIsSameDate({
          startDate: defineds.startOfYesterday,
          endDate: defineds.endOfYesterday,
        })
      ) {
        datePreset = 'YESTERDAY';
      } else if (
        checkIfIsSameDate({
          startDate: defineds.sevenDaysAgo,
          endDate: defineds.endOfYesterday,
        })
      ) {
        datePreset = 'LAST_7D';
      } else if (
        checkIfIsSameDate({
          startDate: defineds.startOfLastMonth,
          endDate: defineds.endOfLastMonth,
        })
      ) {
        datePreset = 'LAST_MONTH';
      } else if (
        checkIfIsSameDate({
          startDate: defineds.startOfMonth,
          endDate: defineds.endOfToday,
        })
      ) {
        datePreset = 'THIS_MONTH';
      } else if (
        checkIfIsSameDate({
          startDate: defineds.startOfYear,
          endDate: defineds.endOfToday,
        })
      ) {
        datePreset = 'THIS_YEAR';
      }

      return datePreset;
    }

    const period: Period = {
      datePreset: getDatePreset(),
      start: dayjs(ranges.selection.startDate).toDate(),
      end: dayjs(ranges.selection.endDate).toDate(),
      isCustomOption: ranges.selection.isCustomOption,
    };

    if (handleSelectButtonCalendar) {
      handleSelectButtonCalendar(period);
    }
  }

  return (
    <S.Container hideStaticRanges={hideStaticRanges}>
      <ReactDateRangePicker
        staticRanges={staticRanges}
        inputRanges={inputRanges}
        ranges={ranges}
        rangeColors={['#2328EB']}
        onChange={handleSelect}
        months={width > 1350 ? 2 : 1}
        direction="horizontal"
        locale={localePT}
        minDate={minDate}
        maxDate={maxDate || new Date()}
        weekStartsOn={0}
        {...props}
      />
    </S.Container>
  );
}

export default DateRange;
