/* Create action types */
import createActionTypes from '../createActionTypes';

export const GET_UNITS = createActionTypes('GET_UNITS');
export const ADD_UNIT = createActionTypes('ADD_UNIT');
export const CHANGE_TARGETING = createActionTypes('CHANGE_TARGETING');
export const GET_EA_GROUPS = createActionTypes('GET_EA_GROUPS');
export const GET_EA_USERS = createActionTypes('GET_EA_USERS');
export const CHANGE_UNITS_STATUS_FILTER = 'CHANGE_UNITS_STATUS_FILTER';
export const CHANGE_UNITS_NAMES_FILTER = 'CHANGE_UNITS_NAMES_FILTER';
export const UPDATE_UNITS_AFTER_RENAME = 'UPDATE_UNITS_AFTER_RENAME';
