import { css } from 'styled-components';

function multilineEllipsis(
  fontSize = 15,
  lineHeight = 1.5,
  linesToShow = 2,
  background = 'transparent'
) {
  const maxHeight = fontSize * lineHeight * linesToShow;
  return css`
    background: ${background};
    display: block;
    display: -webkit-box;
    max-height: ${maxHeight}px;
    font-size: ${fontSize}px;
    line-height: ${lineHeight};
    -webkit-line-clamp: ${linesToShow};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: -o-ellipsis-lastline;
  `;
}

export { multilineEllipsis };
