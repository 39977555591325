const ContactBoxEN = {
  ContactBox: {
    CONTACT_TITLE: 'Need to talk to us?',
    CONTACT_TEXT:
      "If our articles didn't help you, contact us and we'll answer as soon as possible.",
    CONTACT_BUTTON: 'Contact us',
  },
};

const ContactBoxPT = {
  ContactBox: {
    CONTACT_TITLE: 'Precisa falar com a gente?',
    CONTACT_TEXT:
      'Se nossos artigos não te ajudaram, entre em contato e vamos responder assim que possível.',
    CONTACT_BUTTON: 'Entre em contato',
  },
};

const ContactBoxES = {
  ContactBox: {
    CONTACT_TITLE: '¿Necesita hablar con nosotros?',
    CONTACT_TEXT:
      'Si nuestros artículos no le ayudaron, póngase en contacto con nosotros y le responderemos lo antes posible.',
    CONTACT_BUTTON: 'Entre en contacto',
  },
};

export { ContactBoxEN, ContactBoxES, ContactBoxPT };
