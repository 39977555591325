/* Modules */
import { call, put, takeEvery } from 'redux-saga/effects';

import { FormService } from '@services';

/* Types */
import { SEND_GUEST, SAVE_PROFILE } from './types';
import { SHOW_TOAST } from '../toast/types';
import { IS_LOADING } from '../loading/types';
import { FETCH_USER_ME } from '../auth/types';

function* sendGuest(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });
  try {
    const response = yield call(FormService.sendGuest, action.version, action.guestData);
    if (response.status === 200) {
      yield put({ type: SEND_GUEST.SUCCESS, guest: response.data, values: action.values });
      yield put({
        type: SHOW_TOAST.SUCCESS,
        message: action.successMessage,
        messageType: 'success',
      });
    }
    yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
  } catch (e) {
    if (e.response.status === 409) {
      yield put({
        type: SHOW_TOAST.SUCCESS,
        message: action.emailAlreadyRegistredMessage,
        messageType: 'error',
      });
    }
    yield put({ type: SEND_GUEST.FAILURE, guest: e.response, values: action.values });
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
    yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
  }
}

function* saveProfile(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });

  try {
    const response = yield call(FormService.saveProfile, action.version, action.data);

    if (response.status === 200) {
      yield put({ type: SAVE_PROFILE.SUCCESS, status: response.status });
      yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
      yield put({ type: FETCH_USER_ME.REQUEST, version: '1.0' });
    }
  } catch (e) {
    yield put({ type: SAVE_PROFILE.FAILURE, status: e.response.status });
    yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
  }
}

export const formSaga = [
  takeEvery(SEND_GUEST.REQUEST, sendGuest),
  takeEvery(SAVE_PROFILE.REQUEST, saveProfile),
];
