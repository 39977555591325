import React from 'react';
import * as S from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function Warning({ icon, children, infinite = false }) {
  return (
    <S.WarningContainer infinite={infinite}>
      {!!icon && <FontAwesomeIcon icon={icon} />}
      {children}
    </S.WarningContainer>
  );
}
