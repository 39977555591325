// Types
import {
  AUTHENTICATE_BUSINESS,
  AUTHENTICATE_LOGIN,
  BUSINESS_DATA_DECODED,
  EMAIL_IS_VALID,
  FETCH_DOMAIN,
  FETCH_LIST_COMPANIES,
  FETCH_USER_ME,
  FORGOT_PASSWORD,
  GET_CAPABILITY_CATEGORY,
  GET_CURRENT_USER,
  GET_SESSIONS,
  GET_USERS,
  INVALIDATE_SESSION,
  LOGOUT,
  RECOVERY_PASSWORD,
  RESET_PASSWORD,
  SAVE_TOKENS,
  USER_DATA_DECODED,
} from './types';

const INITIAL_STATE = {
  subDomain: {
    id: '',
    name: '',
    sub_domain: '',
    picture_url: '',
  },
  showChooseCompany: false,
  subDomainLoading: null,
  dataUser: {
    email: '',
    user_name: '',
    user_last_name: '',
    avatar_url: '',
  },
  dataUserStatus: null,
  dataUserLoading: null,
  userToken: null,
  userTokenLoading: false,
  userTokenError: null,
  userTokenData: {
    aud: '',
    bgd_capability: '',
    email: '',
    exp: null,
    iat: null,
    is_verified: '',
    iss: '',
    nbf: null,
    unique_name: '',
  },
  businessId: null,
  businessToken: null,
  businessLoading: false,
  businessTokenData: {},
  listCompanies: [],
  listCompaniesLoading: false,
  forgotPassword: {
    isLoading: null,
    done: null,
  },
  recoveryPassword: {
    data: '',
    isLoading: true,
    error: '',
    status: null,
  },
  users: {
    data: {},
    error: '',
    isLoading: false,
  },
  sessions: {
    data: [],
    error: '',
    isLoading: false,
  },
  invalidateSessions: {
    data: {},
    error: '',
    isLoading: false,
  },
  logout: {
    data: {},
    error: '',
    isLoading: false,
  },
  capabilityCategory: {
    data: [],
    error: '',
    isLoading: false,
  },
};

const authenticationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DOMAIN.REQUEST:
      return {
        ...state,
        subDomainLoading: true,
      };

    case FETCH_DOMAIN.SUCCESS:
      return {
        ...state,
        subDomain: {
          ...action.response,
        },
        showChooseCompany: false,
        subDomainLoading: false,
        businessId: action.response.id,
      };

    case FETCH_DOMAIN.FAILURE:
      return {
        ...state,
        subDomain: { ...INITIAL_STATE.subDomain },
        showChooseCompany: true,
        subDomainLoading: false,
        businessId: null,
      };

    case FETCH_DOMAIN.RESET:
      return {
        ...state,
        subDomain: INITIAL_STATE.subDomain,
        subDomainLoading: INITIAL_STATE.subDomainLoading,
        businessId: null,
      };

    case EMAIL_IS_VALID.REQUEST:
      return {
        ...state,
        dataUser: {
          ...state.dataUser,
        },
        dataUserStatus: null,
        dataUserLoading: true,
      };

    case EMAIL_IS_VALID.SUCCESS:
      return {
        ...state,
        dataUser: {
          ...state.dataUser,
          ...action.response,
          email: action.email,
        },
        dataUserStatus: action.status,
        dataUserLoading: false,
      };

    case EMAIL_IS_VALID.FAILURE:
      return {
        ...state,
        dataUserStatus: action.status,
        dataUserLoading: false,
      };

    case EMAIL_IS_VALID.RESET:
      return {
        ...state,
        dataUser: INITIAL_STATE.dataUser,
        dataUserStatus: INITIAL_STATE.dataUserStatus,
        dataUserLoading: INITIAL_STATE.dataUserLoading,
      };

    case AUTHENTICATE_LOGIN.REQUEST:
      return {
        ...state,
        userTokenLoading: true,
      };

    case AUTHENTICATE_LOGIN.SUCCESS:
      return {
        ...state,
        userToken: action.response,
        userTokenLoading: false,
      };

    case AUTHENTICATE_LOGIN.FAILURE:
      return {
        ...state,
        userTokenError: 'Usuário não validado.',
        userTokenLoading: false,
      };

    case AUTHENTICATE_LOGIN.RESET:
      return {
        ...state,
        userToken: INITIAL_STATE.userToken,
        userTokenError: INITIAL_STATE.userTokenError,
        userTokenLoading: false,
      };

    case USER_DATA_DECODED.SUCCESS:
      return {
        ...state,
        userTokenData: action.decoded,
      };

    case BUSINESS_DATA_DECODED.SUCCESS:
      return {
        ...state,
        businessTokenData: action.decoded,
      };

    case FETCH_LIST_COMPANIES.REQUEST:
      return {
        ...state,
        listCompanies: [],
        listCompaniesLoading: true,
      };

    case FETCH_LIST_COMPANIES.SUCCESS:
      return {
        ...state,
        listCompanies: action.list,
        listCompaniesLoading: false,
      };

    case FETCH_LIST_COMPANIES.FAILURE:
      return {
        ...state,
        listCompanies: [],
        listCompaniesLoading: false,
      };

    case FETCH_LIST_COMPANIES.RESET:
      return {
        ...state,
        listCompanies: INITIAL_STATE.listCompanies,
        listCompaniesLoading: false,
      };

    case AUTHENTICATE_BUSINESS.REQUEST:
      return {
        ...state,
        showChooseCompany: false,
        businessToken: null,
        businessLoading: true,
      };

    case AUTHENTICATE_BUSINESS.SUCCESS:
      return {
        ...state,
        showChooseCompany: false,
        businessToken: action.token,
        businessLoading: false,
      };

    case AUTHENTICATE_BUSINESS.FAILURE:
      return {
        ...state,
        showChooseCompany: true,
        businessToken: null,
        businessLoading: false,
      };

    case AUTHENTICATE_BUSINESS.RESET:
      return {
        ...state,
        businessToken: INITIAL_STATE.businessToken,
        businessLoading: false,
      };

    case SAVE_TOKENS.SUCCESS:
      return {
        ...state,
        userToken: action.userToken ? action.userToken : null,
        businessToken: action.businessToken ? action.businessToken : null,
      };

    case SAVE_TOKENS.RESET:
      return {
        ...state,
        userToken: null,
        businessToken: null,
      };

    case FETCH_USER_ME.REQUEST:
      return {
        ...state,
        dataUser: {
          ...state.dataUser,
        },
        dataUserLoading: true,
      };

    case FETCH_USER_ME.SUCCESS:
      return {
        ...state,
        dataUser: {
          ...state.dataUser,
          ...action.userMe,
        },
        dataUserLoading: false,
      };

    case FETCH_USER_ME.FAILURE:
      return {
        ...state,
        dataUser: {
          ...state.dataUser,
        },
        dataUserLoading: false,
      };

    case FETCH_USER_ME.RESET:
      return {
        ...state,
        dataUser: INITIAL_STATE.dataUser,
        dataUserLoading: false,
      };

    case GET_CURRENT_USER.SUCCESS:
      return {
        ...state,
        dataUser: {
          ...state.dataUser,
          ...action.currentUser,
          isLoading: false,
        },
      };

    case RECOVERY_PASSWORD.REQUEST:
      return {
        ...state,
        dataUser: state.dataUser,
        dataUserLoading: true,
      };

    case RECOVERY_PASSWORD.SUCCESS:
      return {
        ...state,
        dataUser: {
          ...state.dataUser,
          email: action.recoveryPass.email,
          user_name: action.recoveryPass.user_name,
          user_last_name: action.recoveryPass.user_last_name,
          avatar_url: action.recoveryPass.avatar_url,
        },
        dataUserLoading: false,
      };

    case RECOVERY_PASSWORD.FAILURE:
      return {
        ...state,
        dataUser: INITIAL_STATE.dataUser,
        dataUserLoading: false,
      };

    case RESET_PASSWORD.REQUEST:
      return {
        ...state,
        recoveryPassword: {
          data: '',
          isLoading: true,
          error: '',
        },
      };

    case RESET_PASSWORD.SUCCESS:
      return {
        ...state,
        recoveryPassword: {
          data: action.recoveryPass,
          isLoading: false,
          error: '',
        },
      };

    case RESET_PASSWORD.FAILURE:
      return {
        ...state,
        recoveryPassword: {
          data: '',
          isLoading: false,
          error: action.errorRecoveryPass,
        },
      };

    case FORGOT_PASSWORD.REQUEST:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          isLoading: true,
          done: null,
        },
      };

    case FORGOT_PASSWORD.SUCCESS:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          isLoading: false,
          done: action.forgotPassword,
        },
      };

    case FORGOT_PASSWORD.FAILURE:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          isLoading: false,
          done: action.forgotPassword,
        },
      };

    case FORGOT_PASSWORD.RESET:
      return {
        ...state,
        forgotPassword: INITIAL_STATE.forgotPassword,
      };

    case GET_USERS.REQUEST:
      return {
        ...state,
        users: {
          data: [],
          error: null,
          isLoading: true,
        },
      };

    case GET_USERS.SUCCESS:
      return {
        ...state,
        users: {
          data: action.users,
          error: null,
          isLoading: false,
        },
      };

    case GET_USERS.FAILURE:
      return {
        ...state,
        users: {
          data: [],
          error: action.usersError,
          isLoading: false,
        },
      };

    case GET_SESSIONS.REQUEST:
      return {
        ...state,
        sessions: {
          data: [],
          error: null,
          isLoading: true,
        },
      };

    case GET_SESSIONS.SUCCESS:
      return {
        ...state,
        sessions: {
          data: action.sessions,
          error: null,
          isLoading: false,
        },
      };

    case GET_SESSIONS.FAILURE:
      return {
        ...state,
        sessions: {
          data: [],
          error: action.sessionsError,
          isLoading: false,
        },
      };

    case INVALIDATE_SESSION.REQUEST:
      return {
        ...state,
        invalidateSessions: {
          data: [],
          error: null,
          isLoading: true,
        },
      };

    case INVALIDATE_SESSION.SUCCESS:
      return {
        ...state,
        invalidateSessions: {
          data: action.invalidateSessions,
          error: null,
          isLoading: false,
        },
      };

    case INVALIDATE_SESSION.FAILURE:
      return {
        ...state,
        invalidateSessions: {
          data: [],
          error: action.invalidateSessionsError,
          isLoading: false,
        },
      };

    case LOGOUT.REQUEST:
      return {
        ...state,
        logout: {
          data: [],
          error: null,
          isLoading: true,
        },
      };

    case LOGOUT.SUCCESS:
      return {
        ...state,
        logout: {
          data: action.logout,
          error: null,
          isLoading: false,
        },
      };

    case LOGOUT.FAILURE:
      return {
        ...state,
        logout: {
          data: [],
          error: action.logoutError,
          isLoading: false,
        },
      };

    case GET_CAPABILITY_CATEGORY.REQUEST:
      return {
        ...state,
        capabilityCategory: {
          data: state.capabilityCategory.data || [],
          error: null,
          isLoading: true,
        },
      };

    case GET_CAPABILITY_CATEGORY.SUCCESS:
      return {
        ...state,
        capabilityCategory: {
          data: action.capabilityCategory,
          error: null,
          isLoading: false,
        },
      };

    case GET_CAPABILITY_CATEGORY.FAILURE:
      return {
        ...state,
        capabilityCategory: {
          data: [],
          error: action.error,
          isLoading: false,
        },
      };

    default:
      return state;
  }
};

export default authenticationReducer;
