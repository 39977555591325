const FeedChannelsEN = {
  all: 'All',
  facebook: 'Facebook',
  instagram: 'Instagram',
  tiktok: 'TikTok',
};
const FeedChannelsPT = {
  all: 'Todos',
  facebook: 'Facebook',
  instagram: 'Instagram',
  tiktok: 'TikTok',
};
const FeedChannelsES = {
  all: 'Todos',
  facebook: 'Facebook',
  instagram: 'Instagram',
  tiktok: 'TikTok',
};

export { FeedChannelsEN, FeedChannelsPT, FeedChannelsES };
