import React from 'react';
import { Button } from '@bornlogic/gd-design-system';
import { MixPanelController } from '../../../controllers';

function ButtonWithMixPanel({ eventName, onClick, children, className, ...rest }) {
  function onClickWithEvent(event) {
    if (eventName) {
      const mixPanel = new MixPanelController({ eventName, eventType: 'click' });
      mixPanel.createEvent();
    }

    if (onClick) {
      onClick(event);
    }
  }

  return (
    <div className={className} data-testid="buttonWithMixPanel">
      <Button onClick={onClickWithEvent} {...rest}>
        {children}
      </Button>
    </div>
  );
}

export default ButtonWithMixPanel;
