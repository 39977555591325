// Api
import { ApiTenant } from '@utils/api';

class BusinessService {
  static listUsers(version, search, next) {
    const hasSearch = search || '';
    const hasNext = next ? `&page_hash=${next}` : '';
    return ApiTenant.get(`/business/${version}/users?${hasSearch}${hasNext}`);
  }

  static getUser(version, id, search) {
    return ApiTenant.get(`/business/${version}/users/${id}?${search}`);
  }

  static getUsersSummary(version, search) {
    return ApiTenant.get(`/business/${version}/users/summary?${search}`);
  }

  static updateStatus(version, id, status, updateThirdPartySettings) {
    if (updateThirdPartySettings) {
      return ApiTenant.put(`/business/${version}/users/${id}`, {
        status,
        update_third_party_settings: updateThirdPartySettings,
      });
    }
    return ApiTenant.put(`/business/${version}/users/${id}`, { status });
  }

  static updateUserGroups(version, id, groups) {
    return ApiTenant.put(`/business/${version}/users/${id}`, { permitted_groups: groups });
  }

  static revokeInvite(version, id) {
    return ApiTenant.post(`/business/${version}/revoke`, { user_id: id });
  }

  static inviteUser(
    version,
    email,
    role,
    redirect_url,
    capabilities,
    send_verification_email,
    permittedGroups
  ) {
    return ApiTenant.post(`/business/${version}/Invite`, {
      user_email: email,
      role,
      redirect_url,
      capabilities,
      send_verification_email,
      permitted_groups: permittedGroups || null,
    });
  }

  static getInvitationLink(id, redirect_url) {
    return ApiTenant.post('/business/1.1/InvitationLink', {
      user_id: id,
      redirect_url,
    });
  }

  static resendInvite(id, redirect_url) {
    return ApiTenant.post('/business/1.1/Reinvite', {
      user_id: id,
      redirect_url,
    });
  }

  static getSasurl(version) {
    return ApiTenant.get(`/business/${version}/blob/sas`);
  }

  static editUserRole(version, id, data) {
    return ApiTenant.put(`/business/${version}/users/${id}`, data);
  }

  static verifyBusiness(version) {
    return ApiTenant.post(`/business/${version}/verify`);
  }

  static updateCapabilities(version, id, capabilities, groups) {
    return ApiTenant.put(`/business/${version}/users/${id}`, {
      capabilities,
      permitted_groups: groups,
    });
  }

  static connectedUsers(id, period, limitedToFive = false) {
    return ApiTenant.get(
      `/business/1.1/Users/${id}/AccessLog?${period}&timezone_offset=-3${
        limitedToFive ? '&limit=5' : '&limit=9999'
      }`
    );
  }

  static cloneCapabilities(version, fromUserId, toUserIds) {
    return ApiTenant.post(`/business/${version}/users/${fromUserId}/clonecapabilities`, {
      user_ids: toUserIds,
    });
  }
}

export default BusinessService;
