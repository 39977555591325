// Types
import {
  SEND_GUEST,
  SAVE_PROFILE,
  CHANGE_SEARCH_ACTIONS,
  CHANGE_CHECKBOX_PROFILE_COVER,
  CHANGE_SOURCES_USERS_SHARE,
  CHANGE_SEARCH_MEDIAS,
  CHANGE_SOURCES_MEDIAS,
  CHANGE_MARKED_REPLICATE,
} from './types';

const INITIAL_STATE = {
  guest: {
    name: '',
    lastName: '',
    guestEmail: '',
  },
  userIdGuest: null,
  guestLoading: false,
  objectLanguage: {
    status: null,
    isLoading: false,
  },
  actions: {
    search: '',
    fanpages: [],
    markedUsersShare: [],
    marked: {
      groups: [],
      accounts: [],
      fanpages: [],
    },
  },
  creativesShare: {
    search: '',
    sources: [
      {
        source_id: 'root',
        isChecked: false,
        subGroups: [],
      },
    ],
  },
};

const formReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEND_GUEST.REQUEST:
      return {
        ...state,
        guest: {
          ...state.guest,
        },
        userIdGuest: state.userIdGuest,
        guestLoading: true,
      };

    case SEND_GUEST.SUCCESS:
      return {
        ...state,
        guest: {
          ...action.values,
        },
        userIdGuest: action.guest.user_id,
        guestLoading: false,
      };

    case SEND_GUEST.FAILURE:
      return {
        ...state,
        guest: {
          ...action.values,
        },
        userIdGuest: null,
        guestLoading: false,
      };

    case SEND_GUEST.RESET:
      return {
        ...state,
        guest: INITIAL_STATE.guest,
        userIdGuest: INITIAL_STATE.userIdGuest,
        guestLoading: INITIAL_STATE.guestLoading,
      };

    case SAVE_PROFILE.REQUEST:
      return {
        ...state,
        objectLanguage: {
          ...state.objectLanguage,
          isLoading: true,
        },
      };

    case SAVE_PROFILE.SUCCESS:
      return {
        ...state,
        objectLanguage: {
          ...state.objectLanguage,
          status: action.status,
          isLoading: false,
        },
      };

    case SAVE_PROFILE.FAILURE:
      return {
        ...state,
        objectLanguage: {
          ...state.objectLanguage,
          status: action.status,
          isLoading: false,
        },
      };

    case SAVE_PROFILE.RESET:
      return {
        ...state,
        objectLanguage: INITIAL_STATE.objectLanguage,
      };

    case CHANGE_SEARCH_ACTIONS.SUCCESS:
      return {
        ...state,
        actions: {
          ...state.actions,
          search: action.value,
        },
      };

    case CHANGE_CHECKBOX_PROFILE_COVER.SUCCESS:
      return {
        ...state,
        actions: {
          ...state.actions,
          search: state.actions.search,
          fanpages: action.fanpages,
        },
      };

    case CHANGE_SOURCES_USERS_SHARE.SUCCESS:
      return {
        ...state,
        actions: {
          ...state.actions,
          markedUsersShare: action.markedUsersShare,
        },
      };

    case CHANGE_MARKED_REPLICATE.SUCCESS:
      const newActions = {
        ...state.actions,
      };

      newActions.marked[action.markedType] = action.marked;
      return {
        ...state,
        actions: {
          ...newActions,
        },
      };

    case CHANGE_MARKED_REPLICATE.RESET:
      return {
        ...state,
        actions: {
          ...state.actions,
          marked: {
            groups: [],
            accounts: [],
            fanpages: [],
          },
        },
      };

    case CHANGE_SEARCH_MEDIAS.SUCCESS:
      return {
        ...state,
        creativesShare: {
          ...state.creativesShare,
          search: action.value,
        },
      };

    case CHANGE_SOURCES_MEDIAS.SUCCESS:
      return {
        ...state,
        creativesShare: {
          ...state.creativesShare,
          sources: action.sources,
        },
      };

    case CHANGE_SOURCES_MEDIAS.RESET:
      return {
        ...state,
        creativesShare: {
          ...state.creativesShare,
          sources: [
            {
              source_id: 'root',
              isChecked: false,
              subGroups: [],
            },
          ],
        },
      };

    default:
      return state;
  }
};

export default formReducer;
