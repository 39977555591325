import React from 'react';
import { useSelector } from 'react-redux';
import MenuLink from '../MenuLink';
import { validateCapability } from '@utils/helpers';

function ActionsSidebar() {
  const { businessTokenData } = useSelector((state) => state.authenticationReducer);

  const links = [];

  if (validateCapability(businessTokenData, 'MEDIA_APPROVAL')) {
    links.push({
      name: 'Approvals',
      icon: ['fal', 'check-double'],
      translator: 'APPROVALS',
      disabled: false,
    });
  }
  if (validateCapability(businessTokenData, 'FEED')) {
    links.push({
      name: 'Feed',
      icon: ['fa', 'list'],
      translator: 'FEED',
      disabled: false,
    });
  }

  return <MenuLink links={links} page="Medias" />;
}

export default ActionsSidebar;
