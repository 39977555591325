/* Modules */
import { call, put, takeEvery } from 'redux-saga/effects';

// Services
import { MediasService } from 'services';

/* Types */
import { BASE_URL } from '@utils/constants';
import {
  GET_FOLDERS,
  GET_CAMPAIGN_FOLDERS,
  GET_GDTV_FOLDERS,
  POST_FOLDER,
  POST_MEDIA,
  DELETE_MEDIA,
  GET_FOLDER,
  SET_CREATIVE_INFO,
  REMOVE_CREATIVE,
  REMOVE_GDTV_FOLDER,
  DISABLE_GDTV_FOLDER,
  ENABLE_GDTV_FOLDER,
  REMOVE_CAMPAIGN_FOLDER,
  DOWNLOAD_MEDIA,
  DOWNLOAD_GDTV_MEDIA,
  DOWNLOAD_CAMPAIGN_MEDIA,
  SHARE_TEMPLATE,
  GET_GDTV_FOLDER,
  GET_CAMPAIGN_FOLDER,
  EDIT_GDTV_FOLDER,
  EDIT_CAMPAIGN_FOLDER,
  GET_POSTS,
  GET_ADS,
  GET_CAMPAIGN_VIEWS,
  GET_GDTV_VIEWS,
  PUT_CAMPAIGN_STATUS,
  POST_ADVERTS_STATUS,
  POST_CAMPAIGN_CHANGE_STATUS,
} from './types';

import { SHOW_TOAST } from '../toast/types';
import { IS_LOADING } from '../loading/types';

function* getFolders(action) {
  try {
    const response = yield call(MediasService.getFolders, action.body);

    if (response.status === 200) {
      yield put({ type: GET_FOLDERS.SUCCESS, data: response.data });
    }
  } catch (e) {
    yield put({ type: GET_FOLDERS.FAILURE, data: e.response });
  }
}

function* getGdtvFolders(action) {
  try {
    const response = yield call(MediasService.getGdtvFolders, action.body);

    if (response.status === 200) {
      yield put({ type: GET_GDTV_FOLDERS.SUCCESS, data: response.data });
    }
  } catch (e) {
    yield put({ type: GET_GDTV_FOLDERS.FAILURE, data: e.response });
  }
}

function* getCampaignFolders(action) {
  try {
    const response = yield call(MediasService.getCampaignFolders, action.body);

    if (response.status === 200) {
      yield put({ type: GET_CAMPAIGN_FOLDERS.SUCCESS, data: response.data });
    }
  } catch (e) {
    yield put({ type: GET_CAMPAIGN_FOLDERS.FAILURE, data: e.response });
  }
}

function* getCreative(action) {
  try {
    const response = yield call(MediasService.getCreative, action.id);
    const responseMedias = yield call(MediasService.getCreativeMedias, action.id, action.body);

    if (response.status === 200 && responseMedias.status === 200) {
      yield put({ type: GET_FOLDER.SUCCESS, data: response.data, medias: responseMedias.data });
    }
  } catch (e) {
    yield put({ type: GET_FOLDER.FAILURE, data: e.response });
  }
}

function* postCreative(action) {
  try {
    const response = yield call(MediasService.postCreative, action.body);
    if (response.status === 200 || response.status === 201) {
      yield put({ type: POST_FOLDER.SUCCESS, id: response.data.id });
      yield put({
        type: SHOW_TOAST.SUCCESS,
        message: action.successMessage,
        messageType: 'success',
      });
    }
  } catch (e) {
    yield put({ type: POST_FOLDER.FAILURE, data: e.response });
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
  }
}

function* postMedia(action) {
  try {
    const response = yield call(MediasService.postMedia, action.id, action.body, action.cancel);
    if (response.status === 200) {
      yield put({
        type: POST_MEDIA.SUCCESS,
        id: response.data[0].MediaID,
        uniqueCode: action.uniqueCode,
      });
    }
  } catch (e) {
    yield put({ type: POST_MEDIA.FAILURE, data: e.response });
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
  }
}

function* deleteMedia(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });
  try {
    const response = yield call(MediasService.deleteMedia, action.id);
    if (response.status === 200) {
      if (action.onSuccess) {
        action.onSuccess();
      }
      yield put({
        type: SHOW_TOAST.SUCCESS,
        message: action.successMessage,
        messageType: 'success',
      });
    }
  } catch (e) {
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
  }
  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
}

function* setCreativeInfo(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });
  try {
    const response = yield call(MediasService.setCreativeInfo, action.id, action.body);

    if (response.status === 200) {
      yield put({ type: SET_CREATIVE_INFO.SUCCESS, data: action.body });
      yield put({
        type: SHOW_TOAST.SUCCESS,
        message: action.successMessage,
        messageType: 'success',
      });
    }
  } catch (e) {
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
  }
  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
}

function* removeCreative(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });
  try {
    const response = yield call(MediasService.removeCreative, action.id);
    if (response.status === 200) {
      yield put({
        type: SHOW_TOAST.SUCCESS,
        message: action.successMessage,
        messageType: 'success',
      });
      window.history.back();
    }
  } catch (e) {
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
  }
  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
}

function* downloadMedia(action) {
  try {
    const response = yield call(MediasService.downloadMedia, action.media.id);
    if (response.status === 200) {
      const link = document.createElement('a');
      link.download = action.media.name;
      link.href = BASE_URL + response.data.link;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      yield put({ type: DOWNLOAD_MEDIA.SUCCESS, link: response.data.link });
    }
  } catch (e) {
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
  }
}

function* downloadGdtvMedia(action) {
  try {
    const response = yield call(MediasService.downloadGdtvMedia, action.media.source_id);
    if (response.status === 200) {
      const link = document.createElement('a');
      link.download = action.media.name;
      link.href = response.data.medias[0];
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      yield put({ type: DOWNLOAD_GDTV_MEDIA.SUCCESS, link: response.data.medias[0] });
    }
  } catch (e) {
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
  }
}

function* downloadCampaignMedia(action) {
  try {
    const response = yield call(MediasService.downloadCampaignMedia, action.media.source_id);
    if (response.status === 200) {
      const link = document.createElement('a');
      link.download = action.media.name;
      link.href = response.data.medias[0];
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      yield put({ type: DOWNLOAD_CAMPAIGN_MEDIA.SUCCESS, link: response.data.medias[0] });
    }
  } catch (e) {
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
  }
}

function* shareTemplate(action) {
  try {
    const response = yield call(MediasService.shareTemplate, action.id, action.body);

    if (response.status === 200 || response.status === 201) {
      yield put({
        type: SHOW_TOAST.SUCCESS,
        message: 'Compartilhamento solicitado',
        messageType: 'success',
      });
    }
  } catch (e) {
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: 'Não foi possível solicitar o compartilhamento',
      messageType: 'error',
    });
  }
}

function* getGdtvFolder(action) {
  try {
    const response = yield call(MediasService.getGdtvFolder, action.id);
    const responseMedias = yield call(MediasService.getGdtvMedias, action.id, action.body);

    if (response.status === 200 && responseMedias.status === 200) {
      yield put({
        type: GET_GDTV_FOLDER.SUCCESS,
        data: response.data,
        gdtvMedias: responseMedias.data,
      });
    }
  } catch (e) {
    yield put({ type: GET_GDTV_FOLDER.FAILURE, data: e.response });
  }
}

function* getCampaignFolder(action) {
  try {
    const response = yield call(MediasService.getCampaignFolder, action.id);
    const responseMedias = yield call(MediasService.getCampaignMedias, action.id, action.body);

    if (response.status === 200 && responseMedias.status === 200) {
      yield put({
        type: GET_CAMPAIGN_FOLDER.SUCCESS,
        data: response.data,
        campaignMedias: responseMedias.data,
      });
    }
  } catch (e) {
    yield put({ type: GET_CAMPAIGN_FOLDER.FAILURE, data: e.response });
  }
}

function* removeGdtvFolder(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });
  try {
    const response = yield call(MediasService.deleteGdtvFolder, action.id);
    if (response.status === 200) {
      yield put({
        type: SHOW_TOAST.SUCCESS,
        message: action.successMessage,
        messageType: 'success',
      });
    }
  } catch (e) {
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
  }
  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
}

function* disableGdtvFolder(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });
  try {
    const response = yield call(MediasService.disableGdtvFolder, action.id);
    if (response.status === 200) {
      if (action.onSuccess) {
        action.onSuccess();
      }
      yield put({
        type: SHOW_TOAST.SUCCESS,
        message: action.successMessage,
        messageType: 'success',
      });
    }
  } catch (e) {
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
  }
  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
}

function* enableGdtvFolder(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });
  try {
    const response = yield call(MediasService.enableGdtvFolder, action.id);
    if (response.status === 200) {
      if (action.onSuccess) {
        action.onSuccess();
      }
      yield put({
        type: SHOW_TOAST.SUCCESS,
        message: action.successMessage,
        messageType: 'success',
      });
    }
  } catch (e) {
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
  }
  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
}

function* removeCampaignFolder(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });
  try {
    const response = yield call(MediasService.deleteGdtvFolder, action.id);
    if (response.status === 200) {
      yield put({
        type: SHOW_TOAST.SUCCESS,
        message: action.successMessage,
        messageType: 'success',
      });
    }
  } catch (e) {
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
  }
  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
}

function* editGdtvFolder(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });
  try {
    const response = yield call(MediasService.editGdtvFolder, action.id, action.body);

    if (response.status === 200) {
      yield put({ type: EDIT_GDTV_FOLDER.SUCCESS, data: action.body });
      yield put({
        type: SHOW_TOAST.SUCCESS,
        message: action.successMessage,
        messageType: 'success',
      });
    }
  } catch (e) {
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
  }
  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
}

function* editCampaignFolder(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });
  try {
    const response = yield call(MediasService.editGdtvFolder, action.id, action.body);

    if (response.status === 200) {
      yield put({ type: EDIT_CAMPAIGN_FOLDER.SUCCESS, data: action.body });
      yield put({
        type: SHOW_TOAST.SUCCESS,
        message: action.successMessage,
        messageType: 'success',
      });
    }
  } catch (e) {
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
  }
  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
}

function* getPosts(action) {
  try {
    const response = yield call(MediasService.getPosts, action.id, action.since, action.until);

    if (response.status === 200) {
      yield put({ type: GET_POSTS.SUCCESS, data: response.data });
    }
  } catch (e) {
    yield put({ type: GET_POSTS.FAILURE, data: e.response });
  }
}

function* getAds(action) {
  try {
    const response = yield call(MediasService.getAds, action.id, action.since, action.until);

    if (response.status === 200) {
      yield put({ type: GET_ADS.SUCCESS, data: response.data });
    }
  } catch (e) {
    yield put({ type: GET_ADS.FAILURE, data: e.response });
  }
}

function* getCampaignViews(action) {
  try {
    const response = yield call(MediasService.getCampaignViews, action.campaignId);
    if (response.status === 200) {
      yield put({ type: GET_CAMPAIGN_VIEWS.SUCCESS, data: response.data });
    }
  } catch (e) {
    yield put({ type: GET_CAMPAIGN_VIEWS.FAILURE, data: e.response });
  }
}

function* getGdtvViews(action) {
  try {
    const response = yield call(MediasService.getGdtvViews, action.directoryId);

    if (response.status === 200) {
      yield put({ type: GET_GDTV_VIEWS.SUCCESS, data: response.data });
    }
  } catch (e) {
    yield put({ type: GET_GDTV_VIEWS.FAILURE, data: e.response });
  }
}

function* putCampaignStatus(action) {
  try {
    const response = yield call(MediasService.putCampaignStatus, action.id, action.body);
    if (response.status === 200) {
      yield put({ type: PUT_CAMPAIGN_STATUS.SUCCESS });
    }
  } catch (e) {
    yield put({ type: PUT_CAMPAIGN_STATUS.FAILURE, data: e.response });
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
  }
}

function* postAdvertsStatus(action) {
  try {
    const response = yield call(MediasService.postAdvertsStatus, action.id, action.body);
    if (response.status === 200) {
      yield put({ type: POST_ADVERTS_STATUS.SUCCESS });
    }
  } catch (e) {
    yield put({ type: POST_ADVERTS_STATUS.FAILURE, data: e.response });
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
  }
}

function* postCampaignChangeStatus(action) {
  try {
    const response = yield call(
      MediasService.postCampaignChangeStatus,
      action.sourceId,
      action.facebookAdId,
      action.body
    );
    if (response.status === 200) {
      yield put({ type: POST_CAMPAIGN_CHANGE_STATUS.SUCCESS });
    }
  } catch (e) {
    yield put({ type: POST_CAMPAIGN_CHANGE_STATUS.FAILURE, data: e.response });
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
  }
}

export const mediasSaga = [
  takeEvery(GET_FOLDERS.REQUEST, getFolders),
  takeEvery(GET_GDTV_FOLDERS.REQUEST, getGdtvFolders),
  takeEvery(GET_CAMPAIGN_FOLDERS.REQUEST, getCampaignFolders),
  takeEvery(GET_FOLDER.REQUEST, getCreative),
  takeEvery(POST_FOLDER.REQUEST, postCreative),
  takeEvery(POST_MEDIA.REQUEST, postMedia),
  takeEvery(DELETE_MEDIA.REQUEST, deleteMedia),
  takeEvery(SET_CREATIVE_INFO.REQUEST, setCreativeInfo),
  takeEvery(REMOVE_CREATIVE.REQUEST, removeCreative),
  takeEvery(REMOVE_GDTV_FOLDER.REQUEST, removeGdtvFolder),
  takeEvery(DISABLE_GDTV_FOLDER.REQUEST, disableGdtvFolder),
  takeEvery(ENABLE_GDTV_FOLDER.REQUEST, enableGdtvFolder),
  takeEvery(REMOVE_CAMPAIGN_FOLDER.REQUEST, removeCampaignFolder),
  takeEvery(DOWNLOAD_MEDIA.REQUEST, downloadMedia),
  takeEvery(DOWNLOAD_GDTV_MEDIA.REQUEST, downloadGdtvMedia),
  takeEvery(DOWNLOAD_CAMPAIGN_MEDIA.REQUEST, downloadCampaignMedia),
  takeEvery(SHARE_TEMPLATE.REQUEST, shareTemplate),
  takeEvery(GET_GDTV_FOLDER.REQUEST, getGdtvFolder),
  takeEvery(EDIT_GDTV_FOLDER.REQUEST, editGdtvFolder),
  takeEvery(EDIT_CAMPAIGN_FOLDER.REQUEST, editCampaignFolder),
  takeEvery(GET_POSTS.REQUEST, getPosts),
  takeEvery(GET_ADS.REQUEST, getAds),
  takeEvery(GET_CAMPAIGN_FOLDER.REQUEST, getCampaignFolder),
  takeEvery(GET_CAMPAIGN_VIEWS.REQUEST, getCampaignViews),
  takeEvery(GET_GDTV_VIEWS.REQUEST, getGdtvViews),
  takeEvery(PUT_CAMPAIGN_STATUS.REQUEST, putCampaignStatus),
  takeEvery(POST_ADVERTS_STATUS.REQUEST, postAdvertsStatus),
  takeEvery(POST_CAMPAIGN_CHANGE_STATUS.REQUEST, postCampaignChangeStatus),
];
