// Api
import { ApiTenant } from '@utils/api';
import {getAttributionWindows} from "@utils/helpers";

class InsightsService {
  static async get(version, search) {
    let window = ''
    if(!search?.includes('&attribution_windows')){
      window = await getAttributionWindows()
    }

    return ApiTenant.get(`/insights/${version}${search}${window}`);
  }

  static async post(version, search, data) {
    const window = await getAttributionWindows()
    return ApiTenant.post(`/insights/${version}${search}${window}`, data);
  }

  static postListAll(version, search, data) {
    return ApiTenant.post(`/insights/${version}${search}`, data);
  }

  static getAccountIds(version, id, search, types, budgetId) {
    const history = budgetId ? `&budget_id=${budgetId}` : '';
    return ApiTenant.get(`/schema/${version}/${id}/children${search}&types=${types}${history}`);
  }

  static getAccountIdsChilds(version, id, search) {
    return ApiTenant.get(`/schema/${version}/${id}/ChildGroups${search}`);
  }

  static getAccountIdsUnitiesChilds(version, id, search) {
    return ApiTenant.get(`/schema/${version}/${id}/leaves${search}`);
  }

  static getAdAccounts(version, id, search) {
    return ApiTenant.get(`/schema/${version}/${id}/facebook/adaccounts${search}`);
  }

  static postListAccounts(version, search, data) {
    return ApiTenant.post(`/insights/${version}${search}`, data);
  }

  static getPages(version, id, search) {
    return ApiTenant.get(`/schema/${version}/${id}/facebook/fanpages${search}`);
  }

  static postListPages(version, search, data) {
    return ApiTenant.post(`/insights/${version}${search}`, data);
  }

  static postDataSummaries(version, search, data) {
    return ApiTenant.post(`/insights/${version}${search}`, data);
  }

  static getStrategies(version, id, search) {
    return ApiTenant.get(`/schema/${version}/${id}/strategies${search}`);
  }

  static postListStrategies(version, search, data) {
    return ApiTenant.post(`/insights/${version}/campaign${search}`, data);
  }

  static getBreakdown(version, search) {
    return ApiTenant.get(`/insights/${version}/breakdown${search}`);
  }

  static postBreakdown(version, search, data) {
    return ApiTenant.post(`/insights/${version}/breakdown${search}`, data);
  }

  static postRanking(version, search, data) {
    return ApiTenant.post(`/insights/${version}/ranking${search}`, data);
  }

  static postDataRanking(data) {
    return ApiTenant.post('/insights/1.1/ranking', data);
  }

  static postPageRating(version, data, period) {
    return ApiTenant.post(`/insights/${version}/Page/Rating?${period}&period=daily`, data);
  }

  // static getInstagramMetrics(search) {
  //     return ApiTenant.get(`/es-api/insights/ig/day${search}`);
  // }

  // static postInstagramMetricsPerPage(data) {
  //     return ApiTenant.post(`/es-api/insights/ig/day/pages`, data);
  // }

  static getReach(url, data) {
    return ApiTenant.post(`/es-api/insights/fb/adaccount/reach/${url}`, data);
  }

  static getDataChartRanking(data) {
    return ApiTenant.post('/insights/1.1/ranking/evolution', data);
  }

  static downloadLeads(version, id, search) {
    return ApiTenant.get(`/${version}/${id}/FormLead/Report/File${search}`);
  }

  static getOrganicLeads(id, search) {
    return ApiTenant.get(`/1.0/${id}/FormLead/LeadsCount${search}&lead_type=organic`);
  }

  static async getAllMetrics(id, startDate, endDate, timezoneOffset) {
    const window = await getAttributionWindows()

    return ApiTenant.get(
      `/insights/1.1/${id}?since=${startDate}&until=${endDate}&timezone_offset=${timezoneOffset}&fields=currency,impressions,page_post_engagements,page_video_views_organic,page_impressions_organic,video_view,post_engagement,spend,clicks,link_click,lead,onsite_conversion.lead_grouped,onsite_conversion.messaging_conversation_started_7d,onsite_conversion.messaging_conversation_started_7d_rate,offsite_conversion.fb_pixel_purchase,offline_conversion.purchase,offsite_conversion.fb_pixel_purchase_conversion_value,offline_conversion.purchase_conversion_value,post_reactions_organic${window}`
    );
  }

  static getSpend(id, startDate, endDate, timezoneOffset) {
    return ApiTenant.get(
      `/insights/1.1/${id}?since=${startDate}&until=${endDate}&timezone_offset=${timezoneOffset}&fields=spend`
    );
  }

  static getDailySpend(id, startDate, endDate, timezoneOffset) {
    return ApiTenant.get(
      `/insights/1.1/${id}?since=${startDate}&until=${endDate}&timezone_offset=${timezoneOffset}&fields=spend&period=daily`
    );
  }
}

export default InsightsService;
