import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { primaryTheme as theme } from '@styles/themes';

export const ButtonWrapper = styled('div')`
  padding: 0 ${theme.spacing.px40} ${theme.spacing.px32} ${theme.spacing.px40};
  margin-bottom: ${theme.spacing.px60};
`;

export const CloseFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

export const LabelStyled = styled('label')`
  color: ${({ disabled }) => (disabled ? 'black' : 'white')};
  background: ${({ disabled }) => (disabled ? '#f0f0f0' : '#2328eb')};
  border-radius: 8px;
  font-size: 17px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin-top: 0px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 120px;
  padding-left: 40px;
  padding-right: 40px;
  width: 70%;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const Thumbnail = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-top: 30px;
  overflow: hidden;
  position: relative;
  background: black;
  width: 384px;
  height: 500px;
`;

export const ZoomMedia = styled.div`
  width: 384px;
  height: 500px;
  display: block;
  overflow: clip;
  background: url(${(props) => props.src}) no-repeat scroll center center / cover;
  border-radius: 10px;
  @media (max-width: 720px) {
    min-height: 50%;
    width: auto;
  }
  @media (max-width: 1024px) {
    width: 50%;
  }
`;

export const InputStyled = styled('div')`
  display: none;
`;

export const UploadMultipleFiles = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h3 {
    color: ${theme.colors.blueGrey1};
  }
`;

export const Loading = styled.div`
  margin-bottom: 10px;
`;

export const UploadItem = styled('div')`
  width: ${theme.spacing.full};
  display: flex;
  align-items: center;
  padding: ${theme.spacing.px10} ${theme.spacing.px30} ${theme.spacing.px50};
  border-bottom: 1px solid ${theme.colors.grey2};
  box-sizing: border-box;
  svg:nth-child(1) {
    background: ${theme.colors.blueGrey1};
    color: white;
    padding: ${theme.spacing.px10};
    border-radius: ${theme.spacing.full};
  }
  p {
    margin: 0 ${theme.spacing.px15};
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const UploadItems = styled('div')`
  width: 100%;
  max-height: 37vh;
  overflow-y: scroll;
  h3 {
    text-align: center;
    margin: 0 0 ${theme.spacing.px20};
  }
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${theme.colors.white};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.blueGrey1};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const Wrapper = styled.div`
  ${({ theme, direction, justify, gap, width, align, color, marginTop }) => css`
    display: flex;
    flex-direction: ${direction || 'column'};
    justify-content: ${justify || 'center'};
    gap: ${gap || '16px'};
    width: ${width};
    align-items: ${align};
    color: ${color || '#999999'};
    margin-top: ${marginTop || ''};

    .wrap {
      flex-wrap: wrap;
    }
    .iHVOZD:checked + span {
      background: ${theme.colors.blueSecurity};
    }
    .title {
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      color: ${theme.colors.black2};
    }
    .inputsContainer {
      flex-direction: column;

      @media (min-width: ${theme.breakpoint.md}) {
        flex-direction: row;
      }
    }
    .form {
      align-self: baseline;
      width: 100%;

      @media (min-width: ${theme.breakpoint.md}) {
        max-width: 583px;
      }
    }
    .formNavigators {
      display: flex;
      margin-top: 62px;
      justify-content: end;
    }
    .image_result {
      height: 570px;
      background-color: ${theme.colors.blueGrey8};
    }
    .image_container {
      margin-top: 73px;

      border: 1px dashed ${theme.colors.grey};
      display: flex;
      justify-content: flex-start;

      &--required {
        display: flex;
        justify-content: flex-start;
        height: 280px;
        margin-top: 73px;
        border: 1px dashed ${theme.colors.red};
      }
      &-no-border {
        border: none;
        height: 520px;
      }
      &-content {
        padding: 24px 32px;
      }
      &-required {
        border: 1px dashed ${theme.colors.red};
      }
      .text {
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: ${theme.colors.blueGrey8};
      }
      .list {
        margin-bottom: 38px;
      }
    }

    .groupsContainer {
      @media (min-width: ${theme.breakpoint.lg}) {
        margin: 10px auto 0 auto;
        max-width: 1000px;
      }
    }

    .progressBarContainer {
      margin: 0 auto;
      width: 80%;
      max-width: 1600px;
    }
  `}
`;

export const Label = styled.div`
  ${({ theme }) => css`
    display: flex;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: ${theme.colors.blueGrey8};
    &.description {
      font-weight: normal;
      line-height: 24px;
      color: ${theme.colors.black2};
    }

    strong {
      margin: 0 ${theme.spacing.px4};
    }
  `}
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 8px;
  height: 100%;
  max-height: 400px;
  width: 100%;
  overflow-y: scroll;
  flex-wrap: wrap;
  max-width: 1000px;

  ${(props) => props.required && 'border: 1px dashed red;'};
`;

export const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  width: 100%;
  flex: 1;
  gap: 32px;
  &.tradePartners {
    > div {
      width: 100%;
    }
    p {
      color: #337ab7;
      cursor: pointer;
    }
    gap: 5px;

    flex-direction: column;
  }
`;

export const FormNavigators = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const DoubleInputFormRow = styled(FormRow)`
  ${({ theme }) => css`
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: ${theme.spacing.px8};
  `}
`;

export const SmallLabel = styled(Label)`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.px12};
    font-weight: ${theme.fontWeight.normal};
  `}
`;

export const List = styled.li``;

export const Button = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 321px;
    height: 48px;
    background: ${theme.colors.blueSecurity};
    border-radius: 8px;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: ${theme.colors.white};
    cursor: pointer;
  `}
`;

export const CancelButton = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: ${theme.colors.blueSecurity};
    cursor: pointer;
  `}
`;

export const NavigatorButton = styled.div`
  ${({ theme }) => css`
    width: 144px;
    height: 48px;
    display: flex;
    background: ${theme.colors.grey};
    color: ${theme.colors.black2};
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.finished {
      background: ${theme.colors.blueSecurity};
      color: ${theme.colors.white};
    }
  `}
`;
export const ButtonSubmit = styled.button`
  ${({ theme }) => css`
    width: 144px;
    height: 48px;
    display: flex;
    background: ${theme.colors.grey};
    color: ${theme.colors.black2};
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.finished {
      background: ${theme.colors.blueSecurity};
      color: ${theme.colors.white};
    }

    &:disabled {
      background: ${theme.colors.grey};
      color: ${theme.colors.red};
    }
  `}
`;

export const LabelWrapper = styled.div`
  ${({ theme }) => css`
    .optional {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      text-align: right;
      color: ${theme.colors.blueGrey8};
      text-transform: lowercase;
    }
    label {
      font-weight: ${theme.fontWeight.bold};
      font-size: 16px;
      line-height: 19px;
      color: ${theme.colors.black2};
      display: flex;
      align-items: flex-end;
      gap: 4px;
    }
  `}
`;

export const Categories = styled.div`
  margin-top: -15px;
`;

export const AddPartnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  input {
    width: 90%;
    height: 40px;
    border-radius: 8px;
    border: 1px solid gray;
    padding-left: 10px;
  }
  button {
    width: 100px;
    height: 35px;
    border-radius: 5px;
    cursor: pointer;
    background-color: #2328eb;
    border: none;
    color: #ffffff;
    &:disabled {
      background-color: #cccccc;
      cursor: not-allowed;
    }
  }
  p {
    text-align: center;
    font-size: 15px;
  }
  p:last-child {
    color: #337ab7;
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
  }
`;

export const DetailsContainer = styled('div')`
  border: 1px dashed #ccc;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  width: 40%;

  span.info {
    display: flex;
    align-items: flex-start;

    input,
    textarea {
      border-radius: 8px;
      border: 1px solid #ccc;
      outline: none;
      width: 100%;
    }
    input {
      height: 30px;
    }
  }
  &.submit {
    align-items: center;
    p {
      font-size: 20px;
    }

    button, a {
      border: none;
      border-radius: 8px;
      height: 40px;
      width: 180px;
      background: ${({ isCompleted }) => isCompleted && `#2328eb`};
      color: ${({ isCompleted }) => isCompleted && `#fff`};
      cursor: ${({ isCompleted }) => (isCompleted ? `pointer` : 'not-allowed')};;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      i {
        margin-left: 10px;
      }
  }
`;

export const Container = styled('div')`
  display: flex;
  gap: 30px;
  justify-content: space-around;
  padding-left: 30px;
  height: 580px;
`;
