import BFFApi from '@utils/bffApi';
import { ApiTenant } from '@utils/api';

const InstagramService = {
  getAccessToken(redirectURI, code) {
    const body = {
      redirect_uri: redirectURI,
      code: code,
    };
    return BFFApi.post('/api/v1/instagram', body);
  },
  getUserProfile(token) {
    return BFFApi.get(`/api/v1/instagram/user/profile?access_token=${token}`);
  },
  getAllUserMedias(token) {
    return BFFApi.get(`/api/v1/instagram/user/media?access_token=${token}`);
  },
  getMediaByID(token, mediaID) {
    return BFFApi.get(`/api/v1/instagram/user/media?access_token=${token}&id=${mediaID}`);
  },

  getListAccountsIG() {
    return ApiTenant.get('/setup/1.1/Instagram/BusinessAccounts?limit=2000');
  },

  postAccountsIG(igAccountId, unitId) {
    const body = {
      instagram_account_id: igAccountId,
      unit_id: unitId,
    };
    return ApiTenant.post('/setup/1.1/Instagram/BusinessAccounts', body);
  },

  deleteAccountIG(igAccountId, unitId) {
    const body = {
      instagram_account_id: igAccountId,
      unit_id: unitId,
    };
    return ApiTenant.delete('/setup/1.1/Instagram/BusinessAccounts',  body);
  },
};

export default InstagramService;
