import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NoScriptPage from '../NoScriptPage';
import * as S from '../styles';

const TermsListPage = ({ match, clicked, setClicked }) => {
  const [scriptSrc, setScriptSrc] = useState('');
  const [scriptJson, setScriptJson] = useState('');
  const [divId, setDivId] = useState('');
  const [noScript, setNoScript] = useState(false);
  const [button, setButton] = useState({});

  const scriptLoad = (json) => {
    OneTrust.NoticeApi.Initialized.then(function () {
      OneTrust.NoticeApi.LoadNotices([json]);
    });
  };

  useEffect(() => {
    if (clicked) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'otprivacy-notice-script';
      script.src = scriptSrc;
      script.innerText += `settings="eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vYm9ybmxvZ2ljLXByaXZhY3kubXkub25ldHJ1c3QuY29tL3JlcXVlc3QvdjEvcHJpdmFjeU5vdGljZXMvc3RhdHMvdmlld3MifQ=="`;
      script.onload = () => scriptLoad(scriptJson);

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [clicked]);

  const buttonsDataLeft = [
    {
      text: 'Política de Privacidade Bornlogic',
      script:
        'https://privacyportal-br-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js',
      json: 'https://privacyportal-br-cdn.onetrust.com/5c032e63-1671-40ec-b621-69257e160af7/privacy-notices/2caa55c2-fe4a-440c-ac6c-1cac42025272.json',
      divId: 'otnotice-2caa55c2-fe4a-440c-ac6c-1cac42025272',
    },
    {
      text: 'Termos de uso de Website',
      script:
        'https://privacyportal-br-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js',
      json: 'https://privacyportal-br-cdn.onetrust.com/5c032e63-1671-40ec-b621-69257e160af7/privacy-notices/94a806c6-c0ff-47fb-9e07-6ce39d4eee0e.json',
      divId: 'otnotice-94a806c6-c0ff-47fb-9e07-6ce39d4eee0e',
    },
    {
      text: 'Relacionamento com o titular',
      noScrpit: true,
      type: 'titular',
      innerText:
        'Após visitar os documentos acima, caso persista alguma dúvida e/ou questão relacionada à Privacidade e Proteção de Dados, entre em contato com a Bornlogic através do seu canal dedicado: lgpd@bornlogic.com.',
    },
    {
      text: 'Carta aos clientes',
      script:
        'https://privacyportal-br-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js',
      json: 'https://privacyportal-br-cdn.onetrust.com/5c032e63-1671-40ec-b621-69257e160af7/privacy-notices/48ae37f4-501a-45d8-8a35-376163c5360c.json',
      divId: 'otnotice-48ae37f4-501a-45d8-8a35-376163c5360c',
    },
  ];
  const buttonsDataRight = [
    {
      text: 'Políticas de Cookies',
      script:
        'https://privacyportal-br-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js',
      json: 'https://privacyportal-br-cdn.onetrust.com/5c032e63-1671-40ec-b621-69257e160af7/privacy-notices/2a643078-ec7f-4b8e-a485-61618464df98.json',
      divId: 'otnotice-2a643078-ec7f-4b8e-a485-61618464df98',
    },
    {
      text: 'Código de Ética e conduta',
      script:
        'https://privacyportal-br-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js',
      json: 'https://privacyportal-br-cdn.onetrust.com/5c032e63-1671-40ec-b621-69257e160af7/privacy-notices/9adff000-2d5e-4b0c-bec9-acd0f19a52d8.json',
      divId: 'otnotice-9adff000-2d5e-4b0c-bec9-acd0f19a52d8',
    },
    {
      text: 'Fale com o DPO',
      noScrpit: true,
      type: 'dpo',
    },
    {
      text: 'Material de adequação LGPD',
      download: true,
    },
  ];

  return (
    <>
      {clicked ? (
        <S.ClckedContainer>
          <S.BackButtonContainer className="flex-start">
            <S.BackButton
              onClick={() => {
                setClicked(false);
                setNoScript(false);
              }}
            >
              <FontAwesomeIcon icon={['far', 'chevron-left']} /> Voltar
            </S.BackButton>
          </S.BackButtonContainer>
          {noScript ? (
            <NoScriptPage button={button} />
          ) : (
            <div id={divId} className="otnotice"></div>
          )}
        </S.ClckedContainer>
      ) : (
        <>
          <S.TextContainer>
            <S.Title>Privacidade</S.Title>
            Prezado Visitante,
            <p>
              A Bornlogic é uma empresa inovadora, disruptiva e pioneira quanto ao universo de
              possibilidades e valor que oferece aos seus clientes e parceiros comerciais. Somos um
              “organismo vivo” em constante expansão, desenvolvimento e evolução, tudo isso ainda
              reforçando diariamente o compromisso de sempre pautar-se pela ética, transparência,
              lealdade e boa-fé objetiva, em seus mais amplos aspectos.
            </p>
            <p>
              Neste sentido, na esteira da construção do melhor relacionamento com a sociedade e o
              mercado, em conformidade com a Lei Geral de Proteção de Dados e as melhores práticas
              de governança preconizadas em diversos países do mundo que também se preocupam com
              essa temática, apresentamos aqui o nosso Portal de Privacidade.
            </p>
            <p>
              Nele o Visitante poderá encontrar documentos institucionais importantes que refletem o
              padrão ético e normativo adotado pela Bornlogic no que se refere à privacidade e
              proteção de dados pessoais, assim como um formulário de comunicação direta com o canal
              especial da organização para tratamento da matéria, no e-mail de contato:
              lgpd@bornlogic.com.
            </p>
            <p>
              Estamos e sempre estaremos à disposição dos nossos Visitantes para prestar todos os
              esclarecimentos necessários e trabalhar para realizar projetos memoráveis e cheios de
              propósito para os envolvidos e a nossa sociedade, integrando cada vez mais o mundo
              digital com o insubstituível “elemento humano”.
            </p>
          </S.TextContainer>

          <S.ButtonsContainer>
            <S.InnerButtonsContainer>
              {buttonsDataLeft.map((button, index) => (
                <S.StyledButton
                  onClick={() => {
                    if (button.noScrpit) {
                      setNoScript(true);
                      setButton(button);
                    } else {
                      setScriptSrc(button.script);
                      setScriptJson(button.json);
                      setDivId(button.divId);
                    }
                    setClicked(true);
                  }}
                  key={index}
                >
                  {button.text}
                </S.StyledButton>
              ))}
            </S.InnerButtonsContainer>
            <S.InnerButtonsContainer>
              {buttonsDataRight.map((button, index) => (
                <S.StyledButton
                  onClick={() => {
                    setClicked(true);
                    if (button.download) {
                      setClicked(false);
                      window.location.replace(
                        'https://bgdk8slrs.blob.core.windows.net/bornlogic/TreinamentoTermosUso/LGPD.zip'
                      );
                    } else if (button.noScrpit) {
                      setNoScript(true);
                      setButton(button);
                    } else {
                      setScriptSrc(button.script);
                      setScriptJson(button.json);
                      setDivId(button.divId);
                    }
                  }}
                  key={index}
                >
                  {button.text}
                </S.StyledButton>
              ))}
            </S.InnerButtonsContainer>
          </S.ButtonsContainer>
        </>
      )}
    </>
  );
};

export default TermsListPage;
