/* Modules */
import { call, put, takeEvery } from 'redux-saga/effects';

import { ActionsService, SchemaService } from '@services';

/* Types */
import {
  POST_COVER_PROFILE_IMAGE,
  GET_ALL_ACCOUNTS,
  GET_ALL_FANPAGES,
  GET_RECEIVED,
  SEND_ANSWER,
  DISAPPROVE_MEDIA,
  DISAPPROVE_MEDIA_MULT_IMG,
  APPROVE_MEDIA,
  APPROVE_MEDIA_MULT_IMG,
  EDIT_DESCRIPTION_MEDIA,
  UPDATE_MEDIA_MULT_IMG,
} from './types';
import { SHOW_TOAST } from '../toast/types';
import { IS_LOADING } from '../loading/types';

function* postCoverProfileImage(action) {
  const response = yield call(
    ActionsService.postCoverProfileImage,
    action.version,
    action.code,
    action.data
  );

  if (response.status === 200) {
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: 'Alteração realizado com sucesso!',
      messageType: 'success',
    });
  } else {
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: 'Houve um problema ao gravar, tente novamente mais tarde!',
      messageType: 'error',
    });
  }
  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
}

function* postReplicate(action) {
  let response;

  if (action.contentType === 'ads') {
    response = yield call(
      ActionsService.postReplicateAccounts,
      action.version,
      action.group_id,
      action.data
    );
  } else {
    response = yield call(
      ActionsService.postReplicateFanpages,
      action.version,
      action.group_id,
      action.data
    );
  }

  if (response.status === 200) {
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.successMessage,
      messageType: 'success',
    });
  } else {
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.errorMessage,
      messageType: 'error',
    });
  }
  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
}

function* postPromote(action) {
  let response;

  if (action.data.isStories) {
    response = yield call(
      ActionsService.promoteStories,
      action.version,
      action.group_id,
      action.data
    );
  } else if (action.data.isInstagram) {
    response = yield call(
      ActionsService.promoteInstagram,
      action.version,
      action.group_id,
      action.data
    );
  } else {
    response = yield call(
      ActionsService.postPromoteFanpages,
      action.version,
      action.group_id,
      action.data
    );
  }

  if (response.status === 200) {
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.successMessage,
      messageType: 'success',
    });
  } else {
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.errorMessage,
      messageType: 'error',
    });
  }
  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
}

function* getAllAccounts(action) {
  const response = yield call(SchemaService.getAdAccounts, '1.1', action.code);

  if (response.status === 200) {
    yield put({ type: GET_ALL_ACCOUNTS.SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_ALL_ACCOUNTS.FAILURE });
  }
}

function* getAllFanpages(action) {
  const response = yield call(SchemaService.getFanpages, '1.1', action.code, '?fields=name');

  if (response.status === 200) {
    yield put({ type: GET_ALL_FANPAGES.SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_ALL_FANPAGES.FAILURE });
  }
}

function* getReceiveds(action) {
  const response = yield call(SchemaService.getReceived, '1.1', action.code);

  if (response.status === 200) {
    yield put({ type: GET_RECEIVED.SUCCESS, data: response });
  } else {
  }
}

function* sendAnswer(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });
  const response = yield call(
    SchemaService.sendAnswer,
    '1.1',
    action.mediaId,
    action.answer,
    action.user.user_id
  );
  yield put({
    type: SEND_ANSWER.SUCCESS,
    mediaId: action.mediaId,
    answer: action.answer,
    user: action.user,
  });
  if (response.status === 200) {
  }
  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
}

function* disapproveMedia(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });

  let response;

  if (action.mediaType === 'VIDEO') {
    response = yield call(
      SchemaService.disapproveVideo,
      action.version,
      action.sourceId,
      action.body
    );
  } else {
    response = yield call(
      SchemaService.disapproveImage,
      action.version,
      action.sourceId,
      action.body
    );
  }

  if (response.status === 200) {
    yield put({ type: DISAPPROVE_MEDIA.SUCCESS, response: 'success' });
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.successMessage,
      messageType: 'success',
    });
  } else {
    yield put({ type: DISAPPROVE_MEDIA.FAILURE, response: 'failure' });
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.errorMessage,
      messageType: 'error',
    });
  }
  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
}

function* approveMedia(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });

  let response;

  if (action.mediaType === 'VIDEO') {
    response = yield call(SchemaService.approveVideo, action.version, action.sourceId, action.body);
  } else {
    response = yield call(SchemaService.approveImage, action.version, action.sourceId, action.body);
  }

  if (response.status === 200) {
    yield put({ type: APPROVE_MEDIA.SUCCESS, response: 'success' });
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.successMessage,
      messageType: 'success',
    });
  } else {
    yield put({ type: APPROVE_MEDIA.FAILURE, response: 'failure' });
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: response.data.error,
      messageType: 'error',
    });
  }
  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
}

function* approveMediaMultImg(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });

  const response = yield call(
    SchemaService.approveMultImg,
    action.version,
    action.sourceId,
    action.body
  );
  if (response.status === 200) {
    yield put({ type: APPROVE_MEDIA_MULT_IMG.SUCCESS, response: 'success' });
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.successMessage,
      messageType: 'success',
    });
  } else {
    yield put({ type: APPROVE_MEDIA_MULT_IMG.FAILURE, response: 'failure' });
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: response.data.error,
      messageType: 'error',
    });
  }
  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
}

function* updateMediaMultImg(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });

  const response = yield call(
    SchemaService.updateMultImg,
    action.version,
    action.sourceId,
    action.body
  );

  if (response.status === 200) {
    yield put({ type: UPDATE_MEDIA_MULT_IMG.SUCCESS, response: 'success' });
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.successMessage,
      messageType: 'success',
    });
  } else {
    yield put({ type: UPDATE_MEDIA_MULT_IMG.FAILURE, response: 'failure' });
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: response.data.error,
      messageType: 'error',
    });
  }
  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
}

function* editDescriptionMedia(action) {
  if (action.showLoading) {
    yield put({ type: IS_LOADING.SUCCESS, isLoading: true });
  }
  const response = yield call(
    SchemaService.changeDescriptionApprovals,
    action.version,
    action.permittedGroupId,
    action.mediaId,
    action.data
  );

  if (response.status === 200) {
    yield put({
      type: EDIT_DESCRIPTION_MEDIA.SUCCESS,
      status: response.status,
      mediaId: action.mediaId,
      newDescription: action.newDescription,
    });
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.successMessage,
      messageType: 'success',
    });
  } else {
    yield put({
      type: EDIT_DESCRIPTION_MEDIA.FAILURE,
      status: response.status,
      mediaId: action.mediaId,
    });

    const responseDataError = response.data.error;
    let { errorMessage } = action;

    if (responseDataError === 'MISSING_LINK') {
      errorMessage = 'O link de ação do anúncio é obrigatório para este objetivo de campanha';
    }

    if (responseDataError === 'INSUFFICIENT_THIRD_PARTY_BUDGET') {
      errorMessage = 'O orçamento é muito baixo para o período selecionado';
    }

    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: errorMessage,
      messageType: 'error',
    });
  }
  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
}

function* disapproveMediaMultImg(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });

  const response = yield call(
    SchemaService.disapproveMultImg,
    action.version,
    action.sourceId,
    action.body
  );

  if (response.status === 200) {
    yield put({ type: DISAPPROVE_MEDIA_MULT_IMG.SUCCESS, response: 'success' });
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.successMessage,
      messageType: 'success',
    });
  } else {
    yield put({ type: DISAPPROVE_MEDIA_MULT_IMG.FAILURE, response: 'failure' });
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: response.data.error,
      messageType: 'error',
    });
  }
  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });
}

export const actionsSaga = [
  takeEvery(POST_COVER_PROFILE_IMAGE.REQUEST, postCoverProfileImage),
  takeEvery(GET_ALL_ACCOUNTS.REQUEST, getAllAccounts),
  takeEvery(GET_ALL_FANPAGES.REQUEST, getAllFanpages),
  takeEvery(GET_RECEIVED.REQUEST, getReceiveds),
  takeEvery(SEND_ANSWER.REQUEST, sendAnswer),
  takeEvery(DISAPPROVE_MEDIA.REQUEST, disapproveMedia),
  takeEvery(DISAPPROVE_MEDIA_MULT_IMG.REQUEST, disapproveMediaMultImg),
  takeEvery(APPROVE_MEDIA.REQUEST, approveMedia),
  takeEvery(APPROVE_MEDIA_MULT_IMG.REQUEST, approveMediaMultImg),
  takeEvery(UPDATE_MEDIA_MULT_IMG.REQUEST, updateMediaMultImg),
  takeEvery(EDIT_DESCRIPTION_MEDIA.REQUEST, editDescriptionMedia),
];
