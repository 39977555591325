const WeekReportEN = {
  WeekReport: {
    since: 'Since',
    until: 'Unil',
    connected_units: 'Connected units',
    units_with_posts_or_ads: 'Units active',
    units_without_posts_and_ads: 'Units without posting',
    wau_mix: 'WAU (mix)',
    wau_percent: 'WAU %',
    ads: 'Ads',
    posts: 'Posts',
    content_created: 'Content created',
    budget_spent: 'Budget spent',
    impressions: 'Impressions',
    clicks: 'Clicks',
    link_click: 'Link clicked',
    engagements: 'Engagements',
    conversations_started: 'Started chats',
    online_sales: 'Online sales',
    online_recipe: 'Online recipe',
    total_fans: 'Total fans',
  },
};

const WeekReportPT = {
  WeekReport: {
    since: 'inicio',
    until: 'fim',
    connected_units: 'Unidades conectadas',
    units_with_posts_or_ads: 'Unidades ativas',
    units_without_posts_and_ads: 'Unidades inativas',
    wau_mix: 'WAU (mix)',
    wau_percent: 'WAU %',
    ads: 'Ads',
    posts: 'Posts',
    content_created: 'Conteúdos criados',
    budget_spent: 'Verba utilizada',
    impressions: 'Impressões',
    clicks: 'Clicks',
    link_click: 'Clicks no link',
    engagements: 'Engajamentos',
    conversations_started: 'Conversas iniciadas',
    online_sales: 'Vendas online',
    online_recipe: 'Receita online',
    total_fans: 'Total de fans',
  },
};

export { WeekReportEN, WeekReportPT };
