import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const AutoCompleteWrapper = styled.div`
  box-sizing: border-box;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.px20};
    margin-left: ${theme.spacing.px20};
  `}
`;

export const AutoCompleteBody = styled.div`
  ${({ theme, focused }) => css`
    ${focused
      ? css`
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.7);
          z-index: 2005;
          box-sizing: border-box;
          div {
            box-sizing: border-box;
            width: 100%;
            margin-top: 0;
          }
          svg[data-icon='search'],
          svg[data-icon='circle-notch'] {
            position: fixed;
            left: 100px;
            top: 35px;
            z-index: 2002;
          }
          input {
            width: 100%;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            height: 85px;
            border-radius: 0 !important;
            background: ${theme.colors.white};
            box-sizing: border-box;
            font-size: ${theme.fontSize.px18};
            padding: ${theme.spacing.px10} ${theme.spacing.px15} ${theme.spacing.px10} 140px;
            &:active,
            &:focus,
            &:focus-within {
              width: 100%;
              max-width: 100%;
            }
          }
          img {
            position: fixed;
            width: 45px;
            height: 45px;
            z-index: 2003;
            left: 30px;
            top: 22px;
          }
        `
      : css`
          img {
            display: none;
          }
        `}
  `}
`;

export const AutoCompleteContent = styled.div`
  ${({ focused }) => css`
    position: absolute;
    top: 85px;
    width: 210px;
    max-height: 50vh;
    overflow: auto;
    transition: all 0.5s;
    z-index: 100;
    right: 0;
    ${focused &&
    css`
      width: 400px;
    `}
  `}
`;

export const AutoCompleteResult = styled.ul`
  ${({ theme, options, focused, typed }) => css`
    list-style-type: none;
    padding-left: ${theme.spacing.none};
    margin: ${theme.spacing.none};
    background: ${theme.colors.white};
    overflow: hidden;
    border-radius: ${theme.rounded.none} ${theme.rounded.none} ${theme.rounded.px10}
      ${theme.rounded.px10};
    ${options?.length &&
    focused &&
    typed?.length &&
    css`
      border: 1px solid ${theme.colors.grey2};
      border-top: none;
    `}
  `}
`;

export const AutoCompleteLink = styled(Link)`
  display: block;

  ${({ theme }) => css`
    width: ${theme.spacing.full};
    padding: 13px ${theme.spacing.px15};
    font-size: ${theme.fontSize.px14};
    color: ${theme.colors.blueGrey3};
    text-decoration: none;
    transition: all 0.2s;
    box-sizing: border-box;
    text-transform: uppercase;
    &:hover,
    &:active,
    &:focus {
      background: ${theme.colors.green3};
      color: ${theme.colors.white};
      outline: none;
    }
  `}
`;

export const AutoCompleteSpan = styled.span`
  ${({ theme }) => css`
    display: block;
    width: ${theme.spacing.full};
    padding: 13px ${theme.spacing.px15};
    font-size: ${theme.fontSize.px14};
    color: ${theme.colors.blueGrey3};
    text-decoration: none;
    transition: all 0.2s;
    box-sizing: border-box;
    text-transform: uppercase;
  `}
`;

export const ButtonClose = styled.button`
  ${({ theme, focused }) => css`
    background: transparent;
    border: none;
    padding: none;
    ${focused
      ? css`
          position: fixed;
          top: 30px;
          right: 30px;
          opacity: 1;
          transition: all 0.5s;
          overflow: hidden;
          max-height: 99999px;
          cursor: pointer;
          outline: none;
          svg {
            font-size: ${theme.fontSize.px25};
            color: ${theme.colors.blueGrey3};
          }
        `
      : css`
          opacity: 0;
          max-height: 0;
        `}
  `}
`;
