/* Create action types */
import createActionTypes from '../createActionTypes';

export const GET_STEP = createActionTypes('GET_STEP');
export const UPDATE_EMAIL = createActionTypes('UPDATE_EMAIL');
export const GET_PAGES = createActionTypes('GET_PAGES');
export const SET_PAGE = createActionTypes('SET_PAGE');
export const UPDATE_INVITE_LINK = createActionTypes('UPDATE_INVITE_LINK');
export const GET_COMPANY_INFO = createActionTypes('GET_COMPANY_INFO');
export const GET_SUMMARY = createActionTypes('GET_SUMMARY');
export const GET_USERS = createActionTypes('GET_SUMMARY');
