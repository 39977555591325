const PaidPerformanceEN = {
  PaidPerformance: {
    CARD_TITLE: 'Paid Performance',
    CARD_SUBTITLE: 'A brief of your announces performance',
    CARD_BUTTON_TEXT: 'More details',
    IMPRESSIONS: 'Impressions',
    IMPRESSIONS_TOOLTIP:
      "How many times an ad was shown on the feed to your public. If it appears in different moments to the same person, another impression is counted. Be aware: for videos an impression is counted even if the video doesn't start.",
    CPM: "Cost per 1,000 impressions: calculates the price of 1,000 impressions in ads. It's calculated by the total amount spent, divided by the number of impressions, and multiplied by 1,000",
    VIDEO_VIEWS: 'Views',
    VIDEO_VIEWS_TOOLTIP: 'How many video views in ads, calculated only after 3 seconds.',
    CPV: "Cost per view: calculates the price of video views in ads. It's calculated by the total amount spent, divided by the number of views.",
    VR: "View Rate: calculates the percentage of views related to the number of times an ad was shown. It's calculated by the total video views, divided by the total impressions, and multiplied by 100.",
    CLICKS: 'Clicks',
    CLICKS_TOOLTIP: '',
    CPC: "Cost per link click: calculates the price of each link click your ad had. It's calculated by the total amount spent divided by the total of link clicks.",
    CTR: '',
    ENGAGEMENT: 'Engagement',
    ENGAGEMENT_TOOLTIP:
      'Indicates the sum of reactions, comments, shares, views, link clicks, and offer claim.',
    CPE: "Cost per engagement: calculates the investment for each engagement. It's calculated by the total amount spent divided by the number of engagements.",
    ER: "Engagement rate: calculates the percentage of people who engaged with your ads. It's calculated by the number of engagements, divided by impressions, and multiplied by 100.",
    STARTED_CONVERSATIONS: 'Started Conversations',
    STARTED_CONVERSATIONS_TOOLTIP:
      'Indicates the number of people who saw your content and clicked to start a conversation with your page via Whatsapp, Facebook or Instagram.',
    CPSC: "Cost per user who clicked to start a conversation with your business. It's calculated by the total cost divided by the number of conversations started.",
    CIR: '',
    SALES: 'Sales',
    SALES_TOOLTIP: '',
    REVENUE: 'Revenue',
    REVENUE_TOOLTIP: '',
    CPA: '',
    CR: '',
    LEADS: '',
    LEADS_TOOLTIP: '',
    CPCG: 'Quanto foi necessário investir para conseguir um único cadastro.',
    AVAILABLE_LEADS: '',
    AVAILABLE_LEADS_TOOLTIP: '',
    DOWNLOAD_SPREADSHEET: 'Download Spreadsheet',
    DOWNLOAD_DONE: 'Download completed!',
    DOWNLOAD_ERROR: 'Download error.',
    ROAS_TOOLTIP: 'Shows the total revenue divided by the total invested on the period.',
    WISH_LIST: 'Wishlist',
    WISH_LIST_TOOLIP: 'The metric is collected whenever someone saves, likes, or shows interest in an item in the product catalog.'
  },
};

const PaidPerformancePT = {
  PaidPerformance: {
    CARD_TITLE: 'Performance paga',
    CARD_SUBTITLE: 'Resumo do desempenho dos seus anúncios',
    CARD_BUTTON_TEXT: 'Mais detalhes',
    IMPRESSIONS: 'Impressões',
    IMPRESSIONS_TOOLTIP:
      'Mostra a quantidade de vezes que o anúncio foi exibido no feed para o público. Caso apareça em diferentes momentos para a mesma pessoa, mais uma impressão é contada. Mas atenção: para vídeos a impressão é contada mesmo sem o vídeo ser iniciado.',
    CPM: 'Custo por mil: calcula o preço a cada mil impressões de anúncio. Sua fórmula é: quanto já investiu, dividido pelo número de impressões e multiplicado por mil.',
    VIDEO_VIEWS: 'Visualizações',
    VIDEO_VIEWS_TOOLTIP:
      'Mostra a quantidade de visualizações em anúncios de vídeo, a partir de 3 segundos.',
    CPV: 'Custo por visualização: calcula o preço de cada visualização de vídeo anunciado. Sua fórmula é: quanto já investiu, dividido pelo número de visualizações.',
    VR: 'Taxa de Visualização: calcula a porcentagem de visualizações em relação à quantidade de vezes que o anúncio foi exibido. Sua fórmula é: visualizações dividido pelas impressões vezes 100.',
    CLICKS: 'Cliques',
    CLICKS_TOOLTIP:
      'O número de cliques em links dentro do anúncio que levaram a destinos especificados pelo anunciante, dentro ou fora do Facebook',
    CPC: 'Custo por clique no link: calcula o preço de cada clique no link em anúncios. Sua fórmula é: valor investido dividido por quantidade de cliques no link.',
    CTR: 'A porcentagem de vezes que as pessoas viram seu anúncio e deram um click',
    ENGAGEMENT: 'Engajamento',
    ENGAGEMENT_TOOLTIP:
      'Mostra a soma de reações, comentários e compartilhamentos, visualização de foto ou vídeo, clique em link ou pedido em ofertas.',
    CPE: 'Custo por engajamento: calcula o valor investido para cada engajamento. Sua fórmula é: valor investido dividido por engajamento.',
    ER: 'Taxa de engajamento: calcula a porcentagem de pessoas que engajaram com seu conteúdo. Sua fórmula é: número de engajamentos dividido por impressões, vezes 100.',
    STARTED_CONVERSATIONS: 'Conversas iniciadas',
    STARTED_CONVERSATIONS_TOOLTIP:
      'Mostra o número de pessoas que viram seu conteúdo e clicaram para iniciar uma conversa com a sua página por Whatsapp, Facebook ou Instagram.',
    CPSC: 'Custo médio por usuário que clicou para iniciar conversa com seu negócio. Sua fórmula é o custo total dividido pelo número de conversas iniciadas',
    CIR: 'Taxa de Conversas Iniciadas: Calcula o percentual de pessoas que enviaram mensagens para Facebook, Instagram ou Wpp. Sua fórmula é: Total de Conversas Iniciadas dividido pelo total de impressões, vezes 100. ',
    SALES: 'Vendas',
    SALES_TOOLTIP:
      'Mostra o número de vendas totais realizadas a partir dos anúncios em Gerentes Digitais. Ela contém compras tanto Offline quanto Online. ',
    REVENUE: 'Receita',
    REVENUE_TOOLTIP:
      'Mostra o valor total de receita gerado através dos anúncios em Gerentes Digitais. Esse valor contabiliza compras tanto Offline quanto Online. ',
    CPA: 'Mostra o custo médio que suas campanhas precisam para gerar cada venda. A fórmula é: Custo total investido dividido pelo total de vendas, vezes 100.',
    CR: 'É a taxa que calcula a porcentagem de pessoas que compraram através do seu conteúdo. Sua fórmula é: Número de vendas dividido pelo número de impressões, vezes 100.',
    LEADS: 'Cadastros gerados',
    LEADS_TOOLTIP: 'Quantidade de cadastros gerais',
    CPCG: 'Quanto foi necessário investir para conseguir um único cadastro.',
    AVAILABLE_LEADS: 'Cadastros disponíveis',
    AVAILABLE_LEADS_TOOLTIP:
      'Quantidade de cadastros gerados por páginas vinculadas ao gerentesdigitais desde o início do contrato',
    DOWNLOAD_SPREADSHEET: 'Baixar Planilha',
    DOWNLOAD_DONE: 'Seu download está feito!',
    DOWNLOAD_ERROR: 'Erro ao fazer o download.',
    ROAS_TOOLTIP: 'Mostra o cálculo do total de receita pelo total investido no período.',
    WISH_LIST: 'Lista de desejos',
    WISH_LIST_TOOLIP: 'A métrica é coletada toda vez que alguém salva, curte ou demonstra interesse em um item do catálogo de produtos.'

  },
};

const PaidPerformanceES = {
  PaidPerformance: {
    CARD_TITLE: 'Desempeño pagado',
    CARD_SUBTITLE: 'Resumen del rendimiento de sus anuncios',
    CARD_BUTTON_TEXT: 'Más detalles',
    IMPRESSIONS: 'Impresiones',
    IMPRESSIONS_TOOLTIP:
      'Muestra el número de veces que el anuncio se ha mostrado en el feed a la audiencia. Si aparece en diferentes momentos para la misma persona, se cuenta una impresión más. Pero cuidado: en el caso de los videos, la impresión se cuenta incluso sin que se haya iniciado el video.',
    CPM: 'Costo por mil: calcula el precio por mil impresiones de anuncios. Su fórmula es: cuánto has invertido ya, dividido por el número de impresiones y multiplicado por mil.',
    VIDEO_VIEWS: 'Visualizaciones',
    VIDEO_VIEWS_TOOLTIP:
      'Muestra la cantidad de vistas en los anuncios de video, comenzando en 3 segundos.',
    CPV: 'Costo por vista: calcula el precio de cada vista de video anunciada. Su fórmula es: cuánto ya ha invertido, dividido por el número de visitas.',
    VR: 'Tasa de vistas: calcula el porcentaje de vistas en relación con la cantidad de veces que se mostró el anuncio. Su fórmula es: vistas divididas por impresiones multiplicadas por 100.',
    CLICKS: 'Clics',
    CLICKS_TOOLTIP:
      'La cantidad de clics en enlaces dentro del anuncio que llevaron a destinos especificados por el anunciante, dentro o fuera de Facebook.',
    CPC: 'Costo por clic en enlaces: calcula el precio de cada clic en enlaces en los anuncios. Su fórmula es: cantidad invertida dividida por la cantidad de clics en el enlace.',
    CTR: 'El porcentaje de veces que las personas vieron su anuncio e hicieron clic',
    ENGAGEMENT: 'Involucramiento',
    ENGAGEMENT_TOOLTIP:
      'Muestra la suma de reacciones, comentarios y compartidos, vista de foto o video, clic en el enlace o un pedido en las ofertas.',
    CPE: 'Costo por artículo: calcula el monto invertido en cada artículo. Su fórmula es: monto invertido dividido por la carga.',
    ER: 'Tasa de interacción: calcula el porcentaje de personas que han interactuado con su contenido. Su fórmula es: número de interacciones dividido por impresiones multiplicado por 100.',
    STARTED_CONVERSATIONS: 'Conversaciones iniciada',
    STARTED_CONVERSATIONS_TOOLTIP:
      'Muestra la cantidad de personas que vieron su contenido e hicieron clic para iniciar una conversación con su página a través de Whatsapp, Facebook o Instagram.',
    CPSC: 'Costo promedio por usuario que hizo clic para iniciar una conversación con su empresa. Su fórmula es el costo total dividido por la cantidad de conversaciones iniciadas.',
    CIR: 'Tasa de conversación iniciada: Calcula el porcentaje de personas que han enviado mensajes a Facebook, Instagram o Wpp. Su fórmula es: Total de conversaciones iniciadas dividido por el total de impresiones multiplicado por 100.',
    SALES: 'Ventas',
    SALES_TOOLTIP:
      'Muestra el número de ventas totales realizadas a partir de anuncios en Gerentes Digitais. Contiene compras en online y off-line. ',
    REVENUE: 'Ingresos',
    REVENUE_TOOLTIP:
      'Muestra la cantidad total de ingresos generados a través de anuncios en Gerentes Digitais. Este valor cuenta tanto las compras online y off-line. ',
    CPA: 'Muestra el costo promedio que sus campañas necesitan para generar cada venta. La fórmula es: costo total invertido dividido por el total de ventas multiplicado por 100.',
    CR: 'Es la tasa que calcula el porcentaje de personas que han comprado a través de tu contenido. Su fórmula es: Número de ventas dividido por el número de impresiones multiplicado por 100.',
    LEADS: 'Registros generados',
    LEADS_TOOLTIP: 'Número de registros generales',
    CPCG: 'Cuánto fue necesario invertir para conseguir un único registro.',
    AVAILABLE_LEADS: 'Registros disponibles',
    AVAILABLE_LEADS_TOOLTIP:
      'Número de entradas generadas por páginas vinculadas a gerentesdigitais desde el inicio del contrato',
    DOWNLOAD_SPREADSHEET: 'Descargar hoja de cálculo',
    DOWNLOAD_DONE: '¡Tu descarga está lista!',
    DOWNLOAD_ERROR: 'Error al descargar.',
    ROAS_TOOLTIP:
      'Muestra el cálculo de los ingresos totales por el total invertido en el período.',
    WISH_LIST: 'Lista de deseos',
    WISH_LIST_TOOLIP: 'La métrica se recopila cada vez que alguien guarda, le gusta o muestra interés en un elemento del catálogo de productos.'

  },
};

export { PaidPerformanceEN, PaidPerformancePT, PaidPerformanceES };
