import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'react-translate';
import Container from '@components/Container';
import CounterBall from '@components/CounterBall';
import BreadCrumb from 'components/BreadCrumb';
import { getBusinessID } from '@utils/auth';
import { hasCapabilities, validateCapability, checkIfIsTradeView } from '@utils/helpers';
import { LISTING_PAGE_COUNT } from '@redux/generic/types';
import ButtonCalendar from './ButtonCalendar';
import ButtonMode from './ButtonMode';
import SizeSelect from '../Scope/Report/Ranking/SizeSelect';
import AccountsSelect from '../Scope/Report/Ranking/AccountsSelect';
import FeedSelect from '../Scope/MediasCenter/pages/Feed/FeedSelect';
import UsersFilters from '../Users/UsersFilters';
import UnitsFilters from '../Settings/Units/UnitsFilters';
import FeedButtons from '../Scope/MediasCenter/pages/Feed/FeedButtons';
import FeedChannels from 'containers/Scope/MediasCenter/pages/Feed/FeedChannels';
import BudgetHistory from '../Scope/Budget/BudgetHistory';
import * as S from './styles';
import { useCampaign } from 'utils/contexts';

import { Typography } from '@bornlogic/reborn';

function getStoreValues(state) {
  return {
    authenticationReducer: state.authenticationReducer,
    genericReducer: state.genericReducer,
    feedReducer: state.feedReducer,
    actionsReducer: state.actionsReducer,
    budgetReducer: state.budgetReducer,
  };
}

function Tabs({ showButtonCalendar }) {
  const [showGDTV, setShowGDTV] = useState(false);
  const t = useTranslate('Tabs');
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const match = useRouteMatch();
  const { params } = match;
  const {
    genericReducer: {
      exhibitionMode,
      period,
      listingPageCount: {
        data: { adAccountTotalCount, contentTotalCount, groupTotalCount },
      },
    },
    authenticationReducer,
    budgetReducer: { budgetHistory },
    feedReducer: { feedOrderBy },
    actionsReducer: {
      medias: { isLoading },
    },
  } = useSelector(getStoreValues);
  const {
    hasLastCampaign,
    hasCampaigns,
    campaigns: { isLoadingCampaigns },
  } = useCampaign();

  const showCIDetails = !isLoadingCampaigns || hasCampaigns || (!hasCampaigns && hasLastCampaign);
  const isTradeView = checkIfIsTradeView();
  const periodParams = period?.params || '';

  const urlFinalTag = useMemo(() => match?.path?.split('/').at(-1), [match.path]);

  useEffect(() => {
    if (hasCapabilities(authenticationReducer.businessTokenData)) {
      if (
        validateCapability(authenticationReducer.businessTokenData, 'GDTV_MANAGE') &&
        !isTradeView
      ) {
        setShowGDTV(true);
      }
    }
  }, [authenticationReducer.businessTokenData, isTradeView]);

  useEffect(() => {
    if (params.module === 'Listing') {
      dispatch({
        type: LISTING_PAGE_COUNT.REQUEST,
        version: '1.0',
        tenantId: getBusinessID(),
        unit: params.code,
      });
    }
  }, [dispatch, params.code, params.module]);

  function renderBreadCrumb(breadCrumbProps) {
    return (
      <S.LeftContent>
        <BreadCrumb redirectUrl={breadCrumbProps?.redirectUrl} />
      </S.LeftContent>
    );
  }

  return (
    <S.TabsWrapper>
      <Container>
        {pathname.indexOf('/Report') !== -1 && (
          <S.TabsScope>
            {renderBreadCrumb()}
            {params.module === 'Listing' && (
              <S.TabsStyled>
                <S.TabsContent>
                  <S.TabItem
                    to={{
                      pathname: `/${params.code}/Report/${params.module}/Groups`,
                      search,
                    }}
                    actived={params.group === 'Groups' ? 1 : 0}
                  >
                    <p> {t('GROUPS')} </p>
                    <CounterBall counter={groupTotalCount || 0} />
                  </S.TabItem>
                  <S.TabItem
                    to={{
                      pathname: `/${params.code}/Report/${params.module}/Accounts`,
                      search,
                    }}
                    actived={params.group === 'Accounts' ? 1 : 0}
                  >
                    <p> {t('ACCOUNTS')} </p>
                    <CounterBall counter={adAccountTotalCount || 0} />
                  </S.TabItem>
                  <S.TabItem
                    to={{
                      pathname: `/${params.code}/Report/${params.module}/Pages`,
                      search,
                    }}
                    actived={params.group === 'Pages' ? 1 : 0}
                  >
                    <p> {t('PAGES')} </p>
                    <CounterBall counter={contentTotalCount || 0} />
                  </S.TabItem>
                </S.TabsContent>
              </S.TabsStyled>
            )}
            {(params.module === 'Overview' ||
              params.module === 'Engagement' ||
              params.module === 'VideoView' ||
              params.module === 'Comments' ||
              params.module === 'Activity' ||
              params.module === 'Dashboard') && (
              <S.TabsStyled>
                <S.TabsContent />
              </S.TabsStyled>
            )}
            {params.module === 'Ranking' && (
              <S.TabsStyled>
                <S.TabsContent>
                  <S.TabItem
                    to={{
                      pathname: `/${params.code}/Report/${params.module}/Accounts`,
                      search,
                    }}
                    actived={params.group === 'Accounts' ? 1 : 0}
                  >
                    {t('ACCOUNTS')}
                  </S.TabItem>
                </S.TabsContent>
              </S.TabsStyled>
            )}
            {params.module === 'Audience' && (
              <S.TabsStyled>
                <S.TabsContent>
                  <S.TabItem
                    to={{
                      pathname: `/${params.code}/Report/${params.module}/Age&Gender`,
                      search,
                    }}
                    actived={params.group === 'Age&Gender' ? 1 : 0}
                  >
                    {t('AGE-GENDER')}
                  </S.TabItem>
                  <S.TabItem
                    to={{ pathname: `/${params.code}/Report/${params.module}/Channel`, search }}
                    actived={params.group === 'Channel' ? 1 : 0}
                  >
                    {t('CHANNEL')}
                  </S.TabItem>
                  <S.TabItem
                    to={{
                      pathname: `/${params.code}/Report/${params.module}/Device`,
                      search,
                    }}
                    actived={params.group === 'Device' ? 1 : 0}
                  >
                    {t('DEVICE')}
                  </S.TabItem>
                </S.TabsContent>
              </S.TabsStyled>
            )}
            <S.RightContent>
              {params.module === 'Ranking' && <SizeSelect />}
              {params.module === 'Ranking' && params.group === 'Accounts' && <AccountsSelect />}
              {params.module !== 'Dashboard' && showButtonCalendar && <ButtonCalendar />}
            </S.RightContent>
          </S.TabsScope>
        )}
        {pathname.indexOf('/Budget') !== -1 && (
          <S.TabsScope>
            {renderBreadCrumb()}
            {params.module === 'Listing' && (
              <>
                <S.TabsStyled>
                  <S.TabsContent>
                    <S.TabItem
                      to={{
                        pathname: `/${params.code}/Budget/${params.module}/Groups`,
                        search,
                      }}
                      actived={params.group === 'Groups' ? 1 : 0}
                    >
                      <p> {t('GROUPS')} </p>
                      <CounterBall counter={groupTotalCount || 0} />
                    </S.TabItem>
                    {!budgetHistory && (
                      <S.TabItem
                        to={{
                          pathname: `/${params.code}/Budget/${params.module}/Accounts`,
                          search,
                        }}
                        actived={params.group === 'Accounts' ? 1 : 0}
                      >
                        <p> {t('ACCOUNTS')} </p>
                        <CounterBall counter={adAccountTotalCount || 0} />
                      </S.TabItem>
                    )}
                  </S.TabsContent>
                </S.TabsStyled>
                <S.RightContent>{params.group === 'Groups' && <BudgetHistory />}</S.RightContent>
              </>
            )}
          </S.TabsScope>
        )}

        {pathname.indexOf('/Medias') !== -1 && (
          <>
            {params.module === 'Approvals' && (
              <S.TabsScope>
                {renderBreadCrumb()}
                <S.TabsStyled>
                  <S.TabsContent>
                    <S.TabItem
                      to={{
                        pathname: `/${params.code}/Medias/Approvals/New`,
                        search,
                      }}
                      actived={params.group === 'New' ? 1 : 0}
                      disabled={!!isLoading}
                    >
                      {t('NEW')}
                    </S.TabItem>
                    <S.TabItem
                      to={{
                        pathname: `/${params.code}/Medias/Approvals/Approved`,
                        search,
                      }}
                      actived={params.group === 'Approved' ? 1 : 0}
                      disabled={!!isLoading}
                    >
                      {t('APPROVED')}
                    </S.TabItem>
                    <S.TabItem
                      to={{
                        pathname: `/${params.code}/Medias/Approvals/Disapproved`,
                        search,
                      }}
                      actived={params.group === 'Disapproved' ? 1 : 0}
                      disabled={!!isLoading}
                    >
                      {t('DISAPPROVED')}
                    </S.TabItem>
                  </S.TabsContent>
                </S.TabsStyled>
                <S.RightContent>
                  {showButtonCalendar && params.group !== 'New' && <ButtonCalendar />}
                </S.RightContent>
              </S.TabsScope>
            )}

            {params.module === 'Feed' && (
              <S.TabsScope>
                {renderBreadCrumb()}
                <S.TabsStyled>
                  <FeedButtons />
                </S.TabsStyled>
                <S.RightContent>
                  {exhibitionMode != 'list' && (
                    <>
                      <FeedChannels />
                      <FeedSelect />
                    </>
                  )}
                  {feedOrderBy === 'created_time' && showButtonCalendar && <ButtonCalendar />}
                  <ButtonMode url={`/${params.code}/Medias/Feed`} />
                </S.RightContent>
              </S.TabsScope>
            )}
          </>
        )}

        {pathname.indexOf('/Communication') !== -1 && (
          <S.TabsScope>
            {!isTradeView && renderBreadCrumb()}
            <S.TabsStyled>
              <S.TabsContent>
                {/* DESABILITADO TEMPORARIAMENTE DEVIDO INICIO DA UNIÃO ENTRE AS CAMPANHAS DE INCENTIVO*/}
                {/*{showTRADEADS && (*/}
                {/*  <S.TabItem*/}
                {/*    to={{*/}
                {/*      pathname: `/${params.code}/Communication/TradeAds`,*/}
                {/*      search,*/}
                {/*    }}*/}
                {/*    actived={params.module === 'TradeAds' ? 1 : 0}*/}
                {/*    onClick={() => logEvent('click_campaign_trade_ads_tab')}*/}
                {/*  >*/}
                {/*    {t('TRADEADS')}*/}
                {/*  </S.TabItem>*/}
                {/*)}*/}
                {showGDTV && (
                  <S.TabItem
                    to={{
                      pathname: `/${params.code}/Communication/GDTV`,
                      search,
                    }}
                    actived={params.module === 'GDTV' ? 1 : 0}
                  >
                    {t('GDTV')}
                  </S.TabItem>
                )}
                {!isTradeView && (
                  <>
                    <S.TabItem
                      to={{
                        pathname: `/${params.code}/Communication/Library`,
                        search,
                      }}
                      actived={params.module === 'Library' ? 1 : 0}
                    >
                      {t('LIBRARY')}
                    </S.TabItem>
                  </>
                )}
              </S.TabsContent>
            </S.TabsStyled>
            <S.RightContent>{showButtonCalendar && <ButtonCalendar />}</S.RightContent>
          </S.TabsScope>
        )}
        {pathname.indexOf('/MediaItem') !== -1 && (
          <S.TabsScope>
            {!isTradeView &&
              renderBreadCrumb({
                redirectUrl: 'Communication/Library',
              })}
            <S.TabsStyled>
              <S.TabsContent>
                {showGDTV && (
                  <S.TabItem
                    to={{
                      pathname: `/${params.code}/Communication/GDTV`,
                      search,
                    }}
                    actived={0}
                  >
                    {t('GDTV')}
                  </S.TabItem>
                )}
                {!isTradeView && (
                  <S.TabItem
                    to={{
                      pathname: `/${params.code}/Communication/Library`,
                      search,
                    }}
                    actived={1}
                  >
                    {t('LIBRARY')}
                  </S.TabItem>
                )}
              </S.TabsContent>
            </S.TabsStyled>
            <S.RightContent />
          </S.TabsScope>
        )}
        {pathname.indexOf('/GDTVItem') !== -1 && (
          <S.TabsScope>
            {!isTradeView &&
              renderBreadCrumb({
                redirectUrl: 'Communication/GDTV',
              })}
            <S.TabsStyled>
              <S.TabsContent>
                {showGDTV && (
                  <S.TabItem
                    to={{
                      pathname: `/${params.code}/Communication/GDTV`,
                      search,
                    }}
                    actived={1}
                  >
                    {t('GDTV')}
                  </S.TabItem>
                )}
                {!isTradeView && (
                  <>
                    <S.TabItem
                      to={{
                        pathname: `/${params.code}/Communication/Library`,
                        search,
                      }}
                      actived={0}
                    >
                      {t('LIBRARY')}
                    </S.TabItem>
                  </>
                )}
              </S.TabsContent>
            </S.TabsStyled>
            <S.RightContent />
          </S.TabsScope>
        )}
        {pathname.indexOf('/CampaignItem') !== -1 && (
          <S.TabsScope>
            {!isTradeView &&
              renderBreadCrumb({
                redirectUrl: `CI${periodParams && `?${periodParams}`}`,
              })}
            <S.TabsStyled></S.TabsStyled>
            <S.RightContent>{showButtonCalendar && <ButtonCalendar />}</S.RightContent>
          </S.TabsScope>
        )}
        {pathname.indexOf('/Users') !== -1 && (
          <S.TabsScope>
            {renderBreadCrumb()}
            <S.TabsStyled />
            <S.RightContent>
              <UsersFilters />
            </S.RightContent>
          </S.TabsScope>
        )}
        {pathname.indexOf('/Settings') !== -1 && (
          <S.TabsScope>
            {renderBreadCrumb()}
            {(params.module === 'Units' || params.module === 'Groups') && (
              <>
                <S.TabsStyled>
                  <S.TabItem
                    actived={params.module === 'Units' ? 1 : 0}
                    to={{
                      pathname: `/${params.code}/Settings/Units`,
                    }}
                  >
                    {t('UNITS')}
                  </S.TabItem>
                  <S.TabItem
                    actived={params.module === 'Groups' ? 1 : 0}
                    to={{
                      pathname: `/${params.code}/Settings/Groups`,
                    }}
                  >
                    {t('GROUPS')}
                  </S.TabItem>
                </S.TabsStyled>
                {params.module === 'Groups' ? (
                  <S.RightContent>{showButtonCalendar && <ButtonCalendar />}</S.RightContent>
                ) : params.module === 'Units' ? (
                  <S.RightContent>
                    <UnitsFilters />
                  </S.RightContent>
                ) : (
                  <S.RightContent />
                )}
              </>
            )}
            {params.module === 'UserTable' && (
              <>
                <S.TabsStyled />
                <S.RightContent>
                  <UsersFilters />
                </S.RightContent>
              </>
            )}
            {params.module === 'users_management' && (
              <>
                <S.TabsStyled />
                <S.RightContent>
                  <UsersFilters />
                </S.RightContent>
              </>
            )}
            {params.module === 'EasyAccess' && (
              <>
                <S.TabsStyled />
                <S.RightContent />
              </>
            )}
            {params.module === 'Company' && (
              <>
                <S.TabsStyled />
                <S.RightContent />
              </>
            )}
          </S.TabsScope>
        )}
        {pathname.indexOf('/Trends') !== -1 && (
          <S.TabsScope>
            <S.TabsStyled />
            <S.RightContent>{showButtonCalendar && <ButtonCalendar />}</S.RightContent>
          </S.TabsScope>
        )}
        {pathname.indexOf('/CI') !== -1 && (
          <S.TabsScope>
            {!isTradeView && renderBreadCrumb()}
            {showCIDetails && (
              <S.TabsStyled>
                <S.TabItem
                  actived={urlFinalTag === 'CI' ? 1 : 0}
                  to={{
                    pathname: `/${params.code}/CI`,
                  }}
                >
                  <Typography variant="paragraph2">{t('LIST')}</Typography>
                </S.TabItem>
                <S.TabItem
                  actived={urlFinalTag === 'Results' ? 1 : 0}
                  to={{
                    pathname: `/${params.code}/CI/Results`,
                  }}
                >
                  <Typography variant="paragraph2">{t('RESULTS')}</Typography>
                </S.TabItem>
              </S.TabsStyled>
            )}
            <S.RightContent>
              {showButtonCalendar && showCIDetails && <ButtonCalendar />}
            </S.RightContent>
          </S.TabsScope>
        )}
      </Container>
    </S.TabsWrapper>
  );
}

export default Tabs;
