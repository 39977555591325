/* Modules */
import { call, put, takeEvery } from 'redux-saga/effects';
import dayjs from 'dayjs';

// Services
import { InsightsService, ReportService, SchemaService } from '../../services';

// Utils
import { cpm } from '../../utils/businessRule';
import { getAttributionWindows, getTimezone } from '@utils/helpers';

/* Types */
import {
  CHANGE_GROUP_COLOR,
  CREATE_GROUP,
  DELETE_GROUP,
  FETCH_CAMPAIGN_HEALTH_INSTAGRAM,
  FETCH_ITEM_CAMPAIGN_HEALTH_INSTAGRAM,
  GET_ACTIVITIES_GROUPED_BY_DAY,
  GET_ACTIVITY_METRICS,
  GET_AUDIENCE_AGE_GENDER,
  GET_AUDIENCE_CHANNEL,
  GET_AUDIENCE_DEVICE,
  GET_BUDGET_METRICS,
  GET_CAMPAIGN_HEALTH,
  GET_CHART_RANKING,
  GET_COMMENTS_GRAPH,
  GET_COMMENTS_RANK,
  GET_COMMENTS_RANK_EVOLUTION,
  GET_COMMENTS_SUMMARY,
  GET_CONVERSION_FLOW,
  GET_GROUP_COLORS,
  GET_INFO_GROUPS,
  GET_LIST_ALL_ACCOUNTS,
  GET_LIST_ALL_GROUPS,
  GET_LIST_ALL_PAGES,
  GET_RANKING,
  GET_RANKING_ACCOUNTS,
  GET_RANKING_GROUPS,
  GET_RANKING_PAGES,
  GET_SUMMARY,
  MOVE_GROUP,
  POST_ENGAGEMENT_BEST,
  POST_INSIGHTS_METRICS,
  POST_PURCHASE_BEST,
  POST_REPORT_AD,
  POST_REPORT_AD_EVOLUTION,
  POST_REPORT_PAGE_EVOLUTION_FACEBOOK,
  POST_REPORT_PAGE_EVOLUTION_INSTAGRAM,
  POST_REPORT_PAGE_FACEBOOK,
  POST_REPORT_PAGE_INSTAGRAM,
  RENAME_GROUP,
  REPLACE_ACCOUNT,
  REPLACE_PAGE,
} from './types';
import { SHOW_TOAST } from '../toast/types';
import { IS_LOADING } from '../loading/types';
import { RECOUNT_LISTING_GROUP_TOTAL } from '../generic/types';

// Utils

function* getCommentsSummary(action) {
  const response = yield call(ReportService.getCommentsSummary, action.data);
  if (response.status === 200) {
    yield put({ type: GET_COMMENTS_SUMMARY.SUCCESS, commentsSummary: response.data });
  } else {
    yield put({ type: GET_COMMENTS_SUMMARY.FAILURE, commentsSummaryError: response.data.error });
  }
}

function* getCommentsGraph(action) {
  const response = yield call(ReportService.getCommentsGraph, action.data);
  if (response.status === 200) {
    yield put({ type: GET_COMMENTS_GRAPH.SUCCESS, commentsGraph: response.data });
  } else {
    yield put({ type: GET_COMMENTS_GRAPH.FAILURE, commentsGraphError: response.data.error });
  }
}

function* getCommentsRank(action) {
  const response = yield call(ReportService.getCommentsRank, action.data);
  if (response.status === 200) {
    yield put({ type: GET_COMMENTS_RANK.SUCCESS, commentsRank: response.data });
  } else {
    yield put({ type: GET_COMMENTS_RANK.FAILURE, commentsRankError: response.data.error });
  }
}

function* getCommentsRankEvolution(action) {
  const response = yield call(ReportService.getCommentsRankEvolution, action.data);
  if (response.status === 200) {
    const metricsData = response.data.map((item) => item.metric);

    const changedData = response.data.find(
      (x) => x.metric === action.data.metric.toLowerCase()
    ).data;
    yield put({
      type: GET_COMMENTS_RANK_EVOLUTION.SUCCESS,
      chart: changedData,
      charts: response.data,
      metrics: metricsData,
      id: action.data.group,
    });
  } else {
    yield put({
      type: GET_COMMENTS_RANK_EVOLUTION.FAILURE,
      chartError: response.data,
    });
  }
}

function* getCampaignHealth(action) {
  const arrayWillBeSend = [
    {
      grouped_by: action.code,
      name: '',
      score: 3.2,
      score_count: 0,
      spend: 0,
      currency: 'BRL',
      source_ids: [],
      budget: {
        total: 0,
        remaining: 0,
        allocated: 0,
        avaliable: 0,
        business_budget_id: '',
      },
    },
  ];

  const current = yield call(SchemaService.current, '1.1');
  const currentItem =
    action.code !== 'root' ? yield call(SchemaService.getCodeInformation, '1.1', action.code) : {};

  let reachResponse;
  if (action.reachDate) {
    const account = yield call(
      InsightsService.getAccountIds,
      '1.1',
      action.code,
      '?fields=id,name,type,ad_account_ids',
      '["group"]'
    );

    let url = 'monthly';
    const date = action.reachDate;
    if (date.substring(0, 1) !== '2') {
      // Valida se é data ou texto
      url = date.toLowerCase();
    }

    const reachBody = {
      date,
      grouping: [],
    };

    account.data.forEach((item) => {
      reachBody.grouping.push({
        grouped_by: item.source_id,
        source_ids: item.ad_account_ids,
      });
    });
    reachResponse = yield call(InsightsService.getReach, url, reachBody);
  }

  if (current.status === 200 || currentItem.status === 200) {
    const attributionWindows = yield call(getAttributionWindows);

    if (action.code === 'root') {
      const urlInsightCall = `?${
        action.period.params
      }&timezone_offset=${getTimezone()}&fields=offsite_conversion.fb_pixel_purchase_conversion_value,offline_conversion.purchase,offline_conversion.purchase_conversion_value,currency,impressions,video_view,post_engagement,spend,clicks,page_video_views_organic,page_impressions_organic,page_post_engagements,page_actions_post_reactions_like_total,page_actions_post_reactions_love_total,page_actions_post_reactions_wow_total,page_actions_post_reactions_haha_total,page_actions_post_reactions_sorry_total,page_actions_post_reactions_anger_total,link_click,offsite_conversion.fb_pixel_purchase,summaries,lead,onsite_conversion.lead_grouped,onsite_conversion.messaging_conversation_started_7d${attributionWindows}`;
      const response = yield call(InsightsService.get, '1.1', urlInsightCall);

      if (response.status === 200) {
        yield put({
          type: GET_CAMPAIGN_HEALTH.SUCCESS,
          data: response.data,
          status: response.status,
          period: action.period,
          current: current.data,
          currentItem: currentItem.data,
          code: action.code,
          reach: reachResponse && reachResponse.data.data,
        });
      } else {
        yield put({
          type: GET_CAMPAIGN_HEALTH.FAILURE,
          message: 'Response error',
          status: response.status,
        });
      }
    } else {
      const response = yield call(
        InsightsService.post,
        '1.1',
        `?${
          action.period.params
        }&timezone_offset=${getTimezone()}&fields=offsite_conversion.fb_pixel_purchase_conversion_value,offline_conversion.purchase,offline_conversion.purchase_conversion_value,currency,impressions,video_view,post_engagement,spend,clicks,page_video_views_organic,page_impressions_organic,page_post_engagements,page_actions_post_reactions_like_total,page_actions_post_reactions_love_total,page_actions_post_reactions_wow_total,page_actions_post_reactions_haha_total,page_actions_post_reactions_sorry_total,page_actions_post_reactions_anger_total,link_click,offsite_conversion.fb_pixel_purchase,summaries,lead,onsite_conversion.messaging_conversation_started_7d,onsite_conversion.lead_grouped&`,
        arrayWillBeSend
      );

      if (response.status === 200) {
        yield put({
          type: GET_CAMPAIGN_HEALTH.SUCCESS,
          data: response.data,
          status: response.status,
          period: action.period,
          current: current.data,
          currentItem: currentItem.data,
          code: action.code,
          reach: reachResponse && reachResponse.data.data,
        });
      } else {
        yield put({
          type: GET_CAMPAIGN_HEALTH.FAILURE,
          message: 'Response error',
          status: response.status,
        });
      }
    }
  }
}

function* getConversionFlow(action) {
  const fanpageAccount = [
    {
      grouped_by: action.code,
    },
  ];

  if (fanpageAccount) {
    let response;

    if (action.code === 'root') {
      response = yield call(
        InsightsService.get,
        '1.1',
        `?${
          action.period.params
        }&timezone_offset=${getTimezone()}&fields=currency,impressions,video_view,post_engagement,spend,clicks,page_video_views_organic,page_impressions_organic,page_post_engagements,page_actions_post_reactions_like_total,page_actions_post_reactions_love_total,page_actions_post_reactions_wow_total,page_actions_post_reactions_haha_total,page_actions_post_reactions_sorry_total,page_actions_post_reactions_anger_total,link_click,offsite_conversion.fb_pixel_purchase,summaries,offsite_conversion.fb_pixel_view_content,offsite_conversion.fb_pixel_add_to_cart,offsite_conversion.fb_pixel_complete_registration,lead&`
      );

      if (response.status === 200) {
        yield put({
          type: GET_CONVERSION_FLOW.SUCCESS,
          data: response.data,
          status: response.status,
          code: action.code,
        });
      } else {
        yield put({
          type: GET_CONVERSION_FLOW.FAILURE,
          message: 'Response error',
          status: response.status,
        });
      }
    } else {
      response = yield call(
        InsightsService.postDataSummaries,
        '1.1',
        `?${
          action.period.params
        }&timezone_offset=${getTimezone()}&fields=currency,impressions,video_view,post_engagement,spend,clicks,page_video_views_organic,page_impressions_organic,page_post_engagements,page_actions_post_reactions_like_total,page_actions_post_reactions_love_total,page_actions_post_reactions_wow_total,page_actions_post_reactions_haha_total,page_actions_post_reactions_sorry_total,page_actions_post_reactions_anger_total,link_click,offsite_conversion.fb_pixel_purchase,summaries,offsite_conversion.fb_pixel_view_content,offsite_conversion.fb_pixel_add_to_cart,offsite_conversion.fb_pixel_complete_registration,lead&`,
        fanpageAccount
      );

      if (response.status === 200) {
        yield put({
          type: GET_CONVERSION_FLOW.SUCCESS,
          data: response.data.data,
          summaries: response.data.summaries[0],
          status: response.status,
          code: action.code,
        });
      } else {
        yield put({
          type: GET_CONVERSION_FLOW.FAILURE,
          message: 'Response error',
          status: response.status,
        });
      }
    }
  } else {
    yield put({
      type: GET_CONVERSION_FLOW.FAILURE,
      message: 'Response error',
      status: 404,
    });
  }
}

function* getListAllGroups(action) {
  const account = yield call(
    InsightsService.getAccountIds,
    '1.1',
    action.code,
    `?fields=id,name,type,color,${action.reachDate ? 'ad_account_ids' : ''}`,
    '["group"]'
  );

  if (account.status === 200) {
    if (account.data.length) {
      const handleResponse = [];
      const metrics = [
        {
          name: 'impressions',
          value: 0,
          cost_label: 'CPM',
          cost: 0,
        },
        {
          name: 'video_view',
          value: 0,
          cost_label: 'CPV',
          cost: 0,
        },
        {
          name: 'post_engagement',
          value: 0,
          cost_label: 'CPE',
          cost: 0,
        },
        {
          name: 'engagement_rate',
          value: 0,
          cost_label: 'ER',
          cost: 0,
        },
        {
          name: 'conversion_rate',
          value: 0,
          cost_label: 'CR',
          cost: 0,
        },
        {
          name: 'cost_per_acquisition',
          value: 0,
          cost_label: 'CPA',
          cost: 0,
        },
      ];

      account.data.forEach((item) => {
        handleResponse.push({
          currency: 'BRL',
          grouped_by: item.source_id || '',
          name: item.name || '',
          source_ids: [],
          spend: 0,
          type: item.type || '',
          metrics,
          color: item.color,
        });
      });

      let reach = [];
      let reachResponse;

      if (action.reachDate) {
        let url = 'monthly';
        const date = action.reachDate;
        if (date.substring(0, 1) !== '2') {
          // Valida se é data ou texto
          url = date.toLowerCase();
        }

        const reachBody = {
          date,
          grouping: [],
        };

        account.data.forEach((item) => {
          reachBody.grouping.push({
            grouped_by: item.source_id,
            source_ids: item.ad_account_ids,
          });
        });

        reachResponse = yield call(InsightsService.getReach, url, reachBody);
        reach = reachResponse.data && reachResponse.data.data;
      }

      if (handleResponse.length) {
        const attributionWindows = yield call(getAttributionWindows);

        const response = yield call(
          InsightsService.postListAll,
          '1.1',
          `?${
            action.period.params
          }&timezone_offset=${getTimezone()}&fields=currency,impressions,video_view,post_engagement,spend,clicks,page_video_views_organic,page_impressions_organic,page_post_engagements,page_actions_post_reactions_like_total,page_actions_post_reactions_love_total,page_actions_post_reactions_wow_total,page_actions_post_reactions_haha_total,page_actions_post_reactions_sorry_total,page_actions_post_reactions_anger_total,offsite_conversion.fb_pixel_purchase,offsite_conversion.fb_pixel_purchase_rate,offsite_conversion.fb_pixel_purchase_rate_total,cost_per_offsite_conversion.purchase,cost_per_offsite_conversion.fb_pixel_purchase_total,offsite_conversion.fb_pixel_purchase_conversion_value,summaries,link_click,onsite_conversion.messaging_conversation_started_7d,onsite_conversion.messaging_first_reply,offline_conversion.initiate_checkout,offline_conversion.initiate_checkout_conversion_value,offline_conversion.purchase,offline_conversion.purchase_conversion_value${attributionWindows}`,
          handleResponse
        );

        if (response.status === 200) {
          yield put({
            type: GET_LIST_ALL_GROUPS.SUCCESS,
            reach,
            data: response.data.data,
            account: account.data,
            summaries: response.data.summaries[0],
            status: response.status,
          });
        } else {
          yield put({
            type: GET_LIST_ALL_GROUPS.FAILURE,
            message: 'Response error',
            status: response.status,
          });
        }
      } else {
        yield put({
          type: GET_LIST_ALL_GROUPS.FAILURE,
          message: 'Response error',
          status: 404,
        });
      }
    } else {
      yield put({
        type: GET_LIST_ALL_GROUPS.SUCCESS,
        data: [],
        account: [],
        summaries: {},
        status: 200,
      });
    }
  } else {
    yield put({
      type: GET_LIST_ALL_GROUPS.FAILURE,
      message: 'Response error',
      status: 404,
    });
  }
}

// Utilizado em listagem geral / Reports
function* getListAllAccounts(action) {
  const account = yield call(
    InsightsService.getAdAccounts,
    '1.1',
    action.code,
    `?fields=id,name,type,${action.reachDate ? 'ad_account_ids&' : ''}`
  );

  if (account.status === 200) {
    if (account.data.length) {
      const handleResponse = [];
      const metrics = [
        {
          name: 'impressions',
          value: 0,
          cost_label: 'CPM',
          cost: 0,
        },
        {
          name: 'video_view',
          value: 0,
          cost_label: 'CPV',
          cost: 0,
        },
        {
          name: 'post_engagement',
          value: 0,
          cost_label: 'CPE',
          cost: 0,
        },
        {
          name: 'engagement_rate',
          value: 0,
          cost_label: 'ER',
          cost: 0,
        },
        {
          name: 'conversion_rate',
          value: 0,
          cost_label: 'CR',
          cost: 0,
        },
        {
          name: 'cost_per_acquisition',
          value: 0,
          cost_label: 'CPA',
          cost: 0,
        },
      ];

      account.data.forEach((item) => {
        handleResponse.push({
          currency: 'BRL',
          grouped_by: item.source_id || '',
          name: item.name || '',
          source_ids: [item.source_id] || [],
          spend: 0,
          type: item.type || '',
          metrics,
        });
      });

      let reach = [];
      let reachResponse;

      if (action.reachDate) {
        let url = 'monthly';
        const date = action.reachDate;

        if (date.substring(0, 1) !== '2') {
          // Valida se é data ou texto
          url = date.toLowerCase();
        }

        const reachBody = {
          date,
          grouping: [],
        };

        account.data.forEach((item) => {
          reachBody.grouping.push({
            grouped_by: item.source_id,
            source_ids: [item.source_id],
          });
        });

        reachResponse = yield call(InsightsService.getReach, url, reachBody);
        reach = reachResponse.data && reachResponse.data.data;
      }

      if (handleResponse.length) {
        const attributionWindows = yield call(getAttributionWindows);

        const response = yield call(
          InsightsService.postListAccounts,
          '1.1',
          `?${
            action.period.params
          }&timezone_offset=${getTimezone()}&fields=currency,impressions,video_view,post_engagement,spend,clicks,page_video_views_organic,page_impressions_organic,page_post_engagements,page_actions_post_reactions_like_total,page_actions_post_reactions_love_total,page_actions_post_reactions_wow_total,page_actions_post_reactions_haha_total,page_actions_post_reactions_sorry_total,page_actions_post_reactions_anger_total,offsite_conversion.fb_pixel_purchase,offsite_conversion.fb_pixel_purchase_rate_total,cost_per_offsite_conversion.fb_pixel_purchase_total,summaries,link_click,onsite_conversion.messaging_conversation_started_7d,onsite_conversion.messaging_first_reply,offsite_conversion.fb_pixel_purchase_conversion_value,offline_conversion.initiate_checkout,offline_conversion.initiate_checkout_conversion_value,offline_conversion.purchase_conversion_value,offline_conversion.purchase,lead${attributionWindows}`,
          handleResponse
        );

        if (response.status === 200) {
          yield put({
            type: GET_LIST_ALL_ACCOUNTS.SUCCESS,
            reach,
            data: response.data.data,
            account: account.data,
            summaries: response.data.summaries[0],
            status: response.status,
          });
        } else {
          yield put({
            type: GET_LIST_ALL_ACCOUNTS.FAILURE,
            message: 'Response error',
            status: response.status,
          });
        }
      } else {
        yield put({
          type: GET_LIST_ALL_ACCOUNTS.FAILURE,
          message: 'Response error',
          status: 404,
        });
      }
    } else {
      yield put({
        type: GET_LIST_ALL_ACCOUNTS.SUCCESS,
        data: [],
        account: [],
        summaries: {},
        status: 200,
      });
    }
  } else {
    yield put({
      type: GET_LIST_ALL_ACCOUNTS.FAILURE,
      message: 'Response error',
      status: 404,
    });
  }
}

function* getListAllPages(action) {
  const pages = yield call(InsightsService.getPages, '1.1', action.code, '?fields=id,name,type&');
  if (pages.status === 200) {
    if (pages.data.length) {
      const handleResponse = [];
      const metrics = [
        {
          name: 'impressions',
          value: 0,
          cost_label: 'CPM',
          cost: 0,
        },
        {
          name: 'video_view',
          value: 0,
          cost_label: 'CPV',
          cost: 0,
        },
        {
          name: 'post_engagement',
          value: 0,
          cost_label: 'CPE',
          cost: 0,
        },
        {
          name: 'engagement_rate',
          value: 0,
          cost_label: 'ER',
          cost: 0,
        },
      ];

      pages.data.forEach((item) => {
        handleResponse.push({
          currency: 'BRL',
          grouped_by: item.source_id || '',
          name: item.name || '',
          source_ids: [item.source_id] || [],
          spend: 0,
          type: 'pages',
          metrics,
        });
      });

      if (handleResponse.length) {
        const response = yield call(
          InsightsService.postListPages,
          '1.1',
          `?${
            action.period.params
          }&timezone_offset=${getTimezone()}&fields=currency,impressions,video_view,post_engagement,spend,clicks,page_video_views_organic,page_impressions_organic,page_post_engagements,page_actions_post_reactions_like_total,page_actions_post_reactions_love_total,page_actions_post_reactions_wow_total,page_actions_post_reactions_haha_total,page_actions_post_reactions_sorry_total,page_actions_post_reactions_anger_total,offsite_conversion.fb_pixel_purchase,offsite_conversion.fb_pixel_purchase_rate_total,cost_per_offsite_conversion.fb_pixel_purchase_total,summaries,lead&`,
          handleResponse
        );

        if (response.status === 200) {
          yield put({
            type: GET_LIST_ALL_PAGES.SUCCESS,
            data: response.data.data,
            account: pages.data,
            summaries: response.data.summaries[0],
            status: response.status,
          });
        } else {
          yield put({
            type: GET_LIST_ALL_PAGES.FAILURE,
            message: 'Response error',
            status: response.status,
          });
        }
      } else {
        yield put({
          type: GET_LIST_ALL_PAGES.FAILURE,
          message: 'Response error',
          status: 404,
        });
      }
    } else {
      yield put({
        type: GET_LIST_ALL_PAGES.SUCCESS,
        data: [],
        account: [],
        summaries: {},
        status: 200,
      });
    }
  } else {
    yield put({
      type: GET_LIST_ALL_PAGES.FAILURE,
      message: 'Response error',
      status: 404,
    });
  }
}

function* getRankingGroups(action) {
  const account = yield call(
    InsightsService.getAccountIds,
    '1.1',
    action.code,
    '?fields=id,name,type,ad_account_ids',
    '["group"]'
  );
  if (account.status === 200) {
    if (account.data.length) {
      const handleResponse = [];

      account.data.forEach((item) => {
        handleResponse.push({
          grouped_by: item.source_id,
          source_ids: item.ad_account_ids ? item.ad_account_ids : [item.source_id],
        });
      });

      if (handleResponse.length) {
        const urlParams = new URLSearchParams(action.search);
        const sortBy = urlParams.get('sort_by');
        const metricURL = sortBy ? sortBy.toUpperCase() : '';
        let metricCustom = sortBy ? sortBy.toLowerCase() : '';
        let searchCustom;
        let periodParams = action.period.params;

        if (new Date(action.period.end) > new Date()) {
          periodParams = `since=${action.period.start}&until=${dayjs(new Date()).format(
            'YYYY-MM-DD'
          )}`;
        }

        if (metricCustom === 'reach') {
          searchCustom = `?limit=10&metric=reach&${periodParams}&timezone_offset=${getTimezone()}&fields=reach`;
        } else {
          if (metricCustom === 'purchase') {
            metricCustom = 'offsite_conversion.fb_pixel_purchase';
          } else if (metricCustom === 'engagements') {
            metricCustom = 'post_engagement';
          } else if (metricCustom === 'involvement') {
            metricCustom = 'post_involvement';
          } else if (metricCustom === 'involvement_rate') {
            metricCustom = 'post_involvement_rate';
          }
          searchCustom = `?limit=10&metric=${metricCustom}&${periodParams}&timezone_offset=${getTimezone()}`;
        }

        const response = yield call(
          InsightsService.postRanking,
          '1.1',
          searchCustom,
          handleResponse
        );

        if (response.status === 200) {
          const objBest = [];
          const objWorst = [];

          response.data.best.forEach((item) => {
            account.data.forEach((accountItem) => {
              if (item.source_id === accountItem.source_id) {
                objBest.push({
                  source_id: item.source_id,
                  name: accountItem.name,
                  impressions: item.impressions || 0,
                  purchase: item['offsite_conversion.fb_pixel_purchase'] || 0,
                  spend: item.spend || 0,
                  link_click: item.link_click || 0,
                  engagements: item.post_engagement || 0,
                  involvement: item.post_involvement || 0,
                  involvement_rate: item.post_involvement_rate || 0,
                  reach: item.reach || 0,
                });
              }
            });
          });

          response.data.worst.forEach((item) => {
            account.data.forEach((accountItem) => {
              if (item.source_id === accountItem.source_id) {
                objWorst.push({
                  source_id: item.source_id,
                  name: accountItem.name,
                  impressions: item.impressions || 0,
                  purchase: item['offsite_conversion.fb_pixel_purchase'] || 0,
                  spend: item.spend || 0,
                  link_click: item.link_click || 0,
                  engagements: item.post_engagement || 0,
                  involvement: item.post_involvement || 0,
                  involvement_rate: item.post_involvement_rate || 0,
                  reach: item.reach || 0,
                });
              }
            });
          });

          yield put({
            type: GET_RANKING_GROUPS.SUCCESS,
            best: objBest,
            worst: objWorst,
            metric: metricURL,
            status: response.status,
          });
        } else {
          yield put({
            type: GET_RANKING_GROUPS.FAILURE,
            message: 'Response error',
            status: response.status,
          });
        }
      } else {
        yield put({
          type: GET_RANKING_GROUPS.FAILURE,
          message: 'Response error',
          status: 404,
        });
      }
    } else {
      yield put({
        type: GET_RANKING_GROUPS.SUCCESS,
        data: [],
        account: [],
        summaries: {},
        status: 200,
      });
    }
  } else {
    yield put({
      type: GET_RANKING_GROUPS.FAILURE,
      message: 'Response error',
      status: 404,
    });
  }
}

function* getRankingAccounts(action) {
  const account = yield call(
    InsightsService.getAdAccounts,
    '1.1',
    action.code,
    '?fields=id,name,type,ad_account_ids&'
  );
  if (account.status === 200) {
    if (account.data.length) {
      const handleResponse = [];

      account.data.forEach((item) => {
        handleResponse.push({
          grouped_by: item.source_id,
          source_ids: item.ad_account_ids ? item.ad_account_ids : [item.source_id],
        });
      });

      if (handleResponse.length) {
        const urlParams = new URLSearchParams(action.search);
        const sortBy = urlParams.get('sort_by');
        const metricURL = sortBy ? sortBy.toUpperCase() : '';
        let metricCustom = sortBy ? sortBy.toLowerCase() : '';
        let searchCustom;
        let periodParams = action.period.params;

        if (new Date(action.period.end) > new Date()) {
          periodParams = `since=${action.period.start}&until=${dayjs(new Date()).format(
            'YYYY-MM-DD'
          )}`;
        }

        if (metricCustom === 'reach') {
          searchCustom = `?limit=10&metric=reach&${periodParams}&timezone_offset=${getTimezone()}&fields=reach`;
        } else {
          if (metricCustom === 'purchase') {
            metricCustom = 'offsite_conversion.fb_pixel_purchase';
          } else if (metricCustom === 'engagements') {
            metricCustom = 'post_engagement';
          } else if (metricCustom === 'involvement') {
            metricCustom = 'post_involvement';
          } else if (metricCustom === 'involvement_rate') {
            metricCustom = 'post_involvement_rate';
          }

          searchCustom = `?limit=10&metric=${metricCustom}&${periodParams}&timezone_offset=${getTimezone()}`;
        }

        const response = yield call(
          InsightsService.postRanking,
          '1.1',
          searchCustom,
          handleResponse
        );

        if (response.status === 200) {
          const objBest = [];
          const objWorst = [];

          response.data.best.forEach((item) => {
            account.data.forEach((accountItem) => {
              if (item.source_id === accountItem.source_id) {
                objBest.push({
                  source_id: item.source_id,
                  name: accountItem.name,
                  impressions: item.impressions || 0,
                  purchase: item['offsite_conversion.fb_pixel_purchase'] || 0,
                  spend: item.spend || 0,
                  link_click: item.link_click || 0,
                  engagements: item.post_engagement || 0,
                  involvement: item.post_involvement || 0,
                  involvement_rate: item.post_involvement_rate || 0,
                  reach: item.reach || 0,
                });
              }
            });
          });

          response.data.worst.forEach((item) => {
            account.data.forEach((accountItem) => {
              if (item.source_id === accountItem.source_id) {
                objWorst.push({
                  source_id: item.source_id,
                  name: accountItem.name,
                  impressions: item.impressions || 0,
                  purchase: item['offsite_conversion.fb_pixel_purchase'] || 0,
                  spend: item.spend || 0,
                  link_click: item.link_click || 0,
                  engagements: item.post_engagement || 0,
                  involvement: item.post_involvement || 0,
                  involvement_rate: item.post_involvement_rate || 0,
                  reach: item.reach || 0,
                });
              }
            });
          });

          yield put({
            type: GET_RANKING_GROUPS.SUCCESS,
            best: objBest,
            worst: objWorst,
            metric: metricURL,
            status: response.status,
          });
        } else {
          yield put({
            type: GET_RANKING_GROUPS.FAILURE,
            message: 'Response error',
            status: response.status,
          });
        }
      } else {
        yield put({
          type: GET_RANKING_GROUPS.FAILURE,
          message: 'Response error',
          status: 404,
        });
      }
    } else {
      yield put({
        type: GET_RANKING_GROUPS.SUCCESS,
        data: [],
        account: [],
        summaries: {},
        status: 200,
      });
    }
  } else {
    yield put({
      type: GET_RANKING_GROUPS.FAILURE,
      message: 'Response error',
      status: 404,
    });
  }
}

function* getRankingPages(action) {
  const pages = yield call(InsightsService.getPages, '1.1', action.code, '?fields=name');
  if (pages.status === 200) {
    if (pages.data.length) {
      const handleResponse = [];

      pages.data.forEach((item) => {
        handleResponse.push({
          grouped_by: item.source_id,
          source_ids: item.ad_account_ids ? item.ad_account_ids : [item.source_id],
        });
      });

      if (handleResponse.length) {
        const urlParams = new URLSearchParams(action.search);
        const sortBy = urlParams.get('sort_by');
        const metricURL = sortBy ? sortBy.toUpperCase() : '';
        let metricCustom = sortBy ? sortBy.toLowerCase() : '';
        let searchCustom;
        let periodParams = action.period.params;

        if (new Date(action.period.end) > new Date()) {
          periodParams = `since=${action.period.start}&until=${dayjs(new Date()).format(
            'YYYY-MM-DD'
          )}`;
        }

        if (metricCustom === 'reach') {
          searchCustom = `?limit=10&metric=reach&${periodParams}&timezone_offset=${getTimezone()}&fields=reach`;
        } else {
          if (metricCustom === 'impressions') {
            metricCustom = 'page_impressions_organic';
          } else if (metricCustom === 'engagements') {
            metricCustom = 'page_post_engagements';
          } else if (metricCustom === 'involvement') {
            metricCustom = 'page_post_involvement';
          }

          searchCustom = `?limit=10&metric=${metricCustom}&${periodParams}&timezone_offset=${getTimezone()}`;
        }

        const response = yield call(
          InsightsService.postRanking,
          '1.1',
          searchCustom,
          handleResponse
        );

        if (response.status === 200) {
          const objBest = [];
          const objWorst = [];

          response.data.best.forEach((item) => {
            pages.data.forEach((pageItem) => {
              if (item.source_id === pageItem.source_id) {
                objBest.push({
                  source_id: item.source_id,
                  name: pageItem.name,
                  impressions: item.page_impressions_organic || 0,
                  purchase: item['offsite_conversion.fb_pixel_purchase'] || 0,
                  spend: item.spend || 0,
                  link_click: item.link_click || 0,
                  engagements: item.page_post_engagements || 0,
                  involvement: item.page_post_involvement || 0,
                  reach: item.reach || 0,
                });
              }
            });
          });

          response.data.worst.forEach((item) => {
            pages.data.forEach((pageItem) => {
              if (item.source_id === pageItem.source_id) {
                objWorst.push({
                  source_id: item.source_id,
                  name: pageItem.name,
                  impressions: item.page_impressions_organic || 0,
                  purchase: item['offsite_conversion.fb_pixel_purchase'] || 0,
                  spend: item.spend || 0,
                  link_click: item.link_click || 0,
                  engagements: item.page_post_engagements || 0,
                  involvement: item.page_post_involvement || 0,
                  involvement_rate: item.page_post_involvement_rate || 0,
                  reach: item.reach || 0,
                });
              }
            });
          });

          yield put({
            type: GET_RANKING_GROUPS.SUCCESS,
            best: objBest,
            worst: objWorst,
            metric: metricURL,
            status: response.status,
          });
        } else {
          yield put({
            type: GET_RANKING_GROUPS.FAILURE,
            message: 'Response error',
            status: response.status,
          });
        }
      } else {
        yield put({
          type: GET_RANKING_GROUPS.FAILURE,
          message: 'Response error',
          status: 404,
        });
      }
    } else {
      yield put({
        type: GET_RANKING_GROUPS.SUCCESS,
        data: [],
        account: [],
        summaries: {},
        status: 200,
      });
    }
  } else {
    yield put({
      type: GET_RANKING_GROUPS.FAILURE,
      message: 'Response error',
      status: 404,
    });
  }
}

function* getAudienceAgeGender(action) {
  if (action.code === 'root') {
    const arrayWillBeSend = [
      {
        currency: 'BRL',
        grouped_by: action.code,
        source_ids: [],
        spend: 0,
        type: 'group',
      },
    ];

    const breakdown = yield call(
      InsightsService.postBreakdown,
      '1.1',
      `?${
        action.period.params
      }&breakdowns=age,gender&fields=spend,impressions,clicks&timezone_offset=${getTimezone()}`,
      arrayWillBeSend
    );

    if (breakdown.status === 200) {
      const dataBreakdown = breakdown.data.data;
      const female = dataBreakdown.filter((x) => x.breakdowns[1] === 'female');
      const male = dataBreakdown.filter((x) => x.breakdowns[1] === 'male');

      if (dataBreakdown.length) {
        const data = [
          separateGender('spend', female, 'female'),
          separateGender('spend', male, 'male'),
          separateGender('clicks', female, 'female'),
          separateGender('clicks', male, 'male'),
          separateGender('impressions', female, 'female'),
          separateGender('impressions', male, 'male'),
        ];

        yield put({
          type: GET_AUDIENCE_AGE_GENDER.SUCCESS,
          data,
          status: breakdown.status,
        });
      } else {
        yield put({
          type: GET_AUDIENCE_AGE_GENDER.FAILURE,
          message: 'Response error',
          status: breakdown.status,
        });
      }
    } else {
      yield put({
        type: GET_AUDIENCE_AGE_GENDER.FAILURE,
        message: 'Response error',
        status: breakdown.status,
      });
    }
  } else {
    const info = yield call(
      SchemaService.getCodeInfo,
      '1.1',
      action.code,
      '?fields=name,type,budget'
    );

    if (info.status === 200) {
      const arrayWillBeSend = [
        {
          currency: 'BRL',
          grouped_by: action.code,
          source_ids: [info.data.source_id],
          name: info.data.name,
          spend: 0,
          type: 'group',
        },
      ];

      const breakdown = yield call(
        InsightsService.postBreakdown,
        '1.1',
        `?${
          action.period.params
        }&breakdowns=age,gender&fields=spend,impressions,clicks&timezone_offset=${getTimezone()}`,
        arrayWillBeSend
      );

      if (breakdown.status === 200) {
        const dataBreakdown = breakdown.data.data;
        const female = dataBreakdown.filter((x) => x.breakdowns[1] === 'female');
        const male = dataBreakdown.filter((x) => x.breakdowns[1] === 'male');

        if (dataBreakdown.length) {
          const data = [
            separateGender('spend', female, 'female'),
            separateGender('spend', male, 'male'),
            separateGender('clicks', female, 'female'),
            separateGender('clicks', male, 'male'),
            separateGender('impressions', female, 'female'),
            separateGender('impressions', male, 'male'),
          ];

          yield put({
            type: GET_AUDIENCE_AGE_GENDER.SUCCESS,
            data,
            status: breakdown.status,
          });
        } else {
          yield put({
            type: GET_AUDIENCE_AGE_GENDER.FAILURE,
            message: 'Response error',
            status: breakdown.status,
          });
        }
      } else {
        yield put({
          type: GET_AUDIENCE_AGE_GENDER.FAILURE,
          message: 'Response error',
          status: info.status,
        });
      }
    } else {
      yield put({
        type: GET_AUDIENCE_AGE_GENDER.FAILURE,
        message: 'Response error',
        status: info.status,
      });
    }
  }
}

function separateGender(kind, gender, sex) {
  const dataGender = [];
  const totalGender = gender.reduce((acc, val) => acc + val[kind], 0);

  gender.forEach((itemGender) => {
    let percentage = (itemGender[kind] / totalGender) * 100;
    percentage = !isNaN(percentage) ? percentage : 0;

    dataGender.push({
      name: itemGender.breakdowns[0],
      value: itemGender[kind],
      y: +percentage.toFixed(2),
      drilldown: itemGender.breakdowns[0],
    });
  });

  let maxAge = 0;
  let maxPercentage = Math.max(...dataGender.map((x) => x.y)) || 0;

  if (!!dataGender.length && !!maxPercentage) {
    maxAge = dataGender.filter((x) => x.y === maxPercentage)[0].name || 0;
  } else {
    maxPercentage = 0;
  }

  const objWillBeSend = {
    type: kind,
    chart: dataGender,
    gender: sex,
    bestConversion: {
      maxPercentage,
      maxAge,
      totalGender,
    },
  };

  return objWillBeSend;
}

function* getAudienceChannel(action) {
  const arraySourceIds = [];
  let objectWillBeShowed = {
    spend: {
      allChannels: 0,
    },
    clicks: {
      allChannels: 0,
    },
    impressions: {
      allChannels: 0,
    },
  };

  if (action.code === 'root') {
    const arrayWillBeSend = [
      {
        currency: 'BRL',
        grouped_by: action.code,
        source_ids: arraySourceIds,
        spend: 0,
        type: 'group',
      },
    ];

    const breakdown = yield call(
      InsightsService.postBreakdown,
      '1.1',
      `?${
        action.period.params
      }&breakdowns=channel&fields=spend,impressions,clicks&timezone_offset=${getTimezone()}`,
      arrayWillBeSend
    );

    if (breakdown.status === 200) {
      const breakdownData = breakdown.data && breakdown.data.data;

      breakdownData.forEach((item) => {
        action.options.forEach((option) => {
          if (item.breakdowns[0] === 'audience_network') {
            objectWillBeShowed = {
              ...objectWillBeShowed,
              [option]: {
                ...objectWillBeShowed[option],
                audienceNetwork: item[option],
                allChannels: objectWillBeShowed[option].allChannels + item[option],
              },
            };
          } else if (item.breakdowns[0] === 'facebook') {
            objectWillBeShowed = {
              ...objectWillBeShowed,
              [option]: {
                ...objectWillBeShowed[option],
                facebook: item[option],
                allChannels: objectWillBeShowed[option].allChannels + item[option],
              },
            };
          } else if (item.breakdowns[0] === 'instagram') {
            objectWillBeShowed = {
              ...objectWillBeShowed,
              [option]: {
                ...objectWillBeShowed[option],
                instagram: item[option],
                allChannels: objectWillBeShowed[option].allChannels + item[option],
              },
            };
          } else if (item.breakdowns[0] === 'messenger') {
            objectWillBeShowed = {
              ...objectWillBeShowed,
              [option]: {
                ...objectWillBeShowed[option],
                messenger: item[option],
                allChannels: objectWillBeShowed[option].allChannels + item[option],
              },
            };
          }
        });
      });

      yield put({
        type: GET_AUDIENCE_CHANNEL.SUCCESS,
        data: objectWillBeShowed,
        status: breakdown.status,
      });
    } else {
      yield put({
        type: GET_AUDIENCE_CHANNEL.FAILURE,
        message: 'Response error',
        status: breakdown.status,
      });
    }
  } else {
    const info = yield call(
      SchemaService.getCodeInfo,
      '1.1',
      action.code,
      '?fields=name,type,budget'
    );

    if (info.status === 200) {
      const arrayWillBeSend = [
        {
          currency: 'BRL',
          grouped_by: action.code,
          source_ids: [info.data.source_id],
          spend: 0,
          type: 'group',
        },
      ];

      const breakdown = yield call(
        InsightsService.postBreakdown,
        '1.1',
        `?${
          action.period.params
        }&breakdowns=channel&fields=spend,impressions,clicks&timezone_offset=${getTimezone()}`,
        arrayWillBeSend
      );

      if (breakdown.status === 200) {
        const breakdownData = breakdown.data && breakdown.data.data;
        objectWillBeShowed = {
          spend: {
            allChannels: 0,
          },
          clicks: {
            allChannels: 0,
          },
          impressions: {
            allChannels: 0,
          },
        };

        breakdownData.forEach((item) => {
          action.options.forEach((option) => {
            if (item.breakdowns[0] === 'audience_network') {
              objectWillBeShowed = {
                ...objectWillBeShowed,
                [option]: {
                  ...objectWillBeShowed[option],
                  audienceNetwork: item[option] || 0,
                  allChannels: 0 + item[option],
                },
              };
            } else if (item.breakdowns[0] === 'facebook') {
              objectWillBeShowed = {
                ...objectWillBeShowed,
                [option]: {
                  ...objectWillBeShowed[option],
                  facebook: item[option] || 0,
                  allChannels: objectWillBeShowed[option].allChannels + item[option],
                },
              };
            } else if (item.breakdowns[0] === 'instagram') {
              objectWillBeShowed = {
                ...objectWillBeShowed,
                [option]: {
                  ...objectWillBeShowed[option],
                  instagram: item[option] || 0,
                  allChannels: objectWillBeShowed[option].allChannels + item[option],
                },
              };
            } else if (item.breakdowns[0] === 'messenger') {
              objectWillBeShowed = {
                ...objectWillBeShowed,
                [option]: {
                  ...objectWillBeShowed[option],
                  messenger: item[option] || 0,
                  allChannels: objectWillBeShowed[option].allChannels + item[option],
                },
              };
            }
          });
        });

        yield put({
          type: GET_AUDIENCE_CHANNEL.SUCCESS,
          data: objectWillBeShowed,
          status: breakdown.status,
        });
      } else {
        yield put({
          type: GET_AUDIENCE_CHANNEL.FAILURE,
          message: 'Response error',
          status: breakdown.status,
        });
      }
    } else {
      yield put({
        type: GET_AUDIENCE_CHANNEL.FAILURE,
        message: 'Response error',
        status: null,
      });
    }
  }
}

function* getAudienceDevice(action) {
  const arraySourceIds = [];
  if (action.code === 'root') {
    const arrayWillBeSend = [
      {
        currency: 'BRL',
        grouped_by: action.code,
        source_ids: arraySourceIds,
        spend: 0,
        type: 'group',
      },
    ];

    const breakdown = yield call(
      InsightsService.postBreakdown,
      '1.1',
      `?${
        action.period.params
      }&breakdowns=device_os&fields=spend,impressions&timezone_offset=${getTimezone()}`,
      arrayWillBeSend
    );

    if (breakdown.status === 200) {
      const breakdownData = breakdown.data.data;
      const modifiedBreakdown = breakdownData.filter((x) => x.breakdowns[0] !== 'other');
      const arrayWillBeShowed = [];

      modifiedBreakdown.forEach((bd) => {
        arrayWillBeShowed.push({
          type: bd.breakdowns[0],
          cpm: cpm(bd.spend, bd.impressions),
          impressions: bd.impressions,
          spend: bd.spend,
        });
      });

      yield put({
        type: GET_AUDIENCE_DEVICE.SUCCESS,
        data: arrayWillBeShowed,
        status: breakdown.status,
      });
    } else {
      yield put({
        type: GET_AUDIENCE_DEVICE.FAILURE,
        message: 'Response error',
        status: breakdown.status,
      });
    }
  } else {
    const info = !arraySourceIds.length
      ? yield call(SchemaService.getCodeInfo, '1.1', action.code, '?fields=name,type,budget')
      : null;

    if (arraySourceIds.length || info.status === 200) {
      const arrayWillBeSend = [
        {
          currency: 'BRL',
          grouped_by: action.code,
          source_ids: [info.data.source_id],
          spend: 0,
          type: 'group',
        },
      ];

      const breakdown = yield call(
        InsightsService.postBreakdown,
        '1.1',
        `?${
          action.period.params
        }&breakdowns=device_os&fields=spend,impressions&timezone_offset=${getTimezone()}`,
        arrayWillBeSend
      );

      if (breakdown.status === 200) {
        const breakdownData = breakdown.data.data;
        const modifiedBreakdown = breakdownData.filter((x) => x.breakdowns[0] !== 'other');
        const arrayWillBeShowed = [];

        modifiedBreakdown.forEach((bd) => {
          arrayWillBeShowed.push({
            type: bd.breakdowns[0],
            cpm: cpm(bd.spend, bd.impressions),
            impressions: bd.impressions,
            spend: bd.spend,
          });
        });

        yield put({
          type: GET_AUDIENCE_DEVICE.SUCCESS,
          data: arrayWillBeShowed,
          status: breakdown.status,
        });
      } else {
        yield put({
          type: GET_AUDIENCE_DEVICE.FAILURE,
          message: 'Response error',
          status: breakdown.status,
        });
      }
    } else {
      yield put({
        type: GET_AUDIENCE_DEVICE.FAILURE,
        message: 'Response error',
        status: 404,
      });
    }
  }
}

function* renameGroup(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });
  const response = yield call(
    SchemaService.renameGroup,
    action.version,
    action.sourceId,
    action.body
  );
  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });

  if (response.status === 200) {
    yield put({
      type: RENAME_GROUP.SUCCESS,
      sourceId: action.sourceId,
      name: action.body.name,
    });
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.successMessage,
      messageType: 'success',
    });

    if (action.onSuccess) {
      action.onSuccess({
        sourceId: action.sourceId,
        newName: action.body.name,
      });
    }
  } else {
    yield put({
      type: SHOW_TOAST.FAILURE,
      message: action.errorMessage,
      messageType: 'error',
    });

    if (action.onError) {
      action.onError();
    }
  }
}

function* replaceAccount(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });
  const response = yield call(SchemaService.replaceAccountId, action.version, action.body);
  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });

  if (response.status === 200) {
    yield put({
      type: REPLACE_ACCOUNT.SUCCESS,
      sourceId: action.body.entries[0].new_ad_account_id,
      oldId: action.body.entries[0].old_ad_account_id,
    });
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.successMessage,
      messageType: 'success',
    });

    if (action.onSuccess) {
      action.onSuccess();
    }
  } else {
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.errorMessage,
      messageType: 'error',
    });

    if (action.onError) {
      action.onError();
    }
  }
}
function* replacePage(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });
  const response = yield call(SchemaService.replacePageId, action.version, action.body);

  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });

  if (response.status === 200) {
    yield put({
      type: REPLACE_PAGE.SUCCESS,
      sourceId: action.body.entries[0].new_page_id,
      oldId: action.body.entries[0].old_page_id,
    });
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.successMessage,
      messageType: 'success',
    });

    if (action.onSuccess) {
      action.onSuccess();
    }
  } else {
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.errorMessage,
      messageType: 'error',
    });

    if (action.onError) {
      action.onError();
    }
  }
}
function* deleteGroup(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });
  const response = yield call(SchemaService.deleteGroup, action.version, action.sourceId);
  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });

  if (response.status === 200) {
    yield put({ type: DELETE_GROUP.SUCCESS, sourceId: action.sourceId });
    yield put({ type: RECOUNT_LISTING_GROUP_TOTAL.REQUEST, sum: false });
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.successMessage,
      messageType: 'success',
    });
  } else if (response.data.error === 'NODE_IS_NOT_EMPTY') {
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.errorNodeNotEmptyMessage,
      messageType: 'error',
    });
  } else {
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.errorMessage,
      messageType: 'error',
    });
  }
}

function* moveGroup(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });
  const response = yield call(
    SchemaService.moveGroup,
    action.version,
    action.parentId,
    action.data
  );
  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });

  if (response.status === 200) {
    yield put({
      type: DELETE_GROUP.SUCCESS,
      sourceId: action.data.child_source_id,
    });
    yield put({ type: RECOUNT_LISTING_GROUP_TOTAL.REQUEST, sum: false });
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.successMessage,
      messageType: 'success',
    });
  } else if (response.data.error === 'INVALID_ENTITY_RELATIONSHIP') {
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.errorInvalidGroupMessage,
      messageType: 'error',
    });
  } else {
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.errorMessage,
      messageType: 'error',
    });
  }
}

function* changeGroupColor(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });
  const response = yield call(
    SchemaService.changeGroupColor,
    action.version,
    action.sourceId,
    action.body
  );
  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });

  if (response.status === 200) {
    if (action.currentCode === 'root') {
      yield put({
        type: CHANGE_GROUP_COLOR.SUCCESS,
        sourceId: action.sourceId,
        color: action.body.color,
      });
    }
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.successMessage,
      messageType: 'success',
    });
  } else {
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.errorMessage,
      messageType: 'error',
    });
  }
}

function* createGroup(action) {
  yield put({ type: IS_LOADING.SUCCESS, isLoading: true });
  const response = yield call(
    SchemaService.createGroup,
    action.version,
    action.parentId,
    action.data
  );
  yield put({ type: IS_LOADING.SUCCESS, isLoading: false });

  if (response.status === 200) {
    if (action.updateCurrentTable) {
      yield put({
        type: CREATE_GROUP.SUCCESS,
        source_id: response.data.source_id,
        data: action.data,
        currentCode: action.parentId,
      });
      yield put({ type: RECOUNT_LISTING_GROUP_TOTAL.REQUEST, sum: true });
    }
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.successMessage,
      messageType: 'success',
    });
  } else {
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.errorMessage,
      messageType: 'error',
    });
  }
}

function* getGroupColors(action) {
  const response = yield call(SchemaService.getGroupColors, action.version);
  if (response.status === 200) {
    yield put({ type: GET_GROUP_COLORS.SUCCESS, colors: response.data });
  }
}

function* fetchInstagramMetrics(action) {
  const response = yield call(InsightsService.getInstagramMetrics, action.search);

  if (response.status === 200) {
    yield put({
      type: FETCH_CAMPAIGN_HEALTH_INSTAGRAM.SUCCESS,
      campaignHealthInstagram: response.data,
    });
  } else {
    yield put({
      type: FETCH_CAMPAIGN_HEALTH_INSTAGRAM.FAILURE,
      campaignHealthInstagram: response.response,
    });
  }
}

function* fetchItemInstagramMetrics(action) {
  const data = {
    from: new Date(action.startDate).toISOString(),
    to: new Date(action.endDate).toISOString(),
    fields: action.fields,
    pages: [],
  };

  const accounts = yield call(SchemaService.getAdAccounts, '1.1', action.code, '?fields=name&');
  const pages = yield call(SchemaService.getFanpages, '1.1', action.code, '?&fields=name');

  if (accounts.status === 200 && pages.status === 200) {
    pages.data.forEach((page) => {
      data.pages.push(page.source_id);
    });
    accounts.data.forEach((account) => {
      data.pages.push(account.source_id);
    });

    const response = yield call(InsightsService.postInstagramMetricsPerPage, data);
    if (response.status === 200) {
      yield put({
        type: FETCH_CAMPAIGN_HEALTH_INSTAGRAM.SUCCESS,
        campaignHealthInstagram: response.data,
      });
    } else {
      yield put({
        type: FETCH_CAMPAIGN_HEALTH_INSTAGRAM.FAILURE,
        campaignHealthInstagram: response.response,
      });
    }
  } else {
    yield put({
      type: FETCH_CAMPAIGN_HEALTH_INSTAGRAM.FAILURE,
      campaignHealthInstagram: accounts.response,
    });
  }
}

function* getRanking(action) {
  const accountIds = yield call(SchemaService.getCodeInfoChildren, action.group);

  if (accountIds.status === 200 && accountIds.data !== 'NOT_FOUND') {
    const arraySourceIds = Array.from(accountIds.data, (x) => x.sourceID);

    if (arraySourceIds.length) {
      const attributionWindows = yield call(getAttributionWindows, 'array');

      const response = yield call(InsightsService.postDataRanking, {
        from: new Date(action.from),
        to: new Date(action.to),
        groups: arraySourceIds,
        metric: action.metric,
        size: action.size,
        attribution_windows: attributionWindows,
      });

      if (response.status === 200) {
        const newObject = {
          best: [],
          worst: [],
        };

        response.data.best.forEach((valParam) => {
          const val = valParam;
          val.name = accountIds.data.find((x) => x.sourceID === val.source_id).name;
          if (val.name) {
            newObject.best.push(val);
          }
        });

        response.data.worst.forEach((valParam) => {
          const val = valParam;
          val.name = accountIds.data.find((x) => x.sourceID === val.source_id).name;
          if (val.name) {
            newObject.worst.push(val);
          }
        });

        yield put({
          type: GET_RANKING.SUCCESS,
          ranking: newObject,
          size: action.size,
          from: action.from,
          to: action.to,
          group: action.group,
          groups: arraySourceIds,
          metric: action.metric,
        });
      } else {
        yield put({
          type: GET_RANKING.FAILURE,
          rankingError: response.data,
        });
        yield put({
          type: SHOW_TOAST.SUCCESS,
          message: action.errorMessage,
          messageType: 'error',
        });
      }
    } else {
      yield put({
        type: GET_RANKING.FAILURE,
        rankingError: accountIds.data,
      });
    }
  } else {
    yield put({ type: GET_RANKING.FAILURE, rankingError: accountIds.data });
  }
}

function* getChartRanking(action) {
  const response = yield call(InsightsService.getDataChartRanking, action.data);
  if (response.status === 200) {
    const changedData = response.data.find(
      (x) => x.metric === action.data.metric.toLowerCase()
    ).data;

    yield put({
      type: GET_CHART_RANKING.SUCCESS,
      chart: changedData,
      charts: response.data,
      id: action.data.group,
    });
  } else {
    yield put({
      type: GET_CHART_RANKING.FAILURE,
      chartError: response.data,
    });
  }
}

function* postReportAd(action) {
  const response = yield call(ReportService.reportAd, action.data);
  if (response) {
    if (response.status === 200) {
      yield put({
        type: POST_REPORT_AD.SUCCESS,
        ad: response.data.data,
        summary: response.data.summary,
        status: response.status,
      });
    } else {
      yield put({
        type: POST_REPORT_AD.FAILURE,
        ad: response.data,
        status: response.status,
      });
    }
  }
}

function* postReportPageFacebook(action) {
  const response = yield call(ReportService.reportPageFacebook, action.data);
  if (response.status === 200) {
    yield put({
      type: POST_REPORT_PAGE_FACEBOOK.SUCCESS,
      page: response.data.page_insights_data,
      status: response.status,
    });
  } else {
    yield put({
      type: POST_REPORT_PAGE_FACEBOOK.FAILURE,
      page: response.data,
      status: response.status,
    });
  }
}

function* postReportPageInstagram(action) {
  const response = yield call(ReportService.reportPageInstagram, action.data);
  if (response.status === 200) {
    yield put({
      type: POST_REPORT_PAGE_INSTAGRAM.SUCCESS,
      page: response.data.page_insights_data,
      status: response.status,
    });
  } else {
    yield put({
      type: POST_REPORT_PAGE_INSTAGRAM.FAILURE,
      page: response.data,
      status: response.status,
    });
  }
}

function* postReportAdEvolution(action) {
  const response = yield call(ReportService.reportAdEvolution, action.data);
  if (response.status === 200) {
    yield put({
      type: POST_REPORT_AD_EVOLUTION.SUCCESS,
      ad: response.data,
      status: response.status,
    });
  } else {
    yield put({
      type: POST_REPORT_AD_EVOLUTION.FAILURE,
      ad: response.data,
      status: response.status,
    });
  }
}

function* postReportPageEvolutionFacebook(action) {
  const response = yield call(ReportService.reportPageFacebookEvolution, action.data);
  if (response.status === 200) {
    yield put({
      type: POST_REPORT_PAGE_EVOLUTION_FACEBOOK.SUCCESS,
      page: response.data,
      status: response.status,
    });
  } else {
    yield put({
      type: POST_REPORT_PAGE_EVOLUTION_FACEBOOK.FAILURE,
      page: response.data,
      status: response.status,
    });
  }
}

function* postReportPageEvolutionInstagram(action) {
  const response = yield call(ReportService.reportPageInstagramEvolution, action.data);
  if (response.status === 200) {
    yield put({
      type: POST_REPORT_PAGE_EVOLUTION_INSTAGRAM.SUCCESS,
      page: response.data,
      status: response.status,
    });
  } else {
    yield put({
      type: POST_REPORT_PAGE_EVOLUTION_INSTAGRAM.FAILURE,
      page: response.data,
      status: response.status,
    });
  }
}

function* postEngagementBest(action) {
  const response = yield call(ReportService.postBestPerforming, action.data);
  if (response.status === 200) {
    yield put({
      type: POST_ENGAGEMENT_BEST.SUCCESS,
      top: response.data.data || [],
      status: response.status,
    });
  } else {
    yield put({
      type: POST_ENGAGEMENT_BEST.FAILURE,
      top: [],
      status: response.status,
    });
  }
}

function* postPurchaseBest(action) {
  const response = yield call(ReportService.postPurchasePerforming, action.data);
  if (response.status === 200) {
    yield put({
      type: POST_PURCHASE_BEST.SUCCESS,
      topPurchase: response.data || [],
      status: response.status,
    });
  } else {
    yield put({
      type: POST_PURCHASE_BEST.FAILURE,
      topPurchase: [],
      status: response.status,
    });
  }
}

function* getInfoGroups(action) {
  const response = yield call(ReportService.getInfoGroup, action.code, action.arrayIds);
  if (response.status === 200) {
    yield put({
      type: GET_INFO_GROUPS.SUCCESS,
      data: response.data.data,
      status: response.status,
    });
  } else {
    yield put({
      type: GET_INFO_GROUPS.FAILURE,
      data: response.data,
      status: response.status,
    });
  }
}

function* getSummary(action) {
  const response = yield call(SchemaService.getSummary, action.version, action.code, action.period);
  if (response.status === 200) {
    yield put({ type: GET_SUMMARY.SUCCESS, summary: response.data });
  } else {
    yield put({ type: GET_SUMMARY.FAILURE, error: response.status });
  }
}

function* postInsightsMetrics(action) {
  const response = yield call(ReportService.postInsightsMetrics, action.data);
  if (response) {
    if (response.status === 200) {
      yield put({
        type: POST_INSIGHTS_METRICS.SUCCESS,
        custom: response.data.data,
        status: response.status,
      });
    } else {
      yield put({
        type: POST_INSIGHTS_METRICS.FAILURE,
        custom: response.data,
        status: response.status,
      });
    }
  }
}

function* getActivityMetrics(action) {
  const response = yield call(ReportService.getActivityMetrics, action.data);
  if (response) {
    if (response.status === 200) {
      yield put({
        type: GET_ACTIVITY_METRICS.SUCCESS,
        customActivity: response.data.payload.data,
        status: response.status,
      });
    } else {
      yield put({
        type: GET_ACTIVITY_METRICS.FAILURE,
        customActivity: response.data,
        status: response.status,
      });
    }
  }
}

function* getBudgetMetrics(action) {
  const response = yield call(ReportService.getBudgetMetrics, action.data);
  if (response) {
    if (response.status === 200) {
      yield put({
        type: GET_BUDGET_METRICS.SUCCESS,
        customBudget: response.data.payload,
        status: response.status,
      });
    } else {
      yield put({
        type: GET_BUDGET_METRICS.FAILURE,
        customBudget: response.data,
        status: response.status,
      });
    }
  }
}

function* getActivitiesGrouped(action) {
  const response = yield call(ReportService.getActivityGroupedMetricsByDay, action.data);
  if (response) {
    if (response.status === 200) {
      yield put({
        type: GET_ACTIVITIES_GROUPED_BY_DAY.SUCCESS,
        activitiesGrouped: response.data.payload,
        status: response.status,
      });
    } else {
      yield put({
        type: GET_ACTIVITIES_GROUPED_BY_DAY.FAILURE,
        activitiesGrouped: response.data,
        status: response.status,
      });
    }
  }
}

const reportSaga = [
  takeEvery(GET_COMMENTS_SUMMARY.REQUEST, getCommentsSummary),
  takeEvery(GET_COMMENTS_GRAPH.REQUEST, getCommentsGraph),
  takeEvery(GET_COMMENTS_RANK.REQUEST, getCommentsRank),
  takeEvery(GET_COMMENTS_RANK_EVOLUTION.REQUEST, getCommentsRankEvolution),
  takeEvery(GET_CAMPAIGN_HEALTH.REQUEST, getCampaignHealth),
  takeEvery(GET_CONVERSION_FLOW.REQUEST, getConversionFlow),
  takeEvery(GET_LIST_ALL_GROUPS.REQUEST, getListAllGroups),
  takeEvery(GET_LIST_ALL_ACCOUNTS.REQUEST, getListAllAccounts),
  takeEvery(GET_LIST_ALL_PAGES.REQUEST, getListAllPages),
  takeEvery(GET_RANKING_GROUPS.REQUEST, getRankingGroups),
  takeEvery(GET_RANKING_ACCOUNTS.REQUEST, getRankingAccounts),
  takeEvery(GET_RANKING_PAGES.REQUEST, getRankingPages),
  takeEvery(GET_AUDIENCE_AGE_GENDER.REQUEST, getAudienceAgeGender),
  takeEvery(GET_AUDIENCE_CHANNEL.REQUEST, getAudienceChannel),
  takeEvery(GET_AUDIENCE_DEVICE.REQUEST, getAudienceDevice),
  takeEvery(RENAME_GROUP.REQUEST, renameGroup),
  takeEvery(REPLACE_ACCOUNT.REQUEST, replaceAccount),
  takeEvery(REPLACE_PAGE.REQUEST, replacePage),
  takeEvery(DELETE_GROUP.REQUEST, deleteGroup),
  takeEvery(MOVE_GROUP.REQUEST, moveGroup),
  takeEvery(CHANGE_GROUP_COLOR.REQUEST, changeGroupColor),
  takeEvery(CREATE_GROUP.REQUEST, createGroup),
  takeEvery(GET_GROUP_COLORS.REQUEST, getGroupColors),
  takeEvery(FETCH_CAMPAIGN_HEALTH_INSTAGRAM.REQUEST, fetchInstagramMetrics),
  takeEvery(FETCH_ITEM_CAMPAIGN_HEALTH_INSTAGRAM.REQUEST, fetchItemInstagramMetrics),
  takeEvery(GET_RANKING.REQUEST, getRanking),
  takeEvery(GET_CHART_RANKING.REQUEST, getChartRanking),
  takeEvery(POST_REPORT_AD.REQUEST, postReportAd),
  takeEvery(POST_REPORT_PAGE_FACEBOOK.REQUEST, postReportPageFacebook),
  takeEvery(POST_REPORT_PAGE_INSTAGRAM.REQUEST, postReportPageInstagram),
  takeEvery(POST_REPORT_AD_EVOLUTION.REQUEST, postReportAdEvolution),
  takeEvery(POST_REPORT_PAGE_EVOLUTION_FACEBOOK.REQUEST, postReportPageEvolutionFacebook),
  takeEvery(POST_REPORT_PAGE_EVOLUTION_INSTAGRAM.REQUEST, postReportPageEvolutionInstagram),
  takeEvery(POST_ENGAGEMENT_BEST.REQUEST, postEngagementBest),
  takeEvery(POST_PURCHASE_BEST.REQUEST, postPurchaseBest),
  takeEvery(GET_INFO_GROUPS.REQUEST, getInfoGroups),
  takeEvery(GET_SUMMARY.REQUEST, getSummary),
  takeEvery(POST_INSIGHTS_METRICS.REQUEST, postInsightsMetrics),
  takeEvery(GET_ACTIVITY_METRICS.REQUEST, getActivityMetrics),
  takeEvery(GET_BUDGET_METRICS.REQUEST, getBudgetMetrics),
  takeEvery(GET_ACTIVITIES_GROUPED_BY_DAY.REQUEST, getActivitiesGrouped),
];

export default reportSaga;
