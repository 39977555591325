import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import * as S from './styles';


const Checkbox = ({
  type, name, id, color, onClick, rest, checked, iconCheck, dataTestId,
}) => (
  <S.CustomCheckbox color={color} checked={checked}>
    <S.InputCheckbox
      type={type}
      name={name}
      data-testid={dataTestId}
      id={id}
      onChange={onClick}
      checked={checked}
      color={color}
      {...rest}
    />
    <S.IconCheckbox>
      <FontAwesomeIcon icon={iconCheck || faCheck} />
    </S.IconCheckbox>
  </S.CustomCheckbox>
);

Checkbox.propTypes = {
  type: PropTypes.string,
  name: PropTypes.node,
  id: PropTypes.node,
  color: PropTypes.string,
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  dataTestId: PropTypes.string,
};

Checkbox.defaultProps = {
  type: 'checkbox',
  color: 'blue3',
  onClick: () => { },
  checked: false,
  dataTestId: 'checkbox',
};

export default Checkbox;
