// teste

import Cookies from 'universal-cookie';

export const isDEV = process.env.NODE_ENV === 'development';
let defaultCompany = process.env.REACT_APP_TENANT;
let host = 'gerentesdigitais.com';

const cookies = new Cookies();

const redirectToBusiness = (company) => {
  if (isDEV) {
    defaultCompany = company;
  } else {
    window.location.href = `${window.location.protocol}//${company}.${host}`;
  }
};

const loginUser = (userToken) => {
  let temp = `.${host}`;

  if (isDEV) {
    temp = 'localhost';
  }

  cookies.set('user-token', userToken, {
    domain: temp,
  });
};

const logoutUser = () => {
  cookies.remove('user-token', { domain: isDEV ? 'localhost' : `.${host}` });
  logoutBusiness();
};

const loginBusiness = (businessToken) => {
  localStorage.setItem('business-token', businessToken);
};

const logoutBusiness = () => {
  localStorage.removeItem('business-token');
};

const getBusinessID = () => localStorage.getItem('business-id');

const setBusinessID = (id) => {
  localStorage.setItem('business-id', id);
};

const getSubDomain = () => {
  if (isDEV) {
    return defaultCompany;
  }
  return window.location.host.split('.')[0];
};

const setTrackableCockie = (isTrackable) => {
  cookies.set('trackable', isTrackable);
};

const getTrackableCockie = (_) => cookies.get('trackable');

const getDomain = () => (isDEV ? `localhost` : `.${host}`);

const getUserToken = () => {
  const domainValue = getDomain();

  return cookies.get('user-token', {
    domain: domainValue,
  });
};

const getBusinessToken = () => localStorage.getItem('business-token');

const isUserAuthenticated = () => !!getUserToken();

const hasVerificationToken = () => {
  const params = window.location.search
    ? new URLSearchParams(window.location.search)
    : new URLSearchParams(window.location.href.split('?')[1]);
  const verificationToken = params.get('verification_token');
  return verificationToken ? verificationToken.split('#')[0] : false;
};

const hasOauthToken = () => {
  const params = new URLSearchParams(window.location.search);
  const oAuthToken = params.get('code');
  params.delete('code');
  return oAuthToken ? oAuthToken : false;
};

const removeOauthToken = () => {
  window.location.href = `${window.location.protocol}//${window.location.hostname}/#/root/Instagram`;
};

const removeVerificationToken = () => {
  const params = window.location.search
    ? new URLSearchParams(window.location.search)
    : new URLSearchParams(window.location.href.split('?')[1]);
  const verificationToken = params.get('verification_token');
  if (verificationToken && verificationToken.split('#')[0]) {
    window.location.href = `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ''
    }`;
    params.delete('verification_token');
  }
};

export {
  logoutUser,
  logoutBusiness,
  getBusinessID,
  setBusinessID,
  getSubDomain,
  getDomain,
  loginUser,
  loginBusiness,
  isUserAuthenticated,
  getUserToken,
  getBusinessToken,
  redirectToBusiness,
  removeVerificationToken,
  hasVerificationToken,
  setTrackableCockie,
  getTrackableCockie,
  hasOauthToken,
  removeOauthToken,
};
