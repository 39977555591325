import React from 'react';
import { useTranslate } from 'react-translate';

import { Text, SwitchButton, TooltipWrapper, HelpTooltip } from '@bornlogic/gd-design-system';

import * as S from './styles';

function SwitchWithLabel({ toggleValue, onChange, label, hasError = false, tooltipLabel }) {
  const t = useTranslate('Units');

  return (
    <S.Container hasError={hasError}>
      <SwitchButton toggled={toggleValue} onChange={onChange} />
      <Text>{t(label)}</Text>
      {!!tooltipLabel && (
        <S.TooltipSpacing>
          <TooltipWrapper padding="10px" size="250px" orientation="top" content={t(tooltipLabel)}>
            <HelpTooltip color="blueGrey4" size="px12" iconSize="1" />
          </TooltipWrapper>
        </S.TooltipSpacing>
      )}
    </S.Container>
  );
}

export default SwitchWithLabel;
