import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslate } from 'react-translate';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import FileUpload from '@utils/imageUploader';
import { POST_COVER_PROFILE_IMAGE } from '@redux/actions/types';
import { GET_UNITS, UPDATE_UNITS_AFTER_RENAME } from '@redux/settings/types';
import { GET_SAS_URL } from '@redux/business/types';
import { IS_LOADING } from '@redux/loading/types';
import { SHOW_TOAST } from '@redux/toast/types';
import {
  PopoverButton,
  StatusLabel,
  TableActions,
  Text,
  TotalizerCard,
  Totalizers,
} from '@bornlogic/gd-design-system';
import EmptyContent from '@components/EmptyContent';
import { LoadingOld } from '@components/LoadingOld/LoadingOld';
import { useUnitsSummary } from '@utils/hooks/Bussiness';
import EditModal from '../../Scope/Report/ListAll/Table/Rows/ManageGroups/Modals/Edit/EditModalSettings';
import ModalChangeImages from '../../Scope/ProfileAndCover/ModalChangeImages';
import AddGroup from '../Groups/AddGroup';
import AddUnit from './AddUnit';
import ChangeTargeting from './ChangeTargeting';
import ReplicateAudience from './ReplicateAudience';
import ReplicateLeads from './ReplicateLeads';
import AdvancedConfig from './AdvancedConfig';
import UnlistAudience from './UnlistAudience';
import SchemaService from '../../../services/schema';
import InactivateUnits from './InactivateUnits';
import ActivateUnits from './ActivateUnits';
import ChangeGroupInBatchModal from './ChangeGroupInBatchModal';
import { primaryTheme as theme } from '../../../styles/themes';
import * as S from './styles';

export const STATUS_TYPES = {
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  FINISHED: 'FINISHED',
  ERROR: 'ERROR',
  INACTIVE: 'Inactive',
};

const getUnitStatus = (unit) => {
  if (unit.gd_status_unit === STATUS_TYPES.INACTIVE) {
    return STATUS_TYPES.INACTIVE;
  }

  return STATUS_TYPES[unit.status?.status.at(-1)?.status] || '';
};

export const HEADER_SELECT_OPTIONS = (t) => [
  {
    title: t('ACTIVES'),
    getCheckeds: (data, setGroupChecked) => {
      const newData = [...data];

      newData.forEach((row) => {
        const status = getUnitStatus(row);

        if (status === STATUS_TYPES.FINISHED && row.selectable) {
          row.checked = true;
          row.disabled = false;
        } else {
          row.checked = false;
          row.disabled = true;
        }
      });

      setGroupChecked(STATUS_TYPES.FINISHED);
      return newData;
    },
  },
  {
    title: t('CLEAR_FILTER'),
    getCheckeds: (data, setGroupChecked) => {
      const newData = [...data];
      newData.forEach((row) => {
        row.checked = false;
        row.disabled = false;
      });
      setGroupChecked('');
      return newData;
    },
  },
];

export const COLUMNS = (t) => [
  {
    title: t('C_NAME'),
    key: 'name',
    width: 50,
    align: 'left',
    formatter: ({ cellData: value }) => (
      <Text textColor={theme.colors.blueGrey2} weight={Text.variants.WEIGHT_VARIANTS.bold}>
        {value}
      </Text>
    ),
  },
  {
    title: t('C_GROUP'),
    key: 'parent',
    width: 20,
    align: 'left',
    formatter: ({ cellData: value }) => {
      const bgColor = 'grey3';
      const textColor = 'blueGrey2';
      let text;

      if (value && value.name) {
        text = value.name;
      }

      if (!text) {
        return null;
      }

      return (
        <StatusLabel textColor={textColor} bgColor={bgColor} minHeight="px8">
          {text}
        </StatusLabel>
      );
    },
  },

  {
    title: t('C_STATUS'),
    key: 'status',
    width: 20,
    align: 'left',
    formatter: (cellData) => {
      const value = cellData.cellData;
      let statusData;
      let statusText = '';
      let bgColor = 'green1';
      const textColor = 'white';

      if (value && value.status && value.status && value.status.length > 0) {
        statusData = value.status[value.status.length - 1];
      } else {
        return <Text />;
      }

      statusText = t(statusData.status);

      if (statusData.status === STATUS_TYPES.RUNNING) {
        bgColor = 'orange1';
      }

      if (statusData.status === STATUS_TYPES.PENDING) {
        bgColor = 'purple';
      }

      if (statusData.status === STATUS_TYPES.ERROR) {
        bgColor = 'red3';
      }

      if (cellData.rowData.gd_status_unit === STATUS_TYPES.INACTIVE) {
        bgColor = 'grey1';
        statusText = t('INACTIVE');
      }

      return (
        <StatusLabel textColor={textColor} bgColor={bgColor}>
          {statusText}
        </StatusLabel>
      );
    },
  },
];

export const setSameGroupSelectables = (tableData) => {
  const newTableData = [...tableData];
  const checkedRow = newTableData.find((row) => row.checked === true);

  const checkedUnitStatus = getUnitStatus(checkedRow);

  newTableData.forEach((row) => {
    row.disabled = checkedUnitStatus !== getUnitStatus(row);
  });

  return newTableData;
};

const Units = () => {
  // Imagens para subtituição de capa e perfil
  const [cover, setCover] = useState({});
  const [profile, setProfile] = useState({});

  // Controladores
  const [isOpenChangeEditModal, setIsOpenChangeEditModal] = useState(false);
  const [isOpenChangeImageModal, setIsOpenChangeImageModal] = useState(false);
  const [isOpenChangeTargeting, setIsOpenChangeTargeting] = useState(false);
  const [isOpenReplicateAudience, setIsOpenReplicateAudience] = useState(false);
  const [isOpenReplicateLeads, setIsOpenReplicateLeads] = useState(false);
  const [isOpenInactivateUnits, setIsOpenInactivateUnits] = useState(false);
  const [isOpenActivateUnits, setIsOpenActivateUnits] = useState(false);
  const [isOpenUnlistAudience, setIsOpenUnlistAudience] = useState(false);
  const [isOpenChangeGroupModal, setIsOpenChangeGroupModal] = useState(false);
  const [isAddGroupOpen, setIsAddGroupOpen] = useState(false);

  const [changeTargetingData, setChangeTargetingData] = useState({});
  const [addUnitModalStatus, setAddUnitModalStatus] = useState(false);
  const [rowsSelected, setRowsSelected] = useState([]);

  const [advancedConfigOpen, setAdvancedConfigOpen] = useState(false);
  const [sourceIds, setSourceIds] = useState([]);
  const [unitSourceId, setUnitSourceId] = useState('');

  const t = useTranslate('Units');

  const match = useRouteMatch();
  const groupCode = match.params.code || 'root';

  const dispatch = useDispatch();
  const businessReducer = useSelector((state) => state.businessReducer);
  const unitsData = useSelector((state) => state.settingsReducer.units.data);
  const unitsIsLoading = useSelector((state) => state.settingsReducer.units.isLoading);
  const unitsStatusFilter = useSelector((state) => state.settingsReducer.units.statusFilter);
  const unitsNamesFilter = useSelector((state) => state.settingsReducer.units.namesFilter);
  const authenticationReducer = useSelector((state) => state.authenticationReducer);
  const {
    dataUser: { email },
  } = authenticationReducer;
  const isBornlogicUser = email?.includes('@bornlogic');

  useEffect(() => {
    dispatch({
      type: GET_SAS_URL.REQUEST,
      version: '1.2',
    });
  }, [dispatch]);

  let units = unitsData.data;

  if (unitsStatusFilter) {
    units = units.filter((unit) => {
      return getUnitStatus(unit) === unitsStatusFilter;
    });
  }

  if (unitsNamesFilter) {
    units = units.filter((item) => {
      return Object.values(item.name)
        .join('')
        .toLowerCase()
        .includes(unitsNamesFilter.toLowerCase());
    });
  }

  const nextPageHash = unitsData.paging.next;

  const { search } = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);
  const rowsPerPage = urlSearchParams.get('rowsPerPage') ?? 9999;

  const fetchFirstUnits = useCallback(() => {
    dispatch({
      type: GET_UNITS.RESET,
    });

    dispatch({
      type: GET_UNITS.REQUEST,
      version: '1.2',
      source_id: groupCode,
      page_hash: '',
      limit: rowsPerPage,
    });
  }, [dispatch, groupCode, rowsPerPage]);

  useEffect(() => {
    fetchFirstUnits();
  }, [fetchFirstUnits]);

  const fetchMoreUnits = async () => {
    dispatch({
      type: GET_UNITS.REQUEST,
      version: '1.2',
      source_id: groupCode,
      page_hash: nextPageHash,
    });
  };

  const sentViewScreenEvent = useRef(false);

  useEffect(() => {
    if (sentViewScreenEvent.current) {
      return;
    }

    sentViewScreenEvent.current = true;
  }, []);

  const { activesNumber, errorNumber, totalUnits, loadTotalUnits, inactiveUnits, pendingUnits } =
    useUnitsSummary(groupCode);

  const handleAddUnit = () => {
    setAddUnitModalStatus(true);
  };

  useEffect(() => {
    loadTotalUnits();
  }, [loadTotalUnits]);

  const toggleChangeImageModal = () => {
    setIsOpenChangeImageModal(!isOpenChangeImageModal);
  };

  const toggleChangeEditModal = () => {
    setIsOpenChangeEditModal(!isOpenChangeEditModal);
  };

  const toggleReplicateAudience = () => {
    setIsOpenReplicateAudience(!isOpenReplicateAudience);
  };

  const toggleReplicateLeads = () => {
    setIsOpenReplicateLeads(!isOpenReplicateLeads);
  };

  const toggleInactivateUnits = () => {
    setIsOpenInactivateUnits(!isOpenInactivateUnits);
  };

  const toggleActivateUnits = () => {
    setIsOpenActivateUnits(!isOpenActivateUnits);
  };

  const toggleChangeTargetingModal = (unit) => {
    setChangeTargetingData({
      unitAccountId: unit.ad_account_ids === undefined ? null : unit.ad_account_ids[0],
      unitId: unit.source_id,
    });

    setIsOpenChangeTargeting(true);
  };

  const onDrop = (picture, isProfile) => {
    if (isProfile) {
      setProfile(picture);
      return;
    }
    setCover(picture);
  };

  const getPageIdsFromRowsSelected = () => {
    const pageIds = [];

    rowsSelected.forEach((row) => {
      if (row.page_ids) {
        pageIds.push(row.page_ids[0]);
      }
    });

    return pageIds;
  };

  const postCoverProfileImage = (data) => {
    dispatch({
      type: POST_COVER_PROFILE_IMAGE.REQUEST,
      version: '1.1',
      code: groupCode,
      data,
    });

    toggleChangeImageModal();
  };

  const setLoading = (isLoading) => {
    dispatch({
      type: IS_LOADING.SUCCESS,
      isLoading,
    });
  };

  const showToast = (message, type) => {
    dispatch({
      type: SHOW_TOAST.SUCCESS,
      message,
      messageType: type,
    });
  };

  const clearImages = () => {
    setProfile({});
    setCover({});
  };

  const postNewImages = () => {
    const { sas_url } = businessReducer.sasUrl.data;

    let profileUpload;
    let coverUpload;
    const profileUrl = '';
    const coverUrl = '';

    if (!profile.image && !cover.image) {
      showToast(t('MISSING_IMAGE'), 'error');
      return;
    }

    const data = {
      asset_ids: getPageIdsFromRowsSelected(),
      profile_picture_url: profileUrl,
      cover_picture_url: coverUrl,
    };

    profileUpload = new FileUpload();
    coverUpload = new FileUpload();

    setLoading(true);

    if (profile.image && cover.image) {
      profileUpload.UploadImage(profile.file, sas_url, 'profile');
      profileUpload.commitUploadSuccessCallback = (id, newProfileUrl) => {
        data.profile_picture_url = newProfileUrl;
        coverUpload.UploadImage(cover.file, sas_url, 'cover');
        coverUpload.commitUploadSuccessCallback = (id, newCoverUrl) => {
          data.cover_picture_url = newCoverUrl;
          postCoverProfileImage(data);
          clearImages();
        };
      };
    } else if (profile.image) {
      profileUpload.UploadImage(profile.file, sas_url, 'profile');
      profileUpload.commitUploadSuccessCallback = (id, newProfileUrl) => {
        data.profile_picture_url = newProfileUrl;
        postCoverProfileImage(data);
        clearImages();
      };
    } else {
      coverUpload.UploadImage(cover.file, sas_url, 'cover');
      coverUpload.commitUploadSuccessCallback = (id, newCoverUrl) => {
        data.cover_picture_url = newCoverUrl;
        postCoverProfileImage(data);
        clearImages();
      };
    }

    profileUpload.commitUploadErrorCallback = () => {
      setLoading(false);
      showToast('Houve um problema ao gravar, tente novamente mais tarde!', 'error');
    };

    coverUpload.commitUploadErrorCallback = () => {
      setLoading(false);
      showToast('Houve um problema ao gravar, tente novamente mais tarde!', 'error');
    };
  };

  const getSourceIds = async (data) => {
    const arrSourceIds = [];

    const response = await Promise.all(
      data.map((row) =>
        SchemaService.getAdAccounts('1.1', row.source_id, '?fields=id,name').then(
          (resp) => resp.data
        )
      )
    );

    response.forEach((resp) => {
      resp.forEach((item) => {
        arrSourceIds.push(item.source_id);
      });
    });

    return arrSourceIds;
  };

  const handleAdvancedConfigClick = async (row) => {
    const data = await getSourceIds([row]);

    if (data && data.length > 0) {
      setRowsSelected([row]);
      setUnitSourceId(row.source_id);
      setSourceIds(data);
      setAdvancedConfigOpen(true);
    } else {
      showToast('Esta unidade não possui uma conta vinculada no Facebook.', 'error');
    }
  };

  const TABLE_GROUPS_ACTIONS = () => ({
    FINISHED: {
      getRowOptions: () => {
        const options = [
          {
            title: t('CHANGE_IMAGE'),
            onClick: (row) => {
              setRowsSelected([row]);
              toggleChangeImageModal();
            },
          },
          {
            title: t('EDIT_AUDIENCE'),
            onClick: (unitSelected) => {
              toggleChangeTargetingModal(unitSelected);
            },
          },
          {
            title: t('UNLIST_AUDIENCE'),
            onClick: (row) => {
              setRowsSelected([row]);
              setIsOpenUnlistAudience(true);
            },
          },
          {
            title: t('ADVANCED_CONFIG_TITLE_MODAL'),
            onClick: handleAdvancedConfigClick,
          },
          {
            title: t('EDIT'),
            onClick: (row) => {
              setRowsSelected([row]);
              toggleChangeEditModal();
            },
          },
          {
            title: t('REPLICATE_AUDIENCE'),
            onClick: (row) => {
              setRowsSelected([row]);
              toggleReplicateAudience();
            },
          },
          {
            title: t('REPLICATE_LEADS'),
            onClick: (row) => {
              setRowsSelected([row]);
              toggleReplicateLeads();
            },
          },
        ];

        if (isBornlogicUser) {
          options.push({
            title: t('INACTIVATE_UNIT'),
            onClick: (row) => {
              toggleInactivateUnits();
              setRowsSelected([row]);
            },
          });
        }
        return options;
      },
      getGroupActions: () => [
        {
          title: t('CHANGE_IMAGE'),
          onClick: (rowsSelected) => {
            setRowsSelected([rowsSelected]);
            toggleChangeImageModal();
          },
        },
        {
          title: 'Mudar grupo',
          onClick: (rowsSelected) => {
            setRowsSelected(rowsSelected);
            setIsOpenChangeGroupModal(true);
          },
        },
      ],
    },
    ERROR: {
      getRowOptions: () =>
        isBornlogicUser
          ? [
              {
                title: t('INACTIVATE_UNIT'),
                onClick: (row) => {
                  toggleInactivateUnits();
                  setRowsSelected([row]);
                },
              },
            ]
          : [],
      getGroupActions: () => [],
    },
    RUNNING: {
      getRowOptions: () =>
        isBornlogicUser
          ? [
              {
                title: t('INACTIVATE_UNIT'),
                onClick: (row) => {
                  toggleInactivateUnits();
                  setRowsSelected([row]);
                },
              },
              {
                title: t('ACTIVATE_UNIT'),
                onClick: (row) => {
                  toggleActivateUnits();
                  setRowsSelected([row]);
                },
              },
            ]
          : [],
      getGroupActions: () => [],
    },
  });

  const getAdAccountId = () => {
    if (!rowsSelected) {
      return '';
    }

    if (
      rowsSelected &&
      rowsSelected.length > 0 &&
      rowsSelected[0].ad_account_ids &&
      rowsSelected[0].ad_account_ids.length > 0
    ) {
      return rowsSelected[0].ad_account_ids[0] || '';
    }

    return '';
  };

  const getPageId = () => {
    if (!rowsSelected) {
      return '';
    }

    if (
      rowsSelected &&
      rowsSelected.length > 0 &&
      rowsSelected[0].page_ids &&
      rowsSelected[0].page_ids.length > 0
    ) {
      return rowsSelected[0].page_ids[0] || '';
    }

    return '';
  };

  const handleAddGroup = () => {
    setIsAddGroupOpen(true);
  };
  return (
    <S.UnitsContainer>
      <S.ContentContainer>
        <S.HeaderUnitsContainer>
          <S.TotalizerContainer>
            <Totalizers totalTitle={t('UNITS_OPTION')} totalNumber={totalUnits}>
              <TotalizerCard number={activesNumber} label={t('ACTIVES')} bgColor="green1" />
              <TotalizerCard number={errorNumber} label={t('ERROR')} bgColor="red3" />
              <TotalizerCard
                number={inactiveUnits}
                label={inactiveUnits > 1 ? t('INACTIVE_PLURAL') : t('INACTIVE')}
                bgColor="grey1"
              />
              <TotalizerCard
                number={pendingUnits}
                label={pendingUnits > 1 ? t('PENDING_PLURAL') : t('PENDING')}
                bgColor="purple"
              />
            </Totalizers>
          </S.TotalizerContainer>
          <S.AddUnitContainer>
            <PopoverButton
              screenWidthForSingle={1700}
              title="Adicionar"
              items={[
                {
                  title: 'Incluir unidade',
                  subTitle: 'Configure uma nova unidade',
                  onClick: handleAddUnit,
                },
                {
                  title: 'Criar grupo',
                  subTitle: 'Crie um novo grupo',
                  onClick: handleAddGroup,
                },
              ]}
            />
          </S.AddUnitContainer>
        </S.HeaderUnitsContainer>
        <S.TableContainer>
          {units?.length === 0 ? (
            unitsIsLoading ? (
              <LoadingOld isLoading />
            ) : (
              <EmptyContent title={t('EMPTY_UNITS')} />
            )
          ) : (
            <TableActions
              tableGroupActions={TABLE_GROUPS_ACTIONS(t)}
              headerSelectOptions={HEADER_SELECT_OPTIONS(t)}
              setSameGroupSelectables={setSameGroupSelectables}
              data={units}
              rowHeight={60}
              columns={COLUMNS(t)}
              setRowID={(row, index) => `${index}-${row.name}`}
              getNextPageData={fetchMoreUnits}
              nextPage={nextPageHash}
              setRowGroup={(row) => {
                const rowOptions = TABLE_GROUPS_ACTIONS(t)[getUnitStatus(row)]?.getRowOptions();
                if (!rowOptions?.length) return STATUS_TYPES.INACTIVE;

                return getUnitStatus(row);
              }}
              isRowSelectable={(row) => {
                let status = getUnitStatus(row);

                if (
                  status === STATUS_TYPES.RUNNING ||
                  status === STATUS_TYPES.PENDING ||
                  status === STATUS_TYPES.INACTIVE
                ) {
                  return false;
                }

                return true;
              }}
              isRowHighlighted={(row) => {
                let status = getUnitStatus(row) || '';

                if (status === STATUS_TYPES.INACTIVE) {
                  return true;
                }

                return false;
              }}
              actionsText={(data) => {
                const arrCheckeds = data.filter((row) => row.checked === true);

                if (arrCheckeds && arrCheckeds.length === 1) {
                  return `1 ${t('SINGLE_SELECTED_UNIT')}`;
                }

                if (arrCheckeds && arrCheckeds.length > 1) {
                  return `${arrCheckeds.length} ${t('SOME_SELECTED_UNIT')}`;
                }

                return '';
              }}
            />
          )}
        </S.TableContainer>
      </S.ContentContainer>
      <AddUnit isOpen={addUnitModalStatus} onClose={() => setAddUnitModalStatus(false)} />
      <ReplicateLeads
        isOpen={isOpenReplicateLeads}
        onClose={() => setIsOpenReplicateLeads(false)}
        page_id={getPageId(rowsSelected)}
      />
      <ReplicateAudience
        isOpen={isOpenReplicateAudience}
        onClose={() => setIsOpenReplicateAudience(false)}
        ad_account_id={getAdAccountId(rowsSelected)}
      />
      <ChangeTargeting
        isOpen={isOpenChangeTargeting}
        onClose={() => setIsOpenChangeTargeting(false)}
        unitAccountId={changeTargetingData.unitAccountId}
        unitId={changeTargetingData.unitId}
      />
      <ModalChangeImages
        isOpen={isOpenChangeImageModal}
        handleToggle={() => setIsOpenChangeImageModal(!isOpenChangeImageModal)}
        onDrop={onDrop}
        postNewImages={postNewImages}
      />
      <EditModal
        source_id={rowsSelected[0]?.source_id}
        name={rowsSelected[0]?.name}
        instagramAccounts={rowsSelected[0]?.instagram_accounts}
        isOpenChangeEditModal={isOpenChangeEditModal}
        toggleChangeEditModal={toggleChangeEditModal}
        units={units}
        onRenameSuccess={({ sourceId, newName }) => {
          setIsOpenChangeEditModal(false);
          dispatch({
            type: UPDATE_UNITS_AFTER_RENAME,
            sourceId,
            newName,
          });
        }}
      />
      <AddGroup isOpen={isAddGroupOpen} onClose={() => setIsAddGroupOpen(false)} />
      <AdvancedConfig
        isOpen={advancedConfigOpen}
        onClose={() => setAdvancedConfigOpen(false)}
        rowsSelected={rowsSelected}
        sourceIds={sourceIds}
        unitSourceId={unitSourceId}
      />
      <InactivateUnits
        isOpen={isOpenInactivateUnits}
        onClose={() => setIsOpenInactivateUnits(false)}
        onSuccess={fetchFirstUnits}
        rowsSelected={rowsSelected}
      />
      <ActivateUnits
        isOpen={isOpenActivateUnits}
        onClose={() => setIsOpenActivateUnits(false)}
        onSuccess={fetchFirstUnits}
        rowsSelected={rowsSelected}
      />
      <UnlistAudience
        key={rowsSelected?.[0]?.source_id ?? ''}
        isOpen={isOpenUnlistAudience}
        onClose={() => setIsOpenUnlistAudience(false)}
        unitId={rowsSelected?.[0]?.source_id}
        adAccountId={rowsSelected?.[0]?.ad_account_ids?.[0] ?? null}
      />
      <ChangeGroupInBatchModal
        isOpen={isOpenChangeGroupModal}
        onClose={() => setIsOpenChangeGroupModal(false)}
        unitsIds={rowsSelected?.map((row) => row.source_id)}
        onSuccess={fetchFirstUnits}
      />
    </S.UnitsContainer>
  );
};

export default Units;
