const NewDashboardEN = {
  Dashboard: {
    ROAS_DESCRIPTION: 'Link unit sales and get ROAS in your analysis',
    LEARN_MORE: 'Learn more',
    this_month: 'This month',
    this_cycle: 'This cycle',
    yesterday: 'Yesterday',
    last_7d: 'Last 7 days',
    last_15d: 'Last 15 days',
    last_month: 'Last month',
    last_3m: 'Last 3 months',
    last_6m: 'Last 6 months',
    last_cycle: 'Last cycle',
    last_3: 'Last three cycles',
    last_6: 'Last six cycles',
  },
};

const NewDashboardPT = {
  Dashboard: {
    LEARN_MORE: 'Saber mais',
    ROAS_DESCRIPTION: 'Vincule as vendas das unidades e tenha o ROAS em suas análises',
    this_month: 'Mês atual',
    this_cycle: 'Mês atual',
    yesterday: 'Ontem',
    last_7d: 'Últimos 7 dias',
    last_15d: 'Últimos 15 dias',
    last_month: 'Mês Passado',
    last_3m: 'Últimos 3 meses',
    last_6m: 'Últimos 6 meses',
    last_cycle: 'Ciclo passado',
    last_3: 'Últimos três ciclos',
    last_6: 'Últimos seis ciclos',
  },
};

const NewDashboardES = {
  Dashboard: {
    ROAS_DESCRIPTION: 'Vincule las ventas de unidades y tenga ROAS en sus análisis',
    LEARN_MORE: 'Saiba mais',
    this_month: 'Mes actual',
    this_cycle: 'Este ciclo',
    yesterday: 'Ayer',
    last_7d: 'Últimos 7 días',
    last_15d: 'Últimos 15 días',
    last_month: 'Mes pasado',
    last_3m: 'Últimos 3 meses',
    last_6m: 'Últimos 6 meses',
    last_cycle: 'Ciclo pasado',
    last_3: 'Últimos 3 ciclos',
    last_6: 'Últimos 6 ciclos',
    LEARN_MORE: 'Sepa mas',
  },
};

export { NewDashboardPT, NewDashboardEN, NewDashboardES };
