import React from 'react';
import { Input } from '@bornlogic/gd-design-system';
import { MixPanelController } from '../../../controllers';

export default function InputWithMixPanel({
  eventName = '',
  className,
  onFocus,
  children,
  ...props
}) {
  function createMixPanelEvent() {
    const mixPanel = new MixPanelController({ eventName, eventType: 'click' });
    mixPanel.createEvent();
  }

  function newOnFocus(event) {
    if (eventName) createMixPanelEvent();
    if (onFocus) onFocus(event);
  }

  return (
    <div className={className} data-testid={'testMixPanelInput'}>
      <Input onFocus={newOnFocus} eventname={eventName} {...props}>
        {children}
      </Input>
    </div>
  );
}
