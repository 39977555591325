import dayjs from 'dayjs';

export const defineds = {
  startOfToday: dayjs().startOf('day').toDate(),
  endOfToday: dayjs().endOf('day').toDate(),
  startOfYesterday: dayjs().startOf('day').subtract(1, 'day').toDate(),
  endOfYesterday: dayjs().endOf('day').subtract(1, 'day').toDate(),
  sevenDaysAgo: dayjs().startOf('day').subtract(7, 'day').toDate(),
  startOfMonth: dayjs().startOf('month').toDate(),
  startOfYear: dayjs().startOf('year').toDate(),
  startOfLastMonth: dayjs().startOf('month').subtract(1, 'month').toDate(),
  endOfLastMonth: dayjs().endOf('month').subtract(1, 'month').toDate(),
};
