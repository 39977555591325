const PLANNER_PT = {
  PLANNER: {
    TITLE_CONFIRM_DELETION: 'Confirmar exclusão',
    TEXT_CONFIRM_DELETION: 'Você realmente deseja deletar o evento',
    TEXT_DELETION:
      'Se nenhuma meta estiver estabelecida a comunicação do app vai se comportar de forma padrão, sem nenhum tipo de personalização para ajudar as unidades de forma específica.',
    TEXT_CONFIRM_EDIT: 'Você deseja realmente editar este evento',
    TEXT_EDIT:
      'A comunicação do app será ajustada para se adequar às informações que foram alteradas.',
    TEXT_CONFIRM_CREATE: 'Você deseja realmente criar este evento',
    TEXT_INVESTMENTS:
      'No período selecionado, a comunicação do app (notificações, dicas, alertas, avisos) será estruturada de forma a ajudar a unidade a entender melhor como investir em conteúdo.',
    TEXT_CONTENT:
      'No período selecionado, a comunicação do app (notificações, dicas, alertas, avisos) será estruturada de forma a ajudar a unidade a entender melhor como criar conteúdo.',
    CONFIRM: 'Confirmar',
    SAVE: 'Salvar',
    CANCEL: 'Cancelar',
    SUCCESS_DELETION: 'Evento excluído com sucesso.',
    SUCCESS_CREATION: 'Evento criado com sucesso.',
    SUCCESS_UPDATE: 'Evento atualizado com sucesso.',
    ERROR_CREATE_OR_UPDATE:
      'Houve algum problema com a sua requisição, por favor, tente novamente.',
    INVESTMENT_TARGET: 'Meta de investimento',
    CONTENT_GOAL: 'Meta de conteúdo',
    CONTENT_GOAL_NUMBER: 'Meta de conteúdo em números',
    EDIT: 'Editar',
    NEW: 'Novo',
    EVENT: 'evento',
    GOAL: 'Objetivo',
    EVENT_GOAL: 'Qual o objetivo deste evento?',
    EVENT_TYPE: 'Tipo de evento',
    DATE: 'Data de início e término',
    SELECT: 'Selecionar',
    ALT_ICON_PLANNER: 'Ícone referente a um calendário',
    CREATE: 'Criar',
    NEW: 'Novo',
    DESCRIPTION_INVESTMENT:
      'Valor desejado a ser utilizado pelas unidades na criação de anúncios no período indicado',
    DESCRIPTION_CONTENT:
      'Número de publicações + anúncios desejado a ser criado pelas unidades no período indicado',
    NEW_EVENT: 'Criar novo evento',
    ALL: 'Ver todos',
    WEEKEND: 'Mostrar fins de semana',
    TODAY: 'Hoje',
    NEXT: 'Próximo',
    PAST_ERROR: 'Não é possível criar eventos em dias passados',
  },
};

const PLANNER_EN = {
  PLANNER: {
    TITLE_CONFIRM_DELETION: 'Confirm deletion',
    TEXT_CONFIRM_DELETION: 'Do you really want to delete the event',
    TEXT_DELETION:
      "If no goals are set, the app's communication will behave in a standard way, without any kind of customization to help the units in a specific way.",
    TEXT_CONFIRM_EDIT: 'Do you really want to edit this event',
    TEXT_EDIT: "The app's communication will be adjusted to suit the information that has changed.",
    TEXT_CONFIRM_CREATE: 'Do you really want to create this event',
    TEXT_INVESTMENTS:
      "In the selected period, the app's communication (notifications, tips, alerts, warnings) will be structured in order to help the unit understand how to invest in content.",
    TEXT_CONTENT:
      'In the selected period, the communication of the app (notifications, tips, alerts, warnings) will be structured in order to help the unit understand how to create content.',
    CONFIRM: 'Confirm',
    SAVE: 'Save',
    CANCEL: 'Cancel',
    SUCCESS_DELETION: 'Event deleted successfully.',
    SUCCESS_CREATION: 'Event created successfully.',
    SUCCESS_UPDATE: 'Event updated successfully.',
    ERROR_CREATE_OR_UPDATE: "There's some errors on your request, please try again later",
    INVESTMENT_TARGET: 'Investment goal',
    CONTENT_GOAL: 'Content goal',
    CONTENT_GOAL_NUMBER: 'Content goal in numbers',
    EDIT: 'Edit',
    NEW: 'New',
    EVENT: 'event',
    GOAL: 'Goal',
    EVENT_GOAL: 'What is the purpose of this event?',
    EVENT_TYPE: 'Type of event',
    DATE: 'Start and end date',
    SELECT: 'Select',
    ALT_ICON_PLANNER: "Planner's icon",
    CREATE: 'Create',
    NEW: 'New',
    DESCRIPTION_INVESTMENT:
      'Desired value to be used by the units in the creation of ads in the indicated period',
    DESCRIPTION_CONTENT:
      'Number of publications + desired advertisements to be created by the units in the indicated period',
    NEW_EVENT: 'Create new event',
    ALL: 'See all',
    WEEKEND: 'Show weekends',
    TODAY: 'Today',
    NEXT: 'Next',
    PAST_ERROR: 'Unable to create events in past days',
  },
};

const PLANNER_ES = {
  PLANNER: {
    TITLE_CONFIRM_DELETION: 'Confirmar la eliminación',
    TEXT_CONFIRM_DELETION: '¿Realmente quieres eliminar el evento',
    TEXT_DELETION:
      'Si no se establecen objetivos, la comunicación de la aplicación se comportará de forma estándar, sin ningún tipo de personalización para ayudar a las unidades de forma específica.',
    TEXT_CONFIRM_EDIT: '¿Realmente quieres editar este evento',
    TEXT_EDIT:
      'La comunicación de la aplicación se ajustará para adaptarse a la información que ha cambiado.',
    TEXT_CONFIRM_CREATE: '¿Realmente quieres crear este evento',
    TEXT_INVESTMENTS:
      'En el período seleccionado, la comunicación de la aplicación (notificaciones, consejos, alertas, advertencias) se estructurará para ayudar la unidad a comprender mejor cómo invertir en contenido.',
    TEXT_CONTENT:
      'En el período seleccionado, la comunicación de la aplicación (notificaciones, consejos, alertas, advertencias) se estructurará de manera que ayude la unidad a comprender mejor cómo crear contenido.',
    CONFIRM: 'Confirmar',
    SAVE: 'Ahorrar',
    CANCEL: 'Cancelar',
    SUCCESS_DELETION: 'Evento eliminado con éxito.',
    SUCCESS_CREATION: 'Evento creado con éxito.',
    SUCCESS_UPDATE: 'Evento actualizado con éxito.',
    ERROR_CREATE_OR_UPDATE: 'Hubo un problema con su solicitud, inténtelo de nuevo.',
    INVESTMENT_TARGET: 'Meta de inversión',
    CONTENT_GOAL: 'Meta de contenido',
    CONTENT_GOAL_NUMBER: 'Meta de contenido en números',
    EDIT: 'Editar',
    NEW: 'Nuevo',
    EVENT: 'evento',
    GOAL: 'Objetivo',
    EVENT_GOAL: '¿Cuál es el propósito de este evento?',
    EVENT_TYPE: 'Tipo de evento',
    DATE: 'Fecha de inicio y finalización',
    SELECT: 'Seleccione',
    ALT_ICON_PLANNER: 'Icono de un calendario',
    CREATE: 'Crear',
    NEW: 'Nuevo',
    DESCRIPTION_INVESTMENT:
      'Valor deseado a ser utilizado por las unidades en la creación de anuncios en el período indicado',
    DESCRIPTION_CONTENT:
      'Número de publicaciones + anuncios deseados a ser creados por las unidades en el período indicado',
    NEW_EVENT: 'Crear nuevo evento',
    ALL: 'Ver todo',
    WEEKEND: 'Mostrar los fines de semana',
    TODAY: 'Este dia',
    NEXT: 'Siguiente',
    PAST_ERROR: 'No se pueden crear eventos en días anteriores',
  },
};

export { PLANNER_PT, PLANNER_EN, PLANNER_ES };
