import styled from 'styled-components';

export const ChooseCompanyWrapper = styled('div')`
${({
    theme: {
      spacing, breakpoint, colors, rounded,
    },
  }) => `
    background: ${colors.white};
    box-shadow: ${spacing.none} ${spacing.px10} ${spacing.px30} ${colors.grey2};
    border-radius: ${rounded.px10};
    margin: ${spacing.px60} ${spacing.none} ${spacing.px30};
    width: 500px;
    max-width: ${spacing.full};
    box-sizing: border-box;
    padding: ${spacing.px60};
    @media screen and (max-width: ${breakpoint.sm}) {
        padding: ${spacing.px15} ${spacing.px15};
        margin-left: ${spacing.px15};
        margin-right: ${spacing.px15};
    }
`}
`;

export const ChooseCompanyTitle = styled('h5')`
${({ theme: { spacing } }) => `
    margin: ${spacing.none} ${spacing.none} ${spacing.px60};
`}
`;

export const ChooseCompanyList = styled('div')`
${({ theme: { spacing } }) => `
    list-style-type: none;
    padding-left: ${spacing.none};
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: ${spacing.px60};
`}
`;

export const ChooseCompanyItem = styled('button')`
${({
    theme: {
      spacing, colors, rounded, breakpoint,
    },
  }) => `
    border: 1px solid ${colors.grey2};
    border-radius: ${rounded.px10};
    background: ${colors.white};
    padding: ${spacing.px10} ${spacing.px25};
    margin-bottom: ${spacing.px15};
    min-width: 320px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    outline: none;
    box-sizing: border-box;
    &:hover {
        background: ${colors.grey3};
    }
    @media screen and (max-width: ${breakpoint.sm}) {
        min-width: 240px;
        width: 240px;
    }
`}
`;

export const ChooseCompanyImage = styled('figure')`
${({ theme: { spacing } }) => `
    margin: ${spacing.none} ${spacing.px10} ${spacing.none} ${spacing.none};
    width: ${spacing.middle};
    max-width: 130px;
    max-height: 50px;
    aling-items: center;
    & > img {
        max-width: ${spacing.full};
        max-height: 50px;
    }
`}
`;

export const ChooseCompanyInfo = styled('div')`
${({ theme: { spacing } }) => `
    margin: ${spacing.none} ${spacing.none} ${spacing.none} ${spacing.px10};
    width: ${spacing.middle};
    text-align: left;
`}
`;

export const ChooseCompanyName = styled('div')`
${({ theme: { fontSize, colors } }) => `
    font-size: ${fontSize.px16};
    color: ${colors.blueGrey2};
`}
`;

export const ChooseCompanyUsername = styled('div')`
${({ theme: { fontSize, colors } }) => `
    font-size: ${fontSize.px10};
    color: ${colors.blue2};
`}
`;

export const GoBack = styled('button')`
${({ theme: { fontSize, colors, fontWeight } }) => `
    color: ${colors.blue2};
    font-size: ${fontSize.px18};
    font-weight: ${fontWeight.light};
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
`}
`;
