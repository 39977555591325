const UserTableEN = {
  UserTable: {
    default_user: 'User',
    admin: 'Administrator',
    invited: 'Invited',
    active: 'Active',
    blocked: 'Blocked',
    NAME: 'Name',
    ROLE: 'Role',
    USER_OPTION: 'Users',
    INVITE_USERS: 'Invite Users',
    REVOKE_INVITE: 'Revoke invite',
    RESEND_INVITE: 'Reinvite User',
    GROUP: 'Group',
    SELECT_GROUP: 'Select a group',
    INVITED: 'Invited',
    ACTIVE: 'Active',
    ACTIVE_EMPLOYEE: 'Active users',
    ACTIVE_ADMIN: 'Active administrators',
    BLOCK: 'Block',
    BLOCKED: 'Blocked',
    UNBLOCK: 'Unblock',
    EDIT_GROUP: 'Edit group',
    EDIT_CAPABILITIES: 'Edit capabilities',
    EDIT_ROLE: 'Edit role',
    EDIT_AUDIENCE: 'Edit audience',
    REPLICATE_CAPABILITIES: 'Replicate capabilities',
    CLEAR_FILTER: 'Clear selection',
    SINGLE_SELECTED_USER: 'selected user',
    SOME_SELECTED_USER: 'selected users',
  },
};

const UserTableES = {
  UserTable: {
    default_user: 'Usuario',
    admin: 'Administrador',
    invited: 'Invitado',
    active: 'Activo',
    blocked: 'Obstruido',
    NAME: 'Nombre',
    ROLE: 'Rol',
    USER_OPTION: 'Usuarios',
    INVITE_USERS: 'Invitar usuarios',
    REVOKE_INVITE: 'Revocar invitación',
    RESEND_INVITE: 'Reenviar invitación',
    GROUP: 'Grupo',
    SELECT_GROUP: 'Seleccione un grupo',
    INVITED: 'Invitados',
    ACTIVE: 'Activos',
    ACTIVE_EMPLOYEE: 'Usuarios activos',
    ACTIVE_ADMIN: 'Administrador activos',
    BLOCK: 'Bloquear',
    BLOCKED: 'Bloqueados',
    UNBLOCK: 'Desbloquear',
    EDIT_GROUP: 'Editar grupo',
    EDIT_CAPABILITIES: 'Editar permisos',
    EDIT_ROLE: 'Editar rol',
    REPLICATE_CAPABILITIES: 'Replicar permisos',
    CLEAR_FILTER: 'Borrar selección',
    CHANGE: 'Editar',
    SINGLE_SELECTED_USER: 'usuario seleccionado',
    SOME_SELECTED_USER: 'usuarios seleccionados',
  },
};

const UserTablePT = {
  UserTable: {
    default_user: 'Usuário',
    admin: 'Administrador',
    invited: 'Convidado',
    active: 'Ativo',
    blocked: 'Bloqueado',
    NAME: 'Nome',
    ROLE: 'Função',
    USER_OPTION: 'Usuários',
    INVITE_USERS: 'Convidar Usuários',
    REVOKE_INVITE: 'Revogar convite',
    RESEND_INVITE: 'Reenviar convite',
    GROUP: 'Grupo',
    SELECT_GROUP: 'Selecione um grupo',
    INVITED: 'Convidados',
    ACTIVE: 'Ativo',
    ACTIVE_EMPLOYEE: 'Usuários ativos',
    ACTIVE_ADMIN: 'Administradores ativos',
    BLOCK: 'Bloquear',
    BLOCKED: 'Bloqueados',
    UNBLOCK: 'Desbloquear',
    EDIT_GROUP: 'Editar grupo',
    EDIT_CAPABILITIES: 'Editar permissões',
    EDIT_ROLE: 'Editar função',
    REPLICATE_CAPABILITIES: 'Replicar permissões',
    EDIT_AUDIENCE: 'Alterar público',
    CLEAR_FILTER: 'Limpar seleção',
    CHANGE: 'Alterar',
    SINGLE_SELECTED_USER: 'usuário selecionado',
    SOME_SELECTED_USER: 'usuários selecionados',
  },
};

export { UserTableEN, UserTableES, UserTablePT };
