/* eslint-disable react/jsx-filename-extension */
import React, { useContext } from 'react';

import { useDispatch } from 'react-redux';

import { Text, Select, Checkbox } from '@bornlogic/gd-design-system';
import { SHOW_TOAST } from '@redux/toast/types';
import AdvancedConfigsContext from '@utils/contexts/AdvancedConfig';
import { AdvancedDialogContainer } from '../styles';
import Box from '@components/Box';

import { primaryTheme as theme } from '../../../../../styles/themes';

const arrAvailableAges = [...Array(53).keys()].map((key) => {
  const geralKey = key + 13;

  let title = geralKey;
  const value = geralKey;

  if (geralKey === 65) {
    title = `${geralKey}+`;
  }

  return {
    title,
    value,
  };
});

const PublicForm = () => {
  const dispatch = useDispatch();
  const { maxAge, setMaxAge, minAge, setMinAge, male, setMale, female, setFemale } =
    useContext(AdvancedConfigsContext);

  const showToast = (message, type) => {
    dispatch({
      type: SHOW_TOAST.SUCCESS,
      message,
      messageType: type,
    });
  };

  const processValidations = {
    minAge(value) {
      if (maxAge !== '' && maxAge < value) {
        showToast('Idade mínima deve ser menor que a idade máxima.', 'error');
        setMinAge('');
      } else {
        setMinAge(value);
      }
    },
    maxAge(value) {
      if (minAge !== '' && minAge > value) {
        showToast('Idade máximo deve ser maior que a idade mínima.', 'error');
        setMaxAge('');
      } else {
        setMaxAge(value);
      }
    },
  };

  const handleSelectAge = (validationType, value) => {
    processValidations[validationType](value);
  };

  return (
    <AdvancedDialogContainer>
      <Box flex="1" mb={theme.spacing.px24}>
        <Text>Defina aqui o público que será impactado por seus anúncios.</Text>
      </Box>
      <Box flex="1" my={theme.spacing.px5}>
        <Text weight="bold">Idade</Text>
      </Box>
      <Box flex="1">
        <Text>Escolha idades mínima e/ou máxima nos campos abaixo.</Text>
      </Box>
      <Box flex="1" alignItems="center" my="8px">
        <Box>
          <Select
            items={arrAvailableAges}
            onChange={(value) => handleSelectAge('minAge', value)}
            selectValue={minAge}
            placeholder="-"
          />
        </Box>
        <Box mx={theme.spacing.px24} alignItems="center">
          <Text>A</Text>
        </Box>
        <Box>
          <Select
            items={arrAvailableAges}
            onChange={(value) => handleSelectAge('maxAge', value)}
            selectValue={maxAge}
            placeholder="-"
          />
        </Box>
      </Box>
      <Box flex="1" mt={theme.spacing.px30} mb={theme.spacing.px16}>
        <Text weight="bold">Gênero</Text>
      </Box>
      <Box flex="1" mt={theme.spacing.px4}>
        <Box>
          <Checkbox checked={male} onChange={() => setMale(!male)} />
        </Box>
        <Box alignItems="center" ml={theme.spacing.px4}>
          <Text>Masculino</Text>
        </Box>
      </Box>
      <Box flex="1" mt={theme.spacing.px4}>
        <Box>
          <Checkbox checked={female} onChange={() => setFemale(!female)} />
        </Box>
        <Box alignItems="center" ml={theme.spacing.px4}>
          <Text>Feminino</Text>
        </Box>
      </Box>
    </AdvancedDialogContainer>
  );
};

export default PublicForm;
