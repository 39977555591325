import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const headingDefault = css`
  margin-block-start: 0;
  margin-block-end: 0;

  font-family: ${({ theme }) => theme.fontFamily.default};
  margin-bottom: ${({ theme, ...props }) => theme.spacing[props.marginBottom]};
  font-weight: ${(props) => props.weight};
  color: ${({ theme, ...props }) => theme.colors[props.color]};
`;

const H1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.px40};
  ${headingDefault};
`;

const H2 = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.px32};
  ${headingDefault};
`;

const H3 = styled.h3`
  font-size: ${({ theme }) => theme.fontSize.px24};
  ${headingDefault};
`;

const H4 = styled.h4`
  font-size: ${({ theme }) => theme.fontSize.px20};
  ${headingDefault};
`;

const H5 = styled.h5`
  font-size: ${({ theme }) => theme.fontSize.px16};
  ${headingDefault};
`;

const H6 = styled.h6`
  font-size: ${({ theme }) => theme.fontSize.px14};
  ${headingDefault};
`;

const variantMapping = {
  h1: () => H1,
  h2: () => H2,
  h3: () => H3,
  h4: () => H4,
  h5: () => H5,
  h6: () => H6,
};

const Title = ({ tag, color, fontWeight, children, marginBottom }) => {
  const Component = variantMapping[tag] ? variantMapping[tag]() : H1;

  return (
    <Component weight={fontWeight} color={color} marginBottom={marginBottom}>
      {children}
    </Component>
  );
};

Title.defaultProps = {
  as: 'h1',
  weight: 'bold',
  color: 'blueGrey2',
  children: '',
};

Title.propTypes = {
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  weight: PropTypes.oneOf(['normal', 'bold']),
  color: PropTypes.string,
};

export default Title;
