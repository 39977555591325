import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { haveIntersectionBetweenTwoArrays } from '../../utils/helpers';

export const MainMenuWrapper = styled.nav`
  display: flex;
  flex-direction: column;

  ${({ theme: { spacing } }) => css`
    margin-left: ${spacing.px40};
  `}
`;

export const MainMenuList = styled.ul`
  list-style-type: none;
  align-items: stretch;
  display: flex;
  min-width: 720px;

  ${({ theme: { spacing, breakpoint } }) => css`
    margin: ${spacing.none};
    padding-left: ${spacing.none};
    height: ${spacing.px80};
    @media screen and (max-width: ${breakpoint.xl}) {
      display: none !important;
    }
  `}
`;

export function isActive(title: string, pathname: string) {
  const activeSubpages = ['GDTVItem', 'MediaItem'];
  const currentPageIsAnyCommunicationActiveSubpage = haveIntersectionBetweenTwoArrays(
    pathname.split('/'),
    activeSubpages
  );

  if (title === 'Communication' && currentPageIsAnyCommunicationActiveSubpage) {
    return true;
  }
  if (title === 'TradeCI' && pathname.includes('Campaigns')) return true;
  if (title === 'Insights' && pathname.includes('Trade/Dashboard')) return true;
  if (title === 'CI' && pathname.includes('CampaignItem')) return true;

  return pathname.indexOf(title) !== -1;
}

interface MainMenuItemProps {
  currentTitle: string;
  active: string;
  isBornlogic: boolean;
}

export const MainMenuItem = styled.li<MainMenuItemProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${({ theme: { spacing, fontWeight, colors }, currentTitle, active, isBornlogic }) => css`
    margin: ${isBornlogic ? `${spacing.none} ${spacing.px10}` : `${spacing.none} ${spacing.px16}`};

    @media screen and (max-width: 1650px) {
      margin: ${isBornlogic
        ? `${spacing.none} ${spacing.px8} ${spacing.none} ${spacing.none}`
        : `${spacing.none} ${spacing.px16}`};
    }
    ${isActive(currentTitle, active) &&
    css`
      h6 {
        font-weight: ${fontWeight.bold} !important;
        color: ${colors.blueSecurity} !important;
      }
    `}
  `}
`;

export const MainMenuLink = styled(Link)`
  text-decoration: none;
  align-items: center;
  display: flex;

  ${({ theme: { fontWeight, colors, spacing, breakpoint } }) => css`
    height: ${spacing.px80};
    h6 {
      position: relative;
      font-weight: ${fontWeight.normal};
      color: ${colors.blueGrey2};
    }
    :focus {
      h6 {
        font-weight: ${fontWeight.light};
        color: ${colors.blueSecurity};
      }
    }
    :hover {
      h6 {
        color: ${colors.blueSecurity};
      }
    }
    @media (max-width: ${breakpoint.xl}) {
      height: ${spacing.px20};
    }
  `}
`;
