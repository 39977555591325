import { Capabilities } from 'model/auth';
import { useEffect, useMemo } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { buildCapabilitiesObject } from './const';
import { CapabilitiesResponse } from './types';

export function useCapability(capabilities: Capabilities[], noCapabilityCallback?: Function) {
  const { businessTokenData } = useSelector((state: RootStateOrAny) => state.authenticationReducer);

  const capabilitiesResult = useMemo<CapabilitiesResponse>(
    () => buildCapabilitiesObject(businessTokenData, capabilities),
    [businessTokenData, capabilities]
  );

  useEffect(() => {
    const handleNoCapabilities = () => {
      if (capabilitiesResult) {
        const isAllowed = Object.values(capabilitiesResult);
        if (isAllowed.includes(false)) {
          if (noCapabilityCallback) noCapabilityCallback();
        }
      }
    };

    handleNoCapabilities();
  }, [businessTokenData, capabilitiesResult, noCapabilityCallback]);

  return capabilitiesResult;
}
