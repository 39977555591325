import React, { lazy, Suspense, useState } from 'react';

import Container from '@components/Container';
import { useTranslate } from 'react-translate';

import MainMenu from '@containers/MainMenu';
import { isDEV } from '@utils/auth';

import { FETCH_AUTO_COMPLETE_GROUP } from '@redux/generic/types';

import images from '@images';

import { validateCapability } from '@utils/helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExchangeTenantSelector } from './ExchangeTenantSelector';
import SearchBar from './SearchBar';

const MenuProfileMobile = lazy(() => import('@containers/Menus/MenuProfileMobile'));
import * as S from './styles';

import tradeLogo from '@images/tradeLogo.svg';
import TradeHeaderTrackChart from '../Trade/Insights/LoadingContent/HeaderProgressChart';

import SurveyModalWrapper from './SurveyWrapper';
import { useHistory, useRouteMatch } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useRef } from 'react';
import { useEffect } from 'react';
import { checkIfIsTradeView } from '../../utils/helpers';
import { SUBDOMAIN_DICT, developmentLogoTag } from './consts';
/* import Notifications from './Notifications'; */
import HelpButton from './HelpButton';
import Profile from './Profile';

import { useSurvey } from '@utils/contexts';

function Header() {
  const t = useTranslate('Header');
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { trigger } = useSurvey();

  const { businessTokenData } = useSelector((state) => state.authenticationReducer);
  const {
    autoCompleteGroup: { data },
  } = useSelector((state) => state.genericReducer);

  const isTradeView = checkIfIsTradeView();
  const [shouldShowTradeChart, setShouldShowTradeChart] = useState(false);
  const [searchBarIsOpen, setSearchBarIsOpen] = useState(false);
  const [showMenuMobile, setShowMenuMobile] = useState(false);

  const hasTradeAdsIndustryCapability = validateCapability(businessTokenData, 'TRADE_ADS_INDUSTRY');
  const inputRef = useRef();

  useEffect(() => {
    function escapeTrigger(e) {
      if (e.keyCode === 27) {
        setSearchBarIsOpen(false);
      }
    }

    document.body.addEventListener('keydown', (e) => escapeTrigger(e), true);

    if (isTradeView) {
      if (match.url?.includes('Trade/Dashboard')) {
        setShouldShowTradeChart(true);
      }
    }

    return () => {
      document.body.removeEventListener('keydown', escapeTrigger);
    };
  }, [match.url, isTradeView]);

  useEffect(() => {
    if (searchBarIsOpen) {
      focusInputRef();
    }
  }, [searchBarIsOpen]);

  function focusInputRef() {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }

  function handleFocus() {
    resetAutoComplete();
    setSearchBarIsOpen(true);
  }

  function resetAutoComplete() {
    dispatch({
      type: FETCH_AUTO_COMPLETE_GROUP.RESET,
    });
  }

  function submitGroupOnEnter(e) {
    const { params } = match;

    if (!!data.length && !!data[0].source_id && e.keyCode === 13) {
      setSearchBarIsOpen(false);
      const path = history.location.pathname.split('/')[2];
      let url = `/${data[0].source_id}/${path}`;

      if (params?.module) url += `/${params.module}`;
      if (params?.group) url += `/${params.group}`;

      history.push(url);
    }
  }

  function shouldFixPosition() {
    const { url } = match;

    return (
      !url.includes('Users/Management') &&
      !url.includes('CI') &&
      !url.includes('/CampaignItem/') &&
      isTradeView
    );
  }

  function toggleMenu() {
    setShowMenuMobile((state) => !state);
  }

  const subdomain = window.location.host.split('.')[0];
  const tag = isDEV ? developmentLogoTag : SUBDOMAIN_DICT[subdomain];

  return (
    <S.HeaderWrapper id="header" isTradeView={isTradeView} shouldFixPosition={shouldFixPosition()}>
      <Container>
        <SurveyModalWrapper trigger={trigger} />

        <S.HeaderStyled>
          <S.HeaderInitial>
            <S.LogoWrapper
              to={
                !hasTradeAdsIndustryCapability && isTradeView
                  ? '/root/Trade/Dashboard'
                  : hasTradeAdsIndustryCapability
                  ? '/root/TradeAds'
                  : `/${match.params.code || 'root'}/Report`
              }
            >
              {isTradeView ? (
                <img src={tradeLogo} />
              ) : (
                <S.LogoImage src={images.logoGd} alt="Gerentes Digitais" />
              )}
            </S.LogoWrapper>
            <S.LogoTag>{tag}</S.LogoTag>
            <S.MenuWrapper>
              <MainMenu />
            </S.MenuWrapper>
            {hasTradeAdsIndustryCapability && <ExchangeTenantSelector />}
          </S.HeaderInitial>
          <S.HeaderFinal>
            {shouldShowTradeChart && <TradeHeaderTrackChart />}
            {isTradeView && <HelpButton />}
            {!hasTradeAdsIndustryCapability && !isTradeView && (
              <>
                <S.ButtonSearch onClick={handleFocus}>
                  <FontAwesomeIcon icon={['fal', 'search']} />
                  <p>{t('SEARCH')}</p>
                </S.ButtonSearch>

                <HelpButton />
                {/* <Notifications /> */}
              </>
            )}

            <Profile />
          </S.HeaderFinal>
          <S.HeaderFinalMobile>
            <S.ToggleMenu onClick={toggleMenu}>
              <FontAwesomeIcon icon={['fal', 'bars']} />
            </S.ToggleMenu>
          </S.HeaderFinalMobile>
          {searchBarIsOpen ? (
            <SearchBar
              focused={searchBarIsOpen}
              onKeyDown={submitGroupOnEnter}
              closeAutocomplete={() => setSearchBarIsOpen(false)}
              clickOpen={handleFocus}
              innerRef={inputRef}
            />
          ) : (
            <Suspense fallback={null}>
              <S.MenuMobile open={showMenuMobile}>
                <MenuProfileMobile
                  open={showMenuMobile}
                  clickClose={toggleMenu}
                  closeAutocomplete={() => setSearchBarIsOpen(false)}
                  clickOpen={handleFocus}
                  innerRef={inputRef}
                />
              </S.MenuMobile>
            </Suspense>
          )}
        </S.HeaderStyled>
      </Container>
    </S.HeaderWrapper>
  );
}

export default Header;
