import { useCallback, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

export const useUrlHandler = (param) => {
  const history = useHistory();
  const { code, module, group } = useParams();
  const { search } = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);

  const handleChangeUrlValue = useCallback(
    (newValue) => {
      urlParams.set(param, newValue);

      history.replace(`/${code}/Report/${module}/${group}?${urlParams}`);
    },
    [param, history, code, module, group, urlParams]
  );

  return { handleChangeUrlValue, value: urlParams.get(param) };
};
