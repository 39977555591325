import React, { useState, useEffect, useCallback } from 'react';
import { useTranslate } from 'react-translate';
import { LoadingOld } from '@components/LoadingOld/LoadingOld';
import Input from '@components/Input/Input';
import { Preview } from './Preview';
import * as C from './styles';
import * as S from './styles';
import { permitedVideos, permitedImages } from '@utils/helpers';
import useShowToast from '@utils/hooks/Common/useShowToast';
import { commitPost, createFile, postFilePart } from '../services';

const FilesUpload = ({ userConfigs, removeBusiness }) => {
  const t = useTranslate('Medias');

  const [newFiles, setNewFiles] = useState([]);
  const [hasFileUploading, setHasFileUploading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [fileParts, setFileParts] = useState([]);
  const showToast = useShowToast();
  const [fileConfigs, setFileConfigs] = useState({});
  const [fileUrl, setFileUrl] = useState('');
  const [loadings, setLoadins] = useState({ midia: false, uploading: false });

  const prepareFileToUpload = useCallback(
    (uploadConfigs, fileParams) => {
      if (fileParams.fileSize <= 0) return;
      const { max_chunk_size_in_bytes, offset_method } = uploadConfigs.upload_settings;

      const fileSizeRangesInBytes = {
        small: 10485760,
        medium: 52428800,
      };
      let end = 0;
      const filesParts = [];
      let start = 0;
      let bytesRemaining = fileParams.fileSize;
      let offsetValue = 0;

      if (fileParams.fileSize <= fileSizeRangesInBytes.small) {
        end = 2048 * 1024;
      } else if (
        fileParams.fileSize > fileSizeRangesInBytes.small &&
        fileParams.fileSize <= fileSizeRangesInBytes.medium
      ) {
        end = 2048 * 2048;
      } else {
        end = 2048 * 2560 > max_chunk_size_in_bytes ? max_chunk_size_in_bytes : 2048 * 2560;
      }

      for (let i = 0; bytesRemaining > 0; i++) {
        const filePiece = newFiles[0].file.slice(start, end);

        if (filePiece.size > 0) {
          bytesRemaining -= filePiece.size;
          if (offset_method === 'BYTE_COUNT') {
            offsetValue = start;
          } else {
            offsetValue = i;
          }
          start += filePiece.size;
          end += filePiece.size;

          const formData = new FormData();
          formData.append('data', filePiece);
          formData.append('offset', offsetValue);

          filesParts.push({ formData, offsetValue });
        }
      }

      setFileParts(filesParts);
      setIsCompleted(true);
      setLoadins({ ...loadings, midia: false });
    },
    [newFiles]
  );

  const handleRemove = useCallback(() => {
    setHasFileUploading(false);
    setFileParts([]);
    setIsCompleted(false);
    setFileUrl('');
    setNewFiles([]);
  }, []);

  const getFileConfigs = useCallback(async () => {
    const params = {
      fileName: newFiles[0]?.file.name,
      fileSize: newFiles[0]?.file.size,
      ...userConfigs,
    };

    try {
      const response = await createFile(params);
      if (response.status === 200) {
        setFileConfigs(response.data);
        prepareFileToUpload(response.data, params);
      }
    } catch (e) {
      showToast('Houve algum erro, tente novamente!', 'error');
      handleRemove();
    }
  }, [prepareFileToUpload, newFiles, handleRemove, userConfigs, showToast]);

  useEffect(() => {
    if (!hasFileUploading && newFiles.length > 0) {
      getFileConfigs();
      setHasFileUploading(true);
    }
  }, [hasFileUploading, newFiles, getFileConfigs]);

  const handleChange = (files) => {
    setLoadins({ ...loadings, midia: true });
    const newFilesStructure = [];

    for (let i = 0; i < files.target.files.length; i++) {
      newFilesStructure.push({
        file: files.target.files[i],
        uniqueCode: new Date().getTime() + files.target.files[i].name,
        uploadPercentage: 0,
      });
    }
    setNewFiles([...newFilesStructure]);
  };

  const handleSubmit = async () => {
    setLoadins({ ...loadings, uploading: true });
    const { id: fileId } = fileConfigs;
    const { token } = userConfigs;

    if (!isCompleted) {
      showToast('Há dados faltando, verifique as informações!', 'error');
      return;
    }

    const processed = [];
    const start = Date.now();

    for (const file of fileParts) {
      try {
        const response = await postFilePart(file.formData, token, fileId);
        processed.push(response);
      } catch (e) {
        showToast('Houve algum erro ao enviar, reinicie o processo!', 'error');
        handleRemove();
        setLoadins({ ...loadings, uploading: false });
        break;
      }
    }

    if (processed.length === fileParts.length) {
      try {
        const response = await commitPost(fileId, token, { status: 'COMPLETED' });
        setLoadins({ ...loadings, uploading: false });
        setFileUrl(response.data.url);
      } catch (e) {
        showToast('Houve algum erro ao enviar, reinicie o processo!', 'error');
        setLoadins({ ...loadings, uploading: false });
        handleRemove();
      }
    }

    const end = Date.now();

    console.log(`Tempo para upload: ${(end - start) / 1000}`);
  };

  const handleRestart = () => {
    handleRemove();
    removeBusiness();
  };

  return (
    <S.Container>
      <S.Wrapper width="384px" className={`image_container`}>
        <C.UploadMultipleFiles>
          {!!fileParts?.length && (
            <Preview
              newFiles={newFiles}
              fileUrl={fileUrl}
              setNewFiles={setNewFiles}
              handleRemove={handleRemove}
              t={t}
            />
          )}

          <div style={{ marginBottom: '2px' }}>
            {loadings.midia && !fileParts.length && <LoadingOld isLoading />}
            {!!fileParts.length && !fileUrl && (
              <div>
                <p>Arquivo pronto para ser enviado!</p>
              </div>
            )}
          </div>

          {newFiles.length < 1 && (
            <S.Wrapper direction="column" className="image_container-content">
              <S.Wrapper align="center">Selecione o arquivo de mídia a ser enviado:</S.Wrapper>
              <S.Wrapper align="left" className="text list" gap="0px">
                <S.Wrapper gap="0px"></S.Wrapper>
              </S.Wrapper>
              <C.LabelStyled
                htmlFor="multiple-files"
                disabled={!userConfigs.token || !userConfigs.folderId}
              >
                Selecionar Mídia {loadings.midia && <i className="fad fa-spinner fa-spin" />}
              </C.LabelStyled>

              <C.InputStyled>
                <C.ButtonWrapper>
                  <Input
                    type="file"
                    id="multiple-files"
                    label=""
                    accept={`${permitedVideos}, ${permitedImages}`}
                    onChange={handleChange}
                  />
                </C.ButtonWrapper>
              </C.InputStyled>
            </S.Wrapper>
          )}
        </C.UploadMultipleFiles>
      </S.Wrapper>
      {fileUrl ? (
        <S.DetailsContainer className="submit" isCompleted={isCompleted}>
          <p>Envio concluído com sucesso!</p>
          <a href={fileUrl} target="_blank" rel="noreferrer">
            Abrir mídia em nova aba
          </a>
          <button onClick={handleRestart}>Enviar outra mídia.</button>
        </S.DetailsContainer>
      ) : (
        <S.DetailsContainer className="submit" isCompleted={isCompleted}>
          <p>Fazer upload da mídia</p>
          <button onClick={handleSubmit}>
            Confirmar {loadings.uploading && <i className="fad fa-spinner fa-spin" />}
          </button>
        </S.DetailsContainer>
      )}
    </S.Container>
  );
};

export default FilesUpload;
