/* eslint-disable react/jsx-filename-extension */
import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { useTranslate } from 'react-translate';
import { isNaN, debounce } from 'lodash';

import {
  Text,
  Radio,
  Input,
  TextArea,
  HelpTooltip,
  TooltipWrapper,
} from '@bornlogic/gd-design-system';

import AdvancedConfigsContext, { LOCALIZATION_OPTION } from '@utils/contexts/AdvancedConfig';
import { useFacebookCities } from '@hooks/Facebook/useFacebookCities';
import Select from 'react-select';

import { AdvancedDialogContainer } from '../styles';
import { primaryTheme as theme } from '@styles/themes';
import Box from '../../../../../components/Box';

const TooltipSpacing = styled.div`
  margin-top: ${(props) => props.marginTop};
  margin-left: 3px;
`;

// Some cities come duplicated from endpoint
function removeDuplicateObjectsBasedOnKeyFromArray(array, key) {
  if (!array?.length) return [];


  const uniqueKeys = [];

  return array.filter((element) => {
    const isDuplicate = uniqueKeys.includes(element[key]);

    if (!isDuplicate) {
      uniqueKeys.push(element[key]);

      return true;
    }

    return false;
  });
}

// Some regions (SP and RJ) come with a (state) flag
function getRegionLabel(region) {
  if (!region) return ''

  return region.replace('(state)', '').trim();
}

const LocalizationForm = () => {
  const {
    zipCodes,
    setStrZipCodes,
    setLatitude,
    setLongitude,
    setRadius,
    localizationOption,
    setLocalizationOption,
    citiesIds,
    setCitiesIds,
    latitude,
    longitude,
    radius,
    strZipCodes,
    tKey,
  } = useContext(AdvancedConfigsContext);

  const t = useTranslate('Units');

  const translateKey = `${tKey}_LOCALIZATION_FORM`;

  const [citySearchInput, setCitySearchInput] = useState('');
  const [citySearchQuery, setCitySearchQuery] = useState('');
  const debouncedSetSearchQuery = useRef(
    debounce((searchText) => setCitySearchQuery(searchText), 500)
  ).current;

  const citySearchResult = useFacebookCities({ search: citySearchQuery, limit: 10 });

  const citySearchResultOptions = removeDuplicateObjectsBasedOnKeyFromArray(
    citySearchResult.data?.data
      ?.filter((city) => !citiesIds.includes(city.id))
      ?.map((city) => {
        return {
          value: city.id,
          label: city.name + ` (${getRegionLabel(city.region)})`,
        };
      }),
    'value'
  );

  const citiesResult = useFacebookCities({ ids: citiesIds });

  const selectedCities = citiesResult.data?.data?.map((city) => {
    return {
      value: city.id,
      label: city.name + ` (${getRegionLabel(city.region)})`,
    };
  });

  const renderZipCodes = useCallback(() => {
    let newStr = '';
    let separator = '';

    if (zipCodes) {
      zipCodes.forEach((zip) => {
        newStr += separator + String(zip);
        separator = ', ';
      });
    }

    setStrZipCodes(newStr);
  }, [zipCodes, setStrZipCodes]);

  useEffect(() => {
    renderZipCodes();
  }, [renderZipCodes]);

  const verifyTargetingInputs = (minComparison, maxComparison, value) => {
    if (value === '.') return false;
    if (value === '-') return true;
    if (value.indexOf('.') === value.length - 1) return true;

    if (!isNaN(Number(value))) {
      let firstNumber = value;

      if (value.indexOf('.') > -1) {
        firstNumber = Number(value.substring(0, value.indexOf('.')));
      }

      return firstNumber >= minComparison && firstNumber <= maxComparison;
    }

    return false;
  };

  const formValidations = {
    latitude(value) {
      if (verifyTargetingInputs(-90, 90, value)) {
        setLatitude(value);
      }
    },
    longitude(value) {
      if (verifyTargetingInputs(-180, 180, value)) {
        setLongitude(value);
      }
    },
    radius(value) {
      setRadius(value);
    },
    strZipCodes(value) {
      setStrZipCodes(value);
    },
  };

  const handleInputs = (inputType, event) => {
    event.preventDefault();

    formValidations[inputType](event.target.value);
  };

  return (
    <AdvancedDialogContainer>
      <Box flex="1" mb={theme.spacing.px24}>
        <Text>
          {t(`${translateKey}_TITLE_1`)} {t(`${translateKey}_TITLE_2`)}
        </Text>
      </Box>
      <Box flex="1" mt={theme.spacing.px24} mb={theme.spacing.px18}>
        <Radio
          label={t(`${translateKey}_LATITUDE_SWITCH_LABEL`)}
          value={LOCALIZATION_OPTION.GEO_LOCATION}
          checked={localizationOption === LOCALIZATION_OPTION.GEO_LOCATION}
          onClick={() => setLocalizationOption(LOCALIZATION_OPTION.GEO_LOCATION)}
          id="latitude_radio"
        />
        <TooltipSpacing marginTop={'-3px'}>
          <TooltipWrapper
            padding="10px"
            size="250px"
            orientation="right"
            content={t('LATITUDE_LOGINTUDE_HELP_TOOLTIP')}
          >
            <HelpTooltip color="blueGrey4" size="px12" iconSize="1" />
          </TooltipWrapper>
        </TooltipSpacing>
      </Box>
      {localizationOption === LOCALIZATION_OPTION.GEO_LOCATION && (
        <>
          <Box flex="1" my="12px" pb="2px">
            <Text>{t(`${translateKey}_LATITUDE_SWITCH_DESCRIPTION`)}</Text>
          </Box>
          <Box flex="1" mt="12px">
            <Box flex="1">
              <Text weight="bold">{t(`${translateKey}_LATITUDE_LABEL`)}</Text>
              <TooltipSpacing marginTop={'-6px'}>
                <TooltipWrapper
                  padding="10px"
                  size="250px"
                  orientation="right"
                  content={t('LATITUDE_HELP_TOOLTIP')}
                >
                  <HelpTooltip color="blueGrey4" size="px12" iconSize="1" />
                </TooltipWrapper>
              </TooltipSpacing>
            </Box>
            <Box flex="1">
              <Text weight="bold">{t(`${translateKey}_LONGITUDE_LABEL`)}</Text>
              <TooltipSpacing marginTop={'-6px'}>
                <TooltipWrapper
                  padding="10px"
                  size="250px"
                  orientation="left"
                  content={t('LONGITUDE_HELP_TOOLTIP')}
                >
                  <HelpTooltip color="blueGrey4" size="px12" iconSize="1" />
                </TooltipWrapper>
              </TooltipSpacing>
            </Box>
          </Box>
          <Box flex="1" my="8px">
            <Box flex="1" mr="2px">
              <Input
                onChange={(event) => handleInputs('latitude', event)}
                placeHolder=""
                value={latitude}
              />
            </Box>
            <Box flex="1" ml="2px">
              <Input
                onChange={(event) => handleInputs('longitude', event)}
                placeHolder=""
                value={longitude}
              />
            </Box>
          </Box>
          <Box flex="1" my="8px">
            <Box flex="1">
              <Text weight="bold">{t(`${translateKey}_RADIUS_TITLE`)}</Text>
              <TooltipSpacing marginTop={'-5px'}>
                <TooltipWrapper
                  padding="10px"
                  size="250px"
                  orientation="right"
                  content={t('RADIUS_HELP_TOOLTIP')}
                >
                  <HelpTooltip color="blueGrey4" size="px12" iconSize="1" />
                </TooltipWrapper>
              </TooltipSpacing>
            </Box>
          </Box>
          <Box flex="1" my="8px">
            <Box flex="1">
              <Text>{t(`${translateKey}_RADIUS_DESCRIPTION`)}</Text>
            </Box>
          </Box>
          <Box flex="1">
            <Box flex="1" mr="2px">
              <Input
                onChange={(event) => handleInputs('radius', event)}
                placeHolder=""
                value={radius}
              />
            </Box>
            <Box flex="1" />
          </Box>
        </>
      )}

      <Box flex="1" mt={theme.spacing.px24} mb={theme.spacing.px18}>
        <Radio
          label={t(`${translateKey}_CITIES_TITLE`)}
          value={LOCALIZATION_OPTION.CITIES}
          checked={localizationOption === LOCALIZATION_OPTION.CITIES}
          onClick={() => setLocalizationOption(LOCALIZATION_OPTION.CITIES)}
          id="city_radio"
        />

        <TooltipSpacing marginTop={'-3px'}>
          <TooltipWrapper
            padding={theme.spacing.px10}
            size="250px"
            orientation="right"
            content={t(`${translateKey}_CITIES_DESCRIPTION`)}
          >
            <HelpTooltip color="blueGrey4" size="px12" iconSize="1" />
          </TooltipWrapper>
        </TooltipSpacing>
      </Box>

      {localizationOption === LOCALIZATION_OPTION.CITIES && (
        <>
          <Box flex="1" mb={theme.spacing.px8}>
            <Text>
              <label htmlFor="localization-form-cities">{t(`${translateKey}_CITIES_LABEL`)}</label>
            </Text>
          </Box>

          <Select
            inputId="localization-form-cities"
            isMulti
            isClearable
            closeMenuOnScroll={false}
            isLoading={citySearchResult.isValidating}
            placeholder={t(`${translateKey}_CITIES_PLACEHOLDER`)}
            noOptionsMessage={() =>
              citySearchQuery === ''
                ? t(`${translateKey}_CITIES_EMPTY_QUERY`)
                : t(`${translateKey}_CITIES_EMPTY_RESULTS`)
            }
            value={selectedCities}
            options={citySearchResultOptions}
            onChange={(selectedCities) => {
              setCitiesIds(selectedCities.map((city) => city.value));
            }}
            inputValue={citySearchInput}
            onInputChange={(inputText, event) => {
              if (event.action !== 'input-blur' && event.action !== 'menu-close') {
                setCitySearchInput(inputText);
                debouncedSetSearchQuery(inputText);
              }
            }}
          />
        </>
      )}

      <Box flex="1" mt="24px" mb="18px">
        <Radio
          label={t(`${translateKey}_ADRESS_LABEL`)}
          value={LOCALIZATION_OPTION.ZIP_CODE}
          checked={localizationOption === LOCALIZATION_OPTION.ZIP_CODE}
          onClick={() => setLocalizationOption(LOCALIZATION_OPTION.ZIP_CODE)}
          id="adress_radio"
        />
        <TooltipSpacing marginTop={'-3px'}>
          <TooltipWrapper
            padding="10px"
            size="250px"
            orientation="right"
            content={t('ADRESS_HELP_TOOLTIP')}
          >
            <HelpTooltip color="blueGrey4" size="px12" iconSize="1" />
          </TooltipWrapper>
        </TooltipSpacing>
      </Box>
      {localizationOption === LOCALIZATION_OPTION.ZIP_CODE && (
        <>
          <Box flex="1" my="8px">
            <Text weight="bold">{t(`${translateKey}_ADRESS_DESCRIPTION`)}</Text>
          </Box>
          <Box flex="1">
            <TextArea
              hasBorder
              rows={5}
              placeHolder={t(`${translateKey}_ADRESS_PLACEHOLDER`)}
              onChange={(event) => handleInputs('strZipCodes', event)}
              value={strZipCodes}
            />
          </Box>
        </>
      )}
    </AdvancedDialogContainer>
  );
};

export default LocalizationForm;
