export const getCount = () => {
  const count = Math.floor((window.innerWidth - 242) / 260);
  switch (count) {
    case 7:
      return 21;
    case 8:
      return 24;
    default:
      return 20;
  }
};

export const handleInsightsValues = ({ insights = null, feedType, capabilities }) => {
  const INITIAL_STATE = {
    thumbsValue: 0,
    pointerValue: 0,
    printValue: 0,
    spendValue: 0,
    linkValue: 0,
    conversionPurchaseValue: 0,
  };
  if (!insights) {
    return INITIAL_STATE;
  }

  if (insights.hasOwnProperty('offsite_conversion.fb_pixel_purchase_conversion_value')) {
    INITIAL_STATE.offsite_conversion =
      insights['offsite_conversion.fb_pixel_purchase_conversion_value'] || 0;
  }
  if (insights.hasOwnProperty('offline_conversion.purchase_conversion_value')) {
    INITIAL_STATE.offline_conversion =
      insights['offline_conversion.purchase_conversion_value'] || 0;
  }
  if (insights.hasOwnProperty('roas_all_conversion_value')) {
    INITIAL_STATE.roas_all = insights.roas_all_conversion_value || 0;
  }
  if (insights.hasOwnProperty('roas_off_conversion_value')) {
    INITIAL_STATE.roas_off = insights.roas_off_conversion_value || 0;
  }
  if (insights.hasOwnProperty('roas_on_conversion_value')) {
    INITIAL_STATE.roas_on = insights.roas_on_conversion_value || 0;
  }

  if (insights.hasOwnProperty('post_reactions_like_total')) {
    INITIAL_STATE.thumbsValue = insights.post_reactions_like_total || 0;
  } else if (capabilities.hasMetricInvolvement) {
    INITIAL_STATE.thumbsValue = insights.post_involvement || 0;
  } else {
    INITIAL_STATE.thumbsValue = insights.post_engagement || 0;
  }

  if (insights.hasOwnProperty('post_clicks')) {
    INITIAL_STATE.pointerValue = insights.post_clicks || 0;
  } else {
    INITIAL_STATE.pointerValue = insights.clicks || 0;
  }

  if (insights.hasOwnProperty('link_click')) {
    INITIAL_STATE.linkValue = insights.link_click || 0;
  }

  if (insights.hasOwnProperty('offsite_conversion.fb_pixel_purchase')) {
    INITIAL_STATE.conversionPurchaseValue = insights['offsite_conversion.fb_pixel_purchase'] || 0;
  }

  if (feedType === 'POST') {
    if (insights.hasOwnProperty('post_impressions_organic')) {
      INITIAL_STATE.printValue = insights.post_impressions_organic || 0;
    }
  } else if (insights.hasOwnProperty('impressions')) {
    INITIAL_STATE.printValue = insights.impressions || 0;
  }

  INITIAL_STATE.spendValue = insights.spend;

  return {
    ...INITIAL_STATE,
  };
};
