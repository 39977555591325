// Types
import {
  GET_CAMPAIGN,
  GET_CAMPAIGN_REPORT,
  GET_CAMPAIGN_FINANCIAL_METRICS,
  GET_CAMPAIGN_FEED,
} from './types';

import { INITIAL_STATE, handleNewFetch, handleFetchMore } from './consts';

export default function campaignsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CAMPAIGN.RESET:
      return INITIAL_STATE;
    case GET_CAMPAIGN.REQUEST:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          isLoading: true,
        },
      };
    case GET_CAMPAIGN.SUCCESS:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          data: {
            ...state.campaign.data,
            ...action.data,
          },
          isLoading: false,
        },
      };

    case GET_CAMPAIGN_REPORT.REQUEST:
      return {
        ...state,
        campaignReport: {
          ...state.campaignReport,
          isLoading: true,
        },
      };
    case GET_CAMPAIGN_REPORT.SUCCESS:
      return {
        ...state,
        campaignReport: {
          ...state.campaignReport,
          data: {
            ...state.campaignReport.data,
            ...action.data,
          },
          isLoading: false,
        },
      };

    case GET_CAMPAIGN_FINANCIAL_METRICS.REQUEST:
      return {
        ...state,
        campaignFinancialMetrics: {
          ...state.campaignFinancialMetrics,
          isLoading: true,
        },
      };
    case GET_CAMPAIGN_FINANCIAL_METRICS.SUCCESS:
      return {
        ...state,
        campaignFinancialMetrics: {
          ...state.campaignFinancialMetrics,
          data: {
            ...state.campaignFinancialMetrics.data,
            ...action.data,
          },
          isLoading: false,
        },
      };

    case GET_CAMPAIGN_FEED.REQUEST:
      return {
        ...state,
        campaignFeed: {
          ...state.campaignFeed,
          isLoading: true,
        },
      };
    case GET_CAMPAIGN_FEED.SUCCESS:
      if (action?.isFetchMore) return handleFetchMore({ action, state });
      return handleNewFetch({ action, state });

    default:
      return state;
  }
}
