import styled from 'styled-components';

export const Container = styled('div')`
  display: flex;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing.px16}
  min-width: 300px;
  justify-content: center;
  h5 {
    font-size: ${({ theme }) => theme.fontSize.px14}
    font-weight: 400;
    color: #131313;
  }
  span{
    margin-right: ${({ theme }) => theme.spacing.px8};
  };

  :hover {
    cursor: pointer;
    background: #f3f3f4;
  }
`;
