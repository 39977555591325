import { useEffect, useState } from 'react';
import { CompanyService } from '@services';

const useEditStatus = (tenant) => {
  const [error, setError] = useState('');
  const [originalStatus, setOriginalStatus] = useState(null);
  const [updatingStatus, setUpdatingStatus] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [confirmModalVisible, setConfirmModalVisibility] = useState(false);

  useEffect(() => {
    if (tenant && tenant.status) {
      setUpdatingStatus(tenant.status);
      setOriginalStatus(tenant.status);
    }
  }, [tenant]);

  const confirmStatus = () => {
    setError('');

    if (updatingStatus) setConfirmModalVisibility(true);
    if (!updatingStatus) setError('Selecione uma opção de contrato do cliente!');
  };

  const cancelUpdate = () => {
    setUpdatingStatus(tenant?.status);
    setConfirmModalVisibility(false);
    setIsUpdating(false);
  };

  const saveStatusDatabase = async () => {
    const body = {
      status: updatingStatus,
    };

    const response = await CompanyService.updateCompany(body);

    if (response.status === 200) {
      setOriginalStatus(updatingStatus);
      setIsUpdating(false);
      setError('');
      setConfirmModalVisibility(false);

      return true;
    }

    return false;
  };

  return {
    updatingStatus,
    setUpdatingStatus,
    isUpdating,
    setIsUpdating,
    confirmModalVisible,
    originalStatus,
    confirmStatus,
    saveStatusDatabase,
    cancelUpdate,
    error,
  };
};

export default useEditStatus;
