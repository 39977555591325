import React from 'react';
import { MixPanelController } from '../../../controllers';

export default function WrapperClickWithMixPanel({ eventName, onClick, children, ...rest }) {
  function onClickWithEvent(event) {
    if (eventName) {
      const mixPanel = new MixPanelController({ eventName, eventType: 'click' });
      mixPanel.createEvent();
    }

    if (onClick) {
      onClick(event);
    }
  }

  return (
    <div onClick={onClickWithEvent} {...rest}>
      {children}
    </div>
  );
}

