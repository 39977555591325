import styled, { css } from 'styled-components';
import { ButtonWithMixPanel } from '@components/MixPanel';

export const FeedButtonItem = styled(ButtonWithMixPanel)`
  button {
    all: unset;
    :hover {
      background-color: none;
      background: none;
    }

    ${({ theme, active }) => css`
      background: none;
      border: none;
      font-size: ${theme.fontSize.px12};
      color: ${theme.colors.blueGrey3};
      padding: 16px ${theme.spacing.px15};
      text-decoration: none;
      white-space: nowrap;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      outline: none;
      &::first-letter {
        text-transform: uppercase;
      }

      ${active &&
      css`
        background: ${theme.colors.white};
        color: ${theme.colors.blueSecurity};
        border-bottom: 2px solid ${theme.colors.blueSecurity};
      `}

      @media screen and (max-width: ${theme.breakpoint.md}) {
        width: ${theme.spacing.full};
        box-sizing: border-box;
        text-align: center;
        padding: ${theme.spacing.px15} ${theme.spacing.px15};
        &:last-child {
          border-bottom: none;
        }
      }
    `}
  }
`;
