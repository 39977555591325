import React from 'react';
import { VideoPlay } from '@components/VideoPlay';
import * as C from './styles';
import * as S from './styles';

export const Preview = ({ newFiles, handleRemove, type = 'form', t, fileUrl }) => {
  return newFiles.length && newFiles[0].file.type.includes('image') ? (
    <>
      {fileUrl && <C.ZoomMedia src={fileUrl} />}
      {!fileUrl && (
        <S.CancelButton
          onClick={() => {
            handleRemove();
          }}
        >
          {type === 'form' && 'Remover Imagem'}
        </S.CancelButton>
      )}
    </>
  ) : (
    <>
      {fileUrl && (
        <>
          <C.Thumbnail>
            <VideoPlay
              media={newFiles.length && newFiles[0].file}
              video={fileUrl}
              t={t}
              noClose
              noDownload
            />
          </C.Thumbnail>
        </>
      )}
      {!fileUrl && (
        <S.CancelButton
          onClick={() => {
            handleRemove();
          }}
        >
          {type === 'form' && 'Remover vídeo'}
        </S.CancelButton>
      )}
    </>
  );
};
