import styled from 'styled-components';

export const Spacing = styled.span`
  margin-left: 20px;
`;

export const FilterInput = styled.input`
  margin-right: 20px;
  height: 46px;
  border: none;
  font-size: 16px;
  font-weight: 300;
  color: #577584;
  padding-left: 5px;
  border-bottom: 2px solid #0087c8;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: #577584;
  }
`;
