import styled, { css } from 'styled-components';

export const ContainerWrapper = styled('div')`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    width: ${theme.spacing.full};
  `}
`;

export const ContainerItem = styled('div')`
  ${({ theme, noLeft, spacingDefault, maxWidth }) => css`
    padding: ${theme.spacing.none} ${theme.spacing.px25};
    padding-left: ${noLeft && theme.spacing.none};
    width: ${theme.spacing.full};
    max-width: ${maxWidth};
    box-sizing: border-box;
    @media screen and (max-width: ${theme.breakpoint.xl}) {
      padding: ${`${theme.spacing.none} ${theme.spacing.px30} ${theme.spacing.none} ${
        noLeft && !spacingDefault ? theme.spacing.none : theme.spacing.px30
      }`};
    }
    @media screen and (max-width: ${theme.breakpoint.md}) {
      padding: ${`${theme.spacing.none} ${theme.spacing.px30} ${theme.spacing.none} ${
        noLeft ? theme.spacing.px30 : theme.spacing.px30
      }`};
    }
  `}
`;
