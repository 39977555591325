import {
  PreviewModalEN,
  PreviewModalPT,
  PreviewModalES,
} from '../Components/PreviewModal/translate';
import {
  WillHaveMoneyEN,
  WillHaveMoneyPT,
  WillHaveMoneyES,
} from '../Components/WillHaveMoney/translate';

const SugestedApprovalsModalEN = {
  SugestedApprovalsModal: {
    CHOOSE_A_DATE: 'Choose the date of duration',
    GO_BACK: 'Go back',
    NEXT: 'Next',
    PUBLISH: 'Publish',
    TYPE_HERE: 'Type here...',
    START_LABEL: 'Start',
    END_LABEL: 'End',
    VALUE_TITLE: 'Enter total investment amount',
    TOTAL_VALUE: 'Total value',
    TO: 'to',
    PERIOD: 'Period',
    ACCOUNT_BALLANCE: 'Account balance',
    EDIT_DESCRITION_SUCCESS: 'The media was changed successfully.',
    EDIT_DESCRITION_FAILURE: 'An error has occurred. Try to change the media.',
    EDIT_DESCRIPTION: 'Edit',
    INSERT_DESCRIPTION: 'Insert description',
    APPROVE_SUCCESS: 'Media approved successfully!',
    APPROVE_ERROR: 'Error while approving media, try again later or contact an administrator.',
    ...WillHaveMoneyEN,
    ...PreviewModalEN,
  },
};

const SugestedApprovalsModalPT = {
  SugestedApprovalsModal: {
    CHOOSE_A_DATE: 'Escolha a data de duração',
    GO_BACK: 'Voltar',
    NEXT: 'Próximo',
    PUBLISH: 'Enviar',
    TYPE_HERE: 'Digite aqui...',
    START_LABEL: 'Início',
    END_LABEL: 'Término',
    VALUE_TITLE: 'Insira o valor total do investimento',
    TOTAL_VALUE: 'Valor total',
    TO: 'a',
    PERIOD: 'Período',
    ACCOUNT_BALLANCE: 'Saldo da conta',
    EDIT_DESCRITION_SUCCESS: 'A mídia foi alterada com sucesso.',
    EDIT_DESCRITION_FAILURE: 'Ocorreu um erro ao tentar alterar a mídia.',
    EDIT_DESCRIPTION: 'Editar',
    INSERT_DESCRIPTION: 'Inserir descrição',
    APPROVE_SUCCESS: 'Mídia aprovada com sucesso!',
    APPROVE_ERROR:
      'Erro ao aprovar a mídia, tente novamente mais tarde ou entre em contato com um administrador.',
    ...WillHaveMoneyPT,
    ...PreviewModalPT,
  },
};

const SugestedApprovalsModalES = {
  SugestedApprovalsModal: {
    CHOOSE_A_DATE: 'Choose the date of duration',
    GO_BACK: 'Go back',
    NEXT: 'Next',
    PUBLISH: 'Publish',
    TYPE_HERE: 'Type here...',
    START_LABEL: 'Start',
    END_LABEL: 'End',
    VALUE_TITLE: 'Enter total investment amount',
    TOTAL_VALUE: 'Total value',
    TO: 'to',
    PERIOD: 'Period',
    ACCOUNT_BALLANCE: 'Account balance',
    EDIT_DESCRITION_SUCCESS: 'The media was changed successfully.',
    EDIT_DESCRITION_FAILURE: 'An error has occurred. Try to change the media.',
    EDIT_DESCRIPTION: 'Editar',
    INSERT_DESCRIPTION: 'Insertar descripción',
    APPROVE_SUCCESS: 'Media approved successfully!',
    APPROVE_ERROR: 'Error while approving media, try again later or contact an administrator.',
    ...WillHaveMoneyES,
    ...PreviewModalES,
  },
};

export { SugestedApprovalsModalPT, SugestedApprovalsModalEN, SugestedApprovalsModalES };
