import BFFApi from '@utils/bffApi';

const OnboardingService = {
  getTrails() {
    return BFFApi.get('/api/v1/onboarding/trail');
  },
  getStepsByTrail(trailId) {
    return BFFApi.get(`/api/v1/onboarding/steps-by-trail/${trailId}`);
  },
  getOnboardingSteps() {
    return BFFApi.get('/api/v1/onboarding/step');
  },
  saveComment(body) {
    return BFFApi.post('/api/v1/onboarding/save-comment', body);
  },
  updateComment(body) {
    return BFFApi.put('/api/v1/onboarding/update-comment', body);
  },
  deleteComment(body) {
    return BFFApi.put('​/api/v1/onboarding/delete-comment', body);
  },
  startStep(body) {
    return BFFApi.post('/api/v1/onboarding/start-step', body);
  },
  finishStep(body) {
    return BFFApi.post('/api/v1/onboarding/finish-step', body);
  },
};

export default OnboardingService;
