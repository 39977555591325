import React, { useState } from 'react';
import { isEqual } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as S from './styles';
import DownloadButton from 'components/DownloadButton';

export const VideoPlay = ({ media, video, hideVideo, noClose, noDownload, autoPlay = true }) => {
  const [videoPlaying, setVideoPlaying] = useState(null);

  const videoPlayHandle = (video) => {
    const videos = document.getElementsByClassName('videos');

    if (videoPlaying !== null) {
      try {
        document.exitPictureInPicture();
      } catch (error) {}
    }

    setVideoPlaying(video.currentTarget);

    Array.from(videos).forEach((item) => {
      if (item !== video.currentTarget) {
        item.pause();
      }
    });
  };

  const videoStopHandle = (video) => {
    if (isEqual(video.currentTarget, videoPlaying)) {
      setVideoPlaying(null);
    }
  };
  const tagVideo = document.querySelector('.videos');
  const isPaused = tagVideo && tagVideo.paused;
  const isEnded = tagVideo && tagVideo.ended;
  return (
    <S.VideoOverwrite isPaused={isPaused} isEnded={isEnded}>
      {!noClose && (
        <S.CloseVideo className="close-video" onClick={hideVideo}>
          <FontAwesomeIcon icon={['fal', 'times']} />
        </S.CloseVideo>
      )}
      <video
        autoPlay={autoPlay}
        poster={media?.thumbnail_url}
        preload="metadata"
        id={media.id}
        width="95%"
        height="95%"
        className="videos"
        controls
        onPlay={videoPlayHandle}
        onPause={videoStopHandle}
      >
        <source src={video} type="video/mp4" />
      </video>
      {!noDownload && (
        <S.DownloadButtonConteiner>
          <DownloadButton donwloadUrl={video} />
        </S.DownloadButtonConteiner>
      )}
    </S.VideoOverwrite>
  );
};

export default VideoPlay;
