import React from 'react';
import { useTranslate } from 'react-translate';
import { useDispatch, useSelector } from 'react-redux';
import { SET_FEED_TYPE, SET_FEED_ORDER_BY } from '@redux/feed/types';
import * as S from './styles';

export default function FeedButton() {
  const t = useTranslate('Feed');
  const { feedType, isLoading } = useSelector((state) => state.feedReducer);
  const dispatch = useDispatch();

  const sortMetricsBy = (event) => {
    dispatch({
      type: SET_FEED_ORDER_BY.SUCCESS,
      feedOrderBy: event,
    });
  };

  const handleChangedFeedTab = (newFeedType) => {
    dispatch({
      type: SET_FEED_TYPE.SUCCESS,
      feedType: newFeedType,
    });
  };

  return (
    <>
      <S.FeedButtonItem
        eventName="click_ad_feed_web_sub_menu_button"
        active={feedType === 'AD'}
        disabled={isLoading}
        onClick={() => {
          sortMetricsBy('created_time');
          handleChangedFeedTab('AD');
        }}
      >
        {t('ADS')}
      </S.FeedButtonItem>
      <S.FeedButtonItem
        eventName="click_post_feed_web_sub_menu_button"
        active={feedType === 'POST'}
        disabled={isLoading}
        onClick={() => {
          sortMetricsBy('relevance');
          handleChangedFeedTab('POST');
        }}
      >
        {t('POSTS')}
      </S.FeedButtonItem>
      {/* <S.FeedButtonItem
        onClick={() => {
          handleChangedFeedTab('INSTAGRAM_STORIES');
        }}
        active={feedType === 'INSTAGRAM_STORIES'}
        disabled={isLoading}
      >
        {t('INSTAGRAM_STORIES')}
      </S.FeedButtonItem> */}
    </>
  );
}
