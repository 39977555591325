import styled from 'styled-components';

export const OuterContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  height: 95px;
  margin-bottom: 20px;

  img {
    margin-left: 25px;
  }
`;
