import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TermsListPage from '../TermsListPage';
import PrivacyHeader from './PrivacyHeader';
import * as S from '../styles';

const PrivacyTerms = () => {
  const history = useHistory();
  const [clicked, setClicked] = useState(false);

  return (
    <S.FlexContainer>
      <PrivacyHeader />
      <S.OuterContainer className={clicked && 'clicked'}>
        <TermsListPage clicked={clicked} setClicked={setClicked} />
        {!clicked && (
          <S.BackButton className="logged-out" onClick={() => history.push('/AuthLogin')}>
            <FontAwesomeIcon icon={['far', 'chevron-left']} />
            Voltar para área de login
          </S.BackButton>
        )}
        <span className="copyright">© 2022 Bornlogic®. Todos os direitos reservados.</span>
      </S.OuterContainer>
    </S.FlexContainer>
  );
};

export default PrivacyTerms;
