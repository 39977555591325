import styled, { keyframes, css } from 'styled-components';

const fade = keyframes`
  0%, 100% { opacity: 0 }
  5%, 95% {opacity: 1}

`;

export const WarningContainer = styled.div`
  position: fixed;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  gap: 16px;
  font-size: ${({ theme }) => theme.fontSize.px16};
  padding: ${({ theme }) => theme.spacing.px14};
  background-color: ${({ theme }) => theme.colors.black2};
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.spacing.px8};
  ${({ infinite }) =>
    !infinite &&
    css`
      animation: 3000ms ${fade} ease-in forwards;
    `}
`;
