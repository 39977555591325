const ImageDropZoneEN = {
  ImageDropZone: {
    'ERROR-MESSAGE': 'Wrong image dimensions ',
    'UPLOAD-BUTTON': 'Choose an image',
    'DELETE-BUTTON': 'Remove image',
    'DROP-HERE': 'Drop here',
  },
};

const ImageDropZonePT = {
  ImageDropZone: {
    'ERROR-MESSAGE': 'Dimensões da imagem incorretas ',
    'UPLOAD-BUTTON': 'Escolha uma imagem',
    'DELETE-BUTTON': 'Remover a imagem',
    'DROP-HERE': 'Arraste para cá',
  },
};

const ImageDropZoneES = {
  ImageDropZone: {
    'ERROR-MESSAGE': 'Dimensiones de imagen incorrectas',
    'UPLOAD-BUTTON': 'Elige una imagen',
    'DELETE-BUTTON': 'Quitar imagem',
    'DROP-HERE': 'Arrastre aquí',
  },
};

export { ImageDropZonePT, ImageDropZoneEN, ImageDropZoneES };
