/* Create action types */
import createActionTypes from '../createActionTypes';

export const FETCH_LIST_USERS = createActionTypes('FETCH_LIST_USERS');
export const CHANGE_STATUS_USER = createActionTypes('CHANGE_STATUS_USER');
export const CHANGE_GROUP_USER = createActionTypes('CHANGE_GROUP_USER');
export const REVOKE_INVITE = createActionTypes('REVOKE_INVITE');
export const INVITE_USER = createActionTypes('INVITE_USER');
export const VERIFY_EMAIL_USER = createActionTypes('VERIFY_EMAIL_USER');
export const GET_SAS_URL = createActionTypes('GET_SAS_URL');
export const EDIT_USER_ROLE = createActionTypes('EDIT_USER_ROLE');
export const VERIFY_BUSINESS = createActionTypes('VERIFY_BUSINESS');
export const CHANGE_CAPABILITIES = createActionTypes('CHANGE_CAPABILITIES');
export const GET_USERS_SUMMARY = createActionTypes('GET_USERS_SUMMARY');
export const CLONE_CAPABILITIES = createActionTypes('CLONE_CAPABILITIES');
