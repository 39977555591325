export const GOALS_OPTIONS = [
  { value: 'post_engagement', label: 'ENGAGEMENT' },
  { value: 'messages', label: 'MESSAGES' },
  { value: 'link_clicks', label: 'LINK_CLICK' },
  { value: 'conversions', label: 'CONVERSION' },
  { value: 'lead_generation', label: 'LEADS' },
];

export const CTAS_OPTIONS = [
  { value: 'learn_more', label: 'KNOW_MORE' },
  { value: 'shop_now', label: 'BUY_NOW' },
  { value: 'whatsapp_message', label: 'WHATSAPP' },
  { value: 'sign_up', label: 'REGISTER' },
  { value: 'buy_now', label: 'BUY' },
  { value: 'contact_us', label: 'CONTACT_US' },
  { value: 'apply_now', label: 'APPLY' },
];
