import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '@bornlogic/gd-design-system';
import * as S from './styles';

const MenuLinkItem = (props) => {
  const [subMenusOpen, setSubMenusOpen] = useState(
    props.subMenus && props.subMenus.find((subMenu) => subMenu.active === 1)
  );

  const _handleOpenSubMenus = () => {
    setSubMenusOpen(!subMenusOpen);
  };

  return (
    <>
      {!props.disabled && (
        <>
          {props.subMenus ? (
            <>
              <S.SubMenuContainer onClick={_handleOpenSubMenus}>
                {props.icon && (
                  <S.CustomIcon facebook={props.icon[1] === 'facebook'}>
                    <FontAwesomeIcon icon={props.icon} style={{ width: '20px', height: '20px' }} />
                  </S.CustomIcon>
                )}
                <h6>{props.text}</h6>
                <S.SubMenuIconDiv>
                  <Icon iconType={subMenusOpen ? ['fas', 'caret-up'] : ['fas', 'caret-down']} />
                </S.SubMenuIconDiv>
              </S.SubMenuContainer>
              <S.SubMenusContainer visible={subMenusOpen}>
                {props.subMenus.map((subMenu) => (
                  <S.CustomLink
                    to={subMenu.to}
                    key={subMenu.to.pathname}
                    disabled={subMenu.disabled}
                    active={subMenu.active}
                    onClick={props.onClick}
                    style={{
                      paddingLeft: '60px',
                    }}
                  >
                    {subMenu.icon && (
                      <S.CustomIcon facebook={subMenu.icon[1] === 'facebook'}>
                        <FontAwesomeIcon icon={subMenu.icon} />
                      </S.CustomIcon>
                    )}
                    <h6>{subMenu.text}</h6>
                  </S.CustomLink>
                ))}
              </S.SubMenusContainer>
            </>
          ) : (
            <S.CustomLink
              to={props.to}
              disabled={props.disabled}
              active={props.active}
              onClick={props.onClick}
            >
              {props.icon && (
                <S.CustomIcon facebook={props.icon[1] === 'facebook'}>
                  <FontAwesomeIcon icon={props.icon} style={{ width: '20px', height: '20px' }} />
                </S.CustomIcon>
              )}
              <h6>{props.text}</h6>
            </S.CustomLink>
          )}
        </>
      )}
    </>
  );
};

export default MenuLinkItem;
