const EngagementEN = {
  Engagement: {
    TITLE: 'Engagement',
    TOTAL_VALUE_INVEST: 'Total amount invested in the period',
    TOTAL_ENGAGEMENT: 'Total engagement',
    COST_PER_ENGAGEMENT: 'Cost per engagement',
    REACH_ENGAGEMENT_RATE: 'Reach engagement rate',
    ENGAGEMENT_RATE: 'Engagement rate',
    GROW_FOLLOWERS_FACEBOOK: 'Facebook follower growth',
    GROW_FOLLOWERS_INSTAGRAM: 'Instagram follower growth',
    page_engagement: 'Page engagement',
    post_engagement: 'Engagement',
    video_views: 'Video view',
    link_click: 'Clicks, comments and shares',
    link_click_only: 'Clicks',
    impressions: 'Impressions',
    spend: 'Spend',
    reach: 'People Reached',
    comment: 'Comment',
    post: 'Shared',
    photo_view: 'Photo view',
    video_view: 'Video view',
    post_reaction: 'Reactions',
    NO_DATA: 'Not enough data to display the chart',
    NAME: 'Name',
    DESCRIPTION: 'Description',
    ENGAGEMENT: 'Engagement',
    INVESTMENT: 'Investment',
    TITLE_TOP: 'Top',
    TITLE_TOP_ENGAGEMENT: 'posts with more engagement',
    VIEW_ALL: 'Metrics',
    engagament_warn: 'Sum of reactions, likes, comments, shares, clicks, photos and videos views',
    impressions_warn: 'Number of times ad was shown to a user',
    video_view_warn: 'Number of people who watched 3 seconds or more of a video',
    reach_warn: 'Number of people who saw the ad',
    METRICS: 'Metrics',
    EMPTY_DESCRIPTION: 'There is no description',
    GO_TO_FEED: 'Go to feed',
    ORGANIC_POST_INFO: 'More growth information',
    FACEBOOKS_ORGANIC_REACTIONS: "Facebook's organic reactions",
    AMOUNT_AVAILABLE: 'Amount available',
    OF: 'of',
    PERIOD_OF: 'Compared to the period of the date',
    COMPARED_TO: 'Compared to the',
    AND: 'when the value was',
    OF_THE_TOTAL: 'of the total',
    SEE_MORE: 'See more',
    SEE_LESS: 'See less',
    SEE_PREVIEW: 'See',
    CONVERSIONS_PURCHASE: 'Conversions',
    SUBTITLE:
      'Acompanhe o engajamento do seu público nos conteúdos produzidos pelas unidades dentro de gerentesdigitais',
    post_engagement_warn:
      'Indicates the sum of actions such as like, reaction, comment or sharing, photo or video preview or click on the link.',
    FILTER: 'Filter',
    CPE_TOOLTIP:
      "Cost per engagement: calculates the investment for each engagement. It's calculated by the total amount spent divided by the number of engagements.",
    ER_TOOLTIP:
      "Cost per engagement: calculates the investment for each engagement. It's calculated by the total amount spent divided by the number of engagements.",
    CPM_TOOLTIP:
      "Cost per 1,000 impressions: calculates the price of 1,000 impressions in ads. It's calculated by the total amount spent, divided by the number of impressions, and multiplied by 1,000.",
    CPV_TOOLTIP:
      "Cost per view: calculates the price of video views in ads. It's calculated by the total amount spent, divided by the number of views.",
    VR_TOOLTIP:
      "View Rate: calculates the percentage of views related to the number of times an ad was shown. It's calculated by the total video views, divided by the total impressions, and multiplied by 100.",
  },
};

const EngagementPT = {
  Engagement: {
    TITLE: 'Engajamento',
    SUBTITLE:
      'Acompanhe o engajamento do seu público nos conteúdos produzidos pelas unidades dentro de gerentesdigitais',
    TOTAL_VALUE_INVEST: 'Valor total investido no período',
    TOTAL_ENGAGEMENT: 'Engajamento total',
    COST_PER_ENGAGEMENT: 'Custo por engajamento',
    REACH_ENGAGEMENT_RATE: 'Taxa de engajamento no alcance',
    ENGAGEMENT_RATE: 'Taxa de engajamento',
    GROW_FOLLOWERS_FACEBOOK: 'Crescimento da página',
    GROW_FOLLOWERS_INSTAGRAM: 'Crescimento de seguidores',
    page_engagement: 'Engajamento',
    post_engagement: 'Engajamento',
    video_views: 'Visualizações',
    link_click: 'Cliques, comentários e compartilhamentos',
    link_click_only: 'Cliques',
    impressions: 'Impressões',
    spend: 'Investido',
    reach: 'Pessoas alcançadas',
    comment: 'Comentários',
    post: 'Compartilhamentos',
    photo_view: 'Visualização de fotos',
    video_view: 'Visualização de vídeos',
    post_reaction: 'Reações',
    NO_DATA: 'Não há dados suficientes para exibir o gráfico',
    NAME: 'Nome',
    DESCRIPTION: 'Descrição',
    ENGAGEMENT: 'Engajamento',
    INVESTMENT: 'Investimento',
    TITLE_TOP: 'Top',
    TITLE_TOP_ENGAGEMENT: 'posts com mais engajamento',
    VIEW_ALL: 'Métricas',
    engagament_warn:
      'Soma das reações, curtidas, comentários, compartilhamentos, cliques e visualizações de fotos e vídeos',
    impressions_warn: 'Quantidade de vezes que o anúncio foi mostrado para um usuário',
    video_view_warn: 'Quantidade de pessoas que viram 3 segundos ou mais, de um vídeo',
    reach_warn: 'Quantidade de pessoas que viram o anúncio',
    METRICS: 'Métricas',
    EMPTY_DESCRIPTION: 'Não há descrição',
    GO_TO_FEED: 'Ir para o Feed',
    ORGANIC_POST_INFO: 'Mais informações de crescimento',
    FACEBOOKS_ORGANIC_REACTIONS: 'Reações orgânicas no Facebook',
    AMOUNT_AVAILABLE: 'Verba disponível',
    OF: 'de',
    PERIOD_OF: 'Comparado ao período do dia',
    COMPARED_TO: 'Comparado ao periodo de',
    AND: 'quando o valor foi de',
    OF_THE_TOTAL: 'do total',
    SEE_MORE: 'Ver mais',
    SEE_LESS: 'Ver menos',
    SEE_PREVIEW: 'Ver',
    CONVERSIONS_PURCHASE: 'Conversões',
    post_engagement_warn:
      'Mostra a soma de ações  como reações, comentário, compartilhamento, visualização de foto ou vídeo e clique no link.',
    FILTER: 'Filtrar',
    CPE_TOOLTIP:
      'Custo por engajamento: calcula o valor investido para cada engajamento. Sua fórmula é: valor investido dividido por engajamento.',
    ER_TOOLTIP:
      'Taxa de engajamento: calcula a porcentagem de pessoas que engajaram com seu conteúdo. Sua fórmula é: número de engajamentos dividido por impressões, vezes 100.',
    CPM_TOOLTIP:
      'Custo por mil: calcula o preço a cada mil impressões de anúncio. Sua fórmula é: quanto já investiu, dividido pelo número de impressões e multiplicado por mil.',
    CPV_TOOLTIP:
      'Custo por visualização: calcula o preço de cada visualização de vídeo anunciado. Sua fórmula é: quanto já investiu, dividido pelo número de visualizações.',
    VR_TOOLTIP:
      'Taxa de Visualização: calcula a porcentagem de visualizações em relação à quantidade de vezes que o anúncio foi exibido. Sua fórmula é: visualizações dividido pelas impressões vezes 100.',
  },
};

const EngagementES = {
  Engagement: {
    TITLE: 'Involucramiento',
    SUBTITLE:
      'Acompaña la participación de su público en los contenidos producidos por las unidades dentro de los gerentes digitales',
    TOTAL_VALUE_INVEST: 'Total invertido en el período',
    TOTAL_ENGAGEMENT: 'Engajamento total',
    COST_PER_ENGAGEMENT: 'Costo por participación',
    REACH_ENGAGEMENT_RATE: 'Tasa de participación en el alcance',
    ENGAGEMENT_RATE: 'Tasa de participación',
    GROW_FOLLOWERS_FACEBOOK: 'Crecimiento de página',
    GROW_FOLLOWERS_INSTAGRAM: 'Crecimiento de seguidores',
    page_engagement: 'Involucramiento',
    post_engagement: 'Involucramiento',
    video_views: 'Visualizaciones',
    link_click: 'Clics, comentarios y compartidos',
    link_click_only: 'Clics',
    impressions: 'Impresiones',
    spend: 'Invertido',
    reach: 'Personas alcanzadas',
    comment: 'Comentarios',
    post: 'Comparte',
    photo_view: 'Visualización de fotos',
    video_view: 'Visualización de vídeos',
    post_reaction: 'Reacciones',
    NO_DATA: 'No hay suficientes datos para mostrar el gráfico.',
    NAME: 'Nombre',
    DESCRIPTION: 'Descripción',
    ENGAGEMENT: 'Involucramiento',
    INVESTMENT: 'Inversión',
    TITLE_TOP: 'Cima',
    TITLE_TOP_ENGAGEMENT: 'publicaciones com más participación',
    VIEW_ALL: 'Indicadores',
    engagament_warn:
      'Suma de reacciones, me gusta, comentarios, acciones, clics y visualizaciones de fotos y videos',
    impressions_warn: 'Número de veces que se mostró el anuncio a un usuario',
    video_view_warn: 'Número de personas que vieron 3 segundos o más de un video',
    reach_warn: 'Número de personas que vieron el anuncio.',
    METRICS: 'Indicadores',
    EMPTY_DESCRIPTION: 'No hay descripción',
    GO_TO_FEED: 'Ir a Feed',
    ORGANIC_POST_INFO: 'Más informaciones de crecimiento',
    FACEBOOKS_ORGANIC_REACTIONS: 'Reacciones orgánicas en Facebook',
    AMOUNT_AVAILABLE: 'Importe disponibles',
    OF: 'en',
    PERIOD_OF: 'Comparado con la hora del día',
    COMPARED_TO: 'Comparado con el período de',
    AND: 'cuando el valor era',
    OF_THE_TOTAL: 'del total',
    SEE_MORE: 'Ver más',
    SEE_LESS: 'Ver menos',
    SEE_PREVIEW: 'Para ver',
    CONVERSIONS_PURCHASE: 'Conversiones',
    post_engagement_warn:
      'Muestra la suma de acciones como reacción, comentar, compartir, ver foto o video y hacer clic en el enlace.',
    FILTER: 'Filtrar',
    CPE_TOOLTIP:
      'Costo por participación: calcula la cantidad invertida para cada participación. Su fórmula es: monto invertido dividido por compromiso.',
    ER_TOOLTIP:
      'Tasa de interacción: calcula el porcentaje de personas que han interactuado con su contenido. Su fórmula es: número de interacciones dividido por impresiones multiplicado por 100.',
    CPM_TOOLTIP:
      'Costo por mil: calcula el precio por mil impresiones de anuncios. Su fórmula es: cuánto has invertido ya, dividido por el número de impresiones y multiplicado por mil..',
    CPV_TOOLTIP:
      'Costo por vista: calcula el precio de cada vista de video anunciada. Su fórmula es: cuánto ya ha invertido, dividido por el número de visitas.',
    VR_TOOLTIP:
      'Tasa de vistas: calcula el porcentaje de vistas en relación con la cantidad de veces que se mostró el anuncio. Su fórmula es: vistas divididas por impresiones multiplicadas por 100.',
  },
};

export { EngagementEN, EngagementPT, EngagementES };
