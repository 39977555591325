import styled, { createGlobalStyle } from 'styled-components';

export const ModalWrapper = styled('div')`
  display: block;
`;

export const ButtonClosedStyled = styled('div')`
  ${({ theme }) => ` 
  button{
    top: ${theme.spacing.px20};
    right: ${theme.spacing.px20};
    background: rgba(255, 255, 255, 0.8);
    width: 35px !important;
    height: 35px;
    border-radius: 100%;
  }
`}
`;

export const ModalStyles = createGlobalStyle`
${({ theme, mobileFullScreen, noHeader, noFooter }) => ` 
  .ReactModal__Overlay--after-open {
      background: rgba(0, 0, 0, 0.75) !important;
      z-index: 9999;
  }
  .ReactModal__Content--after-open {
      max-height: 90vh;
      max-width: 90%;
      box-sizing: border-box;
      padding: 0 !important;
      ${noHeader && noFooter ? `border: 0 !important;` : ``}      
      ${
        mobileFullScreen &&
        `
          @media screen and (max-width: ${theme.breakpoint.md}){
              max-height: 100vh;
              max-width: 100.5vw;
              height: 100vh;
              width: 100.5vw !important;
              border-radius: 0 !important;
              border: 0 !important;
              footer {
                  position: fixed;
                  bottom: 0;
                  width: 100%;
                  box-sizing: border-box;
                  display: flex;
                  justify-content: space-around;
              }
          }
      `
      }
    }
`}`;

export const ModalHeader = styled('header')`
  ${({ theme }) => `
    padding: ${theme.spacing.px24} ${theme.spacing.px70} ${theme.spacing.px24} ${theme.spacing.px30};
    border-bottom: 1px solid ${theme.colors.grey2};
`}
`;

export const ModalBody = styled('div')`
  ${({ theme, mobileFullScreen }) => `
    display: block;
    ${
      mobileFullScreen &&
      `
        @media screen and (max-width: ${theme.breakpoint.md}){
            height: 70vh;
        }
    `
    }
`}
`;

export const ModalFooter = styled('footer')`
  ${({ theme }) => `
    padding: ${theme.spacing.px24} ${theme.spacing.px30};
    border-top: 1px solid ${theme.colors.grey2};
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    @media screen and (max-width: 400px) {
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        & > button:first-child {
            margin-bottom: ${theme.spacing.px15};
        }
    }
`}
`;
