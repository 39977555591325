import useSWR from 'swr';
import { fetcher } from '@utils/api';

function useExchangeTenants() {
  const { data, error } = useSWR('/exchange/1.0/tenants', fetcher, { revalidateOnFocus: false });

  return {
    exchangeTenants: data ? data.data : null,
    isLoading: !error && !data,
    isError: error ? error.message : error,
  };
}

function useExchangeCampaignsList(tenantId) {
  const { data, error } = useSWR(
    tenantId ? `/incentivecampaign/1.0/exchange/${tenantId}/campaigns` : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );

  return {
    exchangeCampaignsList: data ? data.data : null,
    isLoading: !error && !data,
    isError: error ? error.message : error,
  };
}

function useExchangeCampaignReport(tenantId, campaignId) {
  const { data, error } = useSWR(
    tenantId && campaignId
      ? `/incentivecampaign/1.0/exchange/${tenantId}/campaigns/${campaignId}/Report`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );

  return {
    exchangeCampaignReport: data,
    isLoading: !error && !data,
    isError: error ? error.message : error,
  };
}

function useExchangeCampaignDetails(tenantId, campaignId) {
  const { data, error } = useSWR(
    tenantId && campaignId
      ? `/incentivecampaign/1.0/exchange/${tenantId}/campaigns/${campaignId}`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );

  return {
    exchangeCampaignDetails: data,
    isLoading: !error && !data,
    isError: error ? error.message : error,
  };
}

function useExchangeCampaignAds(tenantId, campaignId) {
  const { data, error } = useSWR(
    tenantId && campaignId
      ? `/incentivecampaign/1.0/exchange/${tenantId}/campaigns/${campaignId}/Report/InfoPosts`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );

  return {
    exchangeCampaignAds: data ? data.data : null,
    isLoading: !error && !data,
    isError: error ? error.message : error,
  };
}

function useTradeTenants() {
  const { data, error } = useSWR('/exchange/1.0/tradetenants', fetcher, {
    revalidateOnFocus: false,
  });

  return {
    tenants: data ? data.data : null,
    isLoading: !error && !data,
    isError: error ? error.message : error,
  };
}

export {
  useExchangeTenants,
  useExchangeCampaignsList,
  useExchangeCampaignReport,
  useExchangeCampaignDetails,
  useExchangeCampaignAds,
  useTradeTenants,
};
