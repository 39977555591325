const MediaFormatAndStatusEN = {
  unknown: 'Error',
  paused: 'Paused',
  active: 'Active',
  pendingReview: 'Pending',
  scheduled: 'Scheduled',
  finished: 'Finished',
  rejected: 'Rejected',
  deleted: 'Deleted',
  UNKNOWN: 'Error',
  ADVERTISER_ACCOUNT_PUNISH: 'Error',
  AD_STATUS_CAMPAIGN_DELETE: 'Campaign deleted',
  AD_STATUS_ADGROUP_DELETE: 'AD group deleted',
  AD_STATUS_DELETE: 'Deleted',
  AD_STATUS_ADVERTISER_AUDIT_DENY: 'Ad disapproved',
  AD_STATUS_ADVERTISER_AUDIT: 'In Approval',
  ADVERTISER_CONTRACT_PENDING: 'Pending',
  AD_STATUS_BALANCE_EXCEED: 'Balance exceeded',
  AD_STATUS_CAMPAIGN_EXCEED: 'Campaign exceeded',
  AD_STATUS_BUDGET_EXCEED: 'Budget exceeded',
  AD_STATUS_AUDIT: 'In Approval',
  AD_STATUS_REAUDIT: 'Second approval attempt',
  AD_STATUS_AUDIT_DENY: 'Disapproved',
  AD_STATUS_ADGROUP_AUDIT_DENY: 'AD group disapproved',
  AD_STATUS_NOT_START: 'Scheduled',
  AD_STATUS_DONE: 'Done',
  AD_STATUS_CAMPAIGN_DISABLE: 'Campaign disabled',
  AD_STATUS_ADGROUP_DISABLE: 'AD group disabled',
  AD_STATUS_DISABLE: 'Disabled',
  AD_STATUS_DELIVERY_OK: 'Active',
  AD_STATUS_ALL: 'Finished',
};
const MediaFormatAndStatusPT = {
  unknown: 'Erro',
  paused: 'Pausado',
  active: 'Ativa',
  pendingReview: 'Pendente',
  scheduled: 'Agendado',
  finished: 'Finalizado',
  rejected: 'Erro no envio',
  deleted: 'Inativo',
  UNKNOWN: 'Erro',
  ADVERTISER_ACCOUNT_PUNISH: 'Erro',
  AD_STATUS_CAMPAIGN_DELETE: 'Campanha excluída',
  AD_STATUS_ADGROUP_DELETE: 'Grupo de AD escluído',
  AD_STATUS_DELETE: 'Excluído',
  AD_STATUS_ADVERTISER_AUDIT_DENY: 'Anúncio reprovado',
  AD_STATUS_ADVERTISER_AUDIT: 'Em aprovação',
  ADVERTISER_CONTRACT_PENDING: 'Pendente',
  AD_STATUS_BALANCE_EXCEED: 'Saldo excedido',
  AD_STATUS_CAMPAIGN_EXCEED: 'Campanha excedida',
  AD_STATUS_BUDGET_EXCEED: 'Orçamento excedido',
  AD_STATUS_AUDIT: 'Em aprovação',
  AD_STATUS_REAUDIT: 'Segunda tentativa de aprovação',
  AD_STATUS_AUDIT_DENY: 'Reprovado',
  AD_STATUS_ADGROUP_AUDIT_DENY: 'Grupo de AD reprovado',
  AD_STATUS_NOT_START: 'Agendado',
  AD_STATUS_DONE: 'Concluído',
  AD_STATUS_CAMPAIGN_DISABLE: 'Campanha desativada',
  AD_STATUS_ADGROUP_DISABLE: 'Grupo de AD desativado',
  AD_STATUS_DISABLE: 'Desativado',
  AD_STATUS_DELIVERY_OK: 'Ativo',
  AD_STATUS_ALL: 'Finalizado',
};
const MediaFormatAndStatusES = {
  unknown: 'Error',
  paused: 'Pausado',
  active: 'Activo',
  pendingReview: 'Pendiente',
  scheduled: 'Programado',
  finished: 'Acabado',
  rejected: 'Error al enviar',
  deleted: 'Inactivo',
  UNKNOWN: 'Error',
  ADVERTISER_ACCOUNT_PUNISH: 'Error',
  AD_STATUS_CAMPAIGN_DELETE: 'Campaña eliminada',
  AD_STATUS_ADGROUP_DELETE: 'Grupo de AD eliminado',
  AD_STATUS_DELETE: 'Eliminado',
  AD_STATUS_ADVERTISER_AUDIT_DENY: 'Anuncio rechazado',
  AD_STATUS_ADVERTISER_AUDIT: 'En aprobación',
  ADVERTISER_CONTRACT_PENDING: 'Pendiente',
  AD_STATUS_BALANCE_EXCEED: 'Saldo excedido',
  AD_STATUS_CAMPAIGN_EXCEED: 'Campaña excedida',
  AD_STATUS_BUDGET_EXCEED: 'Presupuesto superado',
  AD_STATUS_AUDIT: 'En aprobación',
  AD_STATUS_REAUDIT: 'Segundo intento de aprobación',
  AD_STATUS_AUDIT_DENY: 'Rechazado',
  AD_STATUS_ADGROUP_AUDIT_DENY: 'Grupo de AD desaprobado',
  AD_STATUS_NOT_START: 'Programado',
  AD_STATUS_DONE: 'Terminado',
  AD_STATUS_CAMPAIGN_DISABLE: 'Campaña deshabilitada',
  AD_STATUS_ADGROUP_DISABLE: 'Grupo AD deshabilitado',
  AD_STATUS_DISABLE: 'Deshabilitado',
  AD_STATUS_DELIVERY_OK: 'Activo',
  AD_STATUS_ALL: 'Terminado',
};

export { MediaFormatAndStatusEN, MediaFormatAndStatusPT, MediaFormatAndStatusES };
