const HelpCenterEN = {
  HelpCenter: {
    MAIN_TITLE: 'Help Center',
    MENU_TITLE: 'Frequently Asked Questions',
    BACK_BUTTON: 'Go back',
    CONTACT_TITLE: 'Need to talk to us?',
    CONTACT_TEXT:
      "If our articles didn't help you, contact us and we'll answer as soon as possible",
    CONTACT_BUTTON: 'Contact us',
    UPDATED_AT: 'Updated at',
    jan: 'january',
    feb: 'february',
    mar: 'march',
    apr: 'april',
    may: 'may',
    jun: 'june',
    jul: 'july',
    aug: 'august',
    sept: 'september',
    oct: 'october',
    nov: 'november',
    dec: 'december',
    CSAT_QUESION: 'This information was useful to you?',
    YES: 'Yes',
    NO: 'No',
    CSAT_THANKS: 'Thank you for the answer!',
  },
};

const HelpCenterPT = {
  HelpCenter: {
    MAIN_TITLE: 'Central de Ajuda',
    MENU_TITLE: 'Perguntas Frequentes',
    BACK_BUTTON: 'Voltar',
    CONTACT_TITLE: 'Precisa falar com a gente?',
    CONTACT_TEXT:
      'Se nossos artigos não te ajudaram, entre em contato e vamos responder assim que possível',
    CONTACT_BUTTON: 'Entre em contato',
    UPDATED_AT: 'Atualizado em',
    jan: 'janeiro',
    feb: 'fevereiro',
    mar: 'março',
    apr: 'abril',
    may: 'maio',
    jun: 'junho',
    jul: 'julho',
    aug: 'agosto',
    sept: 'setembro',
    oct: 'outubro',
    nov: 'novembro',
    dec: 'dezembro',
    CSAT_QUESION: 'Essa informação foi útil para você?',
    YES: 'Sim',
    NO: 'Não',
    CSAT_THANKS: 'Obrigado pela sua resposta!',
  },
};

const HelpCenterES = {
  HelpCenter: {
    MAIN_TITLE: 'Centro de Ayuda',
    MENU_TITLE: 'Preguntas Frecuentes',
    BACK_BUTTON: 'Volver',
    CONTACT_TITLE: '¿Necesita hablar con nosotros?',
    CONTACT_TEXT:
      'Si nuestros artículos no le ayudaron, póngase en contacto con nosotros y le responderemos lo antes posible.',
    CONTACT_BUTTON: 'Entre en contacto',
    UPDATED_AT: 'Actualizado el',
    jan: 'enero',
    feb: 'febrero',
    mar: 'marzo',
    apr: 'abril',
    may: 'mayo',
    jun: 'junio',
    jul: 'julio',
    aug: 'agosto',
    sept: 'septiembre',
    oct: 'octubre',
    nov: 'noviembre',
    dec: 'diciembre',
    CSAT_QUESION: '¿Fue útil esta información?',
    YES: 'Sí',
    NO: 'No',
    CSAT_THANKS: '¡Gracias por su respuesta!',
  },
};

export { HelpCenterEN, HelpCenterPT, HelpCenterES };
