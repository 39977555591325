/**
 * IMPORT ALL THE IMAGES HERE AND EXPORT THEM TO USE
 */

import logo from './logo.svg';
import logoGd from './logo_gd.svg';
import logoVertical from './vertical_full-brand.svg';
import logoHorizontal from './horizontal_full-brand.svg';
import logoBornlogic from './logo_bornlogic.png';
import avatarDefault from './avatar_default.png';
import loading from './loading.svg';
import anger from './emojis/anger.png';
import haha from './emojis/haha.png';
import like from './emojis/like.png';
import love from './emojis/love.png';
import sorry from './emojis/sorry.png';
import wow from './emojis/wow.png';
import chrome from './chrome.png';
import android from './android.png';
import edge from './edge.png';
import firefox from './firefox.png';
import explorer from './explorer.jpg';
import safari from './safari.png';
import iphone from './iphone.jpg';
import defaultIcon from './default.jpg';
import erro404 from './erro-404.png';
import erroCarregamento from './erro-carregamento.png';
import imageDefault from './image-default.png';
import videoDefault from './video-default.png';
import checkcircle from './checkcircle.svg';
import checkcirclewhite from './checkcirclewhite.svg';
import checkcirclegris from './checkcirclegris.svg';
import shop from './shop.svg';
import feed from './Onboarding/feed.png';
import governanca from './Onboarding/governanca.png';
import orcamentos from './Onboarding/orcamentos.png';
import visaoGlobal from './Onboarding/visao_global.png';
import caretDown from './Calendar/caret-down.png';
import salesBanner from './salesBanner.svg';
import gmvBanner from './gmv.svg';
import download from './download.svg';
import search from './search.svg';

import iconDownload from './iconDownload.png';
import iconUpload from './iconUpload.png';
import iconFile from './iconFile.svg'
import buttonClose from './buttonClose.svg';
import iconSuccess from './iconSuccess.svg';
import buttonCloseError from './buttonCloseError.svg';
import iconCalendar from './iconCalendar.svg';
import uploadFile from './uploadfile.png';
import downloadFile from './downloadfile.png';
import uploadGreen from '@images/upload-img01.svg';
import uploadPurple from './upload-img03.svg';
import uploadOrange from './upload-img04.svg';
import redEllipse from './redellipse.png';

import regina from './regina.svg';
import regina02 from './regina02.svg';
import regina03 from './regina03.svg';
import jorgin from './jorgin.svg';
import itemAnimation from './item-animation.svg';
import itemAnimation01 from './item-animation-01.svg';
import itemAnimation02 from './item-animation-02.svg';
import itemAnimation03 from './item-animation-03.svg';

import chartBar from './chart-simple.svg';
import chartHeatmap from './chart-tree-map.svg';

import caretUp from './Calendar/caret-up.png';
import chevronLeft from './Calendar/chevron-left.png';
import chevronRight from './Calendar/chevron-right.png';
import calendar from './Calendar/calendar.png';
import caretDownBoxed from './Calendar/caret-down-boxed.png';
import deleteIcon from './Calendar/delete.png';
import dropdown from './Calendar/dropdown.png';
import edit from './Calendar/edit.png';
import month from './Calendar/month.png';
import store from './Calendar/store.png';
import close from './close.png';

export default {
  logo,
  logoGd,
  logoVertical,
  logoHorizontal,
  logoBornlogic,
  avatarDefault,
  loading,
  anger,
  haha,
  like,
  love,
  sorry,
  wow,
  chrome,
  android,
  edge,
  firefox,
  explorer,
  safari,
  iphone,
  defaultIcon,
  erro404,
  erroCarregamento,
  imageDefault,
  videoDefault,
  checkcircle,
  checkcirclewhite,
  checkcirclegris,
  shop,
  feed,
  governanca,
  orcamentos,
  visaoGlobal,
  calendar,
  caretDownBoxed,
  caretDown,
  caretUp,
  chevronLeft,
  chevronRight,
  deleteIcon,
  dropdown,
  edit,
  month,
  store,
  iconUpload,
  iconDownload,
  iconFile,
  buttonClose,
  buttonCloseError,
  iconSuccess,
  iconCalendar,
  salesBanner,
  uploadFile,
  downloadFile,
  uploadGreen,
  uploadPurple,
  uploadOrange,
  redEllipse,
  gmvBanner,
  itemAnimation,
  regina,
  jorgin,
  itemAnimation01,
  regina02,
  itemAnimation02,
  regina03,
  itemAnimation03,
  chartBar,
  chartHeatmap,
  close,
  download,
  search
};
