import axios from 'axios';

const BASE_URL = 'https://graph-homolog.bornlogic.com/v2';

const instancePublic = axios.create({});

export const getOptions = async (token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const response = await instancePublic.get(
    `${BASE_URL}/me/memberships?fields=type,status,businesses{name}&status=ACTIVE`,
    { headers }
  );
  return response.data;
};

export const createFolder = async (params, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const body = {
    name: 'poc-upload',
  };

  return await instancePublic.post(`${BASE_URL}/${params.memberId}/folders`, body, { headers });
};

export const createFile = async (params) => {
  const { fileName, fileSize, token, folderId } = params;
  if (!fileName || !fileSize || !token || !folderId) {
    throw new Error(
      `MISSING PARAMS: All params (fileName, fileSize, token, folderId) are required, check the object sent.`
    );
  }

  const body = { name: params.fileName, size_in_bytes: params.fileSize };
  const headers = {
    Authorization: `Bearer ${params.token}`,
  };
  return await instancePublic.post(`${BASE_URL}/${folderId}/files`, body, { headers });
};

export const postFilePart = async (data, token, fileId) => {
  if (!data || !token || !fileId) {
    throw new Error(
      `MISSING PARAMS: All params (data, token, fileId) are required, check the object sent.`
    );
  }

  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'multipart/form-data',
  };

  return await instancePublic.post(`${BASE_URL}/${fileId}/chunks`, data, { headers });
};

export const commitPost = async (fileId, token, body) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return await instancePublic.patch(`${BASE_URL}/${fileId}?fields=url`, body, { headers });
};
