const MetricsPanelEN = {
  MetricsPanel: {
    impression: 'Impressions',
    engagements: 'Engagement',
    click: 'Link Clicks',
    total_unity: 'Total Unity',
    ads: 'Ads',
    spend: 'Spend',
    revenue: 'Revenue',
    sales_amount: 'Sales amount',
    roas: 'ROAS',
    TOOLTIP_1: ' to ',
    TOOLTIP_2: 'compared with',
    TOOLTIP_3: 'when the value was',
    CAMPAIGN_PERFORMANCE_TITLE: 'Campaign performance',
    FINANCIAL_INDICATORS_TITLE: 'Financial indicators',
  },
};

const MetricsPanelPT = {
  MetricsPanel: {
    impression: 'Impressões',
    engagements: 'Engajamento',
    click: 'Cliques no link',
    total_unity: 'Total de participações',
    ads: 'Anúncios',
    spend: 'Investido',
    revenue: 'Receita',
    sales_amount: 'Volume de vendas',
    roas: 'ROAS',
    TOOLTIP_1: ' a ',
    TOOLTIP_2: 'comparado com',
    TOOLTIP_3: 'quando o valor era',
    CAMPAIGN_PERFORMANCE_TITLE: 'Performance da campanha',
    FINANCIAL_INDICATORS_TITLE: 'Indicadores financeiros',
  },
};

const MetricsPanelES = {
  MetricsPanel: {
    impression: 'Impresiones',
    engagements: 'Involucramiento',
    click: 'Clic en el enlace',
    total_unity: 'Participaciones totales',
    ads: 'Anuncios',
    spend: 'Valor invertido',
    revenue: 'Ingressos',
    sales_amount: 'Cantidad de ventas',
    roas: 'ROAS',
    TOOLTIP_1: ' al ',
    TOOLTIP_2: 'comparado con',
    TOOLTIP_3: 'cuando el valor era',
    CAMPAIGN_PERFORMANCE_TITLE: 'Rendimiento de la campaña',
    FINANCIAL_INDICATORS_TITLE: 'Indicadores financieros',
  },
};

export { MetricsPanelPT, MetricsPanelEN, MetricsPanelES };
