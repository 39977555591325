import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { SET_EXHIBITION_MODE } from '@redux/generic/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as S from './styles';

function ButtonMode({ url }) {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [itemSelected, setItemSelected] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const sortBy = urlParams.get('view_mode');
    const mode = sortBy || 'card';

    setItemSelected(mode);
    setExhibitionMode(mode);

    dispatch({
      type: SET_EXHIBITION_MODE.REQUEST,
      mode,
    });

    return () => {
      history.push({
        search: '',
      });
    };
  }, []);

  const setExhibitionMode = (mode) => {
    const urlParams = new URLSearchParams(search);
    urlParams.set('view_mode', mode);
    history.push(`${url}?${urlParams}`);
    dispatch({
      type: SET_EXHIBITION_MODE.REQUEST,
      mode,
    });
  };

  const toggleMode = () => {
    const newMode = itemSelected === 'list' ? 'card' : 'list';
    setItemSelected(newMode);
    setExhibitionMode(newMode);
  };

  return (
    <S.Wrapper onClick={toggleMode}>
      {itemSelected === 'list' ? (
        <FontAwesomeIcon icon={['fal', 'square']} />
      ) : (
        <FontAwesomeIcon icon={['fal', 'list-ul']} />
      )}
    </S.Wrapper>
  );
}

export default ButtonMode;
