import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(utc);
dayjs.extend(localizedFormat);

export function formatDate({ date, formatAs = 'DD-MM-YYYY', locale = '' }) {
  if (locale) return dayjs(date).locale(locale).format(formatAs);
  return dayjs(date).format(formatAs);
}

export const getFirstDayOfYear = (year) => {
  const date = new Date(year, 0, 1);
  return formatDate({ date, formatAs: 'YYYY-MM-DD' });
};

export const formatUtcAndRetrieveLocalizedString = (date) => {
  return dayjs.utc(date).format('LL');
};

export function getDefaultDates() {
  const currentDayOfMonth = dayjs().format('YYYY-MM-DD');
  const currentDayOfMonthReduce = dayjs().format('DD/MM');

  const startOfCurrentMonth = dayjs().startOf('month').format('YYYY-MM-DD');
  const startOfCurrentMonthReduce = dayjs().startOf('month').format('DD/MM');

  const currentDayOfLastMonth = dayjs().subtract(1, 'months').format('YYYY-MM-DD');
  const currentDayOfLastMonthReduce = dayjs().subtract(1, 'months').format('DD/MM');

  const startOfLastMonth = dayjs().startOf('month').subtract(1, 'months').format('YYYY-MM-DD');
  const startOfLastMonthReduce = dayjs().startOf('month').subtract(1, 'months').format('DD/MM');
  return {
    since: {
      currentDayOfMonth,
      startOfCurrentMonth,
      dmCurrentDayOfMonth: currentDayOfMonthReduce,
      dmStartOfCurrentMonth: startOfCurrentMonthReduce,
    },
    sinceToCompare: {
      currentDayOfLastMonth,
      startOfLastMonth,
      dmCurrentDayOfLastMonth: currentDayOfLastMonthReduce,
      dmStartOfLastMonth: startOfLastMonthReduce,
    },
  };
}

export function addTime({ date, value, dateType }) {
  return dayjs(date).add(value, dateType);
}
export function hasTimeLeft(date){
  return dayjs().isBefore(dayjs(date));
}
