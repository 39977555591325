import { useSelector } from 'react-redux';

import {
  isTenantBornlogic,
  checkIfIsTradeView,
  hasCapabilities,
} from 'utils/helpers';
import { useCapability } from 'utils/hooks/Capability';

import type { IValidatePermission, Scope } from './types';

export function useValidatePermission({
  setLabelActionsMenu,
  labelActionsMenu,
}: IValidatePermission): Scope[] | null {
  const { businessTokenData } = useSelector((state: any) => state.authenticationReducer);

  const {
    HAS_BATCH_PROMOTE: hasBatchPromote,
    HAS_BATCH_REPLICATE: hasBatchReplicate,
    HAS_MEDIA_APPROVAL: hasMediaApproval,
    HAS_TRADE_ADS_INDUSTRY: hasTradeAdsIndustry,
    HAS_REPORT: hasReport,
    HAS_ADACCOUNT_BUDGET: hasAdaccountBudget,
    HAS_MEDIA_SETS: hasMediaSets,
    HAS_FEED: hasFeed,
  } = useCapability([
    'BATCH_PROMOTE',
    'BATCH_REPLICATE',
    'MEDIA_APPROVAL',
    'TRADE_ADS_INDUSTRY',
    'DASHBOARD',
    'REPORT',
    'ADACCOUNT_BUDGET',
    'MEDIA_SETS',
    'FEED',
  ]);

  if (!hasCapabilities(businessTokenData)) {
    return null;
  }

  const scopes: Scope[] = [];

  const batchActions = hasBatchPromote || hasBatchReplicate;

  if (batchActions && hasMediaApproval && hasMediaApproval.is_main_capability) {
    scopes.push('Medias');

    if (hasMediaApproval.length === 1 && labelActionsMenu === 'Medias') {
      setLabelActionsMenu(hasMediaApproval[0].name);
    }
  }

  if (checkIfIsTradeView()) {
    if (isTenantBornlogic()) {
      scopes.push('Tracking');
    } else {
      scopes.push('Insights');
    }

    scopes.push('TradeCI');
    return scopes;
  }

  if (!hasTradeAdsIndustry) {
    if (hasReport) {
      scopes.push('Report');
    }

    if (hasAdaccountBudget) {
      scopes.push('Budget');
    }

    if (hasMediaSets) {
      scopes.push('CI');
    }

    if (batchActions || (hasMediaApproval && !hasMediaApproval.is_main_capability) || hasFeed) {
      scopes.push('Medias');

      const actionsSidebarItens = hasBatchPromote || hasBatchReplicate || hasMediaApproval;

      if (actionsSidebarItens.length === 1 && labelActionsMenu === 'Medias') {
        setLabelActionsMenu(actionsSidebarItens[0].name);
      }
    }
    if (hasMediaSets) {
      scopes.push('Communication');
    }
  } else {
    scopes.push('TradeAds');
  }

  scopes.push('Planner');

  return scopes;
}
