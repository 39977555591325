// Api
import { ApiTenant } from '@utils/api';

class EasyAccessService {
  static getStep(version, facebookAccessToken, invitationToken) {
    return ApiTenant.get(
      `/easy-access/${version}?facebook_access_token=${facebookAccessToken}&invitation_token=${invitationToken}`
    );
  }

  static updateEmail(version, encrypted_facebook_access_token, body) {
    return ApiTenant.put(
      `/easy-access/${version}?encrypted_facebook_access_token=${encrypted_facebook_access_token}`,
      body
    );
  }

  static getPages(version, encrypted_facebook_access_token) {
    return ApiTenant.get(
      `/easy-access/${version}/facebook/pages?encrypted_facebook_access_token=${encrypted_facebook_access_token}`
    );
  }

  static setPage(version, encrypted_facebook_access_token, body) {
    return ApiTenant.post(
      `/easy-access/${version}/facebook/pages?encrypted_facebook_access_token=${encrypted_facebook_access_token}`,
      body
    );
  }

  static updateInviteLink(version, encrypted_facebook_access_token) {
    return ApiTenant.post(
      `/easy-access/${version}/FacebookBusinessManager/UserInvite?encrypted_facebook_access_token=${encrypted_facebook_access_token}`
    );
  }

  static getCompanyInfo(version, invitationToken) {
    return ApiTenant.get(`/easy-access/${version}/CompanyInfo?invitation_token=${invitationToken}`);
  }

  static getSummary(version, search) {
    return ApiTenant.get(`/easy-access/${version}/admin/users/summary?${search}`);
  }

  static getUsers(version, search, next) {
    const hasSearch = search || '';
    const hasNext = next ? `&page_hash=${next}` : '';
    return ApiTenant.get(
      `/easy-access/${version}/admin/users?pending_users_only=true&${hasSearch}${hasNext}`
    );
  }
}

export default EasyAccessService;
