const ActionsFeedCardModalEN = {
  SEE_ON_FACEBOOK: 'See on Facebook',
  SEE_ON_INSTAGRAM: 'See on Instagram',
  SEE_ON_TIKTOK: 'See on Instagram',
  SEE_PREVIEW: 'See preview',
  EDIT_MEDIA: 'Edit',
  DOWNLOAD_MEDIA_CONTENT: 'Download',
};
const ActionsFeedCardModalPT = {
  SEE_ON_FACEBOOK: 'Ver no Facebook',
  SEE_ON_INSTAGRAM: 'Ver no Instagram',
  SEE_ON_TIKTOK: 'Ver no Instagram',
  SEE_PREVIEW: 'Ver prévia',
  EDIT_MEDIA: 'Editar',
  DOWNLOAD_MEDIA_CONTENT: 'Baixar',
};
const ActionsFeedCardModalES = {
  SEE_ON_FACEBOOK: 'Ver en Facebook',
  SEE_ON_INSTAGRAM: 'Ver en Instagram',
  SEE_ON_TIKTOK: 'Ver en Instagram',
  SEE_PREVIEW: 'Vista previa',
  EDIT_MEDIA: 'Editar',
  DOWNLOAD_MEDIA_CONTENT: 'Descargar',
};

export { ActionsFeedCardModalEN, ActionsFeedCardModalPT, ActionsFeedCardModalES };
