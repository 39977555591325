const GMV_PT = {
  GMV: {
    TITLE: 'Informações sobre GMV',
    FIRST_STEP: 'Etapa 01',
    SECOND_STEP: 'Etapa 02',
    THIRD_STEP: 'Etapa 03',
    FIRST_STEP_CONTENT: 'Realizar o download da planilha clicando em "Baixar planilha". Neste arquivo, você deverá seguir as informações presentes nas colunas, adequando-as conforme a sua necessidade e inserindo seus produtos.',
    SECOND_STEP_CONTENT: 'Etapa para realizar o envio da planilha. Lembre-se de enviar arquivos de no máximo 35mb no formato xlsx ou csv. Caso ocorra algum erro, você receberá uma sinalização das linhas com problema. Corrija apenas as linhas sinalizadas e suba uma nova planilha com estes itens corrigidos.',
    THIRD_STEP_CONTENT: 'Serão disponibilizados relatórios de performance em cima do ROAS, onde será possível entender o desempenho das unidades.',
    SUCCESS: 'Arquivo enviado com sucesso',
    ERROR: 'Ocorreu algum erro ao enviar o arquivo',
    ERROR_MESSAGE: 'Ocorreu um erro na linha',
    HOME: 'Home',
    GMV_PAGE: 'Atribuição de vendas',
    INFO_GMV:
      'Antes de enviar as informações do GMV, faça o download do arquivo modelo e o preencha com os seus dados de vendas.',
    DOWNLOAD: 'Baixar planilha',
    UPLOAD: 'planilha de vendas',
    ADD_FILE: 'Adicione o arquivo aqui.',
    EXTENSION: 'Extensões permitidas: .csv e .xlsx.',
    SHARE: 'Compartilhe as informações de vendas atribuídas ao gerentes digitais.',
    USING: 'Use o arquivo modelo para preencher a planilha e enviar suas informações de vendas.',
    MODEL_FILE: 'Modelo de arquivo',
    SEND: 'Envio dos dados de vendas',
    CLEAN: 'Limpar',
    PREPARING_DATABASE: 'Preparando banco de dados...',
    CHECKING_DATABASE_LINES: 'Checando as linhas da planilha...',
    CHECKING_DATABASE_LINES_COMMENT: 'Estamos torcendo para que sejam muitas...',
    DATABASE_READY: 'Pronto!',
    DATABASE_READY_COMMENT_SUCCESS_FIRST: 'Todas as linhas estão certas!',
    DATABASE_READY_COMMENT_SUCCESS_SECOND: 'Agora você pode visualizar o extrato.',
    DATABASE_READY_COMMENT_ERROR_SECOND: 'Você pode corrigir as linhas e reenviar a planilha só com as linhas corrigidas.',
    VIEW_EXTRACT: 'Visualizar extrato',
    VIEW_RESULT: 'Visualizar resultado',
    SEND_CORRECTED_LINES: 'Enviar planilha com linhas corrigidas',
    ERROR_FILE: 'Erro no processamento do arquivo',
    ERROR_FILE_COMMENT_FIRST: 'O arquivo enviado não pôde ser processado...',
    ERROR_FILE_COMMENT_SECOND: 'Faça upload de sua planilha como um arquivo .csv ou .xlsx e verifique se o limite do arquivo é de 40MB',
    SEND_CORRECTED_SHEET: 'Enviar planilha corrigida',
    EXTRACT: 'Extrato',
    NAME_FILE: 'Nome do arquivo',
    SEND: 'Enviado em',
    PRODUCT: 'Produtos',
    AMOUNT: 'Valor total',
    SENT_BY: 'Enviado por',
    DOWNLOAD_SHEET: 'Baixar planilha',
    EMPTY_MESSAGE: 'Nenhuma atribuição de venda encontrada.',
    VIEW_HISTORY: 'Ver histórico',
    ADD: 'Adicionar',
    SEND: 'Enviar',
    BACK: 'Voltar',
    IDENTIFY: 'Seus produtos foram identificados.',
    PRODUCTS: 'produtos.',
    LINE: 'Line',
    SHEET_READY: 'Planilha pronta para ser enviada!',
    ERROR_SIZE_FILE: 'Excedeu o limite de 1MB.',
    WE_FOUND: 'Encontramos ',
    ERROR_LINES_FILE: ' erros no arquivo enviado. Nessas linhas aqui:'
  },
};

const GMV_EN = {
  GMV: {
    TITLE: 'Information about GMV',
    FIRST_STEP: 'Step 01',
    SECOND_STEP: 'Step 02',
    THIRD_STEP: 'Step 03',
    FIRST_STEP_CONTENT: 'Download the spreadsheet by clicking on "Download spreadsheet". In this file, you must follow the information present in the columns, adapting them according to your needs and inserting your products.',
    SECOND_STEP_CONTENT: 'Step to send the spreadsheet. Remember to send files of a maximum of 35mb, always in xlsx or csv format. If an error occurs, you will receive a signal of the lines with the problem. Correct only the flagged rows and upload a new sheet with these corrected items.',
    THIRD_STEP_CONTENT: 'The yield reports will be available in addition to ROAS, from which it will be possible to understand the yield of the units.',
    SUCCESS: 'File uploaded successfully',
    ERROR: 'There was an error sending the file',
    ERROR_MESSAGE: 'An error occurred on the line',
    HOME: 'Home',
    GMV_PAGE: 'Sales attribution',
    INFO_GMV:
      'Before submitting the GMV information, please download the template file and fill it with your sales data.',
    DOWNLOAD: 'Download spreadsheet',
    UPLOAD: 'Upload sales sheet',
    ADD_FILE: 'Add the file here.',
    EXTENSION: 'Allowed extensions: .csv and .xlsx.',
    SHARE: 'Share the sales information assigned to gerentes digitais.',
    USING: 'Use the template file to fill in the spreadsheet and submit your sales information.',
    MODEL_FILE: 'Template file',
    SEND: 'Sending sales data',
    CLEAN: 'Clean',
    PREPARING_DATABASE: 'Preparing database...',
    CHECKING_DATABASE_LINES: 'Checking the spreadsheet rows...',
    CHECKING_DATABASE_LINES_COMMENT: 'We are hoping there will be many...',
    DATABASE_READY: 'Done!',
    DATABASE_READY_COMMENT_SUCCESS_FIRST: 'All lines are correct!',
    DATABASE_READY_COMMENT_SUCCESS_SECOND: 'Now you can preview the extract.',
    DATABASE_READY_COMMENT_ERROR_SECOND: 'You can correct the rows and resubmit the spreadsheet with only the corrected rows.',
    VIEW_EXTRACT: 'View extract',
    VIEW_RESULT: 'View result',
    SEND_CORRECTED_LINES: 'Send worksheet with corrected lines',
    ERROR_FILE: 'Error processing file',
    ERROR_FILE_COMMENT_FIRST: 'The uploaded file could not be processed...',
    ERROR_TYPE_FILE_COMMENT_SECOND: 'Upload your spreadsheet as a .csv or .xlsx file and make sure the file limit is 40MB',
    SEND_CORRECTED_SHEET: 'Send corrected worksheet',
    EXTRACT: 'Extract',
    NAME_FILE: 'File name',
    SEND: 'Sent in',
    PRODUCT: 'Products',
    AMOUNT: 'Amount',
    SENT_BY: 'Sent by',
    DOWNLOAD_SHEET: 'Download spreadsheet',
    EMPTY_MESSAGE: 'No sales attribution found.',
    VIEW_HISTORY: 'View history',
    ADD: 'Add',
    SEND: 'Submit',
    BACK: 'Go back',
    IDENTIFY: 'Your products have been identified.',
    PRODUCTS: 'products.',
    LINE: 'Line',
    SHEET_READY: 'Spreadsheet ready to be sent!',
    ERROR_SIZE_FILE: 'Exceeded the 1MB limit.',
    WE_FOUND: 'We found ',
    ERROR_LINES_FILE: 'errors not sent file. Nessas lines here:',
  },
};

const GMV_ES = {
  GMV: {
    TITLE: 'Informaciones sobre GMV',
    FIRST_STEP: 'Paso 01',
    SECOND_STEP: 'Paso 02',
    THIRD_STEP: 'Paso 03',
    FIRST_STEP_CONTENT: 'Descargue la hoja de cálculo haciendo clic en "Descargar hoja de cálculo". En este archivo, debe seguir la información presente en las columnas, adaptándolas según sus necesidades e insertando sus productos.',
    SECOND_STEP_CONTENT: 'Paso para enviar la hoja de cálculo. Recuerda enviar archivos de un máximo de 35mb en formato xlsx o csv. Si ocurre un error, recibirá una señal de las líneas con el problema. Corrija solo las filas marcadas y cargue una nueva hoja con estos elementos corregidos.',
    THIRD_STEP_CONTENT: 'Los informes de rendimiento estarán disponibles además del ROAS, donde será posible comprender el rendimiento de las unidades.',
    SUCCESS: 'El archivo ha subido correctamente',
    ERROR: 'Hubo un error al enviar el archivo.',
    ERROR_MESSAGE: 'Ocurrió un error en la línea ',
    HOME: 'Home',
    GMV_PAGE: 'Atribución de ventas',
    INFO_GMV:
      'Antes de enviar las informaciones de GMV, descargue el archivo de plantilla y rellénelo con sus datos de ventas.',
    DOWNLOAD: 'Descargar hoja de cálculo',
    UPLOAD: 'Subir hoja de ventas',
    ADD_FILE: 'Agregue el archivo aquí.',
    EXTENSION: 'Extensiones permitidas: .csv y .xlsx.',
    SHARE: 'Comparte la información de ventas asignada a los gerentes digitais.',
    USING:
      'Utilice el archivo de plantilla para completar la hoja de cálculo y enviar su información de ventas.',
    MODEL_FILE: 'Plantilla de archivo',
    SEND: 'Envío de datos de ventas',
    CLEAN: 'Limpio',
    PREPARING_DATABASE: 'Preparando base de datos...',
    CHECKING_DATABASE_LINES: 'Comprobando las filas de la hoja de cálculo...',
    CHECKING_DATABASE_LINES_COMMENT: 'Esperamos que sean muchas...',
    DATABASE_READY: '¡Listo!',
    DATABASE_READY_COMMENT_SUCCESS_FIRST: 'Todas las líneas son correctas!',
    DATABASE_READY_COMMENT_SUCCESS_SECOND: 'Ahora puede obtener una vista previa del extracto.',
    DATABASE_READY_COMMENT_ERROR_SECOND: 'Puede corregir las filas y volver a enviar la hoja de cálculo solo con las filas corregidas.',
    VIEW_EXTRACT: 'Ver extracto',
    VIEW_RESULT: 'Ver resultado',
    SEND_CORRECTED_LINES: 'Enviar hoja de trabajo con líneas corregidas',
    ERROR_FILE: 'Error de procesamiento de archivo',
    ERROR_FILE_COMMENT_FIRST: 'No se pudo procesar el archivo subido...',
    ERROR_FILE_COMMENT_SECOND: 'Cargue su hoja de cálculo como un archivo .csv o .xlsx y asegúrese de que el límite de archivos sea de 40MB',
    SEND_CORRECTED_SHEET: 'Enviar hoja de trabajo corregida',
    EXTRACT: 'Extracto',
    NAME_FILE: 'Nombre del archivo',
    SEND: 'Enviado en',
    PRODUCT: 'Productos',
    AMOUNT: 'Monto',
    SENT_BY: 'Enviado por',
    DOWNLOAD_SHEET: 'descargar hoja de calculo',
    EMPTY_MESSAGE: 'No se encontró ninguna atribución de ventas.',
    VIEW_HISTORY: 'Ver historial',
    ADD: 'Agregar',
    SEND: 'Enviar',
    BACK: 'Regresar',
    IDENTIFY: 'Sus productos han sido identificados.',
    PRODUCTS: 'productos.',
    LINE: 'Línea',
    SHEET_READY: 'Hoja de cálculo lista para ser enviada!',
    ERROR_SIZE_FILE: 'Superó el límite de 1 MB.',
    WE_FOUND: 'Nosotros encontramos ',
    ERROR_LINES_FILE: 'errores en el archivo subido. En estas líneas aquí:'
  },
};

export { GMV_PT, GMV_ES, GMV_EN };
