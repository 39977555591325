import styled from 'styled-components';

export const OuterContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 65%;
`;

export const TitularContainer = styled.div`
  text-align: center;
  width: 100%;
  font-size: 14px;
`;

export const DPOContainer = styled.div`
  margin: 0 50px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
`;

export const TextContainer = styled.span`
  margin-top: 24px;
`;
