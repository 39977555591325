const MarkedActionsModalEN = {
  POST_SUCCESS_MESSAGE: 'Successful replication!',
  POST_FAILURE_MESSAGE: 'Failed to replicate, try again later!',
  POST_PROMOTE_SUCCESS_MESSAGE: 'Success promoting post!',
  POST_PROMOTE_FAILURE_MESSAGE: 'Failed to promote post, please try again later!',
  POST_SUCCESS_MESSAGES: 'Replications performed successfully!',
  POST_FAILURE_MESSAGES: 'Failed to replicate posts, please try again later!',
  POST_PROMOTE_SUCCESS_MESSAGES: 'Success promoting posts!',
  POST_PROMOTE_FAILURE_MESSAGES: 'Failed to promote posts, please try again later!',
  GO_BACK: 'Go back',
  VALUE_TITLE: 'Enter total investment amount',
  START_DATE_LABEL: 'Start date:',
  END_DATE_LABEL: 'End date:',
  TOTAL_VALUE: 'Total value',
  TO: 'to',
  PERIOD: 'Period',
  ACCOUNT_BALLANCE: 'Account balance',
  CALENDAR_TITLE: 'Choose the duration?',
  CLOSE: 'Close',
};

const MarkedActionsModalPT = {
  POST_SUCCESS_MESSAGE: 'Replicação realizada com sucesso!',
  POST_FAILURE_MESSAGE: 'Falha ao replicar, tente novamente mais tarde!',
  POST_PROMOTE_SUCCESS_MESSAGE: 'Sucesso ao promover a postagem!',
  POST_PROMOTE_FAILURE_MESSAGE: 'Falha ao promover a postagem, tente novamente mais tarde!',
  POST_SUCCESS_MESSAGES: 'Replicações realizadas com sucesso!',
  POST_FAILURE_MESSAGES: 'Falha ao replicar postagens, tente novamente mais tarde!',
  POST_PROMOTE_SUCCESS_MESSAGES: 'Sucesso ao promover as postagens!',
  POST_PROMOTE_FAILURE_MESSAGES: 'Falha ao promover as postagens, tente novamente mais tarde!',
  GO_BACK: 'Voltar',
  VALUE_TITLE: 'Insira o valor total do investimento',
  START_DATE_LABEL: 'Data inicial:',
  END_DATE_LABEL: 'Data final:',
  TOTAL_VALUE: 'Valor total',
  TO: 'a',
  PERIOD: 'Período',
  ACCOUNT_BALLANCE: 'Saldo da conta',
  CALENDAR_TITLE: 'Escolha a duração da data?',
  CLOSE: 'Fechar',
};

const MarkedActionsModalES = {
  POST_SUCCESS_MESSAGE: '¡Replicación exitosa!',
  POST_FAILURE_MESSAGE: 'No se pudo replicar, ¡inténtalo de nuevo más tarde!',
  POST_PROMOTE_SUCCESS_MESSAGE: '¡Éxito promocionando la publicación!',
  POST_PROMOTE_FAILURE_MESSAGE:
    'No se pudo promocionar la publicación. Inténtalo de nuevo más tarde.',
  POST_SUCCESS_MESSAGES: '¡Replicaciones realizadas con éxito!',
  POST_FAILURE_MESSAGES: '¡Error al replicar las publicaciones, inténtalo de nuevo más tarde!',
  POST_PROMOTE_SUCCESS_MESSAGES: '¡Éxito en la promoción de publicaciones!',
  POST_PROMOTE_FAILURE_MESSAGES:
    'No se pudieron promocionar las publicaciones, ¡inténtalo de nuevo más tarde!',
  GO_BACK: 'Regresar',
  VALUE_TITLE: 'Ingrese el monto total de la inversión',
  START_DATE_LABEL: 'La fecha de inicio:',
  END_DATE_LABEL: 'Fecha final:',
  TOTAL_VALUE: 'Valor total',
  TO: 'a',
  PERIOD: 'Período',
  ACCOUNT_BALLANCE: 'Saldo de la cuenta',
  CALENDAR_TITLE: '¿Elegir la duración de la fecha?',
  CLOSE: 'Cerca',
};

export { MarkedActionsModalEN, MarkedActionsModalPT, MarkedActionsModalES };
