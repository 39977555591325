import React from 'react';
import { useTranslate } from 'react-translate';
import SelectOnTab from '../../../../components/SelectOnTab/SelectOnTab';

const FilterStatus = ({  onChange, disabled, status }) => {
  const t = useTranslate('User')

  const values = [
    {
      status: 'all',
      preset: 'all',
      label: t('STATUS'),
    },
    {
      status: 'active',
      preset: 'active',
      label: t('ACTIVE'),
    },
    {
      status: 'invited',
      preset: 'invited',
      label: t('INVITED'),
    },
    {
      status: 'blocked',
      preset: 'blocked',
      label: t('BLOCKED'),
    },
    {
      status: 'pending_approval',
      preset: 'pending_approval',
      label: t('PENDING'),
    },
  ];

  return (
    <SelectOnTab
      iconSelect={['fal', 'clock']}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
      selectValue={status}
    >
      {values.map((value) => (
        <option key={value.label} value={`${value.preset}`}>
          {value.label}
        </option>
      ))}
    </SelectOnTab>
  );
};

export default (FilterStatus);
