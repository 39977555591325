// Modules
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { color } from '@bornlogic/reborn';

const SelectWrapper = styled('div')`
  ${({ theme, disabled, mr20 }) => css`
    border: none;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    border-bottom: 2px solid ${color.primary.v100.color};
    background: ${theme.colors.white};
    height: 100%;
    box-sizing: border-box;
    svg:not([data-icon='chevron-down']) {
      width: ${theme.spacing.px15};
      height: ${theme.spacing.px15};
      left: ${theme.spacing.none};
      top: calc(${theme.spacing.middle} - (${theme.spacing.px15} / 2));
      font-size: ${theme.fontSize.px14};
      color: ${theme.colors.blueGrey3};
      position: absolute;
      z-index: 0;
    }
    ${disabled
      ? `
            svg {
                color: ${theme.colors.grey2} !important;
            }
            `
      : ''}
    ${mr20
      ? `
            margin-right: ${theme.spacing.px20};
            `
      : ''}
  `}
`;

const SelectStyled = styled('select')`
  ${({ theme, disabled, iconSelect }) => `
        font-size: ${theme.fontSize.px14};
        font-weight: ${theme.fontWeight.light};
        color: ${theme.colors.blueGrey3};
        background: none;
        outline: none;
        border: none;
        appearance: none;
        -webkit-appearance: none;
        position: relative;
        z-index: 1;
        height: auto;
        padding: ${theme.spacing.px15} ${theme.spacing.px25} ${theme.spacing.px15} ${
    theme.spacing.none
  };
        @media screen and (max-width: 650px) {
            width: ${theme.spacing.full};
        }
        + svg {
            width: ${theme.spacing.px15};
            height: ${theme.spacing.px15};
            right: ${theme.spacing.none};
            top: calc(${theme.spacing.middle} - (${theme.spacing.px15} / 2));
            color: ${theme.colors.blueGrey3};
            font-size: ${theme.fontSize.px12};
            position: absolute;
            z-index: 0;
        }
        ${
          disabled
            ? `
            cursor: not-allowed;
            color: ${theme.colors.grey2};
            `
            : 'cursor: pointer;'
        }
        ${
          iconSelect
            ? `
            padding-left: ${theme.spacing.px25};
            `
            : ''
        }
    `}
`;

const SelectonTab = ({
  onChange,
  selectValue,
  iconSelect,
  children,
  disabled,
  defaultValue,
  mr20,
  ...props
}) => (
  <SelectWrapper {...props} disabled={disabled} mr20={mr20}>
    <FontAwesomeIcon icon={iconSelect} />
    <SelectStyled
      onChange={onChange}
      value={selectValue}
      defaultValue={defaultValue}
      iconSelect={iconSelect}
      disabled={disabled}
    >
      {children}
    </SelectStyled>
    <FontAwesomeIcon icon={['far', 'chevron-down']} />
  </SelectWrapper>
);

SelectonTab.propTypes = {
  iconSelect: PropTypes.array,
  disabled: PropTypes.bool,
};

SelectonTab.defaultProps = {
  iconSelect: [],
  disabled: false,
};

export default SelectonTab;
