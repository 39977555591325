import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslate } from 'react-translate';
import SelectOnTab from '@components/SelectOnTab/SelectOnTab';
import { SET_BUDGET_HISTORY } from '@redux/budget/types';
import { SchemaService } from '@services';
import { formatDate, addTime } from '@utils/dates';

function BudgetHistory() {
  const t = useTranslate('Budget');
  const dispatch = useDispatch();
  const { budgetId } = useSelector((state) => state.budgetReducer);

  const [values, setValues] = useState([]);

  useEffect(() => {
    SchemaService.getBudgetDates().then((response) => {
      setValues(response.data.sort(dateSort).reverse());
    });
  }, []);

  function dateSort(a, b) {
    if (a.end_date > b.end_date) return 1;
    if (b.end_date > a.end_date) return -1;

    return 0;
  }

  function setBudgetId(value) {
    dispatch({
      type: SET_BUDGET_HISTORY.SUCCESS,
      budgetHistory: value ? values[value] : null,
    });
  }

  return (
    <SelectOnTab
      iconSelect={['fal', 'history']}
      onChange={(e) => setBudgetId(e.target.value)}
      selectValue={budgetId}
    >
      <option value={null}>{t('CURRENT_PERIOD')}</option>
      {values.map((value, index) => (
        <option key={value.id} value={index}>
          {formatDate({
            date: addTime({ date: value.start_date, value: 2, dateType: 'days' }),
            formatAs: 'MMMM [de] YYYY',
            locale: t('LOCALE'),
          })}
          {` ${t('TO')} `}
          {formatDate({
            date: value.end_date,
            formatAs: 'MMMM [de] YYYY',
            locale: t('LOCALE'),
          })}
        </option>
      ))}
    </SelectOnTab>
  );
}

export default BudgetHistory;
