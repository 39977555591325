import styled from 'styled-components';

export const Container = styled.section`
  padding: ${({ theme }) => theme.spacing.px16} ${({ theme }) => theme.spacing.px32};
`;

export const InputWrapper = styled.section`
  padding: ${({ theme }) => theme.spacing.px16} 0;
`;

export const LabelSpace = styled.section`
  padding-bottom: ${({ theme }) => theme.spacing.px10};
`;
