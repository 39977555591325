export const EditUserCapabilitiesModalEN = {
  EditUserCapabilitiesModal: {
    EDIT_PERMISSIONS: 'Edit permissions',
    SAVE: 'Save',
    CANCEL: 'Cancel',

    EDIT_CAPABILITIES_SUCCESS_MESSAGE: 'Permissions changed successfully!',
    EDIT_USER_CAPABILITIES_ERROR_MESSAGE: 'Failed to change user permissions!',
  },
};

export const EditUserCapabilitiesModalES = {
  EditUserCapabilitiesModal: {
    EDIT_PERMISSIONS: 'Editar permisos',
    SAVE: 'Salvar',
    CANCEL: 'Cancelar',

    EDIT_USER_CAPABILITIES_SUCCESS_MESSAGE: '¡Permisos cambiados con éxito!',
    EDIT_USER_CAPABILITIES_ERROR_MESSAGE: '¡No se pudieron cambiar los permisos del usuario!',
  },
};

export const EditUserCapabilitiesModalPT = {
  EditUserCapabilitiesModal: {
    EDIT_PERMISSIONS: 'Editar permissões',
    SAVE: 'Salvar',
    CANCEL: 'Cancelar',
    CAPABILITIES: 'Permissões',

    EDIT_USER_CAPABILITIES_SUCCESS_MESSAGE: 'Permissões alteradas com sucesso!',
    EDIT_USER_CAPABILITIES_ERROR_MESSAGE: 'Falha ao alterar as permissões do usuário!',
  },
};
