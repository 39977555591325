import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'react-translate';
import { useParams } from 'react-router';
import Image from '@components/Image';
import { LoadingOld } from '@components/LoadingOld/LoadingOld';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AUTHENTICATE_BUSINESS, FETCH_DOMAIN, FETCH_LIST_COMPANIES } from '@redux/auth/types';
import { redirectToBusiness, setBusinessID } from '@utils/auth';
import {
  GDScoreArray,
  hasCapabilities,
  validateCapability,
  validateUserInfoCapability,
} from '@utils/helpers';
import { auth_server_url, mountUrlToLogout } from '@utils/oAuth';
import { primaryTheme } from '../../../styles/themes';
import * as S from './styles';

const MenuProfile = ({ innerRef, open }) => {
  const authenticationReducer = useSelector((state) => state.authenticationReducer);
  const { listCompanies } = authenticationReducer;
  const { name, picture_url, id, sub_domain } = authenticationReducer.subDomain;
  const { avatar_url, user_name, user_last_name } = authenticationReducer.dataUser;

  const [userInfoCapability, setUserInfoCapability] = useState(false);
  const [hasOtherCompanies, setHasOtherCompanies] = useState(false);
  const [listCompaniesFiltered, setListCompaniesFiltered] = useState([]);
  const [eventType, setEventType] = useState('');
  const [options, setOptions] = useState(listCompaniesFiltered);
  const [openListCompanies, setOpenListCompanies] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const dispatch = useDispatch();
  const params = useParams();
  const t = useTranslate('MenuProfile');
  const isAdmin = useMemo(
    () => authenticationReducer.dataUser.role === 'admin',
    [authenticationReducer.dataUser.role]
  );

  const checkEmail = useMemo(
    () => authenticationReducer?.businessTokenData?.email?.includes('@bornlogic.com'),
    [authenticationReducer.businessTokenData.email]
  );

  const GDScoreLink = () => (
    <S.CustomLink
      to={`/${params.code}/GDScore`}>
      {t('GDSCORE')}
    </S.CustomLink>
  );
  const windowOpen = () => {
    window.open(`${auth_server_url}/Identity/Account/Manage`, '_blank');
  }
  const handleEventType = (value) => {
    setEventType(value);
  }


  const userConfiguration = () => (
    <S.CustomLink
      to={isAdmin ? `/${params.code}/Settings` : `/${params.code}/Users/Management`}
    >
      {isAdmin ? t('CONFIG') : t('USER-CONFIG')}
    </S.CustomLink>
  );

  const logout = () => {
    mountUrlToLogout();
  };

  const setCompany = useCallback(
    (company) => {
      const subDomain = company.sub_domain;
      redirectToBusiness(subDomain);
      setBusinessID(company.id);
      if (subDomain) {
        dispatch({
          type: FETCH_DOMAIN.REQUEST,
          version: '1.2',
          subDomain,
        });
        dispatch({
          type: AUTHENTICATE_BUSINESS.REQUEST,
          version: '1.2',
        });
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!listCompanies?.length && !hasOtherCompanies) {
      dispatch({
        type: FETCH_LIST_COMPANIES.REQUEST,
        version: '1.2',
      });
      setHasOtherCompanies(listCompanies.length !== 1);
    }
  }, [listCompanies?.length, dispatch, hasOtherCompanies]);

  useEffect(() => {
    let filtered = listCompanies.filter((x) => x.id !== id).map((company) => {return {label: company.name, value: company, id: company.id}});
    if(eventType) {
      setOptions(filtered);
    }
    const listSorted = filtered.sort(function (a, b) {
      return a.label.localeCompare(b.label);
    });
    setListCompaniesFiltered(listSorted);
    setOptions(listSorted);
  }, [listCompanies, id, eventType]);

  useEffect(() => {
    if (eventType) {
      const currentList = listCompaniesFiltered.filter(item => item.label === eventType.label || item.id === eventType.label);
      setOptions(currentList);
    }
  }, [eventType, listCompaniesFiltered]);

  useEffect(() => {
    if (hasCapabilities(authenticationReducer.businessTokenData)) {
      setUserInfoCapability(validateUserInfoCapability(authenticationReducer.businessTokenData));
    }
  }, [authenticationReducer.businessTokenData, id, listCompanies]);

  const isThereUsersCapability = () => {
    return !!validateCapability(authenticationReducer.businessTokenData, 'USER_LISTING');
  };

  const styleSelect = {
    control: (base) => ({
      ...base,
      border: `1px solid ${primaryTheme.colors.grey} !important`,
      height: '38px',
      boxShadow: '0 !important',
      '&:hover': {
        border: `1px solid ${primaryTheme.colors.blueSecurity} !important`,
      },
    }),
    menu: (base) => ({
      ...base,
      padding: 0,
      '.menu': {
        display: 'flex',
      },
    }),
  };

const filterOptions = ({data}, input) => {
   if (input && data.label && data.id) {
     return data.label.toLowerCase().includes(input.toLowerCase()) || data.id.toLowerCase().includes(input.toLowerCase())
   }
   return true
}
  return (
    <>
      <S.MenuProfileRelative open={open} ref={innerRef}>
        <S.MenuProfileContent open={open} id="menuProfile">
          {openListCompanies ? (
            <S.CompanyOptions openMenu={openMenu}>
              <S.CompanyOptionsClose
                onClick={() => {
                  setListCompaniesFiltered(listCompaniesFiltered);
                  setOpenListCompanies(false);
                }}
              >
                <FontAwesomeIcon icon={['fal', 'times']} />
              </S.CompanyOptionsClose>
              <S.CompanyOptionsWrapper>
                <S.CompanyOptionsItem>
                  <S.CompanyOptionsButton
                    id="companyProfile"
                    onClick={() => setOpenListCompanies(false)}
                  >
                    <Image src={authenticationReducer.subDomain.picture_url} alt={name} />
                  </S.CompanyOptionsButton>
                  <S.InputWrapper>
                    <S.CustomSelect
                      placeholder={t('SEARCH')}
                      onChange={(value) => handleEventType(value)}
                      value={eventType}
                      options={listCompaniesFiltered}
                      styles={styleSelect}
                      onMenuOpen={() => setOpenMenu(true)}
                      onMenuClose={() => setOpenMenu(false)}
                      type="COMPANIES"
                      filterOption={filterOptions}
                    />
                    <S.CustomCleanButton onClick={() => setEventType('')}>
                      {t('CLEAR')}
                    </S.CustomCleanButton>
                  </S.InputWrapper>
                </S.CompanyOptionsItem>
                {authenticationReducer.listCompaniesLoading ? (
                  <LoadingOld isLoading={authenticationReducer.listCompaniesLoading} centerIcon />
                ) : (
                  options.map((company) => (
                    <S.CompanyOptionsItem key={company.value.id}>
                      <S.CompanyOptionsButton onClick={() => setCompany(company.value)}>
                        <S.CompanyOptionsImage>
                          <Image src={company.value.picture_url} alt={company.value.name} />
                        </S.CompanyOptionsImage>
                        <h5>{company.label}</h5>
                      </S.CompanyOptionsButton>
                    </S.CompanyOptionsItem>
                  ))
                )}
              </S.CompanyOptionsWrapper>
            </S.CompanyOptions>
          ) : (
            <>
              <S.MenuProfileCustomer>
                <S.MenuProfileCustomerButton
                  onlyOne={listCompanies.length === 1}
                  id="companyProfile"
                  onClick={() => setOpenListCompanies(true)}
                >
                  <Image src={picture_url} alt={name} />
                  {listCompanies.length !== 1 && <FontAwesomeIcon icon={['fal', 'cog']} />}
                </S.MenuProfileCustomerButton>
              </S.MenuProfileCustomer>
              <S.MenuProfileUser>
                {avatar_url ? (
                  <S.UserImage>
                    <Image src={avatar_url} alt={`${user_name} ${user_last_name}`} />
                  </S.UserImage>
                ) : (
                  <S.FirstLetterUser>
                    {user_name ? user_name.substring(0, 1).toUpperCase() : '?'}
                  </S.FirstLetterUser>
                )}
                <S.UserInfo>
                  {userInfoCapability && (
                    <S.UserInfoName>
                      {user_name} {user_last_name}
                    </S.UserInfoName>
                  )}
                  <S.UserInfoType>{isAdmin ? t('ADMIN') : t('DEFAULTUSER')}</S.UserInfoType>
                  {validateCapability(authenticationReducer.businessTokenData, 'USER_INFO') && (
                    <S.LinkAccount onClick={windowOpen}>{t('EDIT-PROFILE-SETTINGS')}</S.LinkAccount>
                  )}
                  {validateCapability(authenticationReducer.businessTokenData, 'USER_PROFILE') &&
                    !validateCapability(authenticationReducer.businessTokenData, 'USER_INFO') && (
                      <S.LinkAccount onClick={windowOpen}>{t('PROFILE-SETTINGS')}</S.LinkAccount>
                    )}
                </S.UserInfo>
              </S.MenuProfileUser>
              {(isThereUsersCapability() || GDScoreArray.includes(sub_domain)) && (
                <S.MenuProfileOptions>
                  {GDScoreArray.includes(sub_domain) && GDScoreLink()}
                  {isThereUsersCapability() && userConfiguration()}

                  {validateCapability(
                    authenticationReducer.businessTokenData,
                    'BATCH_UPDATE_PROFILE_COVER'
                  ) && (
                    <S.CustomLink to={`/${params.code}/Profile&Cover`}>
                      {t('PROFILECOVER')}
                    </S.CustomLink>
                  )}

                  <S.CustomLink to={`/${params.code}/PrivacyTerms`}>{t('PRIVACY')}</S.CustomLink>
                </S.MenuProfileOptions>
              )}

              <S.MenuProfileOptions>
                <S.CustomButton onClick={logout}>{t('LOGOUT')}</S.CustomButton>
              </S.MenuProfileOptions>
            </>
          )}
        </S.MenuProfileContent>
      </S.MenuProfileRelative>
    </>
  );
};

export default MenuProfile;
