/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import { useTranslate } from 'react-translate';
import { ModalDefault, Tabs, Tab } from '@bornlogic/gd-design-system';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { SHOW_TOAST } from '@redux/toast/types';
import AdvancedConfigsContext, { useAdvancedConfig } from '@utils/contexts/AdvancedConfig';
import LocalizationForm from './LocalizationForm';
import PublicForm from './PublicForm';
import PublicationForm from './PublicationForm';
import GoalsAndCTA from './GoalsAndCTA';
import SettingsService from '../../../../services/settings';

import * as S from './styles';

function AdvancedConfig({ isOpen, onClose, sourceIds, unitSourceId }) {
  const t = useTranslate('Units');
  const provider = useAdvancedConfig(sourceIds[0], unitSourceId);
  const dispatch = useDispatch();

  const showToast = (message, type) => {
    dispatch({
      type: SHOW_TOAST.SUCCESS,
      message,
      messageType: type,
    });
  };

  const handleConfirmClick = async () => {
    let resp;

    try {
      const body = {
        valid_campaign_objectives: provider.goals,
        valid_call_to_actions: provider.ctas,
      };

      await SettingsService.updateGoalsAndCtaRules({
        body,
        unitId: unitSourceId,
      });
    } catch (e) {
      showToast(t('GOALS_AND_CTA_ERROR'), 'error');
    }

    if (provider.shouldCreateRule) {
      resp = await SettingsService.createAdvancedConfigRule(provider.requestBody);
      if (resp.status === 200) {
        provider.setShouldCreateRule(false);
      }
    } else {
      resp = await SettingsService.updateAdvancedConfigRule(provider.requestBody);
    }

    if (resp.status === 200) {
      showToast('Configurações atualizadas com sucesso!', 'success');
      onClose();
    } else {
      showToast('Ocorreu um erro ao tentar salvar as configurações.', 'error');
    }
  };

  const handleOnCloseModal = () => {
    provider.resetAddAccount();
    onClose();
  };

  return (
    <ModalDefault
      isOpen={isOpen}
      size="40"
      maxHeight="80%"
      title={t('ADVANCED_CONFIG_TITLE_MODAL')}
      confirmBtnText={t('CONFIRM')}
      onConfirmBtnClick={handleConfirmClick}
      onClose={handleOnCloseModal}
      hasCloseButton
      optionBtnText={t('CANCEL')}
      onOptionBtnClick={handleOnCloseModal}
    >
      <AdvancedConfigsContext.Provider value={provider}>
        <S.Container>
          <Tabs initialTabId="localization">
            <Tab id="localization" title="Localização">
              <LocalizationForm />
            </Tab>
            <Tab id="public" title="Público">
              <PublicForm />
            </Tab>
            <Tab id="publication" title="Publicação">
              <PublicationForm />
            </Tab>
            <Tab id="goalsAndCTA" title="Objetivos e CTA">
              <GoalsAndCTA />
            </Tab>
          </Tabs>
        </S.Container>
      </AdvancedConfigsContext.Provider>
    </ModalDefault>
  );
}

AdvancedConfig.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sourceIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AdvancedConfig;
