import React from 'react';
import { STATUS_TYPES } from '../index';
import { useTranslate } from 'react-translate';
import { useDispatch, useSelector } from 'react-redux';
import SelectOnTab from '@components/SelectOnTab/SelectOnTab';
import { CHANGE_UNITS_STATUS_FILTER, CHANGE_UNITS_NAMES_FILTER } from '@redux/settings/types';
import * as S from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const UnitsFilters = () => {
  const t = useTranslate('Units');
  const dispatch = useDispatch();

  const unitsStatus = useSelector((state) => state.settingsReducer.units.status);

  const statusOptions = [
    {
      value: '',
      label: 'Status',
    },
    ...Object.entries(STATUS_TYPES).map(([key, value]) => {
      return {
        value,
        label: t(key),
      };
    }),
  ];

  return (
    <>
      <FontAwesomeIcon
        icon={['fal', 'search']}
        style={{ fontSize: '14px', color: '#577584', marginRight: '4px' }}
      />
      <S.FilterInput
        label=''
        placeholder='Nome'
        onChange={(evt) => {
          dispatch({
            type: CHANGE_UNITS_NAMES_FILTER,
            namesFilter: evt.target.value,
          })
        }}
      />

      <SelectOnTab
        iconSelect={['far', 'building']}
        onChange={(e) => {
          dispatch({
            type: CHANGE_UNITS_STATUS_FILTER,
            statusFilter: e.target.value,
          });
        }}
        selectValue={unitsStatus}
      >
        {statusOptions.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </SelectOnTab>


    </>
  );
};

export default UnitsFilters;
