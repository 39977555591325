const OverviewEN = {
  Overview: {
    OVERVIEW_CAMPAIGN_HEALTH: 'Campaign health',
    OVERVIEW_CAMPAIGN_HEALTH_SUBTITLE_OK: 'Investment within the planned',
    OVERVIEW_CAMPAIGN_HEALTH_SUBTITLE_UNDER: 'Investment below planned',
    OVERVIEW_CAMPAIGN_HEALTH_SUBTITLE_OVER: 'Investment above planned',
    OVERVIEW_INVESTIMENT_PERIOD: 'Amount spent',
    OVERVIEW_INVESTIMENT_BUDGET: 'Budget goal',
    OVERVIEW_REMAINING_BUDGET: 'Remaining budget',
    OVERVIEW_DAILY_GOAL: 'Daily goal',
    OVERVIEW_TOTAL_SPENT: 'Spent from total',
    OVERVIEW_TOTAL_RATING: 'rating',
    OVERVIEW_PAGE_RATING: 'Page Rating',
    /* CardDivided */
    OVERVIEW_ORGANIC_LABEL: 'Organic',
    OVERVIEW_IMPRESSIONS_LABEL: 'Impressions',
    OVERVIEW_VIEWS_LABEL: 'Video views',
    OVERVIEW_CLICKS_LABEL: 'Clicks',
    OVERVIEW_ENGAGEMENTS_LABEL: 'Engagements',
    OVERVIEW_CLICKS_SITE_LABEL: 'Link click',
    OVERVIEW_INVOLVEMENT_LABEL: 'Involvement',
    OVERVIEW_REACH_LABEL: 'Reach',
    OVERVIEW_LINK_CLICK_LABEL: 'Link click',
    OVERVIEW_LINK_PURCHASE: 'Conversions',
    // Conversion Flow
    NO_SHOW_CHART: 'Not enough data to display the chart.',
    CONVERSION_FLOW_LINK_CLICK: 'Link click',
    CONVERSION_FLOW_VIEW_CONTENT: 'View content',
    CONVERSION_FLOW_CART: 'Add to cart',
    CONVERSION_FLOW_REGISTRATION: 'Complete registration',
    CONVERSION_FLOW_PURCHASE: 'Purchase',
    ACTIVITY: 'Activity',
    TOTAL_ADS: 'Total ads',
    AVERAGE_ADS_BY_UNIT: 'Average by unit',
    AVERAGE_ADS_BY_DAY: 'Average by day',
    TOTAL_POSTS: 'Total posts',
    AVERAGE_POSTS_BY_UNIT: 'Average by unit',
    AVERAGE_POSTS_BY_DAY: 'Average by day',
    ACTIVE_USERS: 'Active users',
    IMPRESSIONS_TOOLTIP:
      "How many times an ad was shown on the feed to your public. If it appears in different moments to the same person, another impression is counted. Be aware: for videos an impression is counted even if the video doesn't start.",
    VIDEO_VIEWS_TOOLTIP: 'How many video views in ads, calculated only after 3 seconds.',
    CLICKS_TOOLTIP:
      'Indicates how many clicks your ad had. It counts link clicks, clicks on your page name, click to expand the image or video, click to react, and comment',
    LINK_CLICK_TOOLTIP: 'Indicates how many link clicks your ad had.',
    PURCHASE_TOOLTIP:
      "Indicates the number of conversions you had with your ad. It's tracked by Facebook's Pixel, installed on your website.",
    REACH_TOOLTIP:
      "It shows how many people the ad has reached in your audience's feed. If it appears at different times for the same person, only one reach is counted.",
    INVOLVEMENT_TOOLTIP:
      'Indicates the number of engagement (sum of reactions, comments, shares, views, link clicks, and offer claim), withdrawing the number of views.',
    ENGAGEMENTS_TOOLTIP:
      'Indicates the number of engagement (sum of reactions, comments, shares, views, link clicks, and offer claim).',
    CPM_TOOLTIP:
      "Cost per 1,000 impressions: calculates the price of 1,000 impressions in ads. It's calculated by the total amount spent, divided by the number of impressions, and multiplied by 1,000.",
    CPV_TOOLTIP:
      "Cost per view: calculates the price of video views in ads. It's calculated by the total amount spent, divided by the number of views.",
    VR_TOOLTIP:
      "View Rate: calculates the percentage of views related to the number of times an ad was shown. It's calculated by the total video views, divided by the total impressions, and multiplied by 100.",
    CPC_TOOLTIP:
      "Cost per click: calculates the price of each click on ads. It's calculated by the total amount spent, divided by total clicks.",
    CTR_TOOLTIP:
      "Click-through rate: calculates the percentage of people who saw your ad and clicked somewhere. It's calculated by the total of clicks, divided by impressions, and multiplied by 100.",
    CPLC_TOOLTIP:
      "Cost per link click: calculates the price of each link click your ad had. It's calculated by the total amount spent divided by the total of link clicks.",
    CPA_TOOLTIP:
      "Cost per acquisition: calculates how much you had to spend to generate a conversion. It's calculated by the total amount spent divided by acquisition (conversion).",
    CR_TOOLTIP:
      "Conversion rate: calculates the percentage of people who converted (buy, lead...). It's calculated by the total of conversion divided by clicks and multiplied by 100.",
    CPE_TOOLTIP:
      "Cost per engagement: calculates the investment for each engagement. It's calculated by the total amount spent divided by the number of engagements.",
    ER_TOOLTIP:
      "Engagement rate: calculates the percentage of people who are involved (engagement without views) with your ads. It's calculated by the number of engagements, divided by impressions, and multiplied by 100.",
    CPI_TOOLTIP:
      "Cost per involvement: calculates the investment for each involvement. It's calculated by the total amount spent divided by the number of involvement.",
    IR_TOOLTIP:
      "Involvement rate: calculates the percentage of people who are involved (engagement without views) with your ads. It's calculated by the number of involvements, divided by impressions, and multiplied by 100.",
    DOWNLOAD_DONE: 'Download completed!',
    DOWNLOAD_ERROR: 'Download error.',
    OVERVIEW_LEAD_ADS_LABEL: 'Generated registrations',
    LEAD_ADS_TOOLTIP: 'Total number of registrations',
    CPCG_TOOLTIP: 'How much was needed to invest to get a single registration.',
    DOWNLOAD_SPREADSHEET: 'Download spreadsheet',
    DOWNLOAD_LEADS_LIST_TOOLTIP:
      'Amount of generated registers per pages linked to gerentesdigitais since the beginning of the contract',
    OVERVIEW_STARTED_CONVERSATIONS_LABEL: 'Started conversations',
    STARTED_CONVERSATIONS_TOOLTIP:
      'Shows the number of people that has seen your content and clicked to start a conversation with your page throught WhatsApp, Facebook or Instagram.',
    CPSC_TOOLTIP:
      'Avarage cost for each user that clicked to start a conversation with your business. Its formula is the total cost divided by the number os started conversations.',
    SALES: 'Sales',
    SALES_TOOLTIP: '',
    REVENUE: 'Revenue',
    REVENUE_TOOLTIP: 'Total amount of online and offline revenue for the period.',
    ONLINE_REVENUE_TOOLTIP: 'Revenue accounted for by online sales data.',
    OFFLINE_REVENUE_TOOLTIP: 'Revenue accounted for by offline sales data.',
    ROAS_TOOLTIP: 'Shows the total revenue divided by the total invested on the period.',
    ONLINE_ROAS_TOOLTIP: 'Shows the online revenue divided by the total invested on the period.',
    OFFLINE_ROAS_TOOLTIP: 'Shows the offline revenue divided by the total invested on the period.',
    OVERVIEW_PERFORMANCE_TITLE: 'Performance',
    OVERVIEW_ORGANIC_TITLE: 'Organic Posts',
  },
};

const OverviewPT = {
  Overview: {
    OVERVIEW_CAMPAIGN_HEALTH: 'Saúde da campanha',
    OVERVIEW_CAMPAIGN_HEALTH_SUBTITLE_OK: 'Investimento dentro do planejado',
    OVERVIEW_CAMPAIGN_HEALTH_SUBTITLE_UNDER: 'Investimento abaixo do planejado',
    OVERVIEW_CAMPAIGN_HEALTH_SUBTITLE_OVER: 'Investimento acima do planejado',
    OVERVIEW_INVESTIMENT_PERIOD: 'Valor investido',
    OVERVIEW_INVESTIMENT_BUDGET: 'Meta de investimento',
    OVERVIEW_REMAINING_BUDGET: 'Verba disponível',
    OVERVIEW_DAILY_GOAL: 'Meta diária',
    OVERVIEW_TOTAL_SPENT: 'Gastados do valor total',
    OVERVIEW_TOTAL_RATING: 'avaliações',
    OVERVIEW_PAGE_RATING: 'Avaliações das páginas',
    /* CardDivided */
    OVERVIEW_ORGANIC_LABEL: 'Orgânico',
    OVERVIEW_IMPRESSIONS_LABEL: 'Impressões',
    OVERVIEW_VIEWS_LABEL: 'Visualizações',
    OVERVIEW_CLICKS_LABEL: 'Cliques',
    OVERVIEW_ENGAGEMENTS_LABEL: 'Engajamentos',
    OVERVIEW_CLICKS_SITE_LABEL: 'Clique para o site',
    OVERVIEW_INVOLVEMENT_LABEL: 'Envolvimento',
    OVERVIEW_REACH_LABEL: 'Alcance',
    OVERVIEW_LINK_CLICK_LABEL: 'Cliques no Link',
    OVERVIEW_LINK_PURCHASE: 'Compras',
    // Conversion Flow
    NO_SHOW_CHART: 'Os dados não são suficientes para mostrar o gráfico.',
    CONVERSION_FLOW_LINK_CLICK: 'Clique para o site',
    CONVERSION_FLOW_VIEW_CONTENT: 'Visualização de conteúdo',
    CONVERSION_FLOW_CART: 'Carrinho de compra',
    CONVERSION_FLOW_REGISTRATION: 'Registro',
    CONVERSION_FLOW_PURCHASE: 'Compra',
    ACTIVITY: 'Atividade',
    TOTAL_ADS: 'Total de anúncios',
    AVERAGE_ADS_BY_UNIT: 'Média por unidade',
    AVERAGE_ADS_BY_DAY: 'Média por dia',
    TOTAL_POSTS: 'Total de postagens',
    AVERAGE_POSTS_BY_UNIT: 'Média por unidade',
    AVERAGE_POSTS_BY_DAY: 'Média por dia',
    ACTIVE_USERS: 'Usuários ativos',
    IMPRESSIONS_TOOLTIP:
      'Mostra a quantidade de vezes que o anúncio foi exibido no feed para o público.Caso apareça em diferentes momentos para a mesma pessoa, mais uma impressão é contada. Mas atenção: para vídeos a impressão é contada mesmo sem o vídeo ser iniciado.',
    VIDEO_VIEWS_TOOLTIP:
      'Mostra a quantidade de visualizações em anúncios de vídeo, a partir de 3 segundos.',
    CLICKS_TOOLTIP:
      'Mostra a quantidade de cliques totais em anúncios. Contabiliza cliques no link, cliques no nome da página, cliques para visualizar a imagem e vídeo, cliques para reagir e comentar no conteúdo.',
    LINK_CLICK_TOOLTIP: 'Mostra a quantidade de cliques nos links dos anúncios.',
    PURCHASE_TOOLTIP:
      'Mostra o número de compras feitas no site que tenham sido finalizadas através de algum anúncio. É monitorado pelo Pixel do próprio Facebook instalado em seu site.',
    REACH_TOOLTIP:
      'Mostra a quantidade de pessoas que o anúncio atingiu no feed do seu público. Caso apareça em diferentes momentos para a mesma pessoa, só um alcance é contado.',
    INVOLVEMENT_TOOLTIP:
      'Mostra o número total de engajamentos (soma de reações, comentários e compartilhamentos, visualização de foto, clique em link ou pedido em ofertas) menos o número de visualizações.',
    ENGAGEMENTS_TOOLTIP:
      'Mostra o número total de engajamentos (soma de reações, comentários e compartilhamentos, visualização de foto ou vídeo, clique em link ou pedido em ofertas).',
    CPM_TOOLTIP:
      'Custo por mil: calcula o preço a cada mil impressões de anúncio. Sua fórmula é: quanto já investiu, dividido pelo número de impressões e multiplicado por mil.',
    CPV_TOOLTIP:
      'Custo por visualização: calcula o preço de cada visualização de vídeo anunciado. Sua fórmula é: quanto já investiu, dividido pelo número de visualizações.',
    VR_TOOLTIP:
      'Taxa de Visualização: calcula a porcentagem de visualizações em relação à quantidade de vezes que o anúncio foi exibido. Sua fórmula é: visualizações dividido pelas impressões vezes 100.',
    CPC_TOOLTIP:
      'Custo por clique: calcula o preço de cada clique em anúncios. Sua fórmula é: valor investido dividido por quantidade de cliques.',
    CTR_TOOLTIP:
      'Taxa de cliques: calcula a porcentagem de pessoas que viram o anúncio e clicaram em alguma parte. Sua fórmula é: número de cliques dividido por impressões vezes 100.',
    CPLC_TOOLTIP:
      'Custo por clique no link: calcula o preço de cada clique no link em anúncios. Sua fórmula é: valor investido dividido por quantidade de cliques no link.',
    CPA_TOOLTIP:
      'Custo por aquisição: calcula o valor investido para gerar uma compra em seu site. Sua fórmula é: valor investido dividido por aquisição (compra).',
    CR_TOOLTIP:
      'Taxa de conversão: calcula a porcentagem de pessoas que realizaram a conversão proposta, seja compra, seja completar um formulário, entre outras. Sua fórmula é: número de conversões dividido por cliques vezes 100.',
    CPE_TOOLTIP:
      'Custo por engajamento: calcula o valor investido para cada engajamento. Sua fórmula é: valor investido dividido por engajamento.',
    ER_TOOLTIP:
      'Taxa de engajamento: calcula a porcentagem de pessoas que se engajaram. Sua fórmula é: número de engajamento dividido por impressões, vezes 100.',
    CPI_TOOLTIP:
      'Custo por envolvimento: calcula o valor investido para cada envolvimento. Sua fórmula é: valor investido dividido por envolvimento.',
    IR_TOOLTIP:
      'Taxa de envolvimento: calcula a porcentagem de pessoas que se envolveram, ou seja, engajamento menos visualizações com seu conteúdo. Sua fórmula é: número de envolvimentos dividido por impressões, vezes 100.',
    DOWNLOAD_DONE: 'Seu download está feito!',
    DOWNLOAD_ERROR: 'Erro ao fazer o download.',
    OVERVIEW_LEAD_ADS_LABEL: 'Cadastros gerados',
    LEAD_ADS_TOOLTIP: 'Quantidade de cadastros totais',
    CPCG_TOOLTIP: 'Quanto foi necessário investir para conseguir um único cadastro.',
    DOWNLOAD_SPREADSHEET: 'Baixar planilha',
    DOWNLOAD_LEADS_LIST_TOOLTIP:
      'Quantidade de cadastros gerados por páginas vinculadas ao gerentesdigitais desde o início do contrato',
    OVERVIEW_STARTED_CONVERSATIONS_LABEL: 'Conversas iniciadas',
    STARTED_CONVERSATIONS_TOOLTIP:
      'Mostra o número de pessoas que viram seu conteúdo e clicaram para iniciar uma conversa com a sua página por Whatsapp, Facebook ou Instagram.',
    CPSC_TOOLTIP:
      'Custo médio por usuário que clicou para iniciar conversa com seu negócio. Sua fórmula é o custo total dividido pelo número de conversas iniciadas.',
    SALES: 'Vendas',
    SALES_TOOLTIP: 'Mostra o número de vendas totais realizadas a partir dos anúncios em Gerentes Digitais. Ela contém compras tanto Offline quanto Online. ',
    REVENUE: 'Receita',
    REVENUE_TOOLTIP: 'Mostra o valor total de receita gerado através dos anúncios em Gerentes Digitais. Esse valor contabiliza compras tanto Offline quanto Online. ',
    ONLINE_REVENUE_TOOLTIP: 'Receita contabilizada pelos dados de vendas online.',
    OFFLINE_REVENUE_TOOLTIP: 'Receita contabilizada pelos dados de vendas offline.',
    ROAS_TOOLTIP: 'Mostra o cálculo do total de receita pelo total investido no período.',
    ONLINE_ROAS_TOOLTIP: 'Mostra o cálculo do total de receitas online pelo total investido no período.',
    OFFLINE_ROAS_TOOLTIP: 'Mostra o cálculo do total de receitas offline pelo total investido no período.',
    OVERVIEW_PERFORMANCE_TITLE: 'Performance',
    OVERVIEW_ORGANIC_TITLE: 'Postagens orgânicas',
  },
};

const OverviewES = {
  Overview: {
    OVERVIEW_CAMPAIGN_HEALTH: 'Salud de la campaña',
    OVERVIEW_CAMPAIGN_HEALTH_SUBTITLE_OK: 'Investimento dentro do planejado',
    OVERVIEW_CAMPAIGN_HEALTH_SUBTITLE_UNDER: 'Inversión por debajo del planeado',
    OVERVIEW_CAMPAIGN_HEALTH_SUBTITLE_OVER: 'Investimento acima do planejado',
    OVERVIEW_INVESTIMENT_PERIOD: 'Monto invertido',
    OVERVIEW_INVESTIMENT_BUDGET: 'Objetivo de inversión',
    OVERVIEW_REMAINING_BUDGET: 'Importes disponibles',
    OVERVIEW_DAILY_GOAL: 'Objetivo del día',
    OVERVIEW_TOTAL_SPENT: 'Gastado del valor total',
    OVERVIEW_TOTAL_RATING: 'avaliações',
    OVERVIEW_PAGE_RATING: 'Avaliações das páginas',
    /* CardDivided */
    OVERVIEW_ORGANIC_LABEL: 'Orgánico:',
    OVERVIEW_IMPRESSIONS_LABEL: 'Impresiones',
    OVERVIEW_VIEWS_LABEL: 'Visualizaciones',
    OVERVIEW_CLICKS_LABEL: 'Clics',
    OVERVIEW_ENGAGEMENTS_LABEL: 'Involucramientos',
    OVERVIEW_CLICKS_SITE_LABEL: 'Clique para o site',
    OVERVIEW_INVOLVEMENT_LABEL: 'Envolvimiento',
    OVERVIEW_REACH_LABEL: 'Alcance',
    OVERVIEW_LINK_CLICK_LABEL: 'Clics en enlaces',
    OVERVIEW_LINK_PURCHASE: 'Compras',
    // Conversion Flow
    NO_SHOW_CHART: 'Los datos no son suficientes para mostrar el gráfico.',
    CONVERSION_FLOW_LINK_CLICK: 'Haga clic para acceder al sitio',
    CONVERSION_FLOW_VIEW_CONTENT: 'Vista previa del contenido',
    CONVERSION_FLOW_CART: 'Carrito de compra',
    CONVERSION_FLOW_REGISTRATION: 'Registro',
    CONVERSION_FLOW_PURCHASE: 'Compra',
    ACTIVITY: 'Actividad',
    TOTAL_ADS: 'Total de anuncios',
    AVERAGE_ADS_BY_UNIT: 'Promedio por unidad',
    AVERAGE_ADS_BY_DAY: 'Promedio por día',
    TOTAL_POSTS: 'Total de publicaciones',
    AVERAGE_POSTS_BY_UNIT: 'Promedio por unidad',
    AVERAGE_POSTS_BY_DAY: 'Promedio por día',
    ACTIVE_USERS: 'Usuarios activos',
    IMPRESSIONS_TOOLTIP:
      'Muestra la cantidad de veces que el anuncio se mostró a la audiencia en el feed. Si aparece en diferentes momentos para la misma persona, se cuenta una impresión más. Pero cuidado: en el caso de los videos, la impresión se cuenta incluso sin que se haya iniciado el video.',
    VIDEO_VIEWS_TOOLTIP:
      'Muestra la cantidad de vistas en los anuncios de video, comenzando en 3 segundos.',
    CLICKS_TOOLTIP:
      'Muestra la cantidad total de clics en anuncios. Cuenta los clics en el enlace, los clics en el nombre de la página, los clics para ver la imagen y el video, los clics para reaccionar y comentar el contenido.',
    LINK_CLICK_TOOLTIP: 'Muestra la cantidad de clics en los enlaces de anuncios.',
    PURCHASE_TOOLTIP:
      'Muestra el número de compras realizadas en el sitio que se han completado a través de un anuncio. Es monitoreado por el Pixel de Facebook instalado en su sitio web.',
    REACH_TOOLTIP:
      'Muestra a cuántas personas ha llegado el anuncio en el feed de su audiencia. Si aparece en diferentes momentos para la misma persona, solo se cuenta un rango.',
    INVOLVEMENT_TOOLTIP:
      'Muestra el número total de interacciones (suma de reacciones, comentarios y compartidos, visualización de fotos, clic en un enlace o pedido de ofertas) menos el número de visualizaciones.',
    ENGAGEMENTS_TOOLTIP:
      'Muestra el número total de interacciones (suma de reacciones, comentarios y compartidos, vista de foto o video, clic en enlace o solicitud en ofertas).',
    CPM_TOOLTIP:
      'Costo por mil: calcula el precio por mil impresiones de anuncios. Su fórmula es: cuánto has invertido ya, dividido por el número de impresiones y multiplicado por mil.',
    CPV_TOOLTIP:
      'Costo por vista: calcula el precio de cada vista de video anunciada. Su fórmula es: cuánto ya ha invertido, dividido por el número de visitas.',
    VR_TOOLTIP:
      'Tasa de vistas: calcula el porcentaje de vistas en relación con la cantidad de veces que se mostró el anuncio. Su fórmula es: vistas divididas por impresiones multiplicadas por 100.',
    CPC_TOOLTIP:
      'Costo por clic: calcula el precio de cada clic en un anuncio. Su fórmula es: cantidad invertida dividida por la cantidad de clics.',
    CTR_TOOLTIP:
      'Tasa de clics: calcula el porcentaje de personas que vieron el anuncio e hicieron clic en algún lugar. Su fórmula es: número de clics dividido por impresiones multiplicado por 100.',
    CPLC_TOOLTIP:
      'Costo por clic en el vínculo: calcula el precio de cada clic en el vínculo en los anuncios. Su fórmula es: cantidad invertida dividida por la cantidad de clics en el enlace.',
    CPA_TOOLTIP:
      'Costo por adquisición: calcula el monto invertido para generar una compra en su sitio web. Su fórmula es: monto invertido dividido por adquisición (compra).',
    CR_TOOLTIP:
      'Tasa de conversión: calcula el porcentaje de personas que realizaron la conversión propuesta, ya sea comprando o completando un formulario, entre otros. Su fórmula es: número de conversiones dividido por clics multiplicado por 100.',
    CPE_TOOLTIP:
      'Costo por participación: calcula la cantidad invertida para cada participación. Su fórmula es: monto invertido dividido por compromiso.',
    ER_TOOLTIP:
      'Tasa de participación: calcula el porcentaje de personas que participaron. Su fórmula es: número de interacción dividido por impresiones multiplicado por 100.',
    CPI_TOOLTIP:
      'Costo por participación: calcula el monto invertido para cada participación. Su fórmula es: monto invertido dividido por participación.',
    IR_TOOLTIP:
      'Tasa de participación: calcula el porcentaje de personas que se involucraron, es decir, la participación de menos visualizaciones con su contenido. Su fórmula es: número de vueltas dividido por impresiones, multiplicado por 100.',
    DOWNLOAD_DONE: '¡Tu descarga está lista!',
    DOWNLOAD_ERROR: 'Error al descargar.',
    OVERVIEW_LEAD_ADS_LABEL: 'Registros generados',
    LEAD_ADS_TOOLTIP: 'Número de registros totales',
    CPCG_TOOLTIP: 'Cuánto fue necesario invertir para conseguir un único registro.',
    DOWNLOAD_SPREADSHEET: 'Descargar hoja de cálculo',
    DOWNLOAD_LEADS_LIST_TOOLTIP:
      'Número de entradas generadas por páginas vinculadas a gerentes digitales desde el inicio del contrato',
    OVERVIEW_STARTED_CONVERSATIONS_LABEL: 'Conversaciones iniciadas',
    STARTED_CONVERSATIONS_TOOLTIP:
      'Muestra la cantidad de personas que vieron su contenido e hicieron clic para iniciar una conversación con su página a través de Whatsapp, Facebook o Instagram.',
    CPSC_TOOLTIP:
      'Costo promedio por usuario que hizo clic para iniciar una conversación con su empresa. Su fórmula es el costo total dividido por la cantidad de conversaciones iniciadas.',
    SALES: 'Ventas',
    SALES_TOOLTIP: 'Muestra el número de ventas totales realizadas a partir de anuncios en Gerentes Digitais. Contiene compras en online y off-line. ',
    REVENUE: 'Ingresos',
    REVENUE_TOOLTIP: 'Muestra la cantidad total de ingresos generados a través de anuncios en Gerentes Digitais. Este valor cuenta tanto las compras online y off-line. ',
    ONLINE_REVENUE_TOOLTIP: 'Ingresos contabilizados por los datos de ventas en línea.',
    OFFLINE_REVENUE_TOOLTIP: 'Ingresos contabilizados por datos de ventas fuera de línea.',
    ROAS_TOOLTIP: 'Muestra el cálculo de los ingresos totales por el total invertido en el período.',
    ONLINE_ROAS_TOOLTIP: 'Muestra el cálculo de los ingresos en línea por el total invertido en el período.',
    OFFLINE_ROAS_TOOLTIP: 'Muestra el cálculo de los ingresos fuera de línea por el total invertido en el período.',
    OVERVIEW_PERFORMANCE_TITLE: 'Rendimiento',
    OVERVIEW_ORGANIC_TITLE: 'Publicaciones orgánicas',
  },
};

export { OverviewPT, OverviewEN, OverviewES };
