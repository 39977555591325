export const INITIAL_STATE = {
  campaign: {
    data: [],
    isLoading: false,
    error: '',
  },
  campaignReport: {
    data: [],
    isLoading: false,
    error: '',
  },
  campaignFinancialMetrics: {
    data: [],
    isLoading: false,
    error: '',
  },
  campaignFeed: {
    data: [],
    paging: '',
    isLoading: false,
    error: '',
    total_ad_per_status: {
      total: 0,
      active: 0,
      finished: 0,
      pending_review: 0,
      paused: 0,
    },
  },
};

export function handleFetchMore({ action, state }) {
  return {
    ...state,
    campaignFeed: {
      ...state.campaignFeed,
      paging: action?.data?.paging?.next || '',
      data: state?.campaignFeed?.data.concat(action?.data?.data || []),
      total_ad_per_status: {
        active: action?.data?.active_ads,
        finished: action?.data?.finished_ads,
        pendingReview: action?.data?.pending_review_ads,
        total: action?.data?.total_ads,
        paused: action?.data?.paused_ads,
        scheduled: action?.data?.scheduled_ads,
      },
      isLoading: false,
    },
  };
}

export function handleNewFetch({ action, state }) {
  return {
    ...state,
    campaignFeed: {
      ...state.campaignFeed,
      paging: action?.data?.paging?.next || '',
      data: action?.data?.data || [],
      total_ad_per_status: {
        active: action?.data?.active_ads,
        finished: action?.data?.finished_ads,
        pendingReview: action?.data?.pending_review_ads,
        total: action?.data?.total_ads,
        paused: action?.data?.paused_ads,
        scheduled: action?.data?.scheduled_ads,
      },
      isLoading: false,
    },
  };
}
