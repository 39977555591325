import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from 'react-translate';
import Checkbox from '@components/Checkbox';
import Button from '@components/Button';
import * as S from './styles';
import { useCheckCapabilities } from '../hooks';
import { getType } from './consts';

export default function Marks({ media, listMode, isAd, setMarkedMedias, markedMedias }) {
  const t = useTranslate('Feed');
  const { hasBatchPromoteCapability, hasBatchReplicateCapability } = useCheckCapabilities();
  const authenticationReducer = useSelector((state) => state.authenticationReducer);

  const [isChecked, setIsChecked] = useState(false);

  const mediaType = useMemo(() => getType(media), [media]);

  const shouldRenderMark = useMemo(() => {
    const { businessTokenData } = authenticationReducer;
    const isAmericanas = ['americanas', 'lasa'].includes(businessTokenData.tenant_alias);

    if (isAmericanas && isAd) return false;

    return hasBatchPromoteCapability && hasBatchReplicateCapability;
  }, [authenticationReducer, isAd, hasBatchPromoteCapability, hasBatchReplicateCapability]);

  const updateChecked = useCallback(() => {
    const storage = JSON.parse(window.localStorage.getItem('selectedFeedItems'));
    const storageContent = storage && storage[mediaType];

    if (storageContent !== null && storageContent !== undefined) {
      const hasItem = storageContent.find((item) => item.id === media.id);
      setIsChecked(!!hasItem);
    }
  }, [media.id, mediaType]);

  useEffect(() => {
    updateChecked();
    if (markedMedias === 0) {
      setIsChecked(false);
    }
  }, [updateChecked, markedMedias]);

  const toggleMarkFeed = (feedType) => {
    const selectedItemsAllFeeds = JSON.parse(window.localStorage.getItem('selectedFeedItems')) || {
      ads: [],
      posts: [],
      stories: [],
    };

    const updateFeedType = (newValue) => {
      const newStorage = {
        ...selectedItemsAllFeeds,
        [feedType]: [...newValue],
      };
      window.localStorage.setItem('selectedFeedItems', JSON.stringify(newStorage));
    };

    const addItem = () => {
      setMarkedMedias((count) => count + 1);
      const selectedFeedContent = [...selectedItemsAllFeeds[feedType], media];
      updateFeedType(selectedFeedContent);
    };

    const removeItem = () => {
      setMarkedMedias((count) => count - 1);
      const selectedFeedContent = selectedItemsAllFeeds[feedType].filter(
        (item) => item.id !== media.id
      );
      updateFeedType(selectedFeedContent);
    };

    const willRemoveItem = !!selectedItemsAllFeeds[feedType].find((item) => item.id === media.id);
    if (willRemoveItem) {
      removeItem();
    } else {
      addItem();
    }

    setIsChecked((state) => !state);
  };

  return (
    <>
      {listMode === true
        ? shouldRenderMark && (
            <S.Wrapper>
              <Checkbox
                name={`markedAds_${media.id}`}
                id={media.id}
                onClick={() => toggleMarkFeed(mediaType)}
                checked={isChecked}
                iconCheck={['far', 'check']}
              />
            </S.Wrapper>
          )
        : shouldRenderMark && (
            <S.Wrapper>
              <Button
                backgroundColor={isChecked ? 'red3' : 'white'}
                onClick={() => toggleMarkFeed(mediaType)}
                size="px20"
                fontColor={isChecked ? 'white' : 'blueGrey2'}
              >
                {t(isChecked ? 'UNMARK' : 'MARK')}
              </Button>
            </S.Wrapper>
          )}
    </>
  );
}
