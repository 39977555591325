// Modules
import React, { ChangeEvent, ReactNode } from 'react';

// Icons
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as S from './styles';

interface SelectProps {
  selectedValue?: string;
  defaultValue?: string;
  selectSize?: string;
  disabled?: boolean;
  withoutBorder?: boolean;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  selectValue?: string;
  children: ReactNode;
}

const Select = ({
  onChange,
  selectValue,
  children,
  withoutBorder,
  disabled,
  selectSize,
  defaultValue,
}: SelectProps) => {
  const icon = faChevronDown as IconProp;
  return (
    <S.SelectWrapper withoutBorder={withoutBorder}>
      <S.SelectStyled
        selectSize={selectSize}
        onChange={onChange}
        value={selectValue}
        defaultValue={defaultValue}
        disabled={disabled}
      >
        {children}
      </S.SelectStyled>
      <FontAwesomeIcon icon={icon} />
    </S.SelectWrapper>
  );
};

export default Select;
