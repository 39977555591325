import React from 'react';
import PropTypes from 'prop-types';
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as S from './styles';

const Button = ({
  children,
  type,
  fontColor,
  fontHoverColor,
  icon,
  backgroundColor,
  backgroundHoverColor,
  borderColor,
  borderHoverColor,
  underlineOnHover,
  outline,
  size,
  fontSize,
  rounded,
  block,
  disabled,
  onClick,
  innerRef,
}) => {
  return (
    <S.ButtonStyle
      type={type}
      backgroundColor={backgroundColor}
      backgroundHoverColor={backgroundHoverColor}
      borderColor={borderColor}
      borderHoverColor={borderHoverColor}
      fontColor={fontColor}
      underlineOnHover={underlineOnHover}
      fontHoverColor={fontHoverColor}
      outline={outline}
      size={size}
      fontSize={fontSize}
      rounded={rounded}
      block={block}
      onClick={onClick}
      disabled={disabled}
      ref={innerRef}
      id="button"
    >
      <span>
        {!!icon && <FontAwesomeIcon icon={icon} data-testid="buttonIcon" />}
        {children}
      </span>
    </S.ButtonStyle>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  fontColor: PropTypes.string,
  outline: PropTypes.bool,
  size: PropTypes.string,
  rounded: PropTypes.string,
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  type: 'button',
  backgroundColor: 'blueGrey2',
  outline: false,
  size: 'px40',
  rounded: 'px10',
  fontSize: 'px14',
  block: false,
  disabled: false,
  onClick: () => {},
};

export default Button;
