import React from 'react';
import styled from 'styled-components';


const ImageStyle = styled('img')`
  display: block;
  max-width: 100%

  ${props => `
    ${props.logo ? 'max-height: 40px' : ''}
  `}
`;

export default function Image({...props}) {
  return <ImageStyle {...props} />
}