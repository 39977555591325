// Scale
import { pxToRem } from 'utils/scale';
import { multilineEllipsis } from './styleHandler';
import { DefaultTheme } from 'styled-components';

// Themes
export const primaryTheme: DefaultTheme = {
  breakpoint: {
    xs: '320px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },
  colors: {
    white: '#ffffff',
    none: 'transparent',
    active: {
      blue2: '#004367',
    },

    // New Colors
    grey1: '#b4b4b4',
    grey2: '#cecece',
    grey3: '#f8f8f8',
    grey4: '#E6E6E6',
    grey5: '#f7f7f7',
    grey6: '#BBBBBB',
    grey7: '#e5e5e5',
    grey8: '#d5d5d5',
    grey9: '#808080',
    grey10: '#FEC8C8',
    grey11: '#616161',
    backdrop: 'rgba(40, 40, 40, 0.3)',
    neutralLow: '#757575',
    blueSecurity: '#2328EB',
    blueSecurity2: '#D3D4EB',
    blueSecurity4: '#1a1cab',
    blueSecurity5: '#10126b',
    blueGrey1: '#0C2833',
    blueGrey2: '#304E5C',
    blueGrey3: '#577584',
    blueGrey4: '#8BA4B1',
    blueGrey5: '#C3CFD6',
    blueGrey6: '#F2F2F2',
    blueGrey7: '#314E5D',
    blueGrey8: '#999999',
    blue1: '#004367',
    blue2: '#0087C8',
    blue3: '#2DBFF3',
    blue4: '#B9E6FB',
    blue5: '#E7F3FA',
    blue6: '#F4FBFF',
    blue7: '#2390CE',
    blue8: '#2985AB',
    blue9: '#337ab7',
    red1: '#64001C',
    red2: '#89002B',
    red3: '#B51D42',
    red4: '#FAC8C7',
    red5: '#FFE0E0',
    red6: '#FFEBEB',
    red7: '#4c5564',
    orange1: '#985826',
    orange2: '#AF6A22',
    orange3: '#F1A539',
    orange4: '#FFE17F',
    orange5: '#FFF9DB',
    orange6: '#FFFCEB',
    green1: '#005742',
    green2: '#007864',
    green3: '#00967D',
    green4: '#A0D9DE',
    green5: '#C9F8F6',
    green6: '#E6FFFE',
    pink1: '#6A51A8',
    pink2: '#6A51A8',
    purple: '#561EA5',
    purple2: '#7821E8',
    brown: '#765A50',
    orange: '#F36A2D',
    black: '#000000',
    yellow: '#FFE373',
    green: '#1AB335',
    blue: '#23CCEB',
    red: '#FD2625',
    grey: '#CCCCCC',
    purple3: '#7A23EB',
    purple4: '#7116E9',
    black1: '#131313',
    black2: '#333333',
    pink: '#E0157A',

    //Named Colors,
    inclusionPink: '#EB2387',
    inclusionPinkLight2: '#eb5ea4',
    communicationYellow: '#FEDB4D',
    optmisticOrange: '#FD7929',
    dangerousRed2: '#FEA6A6',
    successfulGreen: '#89E5CD',
    successfulGreen2: '#006147',
    successGreen: '#0A7B5D',
    errorRed: '#E80202',
    seriousBlack: '#999999',
    sucessfulGreen: '#1AB335',
    dangerousRed: '#FD2625',
    warningOrange: '#FEE7DC',
    warningOrange2: '#BF5B1D',
  },
  spacing: {
    px0: pxToRem(0),
    px2: pxToRem(2),
    px3: pxToRem(3),
    px4: pxToRem(4),
    px5: pxToRem(5),
    px6: pxToRem(6),
    px7: pxToRem(7),
    px8: pxToRem(8),
    px10: pxToRem(10),
    px11: pxToRem(11),
    px12: pxToRem(12),
    px14: pxToRem(14),
    px15: pxToRem(15),
    px16: pxToRem(16),
    px18: pxToRem(18),
    px20: pxToRem(20),
    px24: pxToRem(24),
    px25: pxToRem(25),
    px26: pxToRem(26),
    px30: pxToRem(30),
    px32: pxToRem(32),
    px35: pxToRem(35),
    px40: pxToRem(40),
    px48: pxToRem(48),
    px50: pxToRem(50),
    px60: pxToRem(60),
    px70: pxToRem(70),
    px80: pxToRem(80),
    px90: pxToRem(90),
    px100: pxToRem(100),

    none: '0',
    quarter: '25%',
    middle: '50%',
    full: '100%',
  },
  fontSize: {
    none: '0',
    px1: pxToRem(1),
    px2: pxToRem(2),
    px4: pxToRem(4),
    px8: pxToRem(8),
    px10: pxToRem(10),
    px11: pxToRem(11),
    px12: pxToRem(12),
    px14: pxToRem(14),
    px16: pxToRem(16),
    px18: pxToRem(18),
    px20: pxToRem(20),
    px22: pxToRem(22),
    px24: pxToRem(24),
    px25: pxToRem(25),
    px30: pxToRem(30),
    px32: pxToRem(32),
    px35: pxToRem(35),
    px40: pxToRem(40),
    px48: pxToRem(48),
    px45: pxToRem(45),
    px56: pxToRem(56),
    px60: pxToRem(60),
    px64: pxToRem(64),
  },
  fontWeight: {
    light: 300,
    normal: 400,
    bold: 700,
  },
  fontFamily: {
    default:
      '"Lato", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "sans-serif"',
  },
  rounded: {
    px1: '1px',
    px3: '3px',
    px4: '4px',
    px5: '5px',
    px8: '8px',
    px10: '10px',
    px16: '16px',
    px40: '40px',
    px90: '90px',
    none: '0',
    half: '50%',
    full: '100%',
  },
  multilineEllipsis,
  shadows: {
    cardDefault: '3px 1px 24px 2px rgba(153, 153, 153, 0.1)',
    deep: '0px 4px 8px rgba(51, 51, 51, 0.16)',
    light: '0px 3px 3px 0px #8c97a81a',
  },
  gradient: {
    blue: 'linear-gradient(135deg, #7116e9 14.64%, #e0157a 85.36%)',
  },
};
