const CreatePasswordPT = {
  CreatePassword: {
    CREATE_YOUR_PASSWORD: 'Crie sua senha',
    INVITE_RECIEVED: 'Você recebeu um convite para acessar o Gerentes Digitais.',
    PASSWORD_SPECIFICATIONS: 'Sua senha deve conter:',
    MINIMUM_CHARACTERS: 'Mínimo de 6 dígitos',
    LETTERS_AND_NUMBERS: 'Letras e números',
    CHARACTERS: 'Caracterespeciais',
    TYPE_PASSWORD: 'Digite sua senha',
    CONFIRM_PASSWORD: 'Confirme sua senha',
    LOGIN: 'Entrar',
    PASSWORD_SUCCESS: 'Senha alterada com sucesso!',
    PASSWORD_FAILURE: 'Erro ao tentar alterar a senha. Por favor, entre em contato com um administrador!',
  },
};

const CreatePasswordEN = {
  CreatePassword: {
    CREATE_YOUR_PASSWORD: 'Create your password',
    INVITE_RECIEVED: 'You recieved an invite to access the Gerentes Digitais',
    PASSWORD_SPECIFICATIONS: 'Your password must contain:',
    MINIMUM_CHARACTERS: '6-digit minimum',
    LETTERS_AND_NUMBERS: 'Letters and numbers',
    CHARACTERS: 'Special characters',
    TYPE_PASSWORD: 'Type your password',
    CONFIRM_PASSWORD: 'Confirm your password',
    LOGIN: 'Login',
    PASSWORD_SUCCESS: 'Password changed successfully!',
    PASSWORD_FAILURE: 'Error trying to change password. Please contact an administrator!',
  },
};

export { CreatePasswordPT, CreatePasswordEN };
