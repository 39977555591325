// IE
import 'core-js/es6/map'; // for IE old browsers.
import 'core-js/es6/set'; // for IE old browsers
import 'raf/polyfill'; // for IE old browsers
import 'babel-polyfill'; // for IE old browsers

// Modules
import React from 'react';
import ReactDOM from 'react-dom';

//Sentry
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

// Components
import App from './containers/App';

// Providers
import Providers from './providers';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from './ErrorBoundary';

// Sentry.init({
//   dsn: "https://5f55970fd48247a0978c4d61979ee091@o1345730.ingest.sentry.io/6622741",
//   integrations: [new BrowserTracing()],

//   tracesSampleRate: 1.0,
// });

ReactDOM.render(
  <Providers>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Providers>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
serviceWorker.unregister();
