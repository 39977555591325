export const EditUserRoleModalEN = {
  EditUserRoleModal: {
    EDIT_ROLE: 'Edit role',
    ROLE: 'Role',
    SAVE: 'Save',
    CANCEL: 'Cancel',
    SELECT_ROLE: 'Select a role',
    EDIT_USER_ROLE_SUCCESS_MESSAGE: 'Role changed successfully!',
    EDIT_USER_ROLE_ERROR_MESSAGE: 'Failed to change user role!',
  },
};

export const EditUserRoleModalES = {
  EditUserRoleModal: {
    EDIT_ROLE: 'Editar función',
    ROLE: 'Ocupación',
    SAVE: 'Salvar',
    CANCEL: 'Cancelar',
    SELECT_ROLE: 'Seleccione una función',
    EDIT_USER_ROLE_SUCCESS_MESSAGE: '¡Función cambiada con éxito!',
    EDIT_USER_ROLE_ERROR_MESSAGE: '¡No se pudo cambiar la función del usuario!',
  },
};

export const EditUserRoleModalPT = {
  EditUserRoleModal: {
    EDIT_ROLE: 'Editar função',
    ROLE: 'Função',
    SAVE: 'Salvar',
    CANCEL: 'Cancelar',
    SELECT_ROLE: 'Selecione uma função',
    EDIT_USER_ROLE_SUCCESS_MESSAGE: 'Função alterada com sucesso!',
    EDIT_USER_ROLE_ERROR_MESSAGE: 'Falha ao alterar a função do usuário!',
  },
};
