const AudienceEN = {
  Audience: {
    impressions: 'Impressions',
    spend: 'Investment',
    clicks: 'Clicks',
    // Age & Gender
    AGE_GENDER: 'Age and gender',
    male: 'Male',
    female: 'Female',
    // Channel
    CHANNEL: 'Channel',
    SEPARATOR_ON: 'on',
    SHOW_METRICS: 'Chart action display',
    ALL_CHANNELS: 'all channels',
    // Device
    DEVICE: 'Device',
    android: 'Android',
    desktop: 'Desktop',
    ios: 'iOS',
    // Small Values
    'SMALL-VALUES-INFO': 'The values ​​for this category are too small to assemble the chart.',
    // Metrics
    audienceNetwork: 'Partners network',
    facebook: 'Facebook',
    instagram: 'Instagram',
    messenger: 'Messenger',
    best_conversion: 'Best conversion',
    invested_value: 'Invested value',
    NO_CONTENT: "There isn't data enough to be shown.",
    AGE_GENDER_TOOLTIP: "Find out how your units' audience is by age and gender in this report",
    CHANNEL_TOOLTIP: "Find out how your units' audience is by channel in this report.",
    DEVICE_TOOLTIP: 'Find out how your units are hearing by device in this report.',
  },
};

const AudiencePT = {
  Audience: {
    impressions: 'Impressões',
    spend: 'Investimento',
    clicks: 'Cliques',
    // Age & Gender
    AGE_GENDER: 'Idade e gênero',
    male: 'Masculino',
    female: 'Feminino',
    // Channel
    CHANNEL: 'Canal',
    SEPARATOR_ON: 'em',
    SHOW_METRICS: 'Métrica para exibição do gráfico',
    ALL_CHANNELS: 'todos canais',
    // Device
    DEVICE: 'Dispositivo',
    android: 'Android',
    desktop: 'Desktop',
    ios: 'iOS',
    // Small Values
    'SMALL-VALUES-INFO': 'Os valores para esta categoria são muito pequenos para montar o gráfico.',
    // Metrics
    audienceNetwork: 'Rede de parceiros',
    facebook: 'Facebook',
    instagram: 'Instagram',
    messenger: 'Messenger',
    best_conversion: 'Melhor conversão',
    invested_value: 'Valor investido',
    NO_CONTENT: 'Não há dados suficientes para serem exibidos.',
    AGE_GENDER_TOOLTIP:
      'Saiba como está a audiência de suas unidades por idade e gênero neste relatório',
    CHANNEL_TOOLTIP: 'Saiba como está a audiência de suas unidades por canal neste relatório.',
    DEVICE_TOOLTIP: 'Saiba como está a audiência de suas unidades por dispositivo neste relatório.',
  },
};

const AudienceES = {
  Audience: {
    impressions: 'Impresiones',
    spend: 'Inversión',
    clicks: 'Clics',
    // Age & Gender
    AGE_GENDER: 'Edad y género',
    male: 'Masculino',
    female: 'Femenino',
    // Channel
    CHANNEL: 'Canal',
    SEPARATOR_ON: 'em',
    SHOW_METRICS: 'Indicador para visualización del gráfico',
    ALL_CHANNELS: 'todos los canales',
    // Device
    DEVICE: 'Herramienta',
    android: 'Android',
    desktop: 'Desktop',
    ios: 'iOS',
    // Small Values
    'SMALL-VALUES-INFO':
      'Los valores de esta categoría son demasiado pequeños para trazar el gráfico.',
    // Metrics
    audienceNetwork: 'Red de compañeros',
    facebook: 'Facebook',
    instagram: 'Instagram',
    messenger: 'Messenger',
    best_conversion: 'Mejor conversión',
    invested_value: 'Valor invertido',
    NO_CONTENT: 'No hay suficientes datos para mostrar.',
    AGE_GENDER_TOOLTIP:
      'Sepa cómo está la audiencia de sus unidades por edad y género en este informe',
    CHANNEL_TOOLTIP: 'Sepa cómo está la audiencia de sus unidades por canal en este informe.',
    DEVICE_TOOLTIP: 'Sepa cómo está la audiencia de sus unidades por herramienta en este informe.',
  },
};

export { AudiencePT, AudienceEN, AudienceES };
