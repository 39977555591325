/* Modules */
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { SettingsService, RuleService } from '@services';

/* Types */
import { GET_UNITS, ADD_UNIT, CHANGE_TARGETING, GET_EA_GROUPS } from './types';
import { SHOW_TOAST } from '../toast/types';

function* getUnits(action) {
  const response = yield call(
    SettingsService.getUnits,
    action.version,
    action.source_id,
    action.page_hash,
    action.limit
  );

  if (response.status === 200) {
    yield put({ type: GET_UNITS.SUCCESS, units: response.data });
  } else {
    yield put({ type: GET_UNITS.FAILURE, unitsError: response.data.error });
  }
}

function* addUnit(action) {
  const response = yield call(SettingsService.addUnit, action.version, action.payload);

  if (response.status === 200) {
    const unitData = action.payload.entries[0];
    const newUnit = {
      source_id: unitData.group_id,
      name: unitData.name,
      selectable: false,
      disabled: false,
      id: unitData.name,
      group: 'PENDING',
      checked: false,
      lastStatus: {
        status: 'PENDING',
        datetime: Date.now(),
        message: null,
      },
    };

    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.feedbackMessage.success,
      messageType: 'success',
    });
    yield put({ type: ADD_UNIT.SUCCESS, payload: newUnit });
    action.onSuccess();
  } else {
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.feedbackMessage.error,
      messageType: 'error',
    });
  }
}

function* changeUnitTargeting(action) {
  const response = yield call(
    RuleService.setTargetingRestriction,
    action.version,
    action.adAccountId,
    action.payload
  );

  if (response.status === 200) {
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.feedbackMessage.success,
      messageType: 'success',
    });
    action.onSuccess();
  } else {
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.feedbackMessage.error,
      messageType: 'error',
    });
  }
}

function* getEAGroups(action) {
  const response = yield call(SettingsService.getEAGroups, action.version, action.source_id);

  if (response.status === 200 && response.data.length > 0) {
    yield put({ type: GET_EA_GROUPS.SUCCESS, easyAccess: response.data });
  } else {
    yield put({ type: GET_EA_GROUPS.FAILURE, easyAccessError: 'Failure' });
  }
}

export const settingsSaga = [
  takeLatest(GET_UNITS.REQUEST, getUnits),
  takeEvery(ADD_UNIT.REQUEST, addUnit),
  takeEvery(CHANGE_TARGETING.REQUEST, changeUnitTargeting),
  takeEvery(GET_EA_GROUPS.REQUEST, getEAGroups),
];
