const HomeBudgetEN = {
  Budget: {
    LOCALE: 'en',
    CARD_TITLE: 'Budget',
    CARD_SUBTITLE: 'Resumo dos gastos das unidades',
    CARD_BUTTON_TEXT: 'More details',
    TOTAL_SPEND: 'Valor total investido no período',
    AVAILABLE_BUDGET: 'Budget available: ',
    OF: ' of ',
    SPEND_FROM_TOTAL: 'spend from total',
    CURRENT_MONTH_GOAL: 'current month goal',
    ALLOCATED_BUDGET: 'allocated budget',
    SPEND_BUDGET: 'Verba investida',
    BUTTON_TEXT: 'Acompanhar verba',
    MONITORING_OF_UNITS: 'Monitoring of units',
    GDTV: 'Create GDTV',
    CREATE_CAMPAIGN: 'Create Campaign',
    CAMPAIGN_NAME: 'Campaign name',
    TITLE_CAMPAIGN: 'Time to create your incentive campaign!',
    TITLE_CAMPAIGN_ONE: 'You can publish',
    TITLE_CAMPAIGN_TWO: 'just an image or video',
    TITLE_CAMPAIGN_THREE: 'at a time.',
    TITLE_CAMPAIGN_FOUR: 'Allowed extensions: jpeg, jpg, png, mp4 and webm.',
    INPUT_DESCRIPTION: 'Content description',
    INPUT_VALID: 'How long will the campaign be valid for?',
    SUBTITLE_WARNING:
      'Please note: this period will be the same as the ad serving period for your units',
    PLACEHOLDER_CAMPAIGN: 'Enter the campaign name',
    PLACEHOLDER_DESCRIPTION: 'What will be the description for the unit?',
    PLACEHOLDER_START_DATE: 'Start date',
    PLACEHOLDER_END_DATE: 'End date',
    INPUT_OBJECTIVE: 'And the purpose of the ads?',
    CANCEL_BUTTON: 'Cancel',
    SEND_BUTTON: 'Advance',
    CAMPAIGN_BUDGET: 'What will be the budget for the campaign?',
    INSERT_THE_CAMPAIGN_BUDGET: 'Campaign budget',
    SELECT_GOAL: 'Select what your ad is for',
    TITLE_GDTV: 'Create your folder',
    TITLE_GDTV_ONE: 'For ease of organization, your releases can be grouped by subject.',
    TITLE_GDTV_TWO: 'Your units will receive your communication on the',
    TITLE_GDTV_THREE: 'Feed do App.',
    GDTV_NAME: 'Name*',
    PLACEHOLDER_GDTV: 'Enter your folder name',
    INPUT_DESCRIPTION_GDTV: 'Description',
    INPUT_DESCRIPTION_GDTV_ONE: 'What will be the subject of your release',
    TITLE_GDTV_FOUR: ' ',
    INPUT_VALID: 'How long will the campaign be valid for?',
    POST_FOLDER_SUCCESS_MESSAGE: 'Folder created successfully!',
    POST_FOLDER_FAILURE_MESSAGE: 'Failed to create this folder, please try again later!',
    TITLE_SELECT_GROUPS_GDTV: 'Upload your content',
    SUB_TITLE_SELECT_GROUPS_1_GDTV: 'You can only post one image or video at a time.',
    SUB_TITLE_SELECT_GROUPS_2_GDTV: 'Allowed extensions: jpeg, jpg, png, mp4 and webm.',
    SHARE_GDTV: 'Share',
    VIEW_PREVIOUSLY: 'View previously',
    CAMPAIGN_SUCCESS_MESSAGE: 'Campaign successfully created!',
    CAMPAIGN_FAILURE_MESSAGE: 'Error creating the campaign',
    POST_GDTV: 'Post',
    TITLE_SELECT_GROUPS: 'Choose units to run campaign',
    SUB_TITLE_SELECT_GROUPS_1: 'You can select an entire group or just a few',
    SUB_TITLE_SELECT_GROUPS_2: 'units to display your incentive campaign.',
    CAMPAIGN_LABEL_PERIOD: 'Campaign validity period',
    CAMPAIGN_LABEL_OBJECTIVE: 'Purpose of ads',
    YOUR_CAMPAIGN: 'Your incentive campaign',
    link_clicks: 'Link clicks',
    post_engagement: 'Engagements',
    conversions: 'Conversions',
    FILE_TITLE: 'Upload your files',
    TITLE_CAMPAIGN_ONE: 'You can publish',
    TITLE_CAMPAIGN_TWO: 'just an image or video',
    TITLE_CAMPAIGN_THREE: 'at a time.',
    TITLE_CAMPAIGN_FOUR: 'Allowed extensions: jpeg, jpg, png, mp4 and webm.',
    CHOOSE_UNITS: 'Choose units',
    CANCEL: 'Cancel',
    SHARE_WITH_EVERYONE: 'Share with everyone',
    INCLUDE_FILE: 'Include file',
    FILTER_LABEL_POSTING: 'Posting',
    FILTER_LABEL_ANNOUNCING: 'Announcing',
    FILTER_LABEL_BRAND_HOUSE: 'Casas Bahia',
    FILTER_LABEL_BRAND_SCORE: 'Ponto',
    LOW_USE_UNITS: 'Units with low investment',
    HIGH_USE_UNITS: 'Units with high investment',
    EXPECTED_USE_UNITS: 'Units with expected investment',
    TOTAL_USE_UNITS: 'Units with total use of investment',
    CURRENT_PERIOD: 'Current period',
    TO: 'to',
    MONTHLY_BILLING: 'Monthly billing',
  },
};

const HomeBudgetPT = {
  Budget: {
    LOCALE: 'pt-br',

    CARD_TITLE: 'Verba',
    CARD_SUBTITLE: 'Resumo dos gastos das unidades',
    CARD_BUTTON_TEXT: 'Mais detalhes',
    TOTAL_SPEND: 'Valor total investido no período',
    AVAILABLE_BUDGET: 'Verba disponível: ',
    OF: ' de ',
    SPEND_FROM_TOTAL: 'gasto do total',
    CURRENT_MONTH_GOAL: 'Meta do mês atual',
    ALLOCATED_BUDGET: 'Verba alocada',
    SPEND_BUDGET: 'Verba investida',
    BUTTON_TEXT: 'Acompanhar verba',
    MONITORING_OF_UNITS: 'Acompanhamento das unidades',
    GDTV: 'Criar GDTV',
    CREATE_CAMPAIGN: 'Criar Campanha',
    CAMPAIGN_NAME: 'Nome da campanha',
    TITLE_CAMPAIGN: 'Hora de criar sua campanha de incentivo!',
    TITLE_CAMPAIGN_ONE: 'Você poderá publicar',
    TITLE_CAMPAIGN_TWO: 'apenas uma imagem ou vídeo',
    TITLE_CAMPAIGN_THREE: 'por vez.',
    TITLE_CAMPAIGN_FOUR: 'Extensões permitidas: jpeg, jpg, png, mp4 e webm.',
    INPUT_DESCRIPTION: 'Descrição do conteúdo',
    INPUT_SUBTITLE: 'Legenda do conteúdo',
    INPUT_VALID: 'Qual será o período de validade da campanha?',
    SUBTITLE_WARNING:
      'Atenção: esse período poderá ser agendado e será o mesmo de veiculação dos anúncios das suas unidades.',
    PLACEHOLDER_CAMPAIGN: 'Insira o nome da campanha',
    PLACEHOLDER_DESCRIPTION: 'Qual será a descrição para a unidade?',
    PLACEHOLDER_START_DATE: 'Data de início',
    PLACEHOLDER_END_DATE: 'Data de término',
    INPUT_OBJECTIVE: 'E o objetivo dos anúncios?',
    CANCEL_BUTTON: 'Cancelar',
    SEND_BUTTON: 'Avançar',
    CHOOSE_GROUPS: 'Escolher grupos',
    CHOOSE_UNITS: 'Escolher unidades',
    CAMPAIGN_BUDGET: 'Qual será o orçamento para a campanha?',
    INSERT_THE_CAMPAIGN_BUDGET: 'Orçamento da campanha',
    SELECT_GOAL: 'Selecione qual o objetivo do seu anúncio',
    TITLE_GDTV: 'Crie sua pasta',
    TITLE_GDTV_ONE: 'Para facilitar a organização, seus comunicados podem ser agrupados por temas.',
    TITLE_GDTV_TWO: 'Suas unidades receberão seu comunicado no',
    TITLE_GDTV_THREE: 'Feed do App.',
    GDTV_NAME: 'Nome*',
    PLACEHOLDER_GDTV: 'Insira o nome da sua pasta',
    INPUT_DESCRIPTION_GDTV: 'Descrição',
    INPUT_DESCRIPTION_GDTV_ONE: 'Qual será o tema do seu comunicado',
    TITLE_GDTV_FOUR: ' ',
    POST_FOLDER_SUCCESS_MESSAGE: 'Pasta criada com sucesso!',
    POST_FOLDER_FAILURE_MESSAGE: 'Falha ao criar esta pasta, tente novamente mais tarde!',
    TITLE_SELECT_GROUPS_GDTV: 'Faça upload do seu conteúdo',
    SUB_TITLE_SELECT_GROUPS_1_GDTV: 'Você poderá publicar apenas uma imagem ou vídeo por vez.',
    SUB_TITLE_SELECT_GROUPS_2_GDTV: 'Extensões permitidas: jpeg, jpg, png, mp4 e webm.',
    SHARE_GDTV: 'Compartilhar',
    VIEW_PREVIOUSLY: 'Ver prévia',
    CAMPAIGN_SUCCESS_MESSAGE: 'Campanha criada com sucesso!',
    CAMPAIGN_FAILURE_MESSAGE: 'Erro ao criar a campanha',
    POST_GDTV: 'Postar',
    TITLE_SELECT_GROUPS: 'Escolha as unidades para exibir campanha',
    SUB_TITLE_SELECT_GROUPS_1: 'Você poderá selecionar um grupo todo ou apenas algumas',
    SUB_TITLE_SELECT_GROUPS_2: 'unidades para exibir a sua campanha de incentivo.',
    YOUR_CAMPAIGN: 'Sua campanha de incentivo',
    CAMPAIGN_LABEL_PERIOD: 'Período de validade da campanha',
    CAMPAIGN_LABEL_OBJECTIVE: 'Objetivo dos anúncios',
    link_clicks: 'Cliques no link',
    post_engagement: 'Engajamento',
    conversions: 'Conversões',
    FILE_TITLE: 'Faça o upload de seus arquivos',
    TITLE_CAMPAIGN_ONE: 'Você poderá publicar',
    TITLE_CAMPAIGN_TWO: 'apenas uma imagem ou vídeo',
    TITLE_CAMPAIGN_THREE: 'por vez.',
    TITLE_CAMPAIGN_FOUR: 'Extensões permitidas: jpeg, jpg, png, mp4 e webm.',
    CHOOSE_UNITS: 'Escolher unidades',
    CANCEL: 'Cancelar',
    SHARE_WITH_EVERYONE: 'Compartilhar com todos',
    INCLUDE_FILE: 'Incluir arquivo',
    FILTER_LABEL_POSTING: 'Postando',
    FILTER_LABEL_ANNOUNCING: 'Anunciando',
    FILTER_LABEL_NOT_POSTING: 'Não Postando',
    FILTER_LABEL_NOT_ANNOUNCING: 'Não Anunciando',
    FILTER_LABEL_BRAND_HOUSE: 'Casas Bahia',
    FILTER_LABEL_BRAND_SCORE: 'Ponto',
    LOW_USE_UNITS: 'Unidades com baixo investimento',
    HIGH_USE_UNITS: 'Unidades com alto investimento',
    EXPECTED_USE_UNITS: 'Unidades com investimento esperado',
    TOTAL_USE_UNITS: 'Unidades com uso total do investimento',
    CURRENT_MONTH: 'Mês Corrente',
    CURRENT_PERIOD: 'Período Corrente',
    TO: 'a',
    MONTHLY_BILLING: 'Faturamento do mês',
  },
};

const HomeBudgetES = {
  Budget: {
    LOCALE: 'es',
    CARD_TITLE: 'Importe',
    CARD_SUBTITLE: 'Resumen de gastos de las unidades',
    CARD_BUTTON_TEXT: 'Más detalles',
    TOTAL_SPEND: 'Total invertido en el período',
    AVAILABLE_BUDGET: 'Importe disponible: ',
    OF: ' de ',
    SPEND_FROM_TOTAL: 'gastado del total',
    CURRENT_MONTH_GOAL: 'Objetivo del mes actual',
    ALLOCATED_BUDGET: 'Importe repartido',
    // SPEND_BUDGET: 'Verba investida',
    BUTTON_TEXT: 'Acompañar inversión',
    // MONITORING_OF_UNITS: 'Acompanhamento das unidades',
    // GDTV: 'Criar GDTV',
    CREATE_CAMPAIGN: 'Haga campaña',
    // CAMPAIGN_NAME: 'Nome da campanha',
    // TITLE_CAMPAIGN: 'Hora de criar sua campanha de incentivo!',
    // TITLE_CAMPAIGN_ONE: 'Você poderá publicar',
    // TITLE_CAMPAIGN_TWO: 'apenas uma imagem ou vídeo',
    // TITLE_CAMPAIGN_THREE: 'por vez.',
    // TITLE_CAMPAIGN_FOUR: 'Extensões permitidas: jpeg, jpg, png, mp4 e webm.',
    // INPUT_DESCRIPTION: 'Descrição do conteúdo',
    // INPUT_SUBTITLE: 'Legenda do conteúdo',
    // INPUT_VALID: 'Qual será o período de validade da campanha?',
    // SUBTITLE_WARNING:
    //   'Atenção: esse período poderá ser agendado e será o mesmo de veiculação dos anúncios das suas unidades.',
    // PLACEHOLDER_CAMPAIGN: 'Insira o nome da campanha',
    // PLACEHOLDER_DESCRIPTION: 'Qual será a descrição para a unidade?',
    // PLACEHOLDER_START_DATE: 'Data de início',
    // PLACEHOLDER_END_DATE: 'Data de término',
    // INPUT_OBJECTIVE: 'E o objetivo dos anúncios?',
    // CANCEL_BUTTON: 'Cancelar',
    // SEND_BUTTON: 'Avançar',
    // CHOOSE_GROUPS: 'Escolher grupos',
    // CHOOSE_UNITS: 'Escolher unidades',
    // CAMPAIGN_BUDGET: 'Qual será o orçamento para a campanha?',
    // INSERT_THE_CAMPAIGN_BUDGET: 'Orçamento da campanha',
    // SELECT_GOAL: 'Selecione qual o objetivo do seu anúncio',
    // TITLE_GDTV: 'Crie sua pasta',
    // TITLE_GDTV_ONE: 'Para facilitar a organização, seus comunicados podem ser agrupados por temas.',
    // TITLE_GDTV_TWO: 'Suas unidades receberão seu comunicado no',
    // TITLE_GDTV_THREE: 'Feed do App.',
    GDTV_NAME: 'Nome*',
    // PLACEHOLDER_GDTV: 'Insira o nome da sua pasta',
    // INPUT_DESCRIPTION_GDTV: 'Descrição',
    // INPUT_DESCRIPTION_GDTV_ONE: 'Qual será o tema do seu comunicado',
    TITLE_GDTV_FOUR: ' ',
    // POST_FOLDER_SUCCESS_MESSAGE: 'Pasta criada com sucesso!',
    // POST_FOLDER_FAILURE_MESSAGE: 'Falha ao criar esta pasta, tente novamente mais tarde!',
    // TITLE_SELECT_GROUPS_GDTV: 'Faça upload do seu conteúdo',
    // SUB_TITLE_SELECT_GROUPS_1_GDTV: 'Você poderá publicar apenas uma imagem ou vídeo por vez.',
    // SUB_TITLE_SELECT_GROUPS_2_GDTV: 'Extensões permitidas: jpeg, jpg, png, mp4 e webm.',
    // SHARE_GDTV: 'Compartilhar',
    // VIEW_PREVIOUSLY: 'Ver prévia',
    // CAMPAIGN_SUCCESS_MESSAGE: 'Campanha criada com sucesso!',
    // CAMPAIGN_FAILURE_MESSAGE: 'Erro ao criar a campanha',
    // POST_GDTV: 'Postar',
    // TITLE_SELECT_GROUPS: 'Escolha as unidades para exibir campanha',
    // SUB_TITLE_SELECT_GROUPS_1: 'Você poderá selecionar um grupo todo ou apenas algumas',
    // SUB_TITLE_SELECT_GROUPS_2: 'unidades para exibir a sua campanha de incentivo.',
    // YOUR_CAMPAIGN: 'Sua campanha de incentivo',
    // CAMPAIGN_LABEL_PERIOD: 'Período de validade da campanha',
    // CAMPAIGN_LABEL_OBJECTIVE: 'Objetivo dos anúncios',
    // link_clicks: 'Cliques no link',
    post_engagement: 'Involucramiento',
    // conversions: 'Conversões',
    // FILE_TITLE: 'Faça o upload de seus arquivos',
    // CANCEL: 'Cancelar',
    // SHARE_WITH_EVERYONE: 'Compartilhar com todos',
    // INCLUDE_FILE: 'Incluir arquivo',
    // FILTER_LABEL_POSTING: 'Postando',
    // FILTER_LABEL_ANNOUNCING: 'Anunciando',
    // FILTER_LABEL_NOT_POSTING: 'Não Postando',
    // FILTER_LABEL_NOT_ANNOUNCING: 'Não Anunciando',
    FILTER_LABEL_BRAND_HOUSE: 'Casas Bahia',
    FILTER_LABEL_BRAND_SCORE: 'Ponto',
    LOW_USE_UNITS: 'Unidades con baja inversión',
    HIGH_USE_UNITS: 'Unidades con alta inversión',
    EXPECTED_USE_UNITS: 'Unidades con inversión esperada',
    TOTAL_USE_UNITS: 'Unidades que utilizan toda la inversión',
    CURRENT_MONTH: 'Mes actual',
    CURRENT_PERIOD: 'Período actual',
    TO: 'a',
    MONTHLY_BILLING: 'Facturación mensual',
  },
};

export { HomeBudgetEN, HomeBudgetPT, HomeBudgetES };
