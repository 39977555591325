const CompanyEN = {
  Company: {
    COMPANY_TITLE: 'Company Settings',
    COMPANY_SUBTITLE: "Here you'll find the company registration data and contract status.",
    UNITS_OPTION: 'Units',

    EDIT: 'Edit',
    CANCEL: 'Cancel',
    SAVE: 'Save',
    COMPANY_TYPE: 'Category',
    COMPANY_SEGMENT: 'Segment',
    COMPANY_VIEW_WINDOW: 'Conversion Window',
    COMPANY_MAIN_METRIC: 'Main Metric',
    CLIENT_SINCE: 'Client since',
    LAST_UPDATE: 'Last update',
    LAST_UPDATE_MADE_BY: 'Made by',
    SEE_HISTORY: 'See history',
    SUB_SECTOR: 'Sub-sector',
    CS_MANAGER: 'CS Manager',
    ACCOUNT_MANAGER: 'Account Manager',

    CHANGE: 'Change',
    MODAL_CONFIRMATION_TITLE: 'Are you sure you want to change the customer agreement?',
    MODAL_CONFIRMATION_CHURN_TITLE:
      ' Once marked as churn, the customer will be completely unconfigured and there will be no easy way back!',
    COMPANY_NAME: 'Company name',
    EDIT_COMPANY_TITLE: 'Edit company settings',
    EDIT_COMPANY_SUBTITLE:
      'Here you can edit the name, CNPJ, category, segment, conversion window and main metric.',
    VIEW_WINDOW_INPUT_TOOLTIP:
      'Setting this value does change the visibility of the data in the overall report and Dashboard',
    INVALID_CNPJ: 'Invalid CNPJ!',
    EDIT_COMPANY_SUCCESS_MESSAGE: 'Company settings changed successfully!',
    EDIT_COMPANY_ERROR_MESSAGE: 'Failed to update company settings!',

    STATUS_HISTORY_TITLE: 'Status history',
    STATUS_DATE: 'Date',
    STATUS_PREVIOUS: 'Previous status',
    STATUS_NEW: 'New status',
    STATUS_CHANGED_BY: 'Changed by',
    STATUS_HISTORY_NO_CHANGES: 'No changes.',

    UNITS_POTENTIAL_TITLE: 'Units potential',
    UNITS_POTENTIAL_SUBTITLE: "Summary of the project's reach",
    POTENTIAL_UNITS: 'Potential units',
    CONTRACTED_UNITS: 'Contracted units',
    OPERATIONAL_UNITS: 'Operational units',
    INVALID_NUMBER: 'Please, insert a valid number.',
    POTENTIAL_UNITS_EDIT_SUCCESS_MESSAGE: 'Potential units changed successfully!',
    CONTRACTED_UNITS_EDIT_SUCCESS_MESSAGE: 'Failed to update potential units!',

    COMPANY_STATUS_FULL: 'FULL - Complete contract',
    COMPANY_STATUS_FREETRIAL: 'FREETRIAL - Free trial client',
    COMPANY_STATUS_POC: 'POC - Paid trial client',
    COMPANY_STATUS_CHURN: 'CHURN - Deactivated client',
  },
};

const CompanyES = {
  Company: {
    COMPANY_TITLE: 'Configuración de la empresa',
    COMPANY_SUBTITLE:
      'Aquí encontrará los datos de registro de la empresa y el estado del contrato.',
    UNITS_OPTION: 'Unidades',

    EDIT: 'Editar',
    CANCEL: 'Cancelar',
    SAVE: 'Grabar',
    COMPANY_TYPE: 'Categoría',
    COMPANY_SEGMENT: 'Segmento',
    COMPANY_VIEW_WINDOW: 'Ventana de Conversión',
    COMPANY_MAIN_METRIC: 'Métrica Principal',
    CLIENT_SINCE: 'Cliente desde',
    LAST_UPDATE: 'Última actualización',
    LAST_UPDATE_MADE_BY: 'Hecho por',
    SEE_HISTORY: 'Ver historial',
    SUB_SECTOR: 'Sub-sector',
    CS_MANAGER: 'CS Manager',
    ACCOUNT_MANAGER: 'Account Manager',

    CHANGE: 'Cambiar',
    MODAL_CONFIRMATION_TITLE: '¿Está seguro de que desea cambiar el acuerdo del cliente?',
    MODAL_CONFIRMATION_CHURN_TITLE:
      ' Una vez marcado como churn, el cliente se desconfigurará por completo y no habrá un camino fácil de regreso.',
    COMPANY_NAME: 'Razón social',
    EDIT_COMPANY_TITLE: 'Editar configuración de la empresa',
    EDIT_COMPANY_SUBTITLE:
      'Aquí puede editar el nombre, la empresa CNPJ, la categoría, el segmento, la ventana de conversión y la métrica principal.',
    VIEW_WINDOW_INPUT_TOOLTIP:
      'Establecer este valor cambia la visibilidad de los datos en el informe y Dashboard',
    INVALID_CNPJ: 'CNPJ inválido!',
    EDIT_COMPANY_SUCCESS_MESSAGE: '¡La configuración de la empresa cambió con éxito!',
    EDIT_COMPANY_ERROR_MESSAGE: '¡Error al cambiar la configuración de la empresa!',

    STATUS_HISTORY_TITLE: 'Historial del estado',
    STATUS_DATE: 'Fecha',
    STATUS_PREVIOUS: 'Estado anterior',
    STATUS_NEW: 'Estado nuevo',
    STATUS_CHANGED_BY: 'Cambiado por',
    STATUS_HISTORY_NO_CHANGES: 'No se registraron cambios.',

    UNITS_POTENTIAL_TITLE: 'Potencial de unidades',
    UNITS_POTENTIAL_SUBTITLE: 'Resumen del alcance que tenemos en el proyecto.',
    POTENTIAL_UNITS: 'Unidades potenciales',
    CONTRACTED_UNITS: 'Unidades contratadas',
    OPERATIONAL_UNITS: 'Unidades operativas',
    INVALID_NUMBER: 'Por favor, ingrese un número valido.',
    POTENTIAL_UNITS_EDIT_SUCCESS_MESSAGE: '¡Las unidades potenciales cambiaron con éxito!',
    CONTRACTED_UNITS_EDIT_SUCCESS_MESSAGE: 'Unidades potenciales cambiaron con éxito!',

    COMPANY_STATUS_FULL: 'FULL - Contrato completo',
    COMPANY_STATUS_FREETRIAL: 'FREETRIAL - Evaluación gratuita',
    COMPANY_STATUS_POC: 'POC - Evaluación pagada',
    COMPANY_STATUS_CHURN: 'CHURN - Cliente desactivado',
  },
};

const CompanyPT = {
  Company: {
    COMPANY_TITLE: 'Configurações da empresa',
    COMPANY_SUBTITLE: 'Aqui você encontra os dados de cadastro da empresa e situação do contrato.',
    UNITS_OPTION: 'Unidades',

    EDIT: 'Editar',
    CANCEL: 'Cancelar',
    SAVE: 'Salvar',
    COMPANY_TYPE: 'Categoria',
    COMPANY_SEGMENT: 'Segmento',
    COMPANY_VIEW_WINDOW: 'Janela de atribuição',
    COMPANY_MAIN_METRIC: 'Métrica Principal',
    CLIENT_SINCE: 'Cliente desde',
    LAST_UPDATE: 'Última atualização',
    LAST_UPDATE_MADE_BY: 'Feita por',
    SEE_HISTORY: 'Ver histórico',
    SUB_SECTOR: 'Sub-setor',
    CS_MANAGER: 'CS Manager',
    ACCOUNT_MANAGER: 'Account Manager',

    EDIT_COMPANY_TITLE: 'Editar configurações da empresa',
    EDIT_COMPANY_SUBTITLE:
      'Aqui você pode editar o nome, CNPJ da empresa, categoria, segmento, janela de atribuição e métrica principal.',

    CHANGE: 'Alterar',
    MODAL_CONFIRMATION_TITLE: 'Tem certeza que deseja alterar o contrato do cliente?',
    MODAL_CONFIRMATION_CHURN_TITLE:
      ' Uma vez marcado como churn, o cliente será todo desconfigurado e não terá volta fácil!',
    COMPANY_NAME: 'Razão social',
    VIEW_WINDOW_INPUT_TOOLTIP:
      'A configuração deste valor altera a visibilidade dos dados no relatório e Dashboard!',
    INVALID_CNPJ: 'CNPJ inválido!',
    EDIT_COMPANY_SUCCESS_MESSAGE: 'Configurações da empresa alterada com sucesso!',
    EDIT_COMPANY_ERROR_MESSAGE: 'Erro ao alterar as configurações da empresa!',

    STATUS_HISTORY_TITLE: 'Histórico de status',
    STATUS_DATE: 'Data',
    STATUS_PREVIOUS: 'Status anterior',
    STATUS_NEW: 'Novo status',
    STATUS_CHANGED_BY: 'Alterado por',
    STATUS_HISTORY_NO_CHANGES: 'Nenhuma alteração registrada.',

    UNITS_POTENTIAL_TITLE: 'Potencial de unidades',
    UNITS_POTENTIAL_SUBTITLE: 'Resumo do alcance que temos no projeto.',
    POTENTIAL_UNITS: 'Unidades potenciais',
    CONTRACTED_UNITS: 'Unidades contratadas',
    OPERATIONAL_UNITS: 'Unidades operacionais',
    INVALID_NUMBER: 'Por favor, insira um número válido.',
    POTENTIAL_UNITS_EDIT_SUCCESS_MESSAGE: 'Unidades potenciais alteradas com sucesso!',
    CONTRACTED_UNITS_EDIT_SUCCESS_MESSAGE: 'Unidades contratadas alteradas com sucesso!',

    COMPANY_STATUS_FULL: 'FULL - Contrato completo',
    COMPANY_STATUS_FREETRIAL: 'FREETRIAL - Cliente em teste',
    COMPANY_STATUS_POC: 'POC - Cliente em teste pago',
    COMPANY_STATUS_CHURN: 'CHURN - Cliente desativado',
  },
};

export { CompanyEN, CompanyES, CompanyPT };
