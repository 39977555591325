import React, { useEffect, useRef, useState } from 'react';

import { useTranslate } from 'react-translate';
import { useDispatch, useSelector } from 'react-redux';
import Image from '@components/Image';
import { useUser } from '@utils/contexts';
import { redirectToBusiness, logoutUser } from '@utils/auth';

import { EMAIL_IS_VALID, FETCH_LIST_COMPANIES, SAVE_TOKENS } from '@redux/auth/types';

import * as S from './styles';

const ChooseCompany = () => {
  const t = useTranslate('ChooseCompany');
  const dispatch = useDispatch();

  const { tenantOptions, setTenant } = useUser();
  const authenticationReducer = useSelector((state) => state.authenticationReducer);

  const [isOnboarding, setIsOnboarding] = useState(false);
  const prevAuthReducer = useRef(authenticationReducer);

  useEffect(() => {
    dispatch({
      type: FETCH_LIST_COMPANIES.REQUEST,
      version: '1.2',
    });
    const currentDomain = window.location.host.split('.')[0];
    if (currentDomain === 'onboarding') {
      setIsOnboarding(true);
    }
  }, [dispatch]);

  useEffect(() => {
    const { listCompanies, listCompaniesLoading } = authenticationReducer;

    if (prevAuthReducer.current.listCompanies !== listCompanies && !listCompaniesLoading) {
      if (isOnboarding) {
        const subDomain = listCompanies[0].sub_domain;
        redirectToBusiness(subDomain);
      }
    }

    prevAuthReducer.current = authenticationReducer;
  }, [authenticationReducer, isOnboarding]);

  const setCompany = (company) => {
    setTenant(company);
    redirectToBusiness(company.sub_domain);
  };

  const goBack = () => {
    logoutUser();

    dispatch({
      type: EMAIL_IS_VALID.RESET,
    });

    dispatch({
      type: SAVE_TOKENS.RESET,
    });
  };

  return (
    <S.ChooseCompanyWrapper>
      <S.ChooseCompanyTitle>{t('CHOOSE_COMPANY_TITLE')}</S.ChooseCompanyTitle>
      <S.ChooseCompanyList>
        {tenantOptions.length > 0 &&
          tenantOptions.map((company) => (
            <S.ChooseCompanyItem key={company.id} onClick={() => setCompany(company)}>
              <S.ChooseCompanyImage>
                <Image src={company.picture_url} alt={company.name} />
              </S.ChooseCompanyImage>
              <S.ChooseCompanyInfo>
                <S.ChooseCompanyName>{company.name}</S.ChooseCompanyName>
                <S.ChooseCompanyUsername>{company.sub_domain}</S.ChooseCompanyUsername>
              </S.ChooseCompanyInfo>
            </S.ChooseCompanyItem>
          ))}
      </S.ChooseCompanyList>
      <S.GoBack type="button" onClick={goBack}>
        {t('CHOOSE_GO_BACK')}
      </S.GoBack>
    </S.ChooseCompanyWrapper>
  );
};

export default ChooseCompany;
