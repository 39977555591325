import React from 'react';

import * as S from './styles';

const NoScriptPage = ({ button }) => {
  return (
    <S.OuterContainer>
      {button.type === 'titular' ? (
        <S.TitularContainer>{button.innerText}</S.TitularContainer>
      ) : (
        <S.DPOContainer>
          <S.TextContainer>
            Data Protection Officer (DPO)/Encarregado de Proteção de Dados
          </S.TextContainer>
          <S.TextContainer>Fernanda Tostes Malta de Oliveira</S.TextContainer>
          <S.TextContainer>lgpd@bornlogic.com</S.TextContainer>
        </S.DPOContainer>
      )}
    </S.OuterContainer>
  );
};

export default NoScriptPage;
