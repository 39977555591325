// Types
import {
  FETCH_FEED,
  FETCH_FEED_PREVIEWS,
  SET_FEED_TYPE,
  SET_FEED_ORDER_BY,
  SET_FEED_PRESET,
  FETCH_FEED_STORIES,
  FETCH_INSTAGRAM_POSTS,
  UPDATE_AD,
  FILTER_CHANNEL,
} from './types';

const INITIAL_STATE = {
  data: [],
  paging: {},
  previews: {
    data: [],
    isLoading: false,
  },
  editModal: {
    data: [],
    isLoading: false,
  },
  feedType: 'AD',
  feedFilterChannel: 'all',
  feedOrderBy: 'created_time',
  preset: 'lifetime',
  isLoading: false,
  status: null,
  message: null,
  isLoadingStatus: false,
};

const feedReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_FEED.REQUEST:
      return {
        ...state,
        paging: {},
        isLoading: true,
        status: null,
        message: null,
      };

    case FETCH_FEED.SUCCESS:
      return {
        ...state,
        data: [...state.data, ...action.data],
        paging: action.paging,
        isLoading: false,
        status: action.status,
        message: null,
      };

    case FETCH_FEED.FAILURE:
      return {
        ...state,
        data: [],
        paging: {},
        isLoading: false,
        status: action.status,
        message: action.message,
      };

    case FETCH_FEED.RESET:
      return {
        ...state,
        data: INITIAL_STATE.data,
        paging: INITIAL_STATE.paging,
        previews: INITIAL_STATE.previews,
        isLoading: INITIAL_STATE.isLoading,
        status: INITIAL_STATE.status,
        message: INITIAL_STATE.message,
      };

    case FETCH_FEED_PREVIEWS.REQUEST:
      return {
        ...state,
        previews: {
          data: [],
          isLoading: true,
        },
      };

    case FETCH_FEED_PREVIEWS.SUCCESS:
      return {
        ...state,
        previews: {
          data: action.previews,
          isLoading: false,
        },
      };

    case FETCH_FEED_PREVIEWS.FAILURE:
      return {
        ...state,
        previews: {
          data: [],
          isLoading: false,
        },
      };

    case SET_FEED_TYPE.SUCCESS:
      return {
        ...state,
        feedType: action.feedType,
      };

    case FILTER_CHANNEL.SUCCESS:
      return {
        ...state,
        feedFilterChannel: action.feedFilterChannel,
      };

    case SET_FEED_ORDER_BY.SUCCESS:
      return {
        ...state,
        feedOrderBy: action.feedOrderBy,
      };

    case SET_FEED_PRESET.SUCCESS:
      return {
        ...state,
        preset: action.preset,
      };

    case FETCH_FEED_STORIES.REQUEST:
      return {
        ...state,
        paging: {},
        isLoading: true,
        status: null,
        message: null,
      };

    case FETCH_INSTAGRAM_POSTS.REQUEST:
      return {
        ...state,
        paging: {},
        isLoading: true,
        status: null,
        message: null,
      };

    case UPDATE_AD.REQUEST:
      return {
        ...state,
        isLoadingStatus: true,
        editModal: {
          data: [],
          isLoading: true,
        },
      };

    case UPDATE_AD.SUCCESS:
      return {
        ...state,
        isLoadingStatus: false,
        editModal: {
          data: [],
          isLoading: false,
        },
      };

    case UPDATE_AD.FAILURE:
      return {
        ...state,
        isLoadingStatus: false,
        editModal: {
          data: [],
          isLoading: false,
        },
      };

    default:
      return state;
  }
};

export default feedReducer;
