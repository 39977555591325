const ProfileAndCoverEN = {
  ProfileAndCover: {
    'MISSING-IMAGE': 'Please insert one image!',
    CONFIRM: 'Confirm',
    CANCEL: 'Cancel',
    'CHANGE-PROFILE-COVER-IMAGES': 'Change images',
    'FANPAGE-SEARCH-LABEL': 'Search for the page name.',
    'UNCHECK-ALL': 'Unckeck all',
    'CHECK-ALL': 'Check all',
    'NO-FANPAGES': 'No fanpages found.',
    UPLOAD_ERROR: 'An Error happened, try again.'
  },
};

const ProfileAndCoverPT = {
  ProfileAndCover: {
    'MISSING-IMAGE': 'Por favor insira uma imagem!',
    CONFIRM: 'Confirmar',
    CANCEL: 'Cancelar',
    'CHANGE-PROFILE-COVER-IMAGES': 'Mudar imagens',
    'FANPAGE-SEARCH-LABEL': 'Procure pelo nome da página',
    'UNCHECK-ALL': 'Desmarcar todos',
    'CHECK-ALL': 'Marcar todos',
    'NO-FANPAGES': 'Não foram encontradas fanpages.',
    UPLOAD_ERROR: 'Um erro aconteceu, tente novamente.'

  },
};

const ProfileAndCoverES = {
  ProfileAndCover: {
    'MISSING-IMAGE': '¡Por favor inserta una imagen!',
    CONFIRM: 'Confirmar',
    CANCEL: 'Cancelar',
    'CHANGE-PROFILE-COVER-IMAGES': 'Cambiar imágenes',
    'FANPAGE-SEARCH-LABEL': 'Busca el nombre de la página.',
    'UNCHECK-ALL': 'Desmarcar todo',
    'CHECK-ALL': 'Seleccionar todo',
    'NO-FANPAGES': 'No se encontraron páginas de fans.',
    UPLOAD_ERROR:'Se ha producido un error, inténtalo de nuevo.'

  },
};

export { ProfileAndCoverEN, ProfileAndCoverPT, ProfileAndCoverES };
