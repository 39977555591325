const EditModalEN = {
  PAUSE_AD: 'Pause ad',
  ACTIVE_AD: 'Active ad',
  EDIT_MODAL_SUBTITLE: 'When you edit this information, your ad will be sent for review.',
  AD_ACTION_LINK: 'Ad action link',
  CANCEL: 'Cancel',
  CONFIRM: 'Confirm',
  OPTIONAL: '*optional',
  DAILY_BUDGET: 'Daily budget',
  LIFETIME_BUDGET: 'Lifetime budget',
  TO: 'to',
};

const EditModalPT = {
  PAUSE_AD: 'Pausar anúncio',
  ACTIVE_AD: 'Ativar anúncio',
  EDIT_MODAL_SUBTITLE: 'Quando você edita essas informações seu anúncio será enviado para revisão.',
  AD_ACTION_LINK: 'Link de ação do anúncio',
  CANCEL: 'Cancelar',
  CONFIRM: 'Confirmar',
  OPTIONAL: '*opcional',
  DAILY_BUDGET: 'Orçamento diário',
  LIFETIME_BUDGET: 'Orçamento vitalício',
  TO: 'até',
};

const EditModalES = {
  PAUSE_AD: 'Pausar anuncio',
  ACTIVE_AD: 'Ativar anuncio',
  EDIT_MODAL_SUBTITLE: 'Cuando editas esta información, tu anuncio será enviado para revisión.',
  AD_ACTION_LINK: 'Enlace de acción del anuncio',
  CANCEL: 'Cancelar',
  CONFIRM: 'Confirmar',
  OPTIONAL: '*opcional',
  DAILY_BUDGET: 'Presupuesto diario',
  LIFETIME_BUDGET: 'presupuesto de toda la vida',
  TO: 'hasta',
};

export { EditModalEN, EditModalPT, EditModalES };
