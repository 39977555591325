import styled from 'styled-components';

import { primaryTheme as theme } from '../../../../styles/themes';

export const Container = styled.div`
  padding: 4px 0;
`;

export const AdvancedDialogContainer = styled.div`
  box-sizing: border-box;
  display: block;
  padding: ${theme.spacing.px16} ${theme.spacing.px32};
  width: 100%;
`;
