const CardLoginPT = {
  CardLogin: {
    ALT_LOGO: 'Logo Bornlogic!',
    PLACEHOLDER_EMAIL: 'Digite seu e-mail',
    PLACEHOLDER_PASSWORD: 'Digite sua senha',
    BUTTON_NEXT: 'Próxima',
    BUTTON_LOADING: 'Carregando...',
    VALIDATION_EMAIL: 'Hum, e-mail inválido. Se precisar, consulte seu gestor.',
    VALIDATION_PASSWORD: 'Senha incorreta. Tente novamente',
    VALIDATION_PASSWORD_REQUIRED: 'Para prosseguir, digite sua senha de acesso',
    VALIDATION_REQUIRED: 'Para prosseguir, digite seu e-mail de acesso',
    CHANGE_ACCOUNT: 'Usar outra conta',
    REQUEST_INVITE: 'Solicitar convite',
    FORGOT_PASSWORD: 'Esqueceu sua senha?',
    FORGOT_PASSWORD_TEXT1: 'Enviamos um email de confirmação!',
    FORGOT_PASSWORD_TEXT1_AGAIN: 'Enviamos novamente um email de confirmação!',
    FORGOT_PASSWORD_TEXT2: 'Vá até sua caixa de email.',
    FORGOT_PASSWORD_NOT_RECEIVED: 'Não recebi o email',
    FORGOT_PASSWORD_GO_BACK: 'Voltar',
  },
};

const CardLoginEN = {
  CardLogin: {
    ALT_LOGO: 'Bornlogic Logo!',
    PLACEHOLDER_EMAIL: 'Type your email',
    PLACEHOLDER_PASSWORD: 'Enter your password',
    BUTTON_NEXT: 'Next',
    BUTTON_LOADING: 'Loading...',
    VALIDATION_EMAIL: 'Email not registered',
    VALIDATION_PASSWORD: 'Hmm, invalid email. If necessary, consult your manager.',
    VALIDATION_PASSWORD_REQUIRED: 'To proceed, enter your access key',
    VALIDATION_REQUIRED: 'To proceed, enter your access email',
    CHANGE_ACCOUNT: 'Change account',
    REQUEST_INVITE: 'Request invitation',
    FORGOT_PASSWORD: 'Forgot your password?',
    FORGOT_PASSWORD_TEXT1: 'We have sent a confirmation email!',
    FORGOT_PASSWORD_TEXT1_AGAIN: 'We have resent a confirmation email!',
    FORGOT_PASSWORD_TEXT2: 'Go to your email box.',
    FORGOT_PASSWORD_NOT_RECEIVED: 'I did not receive the email',
    FORGOT_PASSWORD_GO_BACK: 'Go back',
  },
};

const CardLoginES = {
  CardLogin: {
    ALT_LOGO: 'Bornlogic Logo!',
    PLACEHOLDER_EMAIL: 'Escriba su correo electrónico',
    PLACEHOLDER_PASSWORD: 'Escriba su contraseña',
    BUTTON_NEXT: 'Próxima',
    BUTTON_LOADING: 'Cargando...',
    VALIDATION_EMAIL: 'Hmm, correo electrónico no válido. Si es necesario, consulte a su gerente.',
    VALIDATION_PASSWORD: 'Contraseña incorrecta. Inténtalo de nuevo',
    VALIDATION_PASSWORD_REQUIRED: 'Ingrese su contraseña de acceso',
    VALIDATION_REQUIRED: 'Para continuar, ingrese su correo electrónico de acceso',
    CHANGE_ACCOUNT: 'Usar otra cuenta',
    REQUEST_INVITE: 'solicitar invitación',
    FORGOT_PASSWORD: '¿Olvidaste tu contraseña?',
    FORGOT_PASSWORD_TEXT1: '¡Hemos enviado un correo electrónico de confirmación!',
    FORGOT_PASSWORD_TEXT1_AGAIN: '¡Hemos reenviado un correo electrónico de confirmación!',
    FORGOT_PASSWORD_TEXT2: 'Vaya a su buzón.',
    FORGOT_PASSWORD_NOT_RECEIVED: 'No recibí el correo electrónico',
    FORGOT_PASSWORD_GO_BACK: 'Volver',
  },
};

export { CardLoginPT, CardLoginEN, CardLoginES };
