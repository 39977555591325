import styled from 'styled-components';

export const ButtonStyle = styled('button')`
${({ theme, showButton, styleguide }) => `
    position: ${styleguide ? 'static' : 'absolute'};
    right: ${theme.spacing.px15};
    top: ${theme.spacing.px25};
    z-index: 9999;
    border: none;
    background: none;
    padding: ${theme.spacing.none};
    font-size: ${theme.fontSize.px25};
    cursor: pointer;
    outline: none;
    opacity: 1;
    transition: all 0.5s;
    width: ${theme.spacing.px30};
    ${!showButton
    ? `
        display: none;
        `
    : ''
}
`}`;
