import { ApiTenant } from '@utils/api';
import BFFApi from '../utils/bffApi';

class ActionsApprovalsService {
  static putMediaNewInfo = (body) => {
    return BFFApi.put('/api/v1/media/update-media-for-approval', body);
  };

  static postDisapprovalsMedia = (body) => {
    return BFFApi.post('/api/v1/media/disapprove', body);
  };

  static postApprovalsMedia = (body) => {
    return BFFApi.post('/api/v1/media/approve', body);
  };

  static async getMedias({
    code,
    limit = 15,
    fields,
    version = '1.1',
    next = undefined,
    period = undefined,
    tab = undefined,
  }) {
    const hasNext = next ? `&page_hash=${next}` : '';
    const hasTab = tab ? `&status=${tab}` : '';
    let hasPeriod = period && period.params ? `&${period.params}` : '';
    if (tab === 'PENDING_APPROVAL') {
      hasPeriod = '';
    }
    const urlFields =
      fields ??
      'id,media_url,facebook_promoted_data,auditor_user,auditor_user_id,lite_media_url,description,media_type,source_id,status,thumbnail_url,disapproval_reason,created_time,fan_page,promote_info,fan_page_id,user,user_id,organic_info,has_suggested_approval,last_update_time,medias,type,tags_to_user,feedback_to_user,link_to_user,instagram_promoted_data,tiktok_promoted_data,incentive_campaign_id';
    const response = await ApiTenant.get(
      `/schema/${version}/${code}/medias?fields=${urlFields}${hasPeriod}&limit=${limit}${hasTab}${hasNext}&timezone_offset=-3`
    );

    return {
      medias: response.data.data,
      paging: response.data?.paging ?? {},
      isSuccess: response.status === 200,
    };
  }
}

export default ActionsApprovalsService;
