import styled from 'styled-components';

export const CustomCheckbox = styled('div')`
${({ theme, color, checked }) => `
        display: block;
        align-self: center;
        width: 18px;
        min-width: 18px;
        height: 18px;
        border-radius: 3px;
        ${checked ? `border: 1.5px solid ${theme.colors[color]};` : `border: 1.5px solid ${theme.colors.grey2};`}
        position: relative;
`}`;

export const InputCheckbox = styled('input')`
${({ theme, color }) => `
    cursor: pointer;
    min-width: 18px;
    max-width: 18px;
    width: 18px;
    min-height: 18px;
    max-height: 18px;
    height: 18px;
    appearance: none !important;
    outline: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0;
    opacity: 0;
    & + span {
        display: none;
    }
    &:checked {
        & + span {
            display: flex;
            align-items: center;
            justify-content: center;
            background: ${theme.colors[color]};
            width: 18px;
            height: 18px;
            svg {
                color: ${theme.colors.white};
            }
        }
    }
`}`;

export const IconCheckbox = styled('span')``;
