import React from 'react';

import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'react-translate';

import { FETCH_AUTO_COMPLETE_GROUP } from '@redux/generic/types';
import AutoComplete from './AutoComplete';

function SearchBar({
  focused,
  onKeyUp,
  onKeyDown,
  closeAutocomplete,
  clickOpen,
  innerRef,
  closeMenuProfileMobile,
}) {
  const t = useTranslate('Header');
  const {
    autoCompleteGroup: { data, isLoading, typed },
  } = useSelector((state) => state.genericReducer);
  const dispatch = useDispatch();

  const searching = debounce((value) => {
    if (value?.length) {
      dispatch({
        type: FETCH_AUTO_COMPLETE_GROUP.REQUEST,
        version: '1.1',
        typed: value,
        types: '"group"',
      });
    } else {
      dispatch({
        type: FETCH_AUTO_COMPLETE_GROUP.RESET,
      });
    }
  }, 500);

  function handleData() {
    const arrayToBeSend = [];

    if (data?.length) {
      data.forEach((item) => {
        arrayToBeSend.push({
          id: item.source_id,
          value: item.name,
        });
      });
    } else {
      arrayToBeSend.push({
        id: null,
        value: 'No items found',
      });
    }

    return arrayToBeSend;
  }

  return (
    <AutoComplete
      closeMenuProfileMobile={closeMenuProfileMobile}
      icon={!isLoading ? ['fal', 'search'] : ['fal', 'circle-notch']}
      onChange={(e) => searching(e.target.value)}
      options={handleData()}
      placeholder={t('SEARCH')}
      typed={typed}
      data={data}
      focused={focused}
      onKeyUp={onKeyUp}
      onKeyDown={onKeyDown}
      closeAutocomplete={closeAutocomplete}
      clickOpen={clickOpen}
      innerRef={innerRef}
    />
  );
}

export default SearchBar;
