/* Create action types */
import createActionTypes from '../createActionTypes';

export const GET_COMMENTS_SUMMARY = createActionTypes('GET_COMMENTS_SUMMARY');
export const GET_COMMENTS_GRAPH = createActionTypes('GET_COMMENTS_GRAPH');
export const GET_COMMENTS_RANK = createActionTypes('GET_COMMENTS_RANK');
export const GET_COMMENTS_RANK_EVOLUTION = createActionTypes('GET_COMMENTS_RANK_EVOLUTION');
export const GET_CAMPAIGN_HEALTH = createActionTypes('GET_CAMPAIGN_HEALTH');
export const FETCH_CAMPAIGN_HEALTH_INSTAGRAM = createActionTypes('FETCH_CAMPAIGN_HEALTH_INSTAGRAM');
export const FETCH_ITEM_CAMPAIGN_HEALTH_INSTAGRAM = createActionTypes(
  'FETCH_ITEM_CAMPAIGN_HEALTH_INSTAGRAM'
);
export const GET_CONVERSION_FLOW = createActionTypes('GET_CONVERSION_FLOW');
export const GET_LIST_ALL_GROUPS = createActionTypes('GET_LIST_ALL_GROUPS');
export const GET_LIST_ALL_ACCOUNTS = createActionTypes('GET_LIST_ALL_ACCOUNTS');
export const GET_LIST_ALL_PAGES = createActionTypes('GET_LIST_ALL_PAGES');
export const GET_RANKING_GROUPS = createActionTypes('GET_RANKING_GROUPS');
export const GET_RANKING_ACCOUNTS = createActionTypes('GET_RANKING_ACCOUNTS');
export const GET_RANKING_PAGES = createActionTypes('GET_RANKING_PAGES');
export const GET_RANKING = createActionTypes('GET_RANKING');
export const CHANGE_METRIC_CHART = createActionTypes('CHANGE_METRIC_CHART');
export const CHANGE_COMMENTS_METRIC_CHART = createActionTypes('CHANGE_COMMENTS_METRIC_CHART');
export const GET_AUDIENCE_AGE_GENDER = createActionTypes('GET_AUDIENCE_AGE_GENDER');
export const GET_AUDIENCE_CHANNEL = createActionTypes('GET_AUDIENCE_CHANNEL');
export const GET_AUDIENCE_DEVICE = createActionTypes('GET_AUDIENCE_DEVICE');
export const RENAME_GROUP = createActionTypes('RENAME_GROUP');
export const REPLACE_ACCOUNT = createActionTypes('REPLACE_ACCOUNT');
export const REPLACE_PAGE = createActionTypes('REPLACE_PAGE');
export const DELETE_GROUP = createActionTypes('DELETE_GROUP');
export const MOVE_GROUP = createActionTypes('MOVE_GROUP');
export const CHANGE_GROUP_COLOR = createActionTypes('CHANGE_GROUP_COLOR');
export const CREATE_GROUP = createActionTypes('CREATE_GROUP');
export const GET_GROUP_COLORS = createActionTypes('GET_GROUP_COLORS');
export const GET_CHART_RANKING = createActionTypes('GET_CHART_RANKING');
export const POST_REPORT_AD = createActionTypes('POST_REPORT_AD');
export const POST_REPORT_PAGE = createActionTypes('POST_REPORT_PAGE');
export const POST_REPORT_PAGE_FACEBOOK = createActionTypes('POST_REPORT_PAGE_FACEBOOK');
export const POST_REPORT_PAGE_INSTAGRAM = createActionTypes('POST_REPORT_PAGE_INSTAGRAM');
export const POST_REPORT_AD_EVOLUTION = createActionTypes('POST_REPORT_AD_EVOLUTION');
export const POST_REPORT_PAGE_EVOLUTION = createActionTypes('POST_REPORT_PAGE_EVOLUTION');
export const POST_REPORT_PAGE_EVOLUTION_FACEBOOK = createActionTypes(
  'POST_REPORT_PAGE_EVOLUTION_FACEBOOK'
);
export const POST_REPORT_PAGE_EVOLUTION_INSTAGRAM = createActionTypes(
  'POST_REPORT_PAGE_EVOLUTION_INSTAGRAM'
);
export const POST_ENGAGEMENT_BEST = createActionTypes('POST_ENGAGEMENT_BEST');
export const POST_PURCHASE_BEST = createActionTypes('POST_PURCHASE_BEST');
export const GET_INFO_GROUPS = createActionTypes('GET_INFO_GROUPS');
export const GET_SUMMARY = createActionTypes('GET_SUMMARY');
export const POST_INSIGHTS_METRICS = createActionTypes('POST_INSIGHTS_METRICS');
export const GET_ACTIVITY_METRICS = createActionTypes('GET_ACTIVITY_METRICS');
export const GET_BUDGET_METRICS = createActionTypes('GET_BUDGET_METRICS');
export const GET_ACTIVITIES_GROUPED_BY_DAY = createActionTypes('GET_ACTIVITIES_GROUPED_BY_DAY');
