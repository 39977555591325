import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const OfflineDescription = styled.h3`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.px30};
`;

export const CustomIcon = styled(FontAwesomeIcon)`
  display: table;
  margin-left: auto;
  margin-right: auto;
  color: ${({ theme }) => theme.colors.blueGrey2};
  font-size: ${({ theme }) => theme.spacing.px90};
  margin-bottom: ${({ theme }) => theme.spacing.px40};
`;

export const LinkReload = styled.a`
  color: ${({ theme }) => theme.colors.blue2};
  font-size: ${({ theme }) => theme.fontSize.px20};
  margin-bottom: ${({ theme }) => theme.spacing.none};
  display: table;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
`;

export const LoadingWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.px30};
`;
