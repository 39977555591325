import React, { useState, useEffect } from 'react';

import styled from 'styled-components';

import { useSelector } from 'react-redux';

import { MultiSelectCheckBox } from '@bornlogic/gd-design-system';

import SchemaService from '../../services/schema';

const MultiSelectContainer = styled.div`
  min-height: 300px;
`;

const GroupsAndPageUniqueList = ({ onSelectLeafs }) => {
  const {
    breadCrumb: {
      data: { currentCode: breadcrumbCode },
    },
  } = useSelector((state) => state.genericReducer);

  const [selectedLeafs, setSelectedLeafs] = useState([]);
  const [elegibleGroups, setElegibleGroups] = useState([]);

  const normalizeGroupsAndPages = (data) => {
    const normalizedData = [];

    data.forEach((item) => {
      normalizedData.push({
        source_id: item.source_id,
        child_count: 0,
        name: item.name,
        color: item.color,
        ad_account_ids: item.ad_account_ids,
      });
    });

    return normalizedData;
  };

  const loadGroupsAndPages = async () => {
    let sourceId = 'root';

    if (breadcrumbCode && breadcrumbCode.source_id && breadcrumbCode.type === 'group') {
      sourceId = breadcrumbCode.source_id;
    }

    const response = await SchemaService.getElegibleGroups(sourceId);

    if (response.status === 200 && response.data && response.data.length > 0) {
      setElegibleGroups(normalizeGroupsAndPages(response.data));
    }
  };

  const _handleAddNewLeaf = (data) => {
    setSelectedLeafs(data);
  };

  useEffect(() => {
    if (selectedLeafs.length > 0) onSelectLeafs(selectedLeafs);
  }, [selectedLeafs]);

  useEffect(() => {
    loadGroupsAndPages();
  }, []);

  return (
    <MultiSelectContainer>
      {elegibleGroups && (
        <MultiSelectCheckBox
          mainNode={elegibleGroups}
          leaf=""
          source_id="source_id"
          child_count="child_count"
          root=""
          searchPlaceHolder="Buscar"
          maxHeight={300}
          minHeight={300}
          selectedLeafs={selectedLeafs}
          onSelectLeaf={_handleAddNewLeaf}
          uniqueSelect
        />
      )}
    </MultiSelectContainer>
  );
};

export default GroupsAndPageUniqueList;
