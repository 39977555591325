import React from 'react';
import { Select } from '@bornlogic/gd-design-system';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_GROUPS_PERMITTED_ACCESS } from '@redux/generic/types';

export const SelectGroup = (props) => {
  const breadcrumbCode = useSelector((state) => state.genericReducer.breadCrumb.data.currentCode);

  let sourceId = 'root';
  if (breadcrumbCode?.source_id && breadcrumbCode?.type === 'group') {
    sourceId = breadcrumbCode.source_id;
  }

  const groupsPermitted = useSelector(state => state.genericReducer.groupsPermitted.data)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({
      type: GET_GROUPS_PERMITTED_ACCESS.REQUEST,
      version: '1.1',
      id: sourceId,
      fields: 'source_id,name',
    });
  }, [dispatch, sourceId]);

  return (
    <Select
      items={groupsPermitted.map((group) => ({
        title: group.name,
        value: group.source_id,
      }))}
      {...props}
    />
  );
};
