const CardInfosEN = {
  PUBLICATION: 'Publication:',
  PUBLICATION_TO: 'to',
  ENGAGEMENT: 'Engagemeent',
  IMPRESSIONS: 'Impreessions',
  CLICKS_MEDIA: 'Clicks',
  LINK_CLICKS_ON_MEDIA: 'Link clicks',
  INVESTMENT_ON_POST: 'Investment',
  REVENUE_WITH_POST: 'Reveneue',
  ROAS_WITH_POST: 'ROAS',
  REPLICATE_POST: 'Replicate',
  PROMOTE_POST: 'Promote',
};
const CardInfosPT = {
  PUBLICATION: 'Veiculação:',
  PUBLICATION_TO: 'até',
  ENGAGEMENT: 'Engajamento',
  IMPRESSIONS: 'Impressões',
  CLICKS_MEDIA: 'Cliques',
  LINK_CLICKS_ON_MEDIA: 'Cliques no link',
  INVESTMENT_ON_POST: 'Investimento',
  REVENUE_WITH_POST: 'Receita',
  ROAS_WITH_POST: 'ROAS',
  REPLICATE_POST: 'Replicar',
  PROMOTE_POST: 'Impulsionar',
};
const CardInfosES = {
  PUBLICATION: 'Veiculação:',
  PUBLICATION_TO: 'ate',
  ENGAGEMENT: 'Engajamento',
  IMPRESSIONS: 'Impressões',
  CLICKS_MEDIA: 'camarillas',
  LINK_CLICKS_ON_MEDIA: 'Cliques sin enlace',
  INVESTMENT_ON_POST: 'Inversión',
  REVENUE_WITH_POST: 'Receita',
  ROAS_WITH_POST: 'ROAS',
  REPLICATE_POST: 'Replicar',
  PROMOTE_POST: 'Impulsivo',
};

export { CardInfosEN, CardInfosPT, CardInfosES };
