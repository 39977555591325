import { useSWRInfinite } from 'swr';
import { useCallback, useMemo } from 'react';
import { bffFetcher } from 'utils/api';
import { Campaign, CampaignFolderResponse, PayloadResponse } from 'model';
import { RootStateOrAny, useSelector } from 'react-redux';
import { IBody, IGetCampaignFolders } from './types';

import { QUERY_SIZE, options, getKey } from './consts';

export function useGetCampaignFolders({
  appliedFilters,
  incentive_campaign_status,
  incentive_campaign_name,
}: IGetCampaignFolders) {
  const genericReducer = useSelector((state: RootStateOrAny) => state.genericReducer);
  const period = genericReducer.period ?? '';

  const code = window.location.hash.substring(2).split('/')[0];

  const URL = useMemo<string>(() => {
    const body: IBody = {
      limit: QUERY_SIZE,
      incentive_campaign_situation: 'with_incentive_campaign',
      fields: 'group_view',
      permitted_group: code,
      since_current: period.start,
      until_current: period.end,
      intersection_period: 'true',
      incentive_campaign_name: incentive_campaign_name,
    };
    if (appliedFilters) body.incentive_campaign_status = incentive_campaign_status;
    const uri = new URLSearchParams(body);
    return `/api/v1/incentivecampaign/period?${uri.toString()}`;
  }, [
    appliedFilters,
    code,
    incentive_campaign_name,
    incentive_campaign_status,
    period.end,
    period.start,
  ]);

  const { data, error, size, setSize, mutate, isValidating } = useSWRInfinite<
    PayloadResponse<CampaignFolderResponse>,
    Error
  >(
    (pageKey, previousPage) => {
      return getKey({ pageKey, previousPage, period, allData: campaigns, URL });
    },
    bffFetcher,
    options
  );

  const campaigns: Campaign[] | undefined = useMemo(() => {
    if (!data?.length) return [];
    return data.flatMap((page) => {
      return page?.payload?.data ?? [];
    });
  }, [data]);

  const fetchMoreData = useCallback(() => {
    if (isValidating) return;
    setSize((size) => size + 1);
  }, [isValidating, setSize]);

  const hasNextPage = useMemo(
    () => Boolean(data && campaigns?.length !== data[0]?.payload?.count_total),
    [data, campaigns?.length]
  );

  const isLoading = useMemo(
    () => (!data && !error) || (size > 0 && data && typeof data[size - 1] === 'undefined'),
    [data, error, size]
  );

  return {
    campaigns: campaigns,
    lastCampaign: data?.length ? data[0]?.payload?.last_campaign : null,
    error,
    fetchMoreData,
    hasNextPage,
    isLoading,
    mutate,
  };
}
