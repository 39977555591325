import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import Select from 'react-select';

export const MenuProfileRelative = styled.div`
  position: absolute;
  min-width: 360px;
  min-height: max-content;
  display: flex;
  justify-content: flex-end;
  z-index: 2005;
  opacity: 0;
  visibility: hidden;

  ${({ open, theme }) => css`
    max-height: ${theme.spacing.none};
    top: ${theme.spacing.none};

    ${open &&
    css`
      opacity: 1;
      visibility: visible;
      max-height: 99999px;
      top: ${theme.spacing.px80};
      right: ${theme.spacing.px20};
    `}
  `}
`;

export const MenuProfileContent = styled.div`
  opacity: 0;
  transition: all 0.5s;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12);
  position: relative;
  box-sizing: border-box;
  display: none;

  ${({ theme, open }) => css`
    background: ${theme.colors.white};
    max-height: ${theme.spacing.none};
    width: ${theme.spacing.full};
    border-radius: ${theme.rounded.px10};
    border: 1px solid ${theme.colors.grey2};
    margin-bottom: ${theme.spacing.px20};

    ${open &&
    css`
      opacity: 1;
      max-height: 99999px;
      display: block;
    `}
    &:after, &:before {
      bottom: ${theme.spacing.full};
      right: ${theme.spacing.px20};
      border: solid transparent;
      content: '';
      height: ${theme.spacing.none};
      width: ${theme.spacing.none};
      position: absolute;
      pointer-events: none;
    }
    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: ${theme.colors.white};
      border-width: ${theme.spacing.px10};
      margin-left: -${theme.spacing.px10};
      top: -${theme.spacing.px20};
    }
    &:before {
      border-color: rgba(204, 204, 204, 0);
      border-bottom-color: ${theme.colors.grey11};
      border-width: ${theme.spacing.px10};
      margin-left: -${theme.spacing.px10};
    }
  `}
`;

const selectModifiers = {
  ['COMPANIES']: css`
    width: 270px;
  `,
};

export const CustomSelect = styled(Select)`
  display: flex;

  ${({ theme, type }) => css`
    width: ${theme.spacing.full};
    font-size: ${theme.fontSize.px16} !important;
    font-weight: ${theme.fontWeight.normal} !important;
    color: ${theme.colors.blueGrey8} !important;
    min-height: ${theme.spacing.px40};
    > div {
      cursor: pointer;
      border-radius: ${theme.rounded.px8};
      border: 1px solid ${theme.colors.grey} !important;
      min-height: ${theme.spacing.px40};
      ${selectModifiers[type]}
    }
    &:hover {
      border: ${theme.spacing.px0};
      outline: none;
    }
  `}
`;

export const MenuProfileUser = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;

  ${({ theme }) => css`
    padding: ${theme.spacing.px25};
    border-bottom: 2px solid ${theme.colors.blue5};
  `}
`;

export const UserImage = styled.figure`
  width: 65px;
  height: 65px;
  overflow: hidden;

  ${({ theme }) => css`
    margin: ${theme.spacing.none};
    border-radius: ${theme.rounded.full};
  `}
`;

export const UserInfo = styled.div`
  box-sizing: border-box;
  line-height: 1;

  ${({ theme }) => css`
    padding-left: ${theme.spacing.px25};
  `}
`;

export const UserInfoName = styled.div`
  line-height: 1;

  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: ${theme.fontSize.px20};
    margin-bottom: ${theme.spacing.px8};
  `}
`;

export const UserInfoType = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.blueGrey3};
    font-size: ${theme.fontSize.px12};
    margin-bottom: ${theme.spacing.px8};
  `}
`;

export const MenuProfileOptions = styled.div`
  &:last-child {
    border-bottom: none;
  }

  ${({ theme }) => css`
    padding: ${theme.spacing.px20} ${theme.spacing.none};
    border-bottom: 2px solid ${theme.colors.blue5};
  `}
`;

export const CustomLink = styled(Link)`
  display: block;
  text-decoration: none;
  transition: all 0.2s;
  text-align: center;

  ${({ theme }) => css`
    color: ${theme.colors.grey11};
    font-size: ${theme.fontSize.px14};
    font-weight: ${theme.fontWeight.light};
    background: ${theme.colors.none};
    padding: ${theme.spacing.px10} 0;
    &:hover {
      background: ${theme.colors.grey5};
      color: ${theme.colors.grey11};
    }
    &:active {
      background: ${theme.colors.grey5};
      color: ${theme.colors.grey11};
    }
  `}
`;

export const CustomButton = styled.button`
  display: block;
  text-decoration: none;
  transition: all 0.2s;
  border: none;
  text-align: center;
  cursor: pointer;

  ${({ theme }) => css`
    width: ${theme.spacing.full};
    color: ${theme.colors.dangerousRed};
    font-size: ${theme.fontSize.px14};
    font-weight: ${theme.fontWeight.light};
    padding: ${theme.spacing.px10};
    background: ${theme.colors.none};
    &:hover {
      background: ${theme.colors.grey10};
      color: ${theme.colors.dangerousRed};
    }
    &:active {
      background: ${theme.colors.dangerousRed};
      color: ${theme.colors.grey5};
    }
  `}
`;

export const CustomCleanButton = styled.button`
  border: none;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }

  ${({ theme, action, type }) => css`
    ${action && modalModifiers[action](theme, type)}
    color: ${theme.colors.blueSecurity};
    font-size: ${theme.fontSize.px14};
  `}
`;

export const FirstLetterUser = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    width: ${theme.spacing.px40};
    height: ${theme.spacing.px40};
    font-size: ${theme.fontSize.px25};
    color: ${theme.colors.white};
    background-color: ${theme.colors.blue2};
    border-radius: ${theme.rounded.full};
  `}
`;

export const MenuProfileCustomer = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.px15} ${theme.spacing.px35} ${theme.spacing.none} ${theme.spacing.px35};
  `}
`;

export const MenuProfileCustomerButton = styled.button`
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  position: relative;

  ${({ theme, onlyOne }) => css`
    border: 1px solid ${theme.colors.grey2};
    border-radius: ${theme.rounded.px10};
    width: ${theme.spacing.full};
    padding: ${theme.spacing.px5} ${theme.spacing.px15};
    img {
      max-height: ${theme.spacing.px30};
      max-width: 75%;
    }
    svg {
      position: absolute;
      right: ${theme.spacing.px15};
      top: ${theme.spacing.px12};
      color: ${theme.colors.blueGrey2};
      font-size: ${theme.fontSize.px16};
    }
    ${onlyOne && 'cursor: default;'}
  `}
`;

export const LinkAccount = styled.div`
  display: table;
  text-decoration: none;
  line-height: 1;
  cursor: pointer;

  ${({ theme }) => css`
    color: ${theme.colors.blueSecurity};
    font-size: ${theme.fontSize.px12};
  `}
`;

export const CompanyOptions = styled.div`
  overflow: auto;
  max-height: 80vh;

  ${({ openMenu }) => css`
    ${openMenu && 'height: 480px;'}
  `}
`;

export const CompanyOptionsWrapper = styled.ul`
  list-style-type: none;
  position: relative;

  ${({ theme }) => css`
    padding: ${theme.spacing.none} ${theme.spacing.px20};
    margin: ${theme.spacing.none} ${theme.spacing.px0} ${theme.spacing.px30};
  `}
`;

export const CompanyOptionsItem = styled.li`
  box-sizing: border-box;
  outline: none;
  &:first-child {
    button {
      justify-content: center;
      cursor: default;
    }
  }
  &:last-child {
    border-bottom: none;
  }

  ${({ theme }) => css`
    width: ${theme.spacing.full};
    border-bottom: 2px solid ${theme.colors.blue5};
  `}
`;

export const CompanyOptionsButton = styled.button`
  background: none;
  border: none;
  box-sizing: border-box;
  outline: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  ${({ theme }) => css`
    padding: ${theme.spacing.px15} ${theme.spacing.px20};
    width: ${theme.spacing.full};
    img {
      max-height: ${theme.spacing.px30};
      max-width: ${theme.spacing.full};
    }
  `}
`;

export const CompanyOptionsImage = styled.figure`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    margin: ${theme.spacing.none};
    width: ${theme.spacing.px50};
    height: ${theme.spacing.px50};
    border-radius: ${theme.rounded.full};
    border: 1px solid ${theme.colors.grey2};
    padding: ${theme.spacing.px5};
    margin-right: ${theme.spacing.px10};
  `}
`;

export const CompanyOptionsClose = styled.button`
  background: none;
  border: none;
  position: relative;
  z-index: 1;
  outline: none;
  cursor: pointer;
  float: right;

  ${({ theme }) => css`
    font-size: ${theme.fontSize.px20};
    color: ${theme.colors.blueGrey2};
    border-radius: ${theme.rounded.full};
    margin: ${theme.spacing.px20} ${theme.spacing.px15} -${theme.spacing.px20} 0;
  `}
`;

export const InputWrapper = styled.div`
  align-items: center;
  display: flex;

  button {
    background: none;
    border: none;
    position: relative;
    z-index: 1;
    outline: none;
    cursor: pointer;
  }

  ${({ theme }) => css`
    gap: ${theme.spacing.px5};
    padding: ${theme.spacing.px10};
    margin-bottom: ${theme.spacing.px20};
    button {
      padding: ${theme.spacing.px16} ${theme.spacing.px45};
    }
  `}
`;
