import React from 'react';
import { ButtonSearch } from '../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslate } from 'react-translate';

function HelpButton() {
  const t = useTranslate('Header');
  const { params } = useRouteMatch();
  const history = useHistory();

  function handleHelpButtonClick() {
    window.open('https://bornlogic.zendesk.com/', '_blank');
  }

  return (
    <ButtonSearch id="helpButton" onClick={handleHelpButtonClick}>
      <FontAwesomeIcon icon={['fal', 'life-ring']} />
      <p>{t('HELP')}</p>
    </ButtonSearch>
  );
}

export default HelpButton;
